<div *ngIf="!minified" class="avatar-wrapper">
    <div class="avatar-frame">
        <div class="avatar">
            <app-shared-avatar [imageUrl]="user.avatar_url"></app-shared-avatar>
        </div>
        <div *ngIf="user" class="ml-2">
            <h3 *ngIf="user.nickname" class="d-block mb-0 name">{{ user.nickname | print }} <i *ngIf="user.is_locked" class="far fa-lock" style="font-size: 15px;"></i></h3>
            <h3 *ngIf="!user.nickname" class="d-block mb-0 name">{{ user.firstname + ' ' + user.lastname | print }} <i *ngIf="user.is_locked" class="far fa-lock" style="font-size: 15px;"></i></h3>
            <p class="d-block reply-time mb-0">
                {{ exactTime ? (answer.creationtime | date:'short') : (answer.creationtime | dateAgo) }}
                -
                <span *ngIf="answer.device">{{ 'FROM' | translate | lowercase }}</span>
                <i *ngIf="answer.isSmartphone() || answer.isMobile()" class="fal fa-mobile ml-2"></i>
                <i *ngIf="answer.isDesktop() || !answer.device" class="far fa-desktop ml-2"></i>
            </p>
        </div>
    </div>
</div>