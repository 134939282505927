
import { Injectable } from '@angular/core';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { BaseService } from '@app/core/services/base.service';
import { ApiService } from '@app/core/services/api.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { of, Observable } from 'rxjs';
import { SearchParams } from '@app/core/models/search-params';
import { PageModel } from '@app/core/models/page';

@Injectable({
  providedIn: 'root'
})
export class RolesService extends BaseService implements BaseServiceI {

  values: Array<any>;

  constructor(public router: Router, public apiService: ApiService, public translateService: TranslateService) {
    super(router, apiService, null);

    this.values = [
        {name: translateService.instant('ROLES.RESEARCHER'), role: 'RESEARCHER'},
        {name: translateService.instant('ROLES.CUSTOMER_BASIC'), role: 'CUSTOMER_BASIC'},
        {name: translateService.instant('ROLES.CUSTOMER_PRO'), role: 'CUSTOMER_PRO'}
    ];
  }

  dropdownList(searchParams?: SearchParams, useCache: boolean = false): Observable<PageModel> {
    return of(new PageModel(this.values, this.values.length));
  }

}
