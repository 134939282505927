import { Component, OnInit } from '@angular/core';
import { ModalComponent } from '@app/core/components/modal/modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { MessageModel } from '@app/shared/models/message.model';
import { TeamService } from '@app/shared/services/team.service';
import { ResponseModel } from '@app/core/models/response.model';
import { TeamModel } from '@app/shared/models/team.model';
import { TemplateMessageModel } from '@app/shared/models/template-message.model';
import { TemplateMessageService } from '@app/shared/services/template-messages.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-missing-answers',
  templateUrl: './missing-answers.component.html',
  styleUrls: ['./missing-answers.component.scss']
})
export class MissingAnswersComponent extends ModalComponent  implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private coreLayoutService: CoreLayoutService,
    private teamService: TeamService,
    private templateMessageService: TemplateMessageService,
    private toastService: ToastrService,
    private translateService: TranslateService

    ) {
    super(activeModal);
    this.modules = {
      'emoji-shortname': true,
      'emoji-textarea': true,
      'emoji-toolbar': true,
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ header: 1 }, { header: 2 }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ color: [] }, { background: [] }],
        ['link'],
        ['emoji']
      ]
    };
  }

  modules: any;
  missingAnswersRaw: any = [];
  missingAnswers: any = [];
  userid: number;
  user: TeamModel;
  emailView = false;
  message: any = {};
  countryid: number;
  blocksLinks = '';
  confirm = false;

  showSaveAsTemplate = false;
  showLoadFromTemplate = false;
  templateTitle = '';
  currentTemplateMessage: TemplateMessageModel;
  templateMessages: TemplateMessageModel[];



  ngOnInit(): void {
    if (this.params.missingAnswers) {
      this.missingAnswersRaw = this.params.missingAnswers;
      this.parseMissingAnswers();
    }
    if (this.params.userid) {
      this.userid = this.params.userid;
    }
    if (this.params.user) {
      this.user = this.params.user;
    }
    if (this.params.countryid) {
      this.countryid = this.params.countryid;
    }
    this.loadTemplateMessages()

  }

  saveAsTemplateMessage() {
    const templateMessage = {
      title: this.templateTitle,
      body: this.message.msg,
      subject: this.message.subject
    }
    this.templateMessageService.createTemplateMessage(templateMessage).subscribe((res: ResponseModel) => {
      this.templateTitle = '';
      this.showSaveAsTemplate = false;1
      this.toastService.success(this.translateService.instant('SAVED_AS_TEMPLATE_MESSAGE'));
      this.loadTemplateMessages()
    })
  }
  loadTemplateMessages() {
    this.templateMessageService.getTemplateMessages().subscribe((res: ResponseModel) => {
      this.templateMessages = res.getData();
    })
  }

  setTemplateMessage(template: TemplateMessageModel) {
    this.currentTemplateMessage = template;
  }

  loadFromTemplate() {
    this.message.subject = this.currentTemplateMessage.subject;
    this.message.msg = this.currentTemplateMessage.body;
  }


  parseMissingAnswers() {
    for (const blockData of this.missingAnswersRaw) {
      const date = new Date(this.parseCompatibilityDate(blockData.block_datetime));
      date.setHours(0, 0, 0, 0);
      this.missingAnswers = [...this.missingAnswers, {
        date,
        block_timestamp: date.getTime(),
        block_title: blockData.block_title,
        blockid: blockData.blockid,
        block_position: blockData.block_position
      }];
    }
  }

  showEmailView() {
    this.emailView = true;
    this.message.countryid = this.countryid;
    this.message.userid = this.userid;
    this.message.missing_answers_blocks = this.missingAnswers;
  }

  closeModal() {
    this.activeModal.close();
  }

  sendEmail() {
    this.message.other_data = {
      recipient_user_firstname: null,
      recipient_room: null,
      recipient_filters: null,
      recipient_variable_tags: null
    };
    this.message.other_data.recipient_user_firstname = this.user.nickname || this.user.firstname;

    const missingBlocksHeader = `<br>Ecco i link alle domande senza risposta:<br>`;
    this.blocksLinks += missingBlocksHeader;
    this.message.msg += missingBlocksHeader;
    for (const block of this.missingAnswers) {
      const blockLink = `<br><a href="#">${block.block_title}</a>`;
      this.blocksLinks += blockLink;
      this.message.msg += blockLink;
    }

    this.confirm = true;
  }

  confirmSend() {
    this.message.msg = this.message.msg.replace(this.blocksLinks, '');
    this.message.send_now = true;
    this.teamService.sendMissingAnswersEmail(this.message).subscribe((res: ResponseModel) => {
      this.closeModal();
    });
  }

  parseCompatibilityDate(value: string | Date) {
    if (typeof value === 'string') {
      if (value.indexOf('-') !== -1) {
        value = this.replaceAll(value, '-', '/');
      }
      // @ts-ignore
      if (value.indexOf('+') !== -1) {
        value = value.toString();
        value = value.substring(0, value.indexOf('+'));
      }
    }
    return value;
  }
  replaceAll(str, search, replace) {
    return str.replace(new RegExp(search, 'g'), replace);
  }
}
