import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { ResponseModel } from '@app/core/models/response.model';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { QuestionsService } from '@app/shared/services/questions.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { QuestionModel } from '@app/shared/models/question.model';
import { BlockModel } from '@app/shared/models/block.model';

@Component({
  selector: 'app-setup-questions-pin-on-media',
  templateUrl: './pin-on-media.component.html',
  styleUrls: ['./pin-on-media.component.scss']
})
export class PinOnMediaQuestionComponent extends CoreBaseComponent implements OnInit {

  @Input() countryUniqId: string = null;
  @Input() block: BlockModel = null;
  @Input() question: QuestionModel = null;
  @Input() disabled = false;
  @Output() saveEmit: EventEmitter<string> = new EventEmitter(null);

  public form: UntypedFormGroup;
  get videos(): UntypedFormArray {
    return this.form.get('videos') as UntypedFormArray;
  }
  get images(): UntypedFormArray {
    return this.form.get('images') as UntypedFormArray;
  }

  constructor(private formBuilder: UntypedFormBuilder, private layoutService: CoreLayoutService, private translate: TranslateService,
              private questionsService: QuestionsService, private toastService: ToastrService) {
    super();
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      question_data: this.formBuilder.group({
        media_type: new UntypedFormControl(this.question ? this.question.question_data.media_type : '', Validators.required),
        video_max_views: new UntypedFormControl(this.question ? this.question.question_data.video_max_views : '', null),
        image_max_seconds: new UntypedFormControl(this.question ? this.question.question_data.image_max_seconds : '', null),
        require_rating: new UntypedFormControl(this.question ? this.question.question_data.require_rating : '', null),
        require_comment: new UntypedFormControl(this.question ? this.question.question_data.require_comment : '', null),
        require_pins: new UntypedFormControl(this.question ? this.question.question_data.require_pins : '', null),
        rating_question: new UntypedFormControl(this.question ? this.question.question_data.rating_question : '', null),
        rating_range: new UntypedFormControl(this.question ? this.question.question_data.rating_range : '', null),
        comment_question: new UntypedFormControl(this.question ? this.question.question_data.comment_question : '', null),
        pins_question: new UntypedFormControl(this.question ? this.question.question_data.pins_question : '', null),
        pins_type: new UntypedFormControl(this.question ? this.question.question_data.pins_type : '', null),
        pins_min_num: new UntypedFormControl(this.question ? this.question.question_data.pins_min_num : '', null),
      }),
      videos: this.formBuilder.array(
        this.question && (this.question.question_data.videos || this.question.question_data.video)
          ? [
            new UntypedFormGroup({
              video_url: new UntypedFormControl(this.question.question_data.video ? this.question.question_data.video.video_url : this.question.question_data.videos[0].video_url, null),
              video_del: new UntypedFormControl(false, null),
              video_uniqid: new UntypedFormControl(this.question.question_data.video ? this.question.question_data.video.video_uniqid : this.question.question_data.videos[0].video_uniqid, null)
            })
          ]
          : []
      ),
      images: this.formBuilder.array(
        this.question && this.question.question_data.image
          ? [
            new UntypedFormGroup({
              image_url: new UntypedFormControl(this.question.question_data.image.image_url, null),
              image_del: new UntypedFormControl(false, null),
              image_uniqid: new UntypedFormControl(this.question.question_data.image.image_uniqid, null)
            })
          ]
          : []
      ),
      blockid: new UntypedFormControl(this.block.id, Validators.required),
      is_masked: new UntypedFormControl(this.question ? this.question.is_masked : this.block.is_room_group ? true : false, null),
      title: new UntypedFormControl(this.question ? this.question.title : '', Validators.required),
      type: new UntypedFormControl('pin-on-media', null),
      personas_tags: new UntypedFormControl(this.question ? this.question.personas_tags : '', null),
      sample_variable_tags: new UntypedFormControl(this.question ? this.question.sample_variable_tags : '', null)
    });
    if (this.disabled) {
      this.form.disable();
    }
    this.layoutService.buttons([
      {label: 'SAVE_AND_EXIT', slug: 'saveexit', classes: 'mr-3 btn-sicche btn-secondary', icon: 'fal fa-share-square', click: this.submitAndExit },
      {label: 'SAVE_AND_CREATE_NEW', slug: 'save', classes: 'mr-5 btn-sicche btn-primary', icon: 'fal fa-check', click: this.submitAndStay },
    ]);

    this.form.setValidators(this.questionDataValidator());
    this.videos.valueChanges.subscribe(value => this.onVideosChange(value));
    this.images.valueChanges.subscribe(value => this.onImagesChange(value));
    this.onVideosChange(this.videos.value); // call first time
    this.onImagesChange(this.images.value); // call first time
  }


  questionDataValue(control: string): any {
    return (this.form.get('question_data') as UntypedFormGroup).controls[control].value;
  }

  questionDataValidator(): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors => {
      const qdata = (group.get('question_data') as UntypedFormGroup).controls;
      if (
        !qdata.media_type.value ||
        (qdata.require_rating.value && !qdata.rating_range.value) ||
        (qdata.require_pins.value && (!qdata.pins_min_num.value || !qdata.pins_type.value)) ||
        (qdata.media_type.value === 'video' && !((group.get('videos') as UntypedFormArray).value.length)) ||
        (qdata.media_type.value === 'image' && !((group.get('images') as UntypedFormArray).value.length)) ||
        (!qdata.require_rating.value && !qdata.require_comment.value && !qdata.require_pins.value)
      ) {
        return { conditionalInputRequired: true };
      }
      return;
    };
  }

  onVideosChange(value: any[]) {
    if (value.length >= 1) {
      this.videos.disable({ emitEvent: false });
    } else {
      this.videos.enable({ emitEvent: false });
    }
  }

  onImagesChange(value: any[]) {
    if (value.length >= 1) {
      this.images.disable({ emitEvent: false });
    } else {
      this.images.enable({ emitEvent: false });
    }
  }

  submitAndExit = () => {
    this.layoutService.getButton('saveexit').loading = true;
    this.submit(() => {
      this.saveEmit.emit('save-and-leave');
    }, () => {
      this.layoutService.getButton('saveexit').loading = false;
    });
  }

  submitAndStay = () => {
    this.layoutService.getButton('save').loading = true;
    this.submit(() => {
      this.saveEmit.emit('save-and-stay');
    }, () => {
      this.layoutService.getButton('save').loading = false;
    });
  }

  submit(callback: any, fallback: any) {
    if (this.disabled) {
      fallback();
      this.toastService.error(this.translate.instant('ERRORS.CAN_NOT_MODIFY_PAST'));
      return;
    }
    const formValue = this.form.getRawValue();
    const formData = new FormData();
    if (this.form.hasError('conditionalInputRequired') || this.form.controls.title.errors?.required) {
      fallback();
      this.toastService.error(this.translate.instant('ERRORS.GENERIC_INPUTS_REQUIRED'));
    } else {
      this.operation('creating').reset().isSubmitting();
      const data = formValue;
      if (data.sample_variable_tags && data.sample_variable_tags.length > 0) {
        data.sample_variable_tags_by_comma = data.sample_variable_tags.join(',');
        data.sample_variable_tags = null;
      }
      if (data.personas_tags && data.personas_tags.length > 0) {
        data.personas_tags_by_comma = data.personas_tags.join(',');
        data.personas_tags = null;
      }
      if (data.question_data.media_type === 'video') {
        if ((this.form.controls.videos as UntypedFormArray).controls[0].value.video) {
          formData.append('videos1', ((this.form.controls.videos as UntypedFormArray).controls[0].value.video as File));
        } else {
          data.question_data.videos = data.videos;
        }
      } else {
        data.question_data.image = data.images[0];
      }
      delete data.videos;
      delete data.images;
      (
        !this.question
          ? this.questionsService.createQuestion(data, formData)
          : this.questionsService.updateQuestion(this.question.id, data, formData)
      )
        .subscribe(
          (res: ResponseModel) => {
            this.operation('creating').isSuccess(res.getErrors());
            this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
            callback();
          }, (e) => {
            this.operation('creating').isFailed(e.errors);
            this.toastService.error(this.translate.instant('ERRORS.GENERIC'));
            fallback();
          }
        );
    }
  }

  uploadErr(e: { error: string, params: { [k: string]: string } }) {
    this.toastService.error(this.translate.instant(e.error, e.params));
  }
}
