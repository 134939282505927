<section>
    <form [formGroup]="form">
        <div class="container-fluid position-relative">

            <div class="text-center mb-3" formGroupName="question_data">
                <h4 class="mb-2">{{ 'SELECT_MEDIA_TYPE' | translate }}</h4>
                <div ngbRadioGroup class="btn-group btn-group-toggle" name="media_type" >
                    <label ngbButtonLabel class="btn btn-secondary">
                        <input ngbButton type="radio" formControlName="media_type" value="video">{{ 'VIDEO' | translate }}
                    </label>
                    <label ngbButtonLabel class="btn btn-secondary">
                        <input ngbButton type="radio" formControlName="media_type" value="image">{{ 'IMAGE' | translate }}
                    </label>
                </div>
            </div>

            <h4 class="mb-2">{{ 'QUESTION' | translate }}</h4>
            <div class="question-text panel shadow">
                <app-setup-questions-text [form]="form" [countryUniqId]="countryUniqId"></app-setup-questions-text>
            </div>


            <ng-container *ngIf="questionDataValue('media_type')">
                <div class="row">
                    <div class="col-12">
                        <h5>{{ 'UPLOAD_MEDIA' | translate }}</h5>
                        <ng-container *ngIf="questionDataValue('media_type') === 'video'">
                            <app-setup-questions-media-upload [form]="form" [formArray]="videos" [formArrayName]="'videos'" [mediaType]="'video'" [multipleAllowed]="false" (inputError)="uploadErr($event)"></app-setup-questions-media-upload>
                        </ng-container>
                        <ng-container *ngIf="questionDataValue('media_type') === 'image'">
                            <app-setup-questions-media-upload [form]="form" [formArray]="images" [formArrayName]="'images'" [mediaType]="'image'" [multipleAllowed]="false"></app-setup-questions-media-upload>
                        </ng-container>
                    </div>
                </div>

                <div class="row">
                    <div class="col-6">
                        <ng-container *ngIf="questionDataValue('media_type') === 'video'">
                            <div class="form-group" formGroupName="question_data">
                                <app-core-select
                                    [classes]="'form-control'"
                                    [placeholder]="'VIDEO_MAX_VIEWS'"
                                    [form]="form.get('question_data')"
                                    [formEntityName]="'video_max_views'"
                                    [readonly]="disabled"
                                    [customList]="['', 1, 2]">
                                </app-core-select>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="questionDataValue('media_type') === 'image'">
                            <div class="form-group" formGroupName="question_data">
                                <input class="form-control" formControlName="image_max_seconds" type="number">
                                <span class="placeholder">{{ 'IMAGE_MAX_SECONDS' | translate }}</span>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <div class="d-inline-flex pr-4 mb-3 required">{{ 'AT_LEAST_ONE_FLAG_REQ' | translate }}</div>

                <div class="row mb-3">
                    <div class="col-12">
                        <div class="mb-3" formGroupName="question_data">
                            <input formControlName="require_rating" type="checkbox" id="require-rating">
                            <label for="require-rating">{{ 'REQUIRE_MEDIA_RATING' | translate }}</label>
                        </div>

                        <ng-container *ngIf="questionDataValue('require_rating')">
                            <div class="form-group" formGroupName="question_data">
                                <input class="form-control" formControlName="rating_question" type="text" [placeholder]="'RATE_MEDIA_DEFAULT_QUESTION' | translate">
                                <span class="placeholder">{{ 'QUESTION_TEXT' | translate }}</span>
                            </div>
                            <div class="d-inline-flex pr-4 align-items-center required" formGroupName="question_data">
                                <div class="check-radio-box mr-2">
                                    <input formControlName="rating_range" [value]="'1_7'" type="radio"  name="rating_range" id="range-17">
                                    <label for="range-17">1 - 7</label>
                                </div>
                                <div class="check-radio-box">
                                    <input formControlName="rating_range" [value]="'0_10'" type="radio"  name="rating_range" id="range-010">
                                    <label for="range-010">0 - 10</label>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-12">
                        <div class="mb-3" formGroupName="question_data">
                            <input formControlName="require_comment" type="checkbox" id="require-comment">
                            <label for="require-comment">{{ 'REQUIRE_MEDIA_COMMENT' | translate }}</label>
                        </div>

                        <ng-container *ngIf="questionDataValue('require_comment')">
                            <div class="form-group" formGroupName="question_data">
                                <input class="form-control" formControlName="comment_question" type="text" [placeholder]="'COMMENT_MEDIA_DEFAULT_QUESTION' | translate">
                                <span class="placeholder">{{ 'QUESTION_TEXT' | translate }}</span>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-12">
                        <div class="mb-3" formGroupName="question_data">
                            <input formControlName="require_pins" type="checkbox" id="require-pins">
                            <label for="require-pins">{{ 'REQUIRE_MEDIA_PINS' | translate }}</label>
                        </div>

                        <ng-container *ngIf="questionDataValue('require_pins')">
                            <div class="form-group" formGroupName="question_data">
                                <input class="form-control" formControlName="pins_question" type="text" [placeholder]="'PIN_ON_MEDIA_DEFAULT_QUESTION' | translate">
                                <span class="placeholder">{{ 'QUESTION_TEXT' | translate }}</span>
                            </div>
                            <div class="d-inline-flex pr-4 align-items-center required" formGroupName="question_data">
                                <div class="check-radio-box mr-2">
                                    <input formControlName="pins_type" value="text" type="radio"  name="pins_type" id="pins-type-txt">
                                    <label for="pins-type-txt">{{ 'ONLY_TEXT' | translate }}</label>
                                </div>
                                <div class="check-radio-box mr-2">
                                    <input formControlName="pins_type" value="text_emoticon" type="radio"  name="pins_type" id="pins-type-txtemtc">
                                    <label for="pins-type-txtemtc">{{ 'TEXT_EMOTICONS' | translate }}</label>
                                </div>
                            </div>
                            <div>{{ 'MINIMUM' | translate }}: </div>
                            <div class="d-inline-flex pr-4 align-items-center required" formGroupName="question_data">
                                <div class="check-radio-box mr-2">
                                    <input formControlName="pins_min_num" value="1" type="radio"  name="pins_min_num" id="pins-min-1">
                                    <label for="pins-min-1">{{ 'MINIMUM' | translate }} 1</label>
                                </div>
                                <div class="check-radio-box mr-2">
                                    <input formControlName="pins_min_num" value="2" type="radio"  name="pins_min_num" id="pins-min-2">
                                    <label for="pins-min-2">{{ 'MINIMUM' | translate }} 2</label>
                                </div>
                                <div class="check-radio-box mr-2">
                                    <input formControlName="pins_min_num" value="3" type="radio"  name="pins_min_num" id="pins-min-3">
                                    <label for="pins-min-3">{{ 'MINIMUM' | translate }} 3</label>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                </div>
            </ng-container>

            <app-setup-questions-selects [question]="question" [block]="block" [form]="form" [countryUniqId]="countryUniqId"></app-setup-questions-selects>
            <div class="mb-3 mt-3" *ngIf="block.is_room_group">
                <input formControlName="is_masked" type="checkbox" id="check2">
                <label for="check2">
                    {{ 'MASQUERADE_QUESTION' | translate }}
                </label>
            </div>
        </div>
    </form>
</section>