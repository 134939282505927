
<h3 class="mt-4 mb-5">{{ 'COUNTRY_SETUP.RESEARCH_INFO' | translate }}</h3>
<app-check-setup *ngIf="country" [country]="country"></app-check-setup>

<div *ngIf="country">
    <button class="btn-sicche btn-secondary" (click)="manageTeam()"><i class="fas fa-cogs"></i>{{ 'COUNTRY_SETUP.MANAGE_TEAM' | translate }}</button>
    <button class="ml-2 btn-sicche btn-secondary" (click)="manageIntervieweds()"><i class="fal fa-users"></i>{{'COUNTRY_SETUP.MANAGE_INTERVIEWEDS' | translate}}</button>
    <button class="ml-2 btn-sicche btn-secondary" *ngIf="research && research.countries.length > 1 && !isMainCountry(country)" (click)="importStructure()">
        <i class="fas fa-file-import"></i>{{ 'COUNTRY_SETUP.IMPORT_STRUCTURE' | translate }}
    </button>
    <span class="info-tooltip"  *ngIf="research && research.countries.length > 1 && !isMainCountry(country)" placement="top" [ngbTooltip]="'TOOLTIPS.SETUP_IMPORT_STRUCTURE' | translate">
        <i class="far fa-info-circle"></i>
    </span>
</div>

<div class="panel shadow">
    <app-core-spinner [toggle]="operation('loading-form').submitting"></app-core-spinner>
    <!-- <p class="text-uppercase bold subtitle">{{ 'COUNTRY_SETUP.INTERVIEWED_INTERFACE_SETTINGS' | translate }}</p> -->
    <form action="" *ngIf="form" [formGroup]="form" (change)="submitForm()" class="w-100">
        <div class="row">
            <div class="col-lg-5 col-xl-6">
                <div [class.is-invalid]="form.get('title').invalid && form.get('title').touched">
                    <div class="form-group required">
                        <input required formControlName="title" type="text" class="form-control" id="title">
                        <span class="placeholder">{{ 'TITLE' | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-xl-3">
                <div class="form-group choose-file-input required" [class.is-invalid]="form.get('logo').invalid && form.get('logo').touched">
                    <div (click)="removeLogo()" class="remove-logo" *ngIf="country.logo_url || country.logo">
                        <i class="fal fa-times"></i>
                    </div>
                    <label>
                        <img [src]="country.logo_url | privatesrc" class="country-logo" alt="">
                        <span class="placeholder">{{ 'LOGO' | translate }}</span>
                        <input type="file" id="file" (change)="uploadLogo($event.target.files)" style="width: 40%">
                    </label>
                </div>
            </div>
            <div class="col-lg-3 col-xl-3">
                <div class="form-group color-picker-input">
                    <label class="form-control">
                        <span class="placeholder">{{ 'CHANGE_COLOR' | translate }}</span>
                        <input [(colorPicker)]="this.country.color" [cpPosition]="'bottom'" [style.background]="this.country.color" (colorPickerClose)="colorChange($event)"/>
                    </label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <button type="button" (click)="customizePrivacy()" class="form-control button-input"></button>
                    <!--<span *ngIf="country.privacy_html">{{'PRIVACY_POLICY_UPLOADED' | translate}}</span>-->
                    <span class="form-field-check" *ngIf="country.privacy_html"><i _ngcontent-bvw-c230="" class="far fa-check"></i></span>
                    <span class="placeholder">{{ 'CUSTOMIZE_PRIVACY_POLICY' | translate }}</span>
                </div>
            </div>

            <div class="col-md-3">
                <div [class.is-invalid]="form.get('email_from_name').invalid && form.get('email_from_name').touched">
                    <div class="form-group">
                        <input required formControlName="email_from_name" (change)="checkCommaEmailFromName()" placeholder="Sicché - Qualitative Platform" type="text" class="form-control" id="email_from_name">
                        <span class="placeholder">{{ 'EMAIL_FROM_NAME' | translate }}</span>
                    </div>
                </div>
            </div>

            <div class="col-md-4 d-flex">
                <div class="form-group">
                    <!-- <select formControlName="language_code"  class="form-control">
                        <option *ngFor="let c of available_languages" [ngValue]="c" >{{ (c | uppercase) | translate}}</option>
                    </select> -->
                    <app-core-select
                        [classes]="'form-control'"
                        [cache]="true"
                        [form]="form"
                        [placeholder]="'CHANGE_INTERVIEWED_LANG'"
                        [formEntityName]="'language_code'"
                        [customList]="available_languages"
                        [preselected]="country.language_code"
                        [label]="'name'"
                        [value]="'language_code'"
                        (change)="submitForm()"
                    >
                    </app-core-select>
                    <span class="info-tooltip" placement="top" [ngbTooltip]="'TOOLTIPS.SETUP_LANGUAGE_SELECTION' | translate">
                        <i class="far fa-info-circle"></i>
                    </span>
                </div>
            </div>

            <div class="col-md-2 d-flex">
                <div class="form-group">
                    <!--<span class="label-big">{{ 'CHANGE_TIMEZONE' | translate }}</span>-->
                    <!-- <select formControlName="timezone"  class="form-control">
                        <option *ngFor="let c of timezoneService.values" [ngValue]="c" >{{ (c | uppercase) | translate}}</option>
                    </select> -->
                    <app-core-select
                        [classes]="'form-control'"
                        [placeholder]="'CHANGE_TIMEZONE'"
                        [cache]="true"
                        [form]="form"
                        [formEntityName]="'timezone'"
                        [service]="timezoneService"
                        [label]="'name'"
                        [value]="'value'"
                        [searchable]="true"
                        [readonly]="country.timezone_locked"
                        (change)="submitForm()"
                        >
                    </app-core-select>
                </div>
            </div>

        </div>
    </form>
</div>