import { Component, OnInit, Input } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { ModalService } from '@app/core/services/modal.service';
import { AnswersService } from '@app/shared/services/answers.service';
import { AnswersChartsService } from '../answers-modals/answers-charts.service';
import * as Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';
exporting(Highcharts);

@Component({
  selector: 'app-moderation-chartsdispatcher',
  templateUrl: './charts-dispatcher.component.html',
  styleUrls: ['./charts-dispatcher.component.scss']
})
export class ChartsModerationDispatcherComponent extends CoreBaseComponent implements OnInit {

  @Input()
  question: QuestionModel;
  @Input()
  answers: Array<any>;
  public chartReady = false;
  public Highcharts: typeof Highcharts = Highcharts;
  public chartOptions: Highcharts.Options = {
    credits: {
        enabled: false
    },
    title: {
      text: ''
    },
    series: [{
      name: 'Risposta singola',
      data: [],
      type: 'pie'
    }],
    tooltip: {
      pointFormat: '<b>{point.percentage:.1f}%</b>' /* {series.name}:  */
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: ['downloadJPEG', 'downloadPDF', 'downloadSVG'],
        },
      },
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
        }
    }
  };

  constructor(private modalService: ModalService, private answerService: AnswersService,
              public answersChartsService: AnswersChartsService) {
    super();
  }

  async ngOnInit(): Promise<void> {
    const dim = await this.answersChartsService.calculateChartDim(this.question);
    this.chartReady = await this.answersChartsService.calculateChartData(this.question, this.answers, this.chartOptions, dim);
  }

  selectText() {
    // void
  }

}
