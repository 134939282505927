import { Component, OnInit, Input, Output, NgZone, ViewChild, ElementRef, TRANSLATIONS } from '@angular/core';
import { BlockModel } from '@app/shared/models/block.model';
import { BlockService } from '@app/shared/services/block.service';
import { EventEmitter } from '@angular/core';
import { ResponseModel } from '@app/core/models/response.model';
import {  UntypedFormControl, UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { DeviceService } from '@app/shared/services/devices.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DatetimeToDbPipe } from '@app/core/pipes/datetime-to-db.pipe';
import { QuestionModel } from '@app/shared/models/question.model';
import { QuestionsService } from '@app/shared/services/questions.service';
import { AddQuestionComponent } from '../../../add-question/add-question.component';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { DateToLocaleStringPipe } from '@app/core/pipes/date-to-locale-string.pipe';
import { DateToHourMinutes } from '@app/core/pipes/date-to-hour-minutes.pipe';
import * as angular from 'angular';
import { ResearchModel } from '@app/shared/models/research.model';
import { ResearchSessionService } from '@app/shared/services/research-session.service';
import { CountryService } from '@app/shared/services/country.service';

@Component({
  selector: 'app-private-question-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.scss']
})
export class BlockComponent extends CoreBaseComponent implements OnInit {

  @Input() block: BlockModel;
  @Input() day: Date;
  @Input() lastDay: Date;
  @Output() updateBlocks = new EventEmitter();
  @Output() removeBlock = new EventEmitter();

  editTitle = false;
  form: UntypedFormGroup;
  collapse = true;
  showQuestions = true;
  fileToUpload: File;
  devices: any;
  questions: QuestionModel[] = [];
  oldBlockTitle: string;
  public loading = true;
  currentResearch: ResearchModel;

  // map related
  zoom: number;
  showMap = false;
  private geoCoder = new google.maps.Geocoder();
  timeEnd: {hour: number, minute: number};
  timeStart: {hour: number, minute: number};
  previousTimeStart: {hour: number, minute: number};
  mapInitialized = false;

  meridianDate = this.countryService.currentCountryUseMeridian();
  countryCode = 'IT';

  timeString = '';

  @ViewChild('search')
  public searchElementRef: ElementRef;


  datepickerEnd = false;

  constructor(
    private blockService: BlockService,
    private formBuilder: UntypedFormBuilder,
    public deviceService: DeviceService,
    private translateService: TranslateService,
    private toastService: ToastrService,
    private datetimeToDbPipe: DatetimeToDbPipe,
    private questionService: QuestionsService,
    private coreLayoutService: CoreLayoutService,
    private datetimeToDb: DatetimeToDbPipe,
    private ngZone: NgZone,
    private dateToHoursMinutesPipe: DateToHourMinutes,
    private researchSessionService: ResearchSessionService,
    private countryService: CountryService,
    private dateToLocalPipe: DateToLocaleStringPipe
  ) {
    super();
  }

  ngOnInit(): void {
    this.countryCode = this.countryService.getSessionCountry().country_code;
    this.currentResearch = this.researchSessionService.research;
    this.formGroup();
    this.devices = this.deviceService.devices;
    this.loadQuestions();
    this.coreLayoutService.sidebarOnClosed$.subscribe(blockid => {
      if (blockid === this.block.id) {
        this.loadQuestions();
      }
    });
    this.day = new Date(this.day);
    this.lastDay = new Date(this.lastDay);
    this.lastDay.setDate(this.lastDay.getDate() + 1);
    this.oldBlockTitle = this.block.title;

    this.timeString = this.dateToHoursMinutesPipe.transform(this.block.datetime_start, this.meridianDate);
  }

  isBlockEditable() {
    if (this.currentResearch.editable_in_the_past) {
      return true;
    }
    if (this.selectedDayIsFuture()) {
      return true;
    }
    return false;
  }

  toggleCollapse() {
    this.block.is_opened = !this.block.is_opened || true;
    this.collapse = !this.collapse;
  }

  selectedDayIsFuture() {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const selectedDate = new Date(this.day);
    if (selectedDate.getTime() >= now.getTime()) {
      return true;
    }
    return false;
  }

  toggleShowMap() {
    this.showMap = !this.showMap;
    if (this.showMap) {
      this.initMap();
    }
  }

  geolocationChange() {
    this.showMap = !this.showMap;
    this.block.has_geolocation = !this.block.has_geolocation;
    if (this.block.has_geolocation) {
      this.initMap();
    }
  }

  initMap() {
    if (!this.block.geolocation_lat) {
      this.setCurrentLocationMap();
    }
    this.geoCoder = new google.maps.Geocoder();
    if (this.searchElementRef) {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.getAddress(place.geometry.location.lat(), place.geometry.location.lng());
        });
      });
    } else {
      this.toastService.error(this.translateService.instant('ERROR_INITIALIZING_MAP_PLEASE_RELOAD'));
    }
  }

  moveMarker(event) {
    this.block.geolocation_lat = event.latLng.lat();
    this.block.geolocation_long = event.latLng.lng();
    this.getAddress(this.block.geolocation_lat, this.block.geolocation_long);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 15;
          this.block.geolocation_address = results[0].formatted_address;
          this.block.geolocation_lat = latitude;
          this.block.geolocation_long = longitude;
          this.form.controls.geolocation_address.setValue(this.block.geolocation_address);
          this.submitForm();
        } else {
          this.toastService.error(this.translateService.instant('NO_RESULTS'));
        }
      } else {
        this.toastService.error(this.translateService.instant('MAP_ERROR'));
      }

    });
  }

  setCurrentLocationMap() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.block.geolocation_lat = position.coords.latitude;
        this.block.geolocation_long = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }

  remove() {
    this.removeBlock.emit(this.block.id);
  }

  loadQuestions() {
    this.loading = true;
    this.questionService.getQuestionsByBlockId([this.block.id]).subscribe((res: ResponseModel) => {
      const temp = [];
      for (const question in res.getData()) {
        if (res.getData().hasOwnProperty(question)) {
          const element = res.getData()[question];
          temp.push(element);
        }
      }
      this.loading = false;
      this.questions = temp;
    });
  }

  addQuestion() {
    if (this.isBlockEditable()) {
      this.coreLayoutService.openSidebar(AddQuestionComponent, {
        country_uniqid: this.block.country_uniqid,
        block: this.block,
        currentDate: this.day,
        isEditableFromBlockData: this.isBlockEditable()
      });
    }
  }

  changeDateEnd(e) {
    const selectedDate = new Date(`${e.year}/${e.month}/${e.day}`);
    this.form.controls.date_end.setValue(this.dateToLocalPipe.transform(selectedDate.toString()));
    this.datepickerEnd = false;
    this.submitForm();
  }

  changeTimeEnd(e) {
    setTimeout(() => {
      this.timeEnd = e
      this.submitForm();
    });
  }

  formGroup() {
    const blockDate      = new Date(this.parseCompatibilityDate(this.block.datetime_end));
    const defaultEndDate = new Date(this.day);
    defaultEndDate.setDate(defaultEndDate.getDate());
    const endDate = ((blockDate.getTime() < new Date('1971-01-01').getTime() ? defaultEndDate : blockDate));

    const tempTimeEnd = new Date(this.parseCompatibilityDate(this.block.datetime_end));
    this.timeEnd = {hour: tempTimeEnd.getHours(), minute: tempTimeEnd.getMinutes()};

    const tempTimeStart = new Date(this.parseCompatibilityDate(this.block.datetime_start));
    this.timeStart = {hour: tempTimeStart.getHours(), minute: tempTimeStart.getMinutes()};
    this.previousTimeStart = this.timeStart;

    this.form = this.formBuilder.group({
      // tslint:disable: max-line-length
      datetime_start: new UntypedFormControl({value: this.parseTime(this.parseCompatibilityDate(this.block.datetime_start)), disabled: !this.isBlockEditable() }, Validators.nullValidator),
      date_end: new UntypedFormControl({value:endDate, disabled: !this.isBlockEditable()}, Validators.nullValidator),
      is_room_group: new UntypedFormControl({value: this.block.is_room_group, disabled: !this.isBlockEditable()}, Validators.nullValidator),
      device: new UntypedFormControl({value: this.block.device || 'ALL', disabled: !this.isBlockEditable()}, Validators.nullValidator),
      is_diary: new UntypedFormControl({value: this.block.is_diary, disabled: !this.isBlockEditable()}, Validators.nullValidator),
      is_highlighted: new UntypedFormControl({value: this.block.is_highlighted, disabled: !this.isBlockEditable()}, Validators.nullValidator),
      is_sequential_questions: new UntypedFormControl({value: this.block.is_sequential_questions, disabled: !this.isBlockEditable()}, Validators.nullValidator),
      image: new UntypedFormControl({value: this.block.image, disabled: !this.isBlockEditable()}, Validators.nullValidator),
      image_del: new UntypedFormControl({value: null, disabled: !this.isBlockEditable()}, Validators.nullValidator),
      has_geolocation: new UntypedFormControl({value: this.block.has_geolocation, disabled: !this.isBlockEditable()}, Validators.nullValidator),
      geolocation_lat: new UntypedFormControl({value: this.block.geolocation_lat, disabled: !this.isBlockEditable()}, Validators.nullValidator),
      geolocation_long: new UntypedFormControl({value: this.block.geolocation_long, disabled: !this.isBlockEditable()}, Validators.nullValidator),
      geolocation_address: new UntypedFormControl({value: this.block.geolocation_address, disabled: !this.isBlockEditable()}, Validators.nullValidator),
      title: new UntypedFormControl({value: this.block.title, disabled: !this.isBlockEditable()}, Validators.nullValidator),
      country_uniqid: new UntypedFormControl({value: this.block.country_uniqid, disabled: !this.isBlockEditable()}, Validators.nullValidator),
      roomid: new UntypedFormControl({value: this.block.roomid, disabled: !this.isBlockEditable()}, Validators.nullValidator)
    });
  }

  removeImage() {
    this.fileToUpload = null;
    this.block.image_url = null;
    this.form.controls.image_del.setValue(true);
    this.submitForm();
  }

  checkDiary() {
    const isDiary = this.form.value.is_diary;
    if (isDiary) {
      this.form.controls.is_room_group.setValue(false);
    }
  }


  changeTimeStart(e) {
    setTimeout(() => {
      const dateCheck = new Date();
      dateCheck.setMilliseconds(0);
      const date = this.day;
      date.setHours(e.hour, e.minute, e.second, 0);
      this.previousTimeStart = angular.copy(this.timeStart);
      this.timeString = this.dateToHoursMinutesPipe.transform(date, this.meridianDate);
      this.form.controls.datetime_start.setValue(date);
      this.submitForm();
    });
  }

  submitForm() {
    this.editTitle = false;
    const formValue = this.formatForm(this.form.value);
    if (this.form.valid) {
      Object.keys(formValue).forEach((key) => (!formValue[key] && formValue[key] !== false) && delete formValue[key]);

      this.blockService.postFile(`blocks/${this.block.id}`, this.fileToUpload, 'image', formValue).subscribe((res: ResponseModel) => {
        this.block = res.getData()[0];
        this.formGroup();
      }, (error => {
        const errObj = error.error.errors;
        for (const key in errObj) {
          if (errObj.hasOwnProperty(key)) {
            const err = errObj[key].toUpperCase();

            if (err === 'DATE_END_IS_PAST') {
              this.toastService.error(this.translateService.instant('DATE_IS_PAST'));
              this.formGroup();
            } else {
              this.toastService.error(this.translateService.instant('ERRORS.' + err));
            }

          }
        }
      }));

    }
  }

  uploadImage(files: FileList) {
    this.fileToUpload = files.item(0);
    this.submitForm();
  }

  updateBlock() {
    this.editTitle = false;
  }

  duplicateBlock() {
    const formValue = this.formatForm(this.form.value);
    this.blockService.duplicateBlock(this.block).subscribe((res: ResponseModel) => {
      this.updateBlocks.emit(res.getData()[0]);
    });
  }

  formatForm(formValue) {
    delete formValue.image;
    formValue.title = angular.copy(this.block.title);
    const newStartDate = new Date(this.parseCompatibilityDate(this.block.datetime_start));
    newStartDate.setHours(this.timeStart.hour);
    newStartDate.setMinutes(this.timeStart.minute);

    let dateEnd = formValue.date_end;
    if (dateEnd && typeof dateEnd === 'string') {
      const parts = dateEnd.split('/')
      dateEnd = (dateEnd.indexOf('/') != 2) ? `${parts[0]}-${parts[1]}-${parts[2]}` : `${parts[2]}-${parts[1]}-${parts[0]}`;
    }
    const newEndDate = new Date(dateEnd);
    newEndDate.setHours(this.timeEnd.hour);
    newEndDate.setMinutes(this.timeEnd.minute);

    formValue.datetime_start   = this.datetimeToDbPipe.transform(newStartDate, this.countryService.getSessionCountry().timezone);
    formValue.datetime_end     = this.datetimeToDbPipe.transform(newEndDate, this.countryService.getSessionCountry().timezone);
    formValue.geolocation_lat  = this.block.geolocation_lat;
    formValue.geolocation_long = this.block.geolocation_long;

    return formValue;
  }

  changeQuestionsOrder(e: CdkDragDrop<string[]>) {
    if (this.isBlockEditable()) {
      const temp = this.arrayMove(this.questions, e.previousIndex, e.currentIndex);
      this.questions = temp;
      this.questionService.reorderQuestions(this.questions, this.block.id).subscribe((res: ResponseModel) => {
        this.toastService.success('OK');
      });
    }
  }

  parseTime(d) {
    const datetime = new Date(d);
    const parsed = ('0' + datetime.getHours()).slice(-2) + ':' +
    ('0' + datetime.getMinutes()).slice(-2);
    return parsed;
  }

}
