
<app-core-spinner [toggle]="loadingfilter"></app-core-spinner>
<form *ngIf="filterForm" [hidden]="isSmart">
    <h4>{{ 'FILTER_DATA' | translate }}</h4>
    <section [formGroup]="filterForm" *ngIf="!loading">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group tool tags-select-tool w-100">
                    <app-core-select
                        *ngIf="rooms.length"
                        [classes]="'form-control'"
                        [placeholder]="'ROOM.ROOM'"
                        [cache]="true"
                        [form]="filterForm"
                        [formEntityName]="'room'"
                        (change)="roomChange($event)"
                        [searchable]="true"
                        [multiple]="true"
                        [readonly]="!rooms.length || blocked.rooms"
                        [customList]="rooms"
                        [label]="'title'"
                        >
                    </app-core-select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group" *ngIf="daysReady">
                    <app-core-select
                        [classes]="'form-control'"
                        [placeholder]="'DAY'"
                        [cache]="true"
                        [form]="filterForm"
                        [formEntityName]="'block_datetime'"
                        [customList]="days"
                        [empty]="{date: null, name: '---'}"
                        [readonly]="filterForm.value.block ? filterForm.value.block.length ? true : filterForm.value.question ? true : false : filterForm.value.question ? true : false || !days.length || blocked.days"
                        [label]="'name'"
                        [resetButton]="!blocked.days"
                        (change)="dayChange($event)"
                        >
                    </app-core-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group tool tags-select-tool w-100">
                    <app-core-select
                        [classes]="'form-control'"
                        [placeholder]="'BLOCK.BLOCK_WITH_INFO'"
                        [cache]="true"
                        [multiple]="true"
                        [customList]="blocks"
                        [label]="'title'"
                        [form]="filterForm"
                        [readonly]="filterForm.value.question || !blocks.length || blocked.blocks || !filterForm.value.room"
                        [formEntityName]="'block'"
                        [searchable]="true"
                        (change)="blockChange($event)"
                        >
                    </app-core-select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <app-core-select
                        [classes]="'form-control'"
                        [placeholder]="'QUESTION'"
                        [cache]="true"
                        [resetButton]="!blocked.question"
                        [form]="filterForm"
                        [formEntityName]="'question'"
                        [customList]="questions"
                        [readonly]="!questions.length || blocked.question"
                        [searchable]="true"
                        [label]="'title'"
                        (change)="questionSelected($event)"
                        >
                    </app-core-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group tool tags-select-tool w-100">
                    <app-core-select
                        [classes]="'form-control'"
                        [placeholder]="'INTERVIEWEDS.INTERVIEWEDS'"
                        [cache]="true"
                        [readonly]="!intervieweds.length"
                        [label]="'nickname'"
                        [multiple]="true"
                        [customList]="intervieweds"
                        [form]="filterForm"
                        [searchable]="true"
                        (change)="interviewedsToggleChange($event)"
                        >
                    </app-core-select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group tool tags-select-tool w-100">
                    <app-core-select
                        [classes]="'form-control'"
                        [placeholder]="'SAMPLE_VARIABLE_TAGS'"
                        [cache]="true"
                        [readonly]="!sampleVariableTags.length || blocked.sample_variable_tags"
                        [multiple]="true"
                        [customList]="sampleVariableTags"
                        [form]="filterForm"
                        [formEntityName]="'sample_variable_tags'"
                        [searchable]="true"
                        (change)="sampleTagsChange($event)"
                        >
                    </app-core-select>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group tool tags-select-tool w-100">
                    <app-core-select
                        [classes]="'form-control'"
                        [placeholder]="'PERSONAS_SHORT'"
                        [form]="filterForm"
                        [formEntityName]="'personas'"
                        [multiple]="true"
                        [customList]="personasTags"
                        [searchable]="true"
                        (change)="personasTagsChange($event)"
                        >
                    </app-core-select>
                </div>
            </div>
            <!-- new line -->
            <div class="col-2" [ngClass]="{'empty': !contentTagsFilter}">
                <div class="form-group tool tags-select-tool w-100" *ngIf="contentTagsFilter">
                    <app-core-select
                        [classes]="'form-control'"
                        [placeholder]="'CONTENT_TAGS'"
                        [cache]="true"
                        [readonly]="!contentTags.length"
                        [multiple]="true"
                        [customList]="contentTags"
                        [form]="filterForm"
                        [formEntityName]="'answer_tags'"
                        [searchable]="true"
                        (change)="contentTagsChange($event)"
                        >
                    </app-core-select>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group tool tags-select-tool w-100" >
                    <input type="text" formControlName="keyword_search_content" class="form-control" (change)="keywordToggleChange($event)">
                    <span class="placeholder">{{ 'SEARCH' | translate }}</span>
                </div>
            </div>
            <div class="col-2" [ngClass]="{'empty': !color}">
                <div *ngIf="color">
                    <div>
                        <div>
                            <label>{{ 'COLOR' | translate }}</label>
                        </div>
                        <div class="color-radios">
                            <input class="d-none" (change)="colorChange()" type="checkbox" formControlName="color_red" id="checkbox-1" name="color" value="red">
                            <label for="checkbox-1" class="red-dark"></label>
                        </div>
                        <div class="color-radios">
                            <input class="d-none" (change)="colorChange()" type="checkbox" formControlName="color_orange" id="checkbox-2" name="color" value="orange">
                            <label for="checkbox-2" class="orange-dark"></label>
                        </div>
                        <div class="color-radios">
                            <input class="d-none" (change)="colorChange()" type="checkbox" formControlName="color_yellow" id="checkbox-3" name="color" value="yellow">
                            <label for="checkbox-3" class="yellow-dark"></label>
                        </div>
                        <div class="color-radios">
                            <input class="d-none" (change)="colorChange()" type="checkbox" formControlName="color_green" id="checkbox-4" name="color" value="green">
                            <label for="checkbox-4" class="green-dark"></label>
                        </div>
                        <div class="color-radios">
                            <input class="d-none" (change)="colorChange()" type="checkbox" formControlName="color_azure" id="checkbox-5" name="color" value="azure">
                            <label for="checkbox-5" class="azure-dark"></label>
                        </div>
                        <div class="color-radios">
                            <input class="d-none" (change)="colorChange()" type="checkbox" formControlName="color_grey" id="checkbox-6" name="color" value="grey">
                            <label for="checkbox-6" class="null-dark"></label>
                        </div>
                    </div>
                    <div>
                        <span *ngIf="filterForm.value.color"
                            (click)="clearFilter('color')">
                            Reset
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-1" [ngClass]="{'empty': !star}">
                <div *ngIf="star" class="star-value">
                    <div>
                        <label>{{ 'RATING' | translate }}</label>
                    </div>
                    <ng-template #starsPopover>
                        <div><i (click)="rated(1)" class="fal fa-star star-red"></i></div>
                        <div><i (click)="rated(2)" class="fal fa-star star-orange"></i></div>
                        <div><i (click)="rated(3)" class="fal fa-star star-green"></i></div>
                    </ng-template>
                    <i class="fal fa-star"
                        [ngClass]="{'star-red': filterForm.value.rating == 1, 'star-orange': filterForm.value.rating == 2, 'star-green': filterForm.value.rating == 3}"
                        [autoClose]="'outside'"
                        [popoverClass]="'stars-popover'"
                        [ngbPopover]="starsPopover">
                    </i>
                    <a *ngIf="filterForm.value.rating" class="ml-1" (click)="rated(null)">Reset</a>
                </div>
            </div>
            <div class="col-1">
                <div *ngIf="highlighted">
                    <div>
                        <label>{{ 'BLOCK.IS_HIGHLIGHTED' | translate }}</label>
                    </div>
                    <label class="switch mr-1">
                        <input type="checkbox" formControlName="only_highlighted" (change)="toggleChange($event)">
                        <span class="slider round"></span>
                    </label>
                </div>
            </div>
            <!-- <div class="col-1 disabled">
                <div>
                    <div>
                        <label>{{ 'CONTEST_BLOCK' | translate }}</label>
                    </div>
                    <label class="switch mr-1">
                        <input type="checkbox" formControlName="only_contest" (change)="toggleChange($event)">
                        <span class="slider round"></span>
                    </label>
                </div>
            </div>
            <div class="col-1 disabled">
                <div>
                    <div>
                        <label>{{ 'LIVE_BLOCK' | translate }}</label>
                    </div>
                    <label class="switch mr-1">
                        <input type="checkbox" formControlName="only_live" (change)="toggleChange($event)">
                        <span class="slider round"></span>
                    </label>
                </div>
            </div> -->
        </div>
    </section>
</form>