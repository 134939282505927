import { Component, OnInit, Input } from '@angular/core';
import { RoomModel } from '@app/shared/models/room.model';
import { RoomsService } from '@app/shared/services/rooms.service';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { TeamService } from '@app/shared/services/team.service';
import { TeamModel } from '@app/shared/models/team.model';
import { ResponseModel } from '@app/core/models/response.model';
import { CountryModel } from '@app/shared/models/country.model';
import { CountryService } from '@app/shared/services/country.service';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { TranslateService } from '@ngx-translate/core';
import { DatetimeToDbPipe } from '@app/core/pipes/datetime-to-db.pipe';

@Component({
  selector: 'app-activate-user-in-room',
  templateUrl: './activate-user-in-room.component.html',
  styleUrls: ['./activate-user-in-room.component.scss']
})
export class ActivateUserInRoomComponent extends CoreBaseComponent implements OnInit {

  @Input() room: RoomModel;
  @Input() oneRoomOnly: boolean;

  constructor(
    private roomService: RoomsService,
    public coreLayoutService: CoreLayoutService,
    private teamService: TeamService,
    private countryService: CountryService,
    private datetimeToDbPipe: DatetimeToDbPipe,
    private translateService: TranslateService
  ) {
    super();
  }

  users: TeamModel[];
  allUsers: TeamModel[];
  columns: any[];
  country: CountryModel;


  ngOnInit(): void {
    this.loadUsers();
  }

  loadUsers() {
    this.teamService.getUsers({
      country_uniqid: this.room.country_uniqid,
      roomid: this.room.id,
    }).subscribe((res: ResponseModel) => {
      this.users = res.getData();


      this.countryService.getCountrySetupInfo(this.room.country_uniqid).subscribe((resCountry: ResponseModel) => {
        this.country = resCountry.getData();

        this.columns = [this.translateService.instant('ALL'), ...this.country.sample_variable_tags];
      });
    });
  }

  setVariableTags(e) {
    if (e === this.translateService.instant('ALL')) {
      this.room.sample_variable_tags = [];
    } else {
      this.room.sample_variable_tags = [e];
    }
    this.updateRoom();
  }

  columnClick(e) {
    // if (e === 'fal fa-square') {
    //   this.selectDeselectUsers();
    // }
  }

  updateRoom() {
    const room = this.room
    room.date_start = this.datetimeToDbPipe.transform(room.date_start, this.countryService.getSessionCountry().timezone);
    room.date_end = this.datetimeToDbPipe.transform(room.date_end, this.countryService.getSessionCountry().timezone);
    this.roomService.updateRoom(room).subscribe((res: ResponseModel) => {
      this.room = res.getData()[0] || res.getData();
      this.loadUsers();
    });
  }

}
