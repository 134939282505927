import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ResearchService } from '@app/shared/services/research.service';
import { ResearchModel } from '@app/shared/models/research.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { ResponseModel } from '@app/core/models/response.model';
import { TranslateService } from '@ngx-translate/core';
import { CountryService } from '@app/shared/services/country.service';
import { ToolbarService } from '@app/core/services/toolbar.service';
import { LogoComponent } from '@app/shared/components/logo/logo.component';
import { ResearchSessionService } from '@app/shared/services/research-session.service';
import { count } from 'console';
import { TeamService } from '@app/shared/services/team.service';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { DatetimeFromDB } from '@app/core/pipes/datetime-from-db.pipe';
import { StorageService } from '@app/core/services/storage.service';

@Component({
  selector: 'app-private-researches-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent extends CoreBaseComponent implements OnInit {

  constructor(
    private researchService: ResearchService,
    private ar: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    public countryService: CountryService,
    private toolbarService: ToolbarService,
    public teamService: TeamService,
    public researchSession: ResearchSessionService,
    private coreLayoutService: CoreLayoutService,
    private datetimeFromDB: DatetimeFromDB,
    private storageService: StorageService
  ) {
    super();
  }



  uniqid: string;
  research: ResearchModel;
  countryUniqid: string;
  section: string;
  countryNames: string[];
  public isCustomerPro = false;

  ngOnInit(): void {
    this.coreLayoutService.classes$.next('customer-pro customer-basic');
    this.teamService.user$.subscribe(data => {
      if (data) {
        this.isCustomerPro = this.teamService.isCurrentUserCustomerPro();
        if (this.isCustomerPro) {
          this.coreLayoutService.classes$.next('customer-pro');
        } else if (this.teamService.isCurrentUserCustomerBasic()) {
          this.coreLayoutService.classes$.next('customer-basic');
        } else {
          this.coreLayoutService.classes$.next('');
        }
      }
    });
    this.countryUniqid = this.ar.snapshot.paramMap.get('country_uniqid');

    if (!this.teamService.currentUser) {
      this.teamService.getCurrentUserTeam(this.countryUniqid).subscribe((res: ResponseModel) => {
        this.teamService.currentUser = res.getData();
      });
    }
    this.loadCountry();

    this.researchSession.events.forEach(event => {
      if (event === 'country_updated') {
        this.loadCountry();
      }
    });

    this.loadPath()
  }

  savePath(pathArr) {
    this.storageService.set(this.countryUniqid + '_navigation', pathArr)
  }

  loadPath() {
    const pathArr = this.storageService.get(this.countryUniqid + '_navigation')
    if (pathArr) {
      this.router.navigate(pathArr)
    }
  }

  loadCountry(fromCache = false) {
    this.loading = true;
    // Get and save research references
    this.uniqid = this.ar.snapshot.paramMap.get('research_uniqid');
    this.researchSession.setResearchUniqId(this.uniqid);
    this.researchSession.setResearchCountryUniqId(this.countryUniqid);
    if (fromCache) {
      this.research = this.researchSession.research;
      this.getCountryNames();
      for (const country of this.research.countries) {
        country.translated = this.translateService.instant(country.country_code.toUpperCase()) + ' ' + country.timezone;
      }
    } else {
      this.researchService.getResearchByUniqid(this.uniqid).subscribe((res: ResponseModel) => {
        const researchTemp = res.getData();
        for (const country of researchTemp.countries) {
          country.translated = this.translateService.instant(country.country_code.toUpperCase()) + ' ' + country.timezone;
        }
        this.research = this.researchSession.setResearch(researchTemp);
        this.getCountryNames();
        this.loading = true;
      });
    }
    this.countryService.getCountrySetupInfo(this.countryUniqid).subscribe((res: ResponseModel) => {
      const country = res.getData();
      this.countryService.setSessionCountry(country);
      if(country.isClientBasic()) {
        this.router.navigate(['/researches/navigation', this.uniqid, this.countryUniqid, 'analysis']);
      }
      if (country.color) {
        this.changeCssVariable('--orange', country.color);
      }
      this.toolbarService.logo(LogoComponent, {customClass: 'logo-internal', customLogoUrl: country.logo_url});
    });
  }

  getCountryNames() {
    const countryNames = [];
    for (const c of this.research.countries) {
      countryNames.push(this.translateService.instant(c.country_code.toUpperCase()));
    }
    this.countryNames = countryNames;
  }

  changeCountry(e) {
    this.countryUniqid = e.country_uniqid;
    this.router.navigate(['/researches/navigation', this.uniqid, this.countryUniqid, 'setup']);
    // this.loadCountry();
    window.location.href = `/#/researches/navigation/${this.uniqid}/${this.countryUniqid}/setup`;
    window.location.reload();
  }

}
