<app-shared-sidebar-container *ngIf="country" >
    <app-shared-sidebar-header>
        <div slot="title">
            <h2 class="mb-0">{{ 'PRIVACY_POLICY' | translate }}</h2>
        </div>
        <div slot="buttons" class="text-right">
            <button class="btn-sicche" (click)="save()">{{ 'SAVE' | translate }}</button>
        </div>
    </app-shared-sidebar-header>
    <div class="sidebar-body">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    {{ 'PRIVACY_POLICY_EDIT_HEADER' | translate }}
                </div>
            </div>
            <div class="row">
                <div class="col-12 my-3">
                    <button (click)="loadDefault()" class="btn-sicche btn-secondary">{{ 'LOAD_DEFAULT_TEXT' | translate }}</button>
                    <span class="info-tooltip" placement="top" [ngbTooltip]="'TOOLTIPS.LOAD_DEFAULT_PRIVACY' | translate">
                        <i class="far fa-info-circle"></i>
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 panel shadow">
                    <quill-editor [(ngModel)]="country.privacy_html" [modules]="modules" [styles]="{height: '400px'}" [placeholder]="'INSERT-TEXT' | translate"></quill-editor>
                </div>
            </div>
            <div class="row">
                <div class="col-6 mt-3">
                    <label for="saveAsDefault">
                        {{ 'SAVE_AS_DEFAULT_LABEL' | translate }}
                        <input
                        id="saveAsDefault"
                        type="checkbox"
                        [(ngModel)]="saveAsDefault"
                        [ngModelOptions]="{standalone: true}"/> `
                    </label>
                </div>

            </div>
        </div>
    </div>
</app-shared-sidebar-container>