import { Pipe, PipeTransform } from '@angular/core';
import { StorageService } from '../services/storage.service';

@Pipe({
    name: 'privatesrc'
})

export class PrivateSrcPipe implements PipeTransform {
    constructor(private storageService: StorageService) {

    }
    transform(value: string): any {
        // i file sono su s3, non più necessario
        return value;
        if (value) {
            const phpsessid = this.storageService.get('phpsessid');
            return `${value}?PHPSESSID=${phpsessid}`;
        }
        return '';
    }
}
