<div *ngIf="!exportableVersion" id="answer-{{ answer.id }}-short">
    <div class="answer panel shadow p-0" id="answer-{{ answer.id }}">
            <div class="panel-header d-flex align-items-center justify-content-between">
                <div class="d-flex pr-3">
                    <div class="mr-2 d-flex align-items-center">
                        <app-shared-avatar [imageUrl]="answer.team.avatar_url"></app-shared-avatar>
                    </div>
                    <div>
                        <ng-template #tipContent>
                            <div [innerHTML]="answer.question_title"></div>
                        </ng-template>
                        <div class="bold mb-0">{{ answer.team.nickname ? answer.team.nickname : answer.team.firstname + ' ' + answer.team.lastname }}</div>
                        <div class="mb-0 d-flex align-items-center"><span class="line-clamp-2">{{ parseCompatibilityDate(answer.creationtime) | dateToLocaleString: true }} - {{ 'ROOM.ROOM' | translate }} {{ answer.question_data.room_title }} - {{ 'BLOCK.BLOCK' | translate }} {{ answer.question_data.block_title }} - </span>
                            <a class="as-link" [ngbTooltip]="tipContent" triggers="click:blur" [autoClose]="'outside'">{{ 'QUESTION' | translate }}</a>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-start">
                    <div [ngbTooltip]="answer.team.sample_variable_tags?.join(', ')" class="tool-btn mr-5">
                        <i class="fal fa-tag mr-2 text-primary-color"></i>
                        <span [ngClass]="{'with-data': answer.team.sample_variable_tags?.join(', ')}">Tags</span>
                    </div>
                    <div class="tool-btn mr-5 tags-select-tool">
                        <span>
                            <ng-template #personasPopover>
                                <app-core-select
                                    *ngIf="personasForm"
                                    [classes]="'form-control'"
                                    [form]="personasForm"
                                    [addTag]="true"
                                    [formEntityName]="'personas_tags'"
                                    [preselected]="{byprop: getPersonasTag(answer.team.personas_tags), value: getPersonasTag(answer.team.personas_tags)}"
                                    (change)="personasTagsChange($event)"
                                    [searchable]="true"
                                    [customList]="getAllPersonasTags()"
                                    >
                                </app-core-select>
                            </ng-template>
                            <span [autoClose]="'outside'" [ngbPopover]="personasPopover" [popoverTitle]="'SELECT_PERSONAS' | translate">
                                <i  class="fal fa-portrait mr-2 text-primary-color"></i>
                                <span [ngClass]="{'': getPersonasTag(answer.team.personas_tags)}">


                                    {{ (
                                        getPersonasTag(answer.team.personas_tags)?.length && getPersonasTag(answer.team.personas_tags)?.toString().toLowerCase()
                                            ? getPersonasTag(answer.team.personas_tags).join(', ').toLowerCase()
                                            : 'personas'
                                    ) }}
                                </span>
                            </span>
                        </span>
                    </div>
                    <div class="tool-btn mr-5">
                        <ng-template #notesPopover>
                            <textarea [(ngModel)]="answer.team.note"  class="form-control">{{ answer.team.note ? answer.team.note : null }}</textarea>
                            <button class="btn btn-sicche btn-secondary mx-3" (click)="notesChanged()">
                                <span *ngIf="operation('saving-note').submitting" class="spinner-border spinner-border-sm" role="status">
                                    <span class="sr-only">Loading...</span>
                                </span>
                                {{ 'SAVE' | translate }}
                            </button>
                        </ng-template>
                        <i class="fal fa-sticky-note mr-2 text-primary-color"></i>
                        <span [ngbPopover]="notesPopover" [ngClass]="{'with-data': answer.team.note}"
                            [ngbTooltip]="answer.team.note"
                            [autoClose]="'outside'" [popoverTitle]="'NOTA' | translate">{{ 'NOTE_S' | translate }}</span>
                    </div>
                </div>
                <div class="right ml-3 d-flex align-items-center">
                    <div class="form-group mb-0">
                        <div>
                            <input (change)="changeAnswerStatus($event, answer)" [(ngModel)]="answer.state.selected" type="checkbox" name="" class="form-control">
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel-body">
                <app-moderation-answerdispatcher
                    (textSelection)="$textSelection({answer: answer})"
                    *ngIf="answer.question_type != 'open-answer'" [item]="answer" [question]="answer.question_data"
                ></app-moderation-answerdispatcher>
                <app-moderation-answerstype-open
                    (textSelection)="$textSelection({answer: answer})"
                    *ngIf="answer.question_type == 'open-answer'"
                    [answer]="answer"
                    [question]="answer.question_data">
                </app-moderation-answerstype-open>
            </div>
            <div class="panel-footer">
                <div class="row">
                    <div class="col-xl-6 d-flex align-items-start justify-content-start">
                        <div class="tool tag-tool text-nowrap mt-2">
                            <i class="far fa-tag text-primary-color"></i>
                            <span>{{ 'CONTENT_TAG' | translate }}</span>
                        </div>
                        <div class="tool tags-select-tool w-100 px-2">
                            <app-core-select
                                *ngIf="allAnswerTags"
                                [classes]="'form-control'"
                                [form]="contentTagsForm"
                                [formEntityName]="'content_tags'"
                                [searchable]="true"
                                [addTag]="true"
                                (change)="answerTagsChanged($event, answer)"
                                [customList]="allAnswerTags"
                                [multiple]="true"
                                >
                            </app-core-select>

                        </div>
                        <div class="tool favourites-tool stars flex-grow-1 mt-1">
                            <app-moderation-starsrating [answer]="answer"></app-moderation-starsrating>
                        </div>
                    </div>
                    <div class="col-xl-6 d-flex align-items-start justify-content-start">
                        <div style="min-width: 50px;" class="tool like-tool ml-auto">
                            <i class="fal fa-heart" [ngClass]="{'favourite-yes': answer.currentuser_like_answer}"></i>
                            <span class="total_answer_like" *ngIf="answer.total_like_to_answer">({{ answer.total_like_to_answer }})</span>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</div>





<ng-container *ngIf="exportableVersion">
    <div  id="answer-exportable-{{ answer.id }}">
        <div class="answer panel shadow p-0">
            <div class="panel-header d-flex align-items-center justify-content-between h-auto" style="border-bottom: 1px solid #D0D3DF">
                <div class="d-flex pr-3">
                    <div class="mr-2">
                        <app-shared-avatar [imageUrl]="answer.team.avatar_url"></app-shared-avatar>
                    </div>
                    <div>
                        <div class="bold mb-0">{{ answer.team.nickname ? answer.team.nickname : answer.team.firstname + ' ' + answer.team.lastname }}</div>
                        <div class="mb-3">{{ parseCompatibilityDate(answer.creationtime) | date: 'd/MM/yy' }} - Ore {{ parseCompatibilityDate(answer.creationtime) | date: 'HH:mm' }} - <b>{{ 'ROOM.ROOM' | translate }}:</b> {{ answer.question_data.room_title }} - <b>{{ 'BLOCK.BLOCK' | translate }}:</b> {{ answer.question_data.block_title }}
                            - <span><b>{{ 'QUESTION' | translate }}:</b> {{ answer.question_title }}</span>
                        </div>
                        <div><b>Tags:</b> {{ answer.team.sample_variable_tags ? answer.team.sample_variable_tags.join(', ') : '--' }}</div>
                        <div><b>Personas:</b> {{ getPersonasTag(answer.team.personas_tags) ? getPersonasTag(answer.team.personas_tags).length ? getPersonasTag(answer.team.personas_tags).join(', ') : '--' : '--' }}</div>
                        <div><b>{{ 'NOTE_S' | translate }}:</b> {{ answer.team.note ? answer.team.note : '--' }}</div>
                    </div>
                </div>
            </div>
            <div class="panel-body">
                <app-moderation-answerdispatcher  *ngIf="answer.question_type != 'open-answer'" [item]="answer" [question]="answer.question_data"></app-moderation-answerdispatcher>
                <app-moderation-answerstype-open
                    *ngIf="answer.question_type == 'open-answer'"
                    [answer]="answer"
                    [question]="answer.question_data">
                </app-moderation-answerstype-open>
            </div>
            <div class="panel-footer">
                <div class="row">
                    <div class="col-xl-6 d-flex align-items-start justify-content-start">
                        <div class="tool tag-tool text-nowrap">
                            <span>{{ 'CONTENT_TAG' | translate }}: </span>
                        </div>
                        <div class="tool tags-select-tool w-100 px-2">
                            {{ answer.answer_tags ? answer.answer_tags.length ? answer.answer_tags.join(', ') : '--' : '--' }}
                        </div>
                    </div>
                    <div class="col-xl-6 d-flex align-items-start justify-content-start">
                        <div style="min-width: 50px;" class="tool like-tool ml-auto d-flex">
                            <div style="margin-top: 4px;">
                                <app-moderation-starsrating [exportableVersion]="exportableVersion" [minimal]="false" [answer]="answer"></app-moderation-starsrating>
                            </div>
                            <div class="star-value  ml-2">
                                <span class="html-star" style="color: red;">
                                    <i class="fal fa-heart" *ngIf="!answer.total_like_to_answer"></i>
                                    <i class="fas fa-heart" *ngIf="answer.total_like_to_answer"></i>
                                </span>
                            </div>
                            <span class="total_answer_like" *ngIf="answer.total_like_to_answer">({{ answer.total_like_to_answer }})</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<!-- #ver. screenshot -->