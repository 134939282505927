import {Component, ElementRef, ViewChild, ViewEncapsulation, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router, ActivatedRoute} from '@angular/router';
import { ModalService } from '@app/core/services/modal.service';
import { ResetPasswordModalComponent } from './modals/reset-password/reset-password-modal.component';

@Component({
  selector: 'app-authentication',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  public tab = 1;
  public activated = false;

  constructor(private translate: TranslateService, private modalService: ModalService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.activated = params.activated;
      if (params.reset_password_token) {
        this.modalService.open({modalModel: ResetPasswordModalComponent, dialogSize: 'lg'}, {token: params.reset_password_token});
      }
      if (params.signup) {
        this.tab = 2;
      }
    });
  }

  toggleCreateAccount(tab) {
    this.tab = tab;
  }

}
