import { BaseModel } from './base';

/**
 * Auth Model
 */
export class AuthModel extends BaseModel  {

  static getResourceName() {
    return 'auth';
  }

}
