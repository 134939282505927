<div class="row">
    <div class="col-12 mb-3" [innerHTML]="'MESSAGES_SECTION.DESCRIPTION' | translate"></div>
</div>
<div class="row mt-3">
    <div class="col-6 d-flex align-items-center">
        <h3>
            <span class="clickable" [ngClass]="!showTemplateMessages ? 'text-custom-sicche' : ''" (click)="showTemplateMessages = false">{{('MESSAGES_SECTION.SENT_MESSAGES' | translate) | uppercase}}: {{messagesCount}} </span> /
            <span class="clickable" [ngClass]="showTemplateMessages ? 'text-custom-sicche' : ''" (click)="showTemplateMessages = true" *ngIf="templateMessages">{{('TEMPLATE_MESSAGES' | translate) | uppercase}}: {{templateMessages.length}}</span>
        </h3>
    </div>
    <div class="col-6 text-right" *ngIf="!showTemplateMessages">
        <button class="btn-sicche btn-secondary" (click)="showFilters = !showFilters"><i class="far fa-filter"></i> {{'MESSAGES_SECTION.SHOW_FILTERS' | translate}}</button>
        <button class="btn-sicche" (click)="newMessage()">{{'MESSAGES_SECTION.CREATE_NEW' | translate}}</button>
    </div>
</div>
<div class="row mt-3" *ngIf="showFilters && !showTemplateMessages">
    <div class="col-6">
        <div class="form-group">
            <app-core-select
            *ngIf="users"
            [classes]="'form-control'"
            [multiple]="false"
            [cache]="true"
            [resetButton]="true"
            [customList]="users"
            [label]="'nicknameAndEmail'"
            [searchable]="true"
            (change)="setUser($event)"
            >
            </app-core-select>
            <span class="placeholder">{{'EMAIL_OR_NICKNAME' | translate}}</span>
        </div>

        <ng-template #emailTemplate let-item>
            <a (click)="setUser(item)" [innerHTML]="item.nickname + ' ' + item.email"></a>
        </ng-template>
        <ng-template #notFoundTemplate let-notFound>
            <div>{{'NOT_FOUND' | translate}}</div>
        </ng-template>
    </div>
    <div class="col-6">
        <div class="form-group">
            <app-core-select
            [classes]="'form-control'"
            [multiple]="true"
            [cache]="true"
            [customList]="country.sample_variable_tags"
            [label]="'name'"
            [value]="'value'"
            [searchable]="true"
            (change)="setVariableTags($event)"
            >
            </app-core-select>
            <span class="placeholder">{{'SAMPLE_VARIABLE_TAGS' | translate}}</span>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12 panel shadow mt-3" *ngIf="!showTemplateMessages">
        <app-core-spinner class="mt-3 mb-2" [toggle]="loadingMessages"></app-core-spinner>
        <div class="position-relative">
            <div class="table-responsive">
                <app-core-table
                *ngIf="messages"
                [items]="messages"
                [columns]="[{name: 'MESSAGES_SECTION.RECIPIENTS', class:'text-left'}, {name: 'MESSAGES_SECTION.SUBJECT', class:'text-left'}, {name: 'MESSAGES_SECTION.DATETIME', class:'text-left'}, {name: ''}]"
                tableid="'messages'"
                [perPage]="999999"
                [rowTemplate]="rowTemplate"
                >
                </app-core-table>
                <ng-template let-item="item" #rowTemplate>
                    <tr>
                        <td (click)="editMessage(item)" style="width:50%;" class="pointer clickable">
                            <span *ngIf="item.other_data && item.other_data.recipient_user_firstname">{{item.other_data.recipient_user_firstname}}</span>
                            <span *ngIf="item.other_data && item.other_data.recipient_room">
                                <span>
                                    {{item.other_data.recipient_room.title}}
                                </span>
                            </span>
                            <span *ngIf="item.other_data && item.other_data.recipient_filters" >
                                <span *ngIf="isString(item.other_data.recipient_filters)">
                                    {{'MESSAGES_SECTION.' + item.other_data.recipient_filters | translate}}
                                </span>
                                <span *ngIf="!isString(item.other_data.recipient_filters) && item.other_data.recipient_filters.value[0]">
                                    {{'MESSAGES_SECTION.' + item.other_data.recipient_filters.value[0].text | translate}} {{item.other_data.recipient_filters.value[0].new_value || ''}}
                                </span>
                            </span>
                            <span *ngIf="item.other_data && item.other_data.recipient_variable_tags && item.other_data.recipient_variable_tags.length > 0" >
                                <span *ngFor="let tag of item.other_data.recipient_variable_tags">
                                    {{tag}}
                                </span>
                            </span>
                            <span *ngIf="
                            item.other_data
                            && !item.other_data.recipient_filters
                            && !item.other_data.recipient_user_firstname
                            && (!item.other_data.recipient_room || item.other_data.recipient_room.length == 0)
                            && (!item.other_data.recipient_variable_tags || item.other_data.recipient_variable_tags.length == 0)">
                                {{'MESSAGES_SECTION.ALL' | translate}}
                            </span>
                            <span *ngIf="!item.other_data">
                                {{printNames(item.user_data)}}
                            </span>
                        </td>
                        <td style="width:25%;">
                            {{item.subject}}
                        </td>
                        <td style="width:25%;">
                            {{item.date_send | dateToLocaleString: true}}
                        </td>
                        <td class="text-right">
                            <button class="btn-sicche btn-secondary" (click)="cancelMessage(item)" *ngIf="!item.sent">{{'CANCEL' | translate}}</button>
                            <button class="btn-sicche btn-secondary" (click)="editMessage(item)" *ngIf="!item.sent">{{'EDIT' | translate}}</button>
                        </td>
                    </tr>
                </ng-template>
            </div>
        </div>
    </div>
    <!-- messaggi template -->
    <div class="col-12 panel shadow-mt-3" *ngIf="showTemplateMessages">
        <div class="position-relative">
            <div class="table-responsive">
                <app-core-table
                *ngIf="templateMessages"
                [items]="templateMessages"
                [columns]="[{name: 'TITLE', class:'text-left'}, {name: 'MESSAGES_SECTION.SUBJECT', class:'text-left'}, {name: 'MESSAGES_SECTION.MESSAGE', class:'text-left'}]"
                tableid="'messages'"
                [perPage]="99999"
                [rowTemplate]="rowTemplate"
                >
                </app-core-table>
                <ng-template let-item="item" #rowTemplate>
                    <tr (click)="editTemplate(item)" class="clickable">
                        <td>
                            {{item.title}}
                        </td>
                        <td>
                            {{item.subject}}
                        </td>
                        <td [innerHTML]="item.body | truncate: [200, '...']">
                        </td>
                    </tr>
                </ng-template>
            </div>
        </div>
    </div>
</div>
