import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ModalService } from '@app/core/services/modal.service'
import { DragScrollComponent } from 'ngx-drag-scroll';
import { BlogService } from '@app/shared/services/blog.service';
import { PageModel } from '@app/core/models/page';
import { BlogModel } from '@app/shared/models/blog.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { ResponseModel } from '@app/core/models/response.model';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-private-dash-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent extends CoreBaseComponent implements OnInit, OnDestroy {

  list: BlogModel[];
  baseBlogUrl: string;

  constructor(private modalService: ModalService, private blogService: BlogService) {
    super();
  }

  ngOnInit() {
    this.baseBlogUrl = environment.websiteUrl;
    this.operation('getting-news').reset().isSubmitting();
    this.blogService.getBlogs().subscribe((res: ResponseModel) => {
      this.list = res.getData().blog;

      // const temp = res.getData().blog;
      // tslint:disable: radix
      // tslint:disable-next-line: max-line-length
      // const sorted = temp.sort((a, b) => (parseInt(a.position) > parseInt(b.position)) ? -1 : ((parseInt(b.position) > parseInt(a.position)) ? 1 : 0));
      // this.list = sorted.slice(0, 4);
      this.operation('getting-news').reset().isEnded();
    });
  }

  ngOnDestroy() {
  }

}
