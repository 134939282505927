import { Component, ViewChild, TemplateRef, AfterViewInit, OnInit } from '@angular/core';
import { Router, Event as RouterEvent, NavigationStart, NavigationEnd,
    NavigationCancel, NavigationError, ActivatedRoute } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { ToolbarService } from '@app/core/services/toolbar.service';
import { SidebarService } from '@app/core/services/sidebar.service';
// Toolbar (entryComponents)
import { ToolbarNotificationsComponent } from '@app/pages/layers/private/toolbar/notifications/toolbar-notifications.component';
import { ToolbarLogoutComponent } from '@app/pages/layers/private/toolbar/logout/toolbar-logout.component';
import { ToolbarProfileComponent } from '@app/pages/layers/private/toolbar/profile/toolbar-profile.component';
import { LogoComponent } from '@app/shared/components/logo/logo.component';
import { ModalService } from '@app/core/services/modal.service';
import { Interval } from '@app/core/helpers/interval';
import { ResponseModel } from '@app/core/models/response.model';
import { PingService } from '@app/shared/services/ping.service';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { HelpModalComponent } from './modals/help/help.component';
import { UiService } from '@app/shared/services/ui.service';
import { AuthService } from '@app/core/services/auth.service';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { CountryService } from '@app/shared/services/country.service';
import { TeamService } from '@app/shared/services/team.service';
import { User } from '@app/shared/models/user.model';
import { environment } from '@environments/environment';
import { HelpComponent } from './toolbar/help/help.component';
import { LanguagesService } from '@app/shared/services/languages.service';

/**
 * String.prototype.replaceAll() polyfill
 * https://gomakethings.com/how-to-replace-a-section-of-a-string-with-another-one-with-vanilla-js/
 * @author Chris Ferdinandi
 * @license MIT
 */
//@ts-ignore
if (!String.prototype.replaceAll) {
  //@ts-ignore
	String.prototype.replaceAll = function(str, newStr){

		// If a regex pattern
		if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
			return this.replace(str, newStr);
		}

		// If a string
		return this.replace(new RegExp(str, 'g'), newStr);

	};
}

@Component({
  selector: 'app-root',
  templateUrl: './private.component.html',
  styles: [':host { display: block; }'],
  styleUrls: ['./private.component.scss']
})
export class PrivateComponent extends CoreBaseComponent implements OnInit {

  public initialized = true;
  public demoWarning = false;
  public subscriptionStatus: string;
  public updatingApp = false;
  inactivityTime = 0;

  constructor(
    private router: Router,
    private modalService: ModalService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private toolbarService: ToolbarService,
    public sidebarService: SidebarService,
    public pingService: PingService,
    private coreSidebarLayoutService: CoreLayoutService,
    private uiService: UiService,
    private authService: AuthService,
    private countryService: CountryService,
    private teamService: TeamService,
    private coreLayoutService: CoreLayoutService,
    private languagesService: LanguagesService
    ) {
      super();
      this.router.events.subscribe(this.navigationInterceptor.bind(this));
      // Reset teamService current user
      this.teamService.resetCurrentUser();
      // Reset navigation sidebar
      this.sidebarService.reset();
      // Reset dynamic sidebar
      this.coreSidebarLayoutService.reset();
      this.uiService.initToolbar();
      // Setup core base service

      if (environment.developmentTools) {
        this.coreSidebarLayoutService.devToolbar = true;
      }

      let leftBarVoices = [
        {label: 'HOME', url: '/dashboard', icon: 'fal fa-home', slug: 'dashboard', children: []},
        {label: 'MY_RESEARCHES', classes: 'active', url: '/researches', icon: 'fal fa-comments', children: []},
        {label: 'HELP', icon: 'fal fa-life-ring', classes: 'help-button', onclick: this.helpModal, context: this, children: []}
      ];

      this.sidebarService.addVoices(leftBarVoices).sticky(true);

      // Ping
      const ping = new Interval(60 * 5 * 1000, () => {
        pingService.ping('/ping').subscribe((response: ResponseModel) => {});
      });
      ping.run();


      // If is customer, hide left bar
      this.authService.authorizedUser$.subscribe((user: any) => {
        if (user) {
          if (user.otherdata) {
            if (user.otherdata.first_role) {
              if (user.otherdata.first_role === 'CUSTOMER_BASIC' || user.otherdata.first_role === 'CUSTOMER_PRO') {
                this.sidebarService.addVoices([]).sticky(true);
              }
            }
          }
        }
      });

      // If is customer pro, hide left bar
      this.teamService.user$.subscribe(res => {
        if (this.teamService.currentUser$) {
          if (this.teamService.currentUser$.role === 'CUSTOMER_PRO') {
            this.sidebarService.addVoices([]).sticky(true);
          }
        }
      });

      // cambio lingua in base a preferenze utente
      this.authService.current().subscribe((res: ResponseModel) => {
        this.languagesService.changeLanguage(res.getData().language_code);
      });

  }



  forbiddenError = (error: any) => {
    // todo
    alert(403);
  }

  helpModal() {
    // this.modalService.open({modalModel: HelpModalComponent, dialogSize: 'custom'}, null);
    this.coreLayoutService.openSidebar(HelpComponent);
  }

  disableLogout() {
    this.toolbarService.get('toolbar-logout').toggle();
  }

  private navigationInterceptor(e: RouterEvent) {
    if (e instanceof NavigationStart) {
      document.body.classList.add('app-loading');
    }
    if (e instanceof NavigationEnd) {
      // todo this.appService.scrollTop(0, 0);
    }
    if (e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError) {
      document.body.classList.remove('app-loading');
    }
  }

  ngOnInit() {
    // this.authLoginService.getCurrentUser().subscribe((res: ResponseModel) => {
    //   console.log(res.getData());
    // });

    setInterval(() => {
      this.inactivityTime += 1000;
      // se 30 minuti di inattività
      if (this.inactivityTime > 1000 * 60 * 30) {
        this.logOut();
      }
    }, 1000);

    document.addEventListener('mousemove', () => this.resetTimeOut());
    document.addEventListener('click', () => this.resetTimeOut());

  }

  resetTimeOut() {
    this.inactivityTime = 0;
  }

  logOut() {
    this.inactivityTime = 0;
    this.authService.logout('/login', true).subscribe(res => {
      this.router.navigateByUrl('/login');
    });
  }

  ngAfterViewInit() {
  }

}
