import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ResponseModel } from '@app/core/models/response.model';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { QuestionsService } from '@app/shared/services/questions.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { QuestionModel } from '@app/shared/models/question.model';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { BlockModel } from '@app/shared/models/block.model';
import { AbstractQuestion } from '../abstract-question.component';

export interface Couple {
  id: number;
  type: 'pre'|'custom'; // 20210709 il cliente chiede che le coppia siano tradotte nella lingua dell'utente attuale
  label_left_key?: string;
  label_right_key?: string;
  label_left: string;
  label_right: string;
  view?: string;
}

@Component({
  selector: 'app-setup-questions-semantic-differentials',
  templateUrl: './semantic-differentials.component.html',
  styleUrls: ['./semantic-differentials.component.scss']
})
export class SemanticDifferentialsQuestionComponent extends AbstractQuestion implements OnInit {

  @Input() countryUniqId: string = null;
  @Input() block: BlockModel = null;
  @Input() question: QuestionModel = null;
  @Input() disabled = false;
  @Output() saveEmit: EventEmitter<string> = new EventEmitter(null);

  public form: UntypedFormGroup;
  public couples: Couple[] = [];
  private answerUniqId = 0;

  get answers() {
    return this.form.get('answers') as UntypedFormArray;
  }

  constructor(private formBuilder: UntypedFormBuilder, private layoutService: CoreLayoutService, protected translate: TranslateService,
              private questionsService: QuestionsService, protected toastService: ToastrService) {
    super(toastService, translate);
  }

  ngOnInit() {
    this.questionsService.getBaseCouples()
      .subscribe((res: ResponseModel) => {
        this.couples = res.getData().map((c: Couple) => this.setBaseCoupleView(c));
        this.answerUniqId = this.couples.reduce((max, el) => el.id > max ? el.id : max, 0);
        this.answerUniqId = this.question
          ? this.question.question_data.answers.reduce((max, el) => el.id > max ? el.id : max, this.answerUniqId)
          : this.answerUniqId;
        this.form.patchValue({
          couples_selection: this.question
            ? this.question.question_data.answers
              .filter((answer: Couple) => answer.type === 'pre')
              .map((answer: Couple) => this.couples.find(c => c.id === answer.id))
            : []
        });
      });

    this.form = this.formBuilder.group({
      question_data: this.formBuilder.group({
        randomize_order: new UntypedFormControl(this.question ? this.question.question_data.randomize_order : '', null),
      }),
      is_masked: new UntypedFormControl(this.question ? this.question.is_masked : this.block.is_room_group ? true : false, null),
      title: new UntypedFormControl(this.question ? this.question.title : '', null),
      has_minimum_multiple_answers_nr: new UntypedFormControl((this.question && this.question.minimum_multiple_answers_nr) ? true : null),
      minimum_multiple_answers_nr: new UntypedFormControl(this.question ? this.question.minimum_multiple_answers_nr : null),
      couples_selection: new UntypedFormControl([], null),
      new_couple: this.formBuilder.group({
        label_left: new UntypedFormControl('', Validators.required),
        label_right: new UntypedFormControl('', Validators.required)
      }),
      answers: this.formBuilder.array(this.question
        ? this.question.question_data.answers.map(answer =>
          new UntypedFormGroup({
            id: new UntypedFormControl(answer.id),
            type: new UntypedFormControl(answer.type || 'custom'),
            label_left_key: answer.type === 'pre' ? new UntypedFormControl(answer.label_left_key) : new UntypedFormControl(),
            label_right_key: answer.type === 'pre' ? new UntypedFormControl(answer.label_right_key) : new UntypedFormControl(),
            label_left: answer.type === 'pre' ? new UntypedFormControl({value: this.translate.instant(answer.label_left_key), disabled: true }) : new UntypedFormControl(answer.label_left),
            label_right: answer.type === 'pre' ? new UntypedFormControl({value: this.translate.instant(answer.label_right_key), disabled: true }) : new UntypedFormControl(answer.label_right),
          }))
        : []),
      type: new UntypedFormControl('semantic-differentials', null),
      blockid: new UntypedFormControl(this.block.id, Validators.required),
      personas_tags: new UntypedFormControl(this.question ? this.question.personas_tags : '', null),
      sample_variable_tags: new UntypedFormControl(this.question ? this.question.sample_variable_tags : '', null)
    }, );
    if (this.disabled) {
      this.form.disable();
    }
    this.layoutService.buttons([
      {label: 'SAVE_AND_EXIT', slug: 'saveexit', classes: 'mr-3 btn-sicche btn-secondary', icon: 'fal fa-share-square', click: this.submitAndExit },
      {label: 'SAVE_AND_CREATE_NEW', slug: 'save', classes: 'mr-5 btn-sicche btn-primary', icon: 'fal fa-check', click: this.submitAndStay },
    ]);

    this.initAttachments();
  }

  setBaseCoupleView(c: Couple): Couple {
    c.label_left = this.translate.instant(c.label_left_key);
    c.label_right = this.translate.instant(c.label_right_key);
    c.view = `${c.label_left} | ${c.label_right}`;
    return c;
  }

  submitAndExit = () => {
    this.layoutService.getButton('saveexit').loading = true;
    this.submit(() => {
      this.saveEmit.emit('save-and-leave');
    }, () => {
      this.layoutService.getButton('saveexit').loading = false;
    });
  }

  submitAndStay = () => {
    this.layoutService.getButton('save').loading = true;
    this.submit(() => {
      this.saveEmit.emit('save-and-stay');
    }, () => {
      this.layoutService.getButton('save').loading = false;
    });
  }

  onCoupleSelectionChange(e: any) {
    // update answers based on selection form
    (e as Array<Couple>).map(el => {
      if (!(this.answers.value as Array<Couple>).find(selected => selected.id === el.id) ) {
        this.addAnswer(el);
      }
    });
    (this.answers.value as Array<Couple>).map(selected => {
      if (this.couples.find(c => c.id === selected.id && !(e as Array<Couple>).find((c1) => c1.id === selected.id))) {
        this.removeAnswer(selected);
      }
    });
  }

  removeAnswer(removing?: Couple, removingIdx?: number) {
    if (this.disabled) { return; }
    const idx = !removing
      ? removingIdx
      : (this.answers.value as Array<Couple>).findIndex(c => c.id === (removing as Couple).id);
    const el = !removing
      ? this.answers.at(idx).value
      : removing;
    this.answers.removeAt(idx);
    // update multiple selection
    this.form.patchValue({
      couples_selection: this.form.value.couples_selection.filter((c: Couple) => c.id !== el.id)
    });
  }

  addAnswer(c: Couple) {
    this.answers.push(new UntypedFormGroup({
      id: new UntypedFormControl(c.id),
      type: new UntypedFormControl('pre'),
      label_left_key: new UntypedFormControl(c.label_left_key),
      label_right_key: new UntypedFormControl(c.label_right_key),
      label_left: new UntypedFormControl({value: c.label_left, disabled: true }, null),
      label_right: new UntypedFormControl({value: c.label_right, disabled: true }, null),
    }));
  }

  addCustomAnswer() {
    if (!this.form.get('new_couple').valid) {
      return;
    }
    this.answerUniqId++;
    this.answers.push(new UntypedFormGroup({
      id: new UntypedFormControl(this.answerUniqId, null),
      type: new UntypedFormControl('custom', null),
      label_left: new UntypedFormControl(this.form.get('new_couple').value.label_left, null),
      label_right: new UntypedFormControl(this.form.get('new_couple').value.label_right, null),
    }));

    this.form.get('new_couple').setValue({
      label_left: '',
      label_right: ''
    });
  }

  changeQuestionsOrder(e: CdkDragDrop<string[]>) {
    this.arrayMove(this.form.get('answers').value, e.previousIndex, e.currentIndex, this.answers);
  }

  submit(callback: any, fallback: any) {
    if (this.disabled) {
      fallback();
      this.toastService.error(this.translate.instant('ERRORS.CAN_NOT_MODIFY_PAST'));
      return;
    }
    this.operation('creating').reset().isSubmitting();
    const formValue = this.form.getRawValue();
    const data = formValue;
    if (data.sample_variable_tags && data.sample_variable_tags.length > 0) {
      data.sample_variable_tags_by_comma = data.sample_variable_tags.join(',');
      data.sample_variable_tags = null;
    }
    if (data.personas_tags && data.personas_tags.length > 0) {
      data.personas_tags_by_comma = data.personas_tags.join(',');
      data.personas_tags = null;
    }
    data.question_data.answers = formValue.answers;

    data.question_data.attached_pdf_files = this.attachedPdfFiles;
    data.question_data.attached_images = this.attachedImages;

    (
      !this.question
        ? this.questionsService.createQuestion(data)
        : this.questionsService.updateQuestion(this.question.id, data)
    ).subscribe((res: ResponseModel) => {
      this.operation('creating').isSuccess(res.getErrors());
      this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
      callback();
    }, (e) => {
      this.operation('creating').isFailed(e.errors);
      this.toastService.error(this.translate.instant('ERRORS.GENERIC'));
      fallback();
    });
  }

}
