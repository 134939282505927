<div id="people-view" class="mt-4">
    <div class="row">
        <div class="col-md-3">
            <div class="panel shadow p-0">
                <!-- filtri -->
                <div class="panel-header d-flex align-items-center justify-content-center mb-4">
                    <div class="tool check mr-5 position-relative variable-filter">
                        <i class="fal fa-tag mr-2 fa-lg text-gray"></i>
                        <span (click)="variablesFilterShow = !variablesFilterShow" id="variablesFilterShowToggle"
                            animation="true" popoverClass="medium-popover" autoClose="outside"
                            [ngbPopover]="contextMenu" placement="auto">
                            {{ 'FILTER_BY_VARIABLES' | translate }}
                        </span>
                        <br>
                        <b>
                            ({{getFilterCount()}} {{'ACTIVE_FILTERS' | translate }})
                        </b>

                        <div [hidden]="!variablesFilterShow" class="tool-btn menu">
                            <ng-template #contextMenu>
                                <ng-scrollbar>
                                    <div class="row p-2">
                                        <div class="col-6">
                                            <div class="mb-3">
                                                <b>{{'SAMPLE_VARIABLE_TAGS' | translate }}</b>
                                            </div>
                                            <div>
                                                <ul>
                                                    <li (click)="!room.sample_variable_tags?.includes(tag) ? selectSampleVariableTag(tag) : null"
                                                        [ngClass]="{'selected': selectedSampleVariableTags?.includes(tag), 'readonly': room.sample_variable_tags?.includes(tag)}"
                                                        *ngFor="let tag of sampleVariableTags">
                                                        {{ tag }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="mb-3">
                                                <b>{{'PERSONAS_SHORT' | translate }}</b>
                                            </div>
                                            <div>
                                                <ul>
                                                    <li *ngFor="let personasTag of personasTagsList"
                                                        (click)="!room.sample_variable_tags?.includes(personasTag) ? selectPersonasTag(personasTag) : null"
                                                        [ngClass]="{'selected': selectedPersonasTags?.includes(personasTag)}">
                                                        {{personasTag}}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </ng-scrollbar>
                                <div class="row p-2">
                                    <div class="col-12 text-right popover-footer">
                                        <a class="mr-5" (click)="resetFilters()">{{'RESET' | translate}}</a>
                                        <a class="" (click)="storeFilters()">{{'SAVE' | translate}}</a>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <!-- /filtri -->
                <app-core-spinner [toggle]="!interviewedsToPrint"></app-core-spinner>
                <div class="people-list-wrapper">
                    <div class="people-list overflow-y-auto" *ngIf="interviewedsToPrint">

                        <div class="alert alert-danger m-2 w-100 text-center"
                            *ngIf="!interviewedsToPrint.length && (!selectPersonasTag.length && !selectedSampleVariableTags.length)">
                            {{ 'THERE_ARE_NO_INTERVIEWEDS' | translate }}
                        </div>

                        <div *ngIf="interviewedsToPrint.length">
                            <div class="team-user" [ngClass]="{'selected': selectedTeamUser.userid == user.userid}"
                                (click)="selectTeamUser(user, true)"
                                *ngFor="let user of interviewedsToPrint; let i = index;">
                                <div class="item d-flex align-items-center justify-content-between w-100">
                                    <div class="d-flex align-items-center flex-grow-1">
                                        <div class="avatar">
                                            <app-shared-avatar *ngIf="user.avatar_url" [imageUrl]="user.avatar_url">
                                            </app-shared-avatar>
                                        </div>
                                        <p class="bold mb-0 ml-2">
                                            {{ user.nickname ? user.nickname : user.firstname + ' ' + user.lastname }}
                                        </p>
                                    </div>
                                    <span *ngIf="user.answers_to_read" class="mx-2 notification float-right">
                                        {{ user.answers_to_read ? 'new' : '' }}
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="(interviewedsToPrint && !interviewedsToPrint.length) && (!selectedPersonasTags.length && !selectedSampleVariableTags.length) && !loading"
            class="col-md-9">
            <div #scrollTarget></div>
            <div class="alert mt-4 alert-danger ml-2 mr-2 w-100 text-center">{{ 'THERE_ARE_NO_INTERVIEWEDS_AND_ANSWERS'
                | translate }}</div>
        </div>
        <div *ngIf="interviewedsToPrint?.length" class="col-md-9">
            <div #scrollTarget></div>
            <div id="messages-panel" class="panel shadow p-0 position-relative">
                <div class="panel-header d-flex align-items-center justify-content-start">
                    <div class="d-flex align-items-center mr-6" *ngIf="selectedTeamUser">
                        <div class="avatar">
                            <app-shared-avatar *ngIf="selectedTeamUser.avatar_url"
                                [imageUrl]="selectedTeamUser.avatar_url"></app-shared-avatar>
                        </div>
                        <p class="bold mb-0 ml-2">
                            {{ selectedTeamUser.nickname ? selectedTeamUser.nickname : selectedTeamUser.firstname + ' '
                            + selectedTeamUser.lastname }}
                            &nbsp;<i *ngIf="selectedTeamUser.is_locked" class="far fa-lock"
                                style="font-size: 15px;"></i>

                        </p>
                    </div>
                    <div *ngIf="!isCustomerPro">
                        <div class="tool-btn" *ngIf="selectedTeamUser">
                            <i class="far fa-tag"></i>
                            <span class="with-data"
                                [ngbTooltip]="selectedTeamUser.sample_variable_tags?.join(', ')">Tags</span>
                        </div>
                        <div class="tool-btn tags-select-tool" *ngIf="selectedTeamUser">
                            <ng-template #personasPopover>
                                <ng-select [items]="personasTagsList" [hideSelected]="true" multiple="true"
                                    [readonly]="isCustomerPro" [addTag]="true" (change)="personasTagsChange($event)"
                                    bindLabel="name" class="form-control" [(ngModel)]="selectedTeamUser.personas_tags">
                                </ng-select>
                                <!--
                                        <button class="btn btn-sicche d-none" (click)="personasTagsChanged()">
                                            <span *ngIf="operation('saving-personas-tags').submitting" class="spinner-border spinner-border-sm" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </span>
                                            {{ 'SAVE' | translate }}
                                        </button>
                                        -->
                            </ng-template>

                            <i [autoClose]="'outside'" [ngbPopover]="personasPopover"
                                [popoverTitle]="'SELECT_PERSONAS' | translate" class="fal fa-portrait as-link"></i>
                            <span [autoClose]="'outside'" [ngbPopover]="personasPopover"
                                [popoverTitle]="'SELECT_PERSONAS' | translate">
                                {{ (
                                selectedTeamUser.personas_tags?.length
                                ? selectedTeamUser.personas_tags?.join(', ') :
                                'personas'
                                ) | titlecase }}</span>
                        </div>
                        <div class="tool-btn" *ngIf="selectedTeamUser && !isCustomerPro">
                            <ng-template #notesPopover>
                                <textarea [(ngModel)]="selectedTeamUser.note"
                                    class="form-control">{{ selectedTeamUser.note ? selectedTeamUser.note : null }}</textarea>
                                <button class="btn btn-sicche" (click)="notesChanged()">
                                    <span *ngIf="operation('saving-note').submitting"
                                        class="spinner-border spinner-border-sm" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </span>
                                    {{ 'SAVE' | translate }}
                                </button>
                            </ng-template>
                            <i class="fal fa-sticky-note"></i>
                            <span [ngbTooltip]="selectedTeamUser.note" [ngbPopover]="notesPopover"
                                [autoClose]="'outside'" [popoverTitle]="'NOTA' | translate">{{ 'NOTE_S' | translate
                                }}</span>
                        </div>
                        <div class="tool mr-5 float-right" *ngIf="!isCustomerPro && interviewedsToPrint?.length">
                            <div class="switch mr-1">
                                <input id="switch" type="checkbox" [ngModel]="activeQuotes"
                                    (change)="toggleQuotesSelection($event.target.checked)">
                                <span (click)="toggleQuotesSelection(!answersModalSupportService.activeQuotes$.value)"
                                    class="slider round"></span>
                                <label for="switch">{{ 'ACTIVATE_QUOTES' | translate }}</label>
                            </div>

                        </div>
                    </div>
                    <div class="d-flex flex-grow-1 justify-content-end">
                        <div class="tool-btn menu" *ngIf="!isCustomerPro" [ngbPopover]="contextMenu" placement="auto">
                            <ng-template #contextMenu>
                                <ul>
                                    <li (click)="openPrivateMessageModal()">{{ 'SEND_PRIVATE_MESSAGE' | translate }}
                                    </li>
                                    <li *ngIf="!selectedTeamUser.is_locked" (click)="blockUser()">{{ 'BLOCK_USER' |
                                        translate }}</li>
                                    <li *ngIf="selectedTeamUser.is_locked" (click)="unlockUser()">{{ 'UNBLOCK_USER' |
                                        translate }}</li>
                                </ul>
                            </ng-template>
                            <i class="fas fa-ellipsis-v"></i>
                        </div>
                    </div>
                </div>
                <ng-scrollbar class="mod-dx-panel-scroll-height">
                    <div id="chat">
                        <div class="display">
                            <app-core-spinner [toggle]="loadingChat"></app-core-spinner>
                            <div *ngIf="!loadingChat">
                                <div *ngIf="questions.length">
                                    <div class="question-group-wrapper"
                                        *ngFor="let question of questions; let i = index;">
                                        <div class="d-flex justify-content-end incoming-msg mine question-msg my-4">
                                            <div class="w-100 d-flex flex-row">
                                                <div class="received-msg d-flex flex-grow-1">
                                                    <div class="received-w-msg w-100 arrow-right bg-almost-white-dark">
                                                        <div [innerHTML]="question.title"></div>
                                                        <div
                                                            *ngIf="question.question_data && question.question_data.pdf_files">
                                                            <span *ngFor="let pdf of question.question_data.pdf_files">
                                                                <a [href]="pdf.pdf_url | privatesrc"
                                                                    target="blank">{{pdf.name}}</a>
                                                            </span>
                                                        </div>
                                                        <div
                                                            *ngIf="question.question_data && question.question_data.attached_pdf_files">
                                                            <span
                                                                *ngFor="let pdf of question.question_data.attached_pdf_files">
                                                                <a [href]="pdf.pdf_url | privatesrc"
                                                                    target="blank">{{pdf.name}}</a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    class="incoming-msg-img d-flex flex-column align-items-center justify-content-start text-center">
                                                    <div class="avatar">
                                                        <app-shared-avatar [imageUrl]="question.team?.avatar_url">
                                                        </app-shared-avatar>
                                                    </div>
                                                    <div class="time">
                                                        <small>{{ question.creationtime | dateAgo }}</small>
                                                        <div *ngIf="question.team">
                                                            <span
                                                                *ngIf="question.team.userid == authService.user.id"><b>{{
                                                                    'YOU' | translate }}, {{ 'MODERATOR' | translate |
                                                                    lowercase }}</b></span>
                                                            <span
                                                                *ngIf="question.team.userid != authService.user.id"><b>{{
                                                                    question.team.nickname ? question.team.nickname :
                                                                    question.team.firstname }}, <span
                                                                        *ngIf="question.team.role == 'RESEARCHER' || question.team.role == 'OWNER'">{{
                                                                        'MODERATOR' | translate | lowercase
                                                                        }}</span></b></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="question.type != 'communications'" class="answer-detail my-4">
                                            <app-moderation-peopleview-question-answers (replyTo)="reply($event)"
                                                [selectedTeamUser]="selectedTeamUser" [answer]="question.answers"
                                                [question]="question">
                                            </app-moderation-peopleview-question-answers>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="chat-end"></div>
                </ng-scrollbar>
                <div id="reply-box"></div>
                <div *ngIf="showTextarea && answerTo && teamService.currentUser$.role != 'CUSTOMER_PRO'">
                    <div class="wysiwyg">
                        <div class="mt-4 p-2 panel"
                            style="min-height: auto; box-shadow: 0 0.0rem 1rem rgba(0, 0, 0, 0.15) !important;">
                            <div class="abs-header"></div>

                            <quill-editor [(ngModel)]="answerText" [modules]="quillModules"
                                (change)="onNewCommentChange($event)" class="editor-body" [styles]="{height: '200px'}">
                            </quill-editor>
                        </div>
                    </div>
                    <div class="footer pb-2 px-2 text-right">
                        <div *ngIf="operation('sending-comment').success" class="alert alert-success">
                            {{ 'COMMENT_SENT_CORRECTLY' | translate }}
                        </div>
                        <a class="mr-4" (click)="showTextarea = false">
                            {{'CANCEL' | translate}}
                        </a>
                        <button class="btn-sicche active" (click)="sendReply()"
                            [disabled]="!selectedTeamUser || !answerText">
                            <div class="spinner-border" *ngIf="operation('sending-comment').submitting" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <i *ngIf="!operation('sending-comment').submitting" class="fal fa-reply"></i>&nbsp;{{
                            'REPLY' | translate }}
                        </button>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
            <!-- next prev arrows -->
            <div class="row mt-0" *ngIf="navigationData">
                <div (click)="prevBlock()" class="col" *ngIf="!navigationData.isFirst">
                    <div class="d-flex pointer align-items-center justify-content-start h-100 pr-5 text-left">
                        <i class="far fa-arrow-right m-2 rotation-180-aclockwise arrow-analysis fa-3x"></i>
                        <span class="arrow-text-analysis"><strong>{{'PREVIOUS_BLOCK' | translate}}</strong></span>
                    </div>
                </div>
                <div (click)="nextBlock()" class="col d-flex justify-content-end" *ngIf="!navigationData.isLast">
                    <div
                        class="d-flex pointer align-items-center justify-content-start h-100 text-right pl-5 text-right">
                        <span class="arrow-text-analysis"><strong>{{'NEXT_BLOCK' | translate}}</strong></span>
                        <i class="far fa-arrow-right m-2 arrow-analysis fa-3x"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="overlay-extract" *ngIf="answersModalSupportService.showQuotesModal$.value">
    <app-moderation-quotes-modal [commentid]="answersModalSupportService.commentSelected$.value"
        [answer]="answersModalSupportService.answerSelected$.value" (closeFunc)="quotesModalClose()">
    </app-moderation-quotes-modal>
</div>