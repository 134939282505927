<div (mouseup)="selectText()" class="container-fluid {{ classes }}"
    [ngClass]="{'with-video-and-images': videosCount && imagesCount,
                'with-images': imagesCount,
                'with-videos': videosCount}" *ngIf="answer.answer_data">
    <div class="row">
        <div class="col-md-12">
            <div *ngIf="!itemToModify" class="answer-text"
                (mouseup)="selectText()" class="w-100 py-3"
                [innerHTML]="answer.answer_data.text || ''">
            </div>
        </div>
    </div>
    <div class="row">
        <div *ngIf="(videosCount || imagesCount) && itemToModify != answer.id"
            class="media col-md-12">
            <div class="d-flex flex-column w-100">
                <div class="d-flex mb-4" *ngIf="videosCount && imagesCount">
                    <a (click)="mediaTab = 'images'" [ngClass]="{'active': mediaTab == 'images'}" class="tab panel shadow p-2 mr-1 my-2">{{ 'IMAGES' | translate }}</a>
                    <a (click)="mediaTab = 'videos'" [ngClass]="{'active': mediaTab == 'videos'}" class="tab panel shadow p-2 mr-1 my-2">{{ 'VIDEOS' | translate }}</a>
                </div>

                <div *ngIf="videosCount && mediaTab == 'videos'" class="">
                <!-- <div *ngIf="videosCount && mediaTab == 'videos'" class="d-flex w-100 videos-tab {
                    background-color: $black;
                    border-radius: 4px;
                }"> -->
                    <div *ngFor="let video of answer.answer_data.videos; let i = index">
                        <div class="row mt-2">
                            <div class="col-4 img-card-box">
                                <div class="card">
                                    <div class="d-flex flex-column align-items-center">
                                        <video *ngIf="!placeholders" width="100%" height="240" controls id="video_player_{{answer.id}}">
                                            <source [src]="video.video_url | privatesrc" type="video/mp4">
                                            Your browser does not support the video tag.
                                        </video>

                                        <div *ngIf="placeholders" class="pdf-placeholder pdf-video"></div>

                                        <div *ngIf="explicitMediaUrls" class="explicit-media-url">
                                            <a [href]="video.video_url">{{ 'MEDIA_DOWNLOAD' | translate }}</a>
                                            <span class="media-file-name">{{ explicitMediaFileName(video.video_url) }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-8">
                                <h5>{{ 'TRANSCRIPTION' | translate }}</h5>
                                <ng-scrollbar>
                                    <div class="transcription-timeline" *ngIf="video.video_data && video.video_data.file_words">
                                        <div class="clickable float-left" *ngFor="let word of video.video_data.file_words"
                                            (click)="setVideoTime(answer.id, word.start, word.end)">{{word.word}}&nbsp;
                                        </div>
                                    </div>
                                    <div class="transcription unselectable" [innerHTML]="video.hlTranscription"></div>
                                </ng-scrollbar>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="imagesCount && mediaTab == 'images'" class="d-flex justify-content-between w-100 img-card-box">
                    <ng-container *ngIf="imagesCount == 1">
                        <div class="card">
                            <div  class="d-flex flex-column align-items-center">
                                <div class="d-flex flex-column align-items-center">
                                    <div class="image-thumb as-link" (click)="openLightbox(answer.answer_data.images[0])" [ngStyle]="{'background-image': 'url(' + (answer.answer_data.images[0].image_url | privatesrc) + ')'}"></div>

                                    <div *ngIf="explicitMediaUrls" class="explicit-media-url">
                                        <a [href]="answer.answer_data.images[0].image_url">{{ 'MEDIA_DOWNLOAD' | translate }}</a>
                                        <span class="media-file-name">{{ explicitMediaFileName(answer.answer_data.images[0].image_url) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="d-flex flex-wrap" *ngIf="imagesCount > 1">
                        <div *ngFor="let image of answer.answer_data.images; let i = index">
                            <div class="d-flex flex-column align-items-center">
                                <div class="image-thumb as-link" (click)="openLightbox(i, answer.answer_data.images)" [ngStyle]="{'background-image': 'url(' + (image.image_url | privatesrc ) + ')'}"></div>

                                <div  class="d-flex flex-column align-items-center">
                                    <div *ngIf="explicitMediaUrls" class="explicit-media-url">
                                        <a [href]="image.image_url">{{ 'MEDIA_DOWNLOAD' | translate }}</a>
                                        <span class="media-file-name">{{ explicitMediaFileName(image.image_url) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!--
        <div
            *ngIf="itemToModify != answer.id"
            [ngClass]="{'col-md-6': videosCount || imagesCount}" (mouseup)="selectText()" class="w-100"
            [innerHTML]="answer.answer_data.text">
        </div>
        -->
        <div *ngIf="itemToModify === answer.id">
            <app-answer-wysiwyg [form]="form" [formEntityName]="'text'"></app-answer-wysiwyg>
            <button class="btn btn-success" (click)="saveMods()">
                <span *ngIf="operation('saving').submitting" class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only">Loading...</span>
                </span>
                {{ 'SAVE' | translate }}
            </button>
        </div>
    </div>
</div>