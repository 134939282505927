<div *ngIf="!operation('requesting').success" style="width: 500px;">
    <h2 class="light">{{ 'INSERT_NEW_PASSWORD' | translate }}</h2>
    <form *ngIf="form" [formGroup]="form">
        <div class="row">
            <div class="col-md-6">
              <div class="" [class.is-invalid]="form.get('password').invalid && form.get('password').touched">
                <div class="form-group">
                  <label>
                    <input formControlName="password" type="password" class="form-control" id="password">
                    <span class="placeholder">{{ 'NEW_PASSWORD' | translate }}</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="" [class.is-invalid]="form.get('repeatpassword').invalid && form.get('repeatpassword').touched">
                <div class="form-group">
                  <label>
                    <input formControlName="repeatpassword" type="password" class="form-control" id="repeatpassword">
                    <span class="placeholder">{{ 'REPEAT_PASSWORD' | translate }}</span>
                  </label>
                </div>
              </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center mb-3">
                <div *ngIf="form.get('password').errors?.pattern || form.get('repeatpassword').errors?.pattern && form.get('password').touched  && form.get('repeatpassword').touched">
                <small>
                    <span class="text-danger">{{ 'PASSWORD_RULES_WARNING' | translate:({param1: passwordMinLength, dumbTrigger: 'it'} | ParamsTranslate) }}</span>
                </small>
                </div>
                <div *ngIf="form.get('password').errors?.MatchFields || form.get('repeatpassword').errors?.MatchFields && form.get('password').touched  && form.get('repeatpassword').touched">
                    <small>
                        <span class="text-danger">{{ 'ERRORS.PASSWORDS_DOES_NOT_MATCH' | translate }}</span>
                    </small>
                </div>
                <small *ngIf="operation('saving-data').errors?.repeatpassword">
                    <span class="text-danger">{{ ('SERVER_ERRORS.' + operation('saving-data').errors.repeatpassword) | translate }}</span>
                </small>
                <small *ngIf="operation('saving-data').errors?.password">
                    <span class="text-danger">{{ ('SERVER_ERRORS.' + operation('saving-data').errors.password) | translate }}</span>
                </small>
            </div>
        </div>
        <small *ngIf="operation('requesting').errors?.token">
            <span class="text-danger">{{ ('SERVER_ERRORS.' + operation('requesting').errors.token) | translate }}</span>
        </small>
        <div class="alert alert-danger" *ngIf="operation('requesting').errors">
            {{ ('SERVER_ERRORS.' + operation('requesting').errors) | translate }}
        </div>
        <div class="text-center w-100">
            <app-core-button-submit
              class="w-100 mt-1 d-block"
              [classes]="'btn-sicche active mx-auto'"
              [spinnerDimension]="'small'"
              [form]="form" 
              (submitCallback)="submit()" 
              [toggle]="operation('requesting').submitting">
              <span>{{ 'RESET' | translate }}</span>
          </app-core-button-submit>
        </div>
    </form>
  </div>
  <div *ngIf="operation('requesting').success">
    <div class="va success-panel text-center">
      <div>
          <i class="far fa-check" style="font-size: 48px;"></i>
      </div>
      <h3>{{ 'ASK_PASSWORD_RESET.PASSWORD_HAS_BEEN_CHANGED' | translate }}</h3>
      <p>{{ 'ASK_PASSWORD_RESET.PASSWORD_HAS_BEEN_CHANGED_MSG' | translate }}</p>
      <br/>
      <!-- <a class="as-link" (click)="closeModal()">{{ 'OK_I_UNDERSTAND' | translate }}</a>-->
    </div>
  </div>