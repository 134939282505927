import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ResponseModel } from '@app/core/models/response.model';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { QuestionsService } from '@app/shared/services/questions.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { RoomsService } from '@app/shared/services/rooms.service';
import { QuestionModel } from '@app/shared/models/question.model';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { BlockModel } from '@app/shared/models/block.model';
import { AbstractQuestion } from '../abstract-question.component';

@Component({
  selector: 'app-setup-questions-grouping-category',
  templateUrl: './grouping-category.component.html',
  styleUrls: ['./grouping-category.component.scss']
})
export class GroupingCategoryQuestionComponent extends AbstractQuestion implements OnInit {

  @Input() countryUniqId: string = null;
  @Input() block: BlockModel = null;
  @Input() question: QuestionModel = null;
  @Input() disabled = false;
  @Output() saveEmit: EventEmitter<string> = new EventEmitter(null);

  public form: UntypedFormGroup;
  private answerUniqId = 0;
  public optionsError = false;

  get answers() {
    return this.form.get('answers') as UntypedFormArray;
  }
  get categories() {
    return this.form.get('categories') as UntypedFormArray;
  }

  constructor(private formBuilder: UntypedFormBuilder, private layoutService: CoreLayoutService, protected translate: TranslateService,
              private questionsService: QuestionsService, protected toastService: ToastrService, public roomsService: RoomsService) {
    super(toastService, translate);
  }

  ngOnInit(): void {
    this.answerUniqId = this.question
      ? this.question.question_data.answers.reduce((max, el) => el.id > max ? el.id : max, this.answerUniqId)
      : this.answerUniqId;

    this.form = this.formBuilder.group({
      question_data: this.formBuilder.group({
        randomize_order: new UntypedFormControl(this.question ? this.question.question_data.randomize_order : '', null),
      }),
      is_masked: new UntypedFormControl(this.question ? this.question.is_masked : this.block.is_room_group ? true : false, null),
      title: new UntypedFormControl(this.question ? this.question.title : '', null),
      has_minimum_multiple_answers_nr: new UntypedFormControl((this.question && this.question.minimum_multiple_answers_nr) ? true : null),
      minimum_multiple_answers_nr: new UntypedFormControl(this.question ? this.question.minimum_multiple_answers_nr : null),
      categories: this.formBuilder.array(this.question
        ? this.question.question_data.categories.map((a: string) => new UntypedFormControl(a, Validators.required))
        : [ // need at least 2 categories
          new UntypedFormControl('', null),
          new UntypedFormControl('', null)
        ]
      ),
      answers: this.formBuilder.array(this.question
        ? this.question.question_data.answers.map(answer =>
          new UntypedFormGroup({
            id: new UntypedFormControl(answer.id, null),
            image_del: new UntypedFormControl(false, null),
            image: new UntypedFormControl(null),
            image_url: new UntypedFormControl(answer.image_url),
            comment: new UntypedFormControl(answer.comment)
          }))
        : []
      ),
      type: new UntypedFormControl('grouping-category', null),
      blockid: new UntypedFormControl(this.block.id, null),
      personas_tags: new UntypedFormControl(this.question ? this.question.personas_tags : '', null),
      sample_variable_tags: new UntypedFormControl(this.question ? this.question.sample_variable_tags : '', null)
    });
    if (!this.question) {
      this.answers.push(new UntypedFormGroup({
        id: new UntypedFormControl(this.answerUniqId++, null),
        image: new UntypedFormControl('', null),
        image_url: new UntypedFormControl('', null),
        image_del: new UntypedFormControl('', null),
        comment: new UntypedFormControl('', null),
      }));
    }
    if (this.disabled) {
      this.form.disable();
    }
    this.layoutService.buttons([
      {label: 'SAVE_AND_EXIT', slug: 'saveexit', classes: 'mr-3 btn-sicche btn-secondary',
        icon: 'fal fa-share-square', click: this.submitAndExit },
      {label: 'SAVE_AND_CREATE_NEW', slug: 'save', classes: 'mr-5 btn-sicche btn-primary', icon: 'fal fa-check', click: this.submitAndStay }
    ]);

    this.initAttachments();
  }

  removeAnswer(index: number) {
    this.answers.removeAt(index);
  }

  addAnswer() {
    this.answers.push(new UntypedFormGroup({
      id: new UntypedFormControl(this.answerUniqId++, null),
      image: new UntypedFormControl('', null),
      image_url: new UntypedFormControl('', null),
      image_del: new UntypedFormControl('', null),
      comment: new UntypedFormControl('', null),
    }));
  }

  removeCategory(index: number) {
    this.categories.removeAt(index);
  }

  addCategory() {
    this.categories.push(new UntypedFormControl('', [Validators.required]));
  }

  submitAndExit = () => {
    this.layoutService.getButton('saveexit').loading = true;
    this.submit(() => {
      this.saveEmit.emit('save-and-leave');
    }, () => {
      this.layoutService.getButton('saveexit').loading = false;
    });
  }

  submitAndStay = () => {
    this.layoutService.getButton('save').loading = true;
    this.submit(() => {
      this.saveEmit.emit('save-and-stay');
    }, () => {
      this.layoutService.getButton('save').loading = false;
    });
  }

  changeQuestionsOrder(e: CdkDragDrop<string[]>) {
    this.arrayMove(this.answers.value, e.previousIndex, e.currentIndex, this.answers);
  }

  submit(callback: any, fallback: any) {
    if (this.disabled) {
      fallback();
      this.toastService.error(this.translate.instant('ERRORS.CAN_NOT_MODIFY_PAST'));
      return;
    }
    const formValue = this.form.getRawValue();
    this.optionsError = false;
    // test for empty options comments or categories
    formValue.answers
      .map(answer => answer.comment)
      .concat(formValue.categories)
      .forEach(el => {
        if (!el.replace(/ /g, '')) {
          this.optionsError = true;
        }
      }
    );
    if (this.optionsError) {
      fallback();
      this.toastService.error(this.translate.instant('ERRORS.ANSWER_OPTIONS_MISSING_COMMENT'));
    } else {
      this.operation('creating').reset().isSubmitting();
      const data = formValue;
      if (data.sample_variable_tags && data.sample_variable_tags.length > 0) {
        data.sample_variable_tags_by_comma = data.sample_variable_tags.join(',');
        data.sample_variable_tags = null;
      }
      if (data.personas_tags && data.personas_tags.length > 0) {
        data.personas_tags_by_comma = data.personas_tags.join(',');
        data.personas_tags = null;
      }
      data.question_data.answers = formValue.answers;
      data.question_data.categories = formValue.categories;

      data.question_data.attached_pdf_files = this.attachedPdfFiles;
      data.question_data.attached_images = this.attachedImages;

      (!this.question ? this.questionsService.createQuestion(data)
        : this.questionsService.updateQuestion(this.question.id, data)).subscribe((res: ResponseModel) => {
        this.operation('creating').isSuccess(res.getErrors());
        this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
        callback();
      }, (e) => {
        this.operation('creating').isFailed(e.errors);
        this.toastService.error(this.translate.instant('ERRORS.GENERIC'));
        fallback();
      });
    }
  }
}
