<div class="my-1 py-2 border-bottom d-flex align-items-center questions bg-white">
    <div class="flex-grow-0 drag-btn p-3"><i class="fal fa-ellipsis-v"></i></div>
    <div class="flex-grow-1" (click)="openQuestion()">
        <div class="d-flex align-items-center">
            <div class="flex-grow-0 text-center">
                <h4 class="mb-0 extra-bold prog-numb">{{index + 1}}</h4>
            </div>
            <div class="flex-grow-1 hover-pointer">
                {{ (question.title || 'Untitled') | striphtmlKeepSpaces | truncate:[200, '...'] }}
            </div>
        </div>
    </div>
    <div class="flex-grow-0 d-flex flex-no-wrap">
        <button class="icon" (click)="removeQuestion()" *ngIf="isEditable">
            <i class="fal fa-trash-alt"></i>
        </button>
        <button class="icon" (click)="duplicateQuestion()" *ngIf="isEditable">
            <i class="fal fa-copy"></i>
        </button>
    </div>
</div>