<app-shared-sidebar-header>
    <div slot="bar" class="tools-bar d-flex align-items-center">
        <div slot="title">
            <h2 class="mb-0">{{ title }}</h2>
        </div>
        <div class="tool ml-5" *ngIf="enableQuotes">
            <div class="switch mr-1">
                <input id="switch" type="checkbox" [ngModel]="answersModalSupportService.activeQuotes$.value" (change)="activeQuotesSwitch($event.target.checked)">
                <span (click)="activeQuotesSwitch(!answersModalSupportService.activeQuotes$.value)" class="slider round"></span>
                <label for="switch">{{ 'ACTIVATE_QUOTES' | translate }}</label>
            </div>

        </div>
    </div>
</app-shared-sidebar-header>