import { Pipe, PipeTransform } from '@angular/core';
import { StorageService } from '../services/storage.service';

@Pipe({
    name: 'secondsToMinutesSeconds'
})

export class SecondsToMinutesSecondsPipe implements PipeTransform {
    constructor(private storageService: StorageService) {

    }
    transform(value: number): any {
        if (value) {
            return new Date(value * 1000).toISOString().substr(14, 5);
        }
        return '00:00';
    }
}
