<div *ngIf="form" class="p-5">
    <div *ngIf="!success">
        <h5>{{ 'YOU_ARE_SENDING_PRIVATE_MESSAGE_TO_S' | translate:({param1: 
            user.nickname ? user.nickname : user.firstname ? user.firstname : '--' } | ParamsTranslate) }}:</h5>
        <h5 class="mt-5">{{ 'WRITE_YOUR_MESSAGE' | translate }}</h5>
        <app-answer-wysiwyg [form]="form" [formEntityName]="'text'"></app-answer-wysiwyg>
        <a (click)="cancel()">{{ 'CANCEL' | translate }}</a>
        <button class="btn btn-success ml-3" (click)="send()">
            <span *ngIf="loading" class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </span>
            <span>{{ 'SEND' | translate }}</span>
        </button>
    </div>
    <div *ngIf="success" class="text-center">
        <div class="alert alert-success">
            {{ 'MESSAGE_SENT_CORRECTLY' | translate }}
        </div>
        <a (click)="cancel()">{{ 'CLOSE' | translate }}</a>
    </div>
</div>