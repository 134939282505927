<div class="px-5 modal-big" *ngIf="!operation('research-submit').success">
    <form *ngIf="form" [formGroup]="form">
        <div class="row">
            <div class="col-md-7">
                <h2>{{ 'NEW_RESEARCH.TITLE' | translate }}</h2>
                <p class="bold">
                    {{ 'NEW_RESEARCH.QUESTION1' | translate }}
                    <span class="info-tooltip" placement="top" [ngbTooltip]="'TOOLTIPS.NEW_RESEARCH_INTERVIEWEDS_NUMBER' | translate">
                        <i class="far fa-info-circle"></i>
                    </span>
                </p>

                <div class="radio-sicche">
                    <input type="radio" formControlName="nr_interviewed" id="radio-1" name="nr_interviewed" value="25">
                    <label for="radio-1" class="radio-sicche with-border with-hover">{{ 'NEW_RESEARCH.SELECTIONS.U25' | translate }}</label>
                </div>
                <div class="radio-sicche">
                    <input type="radio" formControlName="nr_interviewed" id="radio-2" name="nr_interviewed" value="50">
                    <label for="radio-2" class="radio-sicche with-border with-hover">{{ 'NEW_RESEARCH.SELECTIONS.U50' | translate }}</label>
                </div>
                <div class="radio-sicche">
                    <input type="radio" formControlName="nr_interviewed" id="radio-3" name="nr_interviewed" value="100">
                    <label for="radio-3" class="radio-sicche with-border with-hover">{{ 'NEW_RESEARCH.SELECTIONS.U100' | translate }}</label>
                </div>
                <div class="radio-sicche">
                    <input type="radio" formControlName="nr_interviewed" id="radio-4" name="nr_interviewed" value="200">
                    <label for="radio-4" class="radio-sicche with-border with-hover">{{ 'NEW_RESEARCH.SELECTIONS.U200' | translate }}</label>
                </div>
                <div class="mt-4">
                    <p class="bold" [innerHTML]="'NEW_RESEARCH.QUESTION2' | translate"></p>
                    <div class="radio-sicche">
                        <input type="radio" formControlName="nr_effective_days" id="radio-b-1" name="nr_effective_days" value="1">
                        <label for="radio-b-1"  class="radio-sicche with-border with-hover">{{ 'NEW_RESEARCH.SELECTIONS.P1' | translate }}</label>
                    </div>
                    <div class="radio-sicche">
                        <input type="radio" formControlName="nr_effective_days" id="radio-b-2" name="nr_effective_days" value="2">
                        <label for="radio-b-2"  class="radio-sicche with-border with-hover">{{ 'NEW_RESEARCH.SELECTIONS.P2' | translate }}</label>
                    </div>
                    <div class="radio-sicche">
                        <input type="radio" formControlName="nr_effective_days" id="radio-b-4" name="nr_effective_days" value="4">
                        <label for="radio-b-4"  class="radio-sicche with-border with-hover">{{ 'NEW_RESEARCH.SELECTIONS.P4' | translate }}</label>
                    </div>
                    <div class="radio-sicche">
                        <input type="radio" formControlName="nr_effective_days" id="radio-b-7" name="nr_effective_days" value="7">
                        <label for="radio-b-7" class="radio-sicche with-border with-hover">{{ 'NEW_RESEARCH.SELECTIONS.P7' | translate }}</label>
                    </div>
                    <div class="radio-sicche">
                        <input type="radio" formControlName="nr_effective_days" id="radio-b-15" name="nr_effective_days" value="15">
                        <label for="radio-b-15" class="radio-sicche with-border with-hover">{{ 'NEW_RESEARCH.SELECTIONS.P15' | translate }}</label>
                    </div>
                    <!-- <div class="radio-sicche">
                        <input type="radio" formControlName="nr_effective_days" id="radio-b-4" name="nr_effective_days" value="30">
                        <label for="radio-b-4" class="radio-sicche with-border with-hover">{{ 'NEW_RESEARCH.SELECTIONS.P30' | translate }}</label>
                    </div> -->
                    <div class="radio-sicche">
                        <!-- <input type="radio" formControlName="nr_effective_days" id="radio-b-5" name="nr_effective_days" value="90">
                        <label for="radio-b-5" class="radio-sicche with-border with-hover">{{ 'NEW_RESEARCH.SELECTIONS.P90' | translate }}</label> -->
                        <span class="ml-4" [innerHTML]="'COMMUNITY_OR_LONG_TERM' | translate"></span>
                    </div>
                </div>
                <div class="mt-5">
                    <p class="bold" [innerHtml]="'NEW_RESEARCH_PARAGRAPH_3' | translate "></p>
                    <div formArrayName="countries">
                        <div *ngFor="let row of form.get('countries')['controls']; let i = index;" class="country-lang-row">
                            <div class="row">
                                <div class="col-4">
                                    <div class="form-group">
                                        <div>
                                            <app-core-select
                                                [classes]="'form-control'"
                                                [placeholder]="'COUNTRY'"
                                                [cache]="true"
                                                [form]="form.get('countries')['controls'][i]"
                                                [formEntityName]="'country_code'"
                                                [service]="countriesService"
                                                [label]="'name'"
                                                [value]="'country_code'"
                                                [searchable]="true"
                                                (change)="updateTimezone($event, i)"
                                                >
                                            </app-core-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group">
                                        <div>
                                            <app-core-select
                                                [classes]="'form-control'"
                                                [placeholder]="'LANG'"
                                                [cache]="true"
                                                [formEntityName]="'language_code'"
                                                [form]="form.get('countries')['controls'][i]"
                                                [value]="'language_code'"
                                                [searchable]="true"
                                                [customList]="availableLanguages">
                                            </app-core-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <div>
                                            <app-core-select
                                                [classes]="'form-control'"
                                                [cache]="true"
                                                [form]="form.get('countries')['controls'][i]"
                                                [formEntityName]="'timezone'"
                                                [service]="timezoneService"
                                                [label]="'name'"
                                                [value]="'value'"
                                                [searchable]="true"
                                                >
                                            </app-core-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-1 text-center form-group">
                                    <div>
                                        <i (click)="removeCountryLang(i)" *ngIf="i >= 1" class="fal fa-minus-circle mr-2 as-link" style="font-size: 28px;"></i>
                                        <i
                                            (click)="addCountryLang()"
                                            *ngIf="(i+1) === form.get('countries')['controls'].length && i+1 < maxCountries && form.get('countries')['controls'][i].value.language_code && form.get('countries')['controls'][i].value.country_code"
                                            class="fal fa-plus-circle as-link"
                                            style="font-size: 28px;"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="services-checkboxes">
                    <p class="bold mb-2">{{ 'EXTRA_SERVICES' | translate }}</p>
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="check-radio-box">
                                <input type="checkbox" id="check1" formControlName="extra_track_loading">
                                <label for="check1">
                                    {{ 'TRACK_LOADING' | translate }}
                                </label>
                                <span class="info-tooltip" placement="top" [ngbTooltip]="'TOOLTIPS.TRACK_LOADING' | translate">
                                    <i class="far fa-info-circle"></i>
                                </span>
                            </div>
                        </div>
                        <div class="col-sm-8">
                            <div class="check-radio-box">
                                <input type="checkbox" id="check2" formControlName="extra_set_team">
                                <label for="check2">
                                    {{ 'PART_LOADING' | translate }}
                                </label>
                                <span class="info-tooltip" placement="top" [ngbTooltip]="'TOOLTIPS.USERS_UPLOAD' | translate">
                                    <i class="far fa-info-circle"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="check-radio-box">
                                <input type="checkbox" id="check3" formControlName="extra_video_editing">
                                <label for="check3">
                                    {{ 'VIDEO_EDITING' | translate }}
                                </label>
                                <span class="info-tooltip" placement="top" [ngbTooltip]="'TRACK_OPTIMIZATION_TOOLTIP' | translate">
                                    <i class="far fa-info-circle"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="card shadow mb-2">
                    <div class="card-body">
                        <h4 class="mt-0">{{ 'NEW_RESEARCH.GIVENAME' | translate }}</h4>
                        <p>{{ 'NEW_RESEARCH.GIVENAME_INFO' | translate }}</p>
                        <div class="form-group">
                            <div class="form-group">
                                <div class="form-group">
                                    <label>
                                        <input type="text" formControlName="title" class="form-control" id="title">
                                        <span class="placeholder">{{ 'NEW_RESEARCH.RESEARCH_NAME' | translate }}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card shadow">
                    <div class="card-body">
                        <h5 class="bold mb-4">{{ 'NEW_RESEARCH.ACTIVATION_PRICE' | translate }}:</h5>

                        <div class="text-center">
                            <h1 *ngIf="priceData" class="mb-0 price-line mb-2" [ngClass]="{'discounted': priceData?.discount}" >
                                <span class="normal-price">{{ priceData.original_price }}&euro;</span>
                                <span class="discounted-price" *ngIf="priceData?.final_price && priceData?.discount">&nbsp;{{ priceData.final_price }}&euro;</span>
                            </h1>
                            <p class="light">{{ 'IVA_EXCLUDED' | translate }}</p>
                        </div>

                        <div class="row mt-4">
                            <div class="col-xl-7">
                                <div class="form-group" [ngClass]="{'is-success': discountIsValid, 'is-invalid': discountIsValid === false}">
                                    <label>
                                        <input formControlName="discount_code" type="text" class="form-control" id="discount_code">
                                        <span class="placeholder">{{ 'DISCOUNT_CODE' | translate }}</span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-xl-5">
                                <button [disabled]="!form.value.discount_code" class="btn-sicche btn-secondary" (click)="verifyDiscountCode()">
                                    <app-core-spinner [style]="'small'" [toggle]="operation('checking-code').submitting"></app-core-spinner>
                                    <span *ngIf="!operation('checking-code').submitting">
                                        <i class="fal fa-check" style="font-size: 16px;"></i> {{ 'VERIFY' | translate }}
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <app-core-alert
                                    [msgSuccess]="'VALID_DISCOUNT_CODE_O' | translate:({param1: discount?.value} | ParamsTranslate)"
                                    [msgDanger]="'INVALID_DISCOUNT_CODE'"
                                    [conditionBoolean]="discountIsValid">
                                </app-core-alert>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-12 text-center p-2">
                                <app-core-button-submit
                                    class="w-100 mt-1 d-block"
                                    [classes]="'btn-sicche mx-auto btn-rounded with-border btn-light-shadow active w-100'"
                                    [spinnerDimension]="'small'"
                                    [form]="form"
                                    (submitCallback)="submit()"
                                    [toggle]="operation('research-submit').submitting">
                                    <i class="fal fa-paper-plane" style="font-size: 16px;"></i> {{ 'REQUEST_RESEARCH_ACTIVATION' | translate }}
                                </app-core-button-submit>
                                <p class="as-link mt-2 underline" (click)="createForm()">{{ 'RESET_ENG' | translate }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div *ngIf="operation('research-submit').success">
    <div class="va success-panel text-center">
        <div>
            <i class="far fa-check" style="font-size: 48px;"></i>
        </div>
        <h3>{{ 'NEW_RESEARCH.REQUEST_HAS_BEEN_SENT' | translate }}</h3>
        <p>{{ 'NEW_RESEARCH.ACTIVATED_P1' | translate:({param1: authService.user.email, param2: infoService.mails.finance } | ParamsTranslate) }}</p>
        <p></p>
        <p>{{ 'NEW_RESEARCH.ACTIVATED_P2' | translate }}</p>
        <br/>
        <a class="as-link" (click)="closeModal()">{{ 'OK_I_UNDERSTAND' | translate }}</a>
    </div>
</div>