import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from '@app/core/services/modal.service';
import { ModalComponent } from '@app/core/components/modal/modal.component';
import { ActivatedRoute } from '@angular/router';
import { CountryModel } from '@app/shared/models/country.model';
import { CountryService } from '@app/shared/services/country.service';
import { ResponseModel } from '@app/core/models/response.model';

@Component({
  selector: 'app-check-setup',
  templateUrl: './check-setup.component.html',
  styleUrls: ['./check-setup.component.scss']
})
export class CheckSetupComponent implements OnInit {
  isOpened: boolean;
  percentage: number;

  constructor(
    private modalService: ModalService,
    private activatedRoute: ActivatedRoute,
    private countryService: CountryService
  ) { }

  countryUniqid: string;
  @Input() country: CountryModel;


  ngOnInit(): void {
    this.isOpened = false;
  }

  toggleOpen() {
    this.isOpened = !this.isOpened;
  }


  resetSetup() {
    this.modalService.open({title: 'ARE_YOU_SURE.?',
        btnOkText: 'YES',
        btnCancelText: 'CANCEL',
        showBtnClose: false,
        showCancelBtn: true,
        customClass: 'confirm-modal',
        message: 'ARE_YOU_SURE_DELETE.?'
      }).then((data) => {
        if (data) {
          this.country.logo_del = true;
          this.country.title = null;
          this.country.color = null;
          this.country.privacy_html = null;
          this.countryService.updateCountry(this.country).subscribe((res: ResponseModel) => {
            window.location.reload();
          });
        }
    });
  }

}
