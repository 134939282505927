// Angular
import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';

/*
// ! Add it only if you want to prevent default urlencode of angular
import { CustomUrlSerializer } from './helpers/url-serializer';
import {UrlSerializer} from '@angular/router';
*/

// "ngx-flickity":

// Locales
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeIt, 'it-It');

// Third party
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import {TranslateModule, TranslateLoader } from '@ngx-translate/core'; // INFO: need to be imported in every child module
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}
import { DragScrollModule } from 'ngx-drag-scroll';
import { SidebarModule } from 'ng-sidebar';
import 'hammerjs';
import { NgScrollbarModule } from 'ngx-scrollbar';

// Helpers
import { DateUtil } from './helpers/date-util';

// Pipes
import { PrintPipe } from './pipes/print';
import { DateToAgePipe } from './pipes/date-to-age.pipe';
import { StripHtmlPipe } from './pipes/striphtml.pipe';
import { ParamsTranslatePipe } from './pipes/params-translate.pipe';
import { DateToLocaleStringPipe } from './pipes/date-to-locale-string.pipe';
import { DatetimeToDbPipe } from './pipes/datetime-to-db.pipe';
import { DatetimeFromDB } from './pipes/datetime-from-db.pipe';
import { SecondsToMinutesSecondsPipe } from './pipes/seconds-to-minutes-seconds.pipe';
import { DateToDayMonth } from './pipes/date-to-day-month.pipe';
import { PrivateSrcPipe } from './pipes/private-src.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { StriphtmlKeepSpacesPipe } from './pipes/striphtml-keepspace.pipe';
import { DateToHourMinutes } from './pipes/date-to-hour-minutes.pipe';
import { SecondsToTimePipe } from './pipes/seconds-to-time.pipe';
import { NewLineToBrPipe } from './pipes/newLineToBr.pipe';

// Components
import { ModalComponent } from './components/modal/modal.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ButtonSubmitComponent } from './components/buttons/submit/submit.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TableComponent } from './components/table/table.component';
import { SortbyComponent } from './components/sortby/sortby.component';
import { LightboxModalComponent } from './components/lightbox-modal/lightbox-modal.component';
import { SelectComponent } from './components/select/select.component';
import { LayoutComponent } from './components/layout/layout.component';
import { AlertComponent } from './components/alert/alert.component';

// Service providers
import { StorageService } from './services/storage.service';
@NgModule({
    declarations: [
        PrintPipe,
        StripHtmlPipe,
        DateToAgePipe,
        ParamsTranslatePipe,
        ModalComponent,
        ToolbarComponent,
        SidebarComponent,
        ButtonSubmitComponent,
        SpinnerComponent,
        TableComponent,
        SortbyComponent,
        LightboxModalComponent,
        SelectComponent,
        LayoutComponent,
        DateToLocaleStringPipe,
        PrivateSrcPipe,
        DatetimeToDbPipe,
        DatetimeFromDB,
        SecondsToMinutesSecondsPipe,
        StriphtmlKeepSpacesPipe,
        DateToDayMonth,
        AlertComponent,
        TruncatePipe,
        DateToHourMinutes,
        SecondsToTimePipe,
        NewLineToBrPipe
    ],
    imports: [
        CommonModule,
        NgScrollbarModule,
        NgbModule,
        FormsModule,
        BrowserModule,
        RouterModule,
        DragScrollModule,
        LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG }),
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        NgSelectModule,
        SidebarModule.forRoot(),
        // To modify the setup please import the ToastrModule also in shared.module and change its properties
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-right',
            closeButton: true,
            tapToDismiss: true
        }),
        TranslateModule.forRoot({
            defaultLanguage: 'it',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
    ],
    providers: [
        { provide: NgbDateParserFormatter, useClass: DateUtil },
        /*{ provide: UrlSerializer, useClass: CustomUrlSerializer },*/
        StorageService,
        DatetimeToDbPipe,
        DatetimeFromDB,
        SecondsToMinutesSecondsPipe,
        DateToDayMonth,
        DateToLocaleStringPipe,
        StripHtmlPipe,
        PrivateSrcPipe,
        SecondsToTimePipe,
        DateToHourMinutes,
        NewLineToBrPipe
    ],
    exports: [
        CommonModule,
        PrintPipe,
        ParamsTranslatePipe,
        StripHtmlPipe,
        DateToAgePipe,
        FormsModule,
        TranslateModule,
        RouterModule,
        ModalComponent,
        ToolbarComponent,
        SidebarComponent,
        LayoutComponent,
        SelectComponent,
        TableComponent,
        SortbyComponent,
        LightboxModalComponent,
        ButtonSubmitComponent,
        NgbModule,
        SpinnerComponent,
        AlertComponent,
        BrowserAnimationsModule,
        ToastrModule,
        NgSelectModule,
        DragScrollModule,
        StriphtmlKeepSpacesPipe,
        SidebarModule,
        DateToLocaleStringPipe,
        PrivateSrcPipe,
        DatetimeToDbPipe,
        DatetimeFromDB,
        SecondsToMinutesSecondsPipe,
        DateToDayMonth,
        TruncatePipe,
        DateToHourMinutes,
        NewLineToBrPipe,
        SecondsToTimePipe,
        NgScrollbarModule
    ]
})
export class CoreModule { }
