import { BaseModel } from '@app/core/models/base';


export class AnalysisModel extends BaseModel {

    static getResourceName() {
        return 'reports/analisi-export';
    }

}
