<form [formGroup]="quoteForm">
    <div class="answer-selection-modal panel shadow">
        <div class="d-flex align-items-center justify-content-between mb-4 close-button w-100" (click)="closeModal()">
            <h4 class="mb-0 text-left">{{ 'ADD_QUOTES_TITLE' | translate }}</h4>
            <i class="fal fa-times cross cursor-pointer"></i>
        </div>
        <div class="mb-3">
            <small [innerHTML]="'SELECT_QUOTES_TITLE' | translate"></small>
        </div>
        <div class="form-group mb-3">
            <textarea formControlName="quote" placeholder="Estratto" class="form-control"></textarea>
        </div>
        <div class="form-group mb-3 d-none">
            <app-core-select 
                [classes]="'form-control'" 
                [placeholder]="'TAG'" 
                [cache]="true"
                [multiple]="true"
                [form]="quoteForm"
                [formEntityName]="'quote_tags'"
                [searchable]="true">
            </app-core-select>
        </div>
        <div class="form-group mb-3">
            <textarea formControlName="note" placeholder="Note" class="form-control"></textarea>
        </div>
        <div>
            <div class="color-radios">
                <input class="d-none" type="radio" formControlName="color" id="radio-1" name="color" value="red">
                <label for="radio-1" class="red-dark"></label>
            </div>
            <div class="color-radios">
                <input class="d-none" type="radio" formControlName="color" id="radio-2" name="color" value="orange">
                <label for="radio-2" class="orange-dark"></label>
            </div>
            <div class="color-radios">
                <input class="d-none" type="radio" formControlName="color" id="radio-3" name="color" value="yellow">
                <label for="radio-3" class="yellow-dark"></label>
            </div>
            <div class="color-radios">
                <input class="d-none" type="radio" formControlName="color" id="radio-4" name="color" value="green">
                <label for="radio-4" class="green-dark"></label>
            </div>
            <div class="color-radios">
                <input class="d-none" type="radio" formControlName="color" id="radio-5" name="color" value="azure">
                <label for="radio-5" class="azure-dark"></label>
            </div>
        </div>
        <div class="text-right">
            <span *ngIf="submitting" class="spinner-border spinner-border-sm" role="status">
                <span class="sr-only">Loading...</span>
            </span>
            <button [disabled]="!quoteForm.valid" class="btn btn-success" (click)="saveExcerpt()">
                <span *ngIf="submitting" class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only">Loading...</span>
                </span>
                {{ 'SAVE' | translate }}
            </button>
        </div>
    </div>
</form>