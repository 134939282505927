<ng-container *ngIf="answer">
  <div class="img-card-box d-flex flex-wrap justify-content-center p-0 {{ classes }}" *ngIf="answer.answer_data  && !itemToModify">
    <div class="mt-3 px-2" *ngFor="let item of question.question_data.answers; let i = index">
      <div class="card" *ngIf="answerContains(answer.answer_data, item.id)">
        <img class="card-img-top" *ngIf="item.image_url" [src]="item.image_url | privatesrc">
        <!-- <div *ngIf="item.image_url && explicitMediaUrls" class="explicit-media-url">
          <a [href]="item.image_url">{{ 'MEDIA_DOWNLOAD' | translate }}</a>
        </div> -->
        <div class="card-body d-flex align-items-center justify-content-center">
          <div class="answer-detail text-center">
            <div class="comment">{{ item.comment }}</div>
            <blockquote class="user-comment" (mouseup)="selectText()" [innerHTML]="userAnswerComment(answer.answer_data, item.id)"></blockquote>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="itemToModify === answer.id" class="row mt-4">
    <div class="col-6">
      <form [formGroup]="form" style="float:left;">
        <span class="placeholder">{{ 'COMMENT' | translate }}</span>
        <div *ngFor="let c of form.controls; let i = index" class="form-group">
          <textarea cols="50" class="form-control" [formControlName]="i"></textarea>
        </div>
      </form>
      <button class="btn btn-success" (click)="saveMods()">
        <span *ngIf="operation('saving').submitting" class="spinner-border spinner-border-sm" role="status">
          <span class="sr-only">Loading...</span>
        </span>
        {{ 'SAVE' | translate }}
      </button>
    </div>
  </div>

</ng-container>
