import { ResearchModel } from './../models/research.model';
import { Injectable } from '@angular/core';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { BaseService } from '@app/core/services/base.service';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { DiscountModel } from '../models/discount.model';
import { BehaviorSubject } from 'rxjs';
import { ServiceMessageModel } from '@app/core/models/service-message.model';
import { SearchParams } from '@app/core/models/search-params';
import { RoomModel } from '../models/room.model';
import { CountryService } from './country.service';
import { DatetimeToDbPipe } from '@app/core/pipes/datetime-to-db.pipe';


@Injectable({
  providedIn: 'root'
})
export class RoomsService extends BaseService implements BaseServiceI {

  // tslint:disable: variable-name
  message$: BehaviorSubject<ServiceMessageModel> = new BehaviorSubject(null);

  constructor(public router: Router, public apiService: ApiService, private http: HttpClient, private countryService: CountryService, private datetimeToDbPipe: DatetimeToDbPipe) {
    super(router, apiService, RoomModel);
  }

  getRoomsByCountryUniqid(country_uniqid: string) {
      return this.get('', {country_uniqid});
  }

  createRoom(room: RoomModel) {
    room.title = room.title || 'Untitled';
    room.is_room_group = true;
    return this.create(room);
  }

  updateRoom(room: RoomModel) {
    return this.sub(room.id.toString()).create(room);
  }

  getRoomById(roomId: number) {
    return this.sub(roomId.toString()).get();
  }

  deleteRoom(roomid) {
    return this.deleteById('/rooms/', roomid);
  }

  duplicateRoom(roomid) {
    return this.sub(roomid + '/clone').create({});
  }

}
