import { Component, OnInit, Input } from '@angular/core';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { AnalysisService } from '@app/shared/services/analysis.service';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { ResponseModel } from '@app/core/models/response.model';
import { AnswerModel } from '@app/shared/models/answer.model';
import { QuoteModel } from '@app/shared/models/quote.model';
import { AnswersService } from '@app/shared/services/answers.service';
import { ToastrService } from 'ngx-toastr';
import { PrivateSrcPipe } from '@app/core/pipes/private-src.pipe';
import { environment } from '../../../../../../../../../../environments/environment';
import { CountryService } from '@app/shared/services/country.service';
import { TeamService } from '@app/shared/services/team.service';

@Component({
  selector: 'app-overview-modal-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.scss']
})
export class OverviewModalQuotesComponent extends CoreBaseComponent implements OnInit {

  @Input()
  type: string;
  @Input()
  countryUniqId = null;
  public quotes: any[] = []; // quote model
  public originalQuotes: any[] = [];
  public filterForm: UntypedFormGroup = null;
  public exportForm: UntypedFormGroup = null;
  public answers: AnswerModel[] = [];
  public saving: number = null;
  public excelDownloadUrl: string = null;
  public quoteToDownload: QuoteModel = null;
  public isSmart = false;

  constructor(private coreLayoutService: CoreLayoutService, private translate: TranslateService, private privateSrcPipe: PrivateSrcPipe,
              public analysisService: AnalysisService, private answersService: AnswersService, private toastService: ToastrService,
              private translateService: TranslateService, private countryService: CountryService, private fb: UntypedFormBuilder,
              private teamService: TeamService) {
                super();
              }

  ngOnInit(): void {
    this.isSmart = this.countryService.currentCountryIsClientBasic();
    this.coreLayoutService.title('QUOTES');
    // Filter form
    this.filterForm = new UntypedFormGroup({
      room: new UntypedFormControl('', null),
      block: new UntypedFormControl('', null),
      question: new UntypedFormControl('', null),
      sample_variable_tags: new UntypedFormControl('', null),
      personas: new UntypedFormControl('', null),
      block_datetime: new UntypedFormControl('', null),
      color_green: new UntypedFormControl(false, null),
      color_yellow: new UntypedFormControl(false, null),
      color_azure: new UntypedFormControl(false, null),
      color_grey: new UntypedFormControl(false, null),
      color_red: new UntypedFormControl(false, null),
      color_orange: new UntypedFormControl(false, null),
      rating: new UntypedFormControl('', null),
      only_highlighted: new UntypedFormControl(false, null),
      answer_tags: new UntypedFormControl('', null),
      intervieweds: new UntypedFormControl('', null),
    });
    this.exportForm = new UntypedFormGroup({
      photo: new UntypedFormControl('', null),
      data: new UntypedFormControl('', null),
      question: new UntypedFormControl('', null),
      format: new UntypedFormControl(2, Validators.required),
      template: new UntypedFormControl(1, Validators.required),
      nicknameorfullname: new UntypedFormControl('', null)
    });
    // this.getQuotesByFilter();
  }

  nextTemplate() {
    let selectedTemplate = this.exportForm.value.template;
    if (selectedTemplate >= 3) {
      selectedTemplate = 1;
    } else {
      selectedTemplate++;
    }
    this.exportForm.patchValue({
      template: selectedTemplate
    });
  }

  saveMods(quote: QuoteModel) {
    this.saving = quote.id;
    this.answersService.updateQuote(quote.id, quote).subscribe((res: ResponseModel) => {
      this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
      this.saving = null;
    }, (e) => {
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
      this.saving = null;
    });
  }

  clearFilter(name: string) {
    this.filterForm.controls[name].patchValue('');
    this.getQuotesByFilter();
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  public checkModifications(quoteIndex: number) {
    if((this.originalQuotes[quoteIndex].quote == this.quotes[quoteIndex].quote
      || (this.originalQuotes[quoteIndex].quote == null && this.quotes[quoteIndex].quote == '')
      || (this.originalQuotes[quoteIndex].quote == '' && this.quotes[quoteIndex].quote == null))
      && (this.originalQuotes[quoteIndex].note == this.quotes[quoteIndex].note
      || (this.originalQuotes[quoteIndex].note == '' && this.quotes[quoteIndex].note == null)
      || (this.originalQuotes[quoteIndex].note == null && this.quotes[quoteIndex].note == ''))) {
      this.quotes[quoteIndex].toBeSaved = false;
    } else {
      this.quotes[quoteIndex].toBeSaved = true;
    }
  }

  getQuotesByFilter() {
    let date = '';
    if (this.filterForm.value.block_datetime) {
      date = this.formatDate(this.filterForm.value.block_datetime.date);
    }
    const all_rooms = this.filterForm.value.room ? this.filterForm.value.room.length == 1 && this.filterForm.value.room[0].id == 0 ? true : false : false;
    this.operation('getting-quotes').reset().isSubmitting();
    const filter = {
      all_rooms,
      only_highlighted: this.filterForm.value.only_highlighted,
      only_contest: this.filterForm.value.only_contest,
      only_live: this.filterForm.value.only_live,
      keyword_search_content: this.filterForm.value.keyword_search_content,
      intervieweds: this.filterForm.value.intervieweds,
      colors: this.getColors(), // todo mod
      /*
      color_green: this.filterForm.value.color_green,
      color_red: this.filterForm.value.color_red,
      color_orange: this.filterForm.value.color_orange,
      color_yellow: this.filterForm.value.color_yellow,
      color_azure: this.filterForm.value.color_azure,
      */
      rating: this.filterForm.value.rating ? this.filterForm.value.rating : '',
      questionid: this.filterForm.value.question ? this.filterForm.value.question.id : null,
      block_datetime: date,
      'answer_tags_by_comma': this.filterForm.value.answer_tags ? this.filterForm.value.answer_tags : null,
      'roomids_by_comma': Array.isArray(this.filterForm.value.room) ? this.filterForm.value.room.length ? this.filterForm.value.room.map(item => item.id) : null : null,
      'blockids_by_comma': this.filterForm.value.block ? this.filterForm.value.block.map(item => item.id) : null,
      'team_personas_tags_by_comma': this.filterForm.value.personas ? this.filterForm.value.personas : null,
      'team_sample_variable_tags_by_comma': this.filterForm.value.sample_variable_tags ? this.filterForm.value.sample_variable_tags : null
    };
    Object.keys(filter).forEach((key) => (!filter[key]) && delete filter[key]);
    this.analysisService.getQuotesBy(this.countryUniqId, filter).subscribe((res: ResponseModel) => {
      res.getData().map(quote => quote.question_title = quote.question_title.replace(/<[^>]+>/g, ''));
      this.quotes = res.getData();
      this.quotes.map(quote => quote.state.selected = false );
      this.originalQuotes = JSON.parse(JSON.stringify(this.quotes));
      this.operation('getting-quotes').isSuccess();
    }, (e) => {
      this.operation('getting-quotes').isFailed();
    });
  }

  private getColors() {  // todo mod
    const output = [];
    if (this.filterForm.value.color_orange) {
      output.push('orange');
    }
    if (this.filterForm.value.color_green) {
      output.push('green');
    }
    if (this.filterForm.value.color_red) {
      output.push('red');
    }
    if (this.filterForm.value.color_yellow) {
      output.push('yellow');
    }
    if (this.filterForm.value.color_azure) {
      output.push('azure');
    }
    if (this.filterForm.value.color_grey) {
      output.push('grey');
    }
    return output.length ? output.toString() : null;
  }

  selectAll(event: any) {
    this.quotes.map((quote: QuoteModel) => event.target.checked ? quote.state.selected = true : quote.state.selected = false);
  }

  hasSelected() {
    return this.quotes.find((quote: QuoteModel) => quote.state.selected === true);
  }

  exportXls(quoteIds: string[]) {
    this.analysisService.getQuotesXlsExport(this.countryUniqId, quoteIds).subscribe((res: ResponseModel) => {
      this.toastService.success(this.translate.instant('PDF_EXPORT_ALL_EMAIL_SENT', {email: this.teamService.currentUser.email}), null, {timeOut: 10000});
    })
  }

  export(onlySelected = false) {
    const quoteids = [];
    if (!this.quoteToDownload) {
      this.quotes.forEach((quote: QuoteModel) => {
        if (quote.state.selected) {
          quoteids.push(quote.id);
        }
      });
    } else {
      quoteids.push(this.quoteToDownload.id);
    }
    const filter: {
        download_pdf?: boolean,
        download_png?: boolean,
        download_xls?: boolean,
        'quoteidsByComma': Array<any>,
        'include_fieldsByComma'?: Array<any>,
        format?: number,
        template: number
      } = {
        quoteidsByComma: quoteids,
        include_fieldsByComma: [],
        format: 1,
        template: this.exportForm.value.template
    };

    if (this.exportForm.value.format === 3) {
      return this.exportXls(quoteids);
    }
    if (this.exportForm.value.format === 2) {
      filter.download_pdf = true;
    }
    if (this.exportForm.value.format === 1) {
      filter.download_png = true;
    }
    /*
    if (this.exportForm.value.firstnamelastname) {
      filter['include_fieldsByComma'].push('firstname_and_lastname');
    }
    if (this.exportForm.value.nickname) {
      filter['include_fieldsByComma'].push('nickname');
    }
    */
    if (this.exportForm.value.nicknameorfullname) {
      filter.include_fieldsByComma.push(this.exportForm.value.nicknameorfullname);
    }
    if (this.exportForm.value.photo) {
      filter.include_fieldsByComma.push('avatar');
    }
    if (this.exportForm.value.data) {
      filter.include_fieldsByComma.push('quote_date');
    }
    if (this.exportForm.value.question) {
      filter.include_fieldsByComma.push('question');
    }
    this.analysisService.getDownloadQuotesBy(this.countryUniqId, filter).subscribe((res: ResponseModel) => {
      res.download(this.exportForm.value.format === 2 ? 'export.pdf' : 'export.zip');
    }, (e) => {
      this.toastService.error(this.translateService.instant('INVALID_DATE'));
    });
  }

}
