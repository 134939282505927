
<div *ngIf="!exportableVersion" class="panel shadow p-0 mt-3" [ngClass]="{'deleted': deleted, 'highlight': highlighted}">
    <div class="panel-header d-flex align-items-center">
        <div class="avatar-wrapper">
            <div class="avatar-frame">
                <div class="avatar">
                    <app-shared-avatar [imageUrl]="commentAnswer.team.avatar_url"></app-shared-avatar>
                </div>
                <div class="ml-2">
                    <h3 class="d-block mb-0 name">
                        <ng-container *ngIf="!impersonal && authService.user">
                            <span *ngIf="commentAnswer.creatoruserid == authService.user.id">{{ 'YOU' | translate }}, {{ 'MODERATOR' | translate | lowercase }}</span>
                            <span *ngIf="commentAnswer.creatoruserid != authService.user.id">{{ commentAnswer.team.nickname ? commentAnswer.team.nickname : commentAnswer.team.firstname }}&nbsp;<i *ngIf="commentAnswer.team.is_locked" class="far fa-lock" style="font-size: 15px;"></i><span *ngIf="commentAnswer.team.role == 'RESEARCHER' || commentAnswer.team.role == 'OWNER'">, {{ 'MODERATOR' | translate | lowercase }}</span></span>
                        </ng-container>

                        <ng-container *ngIf="impersonal">
                            <span>{{ commentAnswer.team.nickname ? commentAnswer.team.nickname : commentAnswer.team.firstname }} <span *ngIf="commentAnswer.team.role == 'RESEARCHER' || commentAnswer.team.role == 'OWNER'">, {{ 'MODERATOR' | translate | lowercase }}</span></span>
                        </ng-container>
                    </h3>
                    <p class="d-block reply-time mb-0">
                        {{ impersonal ? (getDate(commentAnswer.creationtime) | dateToLocaleString) : (commentAnswer.creationtime | dateAgo) }}
                        <i *ngIf="!impersonal && commentAnswer.isSmartphone()" class="fal fa-mobile ml-2"></i>
                        <i *ngIf="!impersonal && commentAnswer.isDesktop()" class="far fa-desktop ml-2"></i>
                        <i [ngbTooltip]="'PRIVATE_COMMENT' | translate" *ngIf="commentAnswer.isPrivate()" class="fal fa-eye-slash"></i>
                        <ng-template #tipContent>
                            <div [innerHTML]="answer.question_title"></div>
                        </ng-template>
                        <ng-container *ngIf="verbose && answer">
                            <span> - {{ 'ROOM.ROOM' | translate }} {{ answer.question_data.room_title }} - {{ 'BLOCK.BLOCK' | translate }} {{ answer.question_data.block_title }}
                            - <a class="as-link" [ngbTooltip]="tipContent" triggers="click:blur" [autoClose]="'outside'">{{ 'QUESTION' | translate }}</a></span>
                        </ng-container>
                        <ng-template #tipContentB>
                            <div [innerHTML]="commentAnswer.question_title"></div>
                        </ng-template>
                        <ng-container *ngIf="verbose && !answer">
                            <span> - {{ 'ROOM.ROOM' | translate }} {{ commentAnswer.room_title }} - {{ 'BLOCK.BLOCK' | translate }} {{ commentAnswer.block_title }}
                            - <a class="as-link" [ngbTooltip]="tipContentB" triggers="click:blur" [autoClose]="'outside'">{{ 'QUESTION' | translate }}</a></span>
                        </ng-container>
                    </p>
                </div>
            </div>
        </div>
        <div class="d-flex ml-auto">
            <div [ngbTooltip]="commentAnswer.team.sample_variable_tags?.join(', ')" class="tool-btn mr-5" *ngIf="commentAnswer.team.role != 'RESEARCHER' && commentAnswer.team.role != 'OWNER' && !isCustomerPro">
                <i class="fal fa-tag mr-2 text-primary-color"></i>
                <span [ngClass]="{'with-data': commentAnswer.team.sample_variable_tags?.join(', ')}">Tags</span>
            </div>
        </div>
        <div class="tool-btn mr-5 tags-select-tool" *ngIf="commentAnswer.team.role != 'RESEARCHER' && commentAnswer.team.role != 'OWNER' && !isCustomerPro">
            <ng-template #personasPopover>
                <app-core-select
                    *ngIf="personasForm"
                    [classes]="'form-control'"
                    [form]="personasForm"
                    [readonly]="false"
                    [addTag]="true"
                    [formEntityName]="'personas_tags'"
                    [preselected]="{byprop: getPersonasTag(commentAnswer.team.personas_tags), value: getPersonasTag(commentAnswer.team.personas_tags)}"
                    (change)="personasTagsChange($event)"
                    [searchable]="true"
                    [customList]="getAllPersonasTags()"
                    >
                </app-core-select>
            </ng-template>
            <span [ngbPopover]="personasPopover" [autoClose]="'outside'" [popoverTitle]="'SELECT_PERSONAS' | translate">
                <i class="fal fa-portrait text-primary-color mr-2"></i>
                <span [ngClass]="{'': commentAnswer.team.personas_tags}" >
                    {{ (
                        getPersonasTag(commentAnswer.team.personas_tags)?.length && getPersonasTag(commentAnswer.team.personas_tags)?.toString().toLowerCase()
                            ? getPersonasTag(commentAnswer.team.personas_tags).join(', ').toLowerCase()
                            : 'personas'
                    ) }}
                </span>
            </span>

        </div>
        <div class="tool-btn mr-5" *ngIf="commentAnswer.team.role != 'RESEARCHER' && commentAnswer.team.role != 'OWNER' && !isCustomerPro">
            <ng-template #notesPopover>
                <textarea [(ngModel)]="commentAnswer.team.note" class="form-control">{{ commentAnswer.team.note ? commentAnswer.team.note : null }}</textarea>
                <button class="btn btn-sicche btn-secondary mx-3" (click)="notesChanged()">
                    <span *ngIf="operation('saving-note').submitting" class="spinner-border spinner-border-sm" role="status">
                        <span class="sr-only">Loading...</span>
                    </span>
                    {{ 'SAVE' | translate }}
                </button>
            </ng-template>
            <i class="fal fa-sticky-note mr-2 text-primary-color"></i>
            <span [ngbPopover]="notesPopover" [ngClass]="{'with-data': commentAnswer.team.note}"
                [ngbTooltip]="commentAnswer.team.note"
                [autoClose]="'outside'" [popoverTitle]="'NOTA' | translate">{{ 'NOTE_S' | translate }}</span>
        </div>
        <div *ngIf="selectable" class="right ml-3">
            <div class="form-group check-comment">
                <div>
                    <input [(ngModel)]="commentAnswer.state.selected" type="checkbox" name="" class="form-control">
                </div>
            </div>
        </div>
    </div>



    <div class="panel-body">
        <ng-template #quoteUsers>
            <div>
                <div *ngIf="!intervieweds.length">{{ 'THERE_ARE_NO_INTERVIEWEDS' | translate }}</div>
                <ng-scrollbar>
                    <div *ngFor="let user of intervieweds" class="d-flex align-items-center mb-2">
                        <div class="avatar">
                            <div class="avatar-bg-img" *ngIf="user.avatar_url" [ngStyle]="{'background-image': 'url(' + (user.avatar_url | privatesrc) + ')'}"></div>
                            <div *ngIf="!user.avatar_url" class="d-flex align-items-center justify-content-center placeholder-avatar">
                                <i class="align-self-center fal fa-user"></i>
                            </div>
                        </div>
                        <div class="d-flex align-items-center ml-2">
                            <input type="checkbox" name="" [(ngModel)]="user.state.selected">
                            <span class="pl-2">{{ user.nickname }}</span>
                        </div>
                    </div>
                </ng-scrollbar>
                <ng-container *ngIf="intervieweds.length">
                    <div class="mt-3 text-right"><a (click)="quoteSelectedIntervieweds(quoteUsers)" *ngIf="hasCheckedIntervieweds()">{{ 'QUOTE_SELECTED' | translate }}</a></div>
                    <div class="mt-3 text-right"><a (click)="quoteAllIntervieweds(quoteUsers)" *ngIf="!hasCheckedIntervieweds()">{{ 'QUOTE_EVERYBODY' | translate }}</a></div>
                </ng-container>
            </div>
        </ng-template>
        <div class="message-wrapper">
            <div (mouseup)="selectText()" class="row" *ngIf="itemToModify != commentAnswer.id" >
                <!--<div [ngClass]="{'col-md-12': commentAnswer.hasMedia(), 'col-md-12': !commentAnswer.hasMedia()}">-->
                <div class="col-md-12">
                    <div class="message py-3" [innerHTML]="commentAnswer.comment"></div>
                </div>
                <div *ngIf="commentAnswer.hasVideos() || commentAnswer.hasImages() || commentAnswer.hasAudios()" class="media-comm col-md-12">
                    <app-moderation-answer-comment-media [comment]="commentAnswer"></app-moderation-answer-comment-media>
                </div>
            </div>
            <div class="position-relative" *ngIf="itemToModify === commentAnswer.id">
                <div class="quote-user">
                    <div class="position-relative">
                        <span class="as-link" #qp="ngbPopover" (click)="checkQuotedIntervieweds()"
                            [ngbTooltip]="'QUOTE_USER_TOOLTIP' | translate"
                            [autoClose]="'outside'" [ngbPopover]="quoteUsers" [popoverTitle]="'Cita'">
                            @
                        </span>
                    </div>
                </div>
                <app-answer-wysiwyg [injectText]="textToInject" [form]="form" [formEntityName]="'text'"></app-answer-wysiwyg>
                <button class="btn btn-success" (click)="saveMods()">
                    <span *ngIf="operation('saving').submitting" class="spinner-border spinner-border-sm" role="status">
                        <span class="sr-only">Loading...</span>
                    </span>
                    {{ 'SAVE' | translate }}
                </button>
            </div>
        </div>
    </div>



    <div class="panel-footer" *ngIf="!isCustomerPro && !impersonal">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-8">
                    <div class="d-flex align-items-center justify-content-start" *ngIf="commentAnswer.team.role != 'RESEARCHER' && commentAnswer.team.role != 'OWNER' && !isCustomerPro">
                        <div class="tool tag-tool text-nowrap">
                            <i class="far fa-tag text-primary-color"></i>
                            <span>{{ 'CONTENT_TAG' | translate }}</span>
                        </div>
                        <div class="tool tags-select-tool w-100 pr-2">
                            <app-core-select
                                [classes]="'form-control'"
                                [form]="contentTagsForm"
                                [formEntityName]="'content_tags'"
                                [searchable]="true"
                                [addTag]="true"
                                (change)="tagsChanged($event)"
                                [customList]="contentTagsList"
                                [multiple]="true"
                                >
                            </app-core-select>
                        </div>
                        <div class="tool favourites-tool stars flex-grow-1" *ngIf="commentAnswer.team.role != 'RESEARCHER' && commentAnswer.team.role != 'OWNER' && !isCustomerPro">
                            <app-moderation-starsrating [readonly]="isCustomerPro" [comment]="commentAnswer"></app-moderation-starsrating>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 d-flex align-items-center justify-content-start" *ngIf="!isCustomerPro">
                    <div class="tools row time-date ml-auto">
                        <div class="tool mod-del-tool ml-auto w-100 d-flex">
                            <div style="min-width: 50px;" class="tool like-tool" *ngIf="commentAnswer.team.role != 'RESEARCHER' && commentAnswer.team.role != 'OWNER' && !isCustomerPro">
                                <i class="fal fa-heart text-primary-color" (click)="!readonly ? likeAnswer(commentAnswer) : null" [ngClass]="{'favourite-yes': commentAnswer.currentuser_like_comment}"></i>
                                <span class="total_answer_like" *ngIf="commentAnswer.total_like_to_comment">({{ commentAnswer.total_like_to_comment }})</span>
                            </div>
                            <a *ngIf="!readonly" class="mr-5" (click)="modifyComment(commentAnswer.id)">{{ 'MODIFY' | translate }}</a>
                            <a *ngIf="!readonly" (click)="deleteComment(commentAnswer.id)">
                                <span *ngIf="operation('deleting').submitting" class="spinner-border spinner-border-sm" role="status">
                                    <span class="sr-only"><app-core-spinner></app-core-spinner></span>
                                </span>
                                {{ 'DELETE' | translate }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="exportableVersion" class="panel shadow p-0 mt-3 w-100">
    <div [id]="'answer-block-comment-exportable-' + commentAnswer.id">
        <div class="panel-header d-flex align-items-center h-auto" style="border-bottom: 1px solid #D0D3DF">
            <div class="avatar-wrapper">
                <div class="d-flex">
                    <div class="">
                        <app-shared-avatar [imageUrl]="commentAnswer.team.avatar_url"></app-shared-avatar>
                    </div>
                    <div class="ml-2">
                        <h3 class="d-block mb-0 name">
                            <ng-container *ngIf="!impersonal && authService.user">
                                <span *ngIf="commentAnswer.creatoruserid == authService.user.id">{{ 'YOU' | translate }}, {{ 'MODERATOR' | translate | lowercase }}</span>
                                <span *ngIf="commentAnswer.creatoruserid != authService.user.id">{{ commentAnswer.team.nickname ? commentAnswer.team.nickname : commentAnswer.team.firstname }}<span *ngIf="commentAnswer.team.role == 'RESEARCHER' || commentAnswer.team.role == 'OWNER'">, {{ 'MODERATOR' | translate | lowercase }}</span></span>
                            </ng-container>
                            <ng-container *ngIf="impersonal">
                                <span>{{ commentAnswer.team.nickname ? commentAnswer.team.nickname : commentAnswer.team.firstname }} <span *ngIf="commentAnswer.team.role == 'RESEARCHER' || commentAnswer.team.role == 'OWNER'">, {{ 'MODERATOR' | translate | lowercase }}</span></span>
                            </ng-container>
                        </h3>
                        <p class="d-block reply-time mb-0">
                            {{ parseCompatibilityDate(commentAnswer.creationtime) | dateToLocaleString: true }}
                            <i *ngIf="!impersonal && commentAnswer.isSmartphone()" class="fal fa-mobile ml-2"></i>
                            <i *ngIf="!impersonal && commentAnswer.isDesktop()" class="far fa-desktop ml-2"></i>
                            <i [ngbTooltip]="'PRIVATE_COMMENT' | translate" *ngIf="commentAnswer.isPrivate()" class="fal fa-eye-slash"></i>
                            <ng-container *ngIf="verbose && answer">
                                <span> - <b>{{ 'ROOM.ROOM' | translate }}:</b> {{ answer.question_data.room_title }} - <b>{{ 'BLOCK.BLOCK' | translate }}:</b> {{ answer.question_data.block_title }}
                                - <span><b>{{ 'QUESTION' | translate }}: </b> <span [innerHTML]="answer.question_title | striphtml"></span> </span></span>
                            </ng-container>
                            <ng-container *ngIf="verbose && !answer">
                                <span> - <b>{{ 'ROOM.ROOM' | translate }}:</b> {{ commentAnswer.room_title }} - <b>{{ 'BLOCK.BLOCK' | translate }}:</b> {{ commentAnswer.block_title }}
                                - <span><b>{{ 'QUESTION' | translate }}: </b> <span [innerHTML]="commentAnswer.question_title | striphtml"></span> </span></span>
                            </ng-container>

                        </p>
                        <div class="mt-3">
                            <span><b>{{'SAMPLE_VARIABLE_TAGS' | translate }}:</b> {{ commentAnswer.team.sample_variable_tags ? commentAnswer.team.sample_variable_tags.join(', ').toLowerCase() : '--'}}</span>
                        </div>
                        <div>
                            <span><b>{{'PERSONAS_SHORT' | translate }}:</b> {{ getPersonasTag(commentAnswer.team.personas_tags) ? getPersonasTag(commentAnswer.team.personas_tags).join(', ').toLowerCase() : '--'}}</span>
                        </div>
                        <div>
                            <span><b>{{ 'NOTE_S' | translate}}:</b> {{ commentAnswer.team.note ? commentAnswer.team.note : '--' }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="panel-body">
            <div class="message-wrapper">
                <div class="row">
                    <!--<div [ngClass]="{'col-md-12': commentAnswer.hasMedia(), 'col-md-12': !commentAnswer.hasMedia()}">-->
                    <div class="col-md-12">
                        <div class="message py-3" [innerHTML]="commentAnswer.comment"></div>
                    </div>
                    <div *ngIf="commentAnswer.hasVideos() || commentAnswer.hasImages() || commentAnswer.hasAudios()" class="media-comm col-md-">
                        <app-moderation-answer-comment-media [comment]="commentAnswer"></app-moderation-answer-comment-media>
                    </div>
                </div>
            </div>
        </div>
        <div class="panel-footer">
            <div class="container-fluid">
                <div><div class="row">
                    <div class="col-xl-6 d-flex align-items-start justify-content-start">
                        <div class="tool tag-tool text-nowrap">
                            <span>{{ 'CONTENT_TAG' | translate }}: </span>
                        </div>
                        <div class="tool tags-select-tool w-100 px-2">
                            {{ commentAnswer.comment_tags ? commentAnswer.comment_tags.length ? commentAnswer.comment_tags.join(', ') : '--' : '--' }}
                        </div>
                    </div>

                    <div class="col-xl-6 d-flex align-items-start justify-content-start">
                        <div style="min-width: 50px;" class="tool like-tool ml-auto d-flex">

                            <div style="margin-top: 4px;">
                                <app-moderation-starsrating [minimal]="false" [comment]="commentAnswer"></app-moderation-starsrating>
                            </div>

                            <div class="star-value ml-2">
                                <span class="html-star" style="color: red;">
                                    <i class="fal fa-heart" *ngIf="!commentAnswer.total_like_to_comment"></i>
                                    <i class="fas fa-heart" *ngIf="commentAnswer.total_like_to_comment"></i>
                                </span>
                            </div>
                            <span class="total_answer_like" *ngIf="commentAnswer.total_like_to_comment">({{ commentAnswer.total_like_to_comment }})</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
