import { BaseModel } from '@app/core/models/base';

/**
 * User model class
 */

export class TemplateMessageModel extends BaseModel {

    // tslint:disable: variable-name
    id?: number;
    title: string;
    subject: string;
    body: string;

    static getResourceName() {
        return 'manage-users/template-message';
    }



}
