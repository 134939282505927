import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '@app/core/services/modal.service';
import { ModalComponent } from '@app/core/components/modal/modal.component';
import { ModalOptions } from '@app/core/components/modal/modal-options';

@Component({
  selector: 'app-private-modal-forgotten-password',
  templateUrl: './forgotten-password-modal.component.html',
  styleUrls: ['./forgotten-password-modal.component.scss']
})
export class ForgottenPasswordModalComponent extends ModalComponent implements OnInit {

  static defaultOptions: ModalOptions = {
    title: 'ADD_NOTE',
    btnOkText: 'ADD',
    dialogSize: 'custom',
    showOkBtn: false,
    showCancelBtn: false,
    showCloseBtn: false
  };

  constructor(public activeModal: NgbActiveModal, private fb: UntypedFormBuilder) {
                super(activeModal);
  }

  ngOnInit() {
  }

  submitNote() {
  }

}
