
<div id="core-sidebar-wrapper" [class.hidden]="!sidebarService.visible$.value">
    <!-- Sidebar -->
    <nav id="core-sidebar">
        <div class="sidebar-header">
            <h3>{{ sidebarService.title$.value }}</h3>
        </div>
        <ul class="list-unstyled components">
            <ng-container *ngFor="let voice of sidebarService.voices$.value; let keyName = index;">
                    <li [class]="voice.classes ? voice.classes : ''" [class.disabled]="voice.disabled" [class.active]="router.url === voice.url">
                        <ng-container *ngIf="!voice.component">
                            <ng-container *ngIf="voice.children.length">
                                <label
                                    data-toggle="collapse"
                                    for="{{ 'menu-toggle' + keyName }}"
                                    aria-expanded="false" 
                                    class="label-toggler"
                                    [class.dropdown-toggle]="voice.children.length" 
                                >
                                    {{ voice.label | translate }}
                                </label>
                                <input type="checkbox" class="toggle" id="{{ 'menu-toggle' + keyName }}"/>
                                <ng-container *ngIf="voice.children.length">
                                    <ul class="collapse list-unstyled submenu-{{ keyName }}">
                                        <li [class.disabled]="voice.disabled" [class.active]="router.url === child.url" *ngFor="let child of voice.children; let childIndex = index;"
                                            class="subchild subchild-{{ childIndex }}">
                                            <a *ngIf="child.onclick && !child.url" (click)="child.onclick.call(child.context)">
                                                <i [class]="child.icon"></i>
                                                <div class="label"><span>{{ child.label | translate }}</span></div>
                                            </a>
                                            <a *ngIf="!child.onclick && child.url" class="link" [routerLink]="child.disabled ? '' : child.url">{{ child.label | translate }}</a>
                                        </li>
                                    </ul>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="!voice.children.length">
                                <ng-container *ngIf="voice.icon">
                                    <div class="icon-link">
                                        <a *ngIf="voice.onclick && !voice.url" (click)="voice.onclick.call(voice.context)">
                                            <i [class]="voice.icon"></i>
                                            <div class="label"><span>{{ voice.label | translate }}</span></div>
                                        </a>
                                        <a *ngIf="!voice.onclick && voice.url" class="link" [routerLink]="voice.disabled ? '' : voice.url">
                                            <i [class]="voice.icon"></i>
                                            <div class="label"><span>{{ voice.label | translate }}</span></div>
                                        </a>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!voice.icon">
                                    <a *ngIf="voice.onclick && !voice.url" (click)="voice.onclick.call(voice.context)">
                                        <div class="label"><span>{{ voice.label | translate }}</span></div>
                                    </a>
                                    <a class="link" [routerLink]="voice.disabled ? '' : voice.url">{{ voice.label | translate }}</a>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="voice.component">
                            <ng-container
                                *ngComponentOutlet="voice.component">
                            </ng-container>
                        </ng-container>
                    </li>
            </ng-container>
        </ul>
    </nav>
</div>
