import { Component, OnInit, Input } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { AnswerModel } from '@app/shared/models/answer.model';

@Component({
  selector: 'app-moderation-answerstype-attitude-scale',
  templateUrl: './attitude-scale.component.html',
  styleUrls: ['./attitude-scale.component.scss']
})
export class AnswersTypeAttitudeScaleComponent extends CoreBaseComponent implements OnInit {

  @Input()
  question: QuestionModel;
  @Input()
  answer: AnswerModel;
  @Input()
  explicitMediaUrls: boolean;
  @Input()
  classes: string;


  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  getStatement(idx: number) {
    return this.question.question_data.statements[idx].statement;
  }
}
