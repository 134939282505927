import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateToDayMonth'
})

export class DateToDayMonth implements PipeTransform {
    transform(value: Date | string, ...args: unknown[]): any {
        if (!value) {
            return '';
        }
        if (typeof value === 'string' && value.indexOf('-') !== -1) {
            value = this.replaceAll(value, '-', '/');
        }
        const d = new Date(value);
        if (args[0]) {
            return `${d.getUTCDate()}/${d.getUTCMonth() + 1}/${d.getFullYear()}`;
        }
        return `${d.getUTCDate()}/${d.getUTCMonth() + 1}`;
    }

    replaceAll(str, search, replace) {
        return str.replace(new RegExp(search, 'g'), replace);
    }
}
