import { PingModel } from './../models/ping.model';
import { Injectable } from '@angular/core';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { BaseService } from '@app/core/services/base.service';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';


@Injectable({
  providedIn: 'root'
})
export class PingService extends BaseService implements BaseServiceI {

  constructor(public router: Router, public apiService: ApiService) {
    super(router, apiService, PingModel);
  }
}
