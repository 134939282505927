<ng-container *ngIf="answer">
  <div class="img-card-box d-flex flex-wrap justify-content-center p-0 {{ classes }}" *ngIf="answer.answer_data">
    <div class="mt-3 px-2" *ngFor="let r of answer.answer_data.rank; let i = index">
      <div class="card">
        <div class="rank">
          {{ posStr(i + 1) | translate }}
        </div>

        <div class="d-flex align-items-center justify-content-center">
          <div class="text-center">
            <img *ngIf="getItem(r).image_url" class="card-img-top mb-3" [src]="getItem(r).image_url | privatesrc" alt="">
            <!-- <div *ngIf="getItem(r).image_url && explicitMediaUrls" class="explicit-media-url">
              <a [href]="getItem(r).image_url">{{ 'MEDIA_DOWNLOAD' | translate }}</a>
            </div> -->
            <p class="mb-0">{{ getItem(r).comment }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-5" *ngIf="answer.answer_data.comment">
    <div class="card comment-card">
      <div class="d-flex align-items-center justify-content-center m-3">
        <div class="text-center">
          <h4>{{ 'COMMENT' | translate}}</h4>
          <p class="mb-0">{{ answer.answer_data.comment }}</p>
        </div>
      </div>
    </div>
  </div>
</ng-container>
