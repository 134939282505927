import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { RoomsService } from '@app/shared/services/rooms.service';
import { RoomModel } from '@app/shared/models/room.model';
import { ResponseModel } from '@app/core/models/response.model';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BlockService } from '@app/shared/services/block.service';
import { BlockModel } from '@app/shared/models/block.model';
import { QuestionsService } from '@app/shared/services/questions.service';
import { QuestionModel } from '@app/shared/models/question.model';
import { CountryService } from '@app/shared/services/country.service';
import { AnalysisService } from '@app/shared/services/analysis.service';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { AnswerModel } from '@app/shared/models/answer.model';
import { ActivatedRoute } from '@angular/router';
import { DateToHourMinutes } from '@app/core/pipes/date-to-hour-minutes.pipe';
import { AnswersService } from '@app/shared/services/answers.service';
import { ModerationHelperService } from '@app/shared/services/moderation-helper.service';
import { ToastrService } from 'ngx-toastr';
import { TeamModel } from '@app/shared/models/team.model';
import { TeamService } from '@app/shared/services/team.service';


@Component({
  selector: 'app-analysis-answer-wrapper',
  templateUrl: './answer-wrapper.component.html',
  styleUrls: ['./answer-wrapper.component.scss']
})
export class AnalysisAnswerWrapperComponent extends CoreBaseComponent implements OnInit {

  @Input()
  answer: any;
  @Input()
  exportableVersion = false;

  public contentTagsForm: UntypedFormGroup;
  public personasForm: UntypedFormGroup;

  public allAnswerTags: String[];
  public allPersonasTagsByUserid;
  public allNoteByUserid;
  public allPersonasTags: String[];

  @Output() textSelection: EventEmitter<any> = new EventEmitter(null);

  constructor(private teamsService: TeamService, private translate: TranslateService, private countryService: CountryService,
              private answerService: AnswersService, private toastService: ToastrService, private moderationHelperService:  ModerationHelperService) {
                  super();
              }

  ngOnInit(): void {

    this.countryService.currentPersonasTags.subscribe(personasTags => {
      this.allPersonasTags = personasTags
    })

    this.countryService.currentContentTag.subscribe((contentTags) => {
      this.allAnswerTags = contentTags
    })

    this.countryService.allUserPersonasTagById.subscribe((personasTagsByUserid) => {
      this.allPersonasTagsByUserid = personasTagsByUserid
      this.answer.team.personas_tags = this.getPersonasTag(this.answer.team.personas_tags);
    })

    this.countryService.allUsersNoteById.subscribe((noteByUserid) => {
      this.allNoteByUserid = noteByUserid
      this.answer.team.note = this.getNote(this.answer.team.note);
    })

    this.answer.question_title = this.answer.question_title.replace(/<[^>]+>/g, '');

    this.contentTagsForm = new UntypedFormGroup({
      content_tags: new UntypedFormControl(this.answer.answer_tags ?
        this.answer.answer_tags.length ? this.answer.answer_tags : null : null, null),
    });
    this.personasForm = new UntypedFormGroup({
      personas_tags: new UntypedFormControl(this.answer.team.personas_tags ?
        this.answer.team.personas_tags.length ? this.answer.team.personas_tags[0] : null : null, null),
    });
  }


  notesChanged() {
    const operation = this.operation('saving-note').reset().isSubmitting();
    this.teamsService.addModifyUser(new TeamModel({
      email: this.answer.team.email,
      note: this.answer.team.note,
      role: this.answer.team.role,
      country_uniqid: this.answer.country_uniqid
    })).subscribe((res: ResponseModel) => {
      operation.isSuccess();
      this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
      this.countryService.updateUsersNotes(this.answer.team.id, this.answer.team.note)
    }, (e) => {
      operation.isFailed();
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
    });
  }

  getNote(fallback) {
    return this.allNoteByUserid[this.answer.team.userid] || fallback
  }

  getPersonasTag(fallback) {
    return this.allPersonasTagsByUserid[this.answer.team.userid] || fallback
  }

  getAllPersonasTags() {
    return this.allPersonasTags;
  }

  changeAnswerStatus(event: any, answer: AnswerModel) {
    // @ts-ignore
    answer.state.selected = event.target.checked;
  }

  answerTagsChanged(tags: any, answer: AnswerModel) {
    tags = tags.map(tag => tag.name ? tag.name.toLowerCase() : tag.toLowerCase());
    this.answerService.updateAnswer(answer.id, {
      answer_tags: tags.map(tag => tag.name ? tag.name.toLowerCase() : tag.toLowerCase())
    }).subscribe((res: ResponseModel) => {
      this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));

      // Add any new tag to the list of usable tags
      // todo: usa lo stesso metodo per le personas, che è più efficiente
      tags.forEach(tag => {
        if (!this.moderationHelperService.contentTagList.includes(tag)) {
          this.moderationHelperService.contentTagList.push(tag);
        }
      });
      this.moderationHelperService.contentTagList = [...this.moderationHelperService.contentTagList ];
      // Sort by name
      this.moderationHelperService.contentTagList.sort(function(a,b) {
        if (a > b) {
          return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
      });
      answer.answer_tags = tags;

      this.countryService.updateContentTags(this.countryService.getSessionCountry().country_uniqid)
    }, (e) => {
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
    });
  }

  personasTagsChange(event, justClient = false) {
    this.personasForm.patchValue({
      personas_tags: event ? event.name ? event.name.toLowerCase() : event.toLowerCase() : null
    });
    if(justClient) {
      return this.answer.team.personas_tags = [this.personasForm.value.personas_tags];
    }
    const operation = this.operation('saving-personas-tags').reset().isSubmitting();
    this.teamsService.addModifyUser(new TeamModel({
      email: this.answer.team.email,
      /*this.answer.team.personas_tags ? this.answer.team.personas_tags.map((tag: any) => tag.name ? tag.name : tag) : null,*/
      personas_tags: event ? event.name ? event.name.toLowerCase() !== '' ? [event.name.toLowerCase()] : [] : event.toLowerCase() !== '' ? [event.toLowerCase()] : [] : [],
      role: this.answer.team.role,
      country_uniqid: this.answer.country_uniqid
    })).subscribe((res: ResponseModel) => {
      operation.isSuccess();
      this.moderationHelperService.message$.next('personas-list-applied');
      this.moderationHelperService.spreadInOthersAnswersAndComments.next({what: 'personasTag', team: this.answer.team, personasTag: this.personasForm.value.personas_tags});
      this.answer.team.personas_tags = res.getData().personas_tags;
      this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
      this.countryService.updatePersonasTags(this.answer.country_uniqid);
      this.countryService.updateAnswerPersonasTag(this.answer.team.id, res.getData().personas_tags)
    }, (e) => {
      operation.isFailed();
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
    });
  }

  $textSelection(data) {
    this.textSelection.emit(data)
  }
}
