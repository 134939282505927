import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { StandardRoutes } from '../../../../../setup';

@Injectable({
  providedIn: 'root'
})

export class AlreadyLoggedInGuard implements CanActivateChild {
  constructor(
    private auth: AuthService,
    private router: Router
    ) { }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      let test = false;
      test = this.isAuthenticated();
      if (test) {
        this.router.navigate([StandardRoutes.PRIVATE_DASHBOARD]);
        return false;
      }
      return true;
  }

  isAuthenticated(): boolean {
    return this.auth.isAuthenticated();
  }

}
