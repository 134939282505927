import { Observable, throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseModel } from '../models/base';
import { Router } from '@angular/router';
import { BaseServiceI } from './base-interface.service';
import { SearchParams } from '../models/search-params';
import { CacheKey, CacheValue } from '../models/cache-models';
import { PageModel } from '../models/page';
import { ApiService } from './api.service';
import { StandardRoutes } from '../../../../setup';
import { StorageService } from './storage.service';
import { ResponseModel } from '../models/response.model';
import { ServiceErrorModel } from '../models/service-error.model';
import { TranslateService } from '@ngx-translate/core';
import { BaseServiceConfigurationInterface } from '../models/base-service-conf.interface';
import { DateToHourMinutes } from '../pipes/date-to-hour-minutes.pipe';

export class BaseService implements BaseServiceI {

  path: string;
  tempModel: null;
  cacheEnabled: string[] = [];
  cacheData: any[] = [];
  configuration: BaseServiceConfigurationInterface = {
    errors: {
      unhautorized: {
        // Clear auth data when 401 or 403 (UNAUTHORIZED or FORBIDDEN)
        // !MUST BE CLEARED TO REDIRECT!!! (otherwise the Guard will redirect again to private dashboard)
        clear: true,
        // Redirect to login when 401 or 403 (UNAUTHORIZED or FORBIDDEN)
        redirectToLogin: true,
      },
      // Callbacks
      '403': null
    }
  };

  // TODO in tutti i metodi. Se data è un array cicla e crea istanze

  constructor(public router: Router, public apiService: ApiService, public T?, subSlug?: string) {
    if (T === null) {
      this.T = null;
      this.path = subSlug;
    } else {
      if (T && T.getResourceName()) {
        if (!subSlug) {
          this.path = T.getResourceName();
        } else {
          this.path = subSlug;
        }
      }
    }
  }

  as(outputModel: any): BaseService {
    this.tempModel = this.T;
    this.T = outputModel;
    return this;
  }

  ping(customUrl = ''): Observable<ResponseModel> {
    return this.apiService.get(customUrl)
    .pipe(
      map((item: ResponseModel) => {
        return item;
      }),
      catchError(this.errorHandl)
    );
  }

  /**
   * Sub
   * INFO: T should be NULL if you dont want to instantiate the response's data
   * otherwise the responso will be instantiate using the default model
   */
  sub(subSlug: string, T?: any | BaseModel): BaseService {
    return new BaseService(
      this.router,
      this.apiService,
      typeof T !== 'undefined' ? T : this.T,
      `${this.path}/${subSlug}`
    );
  }

  customCall(url: string, T?: any | BaseModel) {
    return new BaseService(
      this.router,
      this.apiService,
      typeof T !== 'undefined' ? T : this.T,
      url
    );
  }

  // POST
  create(data: any | BaseModel): Observable<ResponseModel> {
    return this.apiService.post(this.getCreatePath(), data)
    .pipe(
      map((item: ResponseModel) => {
        if (this.T) {
          item.data = new this.T(item.data);
          this.instantiateSubObjects(this.T, item.data);
        }
        this.afterCall();
        return item;
      }),
      catchError(this.errorHandl)
    );
  }

  postFile(path, file, fileKey, body: any | BaseModel): Observable<ResponseModel> {
    const formData: FormData = new FormData();
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        const element = body[key];
        formData.append(key, element);
      }
    }

    if (file) {
      formData.append(fileKey, file, file.name);
    }

    return this.apiService.postFormData(this.getGetterPath(path), formData);
  }

  postMultipleFiles(path, fileArray = [], body: any | BaseModel): Observable<ResponseModel> {
    const formData: FormData = new FormData();
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        const element = body[key];
        formData.append(key, element);
      }
    }

    for (const file of fileArray) {
      formData.append(file.key, file.file, file.name);
    }

    return this.apiService.postFormData(this.getGetterPath(path), formData);
  }

  private instantiateSubObjects(T: any, modelInstance: any) {
    if (T.getSubTypesList) {
      if (T.getSubTypesList()) {
        T.getSubTypesList().forEach(item => {
          if (modelInstance[item.object]) {
            if(Array.isArray(modelInstance[item.object])) {
              modelInstance[item.object].forEach((z_item, z_index) => {
                modelInstance[item.object][z_index] = new item.model(z_item);
                // Instantiate sub
                try {
                  this.instantiateSubObjects(item.model, modelInstance[item.object][z_index]);
                } catch (e) {
                  console.error('BaseService.instantiateSubObjects: possible redundancy. Ex: user<userModel>.data<userModel>');
                }
              });
            } else {
              modelInstance[item.object] = new item.model(modelInstance[item.object]);
              // Instantiate the sub object
              try {
                this.instantiateSubObjects(item.model, modelInstance[item.object]);
              } catch (e) {
                console.error('BaseService.instantiateSubObjects: possible redundancy. Ex: user<userModel>.data<userModel>');
              }
            }
          }
        });
      }
    }
  }

  getDownload(code: string = '', query?: any, raw = false): Observable<ResponseModel> {
    const getParams = {params: null};
    if (query) {
      getParams.params = query;
    }
    return this.apiService.blobGet(this.getGetterPath(code), getParams)
    .pipe(
      map((item: ResponseModel) => {
        return item;
      }),
      catchError(this.errorHandl)
    );
  }

   // POST
  createDownload(data: any | BaseModel): Observable<ResponseModel> {
    return this.apiService.blobPost(this.getCreatePath(), data)
    .pipe(
      map((item: ResponseModel) => {
        return item;
      }),
      catchError(this.errorHandl)
    );
  }

  // GET
  get(code: string = '', query?: any, raw = false, deleteEmpty = false): Observable<ResponseModel> {
    const getParams = {params: null};
    if (query) {
      getParams.params = query;
    }
    if (deleteEmpty) {
      Object.keys(getParams.params).forEach(key => {
        if (!getParams.params[key]) {
          delete getParams.params[key];
        }
      });
    }
    return this.apiService.get(this.getGetterPath(code), getParams)
    .pipe(
      map((item: ResponseModel) => {
        if (raw) {
          return item;
        }
        if (this.T) {
          if (Array.isArray(item.data)) {
            const list = [];
            for (const record of item.data/*.results*/) {
              let model = null;
              if (this.T) {
                model = new this.T(record);
                this.instantiateSubObjects(this.T, model);
              }
              list.push(model);
            }
            item.data = list;
          } else {
            item.data = new this.T(item.data);
            /*
            if (this.T.getSubTypesList) {
              Object.keys(item.data.getSubTypesList()).forEach(key => {
                item.data[key] = new item.data.getSubTypesList()[key](item.data[key]);
              });
            }
            */
          }
        }
        this.afterCall();
        return item;
      }),
      catchError(this.errorHandl)
    );
  }

  // PUT
  update(data: BaseModel | any, code?: string | number): Observable<ResponseModel> {
    return this.apiService.put(this.getUpdatePath(data, code), JSON.stringify(data))
    .pipe(
      map((item: ResponseModel) => {
        if (this.T) {
          item.data = new this.T(item.data);
          this.instantiateSubObjects(this.T, item.data);
        }
        this.afterCall();
        return item;
      }),
      catchError(this.errorHandl)
    );
  }

  putFile(data): Observable<ResponseModel> {
    return this.apiService.put(`/${this.path}`, data)
    .pipe(
      map((item: ResponseModel) => {
        return item;
      }),
      catchError(this.errorHandl)
    );
  }

  // DELETE
  delete(data?: BaseModel): Observable<any> {
    return this.apiService.delete(this.getDeletePath(data))
    .pipe(
      catchError(this.errorHandl)
    );
  }

  // DELETE BY ID
  deleteById(path: string, id: any): Observable<any> {
    return this.apiService.delete(path + id) // todo correggi
    .pipe(
      catchError(this.errorHandl)
    );
  }

  // DROPDOWN SEARCH: Example, used by selects
  dropdownList(searchParams?: SearchParams, useCache: boolean = false): Observable<PageModel> {
    if (useCache) {
      this.enableMethodCache('find');
    }
    return this.find(searchParams);
  }

  // SEARCH
  find(searchParams?: SearchParams): Observable<PageModel> {
    const cacheKey = this.getCacheKey('find', searchParams);
    if (this.getCacheValue(cacheKey)) {
      return of(this.getCacheValue(cacheKey));
    }
    const params = this.buildFindParams(searchParams);
    const options: any = Object.assign({params}, {} /*this.httpOptions?*/);
    options.observe = 'response';
    return this.apiService.get(this.getFindPath(), { /*observe: 'response',*/ params })
      .pipe(
        map((res: ResponseModel) => {
          try {
            const list = [];
            let totalCount = 1000;
            let totalFound = -1;
            const data = res.data.list ? res.data.list : res.data;
            if (data) {
              for (const item of data/*.results*/) {
                let model = null;
                if (this.T) {
                  model = new this.T(item);
                  this.instantiateSubObjects(this.T, model);
                }
                list.push(model);
              }
            }
            totalCount = res.data.count ? res.data.count : res.data.length;
            totalFound = res.data.total_count ? res.data.total_count : -1;
            const page = new PageModel(list, totalCount, res.getErrors(), totalFound, res.data.page_index, res.data.page_size);
            this.putCacheValue(cacheKey, page);
            this.afterCall();
            return page;
          } catch (e) {
            console.error('BaseService.list', e, res);
          }
        }),
        catchError(this.errorHandl)
      );
  }

  buildFindParams(searchParams?: SearchParams): any {
    let params: any = {};
    if (searchParams) {
      params.page_index = searchParams.page_index ? searchParams.page_index : 0;
      params.page_size = searchParams.page_size ? searchParams.page_size : 100;
      if (searchParams.sort_attribute && searchParams.sort_order) {
        params.sort_attribute = searchParams.sort_attribute;
        params.sort_order = searchParams.sort_order;
      }
      if (searchParams.filter && searchParams.clean_empty_filters) {
        const cleanedFilter = Object.entries(searchParams.filter).reduce((a, [k, v]) =>
          ( (v == null || v === '' || v === 'null') ?
          a : {...a, [k]: v}), {});
        params = Object.assign(params, cleanedFilter);
      } else {
        params = Object.assign(params, searchParams.filter);
      }
    }
    return params;
  }

  getCreatePath(): string {
    return `/${this.path}`;
  }

  getGetterPath(code: string): string {
    return `/${this.path}/${code}`;
  }

  getFindPath(): string {
    return `/${this.path}`;
  }

  getUpdatePath(data: BaseModel, code?: string | number): string {
    return `/${this.path}/${code ? code : data.getId()}`;
  }

  getDeletePath(data: BaseModel): string {
    if (!data) {
      return `/${this.path}`;
    }
    return `/${this.path}/${data.getId()}`;
  }

  // Error handling
  errorHandl = (error: any): Observable<never>|[] => {
    this.afterCall();
    const errorData: any = {};
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorData.message = error.error.message;
    } else {
      // Get server-side error
      console.error(error);
      // UNAUTHORIZED or FORBIDDEN ?
      if (error.status === 401 /*|| error.status === 403*/) {
        if (this.configuration.errors.unhautorized.clear) {
          const ss = new StorageService();
          ss.clearSession();
        }
        if (this.configuration.errors.unhautorized.redirectToLogin) {
          this.router.navigateByUrl('/' + StandardRoutes.LOGIN);
        }
        // return [];
      }
      // Custom callbacks
      if (this.configuration.errors.hasOwnProperty(error.status)) {
        if (this.configuration.errors[error.status]) {
          this.configuration.errors[error.status](error);
        }
      }
    }
    // todo qui  bisogna standardizzare: vd. error.error
    return throwError(new ServiceErrorModel(error.message, error.error ? error.error.errors : error.errors, error.status, error.headers, error.error.data));
  }

  /*** Cache methods ***/

  protected enableMethodCache(method: string) {
    this.cacheEnabled[`${this.path}/${method}`] = true;
  }

  protected isCacheEnable(method: string): boolean {
    return this.cacheEnabled && this.cacheEnabled[`${this.path}/${method}`];
  }

  protected getCacheKey(method: string, params?: any): CacheKey {
    const key = `${this.path}/${method}/${params ? JSON.stringify(params) : ''}`;
    return {
      key, method
    };
  }

  protected getCacheValue(key: CacheKey, defaultValue = null): any {
    if (this.isCacheEnable(key.method) && this.cacheData[key.key]) {
      return this.cacheData[key.key].value;
    }
    return defaultValue;
  }

  protected putCacheValue(key: CacheKey, value: any): void {
    if (this.isCacheEnable(key.method)) {
      const cacheValue: CacheValue = {
        value,
        createDate: new Date()
      };
      this.cacheData[key.key] = cacheValue;
    }
  }

  protected cleanCache(key: CacheKey): void {
    if (this.isCacheEnable(key.method) && this.cacheData[key.key]) {
      delete this.cacheData[key.key];
    }
  }

  private afterCall() {
    // Back to default model
    if (this.tempModel) {
      this.T = this.tempModel;
      this.tempModel = null;
    }
  }
}
