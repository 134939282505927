import { Component, OnInit } from '@angular/core';
import { TeamService } from '@app/shared/services/team.service';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryModel } from '@app/shared/models/country.model';
import { CountryService } from '@app/shared/services/country.service';
import { ResponseModel } from '@app/core/models/response.model';
import { TeamModel } from '@app/shared/models/team.model';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { SendMessageSharedComponent } from '@app/shared/components/send-message/send-message.component';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { RoomModel } from '@app/shared/models/room.model';
import { RoomsService } from '@app/shared/services/rooms.service';
import { ModalService } from '@app/core/services/modal.service';
import { ResearchSessionService } from '@app/shared/services/research-session.service';
import { MissingAnswersComponent } from './missing-answers/missing-answers.component';
import * as angular from 'angular';
import { StripHtmlPipe } from '@app/core/pipes/striphtml.pipe';
import { DatetimeFromDB } from '@app/core/pipes/datetime-from-db.pipe';
import { CheckCustomerStatusComponent } from './check-customer-status/check-customer-status.component';

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss']
})
export class MonitoringComponent extends CoreBaseComponent implements OnInit {

  constructor(
    private teamService: TeamService,
    private activatedRoute: ActivatedRoute,
    private countryService: CountryService,
    private coreLayoutService: CoreLayoutService,
    private translateService: TranslateService,
    private toastService: ToastrService,
    private roomService: RoomsService,
    private modalService: ModalService,
    private researchSessionService: ResearchSessionService,
    private router: Router,
    private stripHtmlPipe: StripHtmlPipe,
    private datetimeFromDB: DatetimeFromDB
  ) {
    super();
  }

  showVariablesTags = false;
  countryUniqid: string;
  sort: string;
  order = 'asc';
  country: CountryModel;
  users: TeamModel[];
  perPage = 20;
  sampleVariableTagsFilter: string[];
  roomsFilter: string;
  roomsFilterOVC: string;
  selected: string[] = [];
  selectedOVC: string[] = [];
  rooms: RoomModel[];
  multipleAnswersQuestions = null;
  multipleAnswersQuestionsUsers = null;
  multipleAnswersQuestionsColumns = [];
  multipleAnswersFound = false;
  loadingUsers = false;

  ngOnInit() {
    if (this.countryService.currentCountryIsClientBasic()) {
      return this.router.navigate(['/researches/navigation',
        this.researchSessionService.getResearchUniqId(), this.researchSessionService.getResearchCountryUniqId(), 'analysis']);
    }
    if (this.teamService.isCurrentUserCustomerPro()) {
      return this.router.navigate(['/researches/navigation',
        this.researchSessionService.getResearchUniqId(), this.researchSessionService.getResearchCountryUniqId(), 'moderation']);
    }
    this.countryUniqid = this.activatedRoute.snapshot.parent.paramMap.get('country_uniqid');
    // TODO cache country info
    this.countryService.getCountrySetupInfo(this.countryUniqid).subscribe((res: ResponseModel) => {
      this.country = res.getData();
    });

    this.roomService.getRoomsByCountryUniqid(this.countryUniqid).subscribe((res: ResponseModel) => {
      this.rooms = [{title: this.translateService.instant('ALL'), id: null}, ...res.getData()];
    });

    this.loadUsers();
  }

  loadUsers(excel = false) {
    this.loadingUsers = true;
    if (excel) {
      this.teamService.getUserMonitoring(
        this.countryUniqid, this.sort, this.order, this.sampleVariableTagsFilter, this.roomsFilter, excel
      ).subscribe((res: any) => {
          this.downloadFile(res.blob, 'monitoring.xlsx');
      });
    } else {
      this.teamService.getUserMonitoring(
        this.countryUniqid, this.sort, this.order, this.sampleVariableTagsFilter, this.roomsFilter, excel
      ).subscribe((res: ResponseModel) => {
        for (const key in res.getData()) {
          if (res.getData().hasOwnProperty(key)) {
            const element = res.getData()[key];
            element.last_login_datetime = this.datetimeFromDB.transform(element.last_login_datetime, this.countryService.getSessionCountry().timezone, true);
          }
        }
        this.users = res.getData();


        this.loadingUsers = false;
        // se non ho ancora ottenuto i dati per le domande con più risposte
        if (!this.multipleAnswersQuestions) {
          this.getQuestionsWithMultipleAnswers(this.countryUniqid);
        }
      });
    }
  }

  filterByRoom(room) {
    this.roomsFilter = room.id;
    this.loadUsers();
  }

  filterByRoomOVC(room) {
    this.roomsFilterOVC = room.id;
    this.getQuestionsWithMultipleAnswers(this.countryUniqid);
  }

  openMissingAnswersModal(missingAnswers, user) {
    this.modalService.open(
      {modalModel: MissingAnswersComponent, dialogSize: 'custom'}, {missingAnswers, userid: user.userid, countryid: this.country.id, user}
    );
  }

  sendMessage() {
    // shared/components
    this.coreLayoutService.openSidebar(SendMessageSharedComponent, {
      country_uniqid: this.countryUniqid,
      selectedUsers: angular.copy(this.selected.concat(this.selectedOVC)),
      selectedUsersData: angular.copy(this.getUsersByIds(this.selected.concat(this.selectedOVC))),
      isSidebar: true,
    });
    this.selected = [];
    this.selectedOVC = [];
  }

  getUsersByIds(userids) {
    const ret = [];
    for (const user of this.users) {
      if (userids.includes(user.userid)) {
        ret.push(user);
      }
    }
    return ret;
  }

  showFilterByVariable() {
    this.showVariablesTags = !this.showVariablesTags;
    this.filterByVariableTags(null);
  }

  createPassword() {
    this.teamService.createNewPassword(this.selected.concat(this.selectedOVC), this.countryUniqid).subscribe((res: ResponseModel) => {
      if (res) {
        this.toastService.success(this.translateService.instant('PASSWORD_SENT'));
        this.selected = [];
        this.selectedOVC = [];
      }
    });
  }

  deleteTeam() {
    this.modalService.open({title: 'ARE_YOU_SURE.?',
        btnOkText: 'YES',
        btnCancelText: 'CANCEL',
        showBtnClose: false,
        showCancelBtn: true,
        customClass: 'confirm-modal',
        message: 'ARE_YOU_SURE_DELETE.?'
      }).then((data) => {
        if (data) {
          this.teamService.deleteFromCountries(this.selected.concat(this.selectedOVC), this.countryUniqid).subscribe((res: ResponseModel) => {
            if (res) {
              this.toastService.success(this.translateService.instant('REMOVED'));
              this.selected = [];
              this.selectedOVC = [];
              this.loadUsers();
              this.getQuestionsWithMultipleAnswers(this.countryUniqid);
            }
          });
        }
    }, (err) => {
      this.toastService.error(this.translateService.instant('CAN_NOT_REMOVE_INTERVIEWEE_AFTER_3_DAYS'));
    });
  }

  thumbsDown(user: TeamModel) {
    user.monitoring_dislike = !user.monitoring_dislike;
    if (user.monitoring_dislike) {
      user.monitoring_medal = false;
    }
    for (const u of this.users) {
      if (u.id === user.id) {
        u.monitoring_dislike = user.monitoring_dislike;
      }
    }
    this.teamService.addModifyUser(user).subscribe();
  }

  medal(user: TeamModel) {
    user.monitoring_medal = !user.monitoring_medal;
    if (user.monitoring_medal) {
      user.monitoring_dislike = false;
    }
    for (const u of this.users) {
      if (u.id === user.id) {
        u.monitoring_medal = user.monitoring_medal;
      }
    }
    this.teamService.addModifyUser(user).subscribe();
  }

  addRemoveToSelected(id) {
    if (this.selected.includes(id)) {
      const index = this.selected.indexOf(id);
      this.selected.splice(index, 1);
    } else {
      this.selected = [...this.selected, id];
    }
  }

  addRemoveToSelectedOVC(id) {
    if (this.selectedOVC.includes(id)) {
      const index = this.selectedOVC.indexOf(id);
      this.selectedOVC.splice(index, 1);
    } else {
      this.selectedOVC = [...this.selectedOVC, id];
    }
  }

  columnClicked(e) {
    if (e === 'MONITORING_SECTION.LOGIN') {
      this.order = (this.order === 'asc' ? 'desc' : 'asc');
      this.sort = 'email';
      this.loadUsers();
    }
    if (e === 'MONITORING_SECTION.ANSWER_TOTAL') {
      this.order = (this.order === 'asc' ? 'desc' : 'asc');
      this.sort = 'nr_answer';
      this.loadUsers();
    }
    if (e === 'fas fa-circle') {
      this.order = (this.order === 'asc' ? 'desc' : 'asc');
      this.sort = 'status_activity';
      this.loadUsers();
    }
    if (e === 'MONITORING_SECTION.NAME') {
      this.order = (this.order === 'asc' ? 'desc' : 'asc');
      this.sort = 'nickname';
      this.loadUsers();
    }

    if (e === 'fal fa-check-square') {
      if (this.selected.length > 0) {
        this.selected = [];
      } else {
        this.selected = this.users.map(u => u.userid);
      }
    }
  }

  columnClickedOVC(e) {
    if (e === 'fal fa-check-square') {
      if (this.selectedOVC.length > 0) {
        this.selectedOVC = [];
      } else {
        this.selectedOVC = this.multipleAnswersQuestionsUsers.map(u => u.userid);
      }
    }
  }

  checkStatus() {
    this.coreLayoutService.openSidebar(CheckCustomerStatusComponent, {country: this.country});
  }

  filterByVariableTags(e) {
    this.sampleVariableTagsFilter = e;
    this.loadUsers();
  }

  getQuestionsWithMultipleAnswers(countryUniqid: string) {
    this.multipleAnswersQuestions = null;
    this.multipleAnswersQuestionsUsers = null;
    this.multipleAnswersQuestionsColumns = [];
    this.teamService.getQuestionsWithMultipleAnswers(countryUniqid, this.roomsFilterOVC).subscribe((res: ResponseModel) => {
      const temp = res.getData();
      const userArr = [];
      const questionArr = [];
      for (const data of temp) {
        const userData = this.users.find(u => u.userid === data.userid);
        if (userData) {
          userData.questions = data.questions;
          userArr.push(userData);

          for (const question of data.questions) {
            if (!questionArr.find(q => q.id === question.questionid)) {
              questionArr.push({
                id: question.questionid,
                title: question.question_title,
                index: questionArr.length + 1
              });
            }
          }
        }
      }
      this.multipleAnswersQuestionsUsers = userArr;
      this.multipleAnswersQuestions = questionArr;
      this.multipleAnswersQuestionsColumns = this.getQuestionsWithMultipleAnswersColumns();
      if (questionArr && questionArr.length > 0) {
        this.multipleAnswersFound = true;
      }
    });
  }

  getTeamQuestionCount(question, team) {
    const foundQuestion = team.questions.find(q => q.questionid === question.id)
    if (foundQuestion) {
      return `${foundQuestion.answers_count}/${foundQuestion.minimum_multiple_answers_nr}`
    }
    return ''
  }

  getQuestionsWithMultipleAnswersColumns() {
    const columns: any = [
      {icon_name: 'fal fa-check-square', class: 'text-left'},
      {name: '', class: 'text-center'},
      {name: 'MONITORING_SECTION.LOGIN', class: 'text-left'},
      {name: 'MONITORING_SECTION.NAME', class: 'text-left'},
    ];
    for (const question of this.multipleAnswersQuestions) {
      columns.push({
        name: `${this.translateService.instant('QUESTION')} ${question.index}`,
        class: 'text-center',
        tooltip: this.stripHtmlPipe.transform(question.title),
        placement: 'bottom'
      });
    }
    return columns;
  }

}
