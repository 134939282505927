import { BaseModel } from '@app/core/models/base';

export class PingModel extends BaseModel {

    constructor(data?: any) {
        super(data);
    }

    /**
     * Api address
     */
    static getResourceName() {
        return '';
    }

}
