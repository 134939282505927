<div id="hiddenDiv" style="visibility: hidden;" class="w-100">A</div>
<div class="row mb-3" *ngIf="!answerToComment">
    <div *ngIf="viewReady">
        <div class="col-12" *ngIf="quillHeight">
            <div class="title read-only-quill" [formGroup]="titleForm">
                <quill-editor (onEditorCreated)="editorCreated($event)" formControlName="title" [readOnly]="true"
                    [modules]="quillModules" [styles]="{height: quillHeight}" [placeholder]="'INSERT-TEXT' | translate">
                </quill-editor>
            </div>
            <div *ngIf="sampleVariableTags?.length" class="mt-2"><b>{{ 'QUESTION_ADDRESSED_TO' | translate }}</b></div>
            <div class="mt-3" *ngIf="sampleVariableTags?.length">
                <span *ngFor="let tag of sampleVariableTags" class="tag">{{ tag }}</span>
            </div>
            <div class="attached-file-container" *ngIf="question.question_data && question.question_data.pdf_files">
                <span class="attached-file" *ngFor="let pdf of question.question_data.pdf_files">
                    <a [href]="pdf.pdf_url | privatesrc" target="blank">{{pdf.name}}</a>
                </span>
            </div>
            <div class="attached-file-container" *ngIf="question.question_data && question.question_data.attached_pdf_files">
                <span class="attached-file" *ngFor="let pdf of question.question_data.attached_pdf_files">
                    <a [href]="pdf.pdf_url | privatesrc" target="blank">{{pdf.name}}</a>
                </span>
            </div>
        </div>
    </div>
    <!-- media -->
    <div class="col-12" *ngIf="question.question_data.images && question.question_data.images.length && question.type != 'image-association'">
        <h4>{{ 'IMAGES' | translate }}</h4>
        <div class="card d-inline-block" *ngFor="let image of question.question_data.images" style="width: 10rem;">
            <img *ngIf="image" class="card-img-top" [src]="image.img_url | privatesrc" alt="Card image cap">
        </div>
    </div>
    <div class="col-12" *ngIf="question.question_data.attached_images && question.question_data.attached_images.length && question.type != 'image-association'">
        <h4>{{ 'IMAGES' | translate }}</h4>
        <div class="card d-inline-block" *ngFor="let image of question.question_data.attached_images" style="width: 10rem;">
            <img *ngIf="image" class="card-img-top" [src]="image.img_url | privatesrc" alt="Card image cap">
        </div>
    </div>
    <div class="col-12 d-flex">
        <div class="graph-loader" *ngIf="imageCloudLoader">
            <app-core-spinner class="spinner" [toggle]="true"></app-core-spinner>
        </div>
        <div class="flex-grow-1 d-flex justify-content-center" id="highchart-container" *ngIf="chartReady">
            <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions" [style.height]="chartDimension.h"
                [style.width]="chartDimension.w" style="display: block;">
            </highcharts-chart>
        </div>
    </div>
</div>

<div *ngIf="!answer || !answer?.length">
    {{ 'THERE_ARE_NO_ANSWERS' | translate }}
</div>
<div class="row mt-4" id="queue">
    <div class="col-md-12">
        <ng-container *ngIf="!answerToComment && question.type != 'communications'">
            <div *ngFor="let item of answer">
                <app-moderation-answer-block [id]="'answer-block-' + item.id" (replyPress)="replyAnswer($event)"
                    [intervieweds]="intervieweds" [personasTags]="personasTags" [contentTags]="contentTags"
                    [question]="question" [item]="item">
                </app-moderation-answer-block>
            </div>
        </ng-container>
        <div *ngIf="answerToComment" class="w-75 mx-auto">
            <div *ngIf="!operation('sending-comment').success">
                <ng-container *ngIf="!answerToCommentIsPrivateMail">
                    <h5 *ngIf="!answerToCommentIsPrivate && answerToComment.question_type == 'open-answer'">{{
                        'YOU_ARE_REPL_TO_ANSWER' | translate:({param1:
                        answerToComment.team.nickname ? answerToComment.team.nickname : answerToComment.team.firstname ?
                        answerToComment.team.firstname : '--' } | ParamsTranslate) }}:</h5>
                    <h5 *ngIf="answerToCommentIsPrivate">{{ 'YOU_ARE_SENDING_PRIVATE_MESSAGE_TO' | translate:({param1:
                        answerToComment.team.nickname ? answerToComment.team.nickname : answerToComment.team.firstname ?
                        answerToComment.team.firstname : '--' } | ParamsTranslate) }}:</h5>
                    <h5 *ngIf="!answerToCommentIsPrivate && answerToComment.question_type != 'open-answer'">{{
                        'YOU_ARE_REPL_TO_ANSWER_B' | translate:({param1:
                        answerToComment.team.nickname ? answerToComment.team.nickname : answerToComment.team.firstname ?
                        answerToComment.team.firstname : '--' } | ParamsTranslate) }}:</h5>
                    <p *ngIf="answerToComment.question_type == 'open-answer'"
                        [innerHTML]="answerToComment.answer_data.text"></p>
                    <div *ngIf="
                        answerToComment.question_type == 'video-answer'
                        || answerToComment.question_type == 'communications'
                        || answerToComment.question_type == 'sentence-completion'
                        || answerToComment.question_type == 'vocal-answer'
                        || answerToComment.question_type == 'image-answer'
                        ">
                        <app-moderation-answerdispatcher [question]="question" [item]="answerToComment">
                        </app-moderation-answerdispatcher>
                    </div>
                    <div *ngIf="answerToComment.question_type == 'video-answer' && answerToComment.answer_data.videos[0].video_data && answerToComment.answer_data.videos[0].video_data.file_words"
                        class="d-inline-block">
                        <div *ngFor="let word of answerToComment.answer_data.videos[0].video_data.file_words"
                            class="float-left">
                            {{word.word}}&nbsp;
                        </div>
                    </div>
                    <div *ngIf="answerToComment.question_type == 'vocal-answer' && answerToComment.answer_data.vocal_data && answerToComment.answer_data.vocal_data.file_words"
                        class="d-inline-block">
                        <div *ngFor="let word of answerToComment.answer_data.vocal_data.file_words" class="float-left">
                            {{word.word}}&nbsp;
                        </div>
                    </div>
                </ng-container>
                <!--
                <ng-container *ngIf="answerToCommentIsPrivateMail">
                    <h5>{{ 'YOU_ARE_SENDING_MAIL_TO' | translate:({param1:
                        answerToComment.team.nickname ? answerToComment.team.nickname : answerToComment.team.firstname ? answerToComment.team.firstname : '--' } | ParamsTranslate) }}:</h5>
                </ng-container>
                -->
                <h5 *ngIf="!answerToCommentIsPrivate && !answerToCommentIsPrivateMail" class="mt-5">{{
                    'WRITE_YOUR_ANSWER' | translate }}</h5>
                <h5 *ngIf="answerToCommentIsPrivate" class="mt-5">{{ 'WRITE_YOUR_ANSWER' | translate }}</h5>
                <div class="position-relative">
                    <ng-template #quoteUsers>
                        <div>
                            <div *ngIf="!intervieweds.length">{{ 'THE_OTHERS_YET_NOT_REPLIED' | translate }}</div>
                            <ng-scrollbar>
                                <div *ngFor="let user of intervieweds" class="d-flex align-items-center mb-2">
                                    <div class="avatar">
                                        <div class="avatar-bg-img" *ngIf="user.avatar_url"
                                            [ngStyle]="{'background-image': 'url(' + (user.avatar_url | privatesrc) + ')'}">
                                        </div>
                                        <div *ngIf="!user.avatar_url"
                                            class="d-flex align-items-center justify-content-center placeholder-avatar">
                                            <i class="align-self-center fal fa-user"></i>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center ml-2">
                                        <input type="checkbox" name="" [(ngModel)]="user.state.selected">
                                        <span class="pl-2">{{ user.nickname }}</span>
                                    </div>
                                </div>
                            </ng-scrollbar>
                            <ng-container *ngIf="intervieweds.length">
                                <div class="mt-3 text-right"><a (click)="quoteSelectedIntervieweds(quoteUsers)"
                                        *ngIf="hasCheckedIntervieweds()">{{ 'QUOTE_SELECTED' | translate }}</a></div>
                                <div class="mt-3 text-right"><a (click)="quoteAllIntervieweds(quoteUsers)"
                                        *ngIf="!hasCheckedIntervieweds()">{{ 'QUOTE_EVERYBODY' | translate }}</a></div>
                            </ng-container>
                        </div>
                    </ng-template>
                    <!-- template messages -->
                    <div class="row my-3" *ngIf="answerToCommentIsPrivateMail">
                        <div class="col-12">
                            <label>
                                <input type="checkbox" [(ngModel)]="showLoadFromTemplate">
                                {{'LOAD_FROM_TEMPLATE_MESSAGE' | translate}}
                                <span class="info-tooltip ml-2" placement="top"
                                    [ngbTooltip]="'TOOLTIPS.TEMPLATE_MESSAGES' | translate">
                                    <i class="far fa-info-circle"></i>
                                </span>
                            </label>
                        </div>
                        <div class="col-7" *ngIf="showLoadFromTemplate">
                            <div class="row">
                                <div class="col-12 ">
                                    <div class="form-group">
                                        <app-core-select *ngIf="templateMessages" [classes]="'form-control'"
                                            [multiple]="false" [cache]="true" [customList]="templateMessages"
                                            [label]="'title'" [searchable]="true" (change)="setTemplateMessage($event)">
                                        </app-core-select>
                                        <span class="placeholder">{{'TEMPLATE_MESSAGES' | translate}}</span>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-5 text-right" *ngIf="showLoadFromTemplate">
                            <a (click)="showLoadFromTemplate=false" class="clickable mr-2">{{'CANCEL' | translate}}</a>
                            <button class="btn-sicche btn-secondary" [disabled]="!currentTemplateMessage"
                                (click)="loadFromTemplate()">{{'LOAD_FROM_TEMPLATE_MESSAGE' | translate}}</button>
                        </div>
                    </div>
                    <!-- end messaggi template -->
                    <div [formGroup]="replyForm" *ngIf="answerToCommentIsPrivateMail">
                        <div class="mb-1">
                            <input type="text" class="form-control" name="subject" formControlName="subject"
                                [placeholder]="'OBJECT' | translate">
                        </div>
                    </div>
                    <div *ngIf="!answerToCommentIsPrivate && question.is_room_group && !answerToCommentIsPrivateMail"
                        class="quote-user">
                        <div class="position-relative">
                            <span class="as-link" #qp="ngbPopover" (click)="checkQuotedIntervieweds()"
                                [ngbTooltip]="'QUOTE_USER_TOOLTIP' | translate" placement="left" [autoClose]="'outside'"
                                [ngbPopover]="quoteUsers" [popoverTitle]="'QUOTE_TAG' | translate">
                                @
                            </span>
                        </div>
                    </div>
                    <app-answer-wysiwyg (textChange)="textChangeEvent()" [injectText]="textToInject" [form]="replyForm"
                        [formEntityName]="'comment'"></app-answer-wysiwyg>
                </div>
                <div class="d-flex">
                    <div *ngIf="!answerToCommentIsPrivateMail && question.is_room_group"
                        class="check-radio-box private-check d-inline-block mt-2 ml-auto">
                        <span *ngIf="!textContainsQuotes() && !inTextThereAreQuotes">
                            <input type="checkbox" id="priv-ans" [(ngModel)]="answerToCommentIsPrivate">
                            <label class="mb-0 ml-2" for="priv-ans">
                                {{ 'PRIVATE_ANSWER' | translate }}
                            </label>
                        </span>
                    </div>
                    <div class="mt-2">
                        <a (click)="cancel()">{{ 'CANCEL' | translate }}</a>
                        <button class="btn btn-success ml-3"
                            [disabled]="(answerToCommentIsPrivateMail && !replyForm.value.subject || !replyForm.value.comment) || !replyForm.value.comment"
                            (click)="sendComment()">
                            <span *ngIf="operation('sending-comment').submitting" class="spinner-border text-primary"
                                role="status">
                                <span class="sr-only">Loading...</span>
                            </span>
                            <span *ngIf="!answerToCommentIsPrivate && !answerToCommentIsPrivateMail">{{ 'REPLY' |
                                translate }}</span>
                            <span *ngIf="answerToCommentIsPrivateMail">{{ 'SEND' | translate }}</span>
                            <span *ngIf="answerToCommentIsPrivate">{{ 'REPLY' | translate }}</span>
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="operation('sending-comment').success && !answerToCommentIsPrivate">
                <div class="alert alert-success">
                    {{ 'COMMENT_SENT_CORRECTLY' | translate }}
                </div>
            </div>
            <div *ngIf="operation('sending-comment').success && answerToCommentIsPrivate">
                <div class="alert alert-success">
                    {{ 'MESSAGE_SENT_CORRECTLY' | translate }}
                </div>
            </div>

            <!-- template messages 1118 -->
            <div class="row mt-4" *ngIf="answerToCommentIsPrivateMail">
                <div class="col-12">
                    <label>
                        <input type="checkbox" [(ngModel)]="showSaveAsTemplate">
                        {{'SAVE_AS_TEMPLATE_MESSAGE' | translate}}
                    </label>
                </div>
                <div class="col-9" *ngIf="showSaveAsTemplate">
                    <div class="row" *ngIf="showSaveAsTemplate">
                        <div class="col-12 ">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="{{'TEMPLATE_MESSAGE_TITLE' | translate }}" [(ngModel)]="templateTitle">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 text-right" *ngIf="showSaveAsTemplate">
                    <button class="btn-sicche btn-secondary" [disabled]="!templateTitle"
                        (click)="saveAsTemplateMessage()">{{'SAVE_AS_TEMPLATE_MESSAGE' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>