<span class="">
    {{"ORDER_BY" | translate}}
    <select class="core-sortby-component" #sortType [(ngModel)]="sort.by" (change)="sortBy(sort.by)" >
        <option *ngFor='let option of options' [disabled]="option.disabled" [ngValue]='option'>{{option.name | translate}}</option>
    </select>
    <i (click)="sortOrder('ASC')" 
        [ngClass]="{'selected': sort.direction == 'ASC'}" 
        class="fas mx-1 fa-sort-amount-down-alt as-link">
    </i>
    <i (click)="sortOrder('DESC')" 
        [ngClass]="{'selected': sort.direction == 'DESC'}" 
        class="fas mx-1 fa-sort-amount-down as-link">
    </i>
</span>