import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { AnswerModel } from '@app/shared/models/answer.model';
import { ModerationHelperService } from '@app/shared/services/moderation-helper.service';
import { TeamService } from '@app/shared/services/team.service';
import { QuoteModel } from '@app/shared/models/quote.model';

@Component({
  selector: 'app-moderation-answerstype-video-answer',
  templateUrl: './video-answer.component.html',
  styleUrls: ['./video-answer.component.scss']
})
export class AnswersTypeVideoAnswerComponent extends CoreBaseComponent implements OnInit {

  @Input() question: QuestionModel;
  @Input() answer: AnswerModel;
  @Input() classes: string;
  @Input() explicitMediaUrls: boolean;
  @Input() placeholders: boolean;
  @Output() textSelection: EventEmitter<any> = new EventEmitter(null);

  constructor(private moderationHelperService:  ModerationHelperService, private teamService: TeamService) {
    super();
  }

  ngOnInit(): void {
    if(!this.teamService.isCurrentUserCustomerPro() && !this.teamService.isCurrentUserCustomerBasic()) {
      if(this.answer.quotes) {
        this.printQuotes(this.answer.quotes);
      }
      this.moderationHelperService.quotes.subscribe(quotes => {
        this.printQuotes(quotes);
      });
    }
  }

  printQuotes(quotes: Array<QuoteModel>) {
    if(quotes != null) {
      quotes.forEach(quote => {
        if(this.answer.answer_data.videos.length && quote.answerid === this.answer.id) {
          this.answer.answer_data.videos.forEach(video => {
            this.hlTranscription(video, quote);
          });
        }
      });
    }
  }

  private hlTranscription(video, quote: QuoteModel) {
    if(!video.hasOwnProperty('hlTranscription')) {
      video.hlTranscription = '';
      video.video_data.file_words.forEach(word => {
        video.hlTranscription += word.word + ' ';
      });
    }
    const qclass = quote.color ? quote.color : 'grey';
    if(quote.quote_original && quote.quote_original.length) {
      quote.quote_original.forEach(q => {
        video.hlTranscription = video.hlTranscription.replace(q, '<em class="highlight ' + qclass + '">'+ q +'</em>');
      });
    } else {
      // old way - before 29/July/2021
      video.hlTranscription = video.hlTranscription.replace(quote.quote, '<em class="highlight ' + qclass + '">'+ quote.quote +'</em>');
    }
    //video.hlTranscription = video.hlTranscription.replace(quote.quote, '<em class="highlight ' + qclass + '">' + quote.quote +'</em>');
  }

  selectText() {
    this.textSelection.emit({commentid:null});
  }

  setVideoTime(index: number, start: number, end: number) {
    const video: any = document.getElementById('video_player_' + index);
    video.currentTime = start / 1000;
  }
}
