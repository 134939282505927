<h3 class="mt-4 mb-5">{{ 'COUNTRY_SETUP.DISCUSSION_INTERFACE_SETTINGS' | translate }}</h3>

<div class="row" *ngIf="rooms">
    <div class="col-md-4 col-xxl-4 col-xxxl-3 pl-0 pr-4 rooms">
        <div #scrollTarget></div>
        <div *ngFor="let room of rooms; let i = index" class="mt-1 shadow card  {{(room.id === openedRoomId) ? 'active-room' : ''}}"
        [id]="'room-' + room.id"
        >
            <div class="header btn-sicche btn-secondary d-flex align-items-center justify-content-between">
                <div class="h-100 d-flex align-items-center flex-grow-1 cursor-pointer" *ngIf="editTitle != room.id" (click)="openRoom(room.id, scrollTarget)">
                    <h4 class="title mb-0">{{room.title}}</h4>
                </div>
                <div class="pr-3 flex-grow-1" *ngIf="editTitle == room.id">
                    <div class="form-group nolabel edit required mb-0">
                        <input required type="text" class="form-control" id="title" [(ngModel)]="room.title" (change)="updateRoom(room.id)">
                    </div>
                </div>
                <div class="text-nowrap">
                    <button class="icon" *ngIf="room.id == openedRoomId" (click)="editTitleToggle(room.id)"><i class="fal fa-pen"></i></button>
                    <button class="icon" *ngIf="room.id != openedRoomId && isRoomDeletable(room.id)" (click)="removeRoom(room.id)"><i class="fal fa-trash-alt"></i></button>
                    <button class="icon" (click)="duplicateRoom(room.id)"><i class="fal fa-copy"></i></button>
                </div>
            </div>
            <div class="p-3 content {{(room.id == openedRoomId) ? 'uncollapsed-down' : 'collapsed-up'}}">
                <div class="d-flex flex-column border-bottom">
                    <div class="d-flex align-items-center justify-content-between py-2">
                        <div>
                            {{ 'FROM' | translate }} {{room.date_start | dateToLocaleString}}
                            <br>
                            {{ 'DATE_TO' | translate }} {{room.date_end | dateToLocaleString}}
                        </div>
                        <div>
                            <button class="btn-sicche btn-icon small" (click)="datepick = !datepick"><i class="fal fa-calendar-alt"></i></button>
                        </div>
                    </div>
                    <app-shared-datepicker-range
                        *ngIf="datepick"
                        [roomid]="room.id"
                        [from]="room.date_start"
                        [to]="room.date_end"
                        (dateChange)="changeDate($event)" class="datepicker-custom">
                    </app-shared-datepicker-range>
                </div>
                <div class="d-flex align-items-center justify-content-between py-2">
                    <div>{{'INTERVIEWEDS.OPEN_INTERVIEWEDS_SIDEBAR' | translate}}</div>
                    <div><button class="btn-sicche btn-icon small" (click)="openInterviewedsSidabar(room)"><i class="fal fa-user-cog"></i></button></div>
                </div>
                <div class="flex-column mt-n4 mb-3">
                    <div class="badge bg-orange text-white p-2" *ngIf="room.sample_variable_tags && room.sample_variable_tags.length == 1">{{room.sample_variable_tags[0]}}</div>
                    <div class="badge bg-orange text-white p-2" *ngIf="!room.sample_variable_tags || room.sample_variable_tags.length == 0">{{'ALL' | translate}}</div>
                </div>

                <div class="d-flex flex-column py-2 border-bottom">
                    <p>{{ 'ROOM.INTERVIEW_TYPE' | translate }}</p>
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="flex-grow-1 check-radio-box">
                            <input type="radio" id="group" [value]="false" [(ngModel)]="room.is_room_group" (change)="updateRoom(room.id, true)" />
                            <label for="group">{{ 'ROOM.INDIVIDUAL' | translate }}</label>
                        </div>
                        <div class="flex-grow-1 check-radio-box">
                            <input type="radio" id="room" [value]="true" [(ngModel)]="room.is_room_group" (change)="updateRoom(room.id, true)" />
                            <label for="room">{{ 'ROOM.GROUP' | translate }}</label>
                        </div>
                    </div>
                </div>

                <div class="d-flex align-items-center justify-content-start py-2 border-bottom">
                    <span class="flex-grow-1">{{'ROOM.INSTRUCTION' | translate}}</span>
                    <span class="info-tooltip mr-0" placement="top" [ngbTooltip]="'TOOLTIPS.ROOM_INSTRUCTION' | translate">
                        <i class="far fa-info-circle"></i>
                    </span>
                    <button class="btn-sicche btn-icon small" (click)="openInstructionsSidebar(room)"><i class="fal fa-info-circle"></i></button>
                </div>
            </div>
        </div>

        <div class="add-room card mt-3 position-relative" (click)="createRoom()">
            <svg width="43px" height="38px" class="mr-2" viewBox="0 0 43 38" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <!-- Generator: Sketch 63.1 (92452) - https://sketch.com -->
                <title>molecule / sicchemoji / ;)</title>
                <desc>Created with Sketch.</desc>
                <g id="molecule-/-sicchemoji-/-;)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <path d="M18.039801,46 C18.7694859,44.7039523 19.3499171,43.4489187 19.7810945,42.2348993 C20.212272,41.0208799 20.5232172,39.8191648 20.7139303,38.6297539 C20.9046434,37.440343 21,36.2222222 21,34.9753915 C21,33.1215511 20.7719735,31.300522 20.3159204,29.5123043 C19.8598673,27.7240865 19.1094527,25.8866518 18.0646766,24 L18.0646766,24 L16,24 C16.6301824,25.4929157 17.119403,26.8586875 17.4676617,28.0973154 C17.8159204,29.3359433 18.0605307,30.5171514 18.2014925,31.6409396 C18.3424544,32.7647278 18.4129353,33.8762118 18.4129353,34.9753915 C18.4129353,36.0909769 18.3383085,37.2065623 18.1890547,38.3221477 C18.039801,39.437733 17.7951907,40.6230425 17.4552239,41.8780761 C17.115257,43.1331096 16.6384743,44.5070843 16.0248756,46 L16.0248756,46 L18.039801,46 Z" id=")" fill="#828282" fill-rule="nonzero" transform="translate(18.500000, 35.000000) rotate(90.000000) translate(-18.500000, -35.000000) "></path>
                    <g id="Group-13" transform="translate(-7.000000, -1.000000)" fill="#828282" fill-rule="nonzero">
                        <path d="M20.0248638,21.3602493 C19.372821,22.4148575 18.596335,23.0218034 18.2585408,23.262785 C17.9320145,23.53519 17.0923235,24.0057667 16.6750086,24.2377425 C16.2775604,24.5018183 15.8125973,24.6740403 15.3581473,24.8835504 C14.8839867,25.0367877 14.4053636,25.2149583 13.9086956,25.3009537 C13.4168098,25.423542 12.9154305,25.461957 12.4232581,25.5178917 C10.4451177,25.6508643 8.58893861,25.326711 7.2337935,25.0176159 C5.87793617,24.707793 5,24.4395475 5,24.4395475 C5,24.4395475 5.92247174,24.4723782 7.29067926,24.4239391 C7.97474045,24.4063121 8.76811949,24.3352368 9.60043644,24.2294821 C10.4307444,24.1017662 11.2979719,23.9188746 12.1127405,23.6440314 C12.5116585,23.4854229 12.9140194,23.3487664 13.2723453,23.1479378 C13.6469435,22.9806975 13.9682242,22.7434855 14.287089,22.5473081 C14.574022,22.3000645 14.9448138,22.0766527 15.1651502,21.8173895 L8.32781949,17.377175 C6.96451105,16.4447939 6.42219194,14.5593321 7.32696377,13.1141366 L12.334819,5.35497893 C13.2395908,3.90978346 15.3079635,3.59772899 16.6712767,4.52937758 C17.5801521,5.15047664 20.0453122,6.75137031 24.0667569,9.33205859 C25.4307823,10.264435 25.9723844,12.1499015 25.0676126,13.595097 L20.0248638,21.3602493 Z" id="Path" transform="translate(15.256714, 14.774274) scale(-1, -1) rotate(327.000000) translate(-15.256714, -14.774274) "></path>
                        <path d="M41.0248638,23.3602493 C40.372821,24.4148575 39.596335,25.0218034 39.2585408,25.262785 C38.9320145,25.53519 38.0923235,26.0057667 37.6750086,26.2377425 C37.2775604,26.5018183 36.8125973,26.6740403 36.3581473,26.8835504 C35.8839867,27.0367877 35.4053636,27.2149583 34.9086956,27.3009537 C34.4168098,27.423542 33.9154305,27.461957 33.4232581,27.5178917 C31.4451177,27.6508643 29.5889386,27.326711 28.2337935,27.0176159 C26.8779362,26.707793 26,26.4395475 26,26.4395475 C26,26.4395475 26.9224717,26.4723782 28.2906793,26.4239391 C28.9747404,26.4063121 29.7681195,26.3352368 30.6004364,26.2294821 C31.4307444,26.1017662 32.2979719,25.9188746 33.1127405,25.6440314 C33.5116585,25.4854229 33.9140194,25.3487664 34.2723453,25.1479378 C34.6469435,24.9806975 34.9682242,24.7434855 35.287089,24.5473081 C35.574022,24.3000645 35.9448138,24.0766527 36.1651502,23.8173895 L29.3278195,19.377175 C27.9645111,18.4447939 27.4221919,16.5593321 28.3269638,15.1141366 L33.334819,7.35497893 C34.2395908,5.90978346 36.3079635,5.59772899 37.6712767,6.52937758 C38.5801521,7.15047664 41.0453122,8.75137031 45.0667569,11.3320586 C46.4307823,12.264435 46.9723844,14.1499015 46.0676126,15.595097 L41.0248638,23.3602493 Z" id="Path" transform="translate(36.256714, 16.774274) scale(-1, -1) rotate(57.000000) translate(-36.256714, -16.774274) "></path>
                    </g>
                </g>
            </svg>
            <h5 class="title mb-0">
                {{ 'ROOM.ADD' | translate }}
                <div *ngIf="operation('creating-room').submitting" class="loading-overlay">
                    <app-core-spinner [toggle]="true"></app-core-spinner>
                </div>
            </h5>
        </div>
    </div>

    <div class="col-md-8 col-xxl-8 col-xxxl-9 px-0">
        <!-- quando non ci sono blocchi di domande -->
        <div *ngIf="!openedRoomId" class="panel shadow p-5">
            <h3 class="regular mb-3" [innerHTML]="'BLOCK.SET_RESEARCH_INSTRUCTION_ROOM_CLOSED' | translate"></h3>
            <!-- <div class="d-flex align-items-center mb-3"><i class="fas fa-arrow-left text-gray mr-3" style="font-size:2.143rem;"></i><h3 class="regular" [innerHTML]="'BLOCK.SET_RESEARCH_INSTRUCTION_2' | translate"></h3></div>
            <div class="d-flex align-items-center mb-3"><i class="fas fa-arrow-down text-gray mr-3" style="font-size:2.143rem;"></i><h3 class="regular" [innerHTML]="'BLOCK.SET_RESEARCH_INSTRUCTION_3' | translate"></h3></div> -->
        </div>
        <div *ngIf="openedRoom && !openedRoom.date_start" class="panel shadow p-5">
            <div class="d-flex align-items-center mb-3"><i class="fas fa-arrow-left text-gray mr-3" style="font-size:2.143rem;"></i><h3 class="regular" [innerHTML]="'BLOCK.SET_RESEARCH_INSTRUCTION_ROOM_OPENED' | translate"></h3></div>
        </div>
        <div *ngFor="let r of rooms">
            <app-private-setup-manage-questions [room]="r" *ngIf="openedRoomId == r.id && openedRoom.date_start"></app-private-setup-manage-questions>
        </div>
    </div>
</div>
