<div class="media-comm">
    <div class="tabs mb-3" *ngIf="showMediaTabs">
        <a *ngIf="comment.hasImages()" (click)="mediaTab = 'images'" [ngClass]="{'active': mediaTab == 'images'}" class="tab panel shadow p-2 mr-1 my-2">{{ 'IMAGES' | translate }}</a>
        <a *ngIf="comment.hasVideos()" (click)="mediaTab = 'videos'" [ngClass]="{'active': mediaTab == 'videos'}" class="tab panel shadow p-2 mr-1 my-2">{{ 'VIDEOS' | translate }}</a>
        <a *ngIf="comment.hasAudios()" (click)="mediaTab = 'audios'" [ngClass]="{'active': mediaTab == 'audios'}" class="tab panel shadow p-2 mr-1 my-2">{{ 'AUDIOS' | translate }}</a>
    </div>
    <div>
        <div (mouseup)="selectText(comment)" *ngIf="mediaTab == 'videos'">
            <div *ngIf="comment.hasVideos()">
                <div *ngFor="let video of comment.videos(); let i = index">
                    <div class="row mt-2">
                        <div [ngClass]="{'col-md-6': !transcriptionBottom, 'd-block': transcriptionBottom}">
                            <video width="100%" height="250" controls id="com_video_player_{{comment.id}}">
                                <source [src]="video.video_url | privatesrc" type="video/mp4">
                                Your browser does not support the video tag.
                            </video>
                        </div>
                        <div [ngClass]="{'col-md-6': !transcriptionBottom, 'd-block w-100': transcriptionBottom}">
                            <h5>{{ 'TRANSCRIPTION' | translate }}</h5>
                            <ng-scrollbar>
                                <div class="transcription-timeline" *ngIf="video.video_data && video.video_data.file_words">
                                    <div class="clickable float-left" *ngFor="let word of video.video_data.file_words"
                                        (click)="setVideoTime(comment.id, word.start, word.end)">{{word.word}}&nbsp;
                                    </div>
                                </div>
                                <div *ngIf="video.hlTranscription" class="transcription" [innerHTML]="video.hlTranscription"></div>
                            </ng-scrollbar>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="mediaTab == 'images'" class="images-list">
            <div *ngFor="let image of comment.images(); let i = index">
                <div class="d-flex flex-column align-items-center">
                    <div class="image-thumb as-link" (click)="openLightbox(i, comment.images())"
                        [ngStyle]="{'background-image': 'url(' + (image.image_url | privatesrc ) + ')'}">
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="mediaTab == 'audios'">
            <div *ngFor="let audio of comment.audios(); let i = index">
                <div class="p-1">
                    <audio class="w-100" id="audio-player" controls>
                    <source [src]="audio.audio_url" type="audio/webm" controlsList="nodownload">
                    {{'BROWSER_NOT_SUPPORTED' | translate}}
                    </audio>
                </div>
            </div>
        </div>
    </div>
</div>