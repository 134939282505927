import { Component, OnInit, Input } from '@angular/core';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { UntypedFormGroup, Validators, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { UserService } from '@app/shared/services/user.service';
import { ModalService } from '@app/core/services/modal.service';
import { LanguagesService } from '@app/shared/services/languages.service';
import { AuthService } from '@app/core/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ResponseModel } from '@app/core/models/response.model';
import { ServiceErrorModel } from '@app/core/models/service-error.model';

@Component({
  selector: 'app-public-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['./forgotten-password.component.scss']
})
export class ForgottenPasswordComponent extends CoreBaseComponent implements OnInit {

  form: UntypedFormGroup = null;

  constructor(private formBuilder: UntypedFormBuilder, private userService: UserService, private authService: AuthService,
              private translate: TranslateService, private languageService: LanguagesService) {
      super();
  }

  ngOnInit() {
      this.form = this.formBuilder.group({
        username: new UntypedFormControl('', [
          Validators.required
        ])
      });
  }

  submit() {
    this.operation('requesting').reset().isSubmitting();
    const data = this.form.getRawValue();
    data.otherdata = 'APP-RES';
    this.authService.askPasswordReset(data, null, this.languageService.currentLanguage).subscribe((res: ResponseModel) => {
      this.operation('requesting').isSuccess();
    }, (error: ServiceErrorModel) => {
      this.operation('requesting').isFailed(error);
    });
  }


}
