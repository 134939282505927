import { Component, OnInit, ViewChild, AfterViewInit, Input, EventEmitter, Output } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { QuestionsService } from '@app/shared/services/questions.service';
import { NgScrollbar } from 'ngx-scrollbar';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { getLocaleDayNames } from '@angular/common';
import { ResearchModel } from '@app/shared/models/research.model';
import { DatePipe } from '@angular/common';
import { ResearchSessionService } from '@app/shared/services/research-session.service';
import { RoomsService } from '@app/shared/services/rooms.service';
import { ResponseModel } from '@app/core/models/response.model';
import { ActivatedRoute } from '@angular/router';
import { RoomModel } from '@app/shared/models/room.model';
import { BlockService } from '@app/shared/services/block.service';
import { BlockModel } from '@app/shared/models/block.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@app/core/services/auth.service';
import 'quill-emoji/dist/quill-emoji.js';
import { TeamModel } from '@app/shared/models/team.model';
import { TeamService } from '@app/shared/services/team.service';
import { PageModel } from '@app/core/models/page';
import { AnswersService } from '@app/shared/services/answers.service';
import { MessageModel } from '@app/shared/models/message.model';
import { DatetimeToDbPipe } from '@app/core/pipes/datetime-to-db.pipe';
import { ToastrService } from 'ngx-toastr';
import { AnswerModel } from '@app/shared/models/answer.model';
import { AnswersModalSupportService } from '@app/shared/services/support/answers-modal.service';
import { QuotesMinimumWords } from '../../../../../../../../../../setup';
import { DateToLocaleStringPipe } from '@app/core/pipes/date-to-locale-string.pipe';
@Component({
  selector: 'app-moderation-peopleview-question-answers',
  templateUrl: './question-answer.component.html',
  styleUrls: ['./question-answer.component.scss']
})
export class ModerationPeopleQuestionAnswerComponent extends CoreBaseComponent implements OnInit {

  @Input()
  question: QuestionModel;
  @Input()
  selectedTeamUser: TeamModel;
  @Output()
  replyTo: EventEmitter<AnswerModel> = new EventEmitter(null);

  @Input()
  roomid: number;
  @Input()
  answer: AnswerModel[] = []
  @Input()
  country_uniqid: string;
  @Input()
  questionid: number;

  public quillModules = {
    'emoji-shortname': true,
    'emoji-textarea': true,
    'emoji-toolbar': true,
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
      ['clean'],
      ['link', 'image', 'video'],
      ['emoji']
    ]
  };

  public intervieweds: TeamModel[] = null;
  public showSelectionModal = false;
  public answerText = null;
  // public answer: AnswerModel[];
  public loadingChat = true;

  constructor(private questionsService: QuestionsService, private researchSessionService: ResearchSessionService,
              private translate: TranslateService, private teamService: TeamService, private toastService: ToastrService,
              private answersService: AnswersService, private datetimeToDbPipe: DatetimeToDbPipe,
              private datePipe: DatePipe, private roomsService: RoomsService, private blocksService: BlockService,
              public authService: AuthService, private dateToEuPipe: DateToLocaleStringPipe,
              public answersModalSupportService: AnswersModalSupportService) {
                super();
              }

  ngOnInit(): void {
    this.operation('loading-answer').reset().isSubmitting().isSuccess();
    this.loadingChat = false;
    /*
    this.answersService.getAnswers({
        userid: this.selectedTeamUser.userid,
        questionid: this.question.id,
        include_comment: true,
        include_quote: true,
        include_stats: true
    }).subscribe((res: ResponseModel) => {
        this.operation('loading-answer').isSuccess();
        this.loadingChat = false;
        this.answer = res.getData();

        if (this.answer.length) {
          // this.getComments(this.answer[0]);
        }
    });
    */
  }

  textSelected(data) {
    if (this.answersModalSupportService.activeQuotes$.value) {
      let selectedText = null;
      if (window.getSelection) {
        selectedText = window.getSelection().toString().trim();
      }
      // Replace non-breaking-space chars TRICK
      var temp = document.createElement('div'), str;
      temp.innerHTML = selectedText;
      //@ts-ignore
      str = temp.innerText || temp.textContent || temp.text;
      if(!str) {
        return false;
      }
      var normalizedString = str.split(/\s/);
      temp = null;
      // End replacing non-breaking-space chars TRICK
      const size = normalizedString.length;
      if(size >= QuotesMinimumWords) {
        this.answersModalSupportService.answerSelected$.next(this.answer[0]);
        if(data && data.commentid) {
          this.answersModalSupportService.commentSelected$.next(data.commentid);
        } else {
          this.answersModalSupportService.commentSelected$.next(null);
        }
        this.answersModalSupportService.showQuotesModal$.next(true);
      }
    }
  }

  replyFeedback(answer: AnswerModel) {
    this.replyTo.emit(answer);
  }

}
