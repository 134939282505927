
<div class="received-msg flex-grow-1 w-100">
    <div class="received-w-msg w-100 arrow-left">
        <!-- INIZIO ALTRI TIPI RISPOSTA -->
        <app-moderation-answerdispatcher  
            [item]="answer" 
            [classes]="'individual-view'"
            [message]="message"
            (textSelection)="selectText()"
            [question]="question">
        </app-moderation-answerdispatcher>
        <div *ngIf="answerIdToModify">
            MODIFICA {{ answerTextModified }}
        </div>
        <!-- FINE ALTRI TIPI RISPOSTA -->
        <!-- INIZIO FOOTER RISPOSTA -->
        <div class="panel-footer" *ngIf="!isCustomerPro">
            <div class="row">
                <div class="col-12">
                    <div class="tool tag-tool text-nowrap">
                        <i class="far fa-tag"></i>
                        <span>{{ 'CONTENT_TAG' | translate }}</span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-9 align-items-center justify-content-start flex-grow-1 d-flex">
                    <div class="tool tags-select-tool w-100 pr-2">
                        <app-core-select 
                            [classes]="'form-control'"
                            [form]="contentTagsForm"
                            [formEntityName]="'content_tags'"
                            [searchable]="true"
                            [addTag]="true"
                            (change)="tagsChanged($event, answer)"
                            [customList]="contentTags"
                            [multiple]="true"
                            [readonly]="isCustomerPro"
                            >
                        </app-core-select>
                    </div>
                    <div class="d-flex align-items-center justify-content-end mt-3 mt-lg-0">
                        <div class="tool favourites-tool stars flex-grow-1">
                            <app-moderation-starsrating [readonly]="isCustomerPro" [answer]="answer"></app-moderation-starsrating>
                        </div>
                        <div style="min-width: 50px;" class="tool like-tool">
                            <i class="fal fa-heart favourite-yes" (click)="likeAnswer(answer)" [ngClass]="{'favourite-yes': answer.currentuser_like_answer}"></i>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 d-flex">
                    <div class="tool mod-del-tool">
                        <a *ngIf="answer.question_type == 'open-answer' && !isCustomerPro" (click)="modifyAnswer(answer)" class="mr-5">{{ 'MODIFY' | translate }}</a>
                        <a *ngIf="!isCustomerPro" (click)="deleteAnswer(answer)">{{ 'DELETE' | translate }}</a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12 text-right">
                    <div class="tool mod-del-tool">
                        <button class="btn-sicche active" *ngIf="!isCustomerPro" (click)="reply(answer)">
                            <i class="fal fa-reply"></i>&nbsp;{{ 'REPLY' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- FINE FOOTER RISPOSTA -->
    </div>



    <ng-container *ngIf="answer.comments?.length">
        <div id="comment-{{ comment.id }}-end" 
            class="d-flex incoming-msg" 
            [ngClass]="{'deleted': deletedComments.includes(comment ? comment.id : null)}" 
            [ngClass]="{
                'researcher-comment': comment.team.role != 'INTERVIEWED', 
                'justify-content-end': comment.team.role != 'INTERVIEWED', 
                'justify-content-start': comment.team.userid == selectedTeamUser.userid }" 
            *ngFor="let comment of answer.comments; let i = index;">
            

            <!-- SE E' COMMENTO DEL RICERCATORE (avatar a destra)-->
            <div *ngIf="comment.team.role != 'INTERVIEWED'" class="w-100 d-flex flex-row mt-3">
                <div class="received-msg d-flex flex-grow-1 pr-1">
                    <div class="received-w-msg w-100 arrow-right bg-almost-white-dark">
                        <div [hidden]="commentToModify?.id == comment.id" [innerHTML]="comment.comment"></div>
                        <div *ngIf="commentToModify?.id == comment.id">
                            <quill-editor 
                                [(ngModel)]="commentNewText"
                                [modules]="quillModules"
                                [placeholder]="'MESSAGES_SECTION.MESSAGE' | translate"
                                [styles]="{height: '200px'}">
                            </quill-editor>
                            <button class="btn btn-success" (click)="saveCommentMods(comment)">
                                <span *ngIf="operation('saving-comment').submitting" class="spinner-border spinner-border-sm" role="status">
                                    <span class="sr-only">Loading...</span>
                                </span>
                                {{ 'SAVE' | translate }}
                            </button>
                        </div>
                        <div class="panel-footer">
                            <div class="tools time-date d-flex align-items-center justify-content-between flex-wrap">
                                <div class="tool mod-del-tool">
                                    <a *ngIf="!isCustomerPro" (click)="modifyComment(comment)" class="mr-5">{{ 'MODIFY' | translate }}</a>
                                    <a *ngIf="!isCustomerPro" (click)="deleteComment(comment)" >{{ 'DELETE' | translate }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="incoming-msg-img d-flex flex-column align-items-center justify-content-start text-center">
                    <div class="avatar">
                        <app-shared-avatar [imageUrl]="comment.team.avatar_url"></app-shared-avatar>
                    </div>
                    <span *ngIf="comment.creatoruserid == authService.user.id"><b>{{ 'YOU' | translate }}, {{ 'MODERATOR' | translate | lowercase }}</b></span>
                    <span *ngIf="comment.creatoruserid != authService.user.id"><b>{{ comment.team.nickname ? comment.team.nickname : comment.team.firstname }}</b>, <span *ngIf="comment.team.role == 'RESEARCHER' || comment.team.role == 'OWNER'"><b>{{ 'MODERATOR' | translate | lowercase }}</b></span></span>
                    <div class="time">
                        <small>{{ comment.creationtime | dateAgo }}</small>
                    </div>
                    <div [ngbTooltip]="'PRIVATE_COMMENT' | translate" *ngIf="comment.isPrivate()">
                        <i class="fal fa-eye-slash"></i>
                    </div>
                </div>
            </div>


            <!-- SE E' COMMENTO DELL'INTERVISTATO (avatar a sinistra)-->
            <div *ngIf="comment.team.userid == selectedTeamUser.userid" class="w-100 d-flex flex-row mt-3">
                <div class="incoming-msg-img d-flex flex-column align-items-center justify-content-start text-center">
                    <div class="avatar">
                        <app-shared-avatar [imageUrl]="comment.team.avatar_url"></app-shared-avatar>
                    </div>
                    <span><b>{{ comment.team.nickname ? comment.team.nickname : comment.team.firstname + ' ' + comment.team.lastname }}</b></span>
                    <div class="time">
                        <small>{{ comment.creationtime | dateAgo }}</small>
                        <i *ngIf="comment.device == 'SMARTPHONE'" class="fal fa-mobile"></i>
                        <i *ngIf="comment.device == 'DESKTOP'" class="far fa-desktop"></i>
                    </div>
                    <div>&nbsp;<i *ngIf="comment.team.is_locked" class="far fa-lock" style="font-size: 15px;"></i></div>
                </div>
                <div class="received-msg d-flex flex-grow-1 pr-1">
                    <div class="received-w-msg w-100 arrow-left">
                        <div class="row">
                            <!--<div [ngClass]="{'col-md-12': comment.hasMedia(), 'col-md-12': !comment.hasMedia()}">-->
                            <div class="col-md-12">
                                <div (mouseup)="selectText(comment)" [hidden]="commentToModify?.id == comment.id" [innerHTML]="comment.comment"></div>
                            </div>
                            <div *ngIf="comment.hasMedia()" class="media-comm col-md-12">
                                <app-moderation-answer-comment-media [transcriptionBottom]="true" (transcriptionSelection)="selectText($event)" [comment]="comment"></app-moderation-answer-comment-media>
                            </div>
                        </div>
                        <div *ngIf="commentToModify?.id == comment.id">
                            <quill-editor 
                                [(ngModel)]="commentNewText"
                                [modules]="quillModules"
                                [placeholder]="'INSERT-TEXT' | translate"
                                [styles]="{height: '200px'}">
                            </quill-editor>
                            <button class="btn btn-success" (click)="saveCommentMods(comment)">
                                <span *ngIf="operation('saving-comment').submitting" class="spinner-border spinner-border-sm" role="status">
                                    <span class="sr-only">Loading...</span>
                                </span>
                                {{ 'SAVE' | translate }}
                            </button>
                        </div>
                        <div class="panel-footer">
                            <div class="row">
                                <div class="col-12">
                                    <div class="tool tag-tool text-nowrap">
                                        <i class="far fa-tag"></i>
                                        <span>{{ 'CONTENT_TAG' | translate }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-9 d-flex align-items-center justify-content-start" *ngIf="!isCustomerPro">
                                    <div class="tool tags-select-tool w-100 pr-2">
                                        <div class="d-flex justify-content-center">
                                            <div *ngIf="!contentTagsReceived" class="spinner-border spinner-border-sm" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                        <app-core-select
                                            *ngIf="contentTagsReceived"
                                            [classes]="'form-control'"
                                            [form]="comment.commentTagsForm"
                                            [formEntityName]="'content_tags'"
                                            [searchable]="true"
                                            [addTag]="true"
                                            [customList]="moderationHelperService.contentTagList"
                                            [multiple]="true"
                                            >
                                        </app-core-select>
                                    </div>
                                    <div class="tool favourites-tool stars flex-grow-1" *ngIf="!isCustomerPro">
                                        <app-moderation-starsrating [readonly]="isCustomerPro" [comment]="comment"></app-moderation-starsrating>
                                    </div>
                                    <div class="tool like-tool" *ngIf="!isCustomerPro" style="width: 120px;">
                                        <i class="fal fa-heart" (click)="likeComment(comment)" [ngClass]="{'favourite-yes': comment.currentuser_like_comment}"></i>
                                        <span class="total_answer_like" *ngIf="comment.total_like_to_comment">({{ comment.total_like_to_comment }})</span>
                                    </div>
                                </div>
                                <div class="col-xl-3 d-flex align-items-center justify-content-start" *ngIf="!isCustomerPro">
                                    <div class="tools time-date d-flex align-items-center justify-content-between flex-wrap">
                                        <div class="tool mod-del-tool">
                                            <a *ngIf="!isCustomerPro" (click)="modifyComment(comment)" class="mr-4">{{ 'MODIFY' | translate }}</a>
                                            <a *ngIf="!isCustomerPro" (click)="deleteComment(comment)" >{{ 'DELETE' | translate }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>


</div>
