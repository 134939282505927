<nav class="navbar navbar-expand-lg navbar-light p-0">
    <button class="navbar-toggler ml-auto btn-sicche" type="button"
        (click)="toggleNavbar()">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse"
        [ngClass]="{ 'show': navbarOpen }">
        <ul class="navbar-nav ml-auto">
            <li class="nav-item va">
                <span *ngIf="tab == 1" class="d-block mr-2 text-orange">
                    <small>{{ 'YOU_DONT_HAVE_ACCOUNT' | translate }}?</small>
                </span>
                <span *ngIf="tab == 2" class="d-block mr-2 text-orange">
                    <small>{{ 'YOU_HAVE_ACCOUNT_ALREADY' | translate }}?</small>
                </span>
            </li>
            <li class="nav-item va-middle">
                <button class="btn-sicche active" (click)="toggle()">
                    <span *ngIf="tab == 1"><i class="fal fa-user"></i> {{ 'CREATE_AN_ACCOUNT' | translate }}</span>
                    <span *ngIf="tab == 2">{{ 'DO_LOGIN' | translate }}</span>
                </button>
            </li>
        </ul>
    </div>
</nav>