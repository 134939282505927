import { QuoteModel } from '@app/shared/models/quote.model';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AnswersService } from '@app/shared/services/answers.service';
import { ToastrService } from 'ngx-toastr';
import { AnswerModel } from '@app/shared/models/answer.model';
import { ResponseModel } from '@app/core/models/response.model';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModerationHelperService } from '@app/shared/services/moderation-helper.service';


@Component({
  selector: 'app-moderation-quotes-modal',
  templateUrl: './quotes-modal.component.html',
  styleUrls: ['./quotes-modal.component.scss']
})
export class ModerationQuotesModalComponent implements OnInit {

  public quoteForm: UntypedFormGroup;
  @Input()
  answer: AnswerModel;
  @Input()
  commentid: number;
  @Input()
  set extraData(value) {
    this._extraData = value;
  };
  @Output()
  closeFunc: EventEmitter<any> = new EventEmitter(null);
  public selectedText = null;
  public submitting = false;
  private _extraData = null;

  constructor(public answerService: AnswersService, private toastService: ToastrService, private translate: TranslateService,
    private moderationHelperService: ModerationHelperService) { }

  ngOnInit() {
    if(this.commentid) {
      this._extraData = {commentid: this.commentid};
    }
    this.quoteForm = new UntypedFormGroup({
      quote: new UntypedFormControl('', Validators.required),
      quote_tags: new UntypedFormControl('', null),
      note: new UntypedFormControl('', null),
      color: new UntypedFormControl('', null)
    });
    if (window.getSelection) {
      this.selectedText = window.getSelection().toString();
      this.quoteForm.patchValue({
        quote: this.selectedText
      });
    }
  }

  closeModal(quote: QuoteModel = null) {
    this.closeFunc.emit(quote);
  }

  saveExcerpt() {
    const quoteOriginal = this.quoteForm.value.quote.split("\n");

    // Remove single words - quotes should be phrases
    for (var i = quoteOriginal.length - 1; i >= 0; i--) {
      if(quoteOriginal[i].split(' ').length <= 1) {
        quoteOriginal.splice(quoteOriginal[i], 1);
      }
    };

    this.submitting = true;
    let params = {
      answerid: this.answer.id,
      commentid: null,
      quote: this.quoteForm.value.quote?.replaceAll(/\s{2,}/g, ' ')?.replaceAll(/\r?\n|\r/g, ' '),
      quote_original: quoteOriginal,
      note: this.quoteForm.value.note,
      // quote_tags: this.quoteForm.value.quote_tags.map(tag => tag ? tag.name : ''),
      stars: null,
      color: this.quoteForm.value.color
    };
    // Extra data
    if(this._extraData) {
      if(this._extraData.commentid) {
        params = Object.assign(params, this._extraData);
        if(params.commentid) {
          params.answerid = null;
        }
      }
    }
    // Empy extra data
    this._extraData = null;

    //console.log(params.quote);
    //const rex = /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/ug;
    //params.quote_original = params.quote.replace(rex, match => ``);
    //console.log(params.quote_original);

    // Save
    this.answerService.addQuote(params).subscribe((res: ResponseModel) => {
      this.moderationHelperService.refreshQuotes.next(true);
      this.closeModal(res.getData()[0]);
      this.submitting = false;
      this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
    }, (e) => {
      this.moderationHelperService.refreshQuotes.next(true);
      this.submitting = false;
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
    });

  }


}
