import { BaseModel } from '../base';
import { SidebarVoice } from './sidebar-voice.interface';
/**
 * Page Model
 */

export class SidebarVoiceModel implements SidebarVoice {

  label: string;
  url?: string;
  component: any;
  children: [];
  disabled?: boolean;
  slug?: string;

  constructor(data: SidebarVoice) {
    Object.assign(this, data);
  }

  disable() {
    this.disabled = true;
  }

  enable() {
    this.disabled = false;
  }

}
