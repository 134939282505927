import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import Wordcloud from 'highcharts/modules/wordcloud';
import { RoomsService } from '@app/shared/services/rooms.service';
import { RoomModel } from '@app/shared/models/room.model';
import { ResponseModel } from '@app/core/models/response.model';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { BlockService } from '@app/shared/services/block.service';
import { BlockModel } from '@app/shared/models/block.model';
import { QuestionsService } from '@app/shared/services/questions.service';
import { QuestionModel } from '@app/shared/models/question.model';
import { CountryService } from '@app/shared/services/country.service';
import { AnalysisService } from '@app/shared/services/analysis.service';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { AnswerModel } from '@app/shared/models/answer.model';
import { ResearchSessionService } from '@app/shared/services/research-session.service';
import { DatetimeToDbPipe } from '@app/core/pipes/datetime-to-db.pipe';
import * as html2canvas from 'html2canvas';
import { CanvasElementContainer } from 'html2canvas/dist/types/dom/replaced-elements/canvas-element-container';
import exporting from 'highcharts/modules/exporting';
import { image } from 'html2canvas/dist/types/css/types/image';
import { CommentModel } from '@app/shared/models/comment.model';
import { TeamService } from '@app/shared/services/team.service';
import { ToastrService } from 'ngx-toastr';
import { QuotesMinimumWords } from '../../../../../../../../../../../setup';
import { AnswersService } from '@app/shared/services/answers.service';
import { AnswersModalSupportService } from '@app/shared/services/support/answers-modal.service';
exporting(Highcharts);
Wordcloud(Highcharts);

@Component({
  selector: 'app-overview-modal-cloud',
  templateUrl: './cloud.component.html',
  styleUrls: ['./cloud.component.scss']
})
export class OverviewModalCloudComponent extends CoreBaseComponent implements OnInit {

  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @Input()
  countryUniqId = null;
  public ready = false;
  public data = [];
  private commentsToDownload = [];
  public comments = [];
  public answers = [];
  public word: string = null;
  public cloudReady = false;
  public filterForm: UntypedFormGroup = null;
  public selectedPoint = null;
  public Highcharts: typeof Highcharts = Highcharts;
  public downloading = false;
  public elaboratedImages = 0;
  public downloadTime = 0;
  public imagesToDownload = [];
  public contentTags = null;
  public isSmart = false;
  public chartOptions: Highcharts.Options = {
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },
    series: [{
      name: '',
      data: this.data,
      type: 'wordcloud',
      point:{
        events: {
          click: (event) => {
            this.wordSelected(event);
          }
        }
      },
      style: {
        fontFamily: 'Arial'
      }
    }],
    exporting: {
      chartOptions: {
          title: {
              text: ''
          }
      },
      buttons: {
        contextButton: {
          menuItems: ['downloadJPEG', 'downloadPDF', 'downloadSVG'],
        },
      },
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      series: {
        allowPointSelect: true
      },
      wordcloud: {
        minFontSize: 6,
        rotation: {from: 0, to: 0}
      }
    }
  };

  imagesToDownloadCount: number;

  public selectedText = null;
  public showSelectionModal = false;
  public quotedAnswer = null;
  public quotedCommentid = null;
  activeQuotes = false;


  constructor(private answersService: AnswersService, private translate: TranslateService, private roomsService: RoomsService,
              private analysisService: AnalysisService, private researchSessionService: ResearchSessionService,
              private dateTimeToDb: DatetimeToDbPipe, private toastService: ToastrService, private teamService: TeamService,
              private answersModalSupportService: AnswersModalSupportService, private countryService: CountryService) {
                  super();
              }

  ngOnInit(): void {
    this.countryService.currentContentTag.subscribe(contentTags => {
      this.contentTags = contentTags;
    });
    this.isSmart = this.countryService.currentCountryIsClientBasic();
    this.initSearch();
    this.answersModalSupportService.activeQuotes$.subscribe(val => {
      this.activeQuotes = val;
    });
  }

  getXlsExport() {
    this.downloading = true;

    const answerIds = this.answers.filter(a => a.state.selected).map(a => a.id.toString());
    const commentIds = this.comments.filter(c => c.state.selected).map(c => c.id.toString());

    this.analysisService.getPostXlsExport(this.countryUniqId, answerIds, commentIds, 'tagcloud').subscribe((res: ResponseModel) => {
      this.downloading = false;
      this.toastService.success(this.translate.instant('PDF_EXPORT_ALL_EMAIL_SENT', {email: this.teamService.currentUser.email}), null, {timeOut: 10000});
    })
  }

  async selectText(event) {
    if (!this.activeQuotes) {
      return false;
    }

    this.selectedText = null;
    if (window.getSelection) {
      this.selectedText = window.getSelection().toString();
    }
    if (event.answer) {
      this.quotedAnswer = event.answer
    }
    if (event.commentid) {
      this.quotedCommentid = event.commentid
      const comment = this.comments.find(c => c.id === this.quotedCommentid);
      this.quotedAnswer = await this.answersService.getAnswerByComment(comment);
    }
    // Replace non-breaking-space chars TRICK
    this.selectedText += "";
    var temp = document.createElement('div'), str;
    temp.innerHTML = this.selectedText;
    //@ts-ignore
    str = temp.innerText || temp.textContent || temp.text;
    if(!str) {
      return false;
    }
    var normalizedString = str.split(/\s/);
    temp = null;
    // End replacing non-breaking-space chars TRICK
    if (this.selectedText) {
      const size = normalizedString.length;
      if(size >= QuotesMinimumWords) {
        this.showSelectionModal = true;
      }
    } else {
      this.showSelectionModal = false;
    }
  }

  quotesModalClose() {
    this.showSelectionModal = false;
    this.getAnswersByFilter(null, true);
  }


  initSearch() {
    this.filterForm = new UntypedFormGroup({
        room: new UntypedFormControl('', null),
        block: new UntypedFormControl('', null),
        question: new UntypedFormControl('', null),
        sample_variable_tags: new UntypedFormControl('', null),
        personas: new UntypedFormControl('', null),
        block_datetime: new UntypedFormControl('', null),
        only_highlighted: new UntypedFormControl('', null),
        keyword_search_content: new UntypedFormControl('', null),
        intervieweds: new UntypedFormControl('', null),
        only_contest: new UntypedFormControl('', null),
        only_live: new UntypedFormControl('', null),
        answer_tags: new UntypedFormControl('', null),
        rating: new UntypedFormControl('', null),
    });
    this.operation('getting-tags').reset().isSubmitting();
    this.analysisService.getTagStats(this.countryUniqId).subscribe((res: ResponseModel) => {
        const data = res.getData();
        Object.keys(data).forEach((key, index) => {
          if (key !== 'state') {
            this.data.push({
                name: key,
                weight: data[key]
            });
          }
        });
        this.operation('getting-tags').isSuccess();
        this.cloudReady = true;
    }, (e) => {
      this.operation('getting-tags').isFailed();
    });
  }

  getCommentsOfAnswer(answer: AnswerModel) {
    let output = [];
    if (answer) {
      output = this.comments.filter(c => c.answerid === answer.id)
    }
    return output;
  }

  wordSelected(event) {
    this.word = null;
    this.operation('getting-by-word').reset().isSubmitting();
    this.word = event.point.name;
    this.getAnswersByFilter();
  }

  processResponse(data) {
    // 20210709 ordino i post prima per blockid poi per data, così sono ordinati per data e raggruppati per blocco
    const tempAnswers = data.answers;
    tempAnswers.sort((a,b) => (a.blockid > b.blockid) ? 1 : ((b.blockid > a.blockid) ? -1 : 0))
    tempAnswers.sort((a,b) => (a.block_datetime_start > b.block_datetime_start) ? 1 : ((b.block_datetime_start > a.block_datetime_start) ? -1 : 0))
    this.answers = tempAnswers

    const tempComments = data.comments;
    tempComments.sort((a,b) => (a.blockid > b.blockid) ? 1 : ((b.blockid > a.blockid) ? -1 : 0))
    tempComments.sort((a,b) => (a.block_datetime_start > b.block_datetime_start) ? 1 : ((b.block_datetime_start > a.block_datetime_start) ? -1 : 0))
    this.comments = tempComments


    this.answers.forEach((answer, index) => {
      this.answers[index] = new AnswerModel(this.answers[index]);
      answer.state = {
        selected: true
      };
    });
    this.comments.forEach((comment, index) => {
      this.comments[index] = new CommentModel(this.comments[index]);
      comment.state = {
        selected: true
      };
    });
  }

  selectAll(event) {
    this.answers.map(answer => {
      answer.state.selected = event.target.checked ? true : false;
    });
    this.comments.map(comment => {
      comment.state.selected = event.target.checked ? true : false;
    });
  }

  downloadSelected() {
    const commentsIdsToDownload = [];
    // Comment
    for (const comment of this.comments) {
      if (comment.state.selected) {
        commentsIdsToDownload.push(comment.id);
      }
    }
    // mi serve un array ordinato come a fe con sia commenti che answer
    const arr = [];
    for (const answer of this.answers) {
      if (answer.state.selected) {
        arr.push({
          id: answer.id,
          isAnswer: true
        })
        for (const comment of answer.comments) {
          if (commentsIdsToDownload.includes(comment.id)) {
            arr.push({
              id: comment.id,
              isAnswer: false
            })
          }
        }
      }
    }
    for (const comment of this.comments) {
      if (comment.state.selected && !arr.find(e => e.id == comment.id && e.isAnswer == false)) {
        arr.push({
          id: comment.id,
          isAnswer: false
        })
      }
    }

    if (arr.length  > 0) {
      this.downloading = true;

      this.analysisService.getPostExport(this.countryUniqId, arr).subscribe((res: ResponseModel) => {
        this.downloading = false;
        this.toastService.success(this.translate.instant('PDF_EXPORT_ALL_EMAIL_SENT', {email: this.teamService.currentUser.email}), null, {timeOut: 10000});
      })
    }
  }

  downloadScreenShot(answerids: number[] | string[], commentids = []) {
    this.imagesToDownloadCount = answerids.length + commentids.length;
    this.downloading = true;
    this.downloadTime = (answerids.length + commentids.length) * 2;
    setTimeout(async () => {
      for (const answerid of answerids) {
        await this.takeScreenshot(answerid, 'answer-exportable', false);
        this.elaboratedImages++;
        this.waitSync(500);
      }
      for (const commentid of commentids) {
        await this.takeScreenshot(commentid, 'answer-block-comment-exportable', false);
        this.elaboratedImages++;
        this.waitSync(500);
      }
      this.downloading = false;
      this.elaboratedImages = 0;
    }, 1000);
  }

  takeScreenshot(itemId, type, instantDownload = false) {
    const element = document.getElementById(type + '-' + itemId);
    // @ts-ignore
    // tslint:disable-next-line: max-line-length
    html2canvas(element, { letterRendering: 1, allowTaint : true, useCORS: true}).then((canvas: any) => {
      // document.body.appendChild(canvas);
      if (instantDownload) {
        this.downloadFile(canvas.toDataURL('image/png'), type + '-' + itemId + '.png');
      } else {
        const imageData = canvas.toDataURL('image/png');
        if(type === 'answer-exportable') {
          this.imagesToDownload = [...this.imagesToDownload, {id: itemId, image: imageData}];
        } else {
          this.commentsToDownload = [...this.commentsToDownload, {id: itemId, image: imageData}];
        }
        if (this.imagesToDownload.length + this.commentsToDownload.length === this.imagesToDownloadCount) {
          this.exportAnswerImages();
        }
      }
    });
  }

  exportAnswerImages() {
    this.analysisService.getAnswersExport(
      this.countryUniqId,
      this.imagesToDownload,
      this.commentsToDownload
    )
      .subscribe((res: ResponseModel) => {
      res.download('answers.zip');
    });
  }

  private formatDate(date) {
    if(!date) {
      return null;
    }
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  hasSelected() {
    const foundAnswersSelected = this.answers.filter((answer: any) => answer.state.selected === true);
    const foundCommentsSelected = this.comments.filter((comment: any) => comment.state.selected === true);
    if (!foundAnswersSelected && !foundCommentsSelected) {
      return 0;
    } else {
      return foundAnswersSelected.length + foundCommentsSelected.length;
    }
  }

  getAnswersByFilter(event?: any, scrollToLastQuote = false) {
    if(!this.ready || !this.word) {
      return;
    }
    let date = '';
    if (this.filterForm.value.block_datetime) {
      date = this.formatDate(this.filterForm.value.block_datetime.date);
    }
    const all_rooms = this.filterForm.value.room ? this.filterForm.value.room.length == 1 && this.filterForm.value.room[0].id == 0 ? true : false : false;
    this.operation('getting-answers').reset().isSubmitting();
    const filters = {
      include_quote: true,
      all_rooms,
      only_highlighted: this.filterForm.value.only_highlighted,
      only_contest: this.filterForm.value.only_contest,
      only_live: this.filterForm.value.only_live,
      keyword_search_content: this.filterForm.value.keyword_search_content,
      intervieweds: this.filterForm.value.intervieweds,
      block_datetime: date,
      order_by_block: true,
      /*answerid: '',*/
      questionid: this.filterForm.value.question ? this.filterForm.value.question.id : null,
      rating: this.filterForm.value.rating,
      'answer_tags_by_comma': [this.word],
      'roomids_by_comma': Array.isArray(this.filterForm.value.room) ? this.filterForm.value.room.length ? this.filterForm.value.room.map(item => item.id) : null : null,
      'blockids_by_comma': this.filterForm.value.block ? this.filterForm.value.block.map(item => item.id) : null,
      'team_personas_tags_by_comma': this.filterForm.value.personas ? this.filterForm.value.personas : [],
      'team_sample_variable_tags_by_comma': this.filterForm.value.sample_variable_tags ? this.filterForm.value.sample_variable_tags : []
    };
    Object.keys(filters).forEach((key) => (!filters[key]) && delete filters[key]);
    this.analysisService.getAnswersBy(this.countryUniqId, filters).subscribe((res: ResponseModel) => {
        this.operation('getting-answers').isSuccess();
        this.processResponse(res.getData());

        if (scrollToLastQuote) {
          setTimeout(() => {
            if (this.quotedCommentid) {
              this.scrollAndHighlightElement(`answer-block-comment-${this.quotedCommentid}`);
            } else {
              this.scrollAndHighlightElement(`answer-${this.quotedAnswer?.id}-short`);
            }
          });
        }
    }, (e) => {
      this.operation('getting-answers').isFailed();
    });
  }


  getUnlinkedComments() {
    const output = [];
    for (const comment of this.comments) {
      if (!comment.answerid || !this.answers.find(a => a.id === comment.answerid)) {
        output.push(comment);
      }
    }
    return output;
  }

}
