<section>
    <form [formGroup]="form">
        <div class="container-fluid">

            <app-setup-questions-shared-media-upload
                [question]="question"
                [images]="attachedImages"
                [pdfFiles]="attachedPdfFiles"
                (addImageOut)="addImage($event)"
                (removeImageOut)="removeImage($event)"
                (addPdfOut)="addPdf($event)"
                (removePdfOut)="removePdf($event)">
            </app-setup-questions-shared-media-upload>

            <h4 class="mb-2">{{ 'QUESTION' | translate }}</h4>
            <div class="question-text panel shadow">
                <app-setup-questions-text [form]="form" [countryUniqId]="countryUniqId"></app-setup-questions-text>
            </div>

            <h4>{{ 'COUPLES' | translate }}</h4>
            <div class="row">
                <div class="col-8 col-xl-7">
                    <div class="form-group form-group tool tags-select-tool">
                        <div class="question-filters">
                            <app-core-select
                                [classes]="'form-control'"
                                [multiple]="true"
                                [label]="'view'"
                                [form]="form"
                                [customList]="couples"
                                [formEntityName]="'couples_selection'"
                                (change)="onCoupleSelectionChange($event)">
                            </app-core-select>
                            <span class="placeholder">{{ 'CHOOSE_COUPLES' | translate }}</span>
                        </div>
                    </div>

                    <div>
                        <label>{{ 'OR_ADD_NEW_COUPLES' | translate }}</label>
                        <div class="d-flex justify-content-between">
                            <div class="form-group">
                                <div formGroupName="new_couple" class="row">
                                    <div class="col-6">
                                        <div>
                                            <label>
                                                <input formControlName="label_left" type="text" class="form-control">
                                                <span class="placeholder">{{ 'LEFT' | translate }}</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div>
                                            <label>
                                                <input formControlName="label_right" type="text" class="form-control">
                                                <span class="placeholder">{{ 'RIGHT' | translate }}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="category-buttons">
                                    <i
                                        (click)="addCustomAnswer()"
                                        class="fal fa-plus-circle as-link"
                                        [class.disabled]="!form.get('new_couple').valid" 
                                        style="font-size: 28px;">
                                    </i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <div class="form-group" formGroupName="question_data">
                            <label for="randomize-order">
                                <input formControlName="randomize_order" type="checkbox" id="randomize-order">
                                {{ 'RANDOMIZE_ITEMS_ORDER' | translate }}
                            </label>
                        </div>
                    </div>

                    <hr class="d-xl-none">
                </div>


                <div class="col-7">
                    <div class="form-group align-items-start">
                        <div formArrayName="answers" class="question-options-list" cdkDropList (cdkDropListDropped)="changeQuestionsOrder($event)">
                            <div *ngFor="let answer of form.get('answers')['controls']; let i = index;" class="answer-row" cdkDrag>
                                <div class="answer my-2">
                                    <div class="handler">
                                        <i class="far fa-grip-lines" style="font-size: 18px;"></i>
                                    </div>
                                    <div class="d-flex justify-content-between pl-2">
                                        <div class="row flex-grow-1" [formGroup]="answer">
                                            <div class="col-6 d-flex align-items-center">
                                                <div class="text-input pl-0">
                                                    <label>
                                                        <input formControlName="label_left" type="text" class="form-control">
                                                        <span class="placeholder">{{ 'LEFT' | translate }}</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-6 d-flex align-items-center">
                                                <div class="text-input pl-0">
                                                    <label>
                                                        <input formControlName="label_right" type="text" class="form-control">
                                                        <span class="placeholder">{{ 'RIGHT' | translate }}</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="category-buttons">
                                            <div>
                                                <i
                                                    class="fal fa-minus-circle as-link"
                                                    style="font-size: 28px;"
                                                    [class.disabled]="disabled" 
                                                    (click)="removeAnswer(null, i)">
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <app-setup-questions-selects [question]="question"  [block]="block" [form]="form" [countryUniqId]="countryUniqId"></app-setup-questions-selects>
            <h4 *ngIf="block.is_room_group" class="mb-2">{{ 'GENERALS' | translate }}</h4>
            <div *ngIf="block.is_room_group" class="row question-checks mt-3">
                <div class="col-6">
                    <div class="mb-3">
                        <input formControlName="is_masked" type="checkbox" id="check2">
                        <label for="check2">
                            {{ 'MASQUERADE_QUESTION' | translate }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </form>
</section>




