import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  constructor() {
  }

  /**
   * SetCookie
   */
  setCookie(name: string, value: any, days: number) {
    let expires = '';
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '')  + expires + '; path=/';
    return this;
  }

  /**
   * GetCookie
   */
  getCookie(name: string) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let item of ca) {
        while (item.charAt(0) === ' ') {
            item = item.substring(1, item.length);
        }
        if (item.indexOf(nameEQ) === 0) {
            return item.substring(nameEQ.length, item.length);
        }
    }
    return null;
  }

  /**
   * ForgetCookie
   */
  forgetCookie(keys: string | Array<string>) {
      if (Array.isArray(keys)) {
          keys.forEach(key => this.setCookie(key, null, null));
      } else {
        this.setCookie(String(keys), null, null);
      }
      return this;
  }
}
