<header class="sidebar-header">
    <div class="d-flex align-items-center justify-content-between flex-grow-1">
        <ng-content class="w-100" select="[slot=bar]"></ng-content>
        <div class="flex-grow-1 sidebar-title pl-0">
            <ng-content select="[slot=title]"></ng-content>
        </div>
        <div class="flex-grow-0 d-flex align-items-center">
            <ng-content select="[slot=buttons]"></ng-content>
        </div>
    </div>
    <div class="close-button" [ngClass]="{'disabled': coreLayoutService.sidebarBlock$.value }" (click)="!coreLayoutService.sidebarBlock$.value ? coreLayoutService.closeSidebar() : null">
        <i class="fal fa-times cross"></i>
    </div>
</header>