import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'secondsToTime'
})

export class SecondsToTimePipe implements PipeTransform {
    transform(value: number): any {
        const hours = Math.floor(value / 3600);
        const minutes = Math.floor((value - hours * 3600) / 60);
        const seconds = Math.floor(value - hours * 3600 - minutes * 60);
        let timeString = hours > 0 ? `${hours}:` : '';
        timeString += `0${minutes}:`.slice(-3);
        timeString += `0${seconds}`.slice(-2);
        return timeString;
    }
}
