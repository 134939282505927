<div class="row mb-5">
    <div class="col-6 d-flex align-items-start justify-content-start">
        <div class="d-inline-flex align-items-center">
            <div class="form-group mb-0">
                <label>
                    <span class="placeholder-input-file btn-sicche btn-secondary">{{'UPLOAD_PDF' | translate}}</span>
                    <input class="d-none" type="file" accept="application/pdf" (change)="addPDF($event.target.files)">
                </label>
            </div>
        </div>

        <div class="d-inline-flex align-items-center">
            <div class="form-group mb-0">
                <label>
                    <span class="placeholder-input-file btn-sicche btn-secondary">{{'UPLOAD_PICTURE' | translate}}</span>
                    <input class="d-none" type="file" accept="image/x-png,image/gif,image/jpeg" (change)="addImage($event.target.files)">
                </label>
            </div>
        </div>
    </div>
    <div class="col-6">
        <div *ngIf="images" class="mb-3">
            <span class="d-flex flex-wrap align-items-start mb-3">
                <span class="img-upload-container mb-3" *ngFor="let img of images">
                    <img class="img-upload" [src]="img.img_url || img.img">
                    <span class="cross-delete-btn" (click)="removeImage(img.id)"><i class="fal fa-times"></i></span>
                </span>
            </span>
        </div>
        <div *ngIf="pdfFiles" class="mb-3">
            <span class="d-flex flex-wrap align-items-start border-bottom pb-3">
                <span class="pr-4" *ngFor="let pdf of pdfFiles">
                    <a target="blank" *ngIf="pdf.pdf_url" [href]="pdf.pdf_url | privatesrc" >{{pdf.name}} </a>
                    <a target="blank" *ngIf="!pdf.pdf_url" >{{pdf.name}} </a>
                    <span class="cross-delete-btn ml-2" (click)="removePdf(pdf.id)"><i class="fal fa-times"></i></span>
                </span>
            </span>
        </div>
    </div>
</div>
