<div class="sidebar-body">
    <section *ngIf="countryUniqId">
        <app-overview-modal-filter
            (searchFn)="getVideosByFilter()"
            [filterForm]="filterForm"
            [highlighted]="true"
            [star]="true"
            [countryUniqId]="countryUniqId">
        </app-overview-modal-filter>
    </section>
    <app-core-spinner class="mt-2" [toggle]="operation('getting-videos').submitting"></app-core-spinner>
    <div class="alert alert-info mt-2"  *ngIf="operation('getting-videos').success && !videos.length">{{ 'THERE_ARE_NO_VIDEOS' | translate }}</div>
    <section class="mt-4" *ngIf="!operation('getting-videos').submitting && videos.length">

        <div class="row mt-2 mb-3">
            <div class="col-12 d-flex align-items-center justify-content-end">
                <div class="text-left flex-grow-1">
                    <h3>{{ videosAll.length }} video</h3>
                </div>
                <div class="check-radio-box mb-0 mr-4">
                    <input (change)="selectAll($event)" type="checkbox" id="check1">
                    <label for="check1">
                        {{ 'SELECT_ALL' | translate }}
                    </label>
                </div>
                <button
                    *ngIf="!isSmart"
                    [disabled]="!hasSelected() || downloading"
                    (click)="downloadSelected()"
                    class="btn btn-sicche">
                    <span *ngIf="!downloading">{{ 'DOWNLOAD_SELECTED' | translate }}</span>
                    <span *ngIf="downloading">{{ 'DOWNLOADING' | translate }}...</span>
                </button>
            </div>
        </div>
        <div class="col-12 text-center mt-2">
            <ul class="pagination">
                <li *ngFor="let n of getVideosCountAsArr()" class="page-item" [ngClass]="selectedPage == n ? 'active' : ''" (click)="paginateByPage(n)"><a class="page-link" >{{n + 1}}</a></li>
            </ul>
        </div>
        <div class="row" *ngFor="let video of videos;let i = index;">
            <div class="col-md-4 left-style mb-4" *ngIf="i%3 == 0 && videos[i]">
                <div class="panel shadow p-3 h-100 my-0">
                    <div class="image-wrapper">
                        <input [(ngModel)]="videos[i].state.selected" type="checkbox" class="form-control" class="selection-checkbox">
                        <video width="100%" height="100%" controls>
                            <source [src]="videos[i].file_url | privatesrc" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-10">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="info-group d-flex">
                                            <div class="avatar">
                                                <div class="avatar-bg-img" *ngIf="videos[i].team.avatar_url" [ngStyle]="{'background-image': 'url(' + (videos[i].team.avatar_url | privatesrc) + ')'}"></div>
                                                <div *ngIf="!videos[i].team.avatar_url" class="d-flex align-items-center justify-content-center placeholder-avatar">
                                                    <i class="align-self-center fal fa-user"></i>
                                                </div>
                                            </div>
                                            <div class="ml-2 bold d-flex align-items-center">
                                                <div>{{ videos[i].team.nickname ? videos[i].team.nickname : videos[i].team.firstname + ' ' + videos[i].team.lastname }}</div>
                                            </div>
                                        </div>
                                        <div class="info-group d-flex mt-3">
                                            <i class="align-self-start fal fa-comment"></i>
                                            <div class="ml-2">
                                                <div>{{ videos[i].room_title }} - {{ videos[i].upload_time | dateToLocaleString }} - {{ videos[i].block_title }} - <a [ngbTooltip]="videos[i].question_title" triggers="click:blur" [autoClose]="'outside'">{{ 'QUESTION' | translate }}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 p-0 text-right">
                                <a
                                    download
                                    [href]="videos[i].file_download_url | privatesrc"
                                    popoverClass="export-popover"
                                    class="btn-sicche btn-icon small btn-secondary">
                                        <i class="far fa-download"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 left-style mb-4" *ngIf="i%3 == 0 && videos[i+1]">
                <div class="panel shadow p-3 h-100 my-0">
                    <div class="image-wrapper">
                        <input [(ngModel)]="videos[i+1].state.selected" type="checkbox" class="form-control" class="selection-checkbox">
                        <video width="100%" height="100%" controls>
                            <source [src]="videos[i+1].file_url | privatesrc" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-10">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="info-group d-flex">
                                            <div class="avatar">
                                                <div class="avatar-bg-img" *ngIf="videos[i+1].team.avatar_url" [ngStyle]="{'background-image': 'url(' + (videos[i+1].team.avatar_url | privatesrc) + ')'}"></div>
                                                <div *ngIf="!videos[i+1].team.avatar_url" class="d-flex align-items-center justify-content-center placeholder-avatar">
                                                    <i class="align-self-center fal fa-user"></i>
                                                </div>
                                            </div>
                                            <div class="ml-2 bold d-flex align-items-center">
                                                <div>{{ videos[i+1].team.nickname ? videos[i+1].team.nickname : videos[i].team.firstname + ' ' + videos[i+1].team.lastname }}</div>
                                            </div>
                                        </div>
                                        <div class="info-group d-flex mt-3">
                                            <i class="align-self-start fal fa-comment"></i>
                                            <div class="ml-2">
                                                <div>{{ videos[i+1].room_title }} - {{ videos[i+1].upload_time | dateToLocaleString }} - {{ videos[i+1].block_title }} - <a [ngbTooltip]="videos[i+1].question_title" triggers="click:blur" [autoClose]="'outside'">{{ 'QUESTION' | translate }}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 p-0 text-right">
                                <a
                                    download
                                    [href]="videos[i+1].file_download_url | privatesrc"
                                    popoverClass="export-popover"
                                    class="btn-sicche btn-icon small btn-secondary">
                                        <i class="far fa-download"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 left-style mb-4" *ngIf="i%3 == 0 && videos[i+2]">
                <div class="panel shadow p-3 h-100 my-0">
                    <div class="image-wrapper">
                        <input [(ngModel)]="videos[i+2].state.selected" type="checkbox" class="form-control" class="selection-checkbox">
                        <video width="100%" height="100%" controls>
                            <source [src]="videos[i+2].file_url | privatesrc" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-10">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="info-group d-flex">
                                            <div class="avatar">
                                                <div class="avatar-bg-img" *ngIf="videos[i+2].team.avatar_url" [ngStyle]="{'background-image': 'url(' + (videos[i+2].team.avatar_url | privatesrc) + ')'}"></div>
                                                <div *ngIf="!videos[i+2].team.avatar_url" class="d-flex align-items-center justify-content-center placeholder-avatar">
                                                    <i class="align-self-center fal fa-user"></i>
                                                </div>
                                            </div>
                                            <div class="ml-2 bold d-flex align-items-center">
                                                <div>{{ videos[i+2].team.nickname ? videos[i+2].team.nickname : videos[i+2].team.firstname + ' ' + videos[i+2].team.lastname }}</div>
                                            </div>
                                        </div>
                                        <div class="info-group d-flex mt-3">
                                            <i class="align-self-start fal fa-comment"></i>
                                            <div class="ml-2 bold d-flex align-items-center">
                                                <div>{{ videos[i+2].room_title }} - {{ videos[i+2].upload_time | dateToLocaleString }} - {{ videos[i+2].block_title }} - <a [ngbTooltip]="videos[i+2].question_title" triggers="click:blur" [autoClose]="'outside'">{{ 'QUESTION' | translate }}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 p-0 text-right">
                                <a
                                    download
                                    [href]="videos[i+2].file_download_url | privatesrc"
                                    popoverClass="export-popover"
                                    class="btn-sicche btn-icon small btn-secondary">
                                        <i class="far fa-download"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 text-center mt-2">
            <ul class="pagination">
                <li *ngFor="let n of getVideosCountAsArr()" class="page-item" [ngClass]="selectedPage == n ? 'active' : ''" (click)="paginateByPage(n)"><a class="page-link" >{{n + 1}}</a></li>
            </ul>
        </div>
    </section>
</div>