import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { QuestionsService } from '@app/shared/services/questions.service';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { AnswersService } from '@app/shared/services/answers.service';
import { AnswersModel } from '@app/shared/models/answers.model';
import { CookiesService } from '@app/core/services/cookies.service';
import { AnswersModalSupportService } from '@app/shared/services/support/answers-modal.service';
import { CountryService } from '@app/shared/services/country.service';
import { ResponseModel } from '@app/core/models/response.model';
import { TeamService } from '@app/shared/services/team.service';
import { NotificationService } from '@app/shared/services/notification.service';
import { RoomModel } from '@app/shared/models/room.model';
import * as angular from 'angular';
import { ModerationHelperService } from '@app/shared/services/moderation-helper.service';
import { AnalysisService } from '@app/shared/services/analysis.service';
import { StorageService } from '@app/core/services/storage.service';
@Component({
  selector: 'app-moderation-answers',
  templateUrl: './answers-modal.component.html',
  styleUrls: ['./answers-modal.component.scss']
})
export class AnswersComponent extends CoreBaseComponent implements OnInit {

  @Input()
  question: QuestionModel;
  @Input()
  room: RoomModel;
  public questions: QuestionModel[] = [];
  public loading = true;
  public answer: AnswersModel[] = [];
  public activeQuotes: boolean | any;
  public findInVideo = null;
  public findInVocal = null;
  public allAnswers = null;
  public intervieweds = [];
  public hasVideos = false;
  public hasVocals = false;
  public variablesFilterShow = false;
  public personasTags = [];
  public sampleVariableTags = [];
  public selectedPersonasTags = [];

  public sampleVariableTagsQuestion = [];
  public selectedSampleVariableTags = [];

  public hasQuotes = false;
  public isCustomerPro = false;
  private q1 = null;

  constructor(private questionsService: QuestionsService, private coreLayoutService: CoreLayoutService, private analysisService: AnalysisService,
              private notificationService: NotificationService, public moderationHelperService:  ModerationHelperService,
              private answersService: AnswersService, private cookiesService: CookiesService, private teamService: TeamService,
              public answersModalSupportService: AnswersModalSupportService, private countryService: CountryService,
              private storageService: StorageService) {
    super();
  }

  ngOnInit(): void {
    this.answersService.toSetRead = [];
    this.isCustomerPro = this.teamService.isCurrentUserCustomerPro();
    this.moderationHelperService.setQuotes(null); // todo di troppo?
    this.moderationHelperService.backButtonInSideModal.next(false);
    // If customer pro block quotes
    if (this.isCustomerPro) {
      this.activeQuotesSwitch(false);
    }
    // Get quotes
    this.analysisService.getQuotesBy(this.question.country_uniqid, {'blockids[]': [this.question.blockid]}).subscribe((res: ResponseModel) => {
      this.moderationHelperService.setQuotes(res.getData());
    }); // Refresh quotes
    this.q1 = this.moderationHelperService.refreshQuotes.subscribe((refresh: boolean) => {
      if(refresh) {
        this.analysisService.getQuotesBy(this.question.country_uniqid, {'blockids[]': [this.question.blockid]}).subscribe((res: ResponseModel) => {
          this.moderationHelperService.setQuotes(res.getData());
        });
      }
    });
    this.moderationHelperService.setQuotes(null);  // todo di troppo?
    // Get sample tags
    this.countryService.getCountrySetupInfo(this.question.country_uniqid).subscribe((res: ResponseModel) => {
      // remove tags contained in room.sample_variable_tags
      // this.sampleVariableTags = res.getData().sample_variable_tags.filter( ( el ) => !this.room.sample_variable_tags.includes( el ));
      this.sampleVariableTags = res.getData().sample_variable_tags;
      // Preselect readonly tags based on current room
      if(this.room.sample_variable_tags && this.room.sample_variable_tags.length) {
        this.room.sample_variable_tags.forEach(tag => {
          this.selectSampleVariableTag(tag, false);
        })
      }
      this.countryService.updatePersonasTags(this.question.country_uniqid).then(() => {
        this.countryService.currentPersonasTags.subscribe((personasTags) => {
          this.personasTags = personasTags

          // controllo se avevo precedentemente salvato la selezione dei tag, in quel caso li ricarico
          const storedSampleVariableTags = this.storageService.get('moderation_filter_sample_variable_tags_' + this.question.country_uniqid);
          const storedPersonasTags = this.storageService.get('moderation_filter_personas_tags_' + this.question.country_uniqid);


          if (storedSampleVariableTags || storedPersonasTags) {
            this.selectedSampleVariableTags = this.sampleVariableTags.filter(tag => storedSampleVariableTags.includes(tag))
            this.selectedPersonasTags = this.personasTags.filter(tag => storedPersonasTags.includes(tag))

            this.applyFilters()
          }
        });

      })
    });

    this.sampleVariableTagsQuestion = this.question.sample_variable_tags;
    //  Set activeQuotes
    this.activeQuotes = this.cookiesService.getCookie('activequotes') ? true : false;
    this.answersModalSupportService.activeQuotes$.next(this.activeQuotes);
    this.get();
  }

  getFilterCount() {
    // 1666 il sample variable tag della room non conta
    const count = this.selectedSampleVariableTags.length + this.selectedPersonasTags.length - (this.room.sample_variable_tags || []).length;
    return count
  }

  resetFilters() {
    this.storageService.forget('moderation_filter_sample_variable_tags_' + this.question.country_uniqid);
    this.storageService.forget('moderation_filter_personas_tags_' + this.question.country_uniqid);
    this.selectedSampleVariableTags = angular.copy(this.room.sample_variable_tags) || [];
    this.selectedPersonasTags = [];
    this.applyFilters();
  }

  storeFilters() {
    this.storageService.set('moderation_filter_sample_variable_tags_' + this.question.country_uniqid, this.selectedSampleVariableTags);
    this.storageService.set('moderation_filter_personas_tags_' + this.question.country_uniqid, this.selectedPersonasTags);
    this.applyFilters();
    document.getElementById('variablesFilterShowToggle').click();
  }

  applyFilters() {
    this.filterByTags();
  }

  get(withTags = false) {
    const query = {questionid: this.question.id, flag_like: false, include_comment: true, include_quote: true};
    if (withTags) {
      // const tagsEncoded = angular.copy(this.selectedSampleVariableTags).map(t => encodeURIComponent(t));
      query['team_sample_variable_tags_by_comma_in_and'] = this.selectedSampleVariableTags.join(',');
      query['team_personas_tags_by_comma'] = this.selectedPersonasTags.join(',');
    }
    this.answer = [];
    this.loading = true;
    // Get answer
    this.answersService.getAnswers(query).subscribe(res => {
      this.allAnswers = res.getData();
      this.answer = this.allAnswers;
      // Reverse
      if(this.question.minimum_multiple_answers_nr && this.question.minimum_multiple_answers_nr > 0) {
        this.answer.reverse();
      }
      if (['vocal-answer', 'open-answer', 'video-answer', 'pin-on-media',
          'image-answer', 'closed-single', 'closed-multiple', 'image-association', 'sentence-completion'].indexOf(this.question.type) !== -1) {
        this.hasQuotes = true;
      }
      // HasVideos?
      if (this.question.type === 'open-answer' || this.question.type === 'video-answer') {
        const withVideo = this.allAnswers.find((answer) => {
          return answer.answer_data.videos.length;
        });
        if (withVideo) {
          this.hasVideos = true;
        }
      }
      if (this.question.type === 'vocal-answer') {
        this.hasVocals = true;
      }

      this.loading = false;
      // Serve the notification
      setTimeout(() => {
        this.serveNotification();
      }, 500);
    });
  }

  private serveNotification() {
    const notice = this.notificationService.currentNotice;
    if (notice) {
      setTimeout(() => {
        if(this.coreLayoutService.sidebarIsOpen()) {
          document.getElementById('answer-block-' + notice.answerid).scrollIntoView();
        }
      }, 500);
      if (!notice.commentid /** && */) {
        this.notificationService.currentNotice = null;
      }
    }
  }

  selectSampleVariableTag(tag: string, filter = true) {
    if (!this.selectedSampleVariableTags.includes(tag)) {
      this.selectedSampleVariableTags.push(tag);
    } else {
      this.selectedSampleVariableTags.splice(this.selectedSampleVariableTags.indexOf(tag), 1);
    }
    if(filter) {
      this.filterByTags();
    }
  }

  selectPersonasTag(tag, filter = true) {
    if (!this.selectedPersonasTags.includes(tag)) {
      this.selectedPersonasTags.push(tag);
    } else {
      this.selectedPersonasTags.splice(this.selectedPersonasTags.indexOf(tag), 1);
    }
    if(filter) {
      this.filterByTags();
    }
  }

  filterByTags() {
    this.get(true);
  }

  findInVideosChange(event) {
    this.answer = this.allAnswers.filter((answer) => {
      let text = null;
      for (let i = answer.answer_data.videos.length - 1; i >= 0; i--) {
        text = answer.answer_data.videos[i].video_data.text.toLowerCase();
        if (text.includes(event.target.value.toLowerCase())) {
          return true;
        }
      }
    });
  }

  findInVocalsChange(event) {
    this.answer = this.allAnswers.filter((answer) => {
      const text = answer.answer_data.vocal_data.text.toLowerCase();
      if (text.includes(event.target.value.toLowerCase())) {
        return true;
      }
    });
  }

  activeQuotesSwitch(status: boolean) {
    this.cookiesService.setCookie('activequotes', status, 1);
    this.answersModalSupportService.activeQuotes$.next(status);
  }

  submit() {
  }

  ngOnDestroy() {
    this.moderationHelperService.setQuotes(null);
    if(this.q1) {
      this.q1.unsubscribe();
    }
  }

}
