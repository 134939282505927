import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ModalService } from '@app/core/services/modal.service';
import { ResearchService } from '@app/shared/services/research.service';
import { ResponseModel } from '@app/core/models/response.model';
import { PageModel } from '@app/core/models/page';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { ResearchModel } from '@app/shared/models/research.model';
import { CountriesService } from '@app/core/services/countries.service';
import { NewResearchModalComponent } from '../../modals/new-research/new-research.component';
import { TeamService } from '@app/shared/services/team.service';
import { CountryService } from '@app/shared/services/country.service';
import { Router } from '@angular/router';
import { ManageResearchesService } from '@app/shared/services/support/manage-researches.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '@app/shared/services/user.service';
import { AuthService } from '@app/core/services/auth.service';

@Component({
  selector: 'app-private-dash-researches',
  templateUrl: './researches.component.html',
  styleUrls: ['./researches.component.scss']
})
export class ResearchesComponent extends CoreBaseComponent implements OnInit, OnDestroy {

  public researches: ResearchModel[] = null;
  demoDisabled = false;
  currentUserIsAdmin = false;
  public isSpecialCustomer = false;

  constructor(
    private modalService: ModalService,
    private researchesService: ResearchService,
    private manageResearchesService: ManageResearchesService,
    private toastService: ToastrService,
    private translate: TranslateService,
    private userService: UserService,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit() {
    this.isSpecialCustomer = this.authService.user.otherdata.first_role === 'CUSTOMER_BASIC'
          || this.authService.user.otherdata.first_role === 'CUSTOMER_PRO';
    this.loadResearches();
    this.userService.getCurrentUser().subscribe((res: ResponseModel) => {
      const user = res.getData();
      if (user.id == '1') {
        this.currentUserIsAdmin = true;
      }
    });
  }

  // controllo se utente è customer ed è stato impostato un country_uniqid tramite link ricevuto via email, in quel caso faccio redirect


  loadResearches = ()  => {
    this.operation('ongoing-researches').reset().isLoading();
    this.researchesService.getOngoingResearches({
        page_index: 0, sort_attribute: 'creationtime', sort_order: 'desc',
        page_size: 10, filter: {include_countries: 1, exclude_statuses: ['REJECTED', 'COMPLETED', 'ARCHIVED'].join(',')}}).subscribe((result: PageModel) => {
      this.researches = result.getData();
      // console.log(this.researches);
      if (!this.isSpecialCustomer) {
        this.researches.push(new ResearchModel()); // trick to print the NEW RESEARCH card
      }

      const demoResearch = this.researches.find(r => r.is_demo);
      if (demoResearch) {
        this.demoDisabled = true;
      }

      this.operation('ongoing-researches').isSuccess(result.getErrors());
    }, (error) => {
      this.operation('ongoing-researches').isFailed(error.errors);
    });
  }

  ngOnDestroy() {
  }

  newResearchModal(e) {
    e.stopPropagation();
    this.modalService.open({modalModel: NewResearchModalComponent, dialogSize: 'custom'}, {
      addedCallback: () => {
        location.reload();
      }
    });
  }

  newDemo() {
    if (!this.demoDisabled) {
      this.demoDisabled = true;
      this.toastService.info(this.translate.instant('WAIT_PLEASE'));
      this.manageResearchesService.getNewDemo().subscribe((res: ResponseModel) => {
        this.loadResearches();
      });
    }
  }

}
