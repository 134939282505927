import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { ResponseModel } from '@app/core/models/response.model';
import { StripHtmlPipe } from '@app/core/pipes/striphtml.pipe';
import { AuthService } from '@app/core/services/auth.service';
import { FaqModel } from '@app/shared/models/faq.model';
import { User } from '@app/shared/models/user.model';
import { HelpService } from '@app/shared/services/help.service';
import { LanguagesService } from '@app/shared/services/languages.service';
import { environment } from '@environments/environment';
import * as angular from 'angular';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent extends CoreBaseComponent implements OnInit {

  originalFaqs: FaqModel[];
  allTutorials: FaqModel[];
  faqs: FaqModel[];
  tutorials: FaqModel[];
  currentUser: User;
  public allLang: string[];
  currentLang: string;
  currentId: string;
  category: string;
  expandAll = false;

  constructor(
    private helpService: HelpService,
    private authService: AuthService,
    private languageService: LanguagesService,
    private domSanitizer: DomSanitizer,
    private stripHtml: StripHtmlPipe
  ) {
    super();
  }

  ngOnInit() {
    this.authService.current().subscribe((userRes: ResponseModel) => {
      this.currentUser = userRes.getData();
      this.helpService.getFaq().subscribe((res: ResponseModel) => {
        this.faqs = res.getData().faq;
        this.tutorials = res.getData().tutorials;
        this.sanitizeUrl();
        this.originalFaqs = angular.copy(this.faqs);
        this.allTutorials = angular.copy(this.tutorials);
        this.filterByLang();
      });
    });

    this.allLang = this.languageService.fullySupportedLanguages;
  }

  search(e) {
    const search = e.target.value;
    this.faqs = angular.copy(this.originalFaqs);

    if (!search) {
      this.expandAll = false;
      return
    }

    const found = [];
    for (const faq of this.faqs) {
      if (
        this.stripHtml.transform(faq.question?.toLowerCase()).includes(search.toLowerCase()) ||
        this.stripHtml.transform(faq.answer?.changingThisBreaksApplicationSecurity?.toLowerCase()).includes(search.toLowerCase())
      ) {
        found.push(faq);
      }
    }
    if (found.length > 0) {
      this.faqs = found;
      this.expandAll = true;
    } else {
      this.resetSearch();
    }
  }

  resetSearch() {
    this.faqs = angular.copy(this.originalFaqs);
    this.expandAll = false;
  }

  filterByLang() {
    if (!this.currentLang) {
      this.currentLang = this.currentUser.language_code || 'it';
    }
    const faqTemp = this.originalFaqs.filter(f => f.language === this.currentLang);
    const tutorialsTemp = this.allTutorials.filter(f => f.language === this.currentLang);
    this.faqs = faqTemp;
    this.tutorials = tutorialsTemp;
  }

  changeLang(e) {
    this.currentLang = e;
    this.filterByLang();
  }

  changeSelected(id) {
    this.currentId = id;
  }

  setCategory(category) {
    this.category = category;
  }

  sanitizeUrl() {
    const srcRegex = new RegExp('src\s*=\s*"(.+?)"');

    for (const faq of this.faqs) {
      faq.answer = this.replaceAll(faq.answer, '/systemFiles', environment.websiteUrl + '/systemFiles');
      faq.answer = this.domSanitizer.bypassSecurityTrustHtml(faq.answer);
      faq.vimeo_embed = this.domSanitizer.bypassSecurityTrustHtml(faq.vimeo_embed);
    }

    for (const tut of this.tutorials) {
      tut.vimeo_embed = this.domSanitizer.bypassSecurityTrustHtml(tut.vimeo_embed);
    }
  }

}
