<section>
    <form [formGroup]="form">
        <div class="container-fluid">

            <app-setup-questions-shared-media-upload
                [question]="question"
                [images]="attachedImages"
                [pdfFiles]="attachedPdfFiles"
                (addImageOut)="addImage($event)"
                (removeImageOut)="removeImage($event)"
                (addPdfOut)="addPdf($event)"
                (removePdfOut)="removePdf($event)">
            </app-setup-questions-shared-media-upload>

            <h4 class="mb-2">{{ 'QUESTION' | translate }}</h4>
            <div class="question-text panel shadow">
                <app-setup-questions-text [form]="form" [countryUniqId]="countryUniqId"></app-setup-questions-text>
            </div>

            <div class="row mb-3">
                <div class="col-12">
                    <h4>{{ 'IMAGES' | translate }}</h4>
                    <ng-container *ngIf="imageSetDisabled; else imageSetForm">
                        <p>{{ 'CHOOSE_DIMENSIONS_BEFORE_IMAGE_SET' | translate }}</p>
                    </ng-container>
                    <ng-template #imageSetForm>
                        <div *ngIf="!form.controls.image_set_selection.disabled">
                            <div class="form-group tool tags-select-tool">
                                <div class="question-filters">
                                    <app-core-select
                                        [classes]="'form-control'"
                                        [multiple]="false"
                                        [label]="'title'"
                                        [form]="form"
                                        [customList]="availImageSetsForDim"
                                        [formEntityName]="'image_set_selection'"
                                        [resetButton]="true"
                                        (change)="onImageSetSelectionChange($event)">
                                    </app-core-select>
                                    <span class="placeholder">{{ 'CHOOSE_IMAGE_SET' | translate }}</span>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>

                <div class="col-6">
                    <h4>{{ 'IMAGE_DIMENSIONS' | translate }}</h4>
                    <div class="form-group" formGroupName="question_data">
                        <div>
                            <input formControlName="dimensions" [value]="1" type="radio"  name="dimensions" id="1x1">
                            <label for="1x1"><span><b>1x1</b></span> <i class="fas fa-square fa-2x pl-2"></i><br>({{ 'BIG_IMAGES' | translate }})</label>

                        </div>
                        <div>
                            <input formControlName="dimensions" [value]="2" type="radio"  name="dimensions" id="1x1">
                            <label for="1x1"><span><b>2x1</b></span> <i class="fas fa-th-large fa-2x pl-2"></i><br>({{ 'MEDIUM_IMAGES' | translate }})</label>

                        </div>
                        <div>
                            <input formControlName="dimensions" [value]="3" type="radio"  name="dimensions" id="1x1">
                            <label for="1x1"><span><b>3x1</b></span> <i class="fas fa-th fa-2x pl-2"></i><br>({{ 'SMALL_IMAGES' | translate }})</label>

                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div>
                        <h4>{{ 'OR_ADD_NEW_IMAGE_SET' | translate }}</h4>
                        <div *ngIf="form.controls.image_set_selection.value">
                            <div class="d-flex">
                                <a *ngIf="form.controls.image_set_selection.value.instructions_pdf_url"
                                    class="pdf-holder mr-3"
                                    [href]="form.controls.image_set_selection.value.instructions_pdf_url"
                                    target="_blank"
                                    download="instructions.pdf">
                                    <div class="d-flex flex-column justify-content-center align-items-center">
                                        <div><i class="far fa-file-pdf"></i></div>
                                        <div>{{ 'IMAGE_SET_INSTRUCTIONS' | translate}}</div>
                                    </div>
                                </a>
                                <div>
                                    <h5>{{ form.controls.image_set_selection.value.title }}</h5>
                                    <span>{{ form.controls.image_set_selection.value.subtitle }}</span>
                                </div>
                            </div>
                        </div>
                        <app-setup-questions-media-upload
                            [form]="form"
                            [formArray]="images"
                            [forceImgSquareView]="true"
                            (inputError)="onUploadError($event)">
                        </app-setup-questions-media-upload>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="form-group align-items-start" formGroupName="question_data">
                        <label for="mandatory_comment">
                            <input formControlName="mandatory_comment" type="checkbox" id="mandatory_comment">
                            {{ 'ADD_COMMENT_FIELD_TO_OPTION' | translate }}
                        </label>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group align-items-start" formGroupName="question_data">
                        <label for="randomize-order">
                            <input formControlName="randomize_order" type="checkbox" id="randomize-order">
                            {{ 'RANDOMIZE_ITEMS_ORDER' | translate }}
                        </label>
                    </div>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-6">
                    <div class="form-group required" formGroupName="question_data">
                        <input formControlName="n_required_obj" type="number" class="form-control">
                        <span class="placeholder">{{ 'N_REQUIRED_OBJECTS' | translate }}</span>
                    </div>
                </div>
            </div> -->


            <app-setup-questions-selects [question]="question" [block]="block" [form]="form" [countryUniqId]="countryUniqId"></app-setup-questions-selects>
            <div class="mb-3 mt-3" *ngIf="block.is_room_group">
                <input formControlName="is_masked" type="checkbox" id="check2">
                <label for="check2">
                    {{ 'MASQUERADE_QUESTION' | translate }}
                </label>
            </div>
        </div>
    </form>
</section>