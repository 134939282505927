import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'hoursToDays'
})
export class HoursToDaysPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {}

  convert = [
    {
      initial_value: 2,
      text: 'MESSAGES_SECTION.HOURS',
      new_value: 2
    },
    {
      initial_value: 120,
      text: 'MESSAGES_SECTION.DAYS',
      new_value: 5
    },
    {
      initial_value: 240,
      text: 'MESSAGES_SECTION.DAYS',
      new_value: 10
    }
  ];

  transform(value: number, ...args: any[]): number {
    return Math.round(value);
  }

  getList() {
    const temp = this.convert;
    for (const foo of temp) {
      foo.text = `${foo.new_value} ${this.translateService.instant(foo.text)}`;
    }
    return temp;
  }

  getByInitialValue(initial_value) {
    const value = this.convert.filter(c => c.initial_value === initial_value);
    return {value};
  }
}
