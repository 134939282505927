<div *ngIf="!operation('signing-up').success">
  <h2 class="light">{{ 'SIGNUP_BEFORE_USE' | translate }}</h2>
  <form *ngIf="form" [formGroup]="form">
    <div class="row">
      <div class="col-md-6">
        <div [class.is-invalid]="form.get('firstname').invalid && form.get('firstname').touched">
          <div class="form-group required">
            <label>
              <input required formControlName="firstname" type="text" class="form-control" id="firstname">
              <span class="placeholder">{{ 'NAME' | translate }}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div [class.is-invalid]="form.get('lastname').invalid && form.get('lastname').touched">
          <div class="form-group required">
            <label>
              <input required formControlName="lastname" type="text" class="form-control" id="lastname">
              <span class="placeholder">{{ 'SURNAME' | translate }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div [class.is-invalid]="form.get('company_declared').invalid && form.get('company_declared').touched">
          <small class="field-note text-soft">{{ 'COMPANY_DECLARED_INFO' | translate }}</small>
          <div class="form-group">
            <label>
              <input formControlName="company_declared" type="text" class="form-control" id="company_declared">
              <span class="placeholder">{{ 'COMPANY_DECLARED' | translate }}</span>
            </label>
            <div class="text-danger" *ngIf="operation('signing-up').errors?.company_declared">
              <small>{{ ('SERVER_ERRORS.' + operation('signing-up').errors.company_declared) | translate }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div [class.is-invalid]="form.get('email').invalid && form.get('email').touched">
          <div class="form-group required">
            <label>
              <input required formControlName="email" type="text" class="form-control" id="email">
              <span class="placeholder">{{ 'EMAIL' | translate }}</span>
            </label>
          </div>
          <div class="text-danger" *ngIf="form.get('email').invalid && form.get('email').touched">
            <small>{{ 'ERRORS.INVALID_EMAIL' | translate }}</small>
          </div>
          <div class="text-danger" *ngIf="operation('signing-up').errors?.email">
            <small>{{ ('SERVER_ERRORS.' + operation('signing-up').errors.email) | translate }}</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div [class.is-invalid]="form.get('password').invalid && form.get('password').touched">
          <small class="field-note text-soft">{{ 'PASSWORD_RULES' | translate:({param1: passwordMinLength, dumbTrigger: 'it'} | ParamsTranslate) }}</small>
          <div class="form-group required">
            <label>
              <input required formControlName="password" type="password" class="form-control" id="empasswordail">
              <span class="placeholder">{{ 'PASSWORD' | translate }}</span>
            </label>
          </div>
          <div class="text-danger" *ngIf="form.get('password').invalid && form.get('password').touched">
            <small *ngIf="passwordMinLength">{{ 'PASSWORD_RULES_WARNING' | translate:({param1: passwordMinLength, dumbTrigger: 'it'} | ParamsTranslate) }}</small>
          </div>
        </div>
      </div>
    </div>
    <div class="check-radio-box" [class.is-invalid]="form.get('consentA').invalid && form.get('consentA').dirty">
      <input formControlName="consentA" type="checkbox" id="consent-a">
      <small style="width: 78%;margin-left: 4px;">
        <span (click)="form.patchValue({consentA: !form.value.consentA})"><small>{{ 'I_DECLARE_PERSONA_DATA' | translate }}</small></span>&nbsp;</small>
    </div>
    <div class="w-100 mb-3" style="margin-left: 35px;">
      <small><a href="assets/pdf/privacy_{{currentLang}}.pdf" target="_blank">{{ 'I_DECLARE_PERSONA_DATA_2' | translate }}</a></small>
    </div>

    <div class="check-radio-box mt-2" [class.is-invalid]="form.get('consentB').invalid && form.get('consentB').dirty">
      <input formControlName="consentB" type="checkbox" id="consent-b">
      <label for="consent-b" style="width: 95%;">
        <small>{{ 'I_GIVE_MY_CONSENT' | translate }}</small>
      </label>
    </div>
    <div *ngIf="operation('signing-up').errors" class="alert alert-danger">
      {{ 'ERRORS.HTTP_GENERIC_ERRORS' | translate }}
    </div>
    <div class="text-center">
      <div class="text-center w-100">
        <app-core-button-submit
          class="w-100 mt-5 d-block"
          [classes]="'btn-sicche active mx-auto'"
          [spinnerDimension]="'small'"
          [form]="form"
          (submitCallback)="submit()"
          [toggle]="operation('signing-up').submitting">
          <span><i class="fal fa-user"></i>&nbsp;{{ 'CREATE_ACCOUNT' | translate }}</span>
      </app-core-button-submit>
    </div>
    </div>
  </form>
</div>
<div *ngIf="operation('signing-up').success" class="text-center mt-4">
  <svg width="100px" height="100px" class="mx-auto mb-5" viewBox="0 0 42 58" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 63.1 (92452) - https://sketch.com -->
    <g id="molecule-/-sicchemoji-/-happy" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group" transform="translate(-9.000000, -1.000000)" fill="#828282" fill-rule="nonzero">
            <path d="M23.9546255,25.7453978 C23.1754382,27.0056485 22.247542,27.7309454 21.8438798,28.018917 C21.4536827,28.3444394 20.4502568,28.9067759 19.9515679,29.1839856 C19.4766196,29.4995547 18.9209914,29.705359 18.3779263,29.9557223 C17.8113072,30.13884 17.2393553,30.3517528 16.6458399,30.4545169 C16.0580392,30.6010092 15.4588938,30.6469149 14.8707507,30.7137564 C12.5068843,30.872658 10.2887609,30.4852967 8.66937035,30.1159298 C7.04912866,29.7456932 6,29.4251414 6,29.4251414 C6,29.4251414 7.10234841,29.4643739 8.7373485,29.4064895 C9.55479768,29.3854253 10.5028811,29.3004907 11.497495,29.1741145 C12.4897083,29.0214946 13.5260401,28.8029402 14.4996839,28.4745042 C14.9763886,28.284968 15.4572076,28.1216643 15.8854049,27.8816752 C16.3330477,27.681824 16.7169761,27.3983571 17.0980178,27.1639262 C17.440901,26.8684715 17.8839952,26.6014958 18.1472959,26.2916777 L9.9767251,20.985647 C8.34757938,19.8714569 7.69951117,17.6183409 8.78070828,15.8913407 L14.7650664,6.619192 C15.8462635,4.89219176 18.317957,4.51928847 19.9471083,5.63260316 C21.0332092,6.37481295 23.9790613,8.28787165 28.7846645,11.3717793 C30.414667,12.4859637 31.0618785,14.7390853 29.9806813,16.4660856 L23.9546255,25.7453978 Z" id="Path" transform="translate(18.256714, 17.875195) scale(-1, -1) rotate(327.000000) translate(-18.256714, -17.875195) "></path>
            <path d="M46.9546255,25.7453978 C46.1754382,27.0056485 45.247542,27.7309454 44.8438798,28.018917 C44.4536827,28.3444394 43.4502568,28.9067759 42.9515679,29.1839856 C42.4766196,29.4995547 41.9209914,29.705359 41.3779263,29.9557223 C40.8113072,30.13884 40.2393553,30.3517528 39.6458399,30.4545169 C39.0580392,30.6010092 38.4588938,30.6469149 37.8707507,30.7137564 C35.5068843,30.872658 33.2887609,30.4852967 31.6693704,30.1159298 C30.0491287,29.7456932 29,29.4251414 29,29.4251414 C29,29.4251414 30.1023484,29.4643739 31.7373485,29.4064895 C32.5547977,29.3854253 33.5028811,29.3004907 34.497495,29.1741145 C35.4897083,29.0214946 36.5260401,28.8029402 37.4996839,28.4745042 C37.9763886,28.284968 38.4572076,28.1216643 38.8854049,27.8816752 C39.3330477,27.681824 39.7169761,27.3983571 40.0980178,27.1639262 C40.440901,26.8684715 40.8839952,26.6014958 41.1472959,26.2916777 L32.9767251,20.985647 C31.3475794,19.8714569 30.6995112,17.6183409 31.7807083,15.8913407 L37.7650664,6.619192 C38.8462635,4.89219176 41.317957,4.51928847 42.9471083,5.63260316 C44.0332092,6.37481295 46.9790613,8.28787165 51.7846645,11.3717793 C53.414667,12.4859637 54.0618785,14.7390853 52.9806813,16.4660856 L46.9546255,25.7453978 Z" id="Path" transform="translate(41.256714, 17.875195) scale(-1, -1) rotate(327.000000) translate(-41.256714, -17.875195) "></path>
        </g>
        <path d="M16.4715026,61 C20.3678756,61 23.4352332,59.8853503 25.6735751,57.656051 C27.8911917,55.3842887 29,52.2632696 29,48.2929936 C29,46.5095541 28.6994819,44.8004246 28.0984456,43.1656051 C27.4974093,41.5307856 26.626943,40.1295117 25.4870466,38.9617834 C25.0103627,38.4734607 24.5025907,38.0541401 23.9637306,37.7038217 C23.4248705,37.3535032 22.8549223,37.0615711 22.253886,36.8280255 C20.8031088,36.2760085 19,36 16.8445596,36 L16.8445596,36 L11,36 L11,61 L16.4715026,61 Z M17.4663212,58.8025478 L13.4559585,58.8025478 L13.4559585,38.1974522 L15.880829,38.1974522 C17.8290155,38.1974522 19.3419689,38.3673036 20.4196891,38.7070064 C21.5388601,39.0679406 22.5233161,39.6624204 23.373057,40.4904459 C24.5336788,41.6369427 25.3419689,43.0169851 25.7979275,44.6305732 C25.9430052,45.1825902 26.0518135,45.7558386 26.1243523,46.3503185 C26.1968912,46.9447983 26.2331606,47.581741 26.2331606,48.2611465 C26.2331606,51.7006369 25.2901554,54.3864119 23.4041451,56.3184713 C21.7875648,57.9745223 19.8082902,58.8025478 17.4663212,58.8025478 L17.4663212,58.8025478 Z" id="D" fill="#828282" fill-rule="nonzero" transform="translate(20.000000, 48.500000) rotate(90.000000) translate(-20.000000, -48.500000) "></path>
    </g>
  </svg>
  <h2 class="light">{{ 'YOUR_ACCOUNT_CREATED' | translate }}!</h2>
  <h3>{{ 'YOUR_ACCOUNT_CREATED_MSG' | translate }}</h3>
</div>