<app-shared-sidebar-container *ngIf="room" >
    <app-shared-sidebar-header>
        <div slot="title">
            <h2 class="mb-0">{{ 'ROOM.GENERAL_INFO' | translate }}</h2>
        </div>
        <div slot="buttons" class="text-right">
            <button class="btn-sicche" (click)="save()">{{ 'SAVE' | translate }}</button>
        </div>
    </app-shared-sidebar-header>   

    <div class="sidebar-body">
        <div class="container-fluid">
            <div class="row">
                <div class="col">
                    <p [innerHTML]="'ROOM.FIRST_MESSAGE_HEADER' | translate"></p>
                </div>
            </div>
            <div class="row">
                <div class="col panel shadow">
                    <quill-editor [(ngModel)]="room.instructions_html" [modules]="modules" [styles]="{height: '400px'}" [placeholder]="'INSERT-TEXT' | translate"></quill-editor>
                </div>
            </div>
        </div>
    </div>
</app-shared-sidebar-container>