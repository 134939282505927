<div class="position-relative">
    <footer class="container-fluid">
        <div class="row bg-white">
            <div class="col-md-9">
                <!-- <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur lorem, ac lobortis nulla pellentesque tristique | privacy policy | cookie policy</p> -->
                <p class="mb-0">© 2020 Sicché Smart Insights srl | all rights reserved</p>
                <p class="mb-0" *ngIf="version">Version {{version}}</p>
            </div>
            <div class="col-md-3 va text-right logo">
                <a target="_blank" href="https://www.sicche.com/">
                    <img src="./assets/images/sicche-logo.svg">
                </a>
            </div>
        </div>
    </footer>
    <div class="internal-quotes-bottom quotes-up">
        <span class="custom-icon text-primary-color icon-quotes-left-hollow" style="font-size: 16.286rem;"></span>
    </div>
</div>