
/**
 * Page Model
 */
export class PageModel {

  list: any[];
  totalCount: number;
  errors: any;
  totalFound: number;
  pageSize: number;
  pageIndex: number;

  get totalPages() {
    return Math.round(this.totalFound / this.pageSize);
  }

  constructor(list?: any[], totalCount?: number, errors?: any, totalFound?: number, pageIndex?: number, pageSize?: number) {
    this.list = list ? list : [];
    this.totalCount = totalCount ? totalCount : 10000;
    this.totalFound = totalFound;
    this.pageSize = pageSize;
    this.pageIndex = pageIndex;
    this.errors = errors;
  }

  getList() {
    return this.list;
  }

  getTotalFound(): number {
    return this.totalFound;
  }

  getData() {
    return this.list;
  }

  getErrors() {
    return this.errors;
  }

}
