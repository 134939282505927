import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SortParams } from '../../models/sort-params';
import { SortOptions } from '../../models/sort-options';

@Component({
  selector: 'app-core-sortby',
  templateUrl: './sortby.component.html',
  styleUrls: ['./sortby.component.scss']
})
export class SortbyComponent implements OnInit {

    @Input()
    list: any[];
    @Input()
    options: SortOptions[];
    @Input()
    selectedIndex: number = null;
    @Input()
    selectedDirection: string;
    @Output()
    listChange = new EventEmitter<Array<any>>();
    @Input()
    sortByFunc: (compTerm: string) => void;
    @Input()
    sortOrderFunc: (direction: string) => void;

    public sort: SortParams = {by: null, direction: null};

    constructor() {
    }

    ngOnInit() {
        if (this.selectedIndex != null) {
            this.sort.by = this.options[this.selectedIndex];
        }
        if (this.selectedDirection != null) {
            this.sort.direction = this.selectedDirection;
        }
    }

    defautlSortOrder = (direction: string) => {
        this.sort.direction = direction;
        return this.sortBy();
    }

    sortOrder(direction: string) {
        if (this.sortOrderFunc) {
            this.sort.direction = direction;
            if (this.sort.by.compTerm) {
                this.sortOrderFunc(direction);
            }
        } else {
            this.defautlSortOrder(direction);
        }
    }

    defaultSortBy = () => {
        this.list.sort((a, b) =>
            this.sort.direction === 'ASC' ?
                    (
                        typeof this.sort.by.compTerm === 'string'
                        ?
                            (a[this.sort.by.compTerm] > b[this.sort.by.compTerm])
                            ?
                                1
                            :
                                ((b[this.sort.by.compTerm] > a[this.sort.by.compTerm]) ? -1 : 0)
                        :
                            (this.sort.by.compTerm(a) > this.sort.by.compTerm(b))
                            ?
                                1
                            :
                                ((this.sort.by.compTerm(b) > this.sort.by.compTerm(a)) ? -1 : 0)
                    )
                :
                (
                    typeof this.sort.by.compTerm === 'string'
                    ?
                        (a[this.sort.by.compTerm] < b[this.sort.by.compTerm]) ?
                                                1 : ((b[this.sort.by.compTerm] < a[this.sort.by.compTerm]) ? -1 : 0)
                    :
                        (this.sort.by.compTerm(a) < this.sort.by.compTerm(b)) ?
                                                1 : ((this.sort.by.compTerm(b) < this.sort.by.compTerm(a)) ? -1 : 0)
                )
            );
        this.listChange.emit(this.list);
    }

    sortBy(selection?) {
        if (this.sortByFunc) {
            this.sort.direction = this.sort.direction ? this.sort.direction : 'ASC';
            this.sortByFunc(selection);
        } else {
            this.defaultSortBy();
        }
    }

}
