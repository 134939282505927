import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { UiService } from './shared/services/ui.service';
import { LanguagesService } from './shared/services/languages.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  smallDeviceAlert = false;

  constructor(private router: Router, private uiService: UiService, private languagesService: LanguagesService, private activatedRoute: ActivatedRoute) {
    // se torno a dashboard rimetto style default
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (['/dashboard', '/researches'].includes(event.url)) {
          this.uiService.initToolbar();
          this.uiService.initToolbar();
          document.documentElement.style.setProperty('--orange', '#FF5100');
        }
      }
    });
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      const forceLanguage = params.forceLanguage;
      if (forceLanguage) {
        this.languagesService.changeLanguage(forceLanguage);
      } else {
        const userLang = navigator.language;
        this.languagesService.changeLanguage(userLang.substring(0, 2));
      }
    })

    if (Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) < 1024) {
      this.smallDeviceAlert = true
    }


    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') != -1) {
      if (ua.indexOf('chrome') > -1) {
        document.body.classList.add("is-chrome")
      } else {
        document.body.classList.add("is-safari")
      }
    }

  }

  ngOnDestroy() {
  }
}


