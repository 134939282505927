<section class="container-fluid p-5 modal-small">
    <div class="row">
        <div class="col-12 my-2" *ngIf="user">
            <b>{{'MESSAGES_SECTION.RECIPIENTS' | translate}}:</b> {{user.nickname || user.email}}
        </div>
        <div *ngIf="!emailView">
            <div class="col-12">
                <h6>
                    {{'MISSING_ANSWERS_MODAL_TEXT' | translate}}
                </h6>
            </div>
            <div class="col-12" *ngFor="let item of missingAnswers,  let i = index">
                {{i}} - {{item.block_title || 'UNTITLED'}}: {{'ON_DATE' | translate}} {{item.date | dateToLocaleString}}
            </div>
        </div>

        <div *ngIf="emailView">
            <!-- template messages -->
            <div class="row my-3" >
                <div class="col-12">
                    <label>
                        <input type="checkbox" [(ngModel)]="showLoadFromTemplate">
                        {{'LOAD_FROM_TEMPLATE_MESSAGE' | translate}}
                        <span class="info-tooltip ml-2" placement="bottom"
                            [ngbTooltip]="'TOOLTIPS.TEMPLATE_MESSAGES' | translate">
                            <i class="far fa-info-circle"></i>
                        </span>
                    </label>
                </div>
                <div class="col-12" *ngIf="showLoadFromTemplate">
                    <div class="row">
                        <div class="col-12 ">
                            <div class="form-group">
                                <app-core-select *ngIf="templateMessages" [classes]="'form-control'" [multiple]="false"
                                    [cache]="true" [customList]="templateMessages" [label]="'title'" [searchable]="true"
                                    (change)="setTemplateMessage($event)">
                                </app-core-select>
                                <span class="placeholder">{{'TEMPLATE_MESSAGES' | translate}}</span>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-right" *ngIf="showLoadFromTemplate">
                    <a (click)="showLoadFromTemplate=false" class="clickable mr-2">{{'CANCEL' | translate}}</a>
                    <button class="btn-sicche btn-secondary" [disabled]="!currentTemplateMessage"
                        (click)="loadFromTemplate()">{{'LOAD_FROM_TEMPLATE_MESSAGE' | translate}}</button>
                </div>
            </div>
            <!-- end messaggi template -->
            <div class="col-12">
                <div class="form-group">
                    <input type="text" class="form-control" [(ngModel)]="message.subject">
                    <span class="placeholder">{{'MESSAGES_SECTION.SUBJECT' | translate}}</span>
                </div>
            </div>
            <div class="col-12">
                <label>{{'MESSAGE' | translate}} - {{'BLOCKS_LINKS_WILL_BE_ADDED' | translate}}</label>
                <quill-editor [(ngModel)]="message.msg" [readOnly]="confirm" [modules]="modules"
                    [styles]="{height: '300px'}" [placeholder]="'INSERT-TEXT' | translate"></quill-editor>
            </div>
        </div>

    </div>
    <div class="row">
        <div class="col-12 mt-3 text-right">
            <a class="as-link mr-2" (click)="closeModal()">{{'CANCEL' | translate}}</a>
            <button class="btn btn-sicche" *ngIf="!emailView" (click)="showEmailView()">{{'SEND' | translate}}</button>
            <button class="btn btn-sicche" *ngIf="emailView && !confirm" (click)="sendEmail()">{{'SEND' |
                translate}}</button>
            <button class="btn btn-sicche" *ngIf="emailView && confirm" (click)="confirmSend()">{{'CONFIRM' |
                translate}}</button>
        </div>
    </div>
    <div class="row mt-4" *ngIf="emailView">
        <div class="col-12">
            <label>
                <input type="checkbox" [(ngModel)]="showSaveAsTemplate">
                {{'SAVE_AS_TEMPLATE_MESSAGE' | translate}}
            </label>
        </div>
        <div class="col-12" *ngIf="showSaveAsTemplate" >
            <div class="row" *ngIf="showSaveAsTemplate">
                <div class="col-12 ">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="{{'TEMPLATE_MESSAGE_TITLE' | translate }}" [(ngModel)]="templateTitle">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 text-right" *ngIf="showSaveAsTemplate" >
            <button class="btn-sicche btn-secondary" [disabled]="!templateTitle" (click)="saveAsTemplateMessage()">{{'SAVE_AS_TEMPLATE_MESSAGE' | translate}}</button>
        </div>
    </div>

</section>