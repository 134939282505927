import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { QuestionsTypesService } from '@app/shared/services/questions-types.service';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { RoomsService } from '@app/shared/services/rooms.service';
import 'quill-emoji/dist/quill-emoji.js';
import Quill from 'quill';

@Component({
  selector: 'app-answer-wysiwyg',
  templateUrl: './wysiwyg.component.html',
  styleUrls: ['./wysiwyg.component.scss']
})
export class WysiwygComponent implements OnInit {

  @Input()
  form: UntypedFormGroup;
  @Input()
  formEntityName: string;
  @Output()
  textChange: EventEmitter<any> = new EventEmitter();
  public editor = null;
  public quillModules = {
    // 'emoji-shortname': true,
    'emoji-textarea': true,
    'emoji-toolbar': true,
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [{ font: ['muli'] }],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],
      ['link', 'image', 'video', 'file'],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
      ['clean'],
      ['emoji']
    ]
  };

  @Input()
  set injectText(value) {
    if(typeof value != 'undefined') {
      this.inject(value.text);
    }
  }

  constructor(public questionTypesService: QuestionsTypesService, public roomsService: RoomsService) {
  }

  textChangeEvent() {
    this.textChange.emit();
  }

  onEditorCreated(event) {
    this.editor = event;
  }

  inject(value) {
    if (this.editor && value) {
      const selection = this.editor.getSelection(true);
      this.editor.insertText(selection, value, 'normal', true);
      this.form.controls[this.formEntityName].setValue(this.editor.getText());
    }
  }

  ngOnInit(): void {
    let Font = Quill.import('formats/font');
    // We do not add Sans Serif since it is the default
    Font.whitelist = ['inconsolata', 'roboto', 'mirza', 'arial'];
    Quill.register(Font, true);

    // We can now initialize Quill with something like this:
    let quillObj = new Quill('#quill-js-container', {
      modules: {
        toolbar: '#toolbar-container'
      },
      placeholder: 'This is a font test...',
      theme: 'snow'
    });
    this.form.valueChanges.subscribe(data => {
      this.textChangeEvent();
    })
  }

}
