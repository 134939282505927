import { Component, Input, OnInit } from '@angular/core';
import { CookiesService } from '@app/core/services/cookies.service';
import { AnswersModalSupportService } from '@app/shared/services/support/answers-modal.service';
import { TeamService } from '@app/shared/services/team.service';

@Component({
  selector: 'app-analysis-sidebar-header',
  templateUrl: './analysis-sidebar-header.component.html',
  styleUrls: ['./analysis-sidebar-header.component.scss']
})
export class AnalysisSidebarHeaderComponent implements OnInit {

  @Input() title: String;

  enableQuotes = false;

  constructor(
    public answersModalSupportService: AnswersModalSupportService,
    private cookiesService: CookiesService,
    private teamService: TeamService,
  ) { }

  ngOnInit(): void {
    this.enableQuotes = !this.teamService.isCurrentUserCustomerPro();

  }

  activeQuotesSwitch(status: boolean) {
    this.cookiesService.setCookie('activequotes', status, 1);
    this.answersModalSupportService.activeQuotes$.next(status);
  }

}
