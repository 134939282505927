import {Component, ElementRef, ViewChild, ViewEncapsulation, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router, ActivatedRoute} from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Mode } from '../../../../../../../setup';
import { ToastrService } from 'ngx-toastr';
import { User } from '@shared/models/user.model';
import { CookiesService } from '@app/core/services/cookies.service';
import { CryptService } from '@app/core/services/crypter.service';
import { ModalService } from '@app/core/services/modal.service';
import { ForgottenPasswordModalComponent } from '@app/pages/layers/public/auth/modals/forgotten-password/forgotten-password-modal.component';
import { CountryService } from '@app/shared/services/country.service';
import { TeamService } from '@app/shared/services/team.service';
import { ResponseModel } from '@app/core/models/response.model';
import { ResearchService } from '@app/shared/services/research.service';
import { UiService } from '@app/shared/services/ui.service';

@Component({
  selector: 'app-auth-loginform',
  templateUrl: './auth-loginform.component.html',
  styleUrls: ['./auth-loginform.component.scss']
})
export class AuthLoginFormComponent implements OnInit {

  form: UntypedFormGroup;
  passwordMinLength = Mode.PASSWORD_MIN_LENGTH;
  submitting = false;
  private salt = 'hu82d8Huw20xHD92';
  public error = false;
  constructor(private translate: TranslateService, private formBuilder: UntypedFormBuilder, private router: Router,
              private cookiesService: CookiesService, private route: ActivatedRoute, private authService: AuthService,
              private toastService: ToastrService, private cryptService: CryptService, private modalService: ModalService,
              private countryService: CountryService, private teamService: TeamService, private researchService: ResearchService,
              private uiService: UiService
            ) {
    // this.appService.pageTitle = 'Accedi';
  }

  ngOnInit() {
    let decryptedPassword = '';
    try {
      decryptedPassword = this.cryptService.decrypt(this.salt, this.cookiesService.getCookie('password'));
    } catch (e) { }
    this.form = this.formBuilder.group({
      username: new UntypedFormControl(this.cookiesService.getCookie('username'), [
        Validators.required
      ]),
      password: new UntypedFormControl(decryptedPassword, [
        Validators.required,

      ]),
      rememberme: new UntypedFormControl(this.cookiesService.getCookie('rememberme'))
    });
    this.route.queryParams.subscribe(params => {
      if (params.email) {
        this.form.patchValue({
          username: params.email
        });
      }
    });
  }

  forgottenPasswordModal() {
    this.modalService.open({modalModel: ForgottenPasswordModalComponent, dialogSize: 'lg'}, null);
  }

  redirect() {
    const countryUniqid = this.countryService.customerCountryUniqid;
    const researchUniqid = this.researchService.customerResearchUniqid;
    if (countryUniqid && researchUniqid && countryUniqid !== 'null') {
      this.teamService.getCurrentUserTeam(countryUniqid).subscribe((res: ResponseModel) => {
        const currentUser = res.getData();
        this.teamService.currentUser = currentUser;
        if (this.teamService.isCurrentUserCustomer()) {
          this.router.navigate(['researches', 'navigation', researchUniqid, countryUniqid, 'analysis']);
        } else {
          this.uiService.initToolbar();
          this.countryService.customerCountryUniqid = null;
          this.researchService.customerResearchUniqid = null;
          this.router.navigateByUrl('/dashboard');
        }
      }, err => console.error(err));
    } else {
      this.router.navigateByUrl('/dashboard');
    }
  }

  submit() {
    this.submitting = true;
    this.error = false;
    this.authService.login({
      username: this.form.value.username,
      password: this.form.value.password
    }, User, {isCustomerPro: false}).subscribe(res => {
      this.submitting = false;
      this.toastService.success(this.translate.instant('LOGGED_IN_CORRECTLY'), 'Log in');
      try {
        this.redirect();
      } catch (error) {
        this.router.navigateByUrl('/dashboard');
      }
      // Set cookies
      if (this.form.value.rememberme) {
        this.cookiesService
          .setCookie('username', this.form.value.username, 10)
          .setCookie('password', this.cryptService.encrypt(this.salt, this.form.value.password), 10)
          .setCookie('rememberme', true, 10);
      } else {
        this.cookiesService.forgetCookie(['username', 'password', 'rememberme']);
      }
    }, (e) => {
      this.submitting = false;
      this.error = true;
      this.toastService.error(this.translate.instant('ERRORS.INVALIDUSERNAMEORPASSWORD'), 'Log in');
    });
  }
}
