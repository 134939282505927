import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { QuestionsTypesService } from '@app/shared/services/questions-types.service';

@Component({
  selector: 'app-setup-questions-types-list',
  templateUrl: './questions-types.component.html',
  styleUrls: ['./questions-types.component.scss']
})
export class QuestionsTypesComponent implements OnInit {

  @Input()
  choicesVisible = false;
  @Output()
  selection: EventEmitter<string> = new EventEmitter(null);
  public selected: string;

  constructor(public questionTypesService: QuestionsTypesService) {
  }

  ngOnInit(): void {
  }

  select(typeSlug: string) {
    this.selected = typeSlug;
    this.selection.emit(typeSlug);
  }

}
