<div *ngIf="toggle && style == 'vertical'" class="d-flex flex-column align-items-center justify-content-center">
    <div class="row">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="text-center">
        <div>
            <strong>{{ title | translate }}</strong>
        </div>
        <div>
            <small>{{ description }}</small>
        </div>
    </div>
</div>
<div *ngIf="toggle && style == 'horizontal'" class="d-flex align-items-center">
    <strong>{{ title | translate }}</strong>
    <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
</div>
