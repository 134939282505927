<div *ngIf="data" class="container ">

    <div *ngIf="data.data_type === 'comment'">
        <app-moderation-answer-comment [impersonal]="true" [readonly]="true" [commentAnswer]="comment" [answer]="answer"></app-moderation-answer-comment>
    </div>

    <div *ngIf="data.data_type === 'answer'">
        <div class="my-3" *ngIf="answer" [innerHtml]="answer.question_title"></div>

        <app-analysis-answer-wrapper [answer]="answer" [exportableVersion]="true">

        </app-analysis-answer-wrapper>
    </div>

</div>