import { BaseModel } from '@app/core/models/base';

/**
 * User model class
 */

export class RoomModel extends BaseModel {

    // tslint:disable: variable-name
    id: number;
    title: string;
    date_start: Date;
    date_end: Date;
    is_room_group: boolean;
    instructions_html: string;
    first_message_default_date_send: Date;
    first_message_default_subject: string;
    first_message_default_html: string;
    country_uniqid: string;
    sample_variable_tags: string[];
    update_is_room_group: boolean;

    static getResourceName() {
        return 'rooms';
    }



}
