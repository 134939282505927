import { BaseModel } from '@app/core/models/base';
import { BaseSubTypeModelSchema } from '@app/core/models/base-sub-types-list.interface';
import { AnswerModel } from './answer.model';
import { CommentModel } from './comment.model';


export class QuestionModel extends BaseModel {

    // tslint:disable: variable-name
    date: string;
    category: any;
    text: string;
    image: string;
    link: string;
    id: number;
    title: string;
    // grosso oggetto json con tutti i dati della domanda. cambia in base al type della domanda
    question_data: any;
    country_uniqid: string;
    blockid: number;
    roomid: number;
    sample_variable_tags: string[]; // in creazione
    answer_tags: string[]; // solo in moderazione
    personas_tags: string[]; // in creazione
    type: string;
    position: number;
    disabled: boolean;
    is_masked: boolean;
    answered: boolean;
    creationtime: Date;
    condition_questionid: number;
    condition_questionopt: number;
    nr_answers_unread: number;
    minimum_multiple_answers_nr: number;
    block_datetime_start?: any;
    extension?: any;
    is_room_group?: boolean;
    videos1?: File;
    mandatory_comment: boolean;

    answers?: AnswerModel[];
    answers_data?: AnswerModel[];
    comments?: CommentModel[];

    static getResourceName() {
        return 'question-management';
    }

    static getSubTypesList(): BaseSubTypeModelSchema[] {
        return [
            {object: 'answers', model: AnswerModel},
        ];
      }

    getTitle(html = true) {
        if (html) {
            return this.title;
        } else {
            return this.title.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ');
        }
    }

}
