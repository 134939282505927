<ng-container *ngIf="item">
    <app-moderation-answerstype-open
        *ngIf="item.question_type == 'open-answer'"
        [answer]="item"
        [question]="question"
        [explicitMediaUrls]="explicitMediaUrls"
        [placeholders]="placeholders"
        [message]="message"
        (textSelection)="selectText($event)">
    </app-moderation-answerstype-open>
    <app-moderation-answerstype-closed-single
        *ngIf="item.question_type == 'closed-single'"
        [answer]="item"
        [message]="message"
        [classes]="classes"
        (textSelection)="selectText($event)"
        [question]="question">
    </app-moderation-answerstype-closed-single>
    <app-moderation-answerstype-closed-multiple
        *ngIf="item.question_type == 'closed-multiple'"
        [answer]="item"
        [message]="message"
        (textSelection)="selectText($event)"
        [classes]="classes"
        [question]="question">
    </app-moderation-answerstype-closed-multiple>
    <app-moderation-answerstype-video-answer
        *ngIf="item.question_type == 'video-answer'"
        [answer]="item"
        [classes]="classes"
        [question]="question"
        [explicitMediaUrls]="explicitMediaUrls"
        [placeholders]="placeholders"
        (textSelection)="selectText($event)">
    </app-moderation-answerstype-video-answer>
    <app-moderation-answerstype-vocal-answer
        *ngIf="item.question_type == 'vocal-answer'"
        [answer]="item"
        [classes]="classes"
        [question]="question"
        [explicitMediaUrls]="explicitMediaUrls"
        [placeholders]="placeholders"
        (textSelection)="selectText($event)">
    </app-moderation-answerstype-vocal-answer>
    <app-moderation-answerstype-image-answer
        *ngIf="item.question_type == 'image-answer'"
        [answer]="item"
        [classes]="classes"
        [message]="message"
        [question]="question"
        [explicitMediaUrls]="explicitMediaUrls"
        [placeholders]="placeholders"
        (textSelection)="selectText($event)">
    </app-moderation-answerstype-image-answer>
    <app-moderation-answerstype-sentence-completion
        *ngIf="item.question_type == 'sentence-completion'"
        [answer]="item"
        [classes]="classes"
        [message]="message"
        (textSelection)="selectText($event)"
        [question]="question">
    </app-moderation-answerstype-sentence-completion>
    <app-moderation-answerstype-semantic-differentials
        *ngIf="item.question_type == 'semantic-differentials'"
        [answer]="item"
        [classes]="classes"
        [question]="question">
    </app-moderation-answerstype-semantic-differentials>
    <app-moderation-answerstype-grouping-category
        *ngIf="item.question_type == 'grouping-category'"
        [answer]="item"
        [classes]="classes"
        [question]="question"
        (textSelection)="selectText($event)">
    </app-moderation-answerstype-grouping-category>
    <app-moderation-answerstype-attitude-scale
        *ngIf="item.question_type == 'attitude-scale'"
        [answer]="item"
        [classes]="classes"
        [question]="question">
    </app-moderation-answerstype-attitude-scale>
    <app-moderation-answerstype-preference-ranking
        *ngIf="item.question_type == 'preference-ranking'"
        [answer]="item"
        [classes]="classes"
        [question]="question">
    </app-moderation-answerstype-preference-ranking>
    <app-moderation-answerstype-image-association
        *ngIf="item.question_type == 'image-association'"
        [answer]="item"
        [classes]="classes"
        (textSelection)="selectText($event)"
        [question]="question">
    </app-moderation-answerstype-image-association>
    <app-moderation-answerstype-pin-on-media
        *ngIf="item.question_type == 'pin-on-media'"
        [answer]="item"
        [classes]="classes"
        [message]="message"
        [question]="question"
        [explicitMediaUrls]="explicitMediaUrls"
        (textSelection)="selectText($event)">
    </app-moderation-answerstype-pin-on-media>
</ng-container>

