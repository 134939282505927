import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { AnswerModel } from '@app/shared/models/answer.model';
import { QuestionModel } from '@app/shared/models/question.model';
import { TranslateService } from '@ngx-translate/core';
import * as utils from './answers-charts-utils';

@Injectable({
  providedIn: 'root'
})
export class AnswersChartsService {

  constructor(
    public translate: TranslateService,
    public compFactory: ComponentFactoryResolver
  ) { }


  public hasChart(question: QuestionModel): boolean {
    let hasChart = [
      'closed-single', 'closed-multiple', 'semantic-differentials', 'attitude-scale',
      'grouping-category', 'preference-ranking', 'sentence-completion', 'image-association'
    ].indexOf(question.type) !== -1;

    if (question.type === 'pin-on-media') {
      hasChart = question.question_data?.require_pins;
    }

    return hasChart;
  }

  async calculateChartData(question: QuestionModel, answers: AnswerModel[], chartOptions: Highcharts.Options, dim: { w: string, h: string }) {
    if (
      ['closed-single', 'closed-multiple', 'semantic-differentials', 'attitude-scale', 'grouping-category', 'preference-ranking', 'sentence-completion', 'image-association', 'pin-on-media']
        .indexOf(question.type) === -1
    ) {
      return false;
    }
    utils.commonSetup(question, chartOptions, dim);
    switch (question.type) {
      case 'closed-single':
        this.closedSingle(question, answers, chartOptions);
        return true;
      case 'closed-multiple':
        this.closedMultiple(question, answers, chartOptions);
        return true;
      case 'semantic-differentials':
        this.semanticDifferentials(question, answers, chartOptions);
        return true;
      case 'attitude-scale':
        this.attitudeScale(question, answers, chartOptions);
        return true;
      case 'grouping-category':
        this.groupingCategories(question, answers, chartOptions);
        return true;
      case 'preference-ranking':
        this.preferenceRanking(question, answers, chartOptions);
        return true;
      case 'sentence-completion':
        this.sentenceCompletion(question, answers, chartOptions);
        return true;
      case 'image-association':
        this.imageAssociation(question, answers, chartOptions);
        return true;
      case 'pin-on-media':
        if (question.question_data.require_pins) {
          // TODO valutare se fare un chart per la distribuzione voti
          await this.pinOnMedia(question, answers, chartOptions);
          return true;
        }
        return false;
    }
    return false;
  }

  async calculateChartDim(question): Promise<{ w: string, h: string }> {
    switch (question.type) {
      case 'closed-single':
      case 'closed-multiple':
        return { w: '100%', h: '300px' };
      case 'semantic-differentials':
        return { w: '100%', h: `${150 + (40 * question.question_data.answers.length)}px` };
      case 'attitude-scale':
        return { w: '100%', h: `${150 + (40 * question.question_data.statements.length)}px` };
      case 'grouping-category':
        return { w: '100%', h: `${150 + (40 * question.question_data.categories.length)}px` };
      case 'preference-ranking':
        return { w: '100%', h: `${150 + (50 * question.question_data.how_many)}px` };
      case 'sentence-completion':
        return { w: '100%', h: '500px' };
        case 'image-association':
        return { w: '100%', h: '600px' };
      case 'pin-on-media':
        if (question.question_data.media_type === 'image') {
          const imgDim = await utils.getImageDim(question.question_data.image.image_url);
          const chartDim = utils.pinOnImageChartDim(utils.pinOnImageFitImageDim(imgDim));
          return { w: `${chartDim.w}px`, h: `${chartDim.h}px` };
        } else {
          return { w: '100%', h: question.question_data.pins_type === 'text_emoticon' ? '400px' : '200px' };
        }
      default:
        return null;
    }
  }

  private closedSingle(question: QuestionModel, answers: AnswerModel[], chartOptions: Highcharts.Options) {
    utils.pieChartSetup(chartOptions);
    const answersCount = { };
    answers.forEach(a => {
      answersCount[a.answer_data.optionid] = answersCount[a.answer_data.optionid] ? answersCount[a.answer_data.optionid] + 1 : 1;
    });
    question.question_data.answers.forEach((answer: any) => {
      // @ts-ignore
      chartOptions.series[0].data.push({ name: answer.comment, id: answer.id, y: answersCount[answer.id] || 0 });
    });
  }

  private closedMultiple(question: QuestionModel, answers: AnswerModel[], chartOptions: Highcharts.Options) {
    utils.pieChartSetup(chartOptions);
    const answersCount = { };
    answers.forEach(a => a.answer_data.forEach(ad => {
      answersCount[ad.optionid] = answersCount[ad.optionid] ? answersCount[ad.optionid] + 1 : 1;
    }));
    question.question_data.answers.forEach((answer: any) => {
      if (answersCount[answer.id]) {
        // @ts-ignore
        chartOptions.series[0].data.push({ name: answer.comment, id: answer.id, y: answersCount[answer.id] || 0 });
      }
    });
  }

  private semanticDifferentials(question: QuestionModel, answers: AnswerModel[], chartOptions: Highcharts.Options) {
    question.question_data.answers.forEach(a => {
      if (a.type === 'pre') {
        a.label_left = this.translate.instant(a.label_left_key);
        a.label_right = this.translate.instant(a.label_right_key);
      }
    });

    utils.semanticDifferentialsSetup(chartOptions, question.question_data.answers, this.translate);
    // setup series
    question.question_data.answers.forEach((a, y) => {
      chartOptions.series.push({
        id: a.id,
        type: 'bubble',
        name: `${a.label_left} | ${a.label_right}`,
        data: Array(5)
          .fill(0)
          .map((e, i) => i + 1)
          .map(x => ({ x, y, z: 0, p: 0 })),
        visible: false,
        sizeBy: 'width',
      });
    });
    // fill data
    answers.forEach((answer: any) => {
      answer.answer_data.forEach(answerData => {
        const serie = chartOptions.series.find(s => s.id === answerData.optionid) as any;
        const datum = (serie.data as Array<any>).find(el => el.x === answerData.rating);
        datum.z++;
        datum.p = Math.floor((datum.z / answers.length) * 100);
      });
    });
    // make means
    const meansDataPoints = chartOptions.series.map((s: any) =>
      (s.data.map(d => d.x * d.z)).reduce((a, b) => a + b, 0) / answers.length
    ).map((m, i) => [m, i]);
    chartOptions.series.push({
      id: 'mean',
      type: 'spline',
      name: this.translate.instant('CHART_MEANS'),
      data: meansDataPoints,
      tooltip: {
        pointFormatter() {
          return `${Math.round(this.x * 100) / 100}`;
        },
      },
      lineWidth: 1,
      findNearestPointBy: 'xy',
    });
  }

  private attitudeScale(question: QuestionModel, answers: AnswerModel[], chartOptions: Highcharts.Options) {
    const steps = Array(
      question.question_data.include_zero ? (question.question_data.steps + 1) : question.question_data.steps
    ).fill(0).map((e, i) => i + (question.question_data.include_zero ? 0 : 1));

    utils.attitudeScaleSetup(
      chartOptions,
      question.question_data.statements,
      steps,
      answers.length,
      { left: question.question_data.label_left, right: question.question_data.label_right, center: question.question_data.label_center },
      this.translate
    );
    answers.forEach((answer: any) => {
      answer.answer_data.forEach(answerData => {
        const serie = chartOptions.series.find(s => s.id === `${answerData.step}`) as any;
        ((serie?.data || []) as Array<any>)[answerData.statement]++;
      });
    });
  }

  private groupingCategories(question: QuestionModel, answers: AnswerModel[], chartOptions: Highcharts.Options) {
    utils.stackedBarChartSetup(
      chartOptions,
      question.question_data.categories,
      this.translate
    );
    question.question_data.answers.forEach((item: any, i: number) => {
      chartOptions.series.push({
        id: item.id,
        type: 'bar',
        name: item.comment,
        data: Array(question.question_data.categories.length).fill(0)
      });
    });
    answers.forEach((answer: any) => {
      answer.answer_data.forEach((answerData) => {
        const dataIdx = question.question_data.categories.indexOf(answerData.category);
        const serie = chartOptions.series.find(s => s.id === answerData.optionid) as any;
        (serie.data as Array<any>)[dataIdx]++;
      });
    });
  }

  private preferenceRanking(question: QuestionModel, answers: AnswerModel[], chartOptions: Highcharts.Options) {
    const sortedItems = (question.question_data.items as Array<any>).sort((a, b) => a.id - b.id);
    const ranks = Array(parseInt(question.question_data.how_many, 10)).fill(0).map((e, i) => i + 1);
    const labels = ranks.map(r => this.translate.instant(question.question_data.best_worst ? `#${r}` : `#${-r}`));
    utils.stackedBarChartSetup(chartOptions, labels, this.translate);
    sortedItems.forEach((item, i) => {
      chartOptions.series.push({
        id: `${item.id}`,
        type: 'bar',
        name: item.comment,
        data: Array(ranks.length).fill(0)
      });
    });
    answers.forEach((answer: any) => {
      answer.answer_data.rank.forEach((id: number, rank: number) => {
        const serie = chartOptions.series.find(s => s.id === `${id}`) as any;
        (serie.data as Array<any>)[rank]++;
      });
    });
  }

  private sentenceCompletion(question: QuestionModel, answers: AnswerModel[], chartOptions: Highcharts.Options) {
    const wordsCount = { };
    answers.forEach(a =>
      a.answer_data.completion_comments
        .map((c: string) => c.trim().toLowerCase())
        .forEach(c => {
          wordsCount[c] = wordsCount[c] ? wordsCount[c] + 1 : 1;
        })
    );

    utils.wordCloudSetup(chartOptions, this.translate);
    Object.keys(wordsCount).forEach(w => (chartOptions.series[0] as any).data.push({
      name: w,
      weight: wordsCount[w],
      perc: Math.floor((wordsCount[w] / answers.length) * 100)
    }));
  }

  private imageAssociation(question: QuestionModel, answers: AnswerModel[], chartOptions: Highcharts.Options) {
    // utils.barChartSetup(chartOptions, sortedKs, this.translate);
    utils.wordCloudSetup(chartOptions, this.translate);

    chartOptions.legend = { enabled: false };

    //@ts-ignore
    const sortedKs = chartOptions.temp
    sortedKs.forEach(img => (chartOptions.series[0] as any).data.push(img));

    chartOptions.series[0].point = {}
    chartOptions.series[0].point.events = {}
    // @ts-ignore
    chartOptions.series[0].point.events.click = chartOptions.tempEvent

    if (question.type === 'image-association') {
      setTimeout(() => {
        const container = document.querySelector('.highcharts-container')
        const points = document.querySelectorAll('.highcharts-point')
        const containerRect = container.getBoundingClientRect();
        points.forEach(point => {
          const rect = point.getBoundingClientRect();

          const el = document.createElement('div')
          const placeholderName = point.innerHTML
          const imageCloudItem = sortedKs.find(item => item.name == placeholderName)

          el.id = `image-cloud-id_${imageCloudItem.answer_id}`
          el.classList.add('image-cloud-item')
          el.style.position = 'absolute'
          el.style.width = rect.width + 'px'
          el.style.height = rect.height + 'px'
          el.style.top = (rect.top - containerRect.top) + 'px'
          el.style.left = (rect.left - containerRect.left) + 'px'
          el.style.pointerEvents = 'none'
          el.innerHTML = `<img src="${imageCloudItem.image_url}" class="w-100" />`

          container.appendChild(el)
        })
      }, 1000);
    }
  }

  private async pinOnMedia(question: QuestionModel, answers: AnswerModel[], chartOptions: Highcharts.Options) {
    if (question.question_data.media_type === 'video') {
      await this.pinOnVideo(question, answers, chartOptions);
    } else {
      await this.pinOnImage(question, answers, chartOptions);
    }
  }


  private async pinOnVideo(question: QuestionModel, answers: AnswerModel[], chartOptions: Highcharts.Options) {
    const hasEmoticons = question.question_data.pins_type === 'text_emoticon';
    // get length in seconds
    const len = await utils.getVideoLen(question.question_data.video ? question.question_data.video.video_url : question.question_data.videos[0].video_url);
    // set number of segments - length < 1 minute ? 1 second per segment : 60 segments
    // + 1 because need the last segment for trail points
    const nseg = (len > 59 ? 59 : Math.floor(len)) + 1;
    // find length of each segment which is at least one second
    // const segLen = Math.ceil(len / nseg);
    const segLen = Math.round((len / nseg) * 100) / 100;
    const data = utils.getVideoSegmentationPinsCount(answers, hasEmoticons, len, segLen);
    utils.pinOnVideoSetup(chartOptions, segLen, this.translate);

    // avoid bubbles to be maxsize when no answers
    if (data.reduce((p, c) => p + c.total, 0) === 0) {
      chartOptions.plotOptions.bubble.maxSize = 2
    }

    chartOptions.series = [{
      type: 'bubble',
      name: 'Totals',
      data: data.map((d, i) => [(i + 0.5), 0, d.total]),
      color: '#99A0A9',
      sizeBy: 'width',
    }];
    const emox = (emo: number) => emo === 1 ? 3 : (emo === 3 ? 1 : 2);
    const emocol = (emo: number) => emo === 1 ? '#008000' : (emo === 3 ? '#FF0000' : '#FEC830');
    if (hasEmoticons) {
      [3, 2, 1].forEach(emo => {
        chartOptions.series.push({
          type: 'bubble',
          name: emo === 1 ? ':)' : (emo === 2 ? ':|' : ':('),
          data: data.map((d, i) => [(i + 0.5), emox(emo), d[emo]]),
          color: emocol(emo),
          sizeBy: 'width',
        });
      });
    }
  }

  private async pinOnImage(question: QuestionModel, answers: AnswerModel[], chartOptions: Highcharts.Options) {
    const hasEmoticons = question.question_data.pins_type === 'text_emoticon';
    const dim = await utils.getImageDim(question.question_data.image.image_url);
    // const segLen = { x: Math.ceil(dim.w / 10), y: Math.ceil(dim.h / 10) };
    const singSegLen = dim.h > dim.w ? Math.ceil(dim.h / 50) : Math.ceil(dim.w / 50);
    const segLen = { x: singSegLen, y: singSegLen };
    const data = utils.getImageGridPinsCount(answers, hasEmoticons, dim, segLen.x, segLen.y);
    const maxCount = data.reduce((max, datapoint) => datapoint[2].total > max ? datapoint[2].total : max, 0);
    utils.heatMapSetup(chartOptions, question.question_data.image.image_url, dim, segLen, maxCount);
    chartOptions.series = [{
      type: 'heatmap',
      data: data.map(d => [d[0], d[1], d[2].total]),
    }];

    // da fare ? boh comunque così non funziona
    // if (hasEmoticons) {
    //   chartOptions.series[0].name = this.translate.instant('TOTALS');
    //   [1, 2, 3].forEach(emo => {
    //     chartOptions.series.push({
    //       type: 'heatmap',
    //       name: 'Emoticon ' + emo,
    //       data: data.map(d => [d[0], d[1], d[2][emo]]),
    //       tooltip: {
    //         headerFormat: 'Pins count for emoticon ' + emo + '<br/>',
    //         pointFormat: '<b>{point.value}</b>'
    //       }
    //     });
    //   });
    // }
  }
}
