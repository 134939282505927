/**
 * Interval
 * Precise alternative to setInterval
 */
export const Interval = function(duration: number, fn: any, doOnce = false) {
    this.baseline = undefined;
    this.started = false;

    this.run = function() {
      if (this.baseline === undefined) {
        this.baseline = new Date().getTime();
      }

      let nextTick = duration;
      let end = null;

      if (this.started) {
        fn();
        if (doOnce) {
          return this.stop();
        }
        end = new Date().getTime();
        this.baseline += duration;
        nextTick = duration - (end - this.baseline);
      }

      if (nextTick < 0) {
        nextTick = 0;
      }

      ( function(i) {
          i.timer = setTimeout(() => {
          i.run(end);
        }, nextTick);
      } (this));

      this.started = true;
    };

    this.stop = function() {
      clearTimeout(this.timer);
    };

};

