<app-shared-sidebar-container *ngIf="country && research">
    <app-shared-sidebar-header>
        <div slot="title">
            <h2 class="mb-0">{{ 'MANAGE_TEAM.HEADER' | translate }}</h2>
          </div>
    </app-shared-sidebar-header>
    <div *ngIf="!sendMessage && !sendMessageCustomer">
        <div class="sidebar-body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <h6>{{ 'MANAGE_TEAM.LABEL' | translate }}</h6>
                    </div>
                </div>
                <div class="row align-items-center mt-3 tool-row">
                    <div class="col">
                        <h4 class="mb-0">{{ 'MANAGE_TEAM.ADD_STUFF' | translate }}</h4>
                    </div>
                </div>
                <form action="" *ngIf="form" [formGroup]="form" (submit)="addMember()" class="panel shadow mb-5">
                    <div class="row">
                        <!-- <div class="col-md-4">
                            <div class="form-group">
                                <app-core-select
                                    [classes]="'form-control'"
                                    [placeholder]="'LANG'"
                                    [cache]="true"
                                    [formEntityName]="'language_code'"
                                    [form]="form"
                                    [value]="'language_code'"
                                    [searchable]="true"
                                    [service]="languagesService">
                                </app-core-select>
                            </div>
                        </div> -->
                        <div class="col-md-4">
                            <div class="form-group required">
                                <app-core-select
                                    [classes]="'form-control'"
                                    [placeholder]="'ROLE'"
                                    [cache]="true"
                                    [formEntityName]="'role'"
                                    [form]="form"
                                    [value]="'role'"
                                    [searchable]="true"
                                    [service]="rolesService"
                                    >
                                </app-core-select>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="form-group required" [class.is-invalid]="form.get('email').invalid && form.get('email').touched">
                                <label>
                                    <!-- <input required formControlName="email" type="text" class="form-control" id="email"> -->

                                    <ng-autocomplete
                                    #autocomplete
                                    required formControlName="email" [data]="autocompleteEmail"
                                    (inputChanged)='emailAutocomplete($event)' [searchKeyword]="'email'"
                                    [itemTemplate]="emailTemplate" [debounceTime]="300" (inputCleared)="formGroup()"
                                    [notFoundTemplate]="notFoundTemplate"
                                    >
                                    </ng-autocomplete>

                                    <ng-template #emailTemplate let-item>
                                        <a (click)="autocompleteUser(item)" [innerHTML]="item.email || ''"></a>
                                    </ng-template>
                                    <ng-template #notFoundTemplate let-notFound>
                                        <div>{{'NOT_FOUND' | translate}}</div>
                                    </ng-template>

                                    <span class="placeholder">{{ 'EMAIL' | translate }}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div [class.is-invalid]="form.get('firstname').invalid && form.get('firstname').touched">
                                <div class="form-group required">
                                    <label>
                                        <input required formControlName="firstname" type="text" class="form-control" id="firstname">
                                        <span class="placeholder">{{ 'NAME' | translate }}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div [class.is-invalid]="form.get('lastname').invalid && form.get('lastname').touched">
                                <div class="form-group required">
                                    <label>
                                        <input required formControlName="lastname" type="text" class="form-control" id="lastname">
                                        <span class="placeholder">{{ 'SURNAME' | translate }}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div [class.is-invalid]="form.get('phone').invalid && form.get('phone').touched">
                                <div class="form-group">
                                    <label>
                                        <input formControlName="phone" type="text" class="form-control" id="phone">
                                        <span class="placeholder">{{ 'TELEPHONE' | translate }}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col text-right">
                            <button type="button" (click)="addMember()" class="btn-sicche"><i class="fal fa-plus"></i>{{ 'ADD' | translate }}</button>
                        </div>
                    </div>
                </form>
                <div class="row align-items-center mt-3 tool-row" *ngIf="users">
                    <div class="col-12 d-flex align-items-center justify-content-start">
                        <button class="btn-sicche btn-secondary w-limit" (click)="changeSelectedUsers('all')">{{ 'MANAGE_TEAM.SELECT_DESELECT_ALL' | translate }}</button>
                        <button [disabled]="selectedUsers.length == 0" class="btn-sicche btn-secondary w-limit" (click)="removeUser()"><i class="fal fa-trash-alt"></i>{{'DELETE_USER' | translate}}</button>
                        <button [disabled]="selectedUsers.length == 0" class="btn-sicche btn-secondary w-limit" (click)="sendInvite()"><i class="fal fa-envelope"></i>{{'SEND_INVITE' | translate}}</button>
                        <!-- <button [disabled]="selectedUsers.length == 0" class="btn-sicche btn-secondary w-limit" (click)="sendPassword()"><i class="fal fa-lock-alt"></i>{{'SEND_PASSWORD' | translate}}</button> -->
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="panel shadow">
                            <app-core-spinner class="mt-3" [toggle]="loadingUsers"></app-core-spinner>
                            <div class="position-relative">
                                <div class="table-responsive">
                                    <app-core-table
                                        *ngIf="users"
                                        [items]="users"
                                        [columns]="[ {name: 'SELECT'}, {name: 'INVITE_STATUS'}, {name: 'EMAIL'}, {name: 'NAME'}, {name: 'SURNAME'}, {name: 'LANGUAGE'}, {name: 'ROLE'}, {name: 'PHONE'}]"
                                        tableid="users"
                                        [perPage]="999999"
                                        [rowTemplate]="rowTemplate">
                                    </app-core-table>
                                    <ng-template let-item="item" #rowTemplate>
                                        <tr>
                                            <td class="text-center">
                                                <input type="checkbox" [checked]="selectedUsers.indexOf(item.userid) > -1" (change)="changeSelectedUsers(item.userid)">
                                            </td>
                                            <td class="text-center">
                                                <i *ngIf="!item.invite_sent && !item.invite_read" class="fal fa-minus"></i>
                                                <i *ngIf="item.invite_sent && !item.invite_read" class="fal fa-envelope"></i>
                                                <i *ngIf="item.invite_sent && item.invite_read" class="fal fa-envelope-open"></i>
                                            </td>
                                            <td class="font-weight-bold text-center">
                                                {{ item.email }}
                                            </td>
                                            <td class="font-weight-bold text-center">
                                                {{ item.firstname }}
                                            </td>
                                            <td class="font-weight-bold text-center">
                                                {{ item.lastname }}
                                            </td>
                                            <td class="text-center">
                                                {{ item.language_code | translate }}
                                            </td>
                                            <td class="text-center">
                                                {{ "ROLES." + item.role | translate }}
                                            </td>
                                            <td class="text-center">
                                                {{ item.telephone }}
                                            </td>
                                        </tr>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- customers -->
                <div class="row align-items-center mt-3 tool-row" *ngIf="customerUsers">
                    <div class="col-12 d-flex align-items-center justify-content-start">
                        <button class="btn-sicche btn-secondary w-limit" (click)="changeCustomerSelectedUsers('all')">{{ 'MANAGE_TEAM.SELECT_DESELECT_ALL' | translate }}</button>
                        <button (click)="openSmartSetupModal()" class="btn-sicche btn-primary" [disabled]="!hasSmartCustomer()">
                            <i class="fal fa-glasses" style="font-size: 14px;"></i>&nbsp;{{ 'REPORT_SMART_SETUP' | translate }}
                        </button>
                        <button [disabled]="customerSelectedUsers.length == 0" class="btn-sicche btn-secondary w-limit" (click)="removeUser(true)"><i class="fal fa-trash-alt"></i>{{'DELETE_USER' | translate}}</button>
                        <button [disabled]="customerSelectedUsers.length == 0" class="btn-sicche btn-secondary w-limit" (click)="sendInvite(true)"><i class="fal fa-envelope"></i>{{'SEND_LINK' | translate}}</button>
                        <!-- <button [disabled]="selectedUsers.length == 0" class="btn-sicche btn-secondary w-limit" (click)="sendPassword()"><i class="fal fa-lock-alt"></i>{{'SEND_PASSWORD' | translate}}</button> -->
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="panel shadow">
                            <app-core-spinner class="mt-3" [toggle]="loadingUsers"></app-core-spinner>
                            <div class="position-relative">
                                <div class="table-responsive">
                                    <app-core-table
                                        *ngIf="customerUsers"
                                        [items]="customerUsers"
                                        [columns]="[{name: 'SELECT'}, {name: 'INVITE_STATUS'}, {name: 'EMAIL'}, {name: 'NAME'}, {name: 'SURNAME'}, {name: 'LANGUAGE'}, {name: 'ROLE'}, {name: 'PHONE'}, {name: 'CUSTOMER_URL'}]"
                                        tableid="customerUsers"
                                        [perPage]="999999"
                                        [rowTemplate]="rowTemplateCustomers">
                                    </app-core-table>
                                    <ng-template let-item="item" #rowTemplateCustomers>
                                        <tr>
                                            <td class="text-center">
                                                <input type="checkbox" [checked]="customerSelectedUsers.indexOf(item.userid) > -1" (change)="changeCustomerSelectedUsers(item.userid)">
                                            </td>
                                            <td class="text-center">
                                                <i *ngIf="!item.invite_sent && !item.invite_read" class="fal fa-minus"></i>
                                                <i *ngIf="item.invite_sent && !item.invite_read" class="fal fa-envelope"></i>
                                                <i *ngIf="item.invite_sent && item.invite_read" class="fal fa-envelope-open"></i>
                                            </td>
                                            <td class="font-weight-bold text-center">
                                                {{ item.reference_email || item.email }}
                                            </td>
                                            <td class="font-weight-bold text-center">
                                                {{ item.firstname }}
                                            </td>
                                            <td class="font-weight-bold text-center">
                                                {{ item.lastname }}
                                            </td>
                                            <td class="text-center">
                                                {{ item.language_code | translate }}
                                            </td>
                                            <td class="text-center">
                                                {{ "ROLES." + item.role | translate }}
                                            </td>
                                            <td class="text-center">
                                                {{ item.telephone }}
                                            </td>
                                            <td class="text-center">
                                                <span *ngIf="item.customer_url">
                                                    <a (click)="copyToClipboard(item.customer_url)">
                                                        {{'COPY_CUSTOMER_URL_TO_CLIPBOARD' | translate}}
                                                    </a>
                                                </span>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="sendMessage" class="sidebar-body">
        <app-shared-send-message
        [selectedUsers]="selectedUsers"
        [selectedUsersData]="getUsersByIds(selectedUsers)"
        [country_uniqid]="country.country_uniqid"
        [type]="'INVITE'"
        [sendLink]="false"
        [isSidebar]="false"
        (msgSent)="msgSent()"
        ></app-shared-send-message>
    </div>
    <div *ngIf="sendMessageCustomer" class="sidebar-body">
        <app-shared-send-message
        [selectedUsers]="customerSelectedUsers"
        [selectedUsersData]="getCustomersByIds(customerSelectedUsers)"
        [country_uniqid]="country.country_uniqid"
        [sendLink]="false"
        [type]="'INVITE'"
        [isSidebar]="false"
        (msgSent)="msgSent()"
        [isCustomer]="true"
        ></app-shared-send-message>
    </div>
</app-shared-sidebar-container>