import { Component, OnInit, Input } from '@angular/core';
import { CoreLayoutService } from '@app/core/services/layout.service';

import 'quill-emoji/dist/quill-emoji.js';
import { CountryModel } from '@app/shared/models/country.model';
import { CountriesService } from '@app/core/services/countries.service';
import { CountryService } from '@app/shared/services/country.service';
import { UserService } from '@app/shared/services/user.service';
import { ResponseModel } from '@app/core/models/response.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@app/shared/models/user.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { ManageUsersService } from '@app/shared/services/support/manage-users.service';

@Component({
  selector: 'app-private-setup-customize-privacy',
  templateUrl: './customize-privacy.component.html',
  styleUrls: ['./customize-privacy.component.scss']
})
export class CustomizePrivacyComponent extends CoreBaseComponent implements OnInit {

  constructor(
    public coreLayoutService: CoreLayoutService,
    private countryService: CountryService,
    private userService: UserService,
    private toastService: ToastrService,
    private translate: TranslateService,
    private manageUsersService: ManageUsersService
  ) {
    super();
    this.modules = {
      'emoji-shortname': true,
      'emoji-textarea': true,
      'emoji-toolbar': true,
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote'],
        [{ header: 1 }, { header: 2 }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ direction: 'rtl' }],
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }],
        [{ font: [] }],
        [{ align: [] }],
        ['clean'],
        ['link', 'image', 'video'],
        ['emoji']
      ]
    };
  }

  country: CountryModel;
  modules = {};
  user: User;
  saveAsDefault = false;

  ngOnInit(): void {
    this.userService.getCurrentUser().subscribe((res: ResponseModel) => {

      this.user = res.getData();
    });

    this.coreLayoutService.sidebarParams$.subscribe((country: CountryModel) => {
      this.country = country;
    });
  }

  loadDefault() {
    if (this.user.default_privacy_html) {
      this.country.privacy_html = this.user.default_privacy_html;
    } else {
      this.toastService.error(this.translate.instant('NO_DEFAULT_PRIVACY_HTML'));
    }
  }

  save() {
    this.countryService.updateCountry(this.country).subscribe((res: ResponseModel) => {
      if (!res) {
        this.toastService.error(this.translate.instant('ERROR'));
      }
      this.coreLayoutService.closeSidebar();
    });
    if (this.saveAsDefault) {
      this.user.default_privacy_html = this.country.privacy_html;
      this.manageUsersService.updatePrivacyPolicy({default_privacy_html: this.country.privacy_html}).subscribe((res: ResponseModel) => {
        this.toastService.success(this.translate.instant('UPDATED_PRIVACY_POLICY'));
      });
    }
  }

}
