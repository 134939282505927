import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ResponseModel } from '@app/core/models/response.model';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { QuestionsService } from '@app/shared/services/questions.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { BlockModel } from '@app/shared/models/block.model';
import { AbstractQuestion } from '../abstract-question.component';

@Component({
  selector: 'app-setup-questions-attitude-scale',
  templateUrl: './attitude-scale.component.html',
  styleUrls: ['./attitude-scale.component.scss']
})
export class AttitudeScaleQuestionComponent extends AbstractQuestion implements OnInit {

  @Input() countryUniqId: string = null;
  @Input() block: BlockModel = null;
  @Input() question: QuestionModel = null;
  @Input() disabled = false;
  @Output() saveEmit: EventEmitter<string> = new EventEmitter(null);

  public form: UntypedFormGroup;
  optionsError = false;

  get statements(): UntypedFormArray {
    return this.form.get('statements') as UntypedFormArray;
  }

  get images(): UntypedFormArray {
    return this.form.get('images') as UntypedFormArray;
  }

  constructor(private formBuilder: UntypedFormBuilder, private layoutService: CoreLayoutService, protected translate: TranslateService,
              private questionsService: QuestionsService, protected toastService: ToastrService) {
    super(toastService, translate);
  }

  ngOnInit(): void {

    const questionDataForm = this.formBuilder.group({
      include_zero: new UntypedFormControl(this.question ? this.question.question_data.include_zero : '', null),
      randomize_order: new UntypedFormControl(this.question ? this.question.question_data.randomize_order : '', null),
      steps: new UntypedFormControl(this.question ? this.question.question_data.steps : '', null),
      steps1: new UntypedFormControl(this.question ? this.question.question_data.steps : '', null),
      label_center: new UntypedFormControl(this.question ? this.question.question_data.label_center : '', null),
      label_left: new UntypedFormControl(this.question ? this.question.question_data.label_left : '', [Validators.required]),
      label_right: new UntypedFormControl(this.question ? this.question.question_data.label_right : '', [Validators.required]),
      include_side_emoticons: new UntypedFormControl(this.question ? this.question.question_data.include_side_emoticons : '', null),
      include_center_emoticon: new UntypedFormControl(this.question ? this.question.question_data.include_center_emoticon : '', null),
    })


    this.form = this.formBuilder.group({
      question_data: questionDataForm,
      statements: this.formBuilder.array(
        this.question
          ? this.question.question_data.statements.map(st =>
            new UntypedFormGroup({
              statement: new UntypedFormControl(st.statement, null)
            })
          )
          : []
      ),
      images: this.formBuilder.array(
        this.question
          ? this.question.question_data.attached_images.map(item =>
            new UntypedFormGroup({
              image_url: new UntypedFormControl(item.image_url, null),
              image_del: new UntypedFormControl(false, null),
              image_uniqid: new UntypedFormControl(item.image_uniqid, null)
            })
          )
          : []
      ),
      blockid: new UntypedFormControl(this.block.id, Validators.required),
      is_masked: new UntypedFormControl(this.question ? this.question.is_masked : this.block.is_room_group ? true : false, null),
      title: new UntypedFormControl(this.question ? this.question.title : '', null),
      has_minimum_multiple_answers_nr: new UntypedFormControl((this.question && this.question.minimum_multiple_answers_nr) ? true : null),
      minimum_multiple_answers_nr: new UntypedFormControl(this.question ? this.question.minimum_multiple_answers_nr : null),
      type: new UntypedFormControl('attitude-scale', null),
      personas_tags: new UntypedFormControl(this.question ? this.question.personas_tags : '', null),
      sample_variable_tags: new UntypedFormControl(this.question ? this.question.sample_variable_tags : '', null)
    });
    if (!this.question) {
      this.addStatement();
    }
    if (this.disabled) {
      this.form.disable();
    }
    this.layoutService.buttons([
      {label: 'SAVE_AND_EXIT', slug: 'saveexit', classes: 'mr-3 btn-sicche btn-secondary', icon: 'fal fa-share-square', click: this.submitAndExit },
      {label: 'SAVE_AND_CREATE_NEW', slug: 'save', classes: 'mr-5 btn-sicche btn-primary', icon: 'fal fa-check', click: this.submitAndStay },
    ]);

    this.form.setValidators(this.requireStepsValidator());
    this.form.controls.images.valueChanges.subscribe(value => this.onImagesChange(value));
    // call first time
    if (!this.question) {
      this.onImagesChange(this.images.value);
    }

    this.initAttachments();
  }

  requireStepsValidator(): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors => {
       const steps = (group.get('question_data') as UntypedFormGroup).controls.steps.value;
       const images = group.controls.images.value;
       if ((!steps || steps <= 0) && (!images || !images.length)) {
          return { stepsOrImagesRequired: true };
       }
       return;
    };
  }

  addStatement() {
    this.statements.push(new UntypedFormGroup({
      statement: new UntypedFormControl('', null)
    }));
  }

  removeStatement(index: number) {
    this.statements.removeAt(index);
  }

  onImagesChange(value: any[]) {
    const data = (this.form.get('question_data') as UntypedFormGroup).controls;
    if (value.length > 0) {
      data.steps.disable();
      data.steps.setValue('');
      data.include_zero.disable();
      data.include_zero.setValue('');
    } else {
      data.steps.enable();
      data.include_zero.enable();
    }
  }

  submitAndExit = () => {
    this.layoutService.getButton('saveexit').loading = true;
    this.submit(() => {
      this.saveEmit.emit('save-and-leave');
    }, () => {
      this.layoutService.getButton('saveexit').loading = false;
    });
  }

  submitAndStay = () => {
    this.layoutService.getButton('save').loading = true;
    this.submit(() => {
      this.saveEmit.emit('save-and-stay');
    }, () => {
      this.layoutService.getButton('save').loading = false;
    });
  }

  submit(callback: any, fallback: any) {
    if (this.disabled) {
      fallback();
      this.toastService.error(this.translate.instant('ERRORS.CAN_NOT_MODIFY_PAST'));
      return;
    }

    const formValue = this.form.getRawValue();
    this.optionsError = false;
    // test for empty statements
    formValue.statements
      .map(s => s.statement)
      .forEach(el => {
        if (!el.replace(/ /g, '')) {
          this.optionsError = true;
        }
      }
    );
    let err = null;
    if (this.optionsError) {
      err = 'ERRORS.ANSWER_OPTIONS_MISSING_COMMENT';
    } else if (this.form.hasError('stepsOrImagesRequired')) {
      err = 'ERRORS.STEPS_OR_IMAGES_REQUIRED';
    } else if (this.form.get('question_data').invalid) {
      err = 'ERRORS.GENERIC_INPUTS_REQUIRED';
    }

    if (err) {
      fallback();
      this.toastService.error(this.translate.instant(err));
    } else {
      this.operation('creating').reset().isSubmitting();
      const data = formValue;
      if (data.sample_variable_tags && data.sample_variable_tags.length > 0) {
        data.sample_variable_tags_by_comma = data.sample_variable_tags.join(',');
        data.sample_variable_tags = null;
      }
      if (data.personas_tags && data.personas_tags.length > 0) {
        data.personas_tags_by_comma = data.personas_tags.join(',');
        data.personas_tags = null;
      }
      data.question_data.statements = this.form.value.statements;
      data.question_data.attached_images = this.form.value.images;

      data.question_data.attached_pdf_files = this.attachedPdfFiles;
      data.question_data.attached_images = this.attachedImages;

      delete data.statements;
      delete data.images;
      (!this.question ? this.questionsService.createQuestion(data)
        : this.questionsService.updateQuestion(this.question.id, data)).subscribe((res: ResponseModel) => {
        this.operation('creating').isSuccess(res.getErrors());
        this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
        callback();
      }, (e) => {
        this.operation('creating').isFailed(e.errors);
        this.toastService.error(this.translate.instant('ERRORS.GENERIC'));
        fallback();
      });
    }
  }
}
