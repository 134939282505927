<section>
    <form [formGroup]="form">
        <div class="container-fluid position-relative">

            <app-setup-questions-shared-media-upload
                [question]="question"
                [images]="attachedImages"
                [pdfFiles]="attachedPdfFiles"
                (addImageOut)="addImage($event)"
                (removeImageOut)="removeImage($event)"
                (addPdfOut)="addPdf($event)"
                (removePdfOut)="removePdf($event)">
            </app-setup-questions-shared-media-upload>

            <h4 class="mb-2">{{ 'QUESTION' | translate }}</h4>
            <div class="question-text panel shadow">
                <app-setup-questions-text [form]="form" [countryUniqId]="countryUniqId"></app-setup-questions-text>
            </div>
            <h4 class="mb-2">{{ 'SUGGESTION' | translate }}</h4>
            <div class="form-group" formGroupName="question_data">
                <textarea class="form-control" formControlName="suggestion"></textarea>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="check-radio-box">
                        <input formControlName="has_minimum_multiple_answers_nr" (change)="setMinimumMultipleAnswer()" type="checkbox" id="checkHasMinimumMultipleAnswer">
                        <label for="checkHasMinimumMultipleAnswer">
                            {{ 'MULTIPLE_ANSWERS_QUESTION' | translate }}
                            <span class="info-tooltip" placement="top" [ngbTooltip]="'MULTIPLE_ANSWERS_QUESTION_TOOLTIP' | translate">
                                <i class="far fa-info-circle"></i>
                            </span>
                        </label>
                    </div>
                    <div class="row" *ngIf="form.controls.has_minimum_multiple_answers_nr.value">
                        <div class="col-6 pr-3">
                            <label for="minMultipleAnswer">
                                {{ 'MULTIPLE_ANSWERS_QUESTION_NUMBER' | translate }}
                            </label>
                            <div class="col-1 p-0 form-group">
                                <input type="number" min="1" formControlName="minimum_multiple_answers_nr" id="minMultipleAnswer" class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="block.is_room_group" class="mb-3 mt-3">
                <input formControlName="is_masked" type="checkbox" id="check2">
                <label for="check2">
                    {{ 'MASQUERADE_QUESTION' | translate }}
                </label>
            </div>

            <app-setup-questions-selects [question]="question" [block]="block" [form]="form" [countryUniqId]="countryUniqId"></app-setup-questions-selects>


        </div>
    </form>
</section>