<app-core-spinner [toggle]="operation('loading-answer').submitting"></app-core-spinner>
<ng-container *ngIf="answer">
    <div class="alert alert-danger mt-2" *ngIf="!answer.length">
        {{ selectedTeamUser.nickname ? selectedTeamUser.nickname : selectedTeamUser.firstname + ' ' + selectedTeamUser.lastname }} {{ 'HAS_NOT_YET_REPLIED_TO_THIS_QUESTION' | translate }}
    </div>
    <div *ngIf="answer.length">
        <ng-container *ngFor="let answ of answer">
            <div class="mt-1 incoming-msg d-flex align-items-start justify-content-between">
                <div class="incoming-msg-img d-flex flex-column align-items-center text-center mt-3">
                    <div class="avatar small">
                        <app-shared-avatar [imageUrl]="answ.team.avatar_url"></app-shared-avatar>
                    </div>
                    <span *ngIf="answ.team.id == authService?.user?.id"><b>{{ 'YOU' | translate }}, {{ 'MODERATOR' | translate | lowercase }}</b></span>
                    <span *ngIf="answ.team.id != authService?.user?.id"><b>{{ answ.team.nickname ? answ.team.nickname : answ.team.firstname + ' ' + answ.team.lastname }}</b></span>
                    <div placement="top" [ngbTooltip]="'LOCKED_USER' | translate" *ngIf="selectedTeamUser.is_locked" class="locked-icon text-center">
                        <i class="far fa-lock" style="font-size: 18px;"></i>
                    </div>
                    <div class="time">
                        <small class="mr-1">{{ answ.creationtime | dateAgo }}</small>
                        <i *ngIf="answ.isMobile()" class="fal fa-mobile"></i>
                        <i *ngIf="answ.isDesktop()" class="far fa-desktop"></i>
                    </div>
                </div>
                <app-moderation-peopleview-answer
                    (replyFeedback)="replyFeedback($event)"
                    [selectedTeamUser]="selectedTeamUser"
                    [question]="question"
                    class="flex-grow-1"
                    (textSelection)="textSelected($event)"
                    [answer]="answ">
                </app-moderation-peopleview-answer>
            </div>
        </ng-container>
    </div>
</ng-container>