import { Component, OnInit, ViewChild, AfterViewInit, Input, EventEmitter, Output } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { QuestionsService } from '@app/shared/services/questions.service';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { AnswersService } from '@app/shared/services/answers.service';
import { ResponseModel } from '@app/core/models/response.model';
import { AnswerModel } from '@app/shared/models/answer.model';
import { AuthService } from '@app/core/services/auth.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@app/core/services/modal.service';
import { TeamService } from '@app/shared/services/team.service';
import { NotificationService } from '@app/shared/services/notification.service';
import { TeamModel } from '@app/shared/models/team.model';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { LightboxModalComponent } from '@app/core/components/lightbox-modal/lightbox-modal.component';
import { CommentModel } from '@app/shared/models/comment.model';
import { ModerationHelperService } from '@app/shared/services/moderation-helper.service';
import { QuoteModel } from '@app/shared/models/quote.model';
import { CountryService } from '@app/shared/services/country.service';

@Component({
  selector: 'app-moderation-answer-comment',
  templateUrl: './answer-comment.component.html',
  styleUrls: ['./answer-comment.component.scss']
})
export class AnswersBlockCommentComponent extends CoreBaseComponent implements OnInit {

  @Input()
  commentAnswer: CommentModel;
  @Input()
  exportableVersion = false;
  @Input()
  readonly: boolean = false;
  @Input()
  personasTags: Array<any>; // answer
  @Input()
  selectable: boolean = false;
  @Input()
  answer: AnswerModel;
  @Input()
  intervieweds: TeamModel[] = [];
  @Input()
  highlighted_in = false;
  @Input()
  verbose = false;
  @Input()
  impersonal; // use true when accessing with no login, for pdf generation
  @Output() textSelection: EventEmitter<any> = new EventEmitter(null);

  public contentTagsList = null;
  public form: UntypedFormGroup;
  public deleted = false;
  public itemToModify: number;
  public isCustomerPro = false;
  public mediaTab = 'images';
  public showMediaTabs = false;
  public highlighted = false;
  public textToInject: any = null;
  public qSubscription = null;
  private originalText = null;
  public personasForm: UntypedFormGroup = null;
  public contentTagsForm: UntypedFormGroup = null;
  public allPersonasTagsByUserid;
  public allNoteByUserid;
  public allPersonasTags: String[];


  @ViewChild('qp') public qp: NgbPopover;

  constructor(private countryService: CountryService, private coreLayoutService: CoreLayoutService, private teamsService: TeamService,
              private notificationService: NotificationService, public moderationHelperService: ModerationHelperService,
              public answerService: AnswersService, public authService: AuthService, private modalService: ModalService,
              private toastService: ToastrService, private translate: TranslateService, private teamService: TeamService) {
    super();
  }

  ngOnInit(): void {
    if (!(this.commentAnswer instanceof CommentModel)) {
      this.commentAnswer = new CommentModel(this.commentAnswer);
    }
    this.countryService.currentContentTag.subscribe(contentTags => {
      this.contentTagsList = contentTags;
    })
    // console.log(this.impersonal)
    // Replace return/new-line with br
    this.commentAnswer.comment = this.commentAnswer.comment?.replace(/(\r\n|\n|\r)/gm, "<br />");

    this.addQuotes();
    if(this.highlighted_in) {
      this.highlighted = true;
    }
    this.isCustomerPro = this.teamService.isCurrentUserCustomerPro();
    this.form = new UntypedFormGroup({
      text: new UntypedFormControl(this.commentAnswer.comment, null)
    });
    this.personasForm = new UntypedFormGroup({
      personas_tags: new UntypedFormControl(this.commentAnswer.team.personas_tags ?
        this.commentAnswer.team.personas_tags.length ? this.commentAnswer.team.personas_tags[0] : null : null, null),
    });
    this.contentTagsForm = new UntypedFormGroup({
      content_tags: new UntypedFormControl(this.commentAnswer.comment_tags ?
        this.commentAnswer.comment_tags.length ? this.commentAnswer.comment_tags : null : null, null),
    });
    this.moderationHelperService.teamLockChange.subscribe(value => {
      if(value && value.userid === this.commentAnswer.team.userid) {
        this.commentAnswer.team = value;
      }
    });
    this.serveNotification();

    this.countryService.allUserPersonasTagById.subscribe((personasTagsByAnswerid) => {
      this.allPersonasTagsByUserid = personasTagsByAnswerid;
      this.commentAnswer.team.personas_tags = this.getPersonasTag(this.commentAnswer.team.personas_tags);
    })

    this.countryService.allUsersNoteById.subscribe((noteByUserid) => {
      this.allNoteByUserid = noteByUserid
      this.commentAnswer.team.note = this.getNote(this.commentAnswer.team.note);
    })


    this.countryService.currentPersonasTags.subscribe(personasTags => {
      this.allPersonasTags = personasTags
    })

  }

  getAllPersonasTags() {
    return this.allPersonasTags;
  }

  getNote(fallback) {
    return this.allNoteByUserid[this.commentAnswer.team.userid] || fallback
  }

  getPersonasTag(fallback): string[] {
    return this.allPersonasTagsByUserid[this.commentAnswer.team.userid] || fallback
  }

  private addQuotes(reset = false) {
    if(!this.teamService.isCurrentUserCustomerPro() && !this.teamService.isCurrentUserCustomerBasic()) {
      console.log(this.commentAnswer.quotes)
      if(this.commentAnswer.quotes) {
        this.copyOriginalText(reset);
        this.printQuotes(this.commentAnswer.quotes);
      }
      this.qSubscription = this.moderationHelperService.quotes.subscribe(quotes => {
        if(quotes) {
          this.copyOriginalText(reset);
          this.printQuotes(quotes);
        }
      });
    }}

  private copyOriginalText(reset = false) {
    // Answer text
    if(!this.originalText || reset) {
      this.originalText = this.commentAnswer.comment;
    }
    this.commentAnswer.comment = this.originalText;
    // Video transcription
    this.commentAnswer.videos().forEach(video => {
      video.hlTranscription = '';
      if (video.video_data.file_words) {
        video.video_data.file_words.forEach(word => {
          video.hlTranscription += word.word + ' ';
        });
      }
    });
  }

  private printQuotes(quotes: Array<QuoteModel>) {
    //@ts-ignore
    this.commentAnswer.comment = this.commentAnswer.comment?.replace(/(\r\n|\n|\r)/gm, "<br />");
    if(quotes != null) {
      quotes.forEach(quote => {
        if(quote.commentid === this.commentAnswer.id) {
          if(this.commentAnswer.comment) {
            const qclass = quote.color ? quote.color : 'grey';
            if(quote.quote_original && quote.quote_original.length) {
              quote.quote_original.forEach(q => {
                this.commentAnswer.comment = this.commentAnswer.comment.replace(q, '<em class="highlight ' + qclass + '">'+ q +'</em>');
              });
            } else {
              // old way - before 29/July/2021
              this.commentAnswer.comment = this.commentAnswer.comment.replace(quote.quote, '<em class="highlight ' + qclass + '">' + quote.quote + '</em>');
            }
          }
        }
        if(this.commentAnswer.videos().length && quote.commentid === this.commentAnswer.id) {
          this.commentAnswer.videos().forEach(video => {
            this.hlTranscription(video, quote);
          });
        }
      });
    }
  }

  private hlTranscription(video: any, quote: QuoteModel) {
    if(!video.hasOwnProperty('hlTranscription')) {
      video.hlTranscription = '';
      video.video_data.file_words.forEach(word => {
        video.hlTranscription += word.word + ' ';
      });
    }
    const qclass = quote.color ? quote.color : 'grey';
    if(quote.quote_original && quote.quote_original.length) {
      quote.quote_original.forEach(q => {
        video.hlTranscription = video.hlTranscription.replace(q, '<em class="highlight ' + qclass + '">'+ q +'</em>');
      });
    } else {
      // old way - before 29/July/2021
      video.hlTranscription = video.hlTranscription.replace(quote.quote, '<em class="highlight ' + qclass + '">' + quote.quote + '</em>');
    }
  }

  selectText() {
    this.textSelection.emit({commentid: this.commentAnswer.id});
  }

  openLightbox(image: any | number, mediaArray?: Array<any>) {
    this.modalService.open({modalModel: LightboxModalComponent, dialogSize: 'custom'}, {image, images: mediaArray});
  }

  private serveNotification() {
    const notice = this.notificationService.currentNotice;
    if (notice) {
      if (notice.commentid) {
        this.highlighted = notice.commentid === this.commentAnswer.id;
        if (this.highlighted) {
          this.notificationService.currentNotice = null;
        }
      }
    }
  }

  quoteAllIntervieweds(popover) {
    let text = '';
    this.intervieweds.forEach(user => {
      if(!this.form.value.text) {
        text += ' @' + this.replaceAll(user.nickname, ' ', '_') + ' ';
      } else {
        if(!this.form.value.text.includes('@' + this.replaceAll(user.nickname, ' ', '_'))) {
          text += ' @' + this.replaceAll(user.nickname, ' ', '_') + ' ';
        }
      }
    });
    this.textToInject = {
      text
    };
    this.qp.close();
  }

  quoteSelectedIntervieweds(popover) {
    let text = '';
    this.intervieweds.forEach(user => {
      if (user.state.selected) {
        if(!this.form.value.text) {
          text += ' @' + this.replaceAll(user.nickname, ' ', '_') + ' ';
        } else {
          if(!this.form.value.text.includes('@' + this.replaceAll(user.nickname, ' ', '_'))) {
            text += ' @' + this.replaceAll(user.nickname, ' ', '_') + ' ';
          }
        }
      }
    });
    this.textToInject = {
      text
    };
    this.resetIntervieweds();
    this.qp.close();
  }

  checkQuotedIntervieweds() {
    this.resetIntervieweds();
    if(this.form.value.text) {
      this.intervieweds.forEach(user => {
        if(this.form.value.text.includes('@' + this.replaceAll(user.nickname, ' ', '_'))) {
          user.state.selected = true;
        }
      });
    }
  }

  resetIntervieweds() {
    this.intervieweds.forEach(user => {
      if (user.state.selected) {
        user.state.selected = false;
      }
    });
  }

  addMention(nickname: string) {
    this.textToInject = {
      text: ' @' + this.replaceAll(nickname, ' ', '_') + ' '
    }
  }

  hasCheckedIntervieweds() {
    return this.intervieweds.filter(u => u.state.selected).length;
  }

  modifyComment(id: number) {
    this.itemToModify = id;
  }

  saveMods() {
    this.operation('saving').reset().isSubmitting();
    this.answerService.updateComment(this.commentAnswer.id, {
        answerid: this.answer.id,
        comment: this.form.value.text,
        commentid: this.commentAnswer.id,
        flag_like: false
    }).subscribe((res: ResponseModel) => {
        this.commentAnswer.comment = this.form.value.text;
        this.addQuotes(true);
        this.itemToModify = null;
        this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
        this.operation('saving').isSuccess();
    });
  }

  deleteComment(id: number) {
    this.modalService.open({title: 'ARE_YOU_SURE.?',
        btnOkText: 'YES',
        btnCancelText: 'CANCEL',
        showBtnClose: false,
        showCancelBtn: true,
        customClass: 'confirm-modal',
        message: 'ARE_YOU_SURE_DELETE.?'
      }).then((data) => {
        if (data) {
          this.answer.nr_comments--;
          this.operation('deleting').reset().isSubmitting();
          this.answerService.deleteComment(id).subscribe((res: ResponseModel) => {
            this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
            this.deleted = true;
            this.operation('deleting').isSuccess();
          }, (e) => {
            this.toastService.error(this.translate.instant('ERROR_GENERIC'));
            this.operation('deleting').isFailed(e);
          } );
        }
    });
  }

  getDate(d: string): Date { // YYYY-MM-DD HH:MM:SS+01 ?
    return new Date(this.parseCompatibilityDate(d));
  }

  likeAnswer(comment: CommentModel) {
    if (this.teamService.currentUser$.role === 'CUSTOMER_PRO') {
      return false;
    }
    if (comment.currentuser_like_comment) {
      this.answerService.unlikeComment(comment.id, comment.answerid).subscribe((res: ResponseModel) => {
        comment.currentuser_like_comment = false;
        comment.total_like_to_comment--;
      });
    } else {
      this.answerService.likeComment(comment.id, comment.answerid).subscribe((res: ResponseModel) => {
        comment.currentuser_like_comment = true;
        comment.total_like_to_comment++;
      });
    }
  }

  tagsChanged(tags: any) {
    tags = tags.map(tag => tag.name ? tag.name.toLowerCase() : tag.toLowerCase());
    this.answerService.updateComment(this.commentAnswer.id, {
      answerid: this.commentAnswer.answerid,
      commentid: this.commentAnswer.id,
      comment_tags: tags.map(tag => tag.name ? tag.name.toLowerCase() : tag.toLowerCase())
    }).subscribe((res: ResponseModel) => {
      this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));

      // Add any new tag to the list of usable tags
      // todo: usa lo stesso metodo per le personas, che è più efficiente
      tags.forEach(tag => {
        if (!this.moderationHelperService.contentTagList.includes(tag)) {
          this.moderationHelperService.contentTagList.push(tag);
        }
      });
      this.moderationHelperService.contentTagList = [...this.moderationHelperService.contentTagList ];
      // Sort by name
      this.moderationHelperService.contentTagList.sort(function(a,b) {
        if (a > b) {
          return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
      });
      this.commentAnswer.answer_tags = tags;
      this.countryService.updateContentTags(this.countryService.getSessionCountry().country_uniqid)
    }, (e) => {
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
    });
  }

  personasTagsChange(event, justClient = false) {
    this.personasForm.patchValue({
      personas_tags: event ? event.name ? event.name.toLowerCase() : event.toLowerCase() : null
    });
    if(justClient) {
      return this.commentAnswer.team.personas_tags = [this.personasForm.value.personas_tags];
    }
    const operation = this.operation('saving-personas-tags').reset().isSubmitting();
    this.teamsService.addModifyUser(new TeamModel({
      email: this.commentAnswer.team.email,
      /*this.item.team.personas_tags ? this.item.team.personas_tags.map((tag: any) => tag.name ? tag.name : tag) : null,*/
      personas_tags: event ? event.name ? event.name.toLowerCase() !== '' ? [event.name.toLowerCase()] : [] : event.toLowerCase() !== '' ? [event.toLowerCase()] : [] : [],
      role: this.commentAnswer.team.role,
      country_uniqid: this.commentAnswer.country_uniqid
    })).subscribe((res: ResponseModel) => {
      operation.isSuccess();
      this.moderationHelperService.message$.next('personas-list-applied');
      this.moderationHelperService.spreadInOthersAnswersAndComments.next({team: this.commentAnswer.team, personasTag: this.personasForm.value.personas_tags});
      this.commentAnswer.team.personas_tags = res.getData().personas_tags;
      this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));

      this.countryService.updateAnswerPersonasTag(this.commentAnswer.team.id, this.commentAnswer.team.personas_tags)
    }, (e) => {
      operation.isFailed();
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
    });
  }

  notesChanged(value = null) {
    if(value) {
      return this.commentAnswer.team.note = value;
    }
    const operation = this.operation('saving-note').reset().isSubmitting();
    this.teamsService.addModifyUser(new TeamModel({
      email: this.commentAnswer.team.email,
      note: this.commentAnswer.team.note,
      role: this.commentAnswer.team.role,
      country_uniqid: this.commentAnswer.country_uniqid
    })).subscribe((res: ResponseModel) => {
      operation.isSuccess();
      this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
      this.moderationHelperService.spreadInOthersAnswersAndComments.next({what: 'note', team: this.commentAnswer.team, note: this.commentAnswer.team.note});
      this.countryService.updateUsersNotes(this.commentAnswer.team.id,  this.commentAnswer.team.note)

    }, (e) => {
      operation.isFailed();
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
    });
  }

}
