<ng-container *ngIf="emoticon === 1">
    <svg
        [attr.width]="width + 'px'"
        viewBox="0 0 4320 4320"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xml:space="preserve"
        xmlns:serif="http://www.serif.com/"
        style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
        <use xlink:href="#_Image1" x="193" y="183" width="998px" height="1083px" transform="matrix(3,0,0,3,0,0)"/>
        <defs>
            <image id="_Image1" width="998px" height="1083px" [attr.xlink:href]="getData(1)"/>
        </defs>
    </svg>
    <!-- <span>
        :)
    </span> -->
</ng-container>


<ng-container *ngIf="emoticon === 2">
    <svg
        [attr.width]="width + 'px'"
        viewBox="0 0 4320 4320"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xml:space="preserve"
        xmlns:serif="http://www.serif.com/"
        style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
        <use xlink:href="#_Image2" x="193" y="180" width="998px" height="1028px" transform="matrix(3,0,0,3,0,0)"/>
        <defs>
            <image id="_Image2" width="998px" height="1028px" [attr.xlink:href]="getData(2)"/>
        </defs>
    </svg>
    <!-- <span>
        :|
    </span> -->
</ng-container>


<ng-container *ngIf="emoticon === 3">
    <svg
        [attr.width]="width + 'px'"
        viewBox="0 0 4320 4320"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xml:space="preserve"
        xmlns:serif="http://www.serif.com/"
        style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
        <use xlink:href="#_Image3" x="191" y="184" width="998px" height="1064px" transform="matrix(3,0,0,3,0,0)"/>
        <defs>
            <image id="_Image3" width="998px" height="1064px" [attr.xlink:href]="getData(3)"/>
        </defs>
    </svg>
    <!-- <span>
        :(
    </span> -->
</ng-container>
