import { Injectable, OnInit } from '@angular/core';
import { OperationStatusModel } from '../models/operation-status.model';
import { strSlug } from '../helpers/slugify';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';

@Injectable()
export abstract class CoreBaseComponent implements OnInit {

  loading = false;

  HttpRequests: OperationStatusModel[] = [];

  constructor(public activeModal?: NgbActiveModal) {}

  // tslint:disable-next-line: contextual-lifecycle
  ngOnInit() {
    return null;
  }

  formValueExcept(form: UntypedFormGroup, toAvoid?: Array<string> | string) {
    const json = form.getRawValue();
    if (toAvoid) {
      if (!Array.isArray(toAvoid)) {
        toAvoid = [toAvoid];
      }
      toAvoid.forEach(key => {
        delete json[key];
      });
    }
    return json;
  }

  operation(requestSlug: string): OperationStatusModel {
    const requestItem: OperationStatusModel = this.HttpRequests.find((item: OperationStatusModel) => {
        return item.name === strSlug(requestSlug);
    });
    if (requestItem) {
      return requestItem;
    } else {
      const newRequest = new OperationStatusModel(strSlug(requestSlug));
      this.HttpRequests.push(newRequest);
      return newRequest;
    }
  }

  arrayMove(arr, oldIndex, newIndex, formControlArray?: UntypedFormArray) {
    if (formControlArray) {
      const currentGroup = formControlArray.at(oldIndex);
      formControlArray.removeAt(oldIndex);
      formControlArray.insert(newIndex, currentGroup);
    } else {
      if (newIndex >= arr.length) {
        let k = newIndex - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
      }
      arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    }
    return arr ? arr : formControlArray ? formControlArray : null;
  }

  Number(val: string) {
    return Number(val);
  }

  getBytesFromMegabytes(bytes) {
    var marker = 1024; // Change to 1000 if required
    var decimal = 3; // Change as required
    var kiloBytes = marker; // One Kilobyte is 1024 bytes
    var megaBytes = marker * marker; // One MB is 1024 KB
    var gigaBytes = marker * marker * marker; // One GB is 1024 MB
    var teraBytes = marker * marker * marker * marker; // One TB is 1024 GB
/*
    // return bytes if less than a KB
    if(bytes < kiloBytes) return bytes + " Bytes";
    // return KB if less than a MB
    else if(bytes < megaBytes) return(bytes / kiloBytes).toFixed(decimal) + " KB";
    // return MB if less than a GB
    else if(bytes < gigaBytes) return(bytes / megaBytes).toFixed(decimal) + " MB";
    // return GB if less than a TB
    else return(bytes / gigaBytes).toFixed(decimal) + " GB";
    */
   return(bytes / megaBytes).toFixed(decimal) + " MB";
  }

  /**
   * Close the current active modal
   */
  closeModal(data?: any) {
    this.activeModal.close(data ? data : false);
  }

  changeCssVariable(variableName, newValue) {
    document.documentElement.style.setProperty(variableName, newValue);
  }

  // tslint:disable-next-line: member-ordering
  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  })

  compareByPosition(a, b) {
    if ( a.position < b.position ) {
      return -1;
    }
    if ( a.position > b.position ) {
      return 1;
    }
    return 0;
  }

  getDistanceFromCoords(lat1, lon1, lat2, lon2) {
    const R    = 6371; // Radius of the earth in km
    const dLat = this.deg2rad(lat2 - lat1);
    const dLon = this.deg2rad(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  isMobile() {
    let isMobile = false;
    // tslint:disable: max-line-length
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
        isMobile = true;
    }
    return isMobile;
  }

  isString(val) {
    if (typeof val === 'string') {
      return true;
    }
    return false;
  }

  replaceAll(str, search, replace) {
    return str.replace(new RegExp(search, 'g'), replace);
  }

  combineStrings(a, b) {
    var c = "";
    a = Array.from(a);
    b = Array.from(b);
    while(a.length > 0 || b.length > 0){
      if(a.length > 0)
        c += a.splice(0,1);
      if(b.length > 0)
        c += b.splice(0,1);
    }
    return c;
  }

  parseCompatibilityDate(value: string | Date) {
    if (typeof value === 'string') {
      if (value.indexOf('-') !== -1) {
        value = this.replaceAll(value, '-', '/');
      }
      // @ts-ignore
      if (value.indexOf('+') !== -1) {
        value = value.toString();
        value = value.substring(0, value.indexOf('+'));
      }
    }
    return value;
  }

  arrayMatches(arr1, arr2) {
    const ret = [];
    arr1.sort();
    arr2.sort();
    for (const item of arr1) {
      if (arr2.includes(item)) {
        ret.push(item);
      }
    }
    if (ret.length === 0) {
      return false;
    }
    return ret;
  }

  matchArray(arr1, arr2) {
    arr1.sort();
    arr2.sort();
    if (arr1 === arr2) { return true; }
    if (arr1 == null || arr2 == null) { return false; }
    if (arr1.length !== arr2.length) { return false; }

    for (let i = 0; i < arr1.length; ++i) {
      if (arr1[i] !== arr2[i]) { return false; }
    }
    return true;
  }

  getUrlArray() {
    const baseUrl = window.location.href;
    const domain = window.location.hostname;
    const url = baseUrl.substring(baseUrl.indexOf(domain) + domain.length, baseUrl.length);
    return url.split('/');
  }

  downloadFile(res: any, name = 'sicche_download') {
    const dataType = res.type;
    const binaryData = [];
    binaryData.push(res);
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
    downloadLink.setAttribute('download', name);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  getLocalLang(defaultLanguage = 'it', long = false) {
    if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
      return defaultLanguage;
    }
    const wn = window.navigator as any;
    let lang = wn.languages ? wn.languages[0] : defaultLanguage;
    lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;
    if (long) {
      return lang;
    }
    return lang.substring(0, 2);
  }

  ngbDateToJsDate(date) {
    return new Date(`${date.year}/${date.month}/${date.day}`);
  }

  searchSimilarText(text, search) {
    if (search === '') {
      return false;
    }
    text = text.toLowerCase();
    search = search.toLowerCase();
    return text.indexOf(search) !== -1;
  }

  generateUniqid(prefix = '', appendix = false) {
    const c = Date.now() / 1000;
    let d = c.toString(16).split('.').join('');
    while (d.length < 14) { d += '0'; }
    let e = '';
    if (appendix) {
        e = '.';
        e += Math.round(Math.random() * 100000000);
    }
    return prefix + d + e;
  }

  inViewport(ele, container, treshold = 0) {
    if(!ele) {
      return null;
    }
    try {
      const { bottom, height, top } = ele.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();
      return top <= containerRect.top
          ? (containerRect.top - top <= height)
          : ((bottom + treshold) - containerRect.bottom <= height);
      } catch(e) {
        console.error(e);
      }
  }

  // usare solo se indispensabile
  waitSync(milliseconds = 0) {
    const start = Date.now();
    let now = start;
    while (now - start < milliseconds) {
      now = Date.now();
    }
  }


  formatDateByCountryCode(countryCode = 'IT', baseDate) {
    const parsedBaseDate = new Date(this.parseCompatibilityDate(baseDate));

    const dateParts = baseDate.split('/');
    baseDate = `${parsedBaseDate.getFullYear()}/${parsedBaseDate.getMonth() + 1}/${parsedBaseDate.getDate()}`;

    const locale = this.getLocaleDateString(countryCode.toUpperCase());
    let date = baseDate;
    if (typeof baseDate === 'string') {
      date = new Date(this.parseCompatibilityDate(baseDate))
    }
    const replacers = {
      "dd": this.padNumber(date.getDate()),
      "MM": this.padNumber(date.getMonth() + 1),
      "yyyy": date.getFullYear(),
      "d": date.getDate(),
      "M": date.getMonth() + 1,
      "yy": date.getFullYear().toString().substring(2, 4),
    }
    let formattedDate = locale
    formattedDate = formattedDate.replace('dd', replacers['dd']);
    formattedDate = formattedDate.replace('MM', replacers['MM']);
    formattedDate = formattedDate.replace('yyyy', replacers['yyyy']);
    formattedDate = formattedDate.replace('d', replacers['d']);
    formattedDate = formattedDate.replace('M', replacers['M']);
    formattedDate = formattedDate.replace('yy', replacers['yy']);
    return formattedDate;
  }

  getLocaleDateString(countryCode) {
    const formats = {
      "ZA": "yyyy/MM/dd",
      "ET": "d/M/yyyy",
      "AE": "dd/MM/yyyy",
      "BH": "dd/MM/yyyy",
      "DZ": "dd-MM-yyyy",
      "EG": "dd/MM/yyyy",
      "IQ": "dd/MM/yyyy",
      "JO": "dd/MM/yyyy",
      "KW": "dd/MM/yyyy",
      "LB": "dd/MM/yyyy",
      "LY": "dd/MM/yyyy",
      "MA": "dd-MM-yyyy",
      "OM": "dd/MM/yyyy",
      "QA": "dd/MM/yyyy",
      "SA": "dd/MM/yy",
      "SY": "dd/MM/yyyy",
      "TN": "dd-MM-yyyy",
      "YE": "dd/MM/yyyy",
      "CL": "dd-MM-yyyy",
      "IN": "dd-MM-yyyy",
      "AZ": "dd.MM.yyyy",
      "RU": "dd.MM.yy",
      "BY": "dd.MM.yyyy",
      "BG": "dd.M.yyyy",
      "BD": "dd-MM-yy",
      "CN": "yyyy/M/d",
      "FR": "dd/MM/yyyy",
      "BA": "d.M.yyyy",
      "ES": "dd/MM/yyyy",
      "CZ": "d.M.yyyy",
      "GB": "dd/MM/yyyy",
      "DK": "dd-MM-yyyy",
      "AT": "dd.MM.yyyy",
      "CH": "dd.MM.yyyy",
      "DE": "dd.MM.yyyy",
      "LI": "dd.MM.yyyy",
      "LU": "dd.MM.yyyy",
      "MV": "dd/MM/yy",
      "GR": "d/M/yyyy",
      "029": "MM/dd/yyyy",
      "AU": "d/MM/yyyy",
      "BZ": "dd/MM/yyyy",
      "CA": "dd/MM/yyyy",
      "IE": "dd/MM/yyyy",
      "JM": "dd/MM/yyyy",
      "MY": "d/M/yyyy",
      "NZ": "d/MM/yyyy",
      "PH": "M/d/yyyy",
      "SG": "d/M/yyyy",
      "TT": "dd/MM/yyyy",
      "US": "M/d/yyyy",
      "ZW": "M/d/yyyy",
      "AR": "dd/MM/yyyy",
      "BO": "dd/MM/yyyy",
      "CO": "dd/MM/yyyy",
      "CR": "dd/MM/yyyy",
      "DO": "dd/MM/yyyy",
      "EC": "dd/MM/yyyy",
      "GT": "dd/MM/yyyy",
      "HN": "dd/MM/yyyy",
      "MX": "dd/MM/yyyy",
      "NI": "dd/MM/yyyy",
      "PA": "MM/dd/yyyy",
      "PE": "dd/MM/yyyy",
      "PR": "dd/MM/yyyy",
      "PY": "dd/MM/yyyy",
      "SV": "dd/MM/yyyy",
      "UY": "dd/MM/yyyy",
      "VE": "dd/MM/yyyy",
      "EE": "d.MM.yyyy",
      "IR": "MM/dd/yyyy",
      "FI": "d.M.yyyy",
      "FO": "dd-MM-yyyy",
      "BE": "d/MM/yyyy",
      "MC": "dd/MM/yyyy",
      "NL": "d-M-yyyy",
      "NG": "d/M/yyyy",
      "IL": "dd/MM/yyyy",
      "HR": "d.M.yyyy",
      "HU": "yyyy. MM. dd.",
      "AM": "dd.MM.yyyy",
      "ID": "dd/MM/yyyy",
      "IS": "d.M.yyyy",
      "IT": "dd/MM/yyyy",
      "JP": "yyyy/MM/dd",
      "GE": "dd.MM.yyyy",
      "KZ": "dd.MM.yyyy",
      "GL": "dd-MM-yyyy",
      "KH": "yyyy-MM-dd",
      "KR": "yyyy-MM-dd",
      "KG": "dd.MM.yy",
      "LA": "dd/MM/yyyy",
      "LT": "yyyy.MM.dd",
      "LV": "yyyy.MM.dd.",
      "MK": "dd.MM.yyyy",
      "MN": "yy.MM.dd",
      "BN": "dd/MM/yyyy",
      "MT": "dd/MM/yyyy",
      "NO": "dd.MM.yyyy",
      "NP": "M/d/yyyy",
      "PL": "yyyy-MM-dd",
      "AF": "dd/MM/yy",
      "BR": "d/M/yyyy",
      "PT": "dd-MM-yyyy",
      "RO": "dd.MM.yyyy",
      "RW": "M/d/yyyy",
      "SE": "yyyy-MM-dd",
      "LK": "yyyy-MM-dd",
      "SK": "d. M. yyyy",
      "SI": "d.M.yyyy",
      "AL": "yyyy-MM-dd",
      "CS": "d.M.yyyy",
      "ME": "d.M.yyyy",
      "RS": "d.M.yyyy",
      "KE": "M/d/yyyy",
      "TJ": "dd.MM.yy",
      "TH": "d/M/yyyy",
      "TM": "dd.MM.yy",
      "TR": "dd.MM.yyyy",
      "UA": "dd.MM.yyyy",
      "PK": "dd/MM/yyyy",
      "UZ": "dd.MM.yyyy",
      "VN": "dd/MM/yyyy",
      "SN": "dd/MM/yyyy",
      "HK": "d/M/yyyy",
      "MO": "d/M/yyyy",
      "TW": "yyyy/M/d",
    };

    return formats[countryCode] || "dd/MM/yyyy";
  }

  padNumber(number, size = 2) {
    number = number.toString();
    while (number.length < size) number = "0" + number;
    return number;
  }

  getDateWithoutTime(date: Date | string = new Date()) {

    if (typeof date === 'string') {
      date = new Date(date);
    }

    const dateWithoutTimeString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    return new Date(this.parseCompatibilityDate(dateWithoutTimeString));
  }

  convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));
  }

  explicitMediaFileName(url: string): string {
    return url.split('/').pop();
  }

  scrollAndHighlightElement(element: string | HTMLElement) {
    let elem = null;
    // check if is string
    if (typeof element === 'string') {
      elem = document.getElementById(element);
    } else {
      elem = element;
    }

    const anchorDiv = document.createElement('div');
    const randomInt = Math.floor(Math.random() * 1000000);
    anchorDiv.id = 'anchorDiv_' + randomInt;
    anchorDiv.style.position = 'absolute';
    anchorDiv.style.top = '-250px';
    elem.appendChild(anchorDiv)

    setTimeout(() => {
      anchorDiv.scrollIntoView({behavior: 'smooth'});

      elem.classList.add('transition-3');
      elem.classList.add('highlight-animate')
      setTimeout(() => {
        elem.classList.remove('highlight-animate')
        anchorDiv.remove()
      }, 1000)
    }, 200);

  }

  fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  }
  copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(function() {
      console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
  }

  openInNewWindow(url) {
    window.open(url, '_blank');
  }
}
