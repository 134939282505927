import { Injectable } from '@angular/core';
import { ToolbarService } from '@app/core/services/toolbar.service';
import { LogoComponent } from '@app/shared/components/logo/logo.component';
import { ToolbarNotificationsComponent } from '@app/pages/layers/private/toolbar/notifications/toolbar-notifications.component';
import { ToolbarProfileComponent } from '@app/pages/layers/private/toolbar/profile/toolbar-profile.component';
import { ToolbarLogoutComponent } from '@app/pages/layers/private/toolbar/logout/toolbar-logout.component';
import { TeamService } from './team.service';
import { AuthService } from '@app/core/services/auth.service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  public routeEventUrl = null;

  constructor(
    private toolbarService: ToolbarService,
    private teamService: TeamService,
    private authService: AuthService,
    private router: Router
  ) { }

  initToolbar() {
    // Reset toolbar
    this.toolbarService.reset();

    // Setup main components
    this.toolbarService.logo(LogoComponent, {customClass: 'logo-internal'})
    .add({
      component: ToolbarNotificationsComponent,
      label: 'TOOLBAR_NOTIFICATIONS',
      slug: 'toolbar-notification',
      status: 'on',
      hidden: true
    }).add({
      component: ToolbarProfileComponent,
      label: 'MANAGE_ACCOUNT',
      slug: 'toolbar-profile',
      status: 'on'
    }).add({
      component: ToolbarLogoutComponent,
      label: 'LOGOUT',
      slug: 'toolbar-logout',
      status: 'on',
      hidden: false
    }).keepExtended(true);

    // If is customer, hide buttons
    this.authService.authorizedUser$.subscribe((user: any) => {
      if (user) {
        if (user.otherdata) {
          if (user.otherdata.first_role) {
            if (user.otherdata.first_role === 'CUSTOMER_BASIC' || user.otherdata.first_role === 'CUSTOMER_PRO') {
              // this.sidebarService.addVoices([]).sticky(true);
              this.toolbarService.get('toolbar-logout').hide();
              this.toolbarService.get('toolbar-profile').hide();
              this.toolbarService.get('toolbar-notification').hide();
            }
          }
        }
      }
    });

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
          // Show loading indicator
      }
      if (event instanceof NavigationEnd) {
        this.routeEventUrl = event.url;
        // Hide loading indicator
        if (event.url === '/dashboard' || event.url === '/researches' || this.teamService.isCurrentUserCustomer()) {
          this.toolbarService.get('toolbar-notification').hide();
          console.log(event.url === '/researches');
        } else {
          this.toolbarService.get('toolbar-notification').show();
        }
      }
      if (event instanceof NavigationError) {
          // Hide loading indicator
          // Present error to user
          //console.log(event.error);
      }
    });

    // If is customer pro, hide left bar
    this.teamService.user$.subscribe(res => {
      if (this.teamService.currentUser$) {
        if (this.teamService.currentUser$.role === 'CUSTOMER_PRO' || this.teamService.currentUser$.role === 'CUSTOMER_BASIC') {
          this.toolbarService.get('toolbar-logout').hide();
          this.toolbarService.get('toolbar-profile').hide();
          this.toolbarService.get('toolbar-notification').hide();
        }
      }
    });

  }

  changeLogo(customUrl: string) {
    // Reset toolbar
    this.toolbarService.reset();

    // Setup main components
    this.toolbarService.logo(LogoComponent, {customLogoUrl: customUrl, customClass: 'logo-internal'})
    .add({
      component: ToolbarNotificationsComponent,
      label: 'TOOLBAR_NOTIFICATIONS',
      slug: 'toolbar-notification',
      status: 'on',
      hidden: this.teamService.isCurrentUserCustomer() || this.routeEventUrl === '/researches' || this.routeEventUrl === '/dashboard',
    }).add({
      component: ToolbarProfileComponent,
      label: 'MANAGE_ACCOUNT',
      slug: 'toolbar-profile',
      status: 'on'
    }).add({
      component: ToolbarLogoutComponent,
      label: 'LOGOUT',
      slug: 'toolbar-logout',
      status: 'on',
      hidden: false
    }).keepExtended(true);
  }


}
