import { BaseModel } from '@app/core/models/base';
import { CountryModel } from './country.model';

export class ResearchModel extends BaseModel {

    // tslint:disable: variable-name
    current_user_is_owner: boolean;
    id: number;
    creationtime: Date;
    research_uniqid: string;
    title: string;
    date_start: Date;
    date_end: Date;
    date_start_effective: Date;
    date_end_effective: Date;
    status: string;
    researcher_name: string;
    creatoruserid: number;
    nr_interviewed: number;
    nr_effective_days: number;
    notes: string;
    price: number;
    discount_amount: number;
    discount_code: string;
    extra_track_loading: boolean;
    extra_video_editing: boolean;
    extra_set_team: boolean;
    country_codes: string;
    status_local: string;
    countries: CountryModel[];
    role_permission: any;
    is_demo: boolean;
    editable_in_the_past: boolean;

    get setuplink() {
        if (this.countries) {
            return `/researches/navigation/${this.research_uniqid}/${this.countries[0].country_uniqid}/setup`;
        }
    }

    constructor(data?: any) {
        super(data);
        this.assignIstancesByKey(this.countries, CountryModel);
    }

    /**
     * Api address
     */
    static getResourceName() {
        return 'researches';
    }

    getCountryTitle(country_uniqid: string) {
        const country = this.countries.filter(c => c.country_uniqid === country_uniqid);
        if (country[0]) {
            return country[0].title;
        }
        return this.title;
    }

    isRejected() {
        return this.status === 'REJECTED';
    }

    isActive() {
        return this.status === 'ACTIVE';
    }

    isMultiCountry() {
        return this.countries ? this.countries.length > 1 ? true : false : false;
    }

    isPreliminary() {
        return this.status === 'PRELIMINARY';
    }

    isCompleted() {
        return this.status === 'COMPLETED';
    }

    computePrice(): number {
        let total = 0;
        if (!this.nr_effective_days && !this.nr_interviewed) {
            return total;
        }
        const schema = `${this.nr_effective_days}_${this.nr_interviewed}`;
        const pricesPerSchema = {
            '4_25': 600,
            '4_50': 850,
            '4_100': 1100,
            '4_200': 1600,
            '7_25': 800,
            '7_50': 1100,
            '7_100': 1300,
            '7_200': 2000,
            '15_25': 1000,
            '15_50': 1300,
            '15_100': 1600,
            '15_200': 2400,
            '30_25': 1200,
            '30_50': 1600,
            '30_100': 2100,
            '30_200': 3000,
            '90_25': 2000,
            '90_50': 2600,
            '90_100': 3100,
            '90_200': 4700
        };
        total = pricesPerSchema[schema];
        if (this.countries.length === 2) {
            total += ((85 / 100) * total);
        }
        if (this.countries.length === 3) {
            total += ((170 / 100) * total);
        }
        if (this.countries.length === 4) {
            total += ((255 / 100) * total);
        }
        if (this.countries.length === 5) {
            total += ((340 / 100) * total);
        }
        if (this.countries.length === 6) {
            total += ((425 / 100) * total);
        }
        if (this.countries.length === 7) {
            total += ((510 / 100) * total);
        }
        if (this.countries.length === 8) {
            total += ((600 / 100) * total);
        }
        // Extra
        if (this.extra_track_loading) {
            total += (400 * 1/*this.countries.length*/);
        }
        if (this.extra_set_team) {
            total += (300 * 1/*this.countries.length*/);
        }
        if (this.extra_video_editing) {
            total += (400 * 1/*this.countries.length*/);
        }
        return total;
    }

    get startDate() {
        return this.parseCompatibilityDate(this.date_start);
    }

    startDateTimezone(timezone = null) {
        return new Date(
            this.parseCompatibilityDate(this.date_start)
        ).toLocaleDateString('en-US', { timeZone: timezone });
    }

    endDateTimezone(timezone = null) {
        return new Date(
            this.parseCompatibilityDate(this.date_end)
        ).toLocaleDateString('en-US', { timeZone: timezone });
    }


}