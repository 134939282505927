import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { AnswersChartsService } from '../../../../moderation/answers-modals/answers-charts.service';
import * as Highcharts from 'highcharts';
import { QuestionModel } from '@app/shared/models/question.model';
import { AnalysisService } from '@app/shared/services/analysis.service';
import { ResponseModel } from '@app/core/models/response.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { AnswerModel } from '@app/shared/models/answer.model';
import * as html2canvas from 'html2canvas';
import { CountryService } from '@app/shared/services/country.service';
import { CommentModel } from '@app/shared/models/comment.model';
import { ToastrService } from 'ngx-toastr';
import { TeamService } from '@app/shared/services/team.service';
import { QuotesMinimumWords } from '../../../../../../../../../../../setup';
import { AnswersService } from '@app/shared/services/answers.service';
import { AnswersModalSupportService } from '@app/shared/services/support/answers-modal.service';

@Component({
  selector: 'app-overview-modal-stars',
  templateUrl: './stars.component.html',
  styleUrls: ['./stars.component.scss']
})
export class OverviewModalStarsComponent extends CoreBaseComponent implements OnInit {

  @Input()
  type: string;
  @Input()
  countryUniqId = null;
  public filterForm: UntypedFormGroup = null;
  public answers: AnswerModel[] = [];
  public comments = [];
  public commentsNotLinkedToAnswers = [];
  public isSmart = false;
  public contentTags = null;
  imagesToDownload = [];
  private commentsToDownload = [];
  imagesToDownloadCount: number;
  downloading = false;
  elaboratedImages = 0;
  downloadTime = 0;

  public selectedText = null;
  public showSelectionModal = false;
  public quotedAnswer = null;
  public quotedCommentid = null;
  activeQuotes = false;

  constructor(private answersService: AnswersService, private translate: TranslateService,
              private answersChartService: AnswersChartsService, private analysisService: AnalysisService,
              public cdr: ChangeDetectorRef, private countryService: CountryService, private toastService: ToastrService,
              private teamService: TeamService, private answersModalSupportService: AnswersModalSupportService) {
                super();
              }

  ngOnInit(): void {
    this.isSmart = this.countryService.currentCountryIsClientBasic();
    this.countryService.currentContentTag.subscribe(contentTags => {
      this.contentTags = contentTags;
    });
    // Filter form
    this.filterForm = new UntypedFormGroup({
      room: new UntypedFormControl('', null),
      block: new UntypedFormControl('', null),
      question: new UntypedFormControl('', null),
      sample_variable_tags: new UntypedFormControl('', null),
      personas: new UntypedFormControl('', null),
      only_highlighted: new UntypedFormControl('', null),
      keyword_search_content: new UntypedFormControl('', null),
      intervieweds: new UntypedFormControl('', null),
      only_contest: new UntypedFormControl('', null),
      only_live: new UntypedFormControl('', null),
      block_datetime: new UntypedFormControl('', null),
      answer_tags: new UntypedFormControl('', null),
      rating: new UntypedFormControl('', null)
    });
    this.answersModalSupportService.activeQuotes$.subscribe(val => {
      this.activeQuotes = val;
    });
    // this.getChartsByFilter();
  }


  async selectText(event) {
    if (!this.activeQuotes) {
      return false;
    }

    this.selectedText = null;
    if (window.getSelection) {
      this.selectedText = window.getSelection().toString();
    }
    if (event.answer) {
      this.quotedAnswer = event.answer
    }
    if (event.commentid) {
      this.quotedCommentid = event.commentid
      const comment = this.comments.find(c => c.id === this.quotedCommentid);
      this.quotedAnswer = await this.answersService.getAnswerByComment(comment);
    }
    // Replace non-breaking-space chars TRICK
    this.selectedText += "";
    var temp = document.createElement('div'), str;
    temp.innerHTML = this.selectedText;
    //@ts-ignore
    str = temp.innerText || temp.textContent || temp.text;
    if(!str) {
      return false;
    }
    var normalizedString = str.split(/\s/);
    temp = null;
    // End replacing non-breaking-space chars TRICK
    if (this.selectedText) {
      const size = normalizedString.length;
      if(size >= QuotesMinimumWords) {
        this.showSelectionModal = true;
      }
    } else {
      this.showSelectionModal = false;
    }
  }


  quotesModalClose() {
    this.showSelectionModal = false;
    this.getStarsByFilter(true);
  }


  hasSelected() {
    const foundAnswersSelected = this.answers.filter((answer: any) => answer.state.selected === true);
    const foundCommentsSelected = this.comments.filter((comment: any) => comment.state.selected === true);
    if (!foundAnswersSelected && !foundCommentsSelected) {
      return 0;
    } else {
      return foundAnswersSelected.length + foundCommentsSelected.length;
    }
  }

  selectAll(event) {
    this.comments.map(comment => {
      comment.state.selected = event.target.checked ? true : false;
    });
    this.answers.map(answer => {
      answer.state.selected = event.target.checked ? true : false;
    });
  }


  downloadSelected() {
    const commentsIdsToDownload = [];
    // Comment
    for (const comment of this.comments) {
      if (comment.state.selected) {
        commentsIdsToDownload.push(comment.id);
      }
    }

    // mi serve un array ordinato come a fe con sia commenti che answer
    const arr = [];
    for (const answer of this.answers) {
      if (answer.state.selected) {
        arr.push({
          id: answer.id,
          isAnswer: true
        })
        for (const comment of answer.commentsB) {
          if (commentsIdsToDownload.includes(comment.id)) {
            arr.push({
              id: comment.id,
              isAnswer: false
            })
          }
        }
      }
    }
    for (const comment of this.comments) {
      if (comment.state.selected && !arr.find(e => e.id == comment.id && e.isAnswer == false)) {
        arr.push({
          id: comment.id,
          isAnswer: false
        })
      }
    }

    if (arr.length  > 0) {
      this.downloading = true;

      this.analysisService.getPostExport(this.countryUniqId, arr).subscribe((res: ResponseModel) => {
        this.downloading = false;
        this.toastService.success(this.translate.instant('PDF_EXPORT_ALL_EMAIL_SENT', {email: this.teamService.currentUser.email}), null, {timeOut: 10000});
      })
    }
  }

  getXlsExport() {
    this.downloading = true;

    const answerIds = this.answers.filter(a => a.state.selected).map(a => a.id.toString());
    const commentIds = this.comments.filter(c => c.state.selected).map(c => c.id.toString());

    this.analysisService.getPostXlsExport(this.countryUniqId, answerIds, commentIds, 'selected_content').subscribe((res: ResponseModel) => {
      this.downloading = false;
      this.toastService.success(this.translate.instant('PDF_EXPORT_ALL_EMAIL_SENT', {email: this.teamService.currentUser.email}), null, {timeOut: 10000});
    })
  }

  async downloadScreenShot(answerids: number[] | string[], commentids = []) {
    this.imagesToDownloadCount = answerids.length + commentids.length;
    this.downloading = true;
    this.downloadTime = (answerids.length + commentids.length) * 2;
    setTimeout(async () => {
      for (const answerid of answerids) {
        await this.takeScreenshot(answerid, 'answer-exportable', false);
        this.elaboratedImages++;
        this.waitSync(500);
      }
      for (const commentid of commentids) {
        await this.takeScreenshot(commentid, 'answer-block-comment-exportable', false);
        this.elaboratedImages++;
        this.waitSync(500);
      }
      this.downloading = false;
      this.elaboratedImages = 0;
    }, 1000);
  }

  async takeScreenshot(itemId, type, instantDownload = false) {
    const element = document.getElementById(type + '-' + itemId);
    element.style.display = 'block';
    // @ts-ignore
    // tslint:disable-next-line: max-line-length
    const canvas = await html2canvas(element, { letterRendering: 1, allowTaint : true, useCORS: true})

    if (instantDownload) {
      this.downloadFile(canvas.toDataURL('image/png'), type + '-' + itemId + '.png');
    } else {
      const imageData = canvas.toDataURL('image/png');
      if(type === 'answer-exportable') {
        this.imagesToDownload = [...this.imagesToDownload, {id: itemId, image: imageData}];
      } else {
        this.commentsToDownload = [...this.commentsToDownload, {id: itemId, image: imageData}];
      }
      if (this.imagesToDownload.length + this.commentsToDownload.length === this.imagesToDownloadCount) {
        this.exportAnswerImages();
      }
    }
  }

  exportAnswerImages() {
    this.analysisService.getAnswersExport(
      this.countryUniqId,
      this.imagesToDownload,
      this.commentsToDownload
    )
      .subscribe((res: ResponseModel) => {
      res.download('answers.zip');
    });
  }


  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  getStarsByFilter(scrollToLastQuote = null) {
    let date = '';
    if (this.filterForm.value.block_datetime) {
      date = this.formatDate(this.filterForm.value.block_datetime.date);
    }
    this.operation('getting-answers').reset().isSubmitting();
    const all_rooms = this.filterForm.value.room ? this.filterForm.value.room.length == 1 && this.filterForm.value.room[0].id == 0 ? true : false : false;  // todo mod
    const filterDate = {
      include_quote: true,
      all_rooms,
      only_highlighted: this.filterForm.value.only_highlighted,
      only_contest: this.filterForm.value.only_contest,
      only_live: this.filterForm.value.only_live,
      keyword_search_content: this.filterForm.value.keyword_search_content,
      intervieweds: this.filterForm.value.intervieweds,
      stars_only: true,
      rating: this.filterForm.value.rating,
      order_by_block: true,
      questionid: this.filterForm.value.question ? this.filterForm.value.question.id : null,
      block_datetime: date,
      'roomids_by_comma': Array.isArray(this.filterForm.value.room) ? this.filterForm.value.room.length ? this.filterForm.value.room.map(item => item.id) : null : null,
      'blockids_by_comma': this.filterForm.value.block ? this.filterForm.value.block.map(item => item.id) : null,
      'answer_tags_by_comma': this.filterForm.value.answer_tags ? this.filterForm.value.answer_tags : null,
      'team_personas_tags_by_comma': this.filterForm.value.personas ? this.filterForm.value.personas : null,
      'team_sample_variable_tags_by_comma': this.filterForm.value.sample_variable_tags ? this.filterForm.value.sample_variable_tags : null
    };
    Object.keys(filterDate).forEach(key => {
      if (!filterDate[key] || filterDate[key] == '') {
        delete filterDate[key];
      }
    });
    // this.operation('getting-answers').reset().isSubmitting();
    this.analysisService.getAnswersBy(this.countryUniqId, filterDate)
    .subscribe((res: ResponseModel) => {
      this.operation('getting-answers').isSuccess();

      // 20210709 ordino i post prima per answer.question_position poi per blockid poi per data, così sono ordinati per data e raggruppati per blocco
      const tempAnswers = res.getData().answers ? res.getData().answers : [];
      tempAnswers.sort((a,b) => (a.question_position > b.question_position) ? 1 : ((b.question_position > a.question_position) ? -1 : 0))
      tempAnswers.sort((a,b) => (a.blockid > b.blockid) ? 1 : ((b.blockid > a.blockid) ? -1 : 0))
      tempAnswers.sort((a,b) => (a.block_datetime_start > b.block_datetime_start) ? 1 : ((b.block_datetime_start > a.block_datetime_start) ? -1 : 0))
      this.answers = tempAnswers

      const tempComments = res.getData().comments ? res.getData().comments : [];
      tempComments.sort((a,b) => (a.question_position > b.question_position) ? 1 : ((b.question_position > a.question_position) ? -1 : 0))
      tempComments.sort((a,b) => (a.blockid > b.blockid) ? 1 : ((b.blockid > a.blockid) ? -1 : 0))
      tempComments.sort((a,b) => (a.block_datetime_start > b.block_datetime_start) ? 1 : ((b.block_datetime_start > a.block_datetime_start) ? -1 : 0))
      this.comments = tempComments

      this.comments.forEach((comment, index) => {
        this.comments[index] = new CommentModel(comment);
      });
      if(this.answers.length) {
        this.answers.forEach(answer => {
          // @ts-ignore
          answer.state = {selected: false}
          // @ts-ignore
          answer.commentsB = this.getCommentsOfAnswer(answer);
        });
      }
      this.commentsNotLinkedToAnswers = this.getUnlinkedComments();

      if (scrollToLastQuote) {
        setTimeout(() => {
          if (this.quotedCommentid) {
            this.scrollAndHighlightElement(`answer-block-comment-${this.quotedCommentid}`);
          } else {
            this.scrollAndHighlightElement(`answer-${this.quotedAnswer?.id}-short`);
          }
        });
      }
    });
  }

  getUnlinkedComments() {
    const output = [];
    for (const comment of this.comments) {
      if (!comment.answerid || !this.answers.find(a => a.id === comment.answerid)) {
        output.push(comment);
      }
    }
    return output;
  }

  getCommentsOfAnswer(answer: AnswerModel) {
    let output = [];
    if (answer) {
      output = this.comments.filter(c => c.answerid === answer.id)
    }
    return output;
  }

  changeAnswerStatus(event: any, answer: AnswerModel) {
    // @ts-ignore
    answer.state.selected = event.target.checked;
  }

}
