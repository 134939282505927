import { Component, OnInit, Input, ViewChild,
  ViewContainerRef, ComponentFactoryResolver, ComponentFactory, ElementRef,
  OnDestroy, ChangeDetectorRef, AfterViewChecked, AfterViewInit } from '@angular/core';
import { CoreLayoutService } from '../../services/layout.service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';

/**
 * Sidebar
 * Note: components to render must be declared as EntryComponentsù
 *
 * --------------------------------Example with sidebar
 * <app-core-layout
 *  [sidebar]="true">
 *   <div slot="with-sidebar">
 *     <router-outlet *ngIf="initialized"></router-outlet>
 *   </div>
 * </app-core-layout>
 */

@Component({
  selector: 'app-core-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input()
  sidebar = false;
  @ViewChild('componentContainer', { read: ViewContainerRef }) componentContainer: ViewContainerRef;
  componentRef: any;

  public sidebarOpen = false;
  private active = false;
  private currentComponent: any = null;

  // todo
  public modeNum = 0;
  public positionNum = 0;
  public dock = false;
  public closeOnClickOutside = true;
  public closeOnClickBackdrop = true;
  public showBackdrop = true;
  public trapFocus = true;
  public autoFocus = true;
  public keyClose = false;
  public autoCollapseHeight: number = null;
  public autoCollapseWidth: number = null;
  public MODES: Array<string> = ['over', 'push', 'slide'];
  public POSITIONS: Array<string> = ['left', 'right', 'top', 'bottom'];

  constructor(
      public coreLayoutService: CoreLayoutService,
      private cd: ChangeDetectorRef,
      private authService: AuthService,
      public router: Router, private resolver: ComponentFactoryResolver) { }

  ngOnInit() {
      this.coreLayoutService.sidebarOpen$.subscribe(isOpen => {
          this.sidebarOpen = isOpen;
          this.pauseMedia();
      });

      this.coreLayoutService.sidebarOnClosed$.subscribe(isClosed => {
        this.pauseMedia();
      });

      this.coreLayoutService.sidebarParams$.subscribe(params => {
        // console.log("LAYOUT-COMP, params:", params);
        if (this.active && this.currentComponent && this.coreLayoutService.sidebarIsOpen()) {
          this.renderComponent(this.currentComponent);
        }
      });

      this.coreLayoutService.componentToRender$.subscribe(component => {
        if (component) {
          this.renderComponent(component);
          this.active = true;
        }
      });
  }

  /**
   * Pause every media playing
   * ((optimization: pause only in the modal))
   */
  pauseMedia() {
    let videos = document.querySelectorAll('video');
    videos.forEach(video => {
      video.pause();
    });
    let audios = document.querySelectorAll('audio');
    audios.forEach(audio => {
      audio.pause();
    });
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  forceLogout() {
    this.authService.logout('/login', true)
    .subscribe(res => {
      // no action
    });
  }

  renderComponent(component: any) {
    setTimeout(() => {
      this.currentComponent = component;
      if (this.componentContainer) {
        this.componentContainer.clear();
      }
      const factory = this.resolver.resolveComponentFactory(component);
      // const viewContainerRef = this.componentContainer.viewContainerRef;
      this.componentRef = this.componentContainer.createComponent(factory);
      // Params
      if (this.coreLayoutService.sidebarParams$.value) {
        Object.keys(this.coreLayoutService.sidebarParams$.value).forEach(key => {
          this.componentRef.instance[key] = this.coreLayoutService.sidebarParams$.value[key];
        });
      }
    });
  }

  exe(func) {
    if (func) {
      func();
    }
  }

  onSidebarClose() {
    if (this.coreLayoutService.sidebarIsOpen()) {
      this.coreLayoutService.sidebarClosed(true);
      this.currentComponent = null;
      this.coreLayoutService.buttons(null);
    }
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

}
