import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { RoomsService } from '@app/shared/services/rooms.service';
import { RoomModel } from '@app/shared/models/room.model';
import { ResponseModel } from '@app/core/models/response.model';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BlockService } from '@app/shared/services/block.service';
import { BlockModel } from '@app/shared/models/block.model';
import { QuestionsService } from '@app/shared/services/questions.service';
import { QuestionModel } from '@app/shared/models/question.model';
import { CountryService } from '@app/shared/services/country.service';
import { AnalysisService } from '@app/shared/services/analysis.service';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { AnswerModel } from '@app/shared/models/answer.model';
import { ResearchSessionService } from '@app/shared/services/research-session.service';
import { DatetimeToDbPipe } from '@app/core/pipes/datetime-to-db.pipe';
import { ActivatedRoute } from '@angular/router';
import { DateToLocaleStringPipe } from '@app/core/pipes/date-to-locale-string.pipe';
import { ToastrService } from 'ngx-toastr';
import { TeamModel } from '@app/shared/models/team.model';
import { TeamService } from '@app/shared/services/team.service';
import * as angular from 'angular';


@Component({
  selector: 'app-overview-modal-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class OverviewModalFilterComponent extends CoreBaseComponent implements OnInit {

  @Input()
  countryUniqId = null;
  @Input()
  color = false;
  @Input()
  star = false;
  @Input()
  contentTagsFilter = true;
  @Input()
  highlighted = false;
  @Output()
  searchFn: EventEmitter<any> = new EventEmitter();
  @Input()
  filterForm: UntypedFormGroup = null;
  public daysReady = false;
  public rooms: RoomModel[] = [];
  public sampleVariableTags: Array<any> = [];
  public personasTags: Array<any> = [];
  public contentTags: Array<any> = [];
  public blocks: BlockModel[] = [];
  public allBlocks: BlockModel[] = [];
  public questions: QuestionModel[] = [];
  public allQuestions: QuestionModel[] = [];
  public intervieweds: TeamModel[] = [];
  public days = [];
  public minDataToLoad = 2;
  public loadingfilter = true;
  // Smart config
  public fieldLoadedCounter = 0;
  public loadingProgress = {
    total: 0,
    rooms: false,
    days: false,
    blocks: false,
    questions: false,
    tags: false
  }
  public smartSetup = null;
  public isSmart = false;
  public blocked = {
    question: false,
    days: false,
    blocks: false,
    rooms: false,
    sample_variable_tags: false
  };

  constructor(private coreLayoutService: CoreLayoutService, private translate: TranslateService, private roomsService: RoomsService,
              private analysisService: AnalysisService, private researchSessionService: ResearchSessionService, private cdr: ChangeDetectorRef,
              private dateTimeToDb: DatetimeToDbPipe, private activatedRoute: ActivatedRoute,
              private dateToEuPipe: DateToLocaleStringPipe, private blocksService: BlockService,
              private questionsService: QuestionsService, private countryService: CountryService,
              private dateToLocaleString: DateToLocaleStringPipe, private toastService: ToastrService,
              private teamService: TeamService) {
                  super();
              }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  initSearch(callback = null) {
    if (this.isSmart) {
      this.analysisService.getSmartConfig(this.countryUniqId)
      .subscribe((res: ResponseModel) => {
        this.smartSetup = res.getData();
        // Preselect team_sample_variable_tags
        if (res.getData().sample_variable_tags.length) {
          this.filterForm.patchValue({
            sample_variable_tags: res.getData().sample_variable_tags
          });
          this.blocked.sample_variable_tags = true;
        }
        // this.loadProgress('tags');
        if (res.getData().blockids.length) {
          this.minDataToLoad++;
        }
        if (res.getData().questionids.length) {
          this.minDataToLoad++;
        }
        if (res.getData().roomids.length) {
          this.minDataToLoad++;
        }
        this.startSetup();
        if(callback) {
          callback();
        }
      });
    } else {
      this.startSetup();
      if(callback) {
        callback();
      }
      // this.search(); // !tolto, altrimenti eseguiva la ricerca 2 volte
    }
  }

  private startSetup() {
    this.loading = false;
    this.countryService.getPersonasTags(this.countryUniqId).subscribe((res: ResponseModel) => {
      this.personasTags = res.getData();
    });
    // Get variabili campione & tag di contenuto
    this.countryService.getCountrySetupInfo(this.countryUniqId).subscribe((res: ResponseModel) => {
        this.sampleVariableTags = res.getData().sample_variable_tags.filter(tag => tag ? true : false);
        this.contentTags = res.getData().answer_tags ? res.getData().answer_tags.filter(tag => tag ? true : false) : [];
        // Order by initial letter
        this.contentTags.sort((a, b) => {
          if (a < b) { return -1; }
          if (a > b) { return 1; }
          return 0;
        });
    });
  }

  private makeAndPreselectAllRooms() {
    const room = new RoomModel({id: 0, title: this.translate.instant('ROOM.ALL_ROOMS')});
    this.rooms.unshift(room);
    if(!this.smartPreselect('room')) {
      this.filterForm.patchValue({room: [room]});
    }
  }

  ngOnInit(): void {
    this.isSmart = this.countryService.currentCountryIsClientBasic();
    this.filterForm.addControl('keyword_search_content', new UntypedFormControl('', null));
    this.initSearch(() => {
      // Get intervieweds
      this.teamService.getUsers(
        {
          country_uniqid: this.countryUniqId,
          role: 'INTERVIEWED'
        }
      ).subscribe((res: ResponseModel) => {
        const temp = res.getData().map(user => {
          if (!user.nickname) {
            if (user.firstname || user.lastname) {
              user.nickname = user.firstname + ' ' + user.lastname
            } else {
              user.nickname = user.email
            }
          }
          return user
        })
        this.intervieweds = temp.sort((a,b) => (a.nickname.toLowerCase() > b.nickname.toLowerCase()) ? 1 : ((b.nickname.toLowerCase() > a.nickname.toLowerCase()) ? -1 : 0))

      })

      // Get rooms
      this.roomsService.getRoomsByCountryUniqid(this.countryUniqId).subscribe((res: ResponseModel) => {
        this.rooms = res.getData();
        this.makeAndPreselectAllRooms();
        // Get blocks
        this.blocksService.getBlocksByCountryId(this.countryUniqId, false)
        .subscribe((res: ResponseModel) => {
          this.allBlocks = res.getData(false, (item) => {
            return item.title = this.rooms.find(room => room.id === item.roomid).title + ' - ' + (item.title ? item.title.replace(/<[^>]+>/g, '') : '');
          });
          const tempBlocks = this.allBlocks.slice(0);

          tempBlocks.sort((a,b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))
          tempBlocks.sort((a,b) => (a.roomid > b.roomid) ? 1 : ((b.roomid > a.roomid) ? -1 : 0))
          this.blocks = tempBlocks;
          // Get days
          this.getDays();
          this.smartPreselect('day');
          // Blocks
          this.filterBlocks();
          this.smartPreselect('block');
          // Get questions
          this.questionsService.getQuestionsByRoomid(null, false, false, null, this.countryUniqId)
          .subscribe((res: ResponseModel) => {
            this.allQuestions = res.getData(false, (item) => {
              return item.title = item.room_title + ' - ' + item.title.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ');
            });
            this.orderQuestions(this.allQuestions, this.allBlocks, this.rooms);
            this.filterQuestions();
            this.smartPreselect('questions');
            this.search();
          });
        });
      });
    });
  }


  private smartPreselect(what: string) {
    if (this.smartSetup && this.isSmart) {
      switch(what) {
        case 'room':
          if (this.smartSetup.all_rooms || this.smartSetup.roomids.length) {
            // Set room
            if(!this.smartSetup.all_rooms) {
              let setupRooms = [];
              this.rooms.forEach(room => {
                this.smartSetup.roomids.forEach(setupRoomId => {
                  if(room.id === setupRoomId) {
                    setupRooms.push(room);
                  }
                });
              });
              this.filterForm.patchValue({
                room: setupRooms
              });
            } else {
              this.filterForm.patchValue({room: [this.rooms[0]]});
            }
            this.blocked.rooms = true;
            return true;
          } else {
            return false;
          }
          case 'questions':
            if (this.smartSetup.questionids) {
              if (this.smartSetup.questionids.length) {
                const foundQuestion = this.allQuestions.find(item => this.smartSetup.questionids.includes(item.id));
                if(!foundQuestion) {
                  return false;
                }
                this.filterForm.patchValue({
                  question: foundQuestion
                });
                this.blocked.question = true;
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          case 'day':
            if (this.smartSetup.days) {
              if (this.smartSetup.days.length) {
                this.days.forEach(day => {
                  if (this.smartSetup.days[0] === String(this.formatDate(day.date))) {
                    this.filterForm.patchValue({
                      block_datetime: day
                    });
                    this.blocked.days = true;
                    this.dayChange(this.filterForm.value.block_datetime, null, false);
                    return true;
                  }
                });
              }
            }
            return false;
          case 'block':
            if (this.smartSetup.blockids.length) {
              const block = this.allBlocks.find(item => this.smartSetup.blockids.includes(item.id));
              if (!block) {
                return false;
              }
              this.filterForm.patchValue({
                block: [this.allBlocks.find(item => this.smartSetup.blockids.includes(item.id))]
              });
              this.blocked.blocks = true;
              return true;
            } else {
              return false;
            }
      }
    } else {
      return false;
    }
  }

  private orderQuestions(questions: Array<QuestionModel>, blocks: Array<BlockModel>, rooms: Array<RoomModel>) {
    // Get days
    questions.forEach((question: QuestionModel, index: number) => {
      let block: BlockModel = blocks.find((block: BlockModel) => {
        return block.id === question.blockid;
      });
      question.extension = {
        day: block.datetime_start,
        blockid: block.id,
        block_title: block.title
      };
    });
    // Order by day
    // @ts-ignore
    questions = questions.sort((questionA: QuestionModel, questionB: QuestionModel) => new Date(questionA.block_datetime_start) - new Date(questionB.block_datetime_start));
    questions = questions.sort((questionA: QuestionModel, questionB: QuestionModel) => {
      if(new Date(questionA.block_datetime_start) > new Date(questionB.block_datetime_start)) return 1;
      if(new Date(questionA.block_datetime_start) < new Date(questionB.block_datetime_start)) return -1;

      if(questionA.extension.blockid > questionB.extension.blockid) return 1
      if(questionA.extension.blockid < questionB.extension.blockid) return -1;

      if(questionA.position > questionB.position) return 1
      if(questionA.position < questionB.position) return -1;
    });
  }

  search() {
    this.loadingfilter = false;
    this.searchFn.emit(null);
  }

  rated(value: number) {
    if (value === this.filterForm.value.rating) {
      value = null;
    }
    this.filterForm.patchValue({
      rating: value
    });
    this.search();
  }

  clearFilter(name: string) {
    this.filterForm.controls[name].patchValue('');
    this.getAnswersByFilter();
  }

  private blockDateIsCorrect(block: BlockModel) {
    if (!this.filterForm.value.block_datetime) {
      return true;
    }
    if (!this.filterForm.value.block_datetime.date) {
      return true;
    }
    if (this.dateToEuPipe.transform(new Date(this.parseCompatibilityDate(block.datetime_start)).toString())
      === this.dateToEuPipe.transform(this.parseCompatibilityDate(this.filterForm.value.block_datetime.date).toString())) {
        return true;
    }
    return false;
  }

  private filterBlocks() {
    let tempBlocks = [];
    // By room
    if(!this.filterForm.value.room || !this.filterForm.value.room.length) {
      return tempBlocks = this.allBlocks.slice(0);
    } else {
      if(this.filterForm.value.room.length && !this.allRooms()) {
        this.allBlocks.forEach((block: BlockModel) => {
          if (this.roomIsSelected(block.roomid)) {
            if (this.blockDateIsCorrect(block)) {
              tempBlocks.push(block);
            }
          }
        });
      } else {
        if(this.filterForm.value.block_datetime) {
          this.allBlocks.forEach((block: BlockModel) => {
            if (this.blockDateIsCorrect(block)) {
              tempBlocks.push(block);
            }
          });
        } else {
          tempBlocks = this.allBlocks.slice(0);
        }
      }
    }
    tempBlocks.sort((a,b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))
    tempBlocks.sort((a,b) => (a.roomid > b.roomid) ? 1 : ((b.roomid > a.roomid) ? -1 : 0))
    this.blocks = tempBlocks;
  }

  private allRooms() {
    let test = false;
    this.filterForm.value.room.forEach((room: RoomModel) => {
      if(0 === room.id) {
        test = true;
      }
    });
    return test;
  }

  private roomIsSelected(roomIdToFind: number) {
    let test = false;
    this.filterForm.value.room.forEach((room: RoomModel) => {
      if(roomIdToFind === room.id) {
        test = true;
      }
    });
    return test;
  }

  private filterQuestions() {
    this.questions = [];
    // Filter by room
    if(this.filterForm.value.room.length && !this.allRooms()) {
      this.allQuestions.forEach((question: QuestionModel) => {
        if (this.roomIsSelected(question.roomid)) {
          this.questions.push(question);
        }
      });
    } else {
      this.questions = this.allQuestions.slice(0);
    }
    // Filter by block
    if(this.filterForm.value.block && this.filterForm.value.block.length) {
      this.questions = this.questions.filter((question: QuestionModel) => {
        let result = false;
        this.filterForm.value.block.forEach((block: BlockModel) => {
          if(question.blockid == block.id) {
            result = true;
          }
        });
        return result;
      });
    } else {
      if(this.filterForm.value.block_datetime) {
        this.questions = this.questions.filter((question: QuestionModel) => {
          let result = false;
          if(this.questionIsInSelectedDay(question)) {
            result = true;
          }
          return result;
        });
      }
    }
  }

  private questionIsInSelectedDay(question: QuestionModel) {
    let bool = false;
    let block = this.allBlocks.find((block: BlockModel) => {
      return block.id === question.blockid;
    });
    if(block) {
      if(this.blockDateIsCorrect(block)) {
        bool = true;
      }
    }
    return bool;
  }

  public roomChange(event, callback = null, allRooms = false) {
    this.filterForm.controls['block'].patchValue('');
    if(this.filterForm.value.room.length >= 2 && this.filterForm.value.room[0].id == 0) {
      this.filterForm.patchValue({
        room: this.filterForm.value.room.filter(room => room.id != 0)
      })
    } else if(this.filterForm.value.room.length >= 2 && this.filterForm.value.room[this.filterForm.value.room.length - 1].id == 0) {
      this.filterForm.patchValue({
        room: [this.filterForm.value.room[this.filterForm.value.room.length - 1]
      ]
      })
    }
    this.filterForm.patchValue({
      block_datetime: null
    });
    this.filterBlocks();
    this.filterQuestions();
    if (callback) {
      callback();
    } else {
      this.getAnswersByFilter();
    }
  }

  public blockChange(event, callback = null, questionsLoaded = null) {
    this.filterForm.controls['question'].patchValue('');
    // If no block selected
    if (!this.filterForm.value.block) {
      this.questions = [];
      this.questionSelected(null);
      this.getAnswersByFilter();
      this.filterQuestions();
      if (callback) {
        callback();
      }
      return false;
    }
    this.filterQuestions();
    if (callback) {
      callback();
    } else {
      this.getAnswersByFilter();
    }
  }

  getDays(forceAll = false) {
    this.countryService.getActiveDays(this.countryUniqId).subscribe(response => {
      const data = [];
      response.getData().forEach((date, index) => {
        const dateItem = {
          date,
          name: `${this.translate.instant('DAY')} ${index + 1} - ${this.dateToLocaleString.transform(date)}`
        };
        data.push(dateItem);
      });
      this.days = data;
      setTimeout(() => {
        this.daysReady = true;
      }, 500);
    });
  }

  dayIsActive(dateItem: {date, name: string}) {
    if (dateItem.date) {
      if (this.allBlocks.length) {
        for (const block of this.allBlocks) {
          if (this.dateToEuPipe.transform(new Date(this.parseCompatibilityDate(block.datetime_start)).toString())
            === this.dateToEuPipe.transform(dateItem.date.toString())) {
            return true;
          }
        }
      }
    }
    return false;
  }

  dayChange(event, callback = null, startSearch = true) {
    this.filterForm.controls['question'].patchValue('');
    this.filterForm.controls['block'].patchValue('');
    this.questions = [];
    this.filterForm.patchValue({
      block: null
    });
    this.filterBlocks();
    this.filterQuestions();
    if (callback) {
      callback();
    } else {
      if(startSearch) {
        this.search();
      }
    }
  }

  roomsSelectedIsRight(block: BlockModel) {
    if (!this.filterForm.value.room) {
      return true;
    } else {
      if (this.filterForm.value.room.id === block.roomid || this.filterForm.value.room.id === 0 /** all rooms */) {
        return true;
      }
    }
    return false;
  }

  personasTagsChange(event) {
    this.search();
  }

  colorChange() {
    this.search();
  }

  sampleTagsChange(event) {
    this.search();
  }

  public questionSelected(event) {
    this.search();
  }

  getAnswersByFilter() {
   this.search();
  }

  contentTagsChange(event?: any) {
    this.search();
  }

  toggleChange(event?: any) {
    this.search();
  }

  interviewedsToggleChange(intervieweds?: any) {
    const temp = angular.copy(intervieweds)
    this.filterForm.controls.intervieweds.setValue(temp.map(int => int.userid).join(','))
    this.search();
  }

  keywordToggleChange(event?: any) {
    const text = event.target.value;
    if (text.length < 3 && text.length !== 0) {
      this.toastService.error(this.translate.instant('MINIMUM_N_CHARS', {n: 3}))
      return
    }
    console.log(this.filterForm.controls)
    this.filterForm.controls.keyword_search_content.setValue(text);
    this.search();
  }

}
