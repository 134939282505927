<h3 *ngIf="teamService.isCurrentUserCustomerPro()">
    {{'ANALYSIS_CUSTOMER_PRO.HEADER' | translate}}
</h3>
<section class="mb-5">
    <div class="day-select-wrapper shadow w-100">
        <div class="center w-75 mx-auto">
            <div class="d-flex align-items-center justify-content-center">
                <h4 class="mb-0 mr-2">{{ 'WHICH_DAY_YOU_WANTTO_MODERATE' |  translate  }}</h4>
                <div>
                    <div class="form-group mb-0">
                        <app-core-select
                            [classes]="'form-control'"
                            [placeholder]="'DAY'"
                            [cache]="true"
                            [form]="formTime"
                            [formEntityName]="'date'"
                            [searchable]="true"
                            [customList]="days"
                            [value]="'date'"
                            [label]="'name'"
                            [empty]="{date: null, name: '---'}"
                            (change)="changeDate($event)">
                        </app-core-select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="tabs">
        <div class="text-center mb-2">{{'SELECT_ROOM' | translate}}</div>
        <app-core-spinner class="mt-6" [toggle]="operation('loading-rooms').submitting"></app-core-spinner>
        <ul class="nav nav-pills mx-auto w-fit-content" *ngIf="rooms.length">
            <li *ngFor="let room of rooms" [ngClass]="{'active': selectedRoomId == room.id}" class="nav-item" (click)="roomSelected(room.id)">
              <a class="nav-link">{{ room.title }}</a>
            </li>
          </ul>
    </div>
</section>
<app-core-spinner class="mt-6" [toggle]="operation('loading-blocks').submitting"></app-core-spinner>

<ng-container *ngIf="!loadingBlocks">
    <section id="question-cards" class="mt-5 mb-0" *ngIf="activeBlocks.length && !operation('loading-blocks').submitting">
        <div *ngIf="!activeBlocks.length && !operation('loading-blocks').submitting">
            {{ 'THERE_ARE_NO_BLOCKS' | translate }}
        </div>
        <div class="temp-card-wrapper">
            <ng-template class="temp-wrapper" #cardsTemplate>
                <div [flickityChild] class="drag-card" *ngFor="let block of activeBlocks"  [ngClass]="{'block-active': selectedBlockId == (block ? block.id : null)}" (click)="blockSelected(block)">
                    <span *ngIf="block">
                        <div *ngIf="!block.image_url" class="header" style="background-image:url('./assets/images/sfondo_sicche_blocco.jpg')">
                        </div>
                        <div *ngIf="block.image_url" class="header" [ngStyle]="{'background-image': 'url(' + (block.image_url | privatesrc) + ')'}">
                        </div>
                        <div class="card-body">
                            <h4 class="card-title">{{ block.title ? block.title : '--' }}</h4>
                            <div class="row mt-4 info">
                                <div class="col-7">
                                    <div class="d-flex align-items-top mb-2"><i class="fal fa-clock mr-2 flex-grow-0"></i><span class="flex-grow-1">{{ parseCompatibilityDate(block.datetime_start) | dateToHourMinutes:meridian }}</span></div>
                                    <div class="d-flex align-items-top mb-2"><i class="fal fa-comments mr-2 flex-grow-0"></i><span class="flex-grow-1">{{ (block.is_room_group ? 'ROOM.GROUP' : 'ROOM.INDIVIDUAL') | translate}}</span></div>
                                    <!--<div *ngIf="block.geolocation_address"><i class="fal fa-map-marker-alt"></i>&nbsp;{{ block.geolocation_address }}</div>-->
                                    <!--<div class="d-flex align-items-top"><i class="fal fa-map-marker-alt mr-2 flex-grow-0"></i><span class="flex-grow-1">Viale Wolfgang Amadeus Mozart, 34 Milano</span></div>-->
                                </div>
                                <div class="col-5 text-center">
                                    <div>
                                        <h2 class="percentage mb-2">{{ block.completion_perc ? block.completion_perc : 0 }}%</h2>
                                        <p class="bold">{{ 'COMPLETED' | translate }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </span>
                </div>
            </ng-template>
        </div>
        <!-- [flickity]="{
            prevNextButtons: true,
            pageDots: false,
            contain: true
        }"  e [flickityChild] sulla card -->
        <div *ngIf="activeBlocks.length"  class="text-right" [flickity]="{
            freeScroll: true,
            pageDots: false,
            percentPosition: false,
            cellAlign: 'left'
            }" #ds>
            <ng-container *ngTemplateOutlet="cardsTemplate"></ng-container>
        </div>
    </section>
</ng-container>

<app-core-spinner class="mt-6" [toggle]="operation('loading-questions').submitting"></app-core-spinner>
<section id="questions-list" class="mt-3 pt-5" *ngIf="questions.length && !operation('loading-questions').submitting">
    <div class="d-flex align-items-center justify-content-between">
        <h3><span *ngIf="viewMode === 0">{{ 'QUESTIONS_LIST' | translate }}</span></h3>
        <div>
            <div *ngIf="viewMode === 0" class="d-inline-block mr-3">{{ 'SORT' | translate }}:
                <span class="btn-sicche btn-icon small"
                    [ngClass]="{'btn-primary': orderby == 'desc', 'btn-secondary': orderby != 'desc'}"
                    (click)="questionsOrderBy('desc')"><i class="far fa-sort-amount-down-alt"></i></span>
                <span class="btn-sicche btn-icon small"
                    [ngClass]="{'btn-primary': orderby == 'asc', 'btn-secondary': orderby != 'asc'}"
                    (click)="questionsOrderBy('asc')"><i class="far fa-sort-amount-up-alt"></i></span>
            </div>
            <div class="d-inline-block">{{ 'VIEW' | translate }}:
                <span class="btn-sicche btn-icon small" [ngClass]="{'btn-secondary': viewMode != 0, 'btn-primary': viewMode == 0}" (click)="changeViewMode(0)"><i class="fal fa-users"></i></span>
                <span class="btn-sicche btn-icon small" [ngClass]="{'btn-secondary': viewMode != 1, 'btn-primary': viewMode == 1}" (click)="changeViewMode(1)"><i class="fal fa-user"></i></span>
            </div>
        </div>
    </div>
    <div class="panel shadow table-view mt-3 py-3 min-h-auto" *ngIf="viewMode === 0">
        <section class="question" (click)="openAnswersModal(question, scrollTarget)" *ngFor="let question of questions">
            <div class="row align-items-center">
                <div class="col-md-8">
                    <p class="text pl-0 mb-0 bold"><span class="question-index mr-3"><b>{{ question.index }}</b></span>
                        {{ (question.title ? question.title : '--') | striphtmlKeepSpaces | truncate:[200, '...'] }}</p>
                </div>
                <div class="col-md-4 va">
                    <div class="resume">
                        <div class="row align-items-center">
                            <div class="col col-6 text-center" [ngClass]="{'no-border': question.type == 'communications'}">
                                <span>
                                    <i class="fal fa-comment-dots"></i>&nbsp;{{ question.nr_intervieweds_with_answers }}/{{ question.nr_intervieweds_total }}
                                </span>
                            </div>
                            <div *ngIf="question.type != 'communications'" class="col col-6 d-flex align-items-center">
                                <span class="notification ml-2">{{ question.nr_answers_unread < 0 ? 0 : question.nr_answers_unread }}</span>
                                <span class="ml-2">{{ 'TO_READ' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div #scrollTarget></div>
    <app-moderation-peopleview
        [question]="selectedQuestion"
        [block]="selectedBlock"
        (selectedBlockChanged)="changeSelectedBlock($event)"
        [blocks]="blocks"
        [roomid]="selectedRoomId"
        [room]="selectedRoom"
        [country_uniqid]="countryUniqid"
        *ngIf="viewMode === 1">
    </app-moderation-peopleview>
</section>
