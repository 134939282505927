import { ResearchModel } from './../models/research.model';
import { Injectable } from '@angular/core';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { BaseService } from '@app/core/services/base.service';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { DiscountModel } from '../models/discount.model';
import { BehaviorSubject } from 'rxjs';
import { ServiceMessageModel } from '@app/core/models/service-message.model';
import { SearchParams } from '@app/core/models/search-params';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class ResearchService extends BaseService implements BaseServiceI {

  message$: BehaviorSubject<ServiceMessageModel> = new BehaviorSubject(null);
  customerResearchUniqid$: string;

  constructor(public router: Router, public apiService: ApiService, private http: HttpClient, private translate: TranslateService) {
    super(router, apiService, ResearchModel);
  }

  get customerResearchUniqid(): string {
    return this.customerResearchUniqid$ || localStorage.getItem('research_uniqid');
  }
  set customerResearchUniqid(uniqid) {
    localStorage.setItem('research_uniqid', uniqid);
    this.customerResearchUniqid$ = uniqid;
  }

  getResearchByUniqid(id: string, query = {}) {
    return this.get(`${id}`, {...query, include_countries: true});
  }

  getOngoingResearches(params: SearchParams) {
    return this.find(params);
  }

  getResearchByCountryId(countryId: string) {
    return this.get(`5e848c34d8f8b`, {include_countries: true});
  }

  researchUsers(researchUniqid: string) {
    return this.sub(`${researchUniqid}/users`);
  }

  getResearchUsers(researchUniqid: string) {
    return this.sub(`${researchUniqid}/users`).find();
  }

  // non più usata, vedi manage-researches
  createDemoResearch() {
    // tslint:disable-next-line: max-line-length
    return this.create({is_demo: true, title: this.translate.instant('SICCHE_PLATFORM_DEMO'), countries: [{country_code: 'it', language_code: 'it', timezone: 'Europe/Rome'}]});
    // return this.get('manage-researches/get-new-demo');
  }
}
