
/**
 * OperationStatusModel
 * collection of useful booleans for generic operations (ajax, system... etc)
 */
export class OperationStatusModel  {

    public name: string;
    private Psubmitting: boolean;
    private Psuccess: boolean;
    private Pfailed: boolean;
    private Perrors: any;
    private Ploading: boolean;

    constructor(slugName: string) {
        this.name = slugName;
    }

    get submitting() {
        return this.Psubmitting ? true : false;
    }

    start() {
        this.reset().isSubmitting();
        return this;
    }

    get loading() {
        return this.Ploading ? true : false;
    }

    get success() {
        return this.Psuccess ? true : false;
    }

    get failed() {
        return this.Pfailed ? true : false;
    }

    get ended() {
        return this.Psubmitting === false;
    }

    get loaded() {
        return this.Ploading === false;
    }

    get errors() {
        if (Array.isArray(this.Perrors)) {
            return this.Perrors.slice();
        } else {
            return this.Perrors;
        }
    }

    reset(): OperationStatusModel {
        this.Psubmitting = false;
        this.Psuccess = false;
        this.Pfailed = false;
        this.Perrors = null;
        this.Ploading = false;
        return this;
    }

    isSubmitting(status: boolean = true): OperationStatusModel {
        this.Psubmitting = status;
        return this;
    }

    isLoading(status: boolean = true): OperationStatusModel {
        this.Ploading = status;
        return this;
    }

    isEnded(): OperationStatusModel {
        this.Psubmitting = false;
        return this;
    }

    isLoaded(): OperationStatusModel {
        this.Ploading = false;
        return this;
    }

    isFailed(errors?: any): OperationStatusModel {
        this.isSubmitting(false);
        this.Pfailed = true;
        this.Psuccess = false;
        this.Ploading = false;
        if (errors) {
            this.Perrors = errors;
        }
        return this;
    }

    isSuccess(errors?: any): OperationStatusModel {
        this.isSubmitting(false);
        this.Pfailed = false;
        this.Psuccess = true;
        this.Ploading = false;
        if (errors) {
            this.Perrors = errors;
        }
        return this;
    }
}
