<div *ngIf="room" class="">
    <div class="day-select-wrapper">
        <div *ngIf="blocks" class="d-flex align-items-center justify-content-center">
            <h4 class="mb-0 mr-2">
                {{ 'BLOCK.DAY_TO_CONFIG' | translate }}
            </h4>
            <div *ngIf="days">
                <div class="form-group mb-0">
                    <app-core-select
                        [classes]="'form-control'"
                        [placeholder]="'CHOOSE'"
                        [cache]="true"
                        [customList]="days"
                        [value]="'date'"
                        [label]="'name'"
                        (change)="changeDate($event)"
                        [(ngmodel)]="day"
                        >
                    </app-core-select>
                    <!-- <select class="form-control" (change)="changeDate($event)">
                        <option>Seleziona data</option>
                        <option *ngFor="let d of days" [value]="d.date">{{d.name}}</option>
                    </select> -->
                </div>
            </div>
        </div>

    </div>


    <div class="mt-3" *ngIf="day" cdkDropList [cdkDropListData]="activeBlocks" (cdkDropListDropped)="changeBlockOrder($event)">
        <app-private-question-block *ngFor="let b of activeBlocks" cdkDrag [cdkDragDisabled]="b.is_opened" [lastDay]="lastDay" [block]="b" [day]="day" (removeBlock)="removeBlock($event)" (updateBlocks)="newBlock($event)"></app-private-question-block>
    </div>

    <app-core-spinner class="mt-6" [toggle]="operation('loading-blocks').submitting"></app-core-spinner>

    <app-core-spinner [toggle]="operation('creating-block').submitting"></app-core-spinner>
    <div class="add-question-block p-5 mt-3" *ngIf="day && isEditable()" (click)="addBlock()">
        <svg width="36px" height="42px" class="mr-2" viewBox="0 0 36 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <!-- Generator: Sketch 63.1 (92452) - https://sketch.com -->
            <title>Molecule / sicchemoji / ?</title>
            <desc>Created with Sketch.</desc>
            <g id="Molecule-/-sicchemoji-/-?" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Group-13" transform="translate(-7.000000, -1.000000)" fill="#828282" fill-rule="nonzero">
                    <path d="M20.0248638,21.3602493 C19.372821,22.4148575 18.596335,23.0218034 18.2585408,23.262785 C17.9320145,23.53519 17.0923235,24.0057667 16.6750086,24.2377425 C16.2775604,24.5018183 15.8125973,24.6740403 15.3581473,24.8835504 C14.8839867,25.0367877 14.4053636,25.2149583 13.9086956,25.3009537 C13.4168098,25.423542 12.9154305,25.461957 12.4232581,25.5178917 C10.4451177,25.6508643 8.58893861,25.326711 7.2337935,25.0176159 C5.87793617,24.707793 5,24.4395475 5,24.4395475 C5,24.4395475 5.92247174,24.4723782 7.29067926,24.4239391 C7.97474045,24.4063121 8.76811949,24.3352368 9.60043644,24.2294821 C10.4307444,24.1017662 11.2979719,23.9188746 12.1127405,23.6440314 C12.5116585,23.4854229 12.9140194,23.3487664 13.2723453,23.1479378 C13.6469435,22.9806975 13.9682242,22.7434855 14.287089,22.5473081 C14.574022,22.3000645 14.9448138,22.0766527 15.1651502,21.8173895 L8.32781949,17.377175 C6.96451105,16.4447939 6.42219194,14.5593321 7.32696377,13.1141366 L12.334819,5.35497893 C13.2395908,3.90978346 15.3079635,3.59772899 16.6712767,4.52937758 C17.5801521,5.15047664 20.0453122,6.75137031 24.0667569,9.33205859 C25.4307823,10.264435 25.9723844,12.1499015 25.0676126,13.595097 L20.0248638,21.3602493 Z" id="Path" transform="translate(15.256714, 14.774274) scale(-1, -1) rotate(327.000000) translate(-15.256714, -14.774274) "></path>
                    <path d="M40.0248638,25.3602493 C39.372821,26.4148575 38.596335,27.0218034 38.2585408,27.262785 C37.9320145,27.53519 37.0923235,28.0057667 36.6750086,28.2377425 C36.2775604,28.5018183 35.8125973,28.6740403 35.3581473,28.8835504 C34.8839867,29.0367877 34.4053636,29.2149583 33.9086956,29.3009537 C33.4168098,29.423542 32.9154305,29.461957 32.4232581,29.5178917 C30.4451177,29.6508643 28.5889386,29.326711 27.2337935,29.0176159 C25.8779362,28.707793 25,28.4395475 25,28.4395475 C25,28.4395475 25.9224717,28.4723782 27.2906793,28.4239391 C27.9747404,28.4063121 28.7681195,28.3352368 29.6004364,28.2294821 C30.4307444,28.1017662 31.2979719,27.9188746 32.1127405,27.6440314 C32.5116585,27.4854229 32.9140194,27.3487664 33.2723453,27.1479378 C33.6469435,26.9806975 33.9682242,26.7434855 34.287089,26.5473081 C34.574022,26.3000645 34.9448138,26.0766527 35.1651502,25.8173895 L28.3278195,21.377175 C26.9645111,20.4447939 26.4221919,18.5593321 27.3269638,17.1141366 L32.334819,9.35497893 C33.2395908,7.90978346 35.3079635,7.59772899 36.6712767,8.52937758 C37.5801521,9.15047664 40.0453122,10.7513703 44.0667569,13.3320586 C45.4307823,14.264435 45.9723844,16.1499015 45.0676126,17.595097 L40.0248638,25.3602493 Z" id="Path" transform="translate(35.256714, 18.774274) scale(-1, -1) rotate(147.000000) translate(-35.256714, -18.774274) "></path>
                </g>
                <circle id="Oval" fill="#828282" cx="18" cy="39" r="3"></circle>
            </g>
        </svg>
        <h5 class="title mb-0 mt-2">{{ 'BLOCK.ADD_BLOCK' | translate }}</h5>
    </div>
</div>
