import { Component, OnInit, ViewChild, AfterViewInit, Input, EventEmitter, Output } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { QuestionsService } from '@app/shared/services/questions.service';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { AnswersService } from '@app/shared/services/answers.service';
import { ResponseModel } from '@app/core/models/response.model';
import { AnswerModel } from '@app/shared/models/answer.model';
import { AuthService } from '@app/core/services/auth.service';
import { FormGroup, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@app/core/services/modal.service';
import { TeamService } from '@app/shared/services/team.service';
import { NotificationService } from '@app/shared/services/notification.service';
import { TeamModel } from '@app/shared/models/team.model';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { LightboxModalComponent } from '@app/core/components/lightbox-modal/lightbox-modal.component';
import { CommentModel } from '@app/shared/models/comment.model';
import { ModerationHelperService } from '@app/shared/services/moderation-helper.service';
import { QuoteModel } from '@app/shared/models/quote.model';

@Component({
  selector: 'app-moderation-answer-comment-wrapper',
  templateUrl: './comment-block.component.html',
  styleUrls: [ './comment-block.component.scss']
})
export class AnswersBlockCommentWrapperComponent extends CoreBaseComponent implements OnInit {

  @Input()
  comment: CommentModel;
  @Input()
  answer: AnswerModel;
  @Input()
  readonly: boolean = false;
  @Input()
  intervieweds: TeamModel[] = [];
  @Input()
  highlighted_in = false;
  @Input()
  impersonal = false; // use true when accessing with no login, for pdf generation
  @Output() textSelection: EventEmitter<any> = new EventEmitter(null);
  


  constructor(private questionsService: QuestionsService, private coreLayoutService: CoreLayoutService,
              private notificationService: NotificationService, private moderationHelperService: ModerationHelperService,
              public answerService: AnswersService, public authService: AuthService, private modalService: ModalService,
              private toastService: ToastrService, private translate: TranslateService, private teamService: TeamService) {
    super();
  }

  ngOnInit(): void {
  }

  selectText(data) {
    this.textSelection.emit({commentid: this.comment.id});
  }

}
