import { Component, OnInit, ViewChild } from '@angular/core';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { CountryModel } from '@app/shared/models/country.model';
import { UntypedFormControl, Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ResearchModel } from '@app/shared/models/research.model';
import { ResearchService } from '@app/shared/services/research.service';
import { ResponseModel } from '@app/core/models/response.model';
import { RolesService } from '@app/shared/services/roles.service';
import { User } from '@app/shared/models/user.model';
import { UserService } from '@app/shared/services/user.service';
import { PageModel } from '@app/core/models/page';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ResearchSessionService } from '@app/shared/services/research-session.service';
import { BaseService } from '@app/core/services/base.service';
import { TeamService } from '@app/shared/services/team.service';
import { MessageModel } from '@app/shared/models/message.model';
import { LanguagesService } from '@app/shared/services/languages.service';
import { TeamModel } from '@app/shared/models/team.model';
import { StripHtmlPipe } from '@app/core/pipes/striphtml.pipe';
import { ModalService } from '@app/core/services/modal.service';
import { SmartUserSetupComponent } from '../../../analysis/smart-setup-modal/smart-setup-modal.component';
import { CoreBaseComponent } from '@app/core/components/base.component';

@Component({
  selector: 'app-manage-team',
  templateUrl: './manage-team.component.html',
  styleUrls: ['./manage-team.component.scss']
})
export class ManageTeamComponent extends CoreBaseComponent  implements OnInit {

  constructor(
    public coreLayoutService: CoreLayoutService,
    public formBuilder: UntypedFormBuilder,
    public researchService: ResearchService,
    public rolesService: RolesService,
    private toastService: ToastrService,
    private translate: TranslateService,
    public researchSessionService: ResearchSessionService,
    private userService: UserService,
    private teamService: TeamService,
    public languagesService: LanguagesService,
    private stripHtml: StripHtmlPipe,
    private modalService: ModalService
  ) {
    super();
  }

  country: CountryModel;
  form: UntypedFormGroup;
  research: ResearchModel;
  selectedCountry: string;
  selectedLanguage: string;
  selectedRole: string;

  users: TeamModel[];
  customerUsers: TeamModel[];
  selectedUsers = [];
  customerSelectedUsers = [];
  languages = [];
  loadingUsers = false;

  autocompleteEmail: TeamModel[];
  email: string;
  sendMessage = false;
  sendMessageCustomer = false;

  @ViewChild('autocomplete') autocomplete;

  ngOnInit(): void {
    this.coreLayoutService.sidebarParams$.subscribe((country: CountryModel) => {
      this.country = country;
      this.selectedLanguage = this.country.language_code;
      this.research = this.researchSessionService.getResearch();
      this.loadUsers();

      this.formGroup();
    });

  }

  loadUsers() {
    this.loadingUsers = true;
    this.teamService.findUsers(this.country.country_uniqid, null, 'creationtime', 'desc', 'RESEARCHER,CUSTOMER_PRO,CUSTOMER_BASIC')
    .subscribe((res: PageModel) => {
      const allUsers = res.getData();
      this.users = allUsers.filter(u => u.role == 'RESEARCHER');
      this.customerUsers = allUsers.filter(u => u.role != 'RESEARCHER');

      this.loadingUsers = false;
    });
  }

  hasSmartCustomer() {
    return this.customerUsers.find(user => (user.role === 'CUSTOMER_BASIC'));
  }

  emailAutocomplete(search: string) {
    this.teamService.autocompleteUsers(search).subscribe((res: ResponseModel) => {
      this.autocompleteEmail = res.getData();
    });
  }

  openSmartSetupModal() {
    this.modalService.open({modalModel: SmartUserSetupComponent, customClass: "smart-setup-modal", dialogSize: 'xl'}, {countryUniqId: this.country.country_uniqid});
  }

  autocompleteUser(user: TeamModel) {
    const email = this.stripHtml.transform(user.email)
    this.email = email;
    this.form.controls.email.setValue(email);
    this.form.controls.firstname.setValue(user.firstname);
    this.form.controls.lastname.setValue(user.lastname);
    this.form.controls.phone.setValue(user.telephone || '');
    this.autocomplete.close()
  }

  formGroup() {
    this.form = this.formBuilder.group({
      language_code: new UntypedFormControl(this.selectedLanguage, Validators.required),
      role: new UntypedFormControl(this.selectedRole, Validators.required),
      email: new UntypedFormControl(null, Validators.required),
      firstname: new UntypedFormControl(null, Validators.required),
      lastname: new UntypedFormControl(null, Validators.required),
      phone: new UntypedFormControl(null, Validators.nullValidator)
    });
  }

  save() {

  }

  msgSent() {
    this.sendMessage = false;
    this.sendMessageCustomer = false;
    this.selectedUsers = [];
    this.customerSelectedUsers = [];
    this.loadUsers();
  }

  getUsersByIds(userids) {
    const ret = [];
    for (const user of this.users) {
      if (userids.includes(user.userid)) {
        ret.push(user);
      }
    }
    return ret;
  }
  getCustomersByIds(userids) {
    const ret = [];
    for (const user of this.customerUsers) {
      if (userids.includes(user.userid)) {
        ret.push(user);
      }
    }
    return ret;
  }


  addMember() {
    if (this.form.valid) {
      const user = this.form.value;
      user.country_uniqid = this.country.country_uniqid;
      user.email = this.stripHtml.transform((user.email.email || user.email).trim());
      user.telephone = this.form.controls.phone.value;
      this.teamService.addModifyUser(user).subscribe((res: ResponseModel) => {
        this.loadUsers();
        this.formGroup();
      }, err => {
        for (const key in err.errors) {
          if (err.errors.hasOwnProperty(key)) {
            const element = err.errors[key];
            this.toastService.error(this.translate.instant('ERRORS.' + element.toUpperCase()));
          }
        }
      });
    } else {
      this.toastService.error(this.translate.instant('MISSING_DATA'));
    }
  }

  checkIfExisting(newUser: TeamModel) {
    for (const user of this.users) {
      if (newUser.email === user.email) {
        this.toastService.error(this.translate.instant('USER_ALREADY_EXISTING_DATA_UPDATED'));
        return true;
      }
    }
  }

  changeSelectedUsers(id) {
    if (id === 'all') {
      if (this.selectedUsers.length > 0) {
        this.selectedUsers = [];
      } else {
        this.selectedUsers = [];
        for (const u of this.users) {
          this.selectedUsers.push(u.userid);
        }
      }
    } else {
      const indexOf = this.selectedUsers.indexOf(id);
      if (indexOf >= 0) {
        this.selectedUsers.splice(indexOf, 1);
      } else {
        this.selectedUsers.push(id);
      }
    }
  }
  changeCustomerSelectedUsers(id) {
    if (id === 'all') {
      if (this.customerSelectedUsers.length > 0) {
        this.customerSelectedUsers = [];
      } else {
        this.customerSelectedUsers = [];
        for (const u of this.customerUsers) {
          this.customerSelectedUsers.push(u.userid);
        }
      }
    } else {
      const indexOf = this.customerSelectedUsers.indexOf(id);
      if (indexOf >= 0) {
        this.customerSelectedUsers.splice(indexOf, 1);
      } else {
        this.customerSelectedUsers.push(id);
      }
    }

  }

  sendInvite(customer = false) {
    if (customer) {
      this.sendMessageCustomer = true;
    } else {
      this.sendMessage = true;
    }
  }
  sendPassword() {
    this.teamService.createNewPassword(this.selectedUsers, this.country.country_uniqid).subscribe((res: ResponseModel) => {
      const msg = res.getData().msg;
      this.toastService.info(this.translate.instant('ADDED'));
      this.selectedUsers = [];
    });
  }

  copyToClipboard(text) {
    this.copyTextToClipboard(text);
    this.toastService.success(this.translate.instant('COPIED_TO_CLIPBOARD'));
  }

  removeUser(customer = false) {

    this.modalService.open({title: 'ARE_YOU_SURE.?',
        btnOkText: 'YES',
        btnCancelText: 'CANCEL',
        showBtnClose: false,
        showCancelBtn: true,
        customClass: 'confirm-modal',
        message: 'ARE_YOU_SURE_DELETE.?'
      }).then((data) => {
        if (data) {
          this.teamService.deleteFromCountries((customer ? this.customerSelectedUsers : this.selectedUsers), this.country.country_uniqid).subscribe((res: ResponseModel) => {

            if (customer) {
              this.customerUsers = this.customerUsers.filter( ( el ) => !this.customerSelectedUsers.includes( el.userid ) );
              this.customerSelectedUsers = [];
            } else {
              this.users = this.users.filter( ( el ) => !this.selectedUsers.includes( el.userid ) );
              this.selectedUsers = [];
            }
          });
        }
    });
  }
}
