
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class InfoService  {

  static mails = {
    finance: 'finance@sicche.com'
  };

  constructor() { }
}
