import { SecondsToTimePipe } from '@app/core/pipes/seconds-to-time.pipe';
import { SiccheEmoticonComponent } from '@app/shared/components/sicche-emoticon/sicche-emoticon.component';
import { AnswerModel } from '@app/shared/models/answer.model';
import { QuestionModel } from '@app/shared/models/question.model';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
require('highcharts/highcharts-more.js')(Highcharts);
require('highcharts/modules/heatmap.js')(Highcharts);
// require('highcharts/modules/export-data')(Highcharts);
require("highcharts/modules/exporting.js")(Highcharts);

export const chartlib = Highcharts;

export function commonSetup(question: QuestionModel, chartOptions: Highcharts.Options, dimEst: { w: string, h: string } ) {
  chartOptions.credits = { enabled: false };
  chartOptions.title = { text: '', align: 'left' };
  chartOptions.exporting = {
    allowHTML: true,
    buttons: {
      contextButton: {
        menuItems: ['downloadJPEG', 'downloadPDF', 'downloadSVG', 'downloadPNG'],
      },
    },
    // chartOptions: {
    //     title: { text: question.title }
    // },
    sourceWidth: (question.type === 'pin-on-media' && question.question_data.media_type === 'image')
      ? parseInt(dimEst.w.replace('px', ''))
      : 900,
    sourceHeight: parseInt(dimEst.h.replace('px', '')),
  };
}

export function pieChartSetup(chartOptions: Highcharts.Options) {
  chartOptions.series = [{
    name: 'Risposta singola',
    data: [],
    type: 'pie'
  }];

  chartOptions.tooltip = {
    pointFormat: '<b>{point.percentage:.1f}%</b>' /* {series.name}:  */
  };

  chartOptions.plotOptions = {
      pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
              // @ts-ignore
              allowOverlap: true,
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          }
      }
  };
}

export function bubbleChartSetup(chartOptions: Highcharts.Options, bubbleSize?: { min: number, max: number }) {
  chartOptions.chart = {
    type: 'bubble',
    plotBorderWidth: 1,
  };

  chartOptions.plotOptions = {
    bubble: {
        minSize: bubbleSize ? bubbleSize.min : 2,
        maxSize: bubbleSize ? bubbleSize.max : 50
    }
  };

  chartOptions.yAxis = {
    tickInterval: 1,
    title: null,
    labels: {
      enabled: true,
    }
  };

  chartOptions.xAxis = {
    labels: {
      enabled: true,
    }
  };

  chartOptions.series = [];
}

export function semanticDifferentialsSetup(chartOptions: Highcharts.Options, couples: any[], translate: TranslateService) {
  bubbleChartSetup(chartOptions, { min: 2, max: 36 });

  chartOptions.tooltip = {
    useHTML: true,
    headerFormat: '<table>',
    pointFormat: '' +
      '<tr><th colspan="2"><h4>{series.name} ({point.x}/5)</h4></th></tr>' +
      '<tr><th>' +  translate.instant('ANSWERS_NUM') + ':</th><td>{point.z}</td></tr>' +
      '<tr><th>' +  translate.instant('FREQUENCY') + ':</th><td>{point.p} %</td></tr>',
    footerFormat: '</table>',
    followPointer: true
  };

  chartOptions.yAxis = [
    {
      max: couples.length,
      min: -1,
      tickInterval: 1,
      title: null,
      labels: {
        enabled: true,
        formatter() {
          if (this.value < 0 || this.value > couples.length - 1) {
            return '';
          }
          return couples[this.value].label_left;
        },
      }
    },
    {
      max: couples.length,
      min: -1,
      tickInterval: 1,
      title: null,
      labels: {
        enabled: true,
        formatter() {
          return (this.value < 0 || this.value > couples.length - 1)
            ? ''
            : couples[this.value].label_right;
        },
      },
      gridLineWidth: 0,
      opposite: true
    }
  ];
  (chartOptions.xAxis as Highcharts.XAxisOptions).max = 6;
  (chartOptions.xAxis as Highcharts.XAxisOptions).min = 0;
  (chartOptions.xAxis as Highcharts.XAxisOptions).labels = {
    formatter() {
      return (this.value < 1 || this.value > 5) ? '' : `${this.value}`;
    },
  };

  chartOptions.plotOptions.series = {
    marker: {
      enabled: true
    },
  };
}

export function attitudeScaleSetup(
  chartOptions: Highcharts.Options,
  statements: any[],
  steps: number[],
  numAnswers: number,
  labels: { left: string, right: string, center?: string},
  translate: TranslateService
) {
  stackedBarChartSetup(chartOptions, statements.map(s => s.statement), translate);

  // const gradient = ['#FF8502', '#F09D50', '#FFBA8A', '#EBC3B6', '#E1D0E6', '#BECBEA', '#96C0FF', '#589CF0', '#287BFF', '#0034F0'];
  const gradient = ['#0034F0', '#287BFF', '#589CF0', '#96C0FF', '#BECBEA', '#E1D0E6', '#EBC3B6', '#FFBA8A', '#F09D50', '#FF8502'];

  steps.sort((a, b) => b - a);
  steps.forEach((s, i) => {
    chartOptions.series.push({
      id: `${s}`,
      type: 'bar',
      name: `${s}`,
      data: Array(statements.length).fill(0),
      color: gradient[i === steps.length - 1 ? gradient.length - 1 : Math.floor((gradient.length / (steps.length - 1)) * i)],
      legendIndex: s,
    });
  });

  (chartOptions.yAxis as Highcharts.YAxisOptions).max = numAnswers;
  (chartOptions.yAxis as Highcharts.YAxisOptions).tickInterval = .5;
  (chartOptions.yAxis as Highcharts.YAxisOptions).title = { text: '' };
  (chartOptions.yAxis as Highcharts.YAxisOptions).labels = {
    formatter() {
      return this.value === 0
        ? labels.left
        : this.value === numAnswers
          ? labels.right
          : this.value === numAnswers / 2 && labels.center
            ? labels.center
            : '';
    }
  };



  // chartOptions.tooltip = {
  //   useHTML: true,
  //   headerFormat: '<table>',
  //   pointFormat: '' +
  //     '<tr><th colspan="2"><h4>{series.name} ({point.x}/' + steps[steps.length - 1] + ')</h4></th></tr>' +
  //     '<tr><th>' +  translate.instant('ANSWERS_NUM') + ':</th><td>{point.z}</td></tr>' +
  //     '<tr><th>' +  translate.instant('FREQUENCY') + ':</th><td>{point.p} %</td></tr>',
  //   footerFormat: '</table>',
  //   followPointer: true
  // };

  // chartOptions.yAxis = {
  //   max: steps[steps.length] + 0.5,
  //   min: steps[0] - 0.5,
  //   tickInterval: 1,
  //   title: null,
  //   labels: {
  //     enabled: true,
  //     formatter() {
  //       return (steps.indexOf(this.value) === -1)
  //         ? ''
  //         : `${steps[this.value]}`;
  //     },
  //   }
  // };

  // (chartOptions.xAxis as Highcharts.XAxisOptions).max = statements.length - 1] + 1;
  // (chartOptions.xAxis as Highcharts.XAxisOptions).min = steps[0] - 1;
  // (chartOptions.xAxis as Highcharts.XAxisOptions).labels = {
  //   formatter() {
  //     return (this.value < steps[0] || this.value > steps[steps.length - 1]) ? '' : `${this.value}`;
  //   },
  // };
}

export function stackedBarChartSetup(chartOptions: Highcharts.Options, ylabels: string[], translate: TranslateService) {
  chartOptions.chart = {
    type: 'bar',
  };

  chartOptions.xAxis = {
    categories: ylabels,
  };

  chartOptions.yAxis = {
    allowDecimals: false,
    min: 0,
    title: {
      text: translate.instant('ANSWERS_NUM')
    }
  };

  chartOptions.plotOptions = {
    series: {
      stacking: 'normal'
    }
  };

  chartOptions.series = [];
}

export function filterFirstN(n: number, countMap: { [k: string]: number }|{ [k: number]: number }, cutEquals?: boolean) {
  const filtered = { };
  while (Object.keys(countMap).length && Object.keys(filtered).length < n) {
    const maxCount = Object.keys(countMap).reduce((max, k) => countMap[k] > max ? countMap[k] : max, 0);
    const words = Object.keys(countMap).filter(k => countMap[k] === maxCount);
    if (!cutEquals) { // riempie fino a <n> ma se ci sono elementi pari al n-esimo li aggiunge
      words.forEach(w => filtered[w] = maxCount);
      words.forEach(w => delete countMap[w]);
    } else { // riempie fino a <n>
      let i = 0;
      while (Object.keys(countMap).length && Object.keys(filtered).length < n && i < words.length) {
        filtered[words[i]] = maxCount;
        delete countMap[words[i]];
        i++;
      }
    }
  }
  return filtered;
}

export function wordCloudSetup(chartOptions: Highcharts.Options, translate: TranslateService) {
  chartOptions.series = [{
    type: 'wordcloud',
    name: translate.instant('ANSWERS_NUM'),
    data: [],
    rotation: {
      orientations: 1
    },
    style: {
      fontFamily: 'Arial'
    },
  }];

  chartOptions.tooltip = {
    useHTML: true,
    headerFormat: '<table class="graph-tooltip">',
    pointFormat: '' +
      '<tr><th>' +  translate.instant('ANSWERS_NUM') + ':</th><td>{point.weight}</td></tr>' +
      '<tr><th>' +  translate.instant('FREQUENCY') + ':</th><td>{point.perc} %</td></tr>',
    footerFormat: '</table>',
    followPointer: true
  };
}

export function barChartSetup(chartOptions: Highcharts.Options, ylabels: string[], translate: TranslateService) {
  chartOptions.chart = {
    type: 'bar'
  };

  chartOptions.xAxis = {
    categories: ylabels,
    title: {
      text: null
    }
  };

  chartOptions.yAxis = {
    allowDecimals: false,
    min: 0,
    title: {
      text: translate.instant('ANSWERS_NUM'),
      align: 'high'
    },
    labels: {
      overflow: 'justify'
    }
  };

  chartOptions.plotOptions = {
    bar: {
      dataLabels: { enabled: true }
    }
  };

  chartOptions.series = [{ id: '1', name: '', type: 'bar', data: [] }];
}

export function heatMapSetup(
  chartOptions: Highcharts.Options,
  imageUrl: string,
  imageDim: { w: number, h: number },
  segLen: { x: number, y: number },
  maxVal: number,
) {
  const fitDim = pinOnImageFitImageDim(imageDim);
  const chartDim = pinOnImageChartDim(fitDim);

  chartOptions.chart = {
    type: 'heatmap',
    inverted: true,
    margin: [
      Math.floor((chartDim.h - fitDim.h) / 3),
      Math.floor((chartDim.w - fitDim.w) / 2),
      Math.floor((chartDim.h - fitDim.h) * 2 / 3),
      Math.floor((chartDim.w - fitDim.w) / 2)
    ],
    plotBackgroundImage: imageUrl,
  };

  const axis = {
    visible: false,
    gridLineWidth: 0,
    minPadding: 0,
    maxPadding: 0,
    startOnTick: false,
    endOnTick: false
  };

  chartOptions.xAxis = JSON.parse(JSON.stringify(axis)) as Highcharts.XAxisOptions;
  chartOptions.xAxis.min = 0;
  chartOptions.xAxis.max = Math.floor(imageDim.h / segLen.y);

  chartOptions.yAxis = JSON.parse(JSON.stringify(axis)) as Highcharts.XAxisOptions;
  chartOptions.yAxis.min = 0;
  chartOptions.yAxis.max = Math.floor(imageDim.w / segLen.x);

  chartOptions.tooltip = {
    formatter() {
      const startingX = (this.point.options.y * segLen.x) + (this.point.options.y > 0 ? 1 : 0);
      const startingY = (this.point.options.x * segLen.y) + (this.point.options.x > 0 ? 1 : 0);
      return `[(${startingX}, ${startingY}) - (${startingX + segLen.x}, ${startingY + segLen.y})]` +
        `<br/><b>${this.point.options.value}</b>`;
    }
  };

  chartOptions.colorAxis = {
    stops: [
      [0.00, 'rgba(230, 230, 230, .3)'],
      [0.25, 'rgba(  0, 250,   0, .8)'],
      [0.50, 'rgba(220, 240,  60, .8)'],
      [0.75, 'rgba(250, 160,  30, .8)'],
      [1.00, 'rgba(250,   0,   0, .8)']
    ],
    min: 0,
    max: maxVal,
    startOnTick: false,
    endOnTick: false,
    minorTickInterval: 0.1,
    tickLength: 0,
    type: 'linear'
  };
}

export function pinOnVideoSetup(chartOptions: Highcharts.Options, segLen: number, translate: TranslateService) {
  bubbleChartSetup(chartOptions);
  (chartOptions.yAxis as Highcharts.YAxisOptions).labels = {
    useHTML: true,
    formatter() {
      switch (this.value) {
        case 0: return '<span class="font-weight-bold" style="font-size: 1rem;">' + translate.instant('TOTALS') + '</span>';
        case 3: return `
          <img style="width:40px; height:40px;" src="${environment.appUrl}/assets/images/sicche-emo-happy.png" alt=":)" />
        `;
        case 2: return `
          <img style="width:40px; height:40px;" src="${environment.appUrl}/assets/images/sicche-emo-medium.png" alt=":|" />
        `;
        case 1: return `
          <img style="width:40px; height:40px;" src="${environment.appUrl}/assets/images/sicche-emo-sad.png" alt=":(" />
        `;

        // THIS FAILS TO EXPORT IMAGES
        // tslint:disable
        // case 3: return '<img alt=":)" style="width: 40px; max-height: 45px; transform: translateY(-50%)" src="assets/images/sicche-emo-happy.png">';
        // case 2: return '<img alt=":| " style="width: 40px; max-height: 45px; transform: translateY(-50%)" src="assets/images/sicche-emo-medium.png">';
        // case 1: return '<img alt=":(" style="width: 40px; max-height: 45px; transform: translateY(-50%)" src="assets/images/sicche-emo-sad.png">';
        // tslint:enable
      }
    }
  };

  chartOptions.legend = { enabled: false };

  chartOptions.tooltip = {
    formatter() {
      return `<b>[${new SecondsToTimePipe().transform((this.x - 0.5) * segLen)} - ` +
        `${new SecondsToTimePipe().transform(((this.x - 0.5) * segLen) + segLen)}]</b><br/>${this.point.options.z}`;
    }
  };

  (chartOptions.xAxis as Highcharts.XAxisOptions).labels = {
    enabled: true,
    // @ts-ignore
    formatter() { return new SecondsToTimePipe().transform(this.value * segLen); }
  };
}

export function getImageGridPinsCount(
  answers: AnswerModel[],
  emoticons: boolean,
  imageDim: { w: number, h: number },
  cellW: number,
  cellH: number,
  pressure = true,
  ray = 4
) {
  const dataMatrix = Array(Math.ceil(imageDim.h / cellH)).fill(0).map(el =>
    Array(Math.ceil(imageDim.w / cellW)).fill(0).map(z => ({ total: 0, 1: 0, 2: 0, 3: 0 }))
  );
  answers.forEach(a => {
    a.answer_data.pins.forEach(pin => {
      const center_y = Math.floor(pin.position.y / cellH);
      const center_x = Math.floor(pin.position.x / cellW);
      if (pressure) { // increment by distance from the center;
        for (let x = center_x - ray + 1; x <= center_x + ray - 1; x++) {
          for (let y = center_y - ray + 1; y <= center_y + ray - 1; y++) {
            if (y >= 0 && y < dataMatrix.length && x >= 0 && x <= dataMatrix[0].length) {
              const d = Math.pow(Math.pow(x - center_x, 2) + Math.pow(y - center_y, 2), 0.5);
              if (dataMatrix[y][x]) {
                dataMatrix[y][x].total += ray - d > 0 ? Math.round(((ray - d) / 4) * 100) / 100 : 0;
              }
            }
          }
        }
      } else { // simple count
        if (dataMatrix[center_y][center_x]) {
          dataMatrix[center_y][center_x].total++;
        }
      }
      if (emoticons) {
        dataMatrix[center_y][center_x][pin.emoticon]++;
      }
    });
  });
  const dataArray = [];
  dataMatrix.forEach((row: Array<any>, i) => row.forEach((cell, j) => dataArray.push([i, j, cell])));
  return dataArray;
}

export function getImageDim(imgUrl: string): Promise<{ w: number, h: number }> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({ w: img.width, h: img.height });
    img.onerror = reject;
    img.src = imgUrl;
  });
}

export function getVideoSegmentationPinsCount(answers: AnswerModel[], emoticons: boolean, videoLen: number, segmentLen: number) {
  const dataSegments = Array(Math.ceil(videoLen / segmentLen)).fill(0).map(el => ({ total: 0, 1: 0, 2: 0, 3: 0 }));
  answers.forEach(a => {
    a.answer_data.pins.forEach(pin => {
      // there is the case a video of 10 seconds, with 10 segments, has a pin at exact second 10 that would try to go into 11th seg.
      const idx = Math.floor(pin.time / segmentLen);
      dataSegments[idx < dataSegments.length ? idx : dataSegments.length - 1].total++;
      if (emoticons) {
        dataSegments[idx < dataSegments.length ? idx : dataSegments.length - 1][pin.emoticon]++;
      }
    });
  });
  return dataSegments;
}

export function getVideoLen(vidUrl: string): Promise<number> {
  return new Promise((resolve, reject) => {
    const vid = document.createElement('video');
    vid.onloadedmetadata = () => resolve(vid.duration);
    vid.onerror = reject;
    vid.src = vidUrl;
  });
}


export function pinOnImageFitImageDim(imageDim: { w: number, h: number }): { w: number, h: number } {
  const newDim = { w: null, h: null };
  const maxDim = 600;
  if (imageDim.w > maxDim || imageDim.h > maxDim) {
    const ratio = imageDim.w / imageDim.h;
    newDim[ratio > 1 ? 'w' : 'h'] = maxDim;
    newDim[ratio > 1 ? 'h' : 'w'] = ratio > 1 ? maxDim / ratio : maxDim * ratio;
  } else {
    newDim.w = imageDim.w;
    newDim.h = imageDim.h;
  }
  return newDim;
}


export function pinOnImageChartDim(imageFitDim: { w: number, h: number }): { w: number, h: number } {
  return { w: imageFitDim.w + 50, h: imageFitDim.h + 120 };
}
