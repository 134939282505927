<ng-container *ngIf="answer">
  <div class="p-0 mb-4 {{ classes }}" *ngIf="answer.answer_data" (mouseup)="selectText()">
    <div class="mt-3" *ngFor="let vid of answer.answer_data.videos; let i = index">
      <div class="card">
        <div class="video-container d-flex align-items-top">
          <div class="img-card-box">
            <div>
              <div  class="d-flex flex-column align-items-center">

                <ng-container *ngIf="!placeholders">
                  <video
                    *ngIf="vid.video_url"
                    id="video_player_{{i}}"
                    class="card-video"
                    alt=""
                    controls
                    [src]="vid.video_url | privatesrc">
                    Your browser does not support the video tag.
                  </video>
                </ng-container>

                <div *ngIf="placeholders" class="pdf-placeholder pdf-video"></div>

                <div *ngIf="explicitMediaUrls" class="explicit-media-url mb-2">
                  <a [href]="vid.video_url">{{ 'MEDIA_DOWNLOAD' | translate }}</a>
                  <span class="media-file-name">{{ explicitMediaFileName(vid.video_url) }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="card-transcription p-3 flex-grow-1">
            <h5>{{ 'TRANSCRIPTION' | translate }}</h5>
            <ng-scrollbar>
              <div *ngIf="vid.video_data && vid.video_data.file_words" (mouseup)="selectText()">
                <div class="transcription-timeline">
                  <div
                    *ngFor="let word of vid.video_data.file_words" 
                    class="clickable float-left"
                    (click)="setVideoTime(i, word.start, word.end)">
                    {{word.word}}&nbsp;
                  </div>
                </div>  
                <div class="transcription" [innerHTML]="vid.hlTranscription"></div>
              </div>
            </ng-scrollbar>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
