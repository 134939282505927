import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { InterviewedsService } from '@app/shared/services/intervieweds.service';
import { PageModel } from '@app/core/models/page';
import { InterviewedsModel } from '@app/shared/models/intervieweds.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { TeamService } from '@app/shared/services/team.service';
import { ManageUsersService } from '@app/shared/services/support/manage-users.service';
import { ResponseModel } from '@app/core/models/response.model';
import { DateToLocaleStringPipe } from '@app/core/pipes/date-to-locale-string.pipe';
import { ModalService } from '@app/core/services/modal.service';
import { ResearchesModalComponent } from './researches-modal/researches-modal.component';


@Component({
  selector: 'app-private-dash-interviewedtable',
  templateUrl: './interviewed-table.component.html',
  styleUrls: ['./interviewed-table.component.scss']
})
export class InterviewedTableComponent extends CoreBaseComponent implements OnInit, OnDestroy {

  intervieweds: InterviewedsModel[] = [];
  sortOrder = 'asc';
  sortAttribute = null;

  constructor(
    private interviewedsService: InterviewedsService,
    public manageUsersService: ManageUsersService,
    private dateToLocale: DateToLocaleStringPipe,
    private modalService: ModalService
    ) {
    super();
  }

  ngOnInit() {
    this.loading = true;
    this.loadInterviews();
  }

  loadInterviews() {
    if (this.manageUsersService.dashboardUsers) {
      this.intervieweds = this.manageUsersService.dashboardUsers;
      this.loading = false;
    } else {
      this.manageUsersService.getDashboardUsers(false, this.sortAttribute, this.sortOrder).subscribe((result: ResponseModel) => {
        this.intervieweds = result.getData();
        this.loading = false;

        this.manageUsersService.dashboardUsers = this.intervieweds;
      });
    }
  }

  openMissingAnswersModal(researches) {
    this.modalService.open(
      {modalModel: ResearchesModalComponent, dialogSize: 'custom'}, {researches}
    );
  }

  add() {
    this.intervieweds.unshift(this.intervieweds[3]);
    this.intervieweds = this.intervieweds.slice();
  }

  download() {
    this.manageUsersService.getDashboardUsers(true, this.sortAttribute, this.sortOrder).subscribe((res: ResponseModel) => {
      this.downloadFile(res.blob, 'intervieweds.xlsx');
    });
  }

  ngOnDestroy() {
  }

  columnClicked(e) {
    if (e === 'NAME') {
      this.sortAttribute = 'firstname';
    }
    if (e === 'SURNAME') {
      this.sortAttribute = 'lastname';
    }
    if (e === 'MAIL') {
      this.sortAttribute = 'email';
    }
    this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    this.loadInterviews();
  }

}
