import { Component, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { AnswersModel } from '@app/shared/models/answers.model';
import * as Highcharts from 'highcharts';
import { AnswerModel } from '@app/shared/models/answer.model';
import { ModerationHelperService } from '@app/shared/services/moderation-helper.service';
import { QuoteModel } from '@app/shared/models/quote.model';
import { TeamService } from '@app/shared/services/team.service';
import { AnswersService } from '@app/shared/services/answers.service';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { ResponseModel } from '@app/core/models/response.model';

@Component({
  selector: 'app-moderation-answerstype-closed-single',
  templateUrl: './closed-single.component.html',
  styleUrls: ['./closed-single.component.scss']
})
export class AnswersTypeClosedSingleComponent extends CoreBaseComponent implements OnInit {

  @Input()
  question: QuestionModel;
  @Input()
  answer: AnswerModel;
  @Input()
  classes: string;
  @Input()
  explicitMediaUrls: boolean;
  @Output() textSelection: EventEmitter<any> = new EventEmitter(null);
  @Input() set message(value: {action: string, data: any}) {
    this.switchMessage(value);
  }
  public form: UntypedFormArray;
  public quotes: Array<QuoteModel> = [];
  public qSubscription = null;
  public itemToModify: number = null;

  constructor(private moderationHelperService:  ModerationHelperService, private answerService: AnswersService, private teamService: TeamService, private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.form = new UntypedFormArray([], null);
    if(this.answer.answer_data.comment) {
      this.form.push(new UntypedFormControl(this.answer.answer_data.comment, null))
    }
    if(!this.teamService.isCurrentUserCustomerPro() && !this.teamService.isCurrentUserCustomerBasic()) {
      if(this.answer.quotes) {
        this.quotes = this.answer.quotes;
        this.printQuotes(this.quotes);
      }
      this.qSubscription = this.moderationHelperService.quotes.subscribe(quotes => {
        this.printQuotes(quotes);
        this.quotes = quotes;
        this.cdr.detectChanges();
      });
    }
  }

  private printQuotes(quotes: Array<QuoteModel>) {
    if(quotes != null) {
      quotes.forEach(quote => {
        try {
          this.answer.answer_data.comment = this.answer.answer_data.comment?.replaceAll(/\s{2,}/g, ' ')?.replaceAll(/\r?\n|\r/g, '');
          if(this.answer.answer_data.comment) {

            if(quote.quote_original && quote.quote_original.length) {
              const qclass = quote.color ? quote.color : 'grey';
              quote.quote_original.forEach(q => {
                this.answer.answer_data.comment = this.answer.answer_data.comment.replace(q, '<em class="highlight ' + qclass + '">'+ q +'</em>');
              });
            } else {
              if(this.answer.answer_data.comment.includes(quote.quote) && quote.answerid === this.answer.id) {
                const qclass = quote.color ? quote.color : 'grey';
                this.answer.answer_data.comment = this.answer.answer_data.comment.replace(quote.quote, '<em class="highlight ' + qclass + '">' + quote.quote +'</em>');
              }
            }


          }
        } catch (error) {
          console.error({quotes, error})
        }
      });
    }
  }

  selectText() {
    this.textSelection.emit({commentid:null});
  }

  private switchMessage(message: {action: string, data: any}) {
    if (!message) {
      return false;
    }
    switch (message.action) {
      case 'modify-answer':
        this.itemToModify = message.data;
        break;
    }
  }

  answerContains(answerData: any, answerId: number) {
    if (answerData.optionid === answerId) {
      return answerData;
    } else {
      return null;
    }
  }

  userAnswerComment(answerData: any, answerId: number) {
    const found = answerData.optionid === answerId ? answerData : null;
    if (found) {
      let comment = found.comment;
      if(comment) {
        comment = found.comment.replace(/(\r\n|\n|\r)/gm, "<br />");
        if(this.quotes) {
          this.quotes.forEach(quote => {

            const qclass = quote.color ? quote.color : 'grey';
            if(quote.quote_original && quote.quote_original.length) {
              quote.quote_original.forEach(q => {
                comment = comment.replace(q, '<em class="highlight ' + qclass + '">'+ q +'</em>');
              });
            } else {
              comment = comment.replace(quote.quote, '<em class="highlight ' + qclass + '">' + quote.quote +'</em>');
            }

          });
        }
      }
      return comment;
    } else {
      return null;
    }
  }

  saveMods() {
    this.operation('saving').reset().isSubmitting();
    const data = this.answer;
    data.answer_data.comment = this.form.value[0];
    this.answerService.updateAnswer(this.answer.id, data).subscribe((res: ResponseModel) => {
      this.answer.answer_data.comment = this.form.value[0];
      this.itemToModify = null;
      this.operation('saving').isSuccess();
    });
  }

  submit() {
  }

}
