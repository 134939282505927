import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ResponseModel } from '@app/core/models/response.model';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { QuestionsService } from '@app/shared/services/questions.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { BlockModel } from '@app/shared/models/block.model';
import { AbstractQuestion } from '../abstract-question.component';

@Component({
  selector: 'app-setup-questions-communications.',
  templateUrl: './communications.component.html',
  styleUrls: ['./communications.component.scss']
})
export class CommunicationsQuestionComponent extends AbstractQuestion implements OnInit {

  @Input() countryUniqId: string = null;
  @Input() block: BlockModel = null;
  @Input() question: QuestionModel = null;
  @Input() disabled = false;
  @Output() saveEmit: EventEmitter<string> = new EventEmitter(null);

  public form: UntypedFormGroup;
  private answerUniqId = 0;

  get answers() {
    return this.form.get('answers') as UntypedFormArray;
  }

  constructor(private formBuilder: UntypedFormBuilder, private layoutService: CoreLayoutService, protected translate: TranslateService,
              private questionsService: QuestionsService, protected toastService: ToastrService) {
    super(toastService, translate);
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      question_data: this.formBuilder.group({}),
      title: new UntypedFormControl(this.question ? this.question.title : '', null),
      has_minimum_multiple_answers_nr: new UntypedFormControl((this.question && this.question.minimum_multiple_answers_nr) ? true : null),
      minimum_multiple_answers_nr: new UntypedFormControl(this.question ? this.question.minimum_multiple_answers_nr : null),
      type: new UntypedFormControl('communications', null),
      blockid: new UntypedFormControl(this.block.id, Validators.required),
      personas_tags: new UntypedFormControl(this.question ? this.question.personas_tags : '', null),
      sample_variable_tags: new UntypedFormControl(this.question ? this.question.sample_variable_tags : '', null)
    });
    if (this.disabled) {
      this.form.disable();
    }
    this.layoutService.buttons([
      {label: 'SAVE_AND_EXIT', slug: 'saveexit', classes: 'mr-3 btn-sicche btn-secondary', icon: 'fal fa-share-square', click: this.submitAndExit },
      {label: 'SAVE_AND_CREATE_NEW', slug: 'save', classes: 'mr-5 btn-sicche btn-primary', icon: 'fal fa-check', click: this.submitAndStay },
    ]);

    this.initAttachments();
  }

  submitAndExit = () => {
    this.layoutService.getButton('saveexit').loading = true;
    this.submit(() => {
      this.saveEmit.emit('save-and-leave');
    }, () => {
      this.layoutService.getButton('saveexit').loading = false;
    });
  }

  submitAndStay = () => {
    this.layoutService.getButton('save').loading = true;
    this.submit(() => {
      this.saveEmit.emit('save-and-stay');
    }, () => {
      this.layoutService.getButton('save').loading = false;
    });
  }

  submit(callback: any, fallback: any) {
    if (this.disabled) {
      fallback();
      this.toastService.error(this.translate.instant('ERRORS.CAN_NOT_MODIFY_PAST'));
      return;
    }
    this.operation('creating').reset().isSubmitting();
    const formValue = this.form.getRawValue();
    const data = formValue;
    if (data.sample_variable_tags && data.sample_variable_tags.length > 0) {
      data.sample_variable_tags_by_comma = data.sample_variable_tags.join(',');
      data.sample_variable_tags = null;
    }
    if (data.personas_tags && data.personas_tags.length > 0) {
      data.personas_tags_by_comma = data.personas_tags.join(',');
      data.personas_tags = null;
    }

    data.question_data.attached_pdf_files = this.attachedPdfFiles;
    data.question_data.attached_images = this.attachedImages;

    (!this.question ? this.questionsService.createQuestion(data)
      : this.questionsService.updateQuestion(this.question.id, data)).subscribe((res: ResponseModel) => {
      this.operation('creating').isSuccess(res.getErrors());
      this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
      callback();
    }, (e) => {
      this.operation('creating').isFailed(e.errors);
      this.toastService.error(this.translate.instant('ERRORS.GENERIC'));
      fallback();
    });
  }

}
