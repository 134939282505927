import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { AnswerModel } from '@app/shared/models/answer.model';
import { ModerationHelperService } from '@app/shared/services/moderation-helper.service';
import { QuoteModel } from '@app/shared/models/quote.model';

@Component({
  selector: 'app-moderation-answerstype-vocal-answer',
  templateUrl: './vocal-answer.component.html',
  styleUrls: ['./vocal-answer.component.scss']
})
export class AnswersTypeVocalAnswerComponent extends CoreBaseComponent implements OnInit {

  @Input() question: QuestionModel;
  @Input() answer: AnswerModel;
  @Input() classes: string;
  @Input() explicitMediaUrls: boolean;
  @Input() placeholders: boolean;
  @Output() textSelection: EventEmitter<any> = new EventEmitter(null);
  private qSubscription = null;
  public originalText = null;

  constructor(private moderationHelperService:  ModerationHelperService) {
    super();
  }

  ngOnInit(): void {
    // Return/new-line to br
    if(this.answer.answer_data.written) {
      this.answer.answer_data.written = this.answer.answer_data.written.replace(/(\r\n|\n|\r)/gm, "<br />");
    }

    this.setupQuotes();
  }

  setupQuotes(reset = false) {
    if(this.answer.quotes) {
      this.copyOriginalText(reset);
      this.printQuotes(this.answer.quotes);
    }
    this.qSubscription = this.moderationHelperService.quotes.subscribe(quotes => {
      this.copyOriginalText(reset);
      this.printQuotes(quotes);
    });
  }

  private copyOriginalText(reset) {
    // Answer text
    if(!this.originalText || reset) {
      this.originalText = this.answer.answer_data.written;
    }
    this.answer.answer_data.written = this.originalText;
  }

  public printQuotes(quotes: Array<QuoteModel>) {
    this.answer.answer_data.written = this.answer.answer_data.written ? this.answer.answer_data.written?.replaceAll(/\s{2,}/g, ' ')?.replaceAll(/\r?\n|\r/g, '') : null;
    if(quotes != null) {
      quotes.forEach(quote => {
        if(/*this.answer.answer_data.text.includes(quote.quote) && */quote.answerid === this.answer.id) {
          if(this.answer.answer_data.written) {
            const qclass = quote.color ? quote.color : 'grey';
            if(quote.quote_original && quote.quote_original.length) {
              quote.quote_original.forEach(q => {
                this.answer.answer_data.written = this.answer.answer_data.written.replace(q, '<em class="highlight ' + qclass + '">'+ q +'</em>');
              });
            } else {
              // old way - before 29/July/2021
              this.answer.answer_data.written = this.answer.answer_data.written.replace(quote.quote, '<em class="highlight ' + qclass + '">'+ quote.quote +'</em>');
            }
            //this.answer.answer_data.written = this.answer.answer_data.written.replace(quote.quote, '<em class="highlight ' + qclass + '">'+ quote.quote +'</em>');
          }
          if(this.answer.answer_data.vocal_data) {
            if(this.answer.answer_data.vocal_data.file_words.length) {
              if(quote.answerid === this.answer.id) {
                this.hlTranscription(this.answer.answer_data.vocal_data, quote);
              }
            }
          }
        }
      });
    }
  }

  private hlTranscription(audio: any, quote) {
    if(!audio.hasOwnProperty('hlTranscription')) {
      audio.hlTranscription = '';
      audio.file_words.forEach(word => {
        audio.hlTranscription += word.word + ' ';
      });
    }
    const qclass = quote.color ? quote.color : 'grey';
    if(quote.quote_original && quote.quote_original.length) {
      quote.quote_original.forEach(q => {
        audio.hlTranscription = audio.hlTranscription.replace(q, '<em class="highlight ' + qclass + '">'+ q +'</em>');
      });
    } else {
      // old way - before 29/July/2021
      audio.hlTranscription = audio.hlTranscription.replace(quote.quote, '<em class="highlight ' + qclass + '">'+ quote.quote +'</em>');
    }
    //audio.hlTranscription = audio.hlTranscription.replace(quote.quote, '<em class="highlight ' + qclass + '">' + quote.quote +'</em>');
  }

  selectText() {
    this.textSelection.emit({commentid:null});
  }

  setAudioTime(start: number, end: number) {
    const audio: any = document.getElementById('audio-player');
    audio.currentTime = start / 1000;
  }

  ngOnDestroy() {
    if(this.qSubscription) {
      this.qSubscription.unsubscribe();
    }
  }
}
