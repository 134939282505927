<section>
    <app-core-spinner [toggle]="operation('archived-researches').loading"></app-core-spinner>
    <div class="row">
        <div class="col-lg-12 pt-5 col-sm-12">
            <h1>{{ 'RESEARCHES_ARCHIVE' | translate }}</h1>
            <p>{{ 'RESEARCHES_ARCHIVE_INFO' | translate }}</p>
        </div>
        <!-- <div class="col-lg-9 col-sm-12 position-relative">
            <app-private-researches-slider [link]="false" [researches]="researches"></app-private-researches-slider>
        </div> -->
    </div>
</section>
<section>
    <h3 class="float-left">{{ 'ARCHIVED_RESEARCHES' | translate }}</h3>&nbsp;
    <div class="clearfix"></div>
    <div class="row">
        <div class="col-12">
            <div class="panel">
                <div class="position-relative">
                    <div class="table-responsive" *ngIf="researches">
                        <app-core-table
                            [items]="researches"
                            [query]="{status:'COMPLETED'}"
                            [columns]="[{name: 'PROJECT_CODE'}, {name: 'NAME'}, {name: 'START_DATE'}, {name: 'END_DATE'}, {name: 'DURATION'}, {name: 'INTERVIEWEDS_W'}, {name: 'COUNTRIES'}, {name: 'EXPORT_RESEARCH'}]"
                            tableid="archived-res"
                            [perPage]="500000"
                            [rowTemplate]="rowTemplate"></app-core-table>
                        <ng-template let-item="item" #rowTemplate>
                            <tr>
                                <td class="font-weight-bold">
                                    {{ item.title }}
                                </td>
                                <td class="font-weight-bold">
                                    {{ item.countries[0].title }}
                                </td>
                                <td class="text-center">
                                    {{ item.date_start | dateToLocaleString }}
                                </td>
                                <td class="text-center">
                                    {{ item.countries[0].last_block_datetime | dateToLocaleString }}
                                </td>
                                <td class="text-center">
                                    {{ item.nr_effective_days }}
                                </td>
                                <td class="text-center">
                                    {{ 'INTERVIEWEDS.TOTALS.CUSTOM' | translate: {param1: item.nr_interviewed} }}
                                </td>
                                <td class="text-center">
                                    <span *ngFor="let country of item.countries let last = last;">
                                        {{ countriesService.nameByCode(country.country_code) }}<ng-container *ngIf="!last">,</ng-container>
                                    </span>
                                </td>
                                <td class="text-center">
                                    <span *ngIf="item.current_user_is_owner && item.status != 'PRELIMINARY' && item.status != 'REJECTED'">
                                        <button class="btn btn-sicche" (click)="export(item)">{{'EXPORT' | translate}}</button>
                                    </span>
                                </td>
                            </tr>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
