<div [className]="this.isOpened ? 'opened': ''" id="checkSetupTongue" (click)="toggleOpen();">
    <div class="text-center" *ngIf="!this.isOpened">
        <p>{{ 'CHECK_SETUP' | translate }}</p>
        <h3>{{(100 - (country.logo_url ? 0 : 30) - (country.color ? 0 : 30) - (country.privacy_html ? 0 : 20) - (country.nr_blocks > 0 ? 0 : 20))}}%</h3>
        <i class="far fa-check"></i>
    </div>
    <div class="m-auto p-auto" *ngIf="this.isOpened">
        <i class="fal fa-times close-x"></i>
    </div>
</div>

<div [className]="this.isOpened ? 'opened': ''" id="checkSetupContent">
    <div class="p-4">
        <div class="row">
            <div class="col-md-12 mb-3">
                <span class="h3 text-muted">{{'RECAP' | translate}}</span>
            </div>
            <div class="col-md-12 mb-2">
                <span class="h1">{{(100 - (country.logo_url ? 0 : 30) - (country.color ? 0 : 30) - (country.privacy_html ? 0 : 20) - (country.nr_blocks > 0 ? 0 : 20))}}%</span>
            </div>
            <div class="col-md-12">
                <p>{{'RECAP_PERCENTAGE' | translate}}</p>
            </div>
            <div class="col-md-12 mb-3" *ngIf="country">
                <span class="number-icon">{{country.nr_rooms}}</span> <span>{{'ROOMS' | translate}}</span><br>
                <span class="number-icon">{{country.nr_intervieweds}}</span> <span>{{'INTERVIEWEDS.INTERVIEWEDS' | translate}}</span><br>
                <span class="number-icon"><i *ngIf="country.logo_url" class="fas fa-check text-check"></i>     <i *ngIf="!country.logo_url" class="fas fa-exclamation-triangle text-alert"></i></span> <span>{{'LOGO' | translate}}<br></span>
                <span class="number-icon"><i *ngIf="country.color" class="fas fa-check text-check"></i>        <i *ngIf="!country.color" class="fas fa-exclamation-triangle text-alert"></i></span> <span>{{ 'COLOR' | translate }}<br></span>
                <span class="number-icon"><i *ngIf="country.privacy_html" class="fas fa-check text-check"></i> <i *ngIf="!country.privacy_html" class="fas fa-exclamation-triangle text-alert"></i></span> <span>{{ 'PRIVACY_POLICY' | translate }}<br></span>
                <span class="number-icon"><i *ngIf="country.nr_blocks > 0" class="fas fa-check text-check"></i> <i *ngIf="!(country.nr_blocks > 0)" class="fas fa-exclamation-triangle text-alert"></i></span> <span>{{ 'ROOM_SET_UP' | translate }}<br></span>
            </div>
            <div class="col-md-12">
                <button type="button" class="btn-sicche"  (click)="resetSetup()"><i class="far fa-trash-alt"></i>{{'RESET_SETUP' | translate}}</button>
            </div>
        </div>
    </div>
</div>