<div class="sidebar-body">
    <section>
        <app-overview-modal-filter
            (searchFn)="getQuotesByFilter()"
            [filterForm]="filterForm"
            [color]="true"
            [highlighted]="true"
            [countryUniqId]="countryUniqId">
        </app-overview-modal-filter>
    </section>
    <app-core-spinner class="mt-2" [toggle]="operation('getting-quotes').submitting"></app-core-spinner>
    <div class="alert alert-info mt-2"  *ngIf="operation('getting-quotes').success && !quotes.length">{{ 'THERE_ARE_NO_QUOTES' | translate }}</div>
    <section class="mt-4" *ngIf="!operation('getting-quotes').submitting">

        <div class="row mt-2 mb-3">
            <div class="col-12 d-flex align-items-center justify-content-end">
                <div class="text-left flex-grow-1">
                    <h3>{{ quotes.length }} citazioni</h3>
                </div>
                <div class="check-radio-box mb-0 mr-4">
                    <input type="checkbox" (change)="selectAll($event)" id="check1">
                    <label for="check1">
                        {{ 'SELECT_ALL' | translate }}
                    </label>
                </div>
                <button
                    *ngIf="!isSmart"
                    [disabled]="!hasSelected()"
                    placement="auto"
                    [autoClose]="'outside'"
                    [ngbPopover]="downloadPopover"
                    (click)="quoteToDownload = null"
                    popoverClass="export-popover"
                    class="btn btn-sicche">
                        <i class="fal fa-download"></i>&nbsp;{{ 'DOWNLOAD_SELECTED' | translate }}
                </button>
            </div>
        </div>
        <div class="row" *ngFor="let quote of quotes;let i = index;">
            <div class="col-md-4 left-style mb-4" *ngIf="i%3 == 0 && quotes[i]">
                <div class="panel shadow p-3 h-100 my-0">
                    <div class="header d-flex align-items-center justify-content-between mb-2">
                        <div class="id">#{{ quotes[i].country_sequence_nr }}</div>
                        <div class="d-flex align-items-center justify-content-end">
                            <span [class]="'notification ' + quotes[i].color + '-dark'"></span>
                            <label class="mb-0" for="">
                                <input [(ngModel)]="quotes[i].state.selected" type="checkbox"/>
                            </label>
                        </div>
                    </div>
                    <div class="body mb-3">
                        <textarea name="quote" rows="5" (input)="checkModifications(i)" [(ngModel)]="quotes[i].quote" class="form-control">{{ quotes[i].quote }}</textarea>
                        <div class="mt-3 note-container">
                            <h5 class="mb-0">{{ 'NOTE' | translate }}</h5>
                            <textarea rows="5" (input)="checkModifications(i)" class="form-control" name="note" [(ngModel)]="quotes[i].note">{{ quotes[i].note ? quotes[i].note : '--' }}</textarea>
                        </div>
                        <div class="text-center mt-3">
                            <button [disabled]="!quotes[i].toBeSaved" (click)="saveMods(quotes[i])" class="btn btn-secondary">
                                <div *ngIf="saving == quotes[i].id" class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                {{ 'SAVE' | translate }}
                            </button>
                        </div>
                    </div>
                    <div class="footer">
                        <div class="row">
                            <div class="col-md-9">
                                <div class="info-group d-flex">
                                    <!-- AVATAR -->
                                    <div class="avatar">
                                        <div class="avatar-bg-img" *ngIf="quotes[i].team_data.avatar_url" [ngStyle]="{'background-image': 'url(' + (quotes[i].team_data.avatar_url | privatesrc) + ')'}"></div>
                                        <div *ngIf="!quotes[i].team_data.avatar_url" class="d-flex align-items-center justify-content-center placeholder-avatar">
                                            <i class="align-self-center fal fa-user"></i>
                                        </div>
                                    </div>
                                    <!-- #AVATAR -->
                                    <div class="ml-2 bold d-flex align-items-center">
                                        <div>{{ quotes[i].team_data.nickname ? quotes[i].team_data.nickname : quotes[i].team_data.firstname + ' ' + quotes[i].team_data.lastname }}</div>
                                    </div>
                                </div>
                                <div class="info-group d-flex mt-3">
                                    <i class="align-self-start fal fa-comment"></i>
                                    <div class="ml-2">
                                        <div>{{ quotes[i].room_title }} - {{ quotes[i].answer_creationtime | dateToLocaleString }} - {{ quotes[i].block_title }} - <a [ngbTooltip]="quotes[i].question_title" triggers="click:blur" [autoClose]="'outside'">{{ 'QUESTION' | translate }}</a></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 text-right">
                                <button
                                    placement="right"
                                    [autoClose]="'outside'"
                                    [ngbPopover]="downloadPopover"
                                    (click)="quoteToDownload = quotes[i]"
                                    popoverClass="export-popover"
                                    class="btn-sicche btn-icon small btn-secondary">
                                        <i class="far fa-download"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 left-style mb-4" *ngIf="i%3 == 0 && quotes[i+1]">
                <div class="panel shadow p-3 h-100 my-0">
                    <div class="header d-flex align-items-center justify-content-between mb-2">
                        <div class="id">#{{ quotes[i+1].country_sequence_nr }}</div>
                        <div class="d-flex align-items-center justify-content-end">
                            <span [class]="'notification ' + quotes[i+1].color + '-dark'"></span>
                            <label class="mb-0" for="">
                                <input [(ngModel)]="quotes[i+1].state.selected" type="checkbox"/>
                            </label>
                        </div>
                    </div>
                    <div class="body mb-3">
                        <textarea (input)="checkModifications(i+1)" rows="5" name="quote" [(ngModel)]="quotes[i+1].quote" class="form-control">{{ quotes[i+1].quote }}</textarea>
                        <div class="mt-3 note-container">
                            <h5 class="mb-0">{{ 'NOTE' | translate }}</h5>
                            <textarea rows="5" (input)="checkModifications(i+1)" class="form-control" name="note" [(ngModel)]="quotes[i+1].note">{{ quotes[i+1].note ? quotes[i+1].note : '--' }}</textarea>
                        </div>
                        <div class="text-center mt-3">
                            <button [disabled]="!quotes[i+1].toBeSaved" (click)="saveMods(quotes[i+1])" class="btn btn-secondary">
                                <div *ngIf="saving == quotes[i+1].id" class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                {{ 'SAVE' | translate }}
                            </button>
                        </div>
                    </div>
                    <div class="footer">
                        <div class="row">
                            <div class="col-md-9">
                                <div class="info-group d-flex">
                                    <!-- AVATAR -->
                                    <div class="avatar">
                                        <div class="avatar-bg-img" *ngIf="quotes[i+1].team_data.avatar_url" [ngStyle]="{'background-image': 'url(' + (quotes[i+1].team_data.avatar_url | privatesrc) + ')'}"></div>
                                        <div *ngIf="!quotes[i+1].team_data.avatar_url" class="d-flex align-items-center justify-content-center placeholder-avatar">
                                            <i class="align-self-center fal fa-user"></i>
                                        </div>
                                    </div>
                                    <!-- #AVATAR -->
                                    <div class="ml-2 bold d-flex align-items-center">
                                        <div>{{ quotes[i+1].team_data.nickname ? quotes[i+1].team_data.nickname : quotes[i+1].team_data.firstname + ' ' + quotes[i+1].team_data.lastname }}</div>
                                    </div>
                                </div>
                                <div class="info-group d-flex mt-3">
                                    <i class="align-self-start fal fa-comment"></i>
                                    <div class="ml-2">
                                        <div>{{ quotes[i+1].room_title }} - {{ quotes[i+1].answer_creationtime | dateToLocaleString }} - {{ quotes[i+1].block_title }} - <a [ngbTooltip]="quotes[i+1].question_title" triggers="click:blur" [autoClose]="'outside'">{{ 'QUESTION' | translate }}</a></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 text-right">
                                <button
                                    placement="left"
                                    [autoClose]="'outside'"
                                    [ngbPopover]="downloadPopover"
                                    (click)="quoteToDownload = quotes[i+1]"
                                    popoverClass="export-popover"
                                    class="btn-sicche btn-icon small btn-secondary">
                                        <i class="far fa-download"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 left-style mb-4" *ngIf="i%3 == 0 && quotes[i+2]">
                <div class="panel shadow p-3 h-100 my-0">
                    <div class="header d-flex align-items-center justify-content-between mb-2">
                        <div class="id">#{{ quotes[i+2].country_sequence_nr }}</div>
                        <div class="d-flex align-items-center justify-content-end">
                            <span [class]="'notification ' + quotes[i+2].color + '-dark'"></span>
                            <label class="mb-0" for="">
                                <input [(ngModel)]="quotes[i+2].state.selected" type="checkbox"/>
                            </label>
                        </div>
                    </div>
                    <div class="body mb-3">
                        <textarea (input)="checkModifications(i+2)" rows="5" name="quote" [(ngModel)]="quotes[i+2].quote" class="form-control">{{ quotes[i+2].quote }}</textarea>
                        <div class="mt-3 note-container">
                            <h5 class="mb-0">{{ 'NOTE' | translate }}</h5>
                            <textarea (input)="checkModifications(i+2)" rows="5" class="form-control" name="note" [(ngModel)]="quotes[i+2].note">{{ quotes[i+2].note ? quotes[i+2].note : '--' }}</textarea>
                        </div>
                        <div class="text-center mt-3">
                            <button [disabled]="!quotes[i+2].toBeSaved" (click)="saveMods(quotes[i+2])" class="btn btn-secondary">
                                <div *ngIf="saving == quotes[i+2].id" class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                {{ 'SAVE' | translate }}
                            </button>
                        </div>
                    </div>
                    <div class="footer">
                        <div class="row">
                            <div class="col-md-9">
                                <div class="info-group d-flex">
                                    <!-- AVATAR -->
                                    <div class="avatar">
                                        <div class="avatar-bg-img" *ngIf="quotes[i+2].team_data.avatar_url" [ngStyle]="{'background-image': 'url(' + (quotes[i+2].team_data.avatar_url | privatesrc) + ')'}"></div>
                                        <div *ngIf="!quotes[i+2].team_data.avatar_url" class="d-flex align-items-center justify-content-center placeholder-avatar">
                                            <i class="align-self-center fal fa-user"></i>
                                        </div>
                                    </div>
                                    <!-- #AVATAR -->
                                    <div class="ml-2 bold d-flex align-items-center">
                                        <div>{{ quotes[i+2].team_data.nickname ? quotes[i+2].team_data.nickname : quotes[i+2].team_data.firstname + ' ' + quotes[i+2].team_data.lastname }}</div>
                                    </div>
                                </div>
                                <div class="info-group d-flex mt-3">
                                    <i class="align-self-start fal fa-comment"></i>
                                    <div class="ml-2">
                                        <div>{{ quotes[i+2].room_title }} - {{ quotes[i+2].answer_creationtime | dateToLocaleString }} - {{ quotes[i+2].block_title }} - <a [ngbTooltip]="quotes[i+2].question_title" triggers="click:blur" [autoClose]="'outside'">{{ 'QUESTION' | translate }}</a></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 text-right">
                                <button
                                    placement="left"
                                    [autoClose]="'outside'"
                                    [ngbPopover]="downloadPopover"
                                    (click)="quoteToDownload = quotes[i+2]"
                                    popoverClass="export-popover"
                                    class="btn-sicche btn-icon small btn-secondary">
                                        <i class="far fa-download"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<ng-template #downloadPopover>
    <div class="p-3">
        <form [formGroup]="exportForm">
            <p class="mt-3"><b>{{ 'FILE_FORMAT' | translate }}</b></p>
            <div class="row">
                <div class="col-md-4">
                    <div class="check-radio-box">
                        <input formControlName="format" [value]="1" type="radio" name="format" id="radio1">
                        <label for="radio1">
                            Png
                        </label>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="check-radio-box">
                        <input formControlName="format" [value]="2" name="format" type="radio" id="radio2">
                        <label for="radio2">
                            Pdf
                        </label>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="check-radio-box">
                        <input formControlName="format" [value]="3" name="format" type="radio" id="radio3">
                        <label for="radio3">
                            Xls
                        </label>
                    </div>
                </div>
            </div>
            <span *ngIf="exportForm.controls.format.value !== 3">
                <p><b>{{ 'EXPORT_OPTIONS' | translate }}</b></p>
                <div class="row">
                    <div class="col-md-4">
                        <div class="check-radio-box">
                            <input type="radio" formControlName="nicknameorfullname" name="nicknameorfullname" value="nickname" id="exportcheck1">
                            <label for="exportcheck1">
                                {{ 'USER.NICKNAME' | translate }}
                            </label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="check-radio-box">
                            <input formControlName="nicknameorfullname" name="nicknameorfullname" value="firstname_and_lastname" type="radio" id="exportcheck2">
                            <label for="exportcheck2">
                                {{ 'USER.FIRSTNAME_LASTNAME' | translate }}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="check-radio-box">
                            <input formControlName="photo" type="checkbox" id="exportcheck3">
                            <label for="exportcheck3">
                                {{ 'PHOTO' | translate }}
                            </label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="check-radio-box">
                            <input formControlName="data" type="checkbox" id="exportcheck4">
                            <label for="exportcheck4">
                                {{ 'DATA' | translate }}
                            </label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="check-radio-box">
                            <input formControlName="question" type="checkbox" id="exportcheck5">
                            <label for="exportcheck5">
                                {{ 'QUESTION' | translate }}
                            </label>
                        </div>
                    </div>
                </div>

                <p class="mt-3"><b>{{ 'VIEW_TEMPLATE' | translate }}</b></p>
                <div class="row">
                    <div class="col-md-10 form-group">
                        <div class="templates textarea">
                            <div class="template template-1" *ngIf="exportForm.value.template == 1">
                                <div class="f12 w-100">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit
                                </div>
                                <div class="d-flex mt-2">
                                    <div class="avatar va">
                                        <!--<i class="fad fa-user-circle" style="font-size: 48px;"></i>-->
                                        <div class="avatar">
                                            <div class="d-flex align-items-center justify-content-center placeholder-avatar">
                                                <i class="align-self-center fal fa-user"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="data va">
                                        <div>
                                            <q>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit</q>
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <p class="f12">23/Nov</p>
                                                </div>
                                                <div class="col-md-9 text-right">
                                                    <p class="f12">Mario Rossi/ Fac simile</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="template template-2" *ngIf="exportForm.value.template == 2">
                                <div class="f12 w-100">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit
                                </div>
                                <div class="d-flex mt-2">
                                    <div class="data va">
                                        <div>
                                            <q>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit</q>
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <p class="f12">23/Nov</p>
                                                </div>
                                                <div class="col-md-9 text-right">
                                                    <p class="f12">Mario Rossi/ Fac simile</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="avatar va d-flex align-items-end">
                                        <!--<i class="fad fa-user-circle" style="font-size: 48px;"></i>-->
                                        <div class="avatar">
                                            <div class="d-flex align-items-center justify-content-center placeholder-avatar">
                                                <i class="align-self-center fal fa-user"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="template template-3" *ngIf="exportForm.value.template == 3">
                                <div class="avatar va mx-auto">
                                    <!--<i class="fad fa-user-circle" style="font-size: 48px;"></i>-->
                                    <div class="avatar">
                                        <div class="d-flex align-items-center justify-content-center placeholder-avatar">
                                            <i class="align-self-center fal fa-user"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="f12 w-100">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit
                                </div>
                                <div class="d-flex mt-2">
                                    <div class="data va">
                                        <div>
                                            <q>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit</q>
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <p class="f12">23/Nov</p>
                                                </div>
                                                <div class="col-md-9 text-right">
                                                    <p class="f12">Mario Rossi/ Fac simile</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="template-4" *ngIf="exportForm.value.template == 4">
                                Template 4
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 d-flex align-items-center justify-content-center">
                        <i class="fal fa-arrow-alt-circle-right text-primary-color cursor-pointer" (click)="nextTemplate()" style="font-size: 2rem;"></i>
                    </div>
                </div>

            </span>
            <div class="row w-100">
                <button [disabled]="!exportForm.valid" (click)="export(true)" class="btn btn-sicche mx-auto">{{ 'EXPORT' | translate }}</button>
            </div>
        </form>
    </div>
</ng-template>