import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { QuestionsTypesService } from '@app/shared/services/questions-types.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { RoomsService } from '@app/shared/services/rooms.service';
import { CountryService } from '@app/shared/services/country.service';
import { ResponseModel } from '@app/core/models/response.model';
import { QuestionsService } from '@app/shared/services/questions.service';
import { QuestionModel } from '@app/shared/models/question.model';
import { ɵNullViewportScroller } from '@angular/common';
import { BlockModel } from '@app/shared/models/block.model';

@Component({
  selector: 'app-setup-questions-selects',
  templateUrl: './selects.component.html',
  styleUrls: ['./selects.component.scss']
})
export class QuestionsSelectsComponent extends CoreBaseComponent implements OnInit {

  @Input()
  form: UntypedFormGroup;
  @Input()
  block: BlockModel;
  @Input()
  question: QuestionModel;
  @Input()
  countryUniqId: string;
  public ready = false;
  public sampleVariableTags = [];
  public personasTagsList = [];
  public questions = [];
  public questionsList = [];
  public answersList = [];

  constructor(public questionTypesService: QuestionsTypesService, public questionsService: QuestionsService,
              public roomsService: RoomsService, private countryService: CountryService) {
    super()
  }

  ngOnInit(): void {
    // Add fields
    this.form.addControl('condition_questionid', new UntypedFormControl(
      this.question ? this.question.condition_questionid : '')
    );
    if(this.form.controls['condition_questionid'].value == '') {
      this.form.controls['condition_questionid'].patchValue(null);
    }
    this.form.addControl('condition_questionopt', new UntypedFormControl(
      this.question ? Number(this.question.condition_questionopt) : '')
    );
    // Get questions for auto filter
    // this.questionsService.getQuestionsByBlockId([this.question ? this.question.blockid : this.block.id], false, true, {
    //   block_datetime_end: this.block.datetime_start,
    //   exclude_answerid: this.question ? this.question.id : ''
    // })
    this.questionsService.getAutomaticFilterQuestions(this.block.roomid, this.block.id)
    .subscribe((res: ResponseModel) => {
      this.questions = res.getData();
      this.questions.forEach((question: QuestionModel) => {
        this.questionsList.push(question);
        this.questionsList.map((item: QuestionModel) => item.title = item.getTitle(false));
        console.log(this.questionsList, question)

        if (this.question) {
          this.questionsList = this.questionsList.filter((item: QuestionModel) =>
            item.id !== this.question.id
            && (
              (
                item.blockid !== this.question.blockid
                && new Date(this.parseCompatibilityDate(item.block_datetime_start)).getTime() <= new Date(this.parseCompatibilityDate(this.question.block_datetime_start)).getTime()
              )
              || item.position < this.question.position
            )
          );
        }
        // Preselect
        if (this.question) {
          if (Number(question.id) === Number(this.question.condition_questionid)) {
            this.questionSelected(question, false);
          }
        }
      });
    });
    this.countryService.getPersonasTags(this.countryUniqId).subscribe((res: ResponseModel) => {
      this.personasTagsList = res.getData();
    });
    this.countryService.getCountrySetupInfo(this.countryUniqId).subscribe((res: ResponseModel) => {
      this.sampleVariableTags = res.getData().sample_variable_tags.filter(tag => tag ? true : false);
    });
    this.ready = true;
  }

  questionSelected(data: any, reset = true) {
    if (reset) {
      this.form.patchValue({
        condition_questionopt: null
      });
    }
    this.questions.forEach((question: QuestionModel) => {
      if (question.id === data.id) {
        this.answersList = question.question_data.answers;
      }
    });
  }

  autoFilterChange(data) {
  }

  sampleVariablesTags(data) {
    /*
    this.form.patchValue({
      sample_variable_tags: this.form.value.sample_variable_tags ? this.form.value.sample_variable_tags.map(
        tag => tag.hasOwnProperty('title') ? tag.title : tag ) : null
    });
    */
  }

  personasTags() {
    /*
    this.form.patchValue({
      personas_tags: this.form.value.personas_tags ? this.form.value.personas_tags.map(item => item) : null
    });
    */
  }

}
