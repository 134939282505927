<section *ngIf="form">
    <form [formGroup]="form">
        <div class="container-fluid position-relative">

            <app-setup-questions-shared-media-upload
                [question]="question"
                [images]="attachedImages"
                [pdfFiles]="attachedPdfFiles"
                (addImageOut)="addImage($event)"
                (removeImageOut)="removeImage($event)"
                (addPdfOut)="addPdf($event)"
                (removePdfOut)="removePdf($event)">
            </app-setup-questions-shared-media-upload>

            <h4 class="mb-2">{{ 'QUESTION' | translate }}</h4>
            <div class="question-text panel shadow">
                <app-setup-questions-text [form]="form" [countryUniqId]="countryUniqId"></app-setup-questions-text>
            </div>

            <div class="row">
                <div class="col-12">
                    <h4>{{ 'STATEMENTS_TO_ANSWER' | translate }}</h4>
                </div>
                <div class="col-9">
                    <div class="form-group">
                        <div formArrayName="statements" class="question-options-list">
                            <div *ngFor="let statement of statements.controls; let i = index;" class="statement-row">
                                <div class="form-group mb-0">
                                    <div class="statement mb-2 d-flex justify-content-between" [formGroup]="statement">
                                        <label>
                                            <input formControlName="statement" type="text" class="form-control">
                                            <span class="placeholder">{{ 'STATEMENT' | translate }}</span>
                                        </label>
                                    </div>
                                    <div class="statement-buttons mb-2 text-center d-flex align-items-center flex-grow-0">
                                        <div>
                                            <i (click)="removeStatement(i)" *ngIf="i >= 1" class="fal fa-minus-circle mr-2 as-link" style="font-size: 28px;"></i>
                                            <i (click)="addStatement()" class="fal fa-plus-circle as-link" style="font-size: 28px;"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group mb-0" formGroupName="question_data">
                        <label class="d-flex align-items-center input-height" for="randomize-order">
                            <input formControlName="randomize_order" type="checkbox" id="randomize-order">
                            {{ 'RANDOMIZE_ITEMS_ORDER' | translate }}
                        </label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <h4>{{ 'ANSWERS_OPTIONS' | translate }}</h4>
                </div>
                <div class="col-12 d-flex">
                    <div class="form-group required" style="display:inline-flex;max-width:400px;">
                        <app-core-select
                            [classes]="'form-control'"
                            [placeholder]="'INDICATE_HOW_MANY_STEPS_IN_THE_SCALE'"
                            [form]="form.get('question_data')"
                            [formEntityName]="'steps'"
                            [readonly]="disabled"
                            [customList]="[3, 4, 5, 6, 7, 8, 9, 10]">
                        </app-core-select>
                    </div>
                    <div class="form-group mb-0 ml-3 input-height" formGroupName="question_data">
                        <label class="d-flex align-items-center input-height" for="include-0">
                            <input formControlName="include_zero" type="checkbox" id="include-0">
                            {{ 'INCLUDE_0' | translate }}
                        </label>
                    </div>
                </div>
            </div>

            <!-- <div class="row">
                <h6>{{ 'OR_INSERT_IMAGES_SCALE' | translate }}</h6>
                <div class="col-md-12">
                    <app-setup-questions-media-upload [form]="form" [formArray]="images"></app-setup-questions-media-upload>
                </div>
            </div> -->

            <div class="row" [formGroup]="form.get('question_data')">
                <div class="col-12">
                    <h4>{{ 'LABELS_E' | translate }}</h4>
                </div>
                <div class="col-md-4">
                    <div class="form-group required">
                        <input type="text" formControlName="label_left" class="form-control">
                        <span class="placeholder">{{ 'LEFT' | translate }}</span>
                    </div>
                    <div class="form-group">
                        <label for="include-side-emoticon-left">
                            <input formControlName="include_side_emoticons" type="checkbox" id="include-side-emoticon-left" [checked]="form.value.question_data?.include_side_emoticons">
                            {{ 'INCLUDE_LABEL_DEFAULT_IMAGE' | translate }}
                            <app-sicche-emoticon [emoticon]="3" [width]="36"></app-sicche-emoticon>
                        </label>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <input type="text" formControlName="label_center" class="form-control">
                        <span class="placeholder">{{ 'CENTER' | translate }}</span>
                    </div>
                    <div class="form-group">
                        <label for="include-center-emoticon">
                            <input formControlName="include_center_emoticon" type="checkbox" id="include-center-emoticon">
                            {{ 'INCLUDE_LABEL_DEFAULT_IMAGE' | translate }}
                            <app-sicche-emoticon [emoticon]="2" [width]="36"></app-sicche-emoticon>
                        </label>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group required">
                        <input type="text" formControlName="label_right" class="form-control">
                        <span class="placeholder">{{ 'RIGHT' | translate }}</span>
                    </div>
                    <div class="form-group">
                        <label for="include-side-emoticon-right">
                            <input formControlName="include_side_emoticons" type="checkbox" id="include-side-emoticon-right" [checked]="form.value.question_data?.include_side_emoticons">
                            {{ 'INCLUDE_LABEL_DEFAULT_IMAGE' | translate }}
                            <app-sicche-emoticon [emoticon]="1" [width]="36"></app-sicche-emoticon>
                        </label>
                    </div>
                </div>
            </div>
            <app-setup-questions-selects [question]="question" [block]="block" [form]="form" [countryUniqId]="countryUniqId"></app-setup-questions-selects>
            <div class="mb-3 mt-3" *ngIf="block.is_room_group">
                <input formControlName="is_masked" type="checkbox" id="check2">
                <label for="check2">
                    {{ 'MASQUERADE_QUESTION' | translate }}
                </label>
            </div>
        </div>
    </form>
</section>