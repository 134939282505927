<app-shared-sidebar-container>
    <div class="sidebar-body">
        <!--<button class="next-prev-btn previous" *ngIf="questionType" (click)="back()">{{ 'RETURN' | translate }}</button>-->
        <div class="position-relative">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-4 fake-select" (click)="showTypes = !showTypes">
                        <div class="form-group form-group tool tags-select-tool">
                            <app-core-select
                                [classes]="'form-control'" 
                                [placeholder]="'CHOOSE'" 
                                [cache]="true"
                                [readonly]="true"
                                [searchable]="false"
                                [searchable]="true">
                            </app-core-select>
                            <div *ngIf="subtitle" class="subtitle">{{ subtitle }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <app-setup-questions-types-list [hidden]="!showTypes" (selection)="startQuestion($event)"></app-setup-questions-types-list>
        </div>

        <!-- question's list -->
        <p *ngIf="isEditorDisabled()" class="text-center text-warning p-3">{{ 'CANT_EDIT_PAST_QUESTION' | translate }}</p>
        <app-setup-questions-communications
            [disabled]="isEditorDisabled()"
            [question]="question"
            [block]="block" 
            [countryUniqId]="country_uniqid" 
            *ngIf="questionType == 'communications'" 
            (saveEmit)="onSave($event)">
        </app-setup-questions-communications>
        <app-setup-questions-attitude-scale
            [disabled]="isEditorDisabled()"
            [question]="question"
            [block]="block" 
            [countryUniqId]="country_uniqid" 
            *ngIf="questionType == 'attitude-scale'" 
            (saveEmit)="onSave($event)">
        </app-setup-questions-attitude-scale>
        <app-setup-questions-sentence-completion
            [disabled]="isEditorDisabled()"
            [question]="question"
            [block]="block" 
            [countryUniqId]="country_uniqid" 
            *ngIf="questionType == 'sentence-completion'" 
            (saveEmit)="onSave($event)">
        </app-setup-questions-sentence-completion>
        <app-setup-questions-openanswer
            [disabled]="isEditorDisabled()"
            [question]="question"
            [block]="block"
            [roomid]="roomid" 
            [countryUniqId]="country_uniqid" 
            *ngIf="questionType == 'open-answer'" 
            (saveEmit)="onSave($event)">
        </app-setup-questions-openanswer>
        <app-setup-questions-closedsingle 
            [disabled]="isEditorDisabled()"
            [question]="question"
            [block]="block" 
            [countryUniqId]="country_uniqid" 
            *ngIf="questionType == 'closed-single'" 
            (saveEmit)="onSave($event)">
        </app-setup-questions-closedsingle>
        <app-setup-questions-closedmultiple 
            [disabled]="isEditorDisabled()"
            [question]="question"
            [block]="block" 
            [countryUniqId]="country_uniqid" 
            *ngIf="questionType == 'closed-multiple'" 
            (saveEmit)="onSave($event)">
        </app-setup-questions-closedmultiple>
        <app-setup-questions-vocalanswer 
            [disabled]="isEditorDisabled()"
            [question]="question"
            [block]="block" 
            [countryUniqId]="country_uniqid" 
            *ngIf="questionType == 'vocal-answer'" 
            (saveEmit)="onSave($event)">
        </app-setup-questions-vocalanswer>
        <app-setup-questions-imageanswer 
            [disabled]="isEditorDisabled()"
            [question]="question"
            [block]="block" 
            [countryUniqId]="country_uniqid" 
            *ngIf="questionType == 'image-answer'" 
            (saveEmit)="onSave($event)">
        </app-setup-questions-imageanswer>
        <app-setup-questions-videoanswer 
            [disabled]="isEditorDisabled()"
            [question]="question"
            [block]="block" 
            [countryUniqId]="country_uniqid" 
            *ngIf="questionType == 'video-answer'" 
            (saveEmit)="onSave($event)">
        </app-setup-questions-videoanswer>
        <app-setup-questions-semantic-differentials
            [disabled]="isEditorDisabled()"
            [question]="question"
            [block]="block"
            [countryUniqId]="country_uniqid"
            *ngIf="questionType == 'semantic-differentials'"
            (saveEmit)="onSave($event)">
        </app-setup-questions-semantic-differentials>
        <app-setup-questions-grouping-category
            [disabled]="isEditorDisabled()"
            [question]="question"
            [block]="block"
            [countryUniqId]="country_uniqid"
            *ngIf="questionType == 'grouping-category'"
            (saveEmit)="onSave($event)">
        </app-setup-questions-grouping-category>
        <app-setup-questions-preference-ranking
            [disabled]="isEditorDisabled()"
            [question]="question"
            [block]="block"
            [countryUniqId]="country_uniqid"
            *ngIf="questionType == 'preference-ranking'"
            (saveEmit)="onSave($event)">
        </app-setup-questions-preference-ranking>
        <app-setup-questions-image-association
            [disabled]="isEditorDisabled()"
            [question]="question"
            [block]="block"
            [countryUniqId]="country_uniqid"
            *ngIf="questionType == 'image-association'"
            (saveEmit)="onSave($event)">
        </app-setup-questions-image-association>
        <app-setup-questions-pin-on-media
            [disabled]="isEditorDisabled()"
            [question]="question"
            [block]="block"
            [countryUniqId]="country_uniqid"
            *ngIf="questionType == 'pin-on-media'"
            (saveEmit)="onSave($event)">
        </app-setup-questions-pin-on-media>
    </div>
</app-shared-sidebar-container>
