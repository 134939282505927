import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { QuestionsService } from '@app/shared/services/questions.service';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { AnswersComponent } from './answers-modals/answers-modal.component';
import { ResearchModel } from '@app/shared/models/research.model';
import { DatePipe } from '@angular/common';
import { ResearchSessionService } from '@app/shared/services/research-session.service';
import { RoomsService } from '@app/shared/services/rooms.service';
import { ResponseModel } from '@app/core/models/response.model';
import { ActivatedRoute, Router } from '@angular/router';
import { RoomModel } from '@app/shared/models/room.model';
import { BlockService } from '@app/shared/services/block.service';
import { BlockModel } from '@app/shared/models/block.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@app/core/services/auth.service';
import { CookiesService } from '@app/core/services/cookies.service';
import { NotificationService } from '@app/shared/services/notification.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { CountryService } from '@app/shared/services/country.service';
import { TeamService } from '@app/shared/services/team.service';
import { AnswersService } from '@app/shared/services/answers.service';
import { DateToLocaleStringPipe } from '@app/core/pipes/date-to-locale-string.pipe';
import { DatetimeFromDB } from '@app/core/pipes/datetime-from-db.pipe';
import { StorageService } from '@app/core/services/storage.service';


@Component({
  selector: 'app-moderation',
  templateUrl: './moderation.component.html',
  styleUrls: ['./moderation.component.scss']
})
export class ModerationComponent extends CoreBaseComponent implements OnInit {

  private test = false; // TEST BOOLEAN
  public days = [];
  public research: ResearchModel;
  public countryUniqid: string;
  public rooms: RoomModel[] = [];
  public allRooms: RoomModel[] = [];
  public blocks: BlockModel[] = [];
  public activeBlocks: BlockModel[] = [];
  public questions: QuestionModel[] = [];
  public viewMode = 0;
  public selectedRoomId: number;
  public selectedRoom: RoomModel;
  public selectedQuestion: QuestionModel;
  public showChat = false;
  public selectedBlock = null;
  public loadingBlocks = true;
  public autoLoadingQuestions = false;
  public autoLoadingRooms = false;
  public orderby = 'desc';
  public meridian = false;
  public selectedBlockId = null;
  public formTime: UntypedFormGroup;
  @ViewChild('scrollTarget') scrollTarget;

  constructor(private questionsService: QuestionsService, private researchSessionService: ResearchSessionService,
    public cd: ChangeDetectorRef, private answersService: AnswersService,
    private activatedRoute: ActivatedRoute, private coreLayoutService: CoreLayoutService, private translate: TranslateService,
    private datePipe: DatePipe, private roomsService: RoomsService, private blocksService: BlockService, private router: Router,
    public authService: AuthService, private cookiesService: CookiesService, private dateToEuPipe: DateToLocaleStringPipe,
    private notificationService: NotificationService, private countryService: CountryService, public teamService: TeamService,
    private datetimeFromDB: DatetimeFromDB, private dateToLocaleString: DateToLocaleStringPipe, private storageService: StorageService) {
    super();
  }

  ngOnInit() {
    if (this.countryService.currentCountryIsClientBasic()) {
      return this.router.navigate(['/researches/navigation',
        this.researchSessionService.getResearchUniqId(), this.researchSessionService.getResearchCountryUniqId(), 'analysis']);
    }

    this.meridian = !this.countryService.currentCountryIsItaly();

    this.formTime = new UntypedFormGroup({
      date: new UntypedFormControl({ date: null, name: "---" }, null),
      roomid: new UntypedFormControl('', null)
    });
    this.research = this.researchSessionService.research;
    this.countryUniqid = this.activatedRoute.parent.snapshot.paramMap.get('country_uniqid');
    // Get blocks
    this.blocksService.getBlocksByCountryId(this.countryUniqid, true)
      .subscribe((res: ResponseModel) => {
        const temp = [];
        for (const key in res.getData()) {
          if (res.getData().hasOwnProperty(key)) {
            const element = res.getData()[key];
            element.datetime_start = this.datetimeFromDB.transform(element.datetime_start, this.countryService.getSessionCountry().timezone);
            element.datetime_end = this.datetimeFromDB.transform(element.datetime_end, this.countryService.getSessionCountry().timezone);
            temp.push(element);
          }
        }
        this.blocks = temp;

        this.getDays(() => {
          this.getRooms(() => {
            this.filterRooms();
          });
        });

      });

    this.notificationService.noticeClicked.subscribe(notice => {
      this.checkNotificationRoom();
    });


  }

  checkNotificationRoom() {
    if (this.notificationService.currentNotice && this.notificationService.currentNotice.roomid) {
      this.roomSelected(this.notificationService.currentNotice.roomid);
    } else {
      this.notificationService.currentNotice = null;
    }
  }

  checkNotificationBlock() {
    if (this.notificationService.currentNotice && this.notificationService.currentNotice.blockid && this.blocks) {
      const block = this.blocks.find(b => b.id === this.notificationService.currentNotice.blockid);
      this.blockSelected(block, true);
    } else {
      this.notificationService.currentNotice = null;
    }
  }

  checkNotificationQuestion() {
    if (this.notificationService.currentNotice && this.notificationService.currentNotice.questionid && this.activeBlocks) {
      const question = this.questions.find(q => q.id === this.notificationService.currentNotice.questionid);
      this.changeViewMode(0, true);
      this.openAnswersModal(question, this.scrollTarget);
      // this.notificationService.currentNotice = null;
    } else {
      // this.notificationService.currentNotice = null;
    }
  }

  getRooms(callback = null) {
    this.operation('loading-rooms').start();
    this.roomsService.getRoomsByCountryUniqid(this.countryUniqid).subscribe((res: ResponseModel) => {
      this.allRooms = res.getData();
      this.filterRooms();
      if (this.allRooms) {
        const lastSelectedRoomId = this.storageService.get(this.countryUniqid + '_selected_room');
        const lastSelectedRoom = this.rooms.find(r => r.id === parseInt(lastSelectedRoomId));
        if (lastSelectedRoom) {
          this.roomSelected(
            this.roomIsActive(lastSelectedRoom)
              ? this.storageService.get(this.countryUniqid + '_selected_room')
              : this.roomSelected(this.rooms[0].id)
          );

        } else {
          this.roomSelected(this.rooms[0].id);
        }
      }
      if (callback) {
        callback();
      }
      this.operation('loading-rooms').isEnded();
      this.checkNotificationRoom();
    });
  }

  roomSelected(roomId: number) {
    if (this.autoLoadingRooms) {
      return false;
    }
    this.autoLoadingRooms = true;
    this.selectedRoom = this.rooms.find(item => item.id == roomId);
    this.activeBlocks = [];
    this.questions = [];
    this.showChat = false;
    this.selectedRoomId = roomId;
    this.formTime.patchValue({
      roomid: roomId
    });

    this.storageService.set(this.countryUniqid + '_selected_room', this.selectedRoomId);
    const operation = this.operation('loading-blocks').start();
    setTimeout(() => {
      this.blocks.forEach((block: BlockModel) => {
        if (block.roomid === Number(roomId)) {
          if (this.blockDateIsCorrect(block)) {
            this.activeBlocks = [...this.activeBlocks, block];
          }
        }
      });
      this.orderBlocks(this.activeBlocks);
      setTimeout(() => {
        if (document.getElementById('question-cards')) {
          document.getElementById('question-cards').scrollIntoView({ behavior: 'smooth' });
        }
      }, 500);
      this.checkNotificationBlock();
      operation.isSuccess();
      this.loadingBlocks = false;
      this.autoLoadingRooms = false;
    });
  }

  blockDateIsCorrect(block: BlockModel) {
    if ((!this.formTime.value.date && !this.formTime.value.date?.date) || this.formTime.value.date == '') {
      return true;
    }
    if (!this.formTime.value.date.date) {
      return true;
    }
    /*
    non funziona
    if (this.dateToEuPipe.transform(new Date(this.parseCompatibilityDate(block.datetime_start)).toString())
      === this.dateToEuPipe.transform(this.formTime.value.date.date.toString())) {
      return true;
    }
    */

    if (
      this.getDateWithoutTime(block.datetime_start).getTime()
      === this.getDateWithoutTime(new Date(this.formTime.value.date.date)).getTime()
    ) {
      return true;
    }
    return false;
  }

  private orderBlocks(blocksList) {
    // Order by date
    blocksList.sort((blockA: BlockModel, blockB: BlockModel) => {
      const dateA = new Date(blockA.datetime_start);
      const dateB = new Date(blockB.datetime_start);
      if (dateA > dateB) {
        return 1;
      }
      if (dateA < dateB) {
        return -1;
      }
      return 0;
    });
  }

  changeSelectedBlock(block) {
    this.selectedBlock = block;
    this.selectedBlockId = block.id;
  }

  blockSelected(block: any, keepViewMode = false) {
    if (this.autoLoadingQuestions) {
      return false;
    }
    this.autoLoadingQuestions = true;

    if (!keepViewMode) {
      if (block.is_room_group) {
        this.viewMode = 0;
      } else {
        this.viewMode = 1;
      }
      // overrude default prendendo l'ultimo selezionato dall'utente
      const viewMode = this.storageService.get(this.countryUniqid + '_moderation-view-mode')
      if (viewMode) {
        this.viewMode = viewMode;
      }
    }

    this.questions = [];
    this.selectedBlockId = block.id;
    this.showChat = false;
    const operation = this.operation('loading-questions').start();
    this.selectedBlock = block;
    this.questionsService.getQuestionsByBlockId(block.id, true).subscribe((res: ResponseModel) => {
      this.autoLoadingQuestions = false;
      this.questions = res.getData();
      // Remove 'comunicazioni'
      // this.questions = this.questions.filter(q => q.type !== 'communications');
      // @ts-ignore
      this.questions.map((question, index) => question.index = (index + 1));

      this.orderby = 'asc'
      const savedOrderby = this.storageService.get(this.countryUniqid + '_moderation-sortby')
      if (savedOrderby) {
        this.questionsOrderBy(savedOrderby)
      }

      this.checkNotificationQuestion();
      setTimeout(() => {
        let questionsList = document.getElementById('questions-list');
        if (questionsList) {
          questionsList.scrollIntoView({ behavior: 'smooth' });
        }
      }, 200);
      operation.isSuccess();
    });
  }

  questionsOrderBy(how: string) {
    this.questions.sort((a, b) => {
      if (new Date(a.position) < new Date(b.position)) {
        return how === 'asc' ? 1 : -1;
      }
      if (new Date(a.position) > new Date(b.position)) {
        return how === 'asc' ? -1 : 1;
      }
      return 0;
    });
    this.orderby = how;
    this.storageService.set(this.countryUniqid + '_moderation-sortby', this.orderby)
  }

  getDays(callback = null) {
    this.countryService.getActiveDays(this.countryUniqid).subscribe(response => {
      const data = [];
      response.getData().forEach((date, index) => {
        const dateItem = {
          date,
          name: `${this.translate.instant('DAY')} ${index + 1} - ${this.dateToLocaleString.transform(date)}`
        };
        if ((new Date()).setHours(0, 0, 0, 0) === new Date(date).setHours(0, 0, 0, 0)) { // TODO funziona?
          this.changeDate({ date: dateItem.date, name: '' });
        }
        data.push(dateItem);
      });
      this.days = data;
      if (callback) {
        callback();
      }
    });
  }

  dayIsActive(dateItem: { date, name: string }) {
    if (dateItem.date) {
      if (this.blocks.length) {
        for (const block of this.blocks) {
          if (this.dateToEuPipe.transform(block.startDate.toString())
            === this.dateToEuPipe.transform(dateItem.date.toString())) {
            return true;
          }
        }
      }
    }
    return false;
  }

  changeDate(event: { date, name: string }) {
    this.questions = [];
    this.formTime.patchValue({
      date: event
    });
    const operation = this.operation('loading-blocks').start();
    this.filterRooms();
    this.activeBlocks = [];
    this.loadingBlocks = true;
    setTimeout(() => {
      this.showChat = false;
      if (event.date) {
        if (this.blocks.length) {
          this.blocks.forEach((block, index) => {
            if (this.roomsSelectedIsRight(block)) {
              if (this.getDateWithoutTime(block.datetime_start).getTime() === this.getDateWithoutTime(event.date).getTime()) {
                if (!this.activeBlocks.some(blockTmp => blockTmp.id === block.id)) {
                  this.activeBlocks = [...this.activeBlocks, block];
                }
              }
            }
          });
        }
      } else {
        if (!this.formTime.value.roomid) {
          this.activeBlocks = this.blocks;
        } else {
          this.blocks.forEach(block => {
            if (this.roomsSelectedIsRight(block)) {
              if (!this.activeBlocks.some(blockTmp => blockTmp.id === block.id)) {
                this.activeBlocks = [...this.activeBlocks, block];
              }
              this.orderBlocks(this.activeBlocks);
            }
          });
        }
      }
      operation.isSuccess();
      this.loadingBlocks = false;
    });
  }

  changeViewMode(mode: number, doNotUpdate = false) {
    this.viewMode = mode;
    if (mode === 0) {
      this.showChat = false;
    }
    if (!doNotUpdate) {
      this.cookiesService.setCookie('moderation-view-mode', this.viewMode, 1);
      this.storageService.set(this.countryUniqid + '_moderation-view-mode', this.viewMode)
      this.blockSelected(this.selectedBlock, true);
    }
  }

  private roomIsActive(room: RoomModel) {
    let roomBlocks = this.blocks.filter((block: BlockModel) => block.roomid === room.id);
    let activeBlocksLength = roomBlocks.filter((block: BlockModel) => this.blockDateIsCorrect(block)).length;
    return activeBlocksLength ? true : false;
  }

  private filterRooms() {
    if (this.formTime.value.date) {
      this.rooms = [];
      this.allRooms.forEach((room: RoomModel) => {
        if (this.roomIsActive(room)) {
          this.rooms.push(room);
        }
      });
    } else {
      this.rooms = this.allRooms;
    }
    // Select the first one or the last selected
    if (this.rooms.length) {
      const lastSelectedRoomId = this.storageService.get(this.countryUniqid + '_selected_room');
      const lastSelectedRoom = this.rooms.find(r => r.id === parseInt(lastSelectedRoomId));
      if (lastSelectedRoom) {
        this.roomSelected(
          this.roomIsActive(lastSelectedRoom)
            ? this.storageService.get(this.countryUniqid + '_selected_room')
            : this.roomSelected(this.rooms[0].id)
        );

      } else {
        this.roomSelected(this.rooms[0].id);
      }
    }


  }


  roomsSelectedIsRight(block: BlockModel) {
    if (!this.formTime.value.roomid) {
      return true;
    } else {
      if (this.formTime.value.roomid === block.roomid) {
        return true;
      }
    }
    return false;
  }

  private setReadSubscriber = null;
  private onSidebarCloseSubscriber = null;

  openAnswersModal(question: QuestionModel, scrollTo: HTMLElement) {
    if (this.onSidebarCloseSubscriber) {
      this.onSidebarCloseSubscriber.unsubscribe();
    }
    this.onSidebarCloseSubscriber = this.coreLayoutService.sidebarOnClosed$.subscribe(questionId => {
      if (questionId) {
        // Set as read all the available ids
        let totalToSetRead = this.answersService.toSetRead.length;
        if (this.setReadSubscriber) {
          this.setReadSubscriber.unsubscribe();
        }
        if (question && questionId === question.id) {
          question.nr_answers_unread -= totalToSetRead;
        }
        this.setReadSubscriber = this.answersService.setAnswersRead().subscribe((readN: number) => {

        });
        this.answersService.toSetRead = [];
      }
    });

    // todo REFACTORING
    if (this.viewMode === 0) {
      this.coreLayoutService.openSidebar(AnswersComponent, { question, room: this.selectedRoom });
    } else {
      if (this.showChat) {
        this.showChat = false;
        setTimeout(() => {
          scrollTo.scrollIntoView({ behavior: 'smooth' });
          this.showChat = true;
          this.selectedQuestion = question;
        }, 500);
      } else {
        setTimeout(() => {
          scrollTo.scrollIntoView({ behavior: 'smooth' });
          this.showChat = true;
          this.selectedQuestion = question;
        }, 500);
      }
    }
    this.coreLayoutService.closingMessage(question.id);
  }

}
