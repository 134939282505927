import { Component, Input, OnInit, EventEmitter, Output, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalComponent } from '../modal/modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-core-lightbox-modal',
  templateUrl: './lightbox-modal.component.html',
  styleUrls: ['./lightbox-modal.component.scss']
})
export class LightboxModalComponent extends ModalComponent implements OnInit {

    public image = null;
    public images: Array<any> = null;
    public currentImage = null;
    public loading = true;
    public index = null;

    constructor(public activeModal: NgbActiveModal) {
        super(activeModal);
    }

    ngOnInit() {
        this.loading = true;
        this.image = this.params.image;
        this.images = this.params.images;
        if (isNaN(this.image)) {
            this.loadImage(this.image);
        } else {
            this.index = this.image;
            this.loadImage(this.images[this.image]);
        }
    }

    @HostListener('document:keydown.arrowleft', ['$event']) onKeyLeftHandler(event: KeyboardEvent) {
        if (this.images) {
            this.image = this.index === 0 ? this.images.length - 1 : this.index--;
            this.loadImage(this.images[this.index]);
        }
    }

    @HostListener('document:keydown.arrowright', ['$event']) onKeyRightHandler(event: KeyboardEvent) {
        if (this.images) {
            this.image = this.index === this.images.length - 1 ? 0 : this.index++;
            this.loadImage(this.images[this.index]);
        }
    }

    closeModal() {
        this.activeModal.close();
    }

    loadImage(image) {
        this.loading = true;
        this.currentImage = new Image();
        this.currentImage.onload = () => {
            this.loading = false;
        };
        this.currentImage.src = image.image_url;
    }
}
