import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResponseModel } from '@app/core/models/response.model';
import { AuthService } from '@app/core/services/auth.service';
import { AnswerModel } from '@app/shared/models/answer.model';
import { CommentModel } from '@app/shared/models/comment.model';
import { QuestionModel } from '@app/shared/models/question.model';
import { AnalysisService } from '@app/shared/services/analysis.service';

@Component({
  selector: 'app-export-external-view',
  templateUrl: './export-external-view.component.html',
  styleUrls: ['./export-external-view.component.scss']
})
export class ExportExternalViewComponent implements OnInit {

  token: string;
  data: any;

  comment: CommentModel;
  answer: AnswerModel;

  question: QuestionModel;
  images = [];
  videos = [];


  constructor(
    private activatedRoute: ActivatedRoute,
    private analysisService: AnalysisService,
  ) { }

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.paramMap.get('token');
    this.analysisService.getExternalViewData(this.token).subscribe((res: ResponseModel) => {
      this.data = res.getData()

      if (this.data.data_type === 'comment') {
        this.comment = new CommentModel(this.data.content_data)
        this.answer = new AnswerModel(this.data.content_data.answer_data)
      }

      if (this.data.data_type === 'answer') {
        this.answer = new AnswerModel(this.data.content_data)
      }
    })

  }

}
