import { BaseModel } from '@app/core/models/base';
import { BaseSubTypeModelSchema } from '@app/core/models/base-sub-types-list.interface';
import { QuestionModel } from './question.model';
import { TeamModel } from './team.model';


export class CommentModel extends BaseModel {

    // tslint:disable: variable-name
    id: number;
    answer_tags: Array<any>;
    creationtime: any;
    comment: string;
    flag_like: boolean;
    parentid: number;
    team: TeamModel;
    device: string;
    answerid: number;
    blockid: number;
    country_uniqid: string;
    countryid: number;
    creatoruserid: number;
    currentuser_like_answer: boolean;
    currentuser_like_comment: boolean;
    extension: {}
    private_userid: number;
    questionid: number;
    roomid: number;
    timezone: string;
    total_like_to_comment: number;
    comment_otherdata: any;
    quotes?: Array<any>;
    comment_tags: Array<any>;
    stars: number;

    room_title?: string;
    question_title?: string;
    block_title?: string;
    

    static getResourceName() {
        return 'comments';
    }

    // !beta
    static getSubTypesList(): BaseSubTypeModelSchema[] {
        return [
            {object: 'team', model: TeamModel}
        ];
    }

    isSmartphone() {
        return this.device === 'SMARTHONE';
    }

    isMobile() {
        return this.device === 'MOBILE' || this.device === 'SMARTHONE';
    }

    isDesktop() {
        return this.device === 'DESKTOP';
    }

    isPrivate() {
        return this.private_userid != 0;
    }

    hasVideos() {
        if(!this.comment_otherdata) {
            return false;
        }
        if(!this.comment_otherdata.videos) {
            return false;
        }
        if(this.comment_otherdata.videos.length) {
            return true;
        }
    }

    hasAudios() {
        if(!this.comment_otherdata) {
            return false;
        }
        if(!this.comment_otherdata.audios) {
            return false;
        }
        if(this.comment_otherdata.audios.length) {
            return true;
        }
    }

    hasImages() {
        if(!this.comment_otherdata) {
            return false;
        }
        if(!this.comment_otherdata.images) {
            return false;
        }
        if(this.comment_otherdata.images.length) {
            return true;
        }
    }

    videos() {
        if(!this.comment_otherdata) {
            return [];
        }
        if(!this.comment_otherdata.videos) {
            return [];
        }
        return this.comment_otherdata.videos;
    }

    images() {
        if(!this.comment_otherdata) {
            return [];
        }
        if(!this.comment_otherdata.images) {
            return [];
        }
        return this.comment_otherdata.images;
    }

    audios() {
        if(!this.comment_otherdata) {
            return [];
        }
        if(!this.comment_otherdata.audios) {
            return [];
        }
        return this.comment_otherdata.audios;
    }

    hasMedia() {
        if(!this.comment_otherdata) {
            return false;
        }
        if(this.comment_otherdata.images && this.comment_otherdata.images.length) {
            return true;
        }
        if(this.comment_otherdata.videos && this.comment_otherdata.videos.length) {
            return true;
        }
        if(this.comment_otherdata.audios && this.comment_otherdata.audios.length) {
            return true;
        }
    }

}
