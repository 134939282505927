import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { ModalService } from '@app/core/services/modal.service';
import { TeamService } from '@app/shared/services/team.service';
import { DragScrollComponent } from 'ngx-drag-scroll';
@Component({
  selector: 'app-p-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  title = 'sicche';

  public isSpecialCustomer = false;

  constructor(private modalService: ModalService, private teamService: TeamService, private authService: AuthService) {
  }

  ngOnInit() {
    this.isSpecialCustomer = this.authService.user.otherdata.first_role === 'CUSTOMER_BASIC'
          || this.authService.user.otherdata.first_role === 'CUSTOMER_PRO';
  }

  ngOnDestroy() {
  }
}
