
<!-- with sidebar -->
<ng-container *ngIf="sidebar">
    <div class="core-layout-sidebar-spinner" *ngIf="coreLayoutService.sidebarBlock$.value">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <ng-sidebar-container [class]="coreLayoutService.classes$.value">
        <ng-sidebar
          #sidebar
          [sidebarClass]="'main-sidebar'"
          [ariaLabel]="'Sidebar'"
          [(opened)]="sidebarOpen"
          [position]="coreLayoutService.sidebarPosition$.value"
          [animate]="true"
          (onClosed)="onSidebarClose()"
          [closeOnClickBackdrop]="!coreLayoutService.sidebarBlock$.value ? coreLayoutService.closeSidebarOnClickBackdrop$.value : false"
          [closeOnClickOutside]="!coreLayoutService.sidebarBlock$.value ? coreLayoutService.closeSidebarOnClickOutside$.value : false"
          [mode]="MODES[modeNum]"
          [dock]="dock"
          [dockedSize]="'50px'"
          [autoCollapseHeight]="autoCollapseHeight"
          [autoCollapseWidth]="autoCollapseWidth"
          [showBackdrop]="showBackdrop"
          [trapFocus]="trapFocus"
          [autoFocus]="autoFocus">
          <div>
              <ng-container *ngIf="coreLayoutService.buttons$.value || coreLayoutService.title$.value">
                <header class="sidebar-header">
                    <!-- decidi con Laviz -->
                    <div class="d-flex align-items-center justify-content-between w-100">
                        <div class="sidebar-title flex-grow-1" [ngClass]="{'': coreLayoutService.title$.value && coreLayoutService.buttons$.value, 'col-md-12': coreLayoutService.title$.value && !coreLayoutService.buttons$.value}">
                            <!-- button left of title (like 'back' button?) -->
                            <div class="title-button" *ngIf="coreLayoutService.titleButton$.value">
                                <button (click)="exe(coreLayoutService.titleButton$.value.click)" [class]="coreLayoutService.titleButton$.value.classes">
                                    <i *ngIf="coreLayoutService.titleButton$.value.icon && !coreLayoutService.titleButton$.value.loading" [class]="coreLayoutService.titleButton$.value.icon"></i>
                                    <span *ngIf="coreLayoutService.titleButton$.value.loading" class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </span>
                                    <span *ngIf="!coreLayoutService.titleButton$.value.loading">{{ coreLayoutService.titleButton$.value.label | translate }}</span>
                                </button>
                            </div>
                            <!-- title -->
                            <div class="title">
                                <h2 class="mb-0">{{ coreLayoutService.title$.value | translate }}</h2>
                            </div>
                        </div>
                        <div [ngClass]="{' flex-grow-0 d-flex align-items-center': coreLayoutService.title$.value, '': !coreLayoutService.title$.value && coreLayoutService.buttons$.value}">
                            <button (click)="exe(button.click)" [class]="button.classes" *ngFor="let button of coreLayoutService.buttons$.value; let i = index;">
                                <i *ngIf="button.icon && !button.loading" [class]="button.icon"></i>
                                <span *ngIf="button.loading" class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </span>
                                <span *ngIf="!button.loading">{{ button.label | translate }}</span>
                            </button>
                        </div>
                        <div class="close-button" [ngClass]="{'disabled': coreLayoutService.sidebarBlock$.value }" (click)="!coreLayoutService.sidebarBlock$.value ? coreLayoutService.closeSidebar() : null">
                            <i class="fal fa-times cross"></i>
                        </div>
                    </div>
                </header>
              </ng-container>
              <ng-template #componentContainer></ng-template>
          </div>
      </ng-sidebar>
      <!-- Page content INFO when used specify the content like this <div slot="with-sidebar"> -->
      <div ng-sidebar-content>
          <ng-content select="[slot=with-sidebar]"></ng-content>
      </div>
  </ng-sidebar-container>  
</ng-container>

<!-- Page content without sidebar INFO when used specify the content like this <div slot="free"> -->
<ng-container *ngIf="!sidebar" class="{{ coreLayoutService.classes$.value }}">
    <ng-content select="[slot=free]"></ng-content>
</ng-container>

<div class="dev-tools" *ngIf="coreLayoutService.devToolbar">
    <div class="d-flex">
        <div class="title">Dev tools</div>
        <ul>
            <li>
                <a (click)="forceLogout()" title="logout">Logout</a>
            </li>
        </ul>
    </div>
</div>