<section>
    <form [formGroup]="form">
        <div class="container-fluid position-relative">

            <app-setup-questions-shared-media-upload
                [question]="question"
                [images]="attachedImages"
                [pdfFiles]="attachedPdfFiles"
                (addImageOut)="addImage($event)"
                (removeImageOut)="removeImage($event)"
                (addPdfOut)="addPdf($event)"
                (removePdfOut)="removePdf($event)">
            </app-setup-questions-shared-media-upload>

            <h4 class="mb-2">{{ 'QUESTION' | translate }}</h4>
            <div class="question-text panel shadow">
                <app-setup-questions-text [form]="form" [countryUniqId]="countryUniqId"></app-setup-questions-text>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="form-group align-items-start required" style="display:inline-flex;min-width:300px;">
                        <app-core-select
                            [classes]="'form-control'"
                            [placeholder]="'N_REQUIRED_WORDS_OR_ADJECTIVES'"
                            [form]="form.get('question_data')"
                            [formEntityName]="'n_required_words'"
                            [readonly]="disabled"
                            [customList]="[1, 2, 3, 4, 5]">
                        </app-core-select>
                    </div>
                </div>
                <div class="col-12 mt-2" formGroupName="question_data">
                    <label for="mandatory_comment">
                        <input formControlName="mandatory_comment" type="checkbox" id="mandatory_comment">
                        {{ 'ADD_MANDATORY_COMMENT' | translate }}
                    </label>
                </div>
            </div>

            <app-setup-questions-selects [question]="question" [block]="block" [form]="form" [countryUniqId]="countryUniqId"></app-setup-questions-selects>
            <div *ngIf="block.is_room_group" class="mb-3 mt-3">
                <input formControlName="is_masked" type="checkbox" id="check2">
                <label for="check2">
                    {{ 'MASQUERADE_QUESTION' | translate }}
                </label>
            </div>
        </div>
    </form>
</section>