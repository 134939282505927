import { Component, OnInit, Input } from '@angular/core';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { UntypedFormGroup, Validators, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { UserService } from '@app/shared/services/user.service';
import { AuthService } from '@app/core/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ResponseModel } from '@app/core/models/response.model';
import { Mode } from '../../../../../../../../setup';
import { CustomValidators } from '@app/core/validators/validators';

@Component({
  selector: 'app-public-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends CoreBaseComponent implements OnInit {

  @Input()
  token: string;
  form: UntypedFormGroup = null;
  passwordMinLength = Mode.PASSWORD_MIN_LENGTH;

  constructor(private formBuilder: UntypedFormBuilder, private userService: UserService, private authService: AuthService,
              private translate: TranslateService) {
      super();
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      token: new UntypedFormControl(this.token, Validators.required),
      password: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$')
      ]),
      repeatpassword: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$')
      ])
    }, {
      validator: CustomValidators.Match('password', 'repeatpassword')
    });
  }

  submit() {
    this.operation('requesting').reset().isSubmitting();
    this.authService.resetPassword(this.form.getRawValue()).subscribe((res: ResponseModel) => {
      this.operation('requesting').isSuccess(res.getErrors());
    }, (error) => {
      this.operation('requesting').isFailed(error.errors);
    });
  }


}
