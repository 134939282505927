import { TranslationWidth } from '@angular/common';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { DatetimeToDbPipe } from '@app/core/pipes/datetime-to-db.pipe';
import { NgbDate, NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-shared-datepicker-range',
  templateUrl: './datepicker-range.component.html',
  styleUrls: ['./datepicker-range.component.scss'],
  providers: [{provide: NgbDatepickerI18n, useClass: DatepickerRangeComponent}],
})
export class DatepickerRangeComponent extends NgbDatepickerI18n implements OnInit {

  @Output() dateChange = new EventEmitter();
  @Input() from;
  @Input() to;
  @Input() roomid;


  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  weekdaysKeys = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
  monthsKeys = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

  constructor(public calendar: NgbCalendar, private translate: TranslateService, private dateToDb: DatetimeToDbPipe) {
    super();
    this.fromDate = calendar.getToday();
    this.toDate = this.dateToNgb(this.to) || calendar.getNext(calendar.getToday(), 'd', 3);
  }

  ngOnInit(): void {
    this.fromDate = this.dateToNgb(this.from) || this.fromDate;
    this.toDate = this.dateToNgb(this.to) || this.toDate;
  }

  getWeekdayShortName(weekday: number): string {
    return this.translate.instant('CALENDAR_VALUES.WEEKDAYS_SHORT.' + this.weekdaysKeys[weekday - 1]);
  }
  getMonthShortName(month: number): string {
    return this.translate.instant('CALENDAR_VALUES.MONTHS_SHORT.' + this.monthsKeys[month - 1]);
  }
  getMonthFullName(month: number): string {
    return this.translate.instant('CALENDAR_VALUES.MONTHS_LONG.' + this.monthsKeys[month - 1]);
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    return this.translate.instant('CALENDAR_VALUES.WEEKDAYS_SHORT.' + this.weekdaysKeys[weekday - 1]);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return '';
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    const from = this.ngbToDate(this.fromDate);
    const to = this.ngbToDate(this.toDate);
    if (from && to) {
      this.dateChange.emit({from: this.dateToDb.transform(from), to: this.dateToDb.transform(to), roomid: this.roomid});
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  dateToNgb(dateString) {
    const date = new Date(dateString);
    if (date) {
      return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
    }
    return false;
  }
  ngbToDate(ngbDate: NgbDate) {
    if (ngbDate) {
      return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
    }
    return false;
  }
}
