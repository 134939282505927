import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TeamModel } from '@app/shared/models/team.model';
import { FormGroup, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { TeamService } from '@app/shared/services/team.service';
import { ResponseModel } from '@app/core/models/response.model';

@Component({
  selector: 'app-private-edit-interviewed',
  templateUrl: './edit-interviewed.component.html',
  styleUrls: ['./edit-interviewed.component.scss']
})
export class EditInterviewedComponent implements OnInit {
  @Input() user: TeamModel;
  @Input() tags: string[];
  @Output() edited = new EventEmitter();


  constructor(
    private toastService: ToastrService,
    private translateService: TranslateService,
    private teamService: TeamService
  ) { }

  ngOnInit(): void {
  }


  submit() {
    this.teamService.addModifyUser(this.user).subscribe((res: ResponseModel) => {
      this.toastService.success(this.translateService.instant('DATA_UPDATED'));
    });
  }

  updateTags(e) {
    this.user.sample_variable_tags = e;
  }

  removeAvatar() {
    this.user.avatar_del = true;
    this.user.avatar = null;
    this.user.avatar_url = null;
    this.submit();
  }

  lockUser() {
    this.user.is_locked = !this.user.is_locked;
  }
  delAvatar() {
    this.user.avatar_del = !this.user.avatar_del;
  }

  changeAvatar(files: FileList) {
    if (files.item(0).type.indexOf('image') !== -1) {
      let newImage;
      const reader = new FileReader();
      reader.onload = (e) => {
        newImage = e.target.result;
        this.user.avatar = newImage;
      };
      reader.readAsDataURL(files.item(0));
    } else {
      this.toastService.error(this.translateService.instant('FILE_IS_NOT_IMAGE'));
    }
  }
}
