import { Component, OnInit, Input, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { AnswerModel } from '@app/shared/models/answer.model';
import { ModerationHelperService } from '@app/shared/services/moderation-helper.service';
import { TeamService } from '@app/shared/services/team.service';
import { QuoteModel } from '@app/shared/models/quote.model';

@Component({
  selector: 'app-moderation-answerstype-image-association',
  templateUrl: './image-association.component.html',
  styleUrls: ['./image-association.component.scss']
})
export class AnswersTypeImageAssociationComponent extends CoreBaseComponent implements OnInit {

  @Input() question: QuestionModel;
  @Input() answer: AnswerModel;
  @Input() classes: string;
  @Input() explicitMediaUrls: boolean;
  @Output() textSelection: EventEmitter<any> = new EventEmitter(null);

  public quotes: Array<QuoteModel>;


  constructor(
    private teamService: TeamService,
    private moderationHelperService: ModerationHelperService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {

    if(!this.teamService.isCurrentUserCustomerPro() && !this.teamService.isCurrentUserCustomerBasic()) {
      if(this.answer.quotes) {
        this.quotes = this.answer.quotes;
      }
      this.moderationHelperService.quotes.subscribe(quotes => {
        this.quotes = quotes;
        this.cdr.detectChanges();
      });

      this.printQuotes()
      setInterval(() => {
        this.printQuotes()
      }, 1000)
    }
  }

  printQuotes() {
    for (const a of this.answer.answer_data) {
      if (a.comment) {
        a.comment = a.comment.replace(/(\r\n|\n|\r)/gm, "<br />");
        if(this.quotes) {
          this.quotes.forEach(quote => {
            const qclass = quote.color ? quote.color : 'grey';
            if(quote.quote_original && quote.quote_original.length) {
              quote.quote_original.forEach(q => {
                a.comment = a.comment.replace(q, '<em class="highlight ' + qclass + '">'+ q +'</em>');
              });
            } else {
              a.comment = a.comment.replace(quote.quote, '<em class="highlight ' + qclass + '">' + quote.quote +'</em>');
            }
          });
        }
      }
    }
  }

  selectText() {
    this.textSelection.emit({commentid:null});
  }

  getImage(optionid: number) {
    // for image-association the optionid is temporarily the index of the selected image
    // (as they are arranged in the question_data.attached_images array)
    return this.question.question_data.images[optionid];
  }
}
