<ng-container *ngIf="answer">
  <div class="p-0 mb-4 {{ classes }}" *ngIf="answer.answer_data">
    <div *ngIf="answer.answer_data.rating" class="d-inline-block mt-3 mr-3">
      <div class="card">
        <div class="p-3">
          <span class="font-weight-bold">{{ 'RATING' | translate }}</span>: {{ answer.answer_data.rating }}
        </div>
      </div>
    </div>
    <div *ngIf="answer.answer_data.comment" class="d-inline-block mt-3 mr-3">
      <div class="card">
        <div class="p-3" (mouseup)="selectText()">
          <span class="font-weight-bold">{{ 'COMMENT' | translate }}</span>: <span [innerHTML]="comment"></span>
        </div>
      </div>
    </div>

    <div class="mt-5" *ngIf="answer.answer_data.pins">
      <div>
        <div *ngIf="question.question_data.media_type === 'image'" class="d-flex flex-column align-items-center">
          <div class="card p-3">
            <div class="image-pins-wrap">
              <img #pinnedImage class="card-img-top" [src]="question.question_data.image.image_url | privatesrc" alt="" (load)="ready = true">
              <ng-container *ngIf="ready">
                <div *ngFor="let pin of answer.answer_data.pins"
                    class="pin"
                    [class.selected]="selectedPin && pin.pin_uniqueid === selectedPin.pin_uniqueid"
                    [style.top]="getImagePinDistance(pin).top"
                    [style.left]="getImagePinDistance(pin).left"
                    (click)="pinSelect(pin)">
                    <!-- [popoverTitle]="pinPopTitle" -->
                    <span *ngIf="pin.emoticon">
                      <app-sicche-emoticon [emoticon]="pin.emoticon" [width]="26"></app-sicche-emoticon>
                    </span>
                    <span *ngIf="!pin.emoticon"><i class="fas fa-thumbtack m-0"></i></span>
                </div>
              </ng-container>
            </div>
            <div *ngIf="explicitMediaUrls" class="explicit-media-url mt-2">
              <a [href]="question.question_data.image.image_url">{{ 'MEDIA_DOWNLOAD' | translate }}</a>
              <span class="media-file-name">{{ explicitMediaFileName(question.question_data.image.image_url) }}</span>
            </div>
          </div>
        </div>

        <div *ngIf="question.question_data.media_type === 'video' && question.question_data.require_pins" class="video-pins-wrap">
          <div class="card">
            <div class="d-flex flex-column align-items-center">
              <video [hidden]="explicitMediaUrls" #videoplayer class="answer-video mb-3" controls playsinline (loadedmetadata)="onVideoMetaLoad()">
                <source [src]="question.question_data.video ? question.question_data.video.video_url : question.question_data.videos[0].video_url" type="video/mp4">
              </video>
              <div *ngIf="explicitMediaUrls" class="d-flex flex-column align-items-center">
                <div class="pdf-placeholder pdf-video"></div>
              </div>

              <div *ngIf="explicitMediaUrls" class="explicit-media-url mb-3">
                <a [href]="question.question_data?.video?.video_url">{{ 'MEDIA_DOWNLOAD' | translate }}</a>
                <span class="media-file-name">{{ explicitMediaFileName(question.question_data.video ? question.question_data.video.video_url : question.question_data.videos[0].video_url) }}</span>
              </div>
            </div>
          </div>

          <ng-container *ngIf="ready">
            <div class="video-pins-bar-wrap">
              <div class="video-pins-bar">
                <ng-container *ngFor="let pin of answer.answer_data.pins">
                  <div
                    class="pin"
                    [class.selected]="selectedPin && pin.pin_uniqueid === selectedPin.pin_uniqueid"
                    [style.left]="getVideoPinDistance(pin)"
                    (click)="pinSelect(pin)">
                    <span *ngIf="pin.emoticon">
                      <app-sicche-emoticon [emoticon]="pin.emoticon" [width]="26"></app-sicche-emoticon>
                    </span>
                    <span *ngIf="!pin.emoticon"><i class="fas fa-thumbtack m-0"></i></span>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <div *ngFor="let time of timeAxis" class="time-seg p-2">
                {{ time | secondsToTime }}
              </div>
              <div class="time-seg p-2">
                {{ videoplayer.duration | secondsToTime }}
              </div>
            </div>
          </ng-container>
        </div>

        <div class="mt-3">
          <ng-container *ngIf="showPin" [ngTemplateOutlet]="pinView"></ng-container>
        </div>

        <div *ngIf="explicitMediaUrls" class="w-100 mt-3">
          <h5>{{ 'COMMENTS' | translate }}</h5>
          <div (mouseup)="selectText()" *ngFor="let pin of answer.answer_data.pins" class="d-flex align-items-center">
            <div class="d-flex align-items-center">
              <ng-container *ngIf="question.question_data.media_type === 'video'">
                <span class="mr-3">{{ pin.time | secondsToTime }}</span>
              </ng-container>
              <ng-container *ngIf="question.question_data.media_type === 'image'">
                <span class="mr-3">({{ pin.position.x | number: '1.0-0' }}, {{ pin.position.y | number: '1.0-0' }})</span>
              </ng-container>
              <span *ngIf="pin.emoticon" class="mr-5">
                <app-sicche-emoticon [emoticon]="pin.emoticon" [width]="26"></app-sicche-emoticon>
              </span>
            </div>
            <div class="pin-comment" [innerHTML]="pin.commentHTML"></div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="itemToModify === answer.id" class="row mt-3">
      <div class="col-8">
        <form [formGroup]="form">
          <div *ngIf="answer.answer_data.comment" class="row align-items-center mb-3">
            <!-- general comment -->
            <div class="col-3 d-flex align-items-center">
              <span class="mr-3">{{ 'COMMENT' | translate }}</span>
            </div>
            <div class="col-9 form-group mb-0">
              <input type="text" class="form-control" formControlName="comment">
            </div>
          </div>

          <div *ngIf="form.controls.pins" formArrayName="pins">
            <div *ngFor="let c of formPins.controls; let i = index" class="mb-3">
              <div class="row align-items-center">
                <!-- identify pin -->
                <div class="col-3 d-flex align-items-center">
                  <span *ngIf="answer.answer_data.pins[i].emoticon" class="mr-3">
                    <app-sicche-emoticon [emoticon]="answer.answer_data.pins[i].emoticon" [width]="26"></app-sicche-emoticon>
                  </span>
                  <ng-container *ngIf="question.question_data.media_type === 'video'">
                    <span>{{ answer.answer_data.pins[i].time | secondsToTime }}</span>
                  </ng-container>
                  <ng-container *ngIf="question.question_data.media_type === 'image'">
                    <span>({{ answer.answer_data.pins[i].position.x | number: '1.0-0' }}, {{ answer.answer_data.pins[i].position.y | number: '1.0-0' }})</span>
                  </ng-container>
                </div>
                <!-- form input -->
                <div class="col-9 form-group mb-0">
                  <input type="text" class="form-control" [formControlName]="i">
                  <span class="placeholder">{{ 'COMMENT' | translate }}</span>
                </div>
              </div>
            </div>
          </div>
        </form>
        <button class="btn btn-success" (click)="saveMods()">
          <span *ngIf="operation('saving').submitting" class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
          </span>
          {{ 'SAVE' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>


<ng-template #pinView>
  <div class="card p-3 pin-view">
      <div *ngIf="selectedPin.emoticon" class="text-center emoticon">
          <app-sicche-emoticon [emoticon]="selectedPin.emoticon" [width]="26"></app-sicche-emoticon>
      </div>
      <div *ngIf="selectedPin.comment" class="text-center" [class.mt-2]="selectedPin.emoticon" (mouseup)="selectText()">
          <span [innerHTML]="selectedPin.commentHTML"></span>
      </div>
  </div>
</ng-template>