import { Component, OnInit, Input } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { AnswerModel } from '@app/shared/models/answer.model';

@Component({
  selector: 'app-moderation-answerstype-preference-ranking',
  templateUrl: './preference-ranking.component.html',
  styleUrls: ['./preference-ranking.component.scss']
})
export class AnswersTypePreferenceRankingComponent extends CoreBaseComponent implements OnInit {

  @Input() question: QuestionModel;
  @Input() answer: AnswerModel;
  @Input() classes: string;
  @Input() explicitMediaUrls: boolean;

  constructor() {
    super();
  }

  ngOnInit(): void { }

  getItem(optionid: number) {
    return this.question.question_data.items.find(a => a.id === optionid);
  }

  posStr(p: number) {
    return this.question.question_data.best_worst ? `#${p}` : `#${-p}`;
  }
}
