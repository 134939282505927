import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { ModalComponent } from '../components/modal/modal.component';
import { ModalOptions } from '../components/modal/modal-options';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  lastModalOpening = 0;
  baseDefaultOptions: ModalOptions;

  constructor(private ngbModal: NgbModal, private logger: NGXLogger) {
  }

  initDefaults() {
    const baseDefaultOptions: ModalOptions = {
      title: 'Message',
      message: 'Test message',
      btnOkText: 'OK',
      btnCancelText: 'CANCEL',
      btnCloseText: 'CLOSE',
      showOkBtn: true,
      showCancelBtn: false,
      showCloseBtn: false,
      showBtnClose: true,
      dialogSize: 'sm',
      customClass: null,
      backdrop: 'static',
      modalModel: ModalComponent
    };
    return baseDefaultOptions;
  }

  /**
   * open
   * @param T Model of modal, contains the default options
   * @param ModalOptions customOptions params: override the default options of Model passed
   * @return Promise<any> result of modal
   */
  open(customOptions?: ModalOptions, params?) {
    if (new Date().getTime() - this.lastModalOpening < 1000) {
      this.lastModalOpening = new Date().getTime();
      return null
    }

    this.baseDefaultOptions = this.initDefaults();
    customOptions = customOptions ? customOptions : { modalModel: ModalComponent };
    let modalOptions: ModalOptions;
    if (customOptions.modalModel && customOptions.modalModel.defaultOptions) {
      modalOptions = Object.assign(this.baseDefaultOptions, customOptions.modalModel.defaultOptions);
    } else {
      modalOptions = Object.assign(this.baseDefaultOptions, customOptions);
    }
    modalOptions = Object.assign(modalOptions, customOptions);
    const modalRef: NgbModalRef = this.ngbModal.open(customOptions.modalModel ? customOptions.modalModel : ModalComponent,
      {
        backdrop: typeof modalOptions.backdrop === 'boolean' ? modalOptions.backdrop : 'static',
        size: modalOptions.dialogSize, windowClass: modalOptions.customClass ? modalOptions.customClass : null
      });
    modalRef.componentInstance.options = modalOptions;
    modalRef.componentInstance.params = params;
    this.lastModalOpening = new Date().getTime();
    return modalRef.result;
  }

}
