import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { ResponseModel } from '@app/core/models/response.model';
import { AnswerModel } from '@app/shared/models/answer.model';
import { CommentModel } from '@app/shared/models/comment.model';
import { AnalysisService } from '@app/shared/services/analysis.service';
import { CountryService } from '@app/shared/services/country.service';

@Component({
  selector: 'app-post-export',
  templateUrl: './post-export.component.html',
  styleUrls: ['./post-export.component.scss']
})
export class PostExportComponent extends CoreBaseComponent implements OnInit {

  public answers: AnswerModel[] = [];
  public comments = [];
  public commentsNotLinkedToAnswers = [];
  public countryUniqId = null;
  public dataUniqid = null;
  public contentTags = [];
  public data = [];

  constructor(private analysisService: AnalysisService, private activatedRoute: ActivatedRoute, private countryService: CountryService) {
    super();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(data => {
      this.countryUniqId = data.country_uniqid;
      this.dataUniqid = data.data_uniqid;
    });
    this.get();
  }

  get() {
    // this.analysisService.getAllData(this.countryUniqId, 'answer_tags').subscribe((res: ResponseModel) => {
      // Get answers
      this.analysisService.getAllData(this.countryUniqId, this.dataUniqid).subscribe(async (res: ResponseModel) => {
        this.contentTags = res.getData().country_data[0].answer_tags;

        const temp = res.getData().posts ? res.getData().posts : [];

        // reinstanzio gli oggetti come answer o comment a seconda di isAnswer
        for (let item of temp) {
          if (item.isAnswer) {
            item.data = new AnswerModel(item.data);
          } else {
            item.data = new CommentModel(item.data);
          }
        }

        this.data = temp;
        console.log(this.data)
        // document.documentElement.style.setProperty('--orange', this.data.country.color);
      });
    // });
  }

  getCommentsOfAnswer(answer: AnswerModel) {
    let output = [];
    if (answer) {
      output = this.comments.filter(c => c.answerid === answer.id)
    }
    return output;
  }

}
