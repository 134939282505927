import {Component, ElementRef, ViewChild, ViewEncapsulation, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router, ActivatedRoute} from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { ResponseModel } from '@app/core/models/response.model';

@Component({
  selector: 'app-customer-login',
  templateUrl: './customer-login.component.html',
  styleUrls: ['./customer-login.component.scss']
})
export class CustomerLoginComponent implements OnInit {

  constructor(private translate: TranslateService,
              private router: Router,
              private route: ActivatedRoute, private authService: AuthService) {
  }

  ngOnInit() {
    const country_uniqid = this.route.snapshot.paramMap.get('country_uniqid');
    const research_uniqid = this.route.snapshot.paramMap.get('research_uniqid');
    const token = this.route.snapshot.paramMap.get('token');

    this.authService.customCall('login/customer')
    .create({country_uniqid, token}).subscribe((res: ResponseModel) => {
        this.authService.setAuthData(res.getData(), {isCustomerPro: true});
        // redirect to research
        this.router.navigate(['researches', 'navigation', research_uniqid, country_uniqid, 'moderation']);
    });
  }

}
