import { Injectable } from '@angular/core';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { BaseService } from '@app/core/services/base.service';
import { ApiService } from '@app/core/services/api.service';
import { Router } from '@angular/router';
import { InterviewedsModel } from '@shared/models/intervieweds.model';

@Injectable({
  providedIn: 'root'
})
export class InterviewedsService extends BaseService implements BaseServiceI {

  constructor(public router: Router, public apiService: ApiService) {
    super(router, apiService, InterviewedsModel);
  }

}
