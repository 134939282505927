import { Pipe, PipeTransform } from '@angular/core';
import * as angular from 'angular';

@Pipe({
    name: 'datetime'
})

export class DatetimeToDbPipe implements PipeTransform {
    transform(value: Date | string, timezone = null, ...args: unknown[]): any {
        if (!value) {
            return null
        }
        let datetime = new Date(this.parseCompatibilityDate(value));
        const from = angular.copy(datetime);
        // if (timezone) {
        //     datetime = this.convertTZ(new Date(value), timezone);
        // }

        // console.log('trasforming date based on ' + timezone, from, datetime);

        if (args[0]) {
            return datetime.getFullYear() + '-' +
            ('0' + (datetime.getMonth() + 1)).slice(-2) + '-' +
            ('0' + datetime.getDate()).slice(-2);
        } else {
            return datetime.getFullYear() + '-' +
            ('0' + (datetime.getMonth() + 1)).slice(-2) + '-' +
            ('0' + datetime.getDate()).slice(-2) + ' ' +
            ('0' + datetime.getHours()).slice(-2) + ':' +
            ('0' + datetime.getMinutes()).slice(-2) + ':' +
            ('0' + datetime.getSeconds()).slice(-2);
        }

    }
    convertTZ(date, tzString) {
        return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));
    }

    replaceAll(str, search, replace) {
        return str.replace(new RegExp(search, 'g'), replace);
      }



    parseCompatibilityDate(value: string | Date) {
        if (typeof value === 'string') {
            if (value.indexOf('-') !== -1) {
            value = this.replaceAll(value, '-', '/');
            }
            // @ts-ignore
            if (value.indexOf('+') !== -1) {
            value = value.toString();
            value = value.substring(0, value.indexOf('+'));
            }
        }
        return value;
    }
}
