import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-core-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

    @Input() toggle: boolean;
    @Input() style = 'vertical';
    @Input() title: string;
    @Input() description: string;

    constructor() { }

    ngOnInit() {}

}
