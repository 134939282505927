import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { QuestionsTypesService } from '@app/shared/services/questions-types.service';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { RoomsService } from '@app/shared/services/rooms.service';
import 'quill-emoji/dist/quill-emoji.js';

@Component({
  selector: 'app-setup-questions-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class QuestionsTextComponent implements OnInit {

  @Input()
  form: UntypedFormGroup;
  @Input()
  countryUniqId: string;

  public quillModules = {
    'emoji-shortname': true,
    'emoji-textarea': true,
    'emoji-toolbar': true,
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
      ['clean'],
      ['link', 'video', 'file'],
      ['emoji']
    ]
  };

  constructor(public questionTypesService: QuestionsTypesService, public roomsService: RoomsService) {
  }

  ngOnInit(): void {
  }

}
