<section>
    <form [formGroup]="form">
        <div class="container-fluid">

            <app-setup-questions-shared-media-upload
                [question]="question"
                [images]="attachedImages"
                [pdfFiles]="attachedPdfFiles"
                (addImageOut)="addImage($event)"
                (removeImageOut)="removeImage($event)"
                (addPdfOut)="addPdf($event)"
                (removePdfOut)="removePdf($event)">
            </app-setup-questions-shared-media-upload>

            <h4 class="mb-2">{{ 'QUESTION' | translate }}</h4>
            <div class="question-text panel shadow">
                <app-setup-questions-text [form]="form" [countryUniqId]="countryUniqId"></app-setup-questions-text>
            </div>
            <div class="row">
                <div class="col-12">
                    <h4>{{ 'ITEMS' | translate }}</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-7">
                    <div class="form-group align-items-start">
                        <div formArrayName="items" class="question-options-list" cdkDropList (cdkDropListDropped)="changeItemsOrder($event)">
                            <div *ngFor="let item of items['controls']; let i = index;" class="answer-row" cdkDrag>
                                <div class="answer my-2">
                                    <div class="handler">
                                        <i class="far fa-grip-lines" style="font-size: 18px;"></i>
                                    </div>
                                    <app-setup-questions-answer-option [form]="item" [answer]="item"></app-setup-questions-answer-option>
                                    <div class="category-buttons text-center">
                                        <div>
                                            <i
                                                *ngIf="i >= (question_data.get('how_many').value ? question_data.get('how_many').value : 3)"
                                                (click)="removeItem(i)"
                                                class="fal fa-minus-circle mr-2 as-link"
                                                style="font-size: 28px;">
                                            </i>
                                            <i
                                                *ngIf="(i+1) === items['controls'].length"
                                                (click)="addItem()"
                                                class="fal fa-plus-circle as-link"
                                                style="font-size: 28px;">
                                            </i>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="mb-3 mt-4">
                        <div class="form-group" formGroupName="question_data">
                            <label for="randomize-order">
                                <input formControlName="randomize_order" type="checkbox" id="randomize-order">
                                {{ 'RANDOMIZE_ITEMS_ORDER' | translate }}
                            </label>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="mb-2 bold">{{ 'RANK_BY' | translate }}</div>
                            <div class="form-group">
                            <div class="" formGroupName="question_data">
                                <label class="switch" for="best_worst_switch">
                                <span class="">{{ 'WORST' | translate }}</span>
                                <input formControlName="best_worst" type="checkbox" id="best_worst_switch">

                                <span class="slider round"></span>

                                <span class="ml-4">{{ 'BEST' | translate }}</span>
                                    <!--<span class="ml-3">{{ (question_data.controls['best_worst'].value ? 'BEST' : 'WORST') | translate }}</span>-->
                                </label>
                            </div>

                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="mb-2 bold"><span>{{ 'HOW_MANY_TO_RANK' | translate }}</span> <span class="px-3">*</span></div>
                        <div class="form-group d-inline-flex" formGroupName="question_data">
                            <label class="mr-3" for="how-many-3">
                                <input formControlName="how_many" type="radio" value="3" id="how-many-3">
                                3
                            </label>
                            <label class="mr-3" for="how-many-5">
                                <input formControlName="how_many" type="radio" value="5" id="how-many-5">
                                5
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="mb-3" formGroupName="question_data" >
                    <input formControlName="mandatory_comment" type="checkbox" id="mandatory_comment">
                    <label for="mandatory_comment">
                        {{ 'ADD_MANDATORY_COMMENT' | translate }}
                    </label>
                </div>
            </div>
            <app-setup-questions-selects [question]="question" [block]="block" [form]="form" [countryUniqId]="countryUniqId"></app-setup-questions-selects>
            <h4 *ngIf="block.is_room_group" class="mb-2">{{ 'GENERALS' | translate }}</h4>
            <div *ngIf="block.is_room_group" class="row question-checks mt-3">
                <div class="col-6">
                    <div class="mb-3">
                        <input formControlName="is_masked" type="checkbox" id="check2">
                        <label for="check2">
                            {{ 'MASQUERADE_QUESTION' | translate }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </form>
</section>