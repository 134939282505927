import { Component, OnInit, ViewChild, AfterViewInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { QuestionsService } from '@app/shared/services/questions.service';
import { NgScrollbar } from 'ngx-scrollbar';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { getLocaleDayNames } from '@angular/common';
import { ResearchModel } from '@app/shared/models/research.model';
import { DatePipe } from '@angular/common';
import { ResearchSessionService } from '@app/shared/services/research-session.service';
import { RoomsService } from '@app/shared/services/rooms.service';
import { ResponseModel } from '@app/core/models/response.model';
import { ActivatedRoute } from '@angular/router';
import { RoomModel } from '@app/shared/models/room.model';
import { BlockService } from '@app/shared/services/block.service';
import { BlockModel } from '@app/shared/models/block.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@app/core/services/auth.service';
import 'quill-emoji/dist/quill-emoji.js';
import { TeamModel } from '@app/shared/models/team.model';
import { TeamService } from '@app/shared/services/team.service';
import { PageModel } from '@app/core/models/page';
import { AnswersService } from '@app/shared/services/answers.service';
import { MessageModel } from '@app/shared/models/message.model';
import { DatetimeToDbPipe } from '@app/core/pipes/datetime-to-db.pipe';
import { ToastrService } from 'ngx-toastr';
import { AnswerModel } from '@app/shared/models/answer.model';
import { ModalService } from '@app/core/services/modal.service';
import { LightboxModalComponent } from '@app/core/components/lightbox-modal/lightbox-modal.component';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CountryService } from '@app/shared/services/country.service';
import { CookiesService } from '@app/core/services/cookies.service';
import { AnswersModalSupportService } from '@app/shared/services/support/answers-modal.service';
import { CommentModel } from '@app/shared/models/comment.model';
import { ModerationHelperService } from '@app/shared/services/moderation-helper.service';


@Component({
  selector: 'app-moderation-peopleview-answer',
  templateUrl: './answer.component.html',
  styleUrls: ['./answer.component.scss']
})
export class ModerationPeopleAnswerComponent extends CoreBaseComponent implements OnInit, AfterViewInit {

  @Input()
  answer: AnswerModel;
  @Input()
  selectedTeamUser: TeamModel;
  @Output()
  replyFeedback: EventEmitter<AnswerModel> = new EventEmitter(null);

  @Input()
  roomid: number;
  @Input()
  question: QuestionModel;
  @Input()
  country_uniqid: string;
  @Output()
  textSelection: EventEmitter<any> = new EventEmitter(null);
  @Input()
  questionid: number;
  public deleted = false;
  public loadingChat = true;
  public answerIdToModify = null;
  public answerTextModified = null;
  public mediaTab = null;
  public contentTagsForm: UntypedFormGroup;
  public contentTagsReceived = true;
  public contentTags = [];
  public message = null;

  public isCustomerPro = false;
  public quillModules = {
    'emoji-shortname': true,
    'emoji-textarea': true,
    'emoji-toolbar': true,
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      ['emoji']
    ]
  };

  public intervieweds: TeamModel[] = null;
  public answerText = null;

  // Selection modal
  public quoteForm: UntypedFormGroup = null;

  constructor(private questionsService: QuestionsService, private researchSessionService: ResearchSessionService,
              private translate: TranslateService, public teamService: TeamService, private toastService: ToastrService,
              private countryService: CountryService, private cookiesService: CookiesService, private cdr: ChangeDetectorRef,
              private answersModalSupportService: AnswersModalSupportService, public moderationHelperService: ModerationHelperService,
              private answersService: AnswersService, private datetimeToDbPipe: DatetimeToDbPipe, private modalService: ModalService,
              private datePipe: DatePipe, private roomsService: RoomsService, private blocksService: BlockService,
              public authService: AuthService, private answerService: AnswersService) {
                super();
              }

  async ngOnInit() {
    this.isCustomerPro = this.teamService.isCurrentUserCustomerPro();
    this.moderationHelperService.teamLockChange.subscribe(team => {
      if(team) {
        if(this.answer.comments.length) {
          this.answer.comments.forEach(comment => {
            if(Number(comment.team.userid) === Number(team.userid)) {
              comment.team.is_locked = team.is_locked;
            }
          });
        }
      }
    });
    // Quote form
    this.quoteForm = new UntypedFormGroup({
      quote: new UntypedFormControl('', Validators.required),
      quote_tags: new UntypedFormControl('', null),
      note: new UntypedFormControl('', null),
      color: new UntypedFormControl('', null)
    });
    const countryUniqid: string = this.countryService.getSessionCountry().country_uniqid;
    this.countryService.getContentTags(countryUniqid, 'answer_tags').subscribe((res: ResponseModel) => {
      this.contentTags = res.getData().map(item => item.name.toLowerCase());
    });
    this.contentTagsForm = new UntypedFormGroup({
      content_tags: new UntypedFormControl(this.answer.answer_tags ?
        this.answer.answer_tags.length ? this.answer.answer_tags : null : null, null),
    });
    if (this.answer.question_type === 'open-answer') {
      this.mediaTab = this.answer.answer_data.images.length ? 'images' : this.answer.answer_data.videos.length ? 'video' : null;
    }
    if (!this.answer.read) {
      this.answersService.setAsRead(this.answer.id).subscribe((res: ResponseModel) => {});
    }
    this.processComments();
  }

  processComments() {
    // Content tags
    this.moderationHelperService.contentTagList = [];
    this.answer.comments.forEach(comment => {
      this.moderationHelperService.contentTagList = this.moderationHelperService.contentTagList.concat(comment.comment_tags);
      // @ts-ignore
      comment.commentTagsForm = new UntypedFormGroup({
        content_tags: new UntypedFormControl(comment.comment_tags ?
          comment.comment_tags ? comment.comment_tags : null : null, null),
      });
      // @ts-ignore
      comment.commentTagsForm.valueChanges.subscribe(changes => {
        // @ts-ignore
        this.tagsChangedComment(comment, comment.commentTagsForm.value);
      });
    });
    // Quotes
    this.addQuotes();
  }


  addQuotes(reset = false) {
    if(!this.teamService.isCurrentUserCustomerPro() && !this.teamService.isCurrentUserCustomerBasic()) {
      this.answer.comments.forEach(comment => {
        if(comment.quotes) {
          this.quoteCopyOriginalText(comment, reset);
          this.quotePrintQuotes(comment);
        }
      });
      this.moderationHelperService.quotes.subscribe(quotes => {
        if(quotes) {
          quotes.forEach(quote => {
            this.answer.comments.forEach(comment => {
              if(comment.id === quote.commentid) {
                comment.quotes.push(quote);
              }
            });
          });
          this.answer.comments.forEach(comment => {
            if(comment.quotes) {
              this.quoteCopyOriginalText(comment);
              this.quotePrintQuotes(comment);
            }
          });
          this.cdr.detectChanges();
        }
      });
    }
  }

  quotePrintQuotes(comment) {
    //@ts-ignore
    comment.comment = comment.comment ? comment.comment?.replaceAll(/\s{2,}/g, ' ')?.replaceAll(/\r?\n|\r/g, '') : '';
    if(comment.quotes != null) {
      comment.quotes.forEach(quote => {
        if(quote.commentid === comment.id) {
          if(comment.comment) {
            const qclass = quote.color ? quote.color : 'grey';
            if(quote.quote_original && quote.quote_original.length) {
              quote.quote_original.forEach(q => {
                comment.comment = comment.comment.replace(q, '<em class="highlight ' + qclass + '">'+ q +'</em>');
              });
            } else {
              // old way - before 29/July/2021
              comment.comment = comment.comment.replace(quote.quote, '<em class="highlight ' + qclass + '">' + quote.quote + '</em>');
            }
          }
        }
        /*
        if(this.commentAnswer.videos().length && quote.commentid === this.commentAnswer.id) {
          this.commentAnswer.videos().forEach(video => {
            this.hlTranscription(video, quote);
          });
        }
        */
      });
    }
  }

  quoteCopyOriginalText(comment, reset = false) {
    // Answer text
    if(!comment.originalText || reset) {
      comment.originalText = comment.comment;
    }
    comment.comment = comment.originalText;
    /*
    // Video transcription
    this.commentAnswer.videos().forEach(video => {
      video.hlTranscription = '';
      if (video.video_data.file_words) {
        video.video_data.file_words.forEach(word => {
          video.hlTranscription += word.word + ' ';
        });
      }
    });
    */
  }

  tagsChangedComment(comment, tags: any) {
    tags = tags.content_tags;
    tags = tags.map(tag => tag.name ? tag.name.toLowerCase() : tag.toLowerCase());
    this.answerService.updateComment(comment.id, {
      answerid: comment.answerid,
      commentid: comment.id,
      comment_tags: tags.map(tag => tag.name ? tag.name.toLowerCase() : tag.toLowerCase())
    }).subscribe((res: ResponseModel) => {
      this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));

      // Add any new tag to the list of usable tags
      // todo: usa lo stesso metodo per le personas, che è più efficiente
      tags.forEach(tag => {
        if (!this.moderationHelperService.contentTagList.includes(tag)) {
          this.moderationHelperService.contentTagList.push(tag);
        }
      });
      this.moderationHelperService.contentTagList = [...this.moderationHelperService.contentTagList ];
      // Sort by name
      this.moderationHelperService.contentTagList.sort(function(a,b) {
        if (a > b) {
          return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
      });
      comment.answer_tags = tags;
    }, (e) => {
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
    });
  }

  rated(rating: number, answer: any) {
    this.answersService.updateAnswer(answer.id, {
      stars: rating
    }).subscribe((res: ResponseModel) => {
      this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
      answer.stars = rating;
    }, (e) => {
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
    });
  }

  deleteAnswer(answer: any) {
    this.modalService.open({title: 'ARE_YOU_SURE.?',
        btnOkText: 'YES',
        btnCancelText: 'CANCEL',
        showBtnClose: false,
        showCancelBtn: true,
        customClass: 'confirm-modal',
        message: 'ARE_YOU_SURE_LOGOUT.?'
      }).then((data) => {
        if (data) {
          this.answersService.deleteAnswer(answer.id).subscribe((res: ResponseModel) => {
            this.deleted = true;
            this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
          }, (e) => {
            this.toastService.error(this.translate.instant('ERROR_GENERIC'));
          });
        }
    });
  }

  tagsChanged(tags: any, answer: AnswerModel) {
    tags = tags.map(tag => tag.name ? tag.name.toLowerCase() : tag.toLowerCase());
    this.answerService.updateAnswer(this.answer.id, {
      answer_tags: tags.map(tag => tag.name ? tag.name.toLowerCase() : tag.toLowerCase())
    }).subscribe((res: ResponseModel) => {
      this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
      this.answer.answer_tags = tags;
    }, (e) => {
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
    });
  }

  ngAfterViewInit() {
    // this.scrollbarRef.scrollToElement(document.getElementById('chat-end'));
  }

  reply(answer: AnswerModel) {
    this.replyFeedback.emit(answer);
  }

  setFavourite(status: boolean) {
    this.answersService.updateAnswer(this.answer.id, {
      favourite: status
    }).subscribe((res: ResponseModel) => {
      this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
      this.answer.favourite = status;
    }, (e) => {
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
    });
  }

  selectText(comment?) {
    // this.answersModalSupportService.answerSelected$.next(this.answer);
    this.textSelection.emit(comment ? {commentid: comment.id} : null);
  }

  // todo refactor
  modifyAnswer(answer: any) {
    this.message = {action: 'modify-answer', data: answer.id};
  }

  saveAnswerTextMod(answer: any) {
    this.operation('saving').reset().isSubmitting();
    const data = answer;
    data.answer_data.text = this.answerTextModified;
    this.answersService.updateAnswer(answer.id, data).subscribe((res: ResponseModel) => {
      answer.answer_data.text = this.answerTextModified;
      this.answerTextModified = null;
      this.answerIdToModify = null;
      this.operation('saving').isSuccess();
      this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
    }, (e) => {
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
    });
  }

  openLightbox(image: any | number, mediaArray?: Array<any>) {
    this.modalService.open({modalModel: LightboxModalComponent, dialogSize: 'custom'}, {image, images: mediaArray});
  }

  // COMMENTS
  // todo refactor, sposta in sotto componente

  public commentToModify: any;
  public commentNewText: null;
  public deletedComments = [];

  modifyComment(comment: any) {
    this.commentNewText = comment.comment;
    this.commentToModify = comment;
  }

  saveCommentMods(comment: any) {
    this.operation('saving-comment').reset().isSubmitting();
    this.answersService.updateComment(comment.id, {
        answerid: this.answer.id,
        comment: this.commentNewText,
        commentid: comment.id,
        flag_like: false
    }).subscribe((res: ResponseModel) => {
        comment.comment = this.commentNewText;
        this.addQuotes(true);
        this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
        this.commentToModify = null;
        this.operation('saving-comment').isSuccess();
    }, (e) => {
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
      this.operation('saving-comment').isFailed();
    });
  }

  likeComment(comment: CommentModel) {
    if (this.teamService.currentUser$.role === 'CUSTOMER_PRO') {
      return false;
    }
    if (comment.currentuser_like_comment) {
      this.answerService.unlikeComment(comment.id, comment.answerid).subscribe((res: ResponseModel) => {
        comment.currentuser_like_comment = false;
        comment.total_like_to_comment--;
      });
    } else {
      this.answerService.likeComment(comment.id, comment.answerid).subscribe((res: ResponseModel) => {
        comment.currentuser_like_comment = true;
        comment.total_like_to_comment++;
      });
    }
  }

  deleteComment(comment: any) {
    this.modalService.open({title: 'ARE_YOU_SURE.?',
        btnOkText: 'YES',
        btnCancelText: 'CANCEL',
        showBtnClose: false,
        showCancelBtn: true,
        customClass: 'confirm-modal',
        message: 'ARE_YOU_SURE_DELETE.?'
      }).then((data) => {
        if (data) {
          // this.answer.nr_comments--; non serve qui
          this.operation('deleting-comment').reset().isSubmitting();
          this.answersService.deleteComment(comment.id).subscribe((res: ResponseModel) => {
            this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
            this.deletedComments.push(comment.id);
            this.operation('deleting-comment').isSuccess();
          }, (e) => {
            this.toastService.error(this.translate.instant('ERROR_GENERIC'));
            this.operation('deleting-comment').isFailed(e);
          });
        }
    });
  }


  likeAnswer(answer: AnswerModel) {
    if (this.teamService.currentUser$.role === 'CUSTOMER_PRO') {
      return false;
    }
    if (answer.currentuser_like_answer) {
      this.answerService.unlike(answer.id).subscribe((res: ResponseModel) => {
        answer.currentuser_like_answer = false;
      });
    } else {
      this.answerService.like(answer.id).subscribe((res: ResponseModel) => {
        answer.currentuser_like_answer = true;
      });
    }
  }




}
