<div class="row">
    <div class="col-12 text-right">
        <div class="pointer ml-2">
            <button class="pointer btn-sicche btn-secondary" (click)="checkStatus()">
                {{'MONITORING_SECTION.CHECK_STATUS' | translate}}
            </button>
        </div>
    </div>
    <div class="col-12 text-right my-3">
        <div class="row">
            <div class="col-md-3 form-group mb-0">
                <app-core-select
                *ngIf="rooms"
                [classes]="'form-control'"
                [multiple]="false"
                [cache]="true"
                [customList]="rooms"
                [label]="'title'"
                [value]="'id'"
                [searchable]="true"
                (change)="filterByRoom($event)"
                >
                </app-core-select>
                <label class="placeholder">{{'FILTER_BY_ROOM' | translate}}</label>
            </div>
            <div class="col-md-3 d-flex align-items-center">
                <div class="pointer clickable">
                    <!--<i class="pointer fal fa-tags text-primary-color"></i>-->
                    <div class="check-radio-box mb-0">
                        <input type="checkbox" id="monitorFilter1" (click)="showFilterByVariable()">
                        <label for="monitorFilter1" style="font-size: 1rem;">
                            {{'MONITORING_SECTION.VARIABLE_FILTER' | translate}}
                        </label>
                    </div>
                </div>
                <div class="pointer clickable ml-3" (click)="loadUsers(true)">
                    <i class="pointer fal fa-file-export text-primary-color"></i>
                    {{'MONITORING_SECTION.EXPORT_TABLE' | translate}}
                </div>
            </div>
            <div class="col-md-6">
                <button [disabled]="selected.length == 0" class="btn-sicche btn-secondary" (click)="sendMessage()">{{'MONITORING_SECTION.SEND_EMAIL' | translate}}</button>
                <!-- <button [disabled]="selected.length == 0" class="btn-sicche" (click)="createPassword()">{{'MONITORING_SECTION.CREATE_PASSWORD' | translate}}</button> -->
                <button [disabled]="selected.length == 0" class="btn-sicche btn-secondary" (click)="deleteTeam()">{{'MONITORING_SECTION.DELETE_TEAM' | translate}}</button>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12" *ngIf="showVariablesTags">
        <div class="form-group">
            <app-core-select
            [classes]="'form-control'"
            [multiple]="true"
            [cache]="true"
            [customList]="country.sample_variable_tags"
            [label]="'name'"
            [value]="'value'"
            [searchable]="true"
            (change)="filterByVariableTags($event)"
            >
            </app-core-select>
            <span class="placeholder">{{'SAMPLE_VARIABLE_TAGS' | translate}}</span>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12 px-4 bg-white shadow panel">
        <app-core-spinner class="mt-3 mb-2" [toggle]="loadingUsers"></app-core-spinner>
        <div class="position-relative">
            <div class="table-responsive">
                <app-core-table
                *ngIf="users"
                [items]="users"
                [columns]="[
                    {icon_name: 'fal fa-check-square'},
                    {icon_name: 'fas fa-circle', class:'text-center'},
                    {name: '', class:'text-center'},
                    {name: 'MONITORING_SECTION.LOGIN', class:'text-left', icon_name: 'fal fa-sort'},
                    {name: 'MONITORING_SECTION.NAME', class:'text-left', icon_name: 'fal fa-sort'},
                    {name: 'MONITORING_SECTION.LAST_LOGIN', class:'text-left'},
                    {name: 'MONITORING_SECTION.ANSWER_TOTAL', class:'text-center', icon_name: 'fal fa-sort'},
                    {name: 'MONITORING_SECTION.POSTS_TOTAL', class:'text-center'},
                    {name: 'MONITORING_SECTION.MISSING_ANSWERS', class:'text-center'},
                    {name: 'MONITORING_SECTION.UNANSWERED_NOTICE', class:'text-center'},
                    {name: 'MONITORING_SECTION.QUOTES', class:'text-center'},
                    {icon_name: 'fal fa-star', tooltip: 'MONITORING_SECTION.TOOLTIPS.STARS', placement:'left'},
                    {icon_name: 'fal fa-comment-slash', tooltip: 'MONITORING_SECTION.TOOLTIPS.ALMOST_MUTED', placement:'left'},
                    {icon_name: 'fal fa-thumbs-down', tooltip: 'MONITORING_SECTION.TOOLTIPS.DISLIKE', placement:'left'},
                    {icon_name: 'fal fa-medal', tooltip: 'MONITORING_SECTION.TOOLTIPS.MEDAL', placement:'left'}
                ]"
                tableid="'messages'"
                [perPage]="99999999"
                [rowTemplate]="rowTemplate"
                (columnClick)="columnClicked($event)"
                >
                </app-core-table>
                <ng-template let-item="item" #rowTemplate>
                    <tr class="text-center">
                        <td class="text-center clickable-icon">
                            <input type="checkbox" (change)="addRemoveToSelected(item.userid)" [checked]="selected.indexOf(item.userid) !== -1">
                        </td>
                        <td class="text-center">
                            <i *ngIf="item.status_activity == 'ANSWER_LESS_6_HR'"  class="fas fa-circle" style="color: #5C9667"></i>
                            <i *ngIf="item.status_activity == 'ANSWER_LESS_24_HR'"  class="fas fa-circle" style="color: #C9D8BC"></i>
                            <i *ngIf="item.status_activity == 'ANSWER_MORE_24_HR'"  class="fas fa-circle" style="color: #FBDD6A"></i>
                            <i *ngIf="item.status_activity == 'HAS_LOGGED_IN'"      class="fas fa-circle" style="color: #DD8956"></i>
                            <i *ngIf="item.status_activity == 'NEVER_LOGGED_IN'"    class="fas fa-circle" style="color: #F05242"></i>
                        </td>
                        <td class="text-center">
                            <!--<img *ngIf="item.avatar_url" [src]="item.avatar_url | privatesrc" alt="" class="avatar">-->
                            <div class="avatar">
                                <div *ngIf="item.avatar_url" class="avatar-bg-img" [ngStyle]="{'background-image': 'url(' + (item.avatar_url | privatesrc) + ')'}">
                                </div>
                            </div>
                        </td>
                        <td class="text-left">
                            {{item.email}}
                        </td>
                        <td class="text-left">
                            {{item.nickname}}
                        </td>
                        <td class="text-left">
                            {{item.last_login_datetime | dateToLocaleString: true}}
                        </td>
                        <td class="text-center">
                            {{item.nr_questions_with_answers}}/{{item.nr_questions_total}}
                        </td>
                        <td class="text-center">
                            {{item.nr_post_total}}
                        </td>
                        <td class="text-center" [ngClass]="item.missing_answers?.length > 0 ? 'underlined clickable' : ''" (click)="(item.missing_answers && (item.nr_questions_total - item.nr_questions_with_answers) > 0) ? openMissingAnswersModal(item.missing_answers, item) : null">
                            {{item.missing_answers_count || 0}}
                        </td>

                        <td class="text-center">
                            {{item.notices_without_answer}}
                        </td>
                        <td class="text-center">
                            {{item.nr_quote_total}}
                        </td>
                        <td class="text-center">
                            {{item.nr_stars_1 + item.nr_stars_2 + item.nr_stars_3}}
                        </td>
                        <td class="text-center">
                            {{item.nr_almost_muted_answer}}
                        </td>
                        <td class="text-center clickable-icon">
                            <i (click)="thumbsDown(item)" class="fal fa-thumbs-down" [style]="!item.monitoring_dislike ? 'color: #b5b5b5' : 'font-weight: 700'"></i>
                        </td>
                        <td class="text-center clickable-icon">
                            <i (click)="medal(item)" class="fal fa-medal" [style]="!item.monitoring_medal ? 'color: #b5b5b5' : 'font-weight: 700'"></i>
                        </td>
                    </tr>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="col-12 mt-5">
        <label>
            <i class="fas fa-circle" style="color: #5C9667"></i>
            {{'MONITORING_SECTION.ANSWER_LESS_6_HR' | translate}}
        </label>
        <br>
        <label>
            <i class="fas fa-circle" style="color: #C9D8BC"></i>
            {{'MONITORING_SECTION.ANSWER_LESS_24_HR' | translate}}
        </label>
        <br>
        <label>
            <i class="fas fa-circle" style="color: #FBDD6A"></i>
            {{'MONITORING_SECTION.ANSWER_MORE_24_HR' | translate}}
        </label>
        <br>
        <label>
            <i class="fas fa-circle" style="color: #DD8956"></i>
            {{'MONITORING_SECTION.HAS_LOGGED_IN' | translate}}
        </label>
        <br>
        <label>
            <i class="fas fa-circle" style="color: #F05242"></i>
            {{'MONITORING_SECTION.NEVER_LOGGED_IN' | translate}}
        </label>
        <br>
    </div>
    <!-- ovc per modifiche tabella vedere ticket 694 -->
        <div class="col-12 text-right my-3" *ngIf="multipleAnswersFound">
            <div class="row">
                <div class="col-md-3 form-group mb-0">
                    <app-core-select
                    *ngIf="rooms"
                    [classes]="'form-control'"
                    [multiple]="false"
                    [cache]="true"
                    [customList]="rooms"
                    [label]="'title'"
                    [value]="'id'"
                    [searchable]="true"
                    (change)="filterByRoomOVC($event)"
                    >
                    </app-core-select>
                    <label class="placeholder">{{'FILTER_BY_ROOM' | translate}}</label>
                </div>
                <div class="col-md-9 text-right">
                    <button [disabled]="selectedOVC.length == 0" class="btn-sicche btn-secondary" (click)="sendMessage()">{{'MONITORING_SECTION.SEND_EMAIL' | translate}}</button>
                    <!-- <button [disabled]="selectedOVC.length == 0" class="btn-sicche" (click)="createPassword()">{{'MONITORING_SECTION.CREATE_PASSWORD' | translate}}</button> -->
                    <button [disabled]="selectedOVC.length == 0" class="btn-sicche btn-secondary" (click)="deleteTeam()">{{'MONITORING_SECTION.DELETE_TEAM' | translate}}</button>
                </div>
            </div>
        </div>

        <div class="col-12 bg-white shadow card px-4 py-2" *ngIf="multipleAnswersFound">
            {{'MONITORING_SECTION.QUESTION_WITH_MULTIPLE_ANSWERS' | translate}}
            <div class="position-relative">
                <div class="table-responsive">
                    <app-core-table
                    *ngIf="multipleAnswersQuestionsUsers"
                    [items]="multipleAnswersQuestionsUsers"
                    [columns]="multipleAnswersQuestionsColumns"
                    tableid="'questions_users_ovc'"
                    [perPage]="perPage"
                    [rowTemplate]="rowTemplateQuestionsOVC"
                    (columnClick)="columnClickedOVC($event)"
                    >
                    </app-core-table>
                    <ng-template let-item="item" #rowTemplateQuestionsOVC>
                        <tr>
                            <td class="text-left clickable-icon">
                                <input type="checkbox" (change)="addRemoveToSelectedOVC(item.userid)" [checked]="selectedOVC.indexOf(item.userid) !== -1">
                            </td>
                            <td class="text-center">
                                <div class="avatar">
                                    <div *ngIf="item.avatar_url" class="avatar-bg-img" [ngStyle]="{'background-image': 'url(' + (item.avatar_url | privatesrc) + ')'}">
                                    </div>
                                </div>
                            </td>
                            <td class="text-left">
                                {{item.email}}
                            </td>
                            <td class="text-left">
                                {{item.nickname}}
                            </td>
                            <td class="text-center" *ngFor="let question of multipleAnswersQuestions">
                                <span class="out-of-number">{{getTeamQuestionCount(question, item)}}</span>
                            </td>
                        </tr>
                    </ng-template>
                </div>
            </div>
        </div>
</div>