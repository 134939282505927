import {Injectable} from '@angular/core';
import {CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../../services/auth.service';
import { StandardRoutes } from '../../../../../setup';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivateChild {
  constructor(
    private auth: AuthService,
    private router: Router,
    private authService: AuthService
    ) { }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      let test = false;
      test = this.isAuthenticated();
      if (!test) {
        this.router.navigate([StandardRoutes.LOGIN]);
      }
      return test;
  }

  isAuthenticated(): boolean/*Observable<boolean>*/ {
    return this.auth.isAuthenticated();
  }

}
