<app-shared-sidebar-container  >
    <app-shared-sidebar-header>
        <div slot="title" class="d-flex align-items-center justify-content-between flex-grow-1">
            <div class="d-flex align-items-center flex-grow-1 sidebar-title pl-0">
                <div class="col-10 pl-0">
                    <h2 class="mb-0">{{ 'HELP' | translate }}</h2>
                </div>
                <div class="col-2 d-flex justify-content-end">
                    <div class="form-group mb-0 mw-150">
                        <app-core-select
                        *ngIf="allLang && currentLang"
                        [classes]="'form-control'"
                        [placeholder]="'LANGUAGE'"
                        [cache]="true"
                        (change)="changeLang($event)"
                        [customList]="allLang"
                        [(ngmodel)]="currentLang"
                        >
                        </app-core-select>
                    </div>
                </div>
            </div>
            <div class="flex-grow-0 d-flex align-items-center">
                <div slot="buttons" class="text-right">
                </div>
            </div>
        </div>
    </app-shared-sidebar-header>
    <div class="sidebar-body">
        <div class="row">
            <div class="col-3">
                <div class="form-group">
                    <input type="text" class="form-control" (keyup)="search($event)">
                    <span class="placeholder">{{'SEARCH' | translate}}</span>
                </div>

                <h5 class="my-3">{{'HOW_TO' | translate}}</h5>
                <ul class="list-group panel shadow p-3 min-h-auto">
                    <li class="list-group-item clickable" [ngClass]="category == 'SETUP' ? 'active' : ''" (click)="setCategory('SETUP')"><span class="parent">{{'SETUP' | translate}}</span>
                        <ul class="list-group ml-2 child" *ngIf="category == 'SETUP' || expandAll">
                            <span *ngFor="let f of faqs">
                                <li *ngIf="f.category == 'SETUP'" class="list-group-item clickable" (click)="changeSelected(f.id)" [ngClass]="f.id == currentId ? 'active' : ''" >{{f.question}}</li>
                            </span>
                        </ul>
                    </li>
                    <li class="list-group-item clickable" [ngClass]="category == 'TIPOLOGIE_DOMANDE' ? 'active' : ''" (click)="setCategory('TIPOLOGIE_DOMANDE')"><span class="parent">{{'TIPOLOGIE_DOMANDE' | translate}}</span>
                        <ul class="list-group ml-2 child" *ngIf="category == 'TIPOLOGIE_DOMANDE' || expandAll">
                            <span *ngFor="let f of faqs">
                                <li *ngIf="f.category == 'TIPOLOGIE_DOMANDE'" class="list-group-item clickable" (click)="changeSelected(f.id)" [ngClass]="f.id == currentId ? 'active' : ''" >{{f.question}}</li>
                            </span>
                        </ul>
                    </li>
                    <li class="list-group-item clickable" [ngClass]="category == 'MODERATION' ? 'active' : ''" (click)="setCategory('MODERATION')"><span class="parent">{{'MODERATION' | translate}}</span>
                        <ul class="list-group ml-2 child" *ngIf="category == 'MODERATION' || expandAll">
                            <span *ngFor="let f of faqs">
                                <li *ngIf="f.category == 'MODERATION'" class="list-group-item clickable" (click)="changeSelected(f.id)" [ngClass]="f.id == currentId ? 'active' : ''" >{{f.question}}</li>
                            </span>
                        </ul>
                    </li>
                    <li class="list-group-item clickable" [ngClass]="category == 'MONITORING' ? 'active' : ''" (click)="setCategory('MONITORING')"><span class="parent">{{'MONITORING' | translate}}</span>
                        <ul class="list-group ml-2 child" *ngIf="category == 'MONITORING' || expandAll">
                            <span *ngFor="let f of faqs">
                                <li *ngIf="f.category == 'MONITORING'" class="list-group-item clickable" (click)="changeSelected(f.id)" [ngClass]="f.id == currentId ? 'active' : ''" >{{f.question}}</li>
                            </span>
                        </ul>
                    </li>
                    <li class="list-group-item clickable" [ngClass]="category == 'MESSAGES' ? 'active' : ''" (click)="setCategory('MESSAGES')"><span class="parent">{{'MESSAGES' | translate}}</span>
                        <ul class="list-group ml-2 child" *ngIf="category == 'MESSAGES' || expandAll">
                            <span *ngFor="let f of faqs">
                                <li *ngIf="f.category == 'MESSAGES'" class="list-group-item clickable" (click)="changeSelected(f.id)" [ngClass]="f.id == currentId ? 'active' : ''" >{{f.question}}</li>
                            </span>
                        </ul>
                    </li>
                    <li class="list-group-item clickable" [ngClass]="category == 'ANALYSIS' ? 'active' : ''" (click)="setCategory('ANALYSIS')"><span class="parent">{{'ANALYSIS' | translate}}</span>
                        <ul class="list-group ml-2 child" *ngIf="category == 'ANALYSIS' || expandAll">
                            <span *ngFor="let f of faqs">
                                <li *ngIf="f.category == 'ANALYSIS'" class="list-group-item clickable" (click)="changeSelected(f.id)" [ngClass]="f.id == currentId ? 'active' : ''" >{{f.question}}</li>
                            </span>
                        </ul>
                    </li>
                    <li class="list-group-item clickable" [ngClass]="category == 'VISTA_INTERVISTATI' ? 'active' : ''" (click)="setCategory('VISTA_INTERVISTATI')"><span class="parent">{{'VISTA_INTERVISTATI' | translate}}</span>
                        <ul class="list-group ml-2 child" *ngIf="category == 'VISTA_INTERVISTATI' || expandAll">
                            <span *ngFor="let f of faqs">
                                <li *ngIf="f.category == 'VISTA_INTERVISTATI'" class="list-group-item clickable" (click)="changeSelected(f.id)" [ngClass]="f.id == currentId ? 'active' : ''" >{{f.question}}</li>
                            </span>
                        </ul>
                    </li>
                    <li class="list-group-item clickable" [ngClass]="category == 'VISTA_CLIENTI' ? 'active' : ''" (click)="setCategory('VISTA_CLIENTI')"><span class="parent">{{'VISTA_CLIENTI' | translate}}</span>
                        <ul class="list-group ml-2 child" *ngIf="category == 'VISTA_CLIENTI' || expandAll">
                            <span *ngFor="let f of faqs">
                                <li *ngIf="f.category == 'VISTA_CLIENTI'" class="list-group-item clickable" (click)="changeSelected(f.id)" [ngClass]="f.id == currentId ? 'active' : ''" >{{f.question}}</li>
                            </span>
                        </ul>
                    </li>
                </ul>
                <h5 class="my-3">Tutorials</h5>
                <ul class="list-group panel shadow p-3 min-h-auto">
                    <li class="list-group-item clickable" (click)="changeSelected(t.id)" *ngFor="let t of tutorials" [ngClass]="t.id == currentId ? 'active' : ''" ><span class="parent">{{t.title}}</span></li>
                </ul>
            </div>
            <div class="col-9">
                <div *ngFor="let t of tutorials">
                    <div class="p-3" *ngIf="t.id == currentId">
                        <h2 >{{t.title}}</h2><br>
                        <span [innerHTML]="t.vimeo_embed"></span>
                    </div>
                </div>
                <div *ngFor="let f of faqs">
                    <div class="p-3" *ngIf="f.id == currentId">
                        <h2 >{{f.question}}</h2><br>
                        <span [innerHTML]="f.answer"></span>
                        <span [innerHTML]="f.vimeo_embed"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-shared-sidebar-container>