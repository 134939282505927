import { Component, OnInit, Input } from '@angular/core';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { OverviewModalComponent } from '@app/pages/layers/private/researches/navigation/analysis/overview-modal/overview-modal.component';
import { AnalysisService } from '@app/shared/services/analysis.service';
import { ResponseModel } from '@app/core/models/response.model';
import { ActivatedRoute } from '@angular/router';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { RoomModel } from '@app/shared/models/room.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { QuestionModel } from '@app/shared/models/question.model';
import { CountryService } from '@app/shared/services/country.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { RoomsService } from '@app/shared/services/rooms.service';
import { ResearchSessionService } from '@app/shared/services/research-session.service';
import { DatetimeToDbPipe } from '@app/core/pipes/datetime-to-db.pipe';
import { TranslateService } from '@ngx-translate/core';
import { QuestionsService } from '@app/shared/services/questions.service';
import { ModalComponent } from '@app/core/components/modal/modal.component';
import { BlockService } from '@app/shared/services/block.service';
import { BlockModel } from '@app/shared/models/block.model';
import { ToastrService } from 'ngx-toastr';
import { isObject } from 'angular';
import { DateToLocaleStringPipe } from '@app/core/pipes/date-to-locale-string.pipe';

@Component({
  selector: 'app-analysis',
  templateUrl: './smart-setup-modal.component.html',
  styleUrls: ['./smart-setup-modal.component.scss']
})
export class SmartUserSetupComponent extends ModalComponent implements OnInit {

  private countryUniqId = null;
  public rooms: RoomModel[] = [];
  public sampleVariableTags: Array<any> = [];
  public questions: QuestionModel[] = [];
  public days = [];
  public filterForm: UntypedFormGroup = null;
  public blocks: BlockModel[] = [];
  public saving = false;
  public loading = true;
  public loadingFilter = true;
  public setup = null;
  private allBlocks: BlockModel[] = [];
  private allQuestions: QuestionModel[] = [];

  constructor(private coreLayoutService: CoreLayoutService, private activatedRoute: ActivatedRoute, private roomsService: RoomsService,
              private analysysService: AnalysisService, public activeModal: NgbActiveModal, public countryService: CountryService,
              private researchSessionService: ResearchSessionService, private dateTimeToDb: DatetimeToDbPipe,
              private toastService: ToastrService, private analysisService: AnalysisService, private dateToEuPipe: DateToLocaleStringPipe,
              private translate: TranslateService, private questionsService: QuestionsService, private blocksService: BlockService) {
                super(activeModal);
              }

  ngOnInit() {
    if (this.params.countryUniqId) {
      this.countryUniqId = this.params.countryUniqId;
    }
    // Get config
    this.analysisService.getSmartConfig(this.countryUniqId)
    .subscribe((res: ResponseModel) => {
      this.loading = false;
      const data = this.setup = res.getData();
      // Make form
      this.filterForm = new UntypedFormGroup({
        room: new UntypedFormControl('', Validators.nullValidator),
        block:  new UntypedFormControl('', Validators.nullValidator),
        block_datetime: new UntypedFormControl('', Validators.nullValidator),
        question: new UntypedFormControl('', Validators.nullValidator),
        sample_variable_tags: new UntypedFormControl(
          res.getData().sample_variable_tags ? res.getData().sample_variable_tags.length ?
            res.getData().sample_variable_tags : '' : '', Validators.nullValidator),
        // Booleani
        show_graph: new UntypedFormControl(data.show_graph, Validators.nullValidator),
        show_audio: new UntypedFormControl(data.show_audio, Validators.nullValidator),
        show_video: new UntypedFormControl(data.show_video, Validators.nullValidator),
        show_star: new UntypedFormControl(data.show_star, Validators.nullValidator),
        show_image: new UntypedFormControl(data.show_image, Validators.nullValidator),
        show_tagcloud: new UntypedFormControl(data.show_tagcloud, Validators.nullValidator),
      });


      this.getDays(() => {
        this.getRooms(() => {
          //this.getDays();
          this.blocksService.getBlocksByCountryId(this.countryUniqId, false)
            .subscribe((res: ResponseModel) => {
              this.allBlocks = res.getData(false, (item) => {
                return item.title = this.rooms.find(room => room.id === item.roomid).title + ' - ' + (item.title ? item.title.replace(/<[^>]+>/g, '') : '');
              });
              this.startOrdering();
              this.blocks = this.allBlocks.slice(0);
              //this.getDays();
              this.filterBlocks();
              if (this.setup) {
                if (this.setup.blockids) {
                  if (this.setup.blockids.length) {
                    this.filterForm.patchValue({
                      block: [this.blocks.find(item => this.setup.blockids.includes(item.id))]
                    });
                    this.blockChange(null);
                  }
                }
              }
              // Get questions
              this.questionsService.getQuestionsByRoomid(null, false, false, null, this.countryUniqId)
              .subscribe((res: ResponseModel) => {
                this.allQuestions = res.getData(false, (item) => {
                  return item.title = item.room_title + ' - ' + item.title.replace(/<[^>]+>/g, '');
                });
                this.startOrdering();
                this.filterQuestions();
                // Preselect
                if (this.setup) {
                  if (this.setup.questionids) {
                    if (this.setup.questionids.length) {
                      this.filterForm.patchValue({
                        question: this.allQuestions.find(item => item.id === this.setup.questionids[0])
                      });
                    }
                  }
                }
                // Get variabili campione & tag di contenuto
                this.countryService.getCountrySetupInfo(this.countryUniqId).subscribe((res: ResponseModel) => {
                  this.sampleVariableTags = res.getData().sample_variable_tags.filter(tag => tag ? true : false);
                  this.loadingFilter = false;
                });
              });
            });
          });

      });

    });
  }

  private startOrd = 0;
  startOrdering() {
    this.startOrd++;
    if(this.startOrd === 3) {
      this.orderQuestions(this.allQuestions, this.allBlocks, this.rooms);
      this.filterQuestions();
    }
  }

  atLeastOneArea() {
    return !(this.filterForm.value.show_graph || this.filterForm.value.show_audio || this.filterForm.value.show_video || this.filterForm.value.show_star || this.filterForm.value.show_image || this.filterForm.value.show_tagcloud);
  }

  changed() {

  }

  private getRooms(cb?) {
    this.roomsService.getRoomsByCountryUniqid(this.countryUniqId).subscribe((res: ResponseModel) => {
      this.rooms = res.getData();
      this.startOrdering();
      // Preselect TUTTE LE STANZE
      const room_all = new RoomModel({id: 0, title: 'Tutte le stanze'});
      this.rooms.unshift(room_all);
      this.filterForm.patchValue({room: [room_all]});
      if(cb) {
        cb();
      }
      if (this.setup) {
        let setupRooms = [];
        if (this.setup.roomids) {
          if (this.setup.roomids.length) {
            this.setup.roomids.forEach(roomId => {
              setupRooms.push(this.rooms.find(item => item.id === roomId));
            })
            this.filterForm.patchValue({
              room: setupRooms
            });
            this.roomChange(null);
          } else {
            this.filterForm.patchValue({
              room: [room_all]
            });
          }
        }
      }
    });
  }

  private parseCompatibilityDate(value: string | Date) {
    if (typeof value === 'string') {
      if (value.indexOf('-') !== -1) {
        value = this.replaceAll(value, '-', '/');
      }
      // @ts-ignore
      if (value.indexOf('+') !== -1) {
        value = value.toString();
        value = value.substring(0, value.indexOf('+'));
      }
    }
    return value;
  }

  private blockDateIsCorrect(block: BlockModel) {
    if (!this.filterForm.value.block_datetime) {
      return true;
    }
    if (!this.filterForm.value.block_datetime.date) {
      return true;
    }
    if (this.dateToEuPipe.transform(new Date(block.datetime_start).toString())
      === this.dateToEuPipe.transform(this.filterForm.value.block_datetime.date.toString())) {
        return true;
    }
    return false;
  }

  public roomChange(event) {
    if(this.filterForm.value.room.length >= 2 && this.filterForm.value.room[0].id == 0) {
      this.filterForm.patchValue({
        room: this.filterForm.value.room.filter(room => room.id != 0)
      })
    } else if(this.filterForm.value.room.length >= 2 && this.filterForm.value.room[this.filterForm.value.room.length - 1].id == 0) {
      this.filterForm.patchValue({
        room: [this.filterForm.value.room[this.filterForm.value.room.length - 1]
      ]
      })
    }
    this.resetBlock();
    this.filterBlocks();
    this.filterQuestions();
  }

  private filterBlocks() {
    this.blocks = [];
    // By room
    if(!this.filterForm.value.room || !this.filterForm.value.room.length) {
      return this.blocks = this.allBlocks.slice(0);
    } else {
      if(this.filterForm.value.room.length && !this.allRooms()) {
        this.allBlocks.forEach((block: BlockModel) => {
          if (this.roomIsSelected(block.roomid)) {
            if (this.blockDateIsCorrect(block)) {
              this.blocks.push(block);
            }
          }
        });
      } else {
        if(this.filterForm.value.block_datetime) {
          this.allBlocks.forEach((block: BlockModel) => {
            if (this.blockDateIsCorrect(block)) {
              this.blocks.push(block);
            }
          });
        } else {
          this.blocks = this.allBlocks.slice(0);
        }
      }
    }
  }

  private allRooms() {
    let test = false;
    this.filterForm.value.room.forEach((room: RoomModel) => {
      if(0 === room.id) {
        test = true;
      }
    });
    return test;
  }

  private roomIsSelected(roomIdToFind: number) {
    let test = false;
    this.filterForm.value.room.forEach((room: RoomModel) => {
      if(roomIdToFind === room.id) {
        test = true;
      }
    });
    return test;
  }

  replaceAll(str, search, replace) {
    return str.replace(new RegExp(search, 'g'), replace);
  }

  public blockChange(event) {
    if (!this.filterForm.value.block) {
      this.filterQuestions();
      return false;
    }
    this.filterForm.controls['question'].patchValue('');
    this.filterQuestions();
  }

  private filterQuestions() {
    this.questions = [];
    // Filter by room
    if(this.filterForm.value.room) {
      if(this.filterForm.value.room.length && !this.allRooms()) {
        this.allQuestions.forEach((question: QuestionModel) => {
          if (this.roomIsSelected(question.roomid)) {
            this.questions.push(question);
          }
        });
      } else {
        this.questions = this.allQuestions.slice(0);
      }
    } else {
      this.questions = this.allQuestions.slice(0);
    }
    // Filter by block
    if(this.filterForm.value.block && this.filterForm.value.block.length) {
      this.questions = this.questions.filter((question: QuestionModel) => {
        let result = false;
        this.filterForm.value.block.forEach((block: BlockModel) => {
          if(question.blockid == block.id) {
            result = true;
          }
        });
        return result;
      });
    } else {
      if(this.filterForm.value.block_datetime) {
        this.questions = this.questions.filter((question: QuestionModel) => {
          let result = false;
          if(this.questionIsInSelectedDay(question)) {
            result = true;
          }
          return result;
        });
      }
    }
  }

  private orderQuestions(questions: Array<QuestionModel>, blocks: Array<BlockModel>, rooms: Array<RoomModel>) {
    // Get days
    questions.forEach((question: QuestionModel, index: number) => {
      let block: BlockModel = blocks.find((block: BlockModel) => {
        return block.id === question.blockid;
      });
      question.extension = {
        day: block.datetime_start,
        blockid: block.id,
        block_title: block.title
      };
    });
    // Order by day
    // @ts-ignore
    questions = questions.sort((questionA: QuestionModel, questionB: QuestionModel) => {
      if(new Date(questionA.block_datetime_start) > new Date(questionB.block_datetime_start)) return 1;
      if(new Date(questionA.block_datetime_start) < new Date(questionB.block_datetime_start)) return -1;

      if(questionA.extension.blockid > questionB.extension.blockid) return 1
      if(questionA.extension.blockid < questionB.extension.blockid) return -1;

      if(questionA.position > questionB.position) return 1
      if(questionA.position < questionB.position) return -1;
    });
  }

  private questionIsInSelectedDay(question: QuestionModel) {
    let bool = false;
    let block = this.allBlocks.find((block: BlockModel) => {
      return block.id === question.blockid;
    });
    if(block) {
      if(this.blockDateIsCorrect(block)) {
        bool = true;
      }
    }
    return bool;
  }

  resetBlock() {
    this.filterForm.patchValue({ block: ''});
  }

  private getDays(callback = null) {
    this.countryService.getActiveDays(this.countryUniqId).subscribe(response => {
      const data = [];
      response.getData().forEach((date, index) => {
        const dateItem = {
          date,
          name: `${this.translate.instant('DAY')} ${index+1}:
            ${new Date(date).toLocaleDateString('it-IT', {  year: 'numeric', month: 'long', day: 'numeric' })}`
        };
        // Preselect
        if (this.setup) {
          if (this.setup.days) {
            if (this.setup.days.length) {
              if ((new Date(this.setup.days[0])).setHours(0, 0, 0, 0) === new Date(date).setHours(0, 0, 0, 0)) {
                this.filterForm.patchValue({
                  block_datetime: dateItem
                });
                // this.changeDate({date: this.formTime.value.date, name: ''});
              }
            }
          }
        }
        data.push(dateItem);
      });
      this.days = data;
      if(callback) {
        callback();
      }
    });


    const research = this.researchSessionService.research;
    this.days = [];
    const dateStartRoom = new Date(this.parseCompatibilityDate(research.date_start));
    const dateEndRoom = new Date(this.parseCompatibilityDate(research.date_end));
    dateEndRoom.setDate(dateEndRoom.getDate());
    const currentDate = dateStartRoom;
    const daysTemp = [];
    let index = 1;
    while (currentDate.getTime() < dateEndRoom.getTime()) {
      const date = new Date(currentDate);
      const dates = date.setHours(0, 0, 0, 0);
      const dateItem = {
        date,
        name: `${this.translate.instant('DAY')} ${index}:
          ${currentDate.toLocaleDateString('it-IT', {  year: 'numeric', month: 'long', day: 'numeric' })}`
      };
      if (this.dayIsActive(dateItem)) {
        daysTemp.push(dateItem);
      }
      // Preselect
      if (this.setup) {
        if (this.setup.days) {
          if (this.setup.days.length) {
            if ((new Date(this.setup.days[0])).setHours(0, 0, 0, 0) === dates) {
              this.filterForm.patchValue({
                block_datetime: dateItem
              });
              // this.changeDate({date: this.formTime.value.date, name: ''});
            }
          }
        }
      }
      index++;
      currentDate.setDate(currentDate.getDate() + 1);
    }
    this.days = daysTemp;
  }

  dayIsActive(dateItem: {date, name: string}) {
    if (dateItem.date) {
      if (this.allBlocks.length) {
        for (const block of this.allBlocks) {
          if (this.dateToEuPipe.transform(new Date(this.parseCompatibilityDate(block.datetime_start)).toString())
            === this.dateToEuPipe.transform(dateItem.date.toString())) {
            return true;
          }
        }
      }
    }
    return false;
  }

  roomsSelectedIsRight(block: BlockModel) {
    if (!this.filterForm.value.room) {
      return true;
    } else {
      if (this.filterForm.value.room.id === block.roomid || this.filterForm.value.room.id === 0 /** all rooms */) {
        return true;
      }
    }
    return false;
  }

  dayChange(event, callback = null) {
    //if(this.filterForm.value.room.id) { // TODO test?
      this.filterForm.controls['question'].patchValue('');
      this.filterForm.controls['block'].patchValue('');
      this.questions = [];
      this.filterForm.patchValue({
        block: null
      });
      this.filterBlocks();
    //}
    this.filterQuestions();
    if (callback) {
      callback();
    }
  }

  closeModal() {
    this.activeModal.close();
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  save() {
    this.saving = true;
    const days = [];
    if (this.filterForm.value.block_datetime) {
      days.push(this.formatDate(this.filterForm.value.block_datetime.date));
    }
    const all_rooms = this.filterForm.value.room ? this.filterForm.value.room.length == 1 && this.filterForm.value.room[0].id == 0 ? true : false : false;
    this.analysisService.setSmartConfig(this.countryUniqId, {
      all_rooms,
      roomids: !all_rooms ? this.filterForm.value.room ? this.filterForm.value.room.map(item => item.id) : [] : [],
      blockids: this.filterForm.value.block ? this.filterForm.value.block.map(item => item.id) : [],
      questionids: this.filterForm.value.question ? [this.filterForm.value.question.id] : [],
      days,
      sample_variable_tags: this.filterForm.value.sample_variable_tags ? this.filterForm.value.sample_variable_tags : [],
      // Booleani
      show_audio: this.filterForm.value.show_audio ? true : false,
      show_graph: this.filterForm.value.show_graph ? true : false,
      show_video: this.filterForm.value.show_video ? true : false,
      show_star: this.filterForm.value.show_star ? true : false,
      show_image: this.filterForm.value.show_image ? true : false,
      show_tagcloud: this.filterForm.value.show_tagcloud ? true : false,
    })
    .subscribe((res: ResponseModel) => {
      this.saving = false;
      this.closeModal();
      this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
    }, (e) => {
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
    });
  }
}
