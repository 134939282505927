import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { SidebarVoice } from '../models/sidebar/sidebar-voice.interface';
import { SidebarVoiceModel } from '../models/sidebar/sidebar-voice.model';
import { BehaviorSubject } from 'rxjs';
import { ToolServiceInterface } from '../models/tool-service.interface';

@Injectable({
  providedIn: 'root'
})
export class SidebarService implements ToolServiceInterface {

  visible$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  voices$: BehaviorSubject<SidebarVoiceModel[]> = new BehaviorSubject([]);
  title$: BehaviorSubject<string | any> = new BehaviorSubject('');
  sticky$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private logger: NGXLogger) {
  }

  reset() {
    this.visible$.next(true);
    this.voices$.next([]);
    this.title$.next('');
  }

  addVoices(voices: SidebarVoice[]) {
    this.voices$.next(voices.map(item => new SidebarVoiceModel(item)));
    return this;
  }

  toggle() {
      this.visible$.next(this.visible$.value === true ? false : true);
      return this;
  }

  setTitle(title: string | any) {
    this.title$.next(title);
    return this;
  }

  get(slug): SidebarVoiceModel {
    return this.voices$.value.find(item => item.slug === slug);
  }

  isVisible() {
    return this.visible$.value;
  }

  sticky(val: boolean) {
    this.sticky$.next(val);
    return this;
  }

}
