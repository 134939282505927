<div class="star-value">
    <ng-template #starsPopover>
        <div><i (click)="rated(1)" class="fal fa-star star-red"></i></div>
        <div><i (click)="rated(2)" class="fal fa-star star-orange"></i></div>
        <div><i (click)="rated(3)" class="fal fa-star star-green"></i></div>
    </ng-template>
    <i *ngIf="answer && !minimal" class="fal fa-star" 
        [ngClass]="{'star-red': answer.stars == 1, 'star-orange': answer.stars == 2, 'star-green': answer.stars == 3}"
        [autoClose]="'outside'" 
        [popoverClass]="'stars-popover'" 
        [ngbPopover]="!readonly ? starsPopover : null">
    </i>
    <i *ngIf="comment && !minimal" class="fal fa-star" 
        [ngClass]="{'star-red': comment.stars == 1, 'star-orange': comment.stars == 2, 'star-green': comment.stars == 3}"
        [autoClose]="'outside'" 
        [popoverClass]="'stars-popover'" 
        [ngbPopover]="!readonly ? starsPopover : null">
    </i>
    <span *ngIf="answer && minimal" class="html-star"
        [ngClass]="{'star-red': answer.stars == 1, 'star-orange': answer.stars == 2, 'star-green': answer.stars == 3}">
        &#9733;
    </span>
    <span *ngIf="comment && minimal" class="html-star"
        [ngClass]="{'star-red': comment.stars == 1, 'star-orange': comment.stars == 2, 'star-green': comment.stars == 3}">
        &#9733;
    </span>
</div>