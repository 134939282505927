<app-shared-sidebar-container>
    <app-shared-sidebar-header>
        <div slot="title">
            <h2 class="mb-0">{{ 'TEMPLATE_MESSAGES' | translate }}</h2>
        </div>
        <div slot="buttons" class="text-right">

        </div>
    </app-shared-sidebar-header>

    <div class="sidebar-body">
        <div class="row p-4">
            <div class="col-12">
                <div class="form-group">
                    <input type="text" [(ngModel)]="template.title" class="form-control">
                    <span class="placeholder">
                        {{'TITLE' | translate}}
                    </span>
                </div>
            </div>
            <div class="col-12 mt-3">
                <div class="form-group">
                    <input type="text" [(ngModel)]="template.subject" class="form-control">
                    <span class="placeholder">
                        {{'MESSAGES_SECTION.SUBJECT' | translate}}
                    </span>
                </div>
            </div>
            <div class="col-12 mt-3">
                <quill-editor [(ngModel)]="template.body" [modules]="modules" [styles]="{height: '250px'}" [placeholder]="'INSERT-TEXT' | translate"></quill-editor>
            </div>
            <div class="col-12 mt-3 text-right">
                <button class="btn-sicche btn-secondary" (click)="deleteTemplate()">{{ 'DELETE' | translate }}</button>
                <button class="btn-sicche" [disabled]="!template.title || !template.subject || !template.body" (click)="updateTemplate()">{{ 'SAVE' | translate }}</button>
            </div>
        </div>
    </div>

</app-shared-sidebar-container>
