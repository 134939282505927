<app-core-spinner [toggle]="loading"></app-core-spinner>
<ng-container *ngIf="form && formEntityName">
    <div [formGroup]="form">
        <span class="reset-button" (click)="resetValue(true)" *ngIf="resetButton && form.value[formEntityName] && !isEmptyArray(form.value[formEntityName])">&#10006;</span>
        <ng-select
            [items]="list"
            *ngIf="!loading"
            [formControlName]="formEntityName"
            [id]="id"
            [addTag]="addTag"
            [bindValue]="value"
            [bindLabel]="label"
            [multiple]="multiple"
            [placeholder]="placeholder | translate"
            [searchable]="searchable"
            [compareWith]="compareWith"
            [readonly]="readonly"
            (change)="onChange($event)"
            [class]="classes">
        </ng-select>
    </div>
</ng-container>
<ng-container *ngIf="!form || !formEntityName">
    <div class="position-relative">
        <span class="reset-button" (click)="resetValue(false)" *ngIf="resetButton && ngmodel">&#10006;</span>
        <ng-select
            [items]="list"
            *ngIf="!loading"
            [(ngModel)]="ngmodel"
            [multiple]="multiple"
            [id]="id"
            [addTag]="addTag"
            (change)="onChange($event)"
            [compareWith]="compareWith"
            [bindValue]="value"
            [bindLabel]="label"
            [readonly]="readonly"
            [placeholder]="placeholder | translate"
            [searchable]="searchable"
            [class]="classes">
        </ng-select>
    </div>
</ng-container>
