<!--
<img *ngIf="imageUrl" [src]="imageUrl | privatesrc">
<img *ngIf="!imageUrl || imageUrl == ''" src="https://www.w3schools.com/howto/img_avatar.png">
-->

<div class="avatar">
    <div class="avatar-bg-img" *ngIf="imageUrl" [ngStyle]="{'background-image': 'url(' + (imageUrl | privatesrc) + ')'}"></div>
    <!--<div class="avatar-bg-img" *ngIf="!imageUrl || imageUrl == ''" style="background-image: url('https://www.w3schools.com/howto/img_avatar.png');"></div>-->
    <div *ngIf="!imageUrl" class="d-flex align-items-center justify-content-center placeholder-avatar">
        <i class="align-self-center fal fa-user"></i>
    </div>
</div>
