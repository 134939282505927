import { Component, OnInit } from '@angular/core';
import { ModalComponent } from '@app/core/components/modal/modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CoreLayoutService } from '@app/core/services/layout.service';

@Component({
  selector: 'app-intervieweds-researches-modal',
  templateUrl: './researches-modal.component.html',
  styleUrls: ['./researches-modal.component.scss']
})
export class ResearchesModalComponent extends ModalComponent  implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private coreLayoutService: CoreLayoutService
  ) {
    super(activeModal);
  }

  public researches = [];


  ngOnInit(): void {
    if (this.params.researches) {
      this.researches = this.params.researches;
    }
  }

  closeModal() {
    this.activeModal.close();
  }
}
