// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  staticUrl: 'https://sicche-mrs.sinapto.tech', // static root
  serviceUrl: 'https://sicche-mrs.sinapto.tech/api/sicche', // api root
  serviceAuth: 'https://sicche-mrs.sinapto.tech/api/auth', // login api
  websiteUrl: 'https://www.sicche.com', // sicche website
  appUrl: 'https://researchers.sicche.com',
  md5_salt: '',
  httpHeaders: {
    'Content-Type': 'application/json',
    // cache pre-flight requests, 24h is firefox limit, gonna be 2h in chromium based browsers
    'Access-Control-Max-Age': '86400'
  },
  developmentTools: false,
  backendLogging: true,
  projectName: 'AppResearcher'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
