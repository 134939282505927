<ng-container *ngIf="answer">
  <div class="semantic-container p-0 mb-4 d-flex flex-wrap align-items-center justify-content-between {{ classes }}" *ngIf="answer.answer_data">
    <div class="flex-grow-1 d-flex align-items-center justify-content-center p-3" *ngFor="let a of answer.answer_data; let i = index">
      <div class="card">
        <div class="side-label-container">
          <div class="side-label-wrap d-flex align-items-center justify-content-start">
            <p class="m-3"><b>{{ getAnswer(a.optionid).label_left }}</b></p>
          </div>
          <div class="d-flex flex-column">
            <div class="d-flex align-items-center justify-content-between">
              <input disabled type="radio" class="ml-0" [checked]="a.rating === 1">
              <input disabled type="radio" [checked]="a.rating === 2">
              <input disabled type="radio" [checked]="a.rating === 3">
              <input disabled type="radio" [checked]="a.rating === 4">
              <input disabled type="radio"  class="mr-0" [checked]="a.rating === 5">
            </div>
            <div class="semantic-gradient"></div>
          </div>
          <div class="side-label-wrap d-flex align-items-center justify-content-end">
            <p class="m-3"><b>{{ getAnswer(a.optionid).label_right }}</b></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
