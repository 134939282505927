import { BaseModel } from '@app/core/models/base';

/**
 * User model class
 */

export class BlockModel extends BaseModel {

    // tslint:disable: variable-name
    country_uniqid: string;
    roomid: number;
    id: number;
    day: Date;
    title: string;
    datetime_start: Date;
    datetime_end: Date;
    is_room_group: boolean;
    image: any;
    image_url: string;
    image_del: boolean;
    device: string;
    is_sequential_questions: boolean;
    is_diary: boolean;
    is_highlighted: boolean;
    has_geolocation: boolean;
    geolocation_lat: number;
    geolocation_long: number;
    geolocation_address: string;
    position: number;
    // fe only
    is_opened: boolean;
    timezone: string;

    static getResourceName() {
        return 'block-management';
    }

    get startDate() {
        return this.datetime_start.toString();
        /*
        return new Date(
            this.parseCompatibilityDate(this.datetime_start)
        ).toLocaleDateString('en-US', { timeZone: this.timezone });
        */
    }

    get endDate(): String {
        return this.datetime_end.toString();
        /*
        return new Date(
            this.parseCompatibilityDate(this.datetime_end)
        ).toLocaleDateString('en-US', { timeZone: this.timezone });
        */
    }



}
