<section>
    <form [formGroup]="form">
        <div class="container-fluid position-relative">

            <app-setup-questions-shared-media-upload
                [question]="question"
                [images]="attachedImages"
                [pdfFiles]="attachedPdfFiles"
                (addImageOut)="addImage($event)"
                (removeImageOut)="removeImage($event)"
                (addPdfOut)="addPdf($event)"
                (removePdfOut)="removePdf($event)">
            </app-setup-questions-shared-media-upload>

            <h4 class="mb-2">{{ 'QUESTION' | translate }}</h4>
            <div class="question-text panel shadow">
                <app-setup-questions-text [form]="form" [countryUniqId]="countryUniqId"></app-setup-questions-text>
            </div>
            <app-setup-questions-selects [question]="question" [block]="block" [form]="form" [countryUniqId]="countryUniqId"></app-setup-questions-selects>
        </div>
    </form>
</section>