<app-core-spinner [toggle]="operation('ongoing-researches').loading"></app-core-spinner>
<div class="row">
    <div class="col-lg-3 pt-5 col-sm-12" *ngIf="researches">
        <h1>{{ 'YOU_HAVE_N_RESEARCHES' | translate:({param1: researches?.length - 1} | ParamsTranslate) }}</h1>
        <div *ngIf="!isSpecialCustomer" (click)="newResearchModal($event)" class="new-research-button-wrapper d-inline-flex">
            <label class="d-inline-flex align-items-center cursor-pointer">
                <button class="btn-sicche btn-icon big">
                    <i class="fal fa-plus fa-sm"></i>
                </button>
            <span class="ml-2">{{ 'CREATE_NEW_RESEARCH' | translate }}</span></label>
        </div>
        <div (click)="newDemo()" class="new-research-button-wrapper d-inline-flex" *ngIf="(!demoDisabled || currentUserIsAdmin) && !isSpecialCustomer">
            <label class="d-inline-flex align-items-center cursor-pointer" style="padding-left: 5px;">
                <button class="btn-sicche btn-icon small btn-secondary">
                    <i class="fal fa-tools"></i>
                </button>
                <span class="ml-2" style="padding-left: 5px;">{{ 'CREATE_DEMO_RESEARCH' | translate }}</span>
                <span class="info-tooltip ml-2" placement="top" [ngbTooltip]="'TOOLTIPS.RESEARCH_DEMO' | translate">
                    <i class="far fa-info-circle"></i>
                </span>
            </label>
        </div>
    </div>
    <div class="col-lg-9 col-sm-12 position-raltive">
        <app-private-researches-slider [researches]="researches"></app-private-researches-slider>
    </div>
</div>