import {Component, ElementRef, ViewChild, ViewEncapsulation, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router, ActivatedRoute} from '@angular/router';
import { ModalService } from '@app/core/services/modal.service';
import { AuthService } from '@app/core/services/auth.service';
import { ResponseModel } from '@app/core/models/response.model';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private translate: TranslateService,
              private modalService: ModalService, private router: Router,
              private route: ActivatedRoute, private authService: AuthService) {
  }

  ngOnInit() {
    this.authService.logout('/login', true).subscribe(res => {
        if (res.data.status === 'loggedout') {
          this.router.navigateByUrl('/login');
        } else {
        }
      });
  }

}
