import { Injectable } from '@angular/core';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { BaseService } from '@app/core/services/base.service';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { ServiceMessageModel } from '@app/core/models/service-message.model';
import { BlockModel } from '../models/block.model';
import { RoomModel } from '../models/room.model';
import { DatetimeToDbPipe } from '@app/core/pipes/datetime-to-db.pipe';
import { CountryService } from './country.service';


@Injectable({
  providedIn: 'root'
})
export class BlockService extends BaseService implements BaseServiceI {

  // tslint:disable: variable-name
  message$: BehaviorSubject<ServiceMessageModel> = new BehaviorSubject(null);

  constructor(public router: Router, public apiService: ApiService, private http: HttpClient, private datetimeToDbPipe: DatetimeToDbPipe, private countryService: CountryService) {
    super(router, apiService, BlockModel);
  }

  createBlock(room: RoomModel, day: Date) {
    day.setHours(6);
    return this.sub('blocks').create({
      roomid: room.id.toString(),
      datetime_start: this.datetimeToDbPipe.transform(day),
      }
    );
  }

  removeBlockById(blockid: number) {
    return this.deleteById('/block-management/blocks/', blockid);
  }

  duplicateBlock(block: BlockModel) {
    return this.sub(`blocks/${block.id}/clone`).create({});
  }

  getBlocksByRoomId(roomid, stats = false) {
    return this.get('blocks', {roomid, include_stats: stats}, false, true);
  }

  getBlocksByCountryId(country_uniqid, stats = false) {
    return this.get('blocks', {country_uniqid, include_stats: stats}, false, true);
  }

  reorder(roomid, blocks: BlockModel[]) {
    const data = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < blocks.length; i++) {
      blocks[i].position = i;
      data.push({
        blockid: blocks[i].id,
        position: i,
      });
    }
    return this.sub('reorder').create({new_order: data, roomid});
  }

}

