import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-shared-sidebar-container',
  templateUrl: './sidebar-container.component.html',
  styleUrls: ['./sidebar-container.component.scss']
})
export class SidebarContainerComponent implements OnInit {
    constructor() { }
    ngOnInit() {}
}
