<ng-container *ngIf="answer">
  <div class="p-0 mb-4 {{ classes }}" *ngIf="answer.answer_data">
    <div class="mt-3">
      <div class="card">

        <div *ngIf="answer.answer_data.written" [innerHTML]="answer.answer_data.written" (mouseup)="selectText()" class="p-3 w-100"></div>
        
        <div class="vocal-container d-flex align-items-top">
          <div class="d-flex flex-column align-items-center img-card-box">
            <div class="card m-1">
              <div *ngIf="!placeholders && answer.answer_data.vocal_url">
                <div class="p-1">
                  <audio id="audio-player" controls>
                    <source [src]="answer.answer_data.vocal_url" type="audio/webm" controlsList="nodownload">
                    {{'BROWSER_NOT_SUPPORTED' | translate}}
                  </audio>
                </div>
              </div>

              <div *ngIf="placeholders && answer.answer_data.vocal_url" class="pdf-placeholder pdf-audio"></div>

              <div *ngIf="explicitMediaUrls && answer.answer_data.vocal_url" class="explicit-media-url mb-2">
                <a [href]="answer.answer_data.vocal_url">{{ 'MEDIA_DOWNLOAD' | translate }}</a>
                <span class="media-file-name">{{ explicitMediaFileName(answer.answer_data.vocal_url) }}</span>
              </div>

              <!--<div *ngIf="answer.answer_data.written" [innerHTML]="answer.answer_data.written" (mouseup)="selectText()" class="p-3 w-100"></div>-->
            </div>
          </div>

          <div *ngIf="answer.answer_data.vocal_data" class="card-transcription p-3 flex-grow-1">
            <h5>{{ 'TRANSCRIPTION' | translate }}</h5>
            <ng-scrollbar>
              <div *ngIf="answer.answer_data.vocal_data.file_words" (mouseup)="selectText()">
                <div
                  *ngFor="let word of answer.answer_data.vocal_data.file_words"
                  class="clickable float-left"
                  (click)="setAudioTime(word.start, word.end)">
                  {{word.word}}&nbsp;
                </div>
              </div>
              <div class="transcription" [innerHTML]="answer.answer_data.vocal_data?.hlTranscription"></div>
            </ng-scrollbar>
          </div>
        </div>


      </div>
    </div>
  </div>
</ng-container>
