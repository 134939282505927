import { BlogModel } from './../models/blog.model';
import { Injectable } from '@angular/core';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { BaseService } from '@app/core/services/base.service';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { FaqModel } from '../models/faq.model';


@Injectable({
  providedIn: 'root'
})
export class HelpService extends BaseService implements BaseServiceI {


  constructor(public router: Router, public apiService: ApiService, private http: HttpClient) {
    super(router, apiService, FaqModel);
  }

  getFaq() {
    return this.apiService.get('/api/help/faq', null, environment.websiteUrl);
  }

}
