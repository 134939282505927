<!-- RESEARCH -->
<div class="banner-eco"><i class="fas fa-leaf"></i> Please consider the enviroment</div>

<div class="banner-media d-flex justify-content-center">
    <div class="d-flex align-items-center">
        <div><i class="fas fa-photo-video"></i></div>
        <div>{{ 'PDF_MEDIA_WARNING' | translate }}</div>
    </div>
</div>

<div *ngIf="data && ready" class="p-4 big-txt">
    <div class="d-flex justify-content-between align-items-start">
        <div class="flex-grow-1">
            <h1>{{data.country.title}}</h1>

            <h4><i class="icon fal fa-calendar-alt mr-2"></i>{{'FROM_DATE' | translate}} {{data.country.research_date_start | dateToLocaleString}} {{'TO_DATE' | translate}} {{data.country.research_date_end | dateToLocaleString}}</h4>
            <div class="row mb-4">
                <div class="col-6">
                    <table class="mb-0">
                        <tr>
                            <th width="100">{{ 'COUNTRY' | translate }}</th>
                            <td>{{ data.country.country_code }}</td>
                        </tr>
                        <tr>
                            <th>{{ 'LANGUAGE' | translate }}</th>
                            <td>{{ data.country.language_code }}</td>
                        </tr>
                        <tr>
                            <th>{{ 'TIMEZONE' | translate }}</th>
                            <td>{{ data.country.timezone }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <!-- alcuni dati della country e della ricerca ancora non vengono ritornati dal be, come l'elenco ricercatori -->
            <div class="mb-5">
                <h4><i class="icon fal fa-users mr-2"></i>{{ 'RESEARCHERS' | translate }}</h4>
                <ul class="researchers-list">
                    <li *ngFor="let r of data.researchers_data">
                        {{ r.firstname }} {{ r.lastname }}
                        &lt;<a [href]= "'mailto:' + r.email">{{ r.email }}</a>&gt;
                    </li>
                </ul>
            </div>
        </div>
        <img class="rounded" style="max-width: 100px;" *ngIf="data.country.logo_url" [src]="data.country.logo_url | privatesrc" alt="">
    </div>
    <!-- INDEX -->
    <h4><i class="icon fal fa-list mr-2"></i>{{ 'INDEX' | translate }}</h4>
    <ul class="research-index mb-5">
        <li *ngFor="let room of data.rooms | keyvalue" class="room p-2">
            <div class="panel shadow p-3 h-100">
                <a [href]="'#room_' + room.value.id" class="room-title">
                    {{ room.value.title }}
                </a>
                <ul class="room-index">
                    <li *ngFor="let day of blocksByDays[room.value.id] | keyvalue; let i = index" class="day">
                        <a [href]="'#room_' + room.value.id + '_day_' + i">
                            {{ 'DAY' | translate }} {{ i + 1 }} - {{ day.key | dateToLocaleString }}
                        </a>
                        <ul class="day-index">
                            <li *ngFor="let block of day.value | keyvalue">
                                <a [href]="'#block_' + block.value.id">
                                    {{ block.value.title }}
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </li>
    </ul>
    <ng-container *ngFor="let room of data.rooms | keyvalue">
        <ng-container [ngTemplateOutlet]="roomTemplate" [ngTemplateOutletContext]="{ room: room.value }"></ng-container>
    </ng-container>
</div>



<!-- ROOM -->
<ng-template #roomTemplate let-room="room">
    <div class="room-wrap mb-5" [id]="'room_' + room.id">
        <div class="room-wrap-title">
            <h2 class="mb-2">{{ room.title }}</h2>
            <h5 class="mb-2"><i class="fal fal fa-calendar-alt mr-2"></i>{{ 'FROM_DATE' | translate }} {{ room.date_start | dateToLocaleString }} {{ 'TO_DATE' | translate }} {{ room.date_end | dateToLocaleString }}</h5>
        </div>
        <ng-container *ngFor="let day of blocksByDays[room.id] | keyvalue; let i = index">
            <h3 [id]="'room_' + room.id + '_day_' + i" class="mb-3">{{ 'DAY' | translate }} {{ i + 1 }} - {{ day.key | dateToLocaleString }}</h3>
            <ul class="ul-blocks panel shadow p-3">
                <li *ngFor="let block of day.value">
                    <ng-container [ngTemplateOutlet]="blockTemplate" [ngTemplateOutletContext]="{ block: block }"></ng-container>
                </li>
            </ul>
        </ng-container>
    </div>
</ng-template>


<!-- BLOCK -->
<ng-template #blockTemplate let-block="block">
    <div class="block-wrap" [id]="'block_' + block.id">
        <div class="d-flex align-items-center mb-4">
            <h4 class="font-weight-normal mb-0 mr-3">{{ block.title }}</h4>
            <div class="d-flex">
                (
                <span><i class="icon fal fa-calendar-alt mr-1"></i>{{ 'FROM_DATE' | translate }} {{ block.datetime_start | dateToLocaleString }} {{ 'TO_DATE' | translate }} {{ block.datetime_end | dateToLocaleString }}</span>
                <span class="mx-3">&#8210;</span>
                <span>{{ (block.is_diary ? 'ROOM.INDIVIDUAL' : 'ROOM.GROUP') | translate }}</span>
                )
            </div>
        </div>
        <h5>{{ 'QUESTIONS_LIST' | translate }}:</h5>
        <ol class="ul-questions">
            <li *ngFor="let questionkv of block.questions | keyvalue">
                <span><ng-container [ngTemplateOutlet]="questionTemplate" [ngTemplateOutletContext]="{ question: questionkv.value }"></ng-container></span>
            </li>
        </ol>
    </div>
</ng-template>



<!--[style.height]="charts[question.id].dim.h"-->
<!-- QUESTION -->
<ng-template #questionTemplate let-question="question">
    <div class="question-wrap">
        <div [innerHtml]="question.title"></div>
        <span class="badge badge-secondary question-type-badge mb-3">{{ getQuestionType(question.type).name }}</span>
        <div *ngIf="hasChart(question) && (arrayFromObj(question.answers).length) && charts[question.id]" class="chart d-flex justify-content-center">
            <highcharts-chart
                [Highcharts]="Highcharts"
                [options]="charts[question.id].options"
                [style.width]="charts[question.id].dim.w"
                [style.height]="charts[question.id].dim.h"
                style="display: block; page-break-before: avoid; page-break-after: avoid; position: relative;">
            </highcharts-chart>
        </div>
        <div *ngIf="!(arrayFromObj(question.answers).length)">
            <h5>{{ 'THERE_ARE_NO_ANSWERS' | translate }}</h5>
        </div>
        <ul class="ul-answers">
            <li *ngFor="let answerkv of question.answers | keyvalue">
                <ng-container [ngTemplateOutlet]="answerTemplate" [ngTemplateOutletContext]="{ answer: getInstance('answer', answerkv.value), question: question }"></ng-container>
            </li>
        </ul>
    </div>
</ng-template>


<!-- ANSWER -->
<ng-template #answerTemplate let-answer="answer" let-question="question">
    <div class="answer-wrap">
        <div class="message"><!-- [ngClass]="{'deleted': deleted, 'highlight': highlighted}" possibile ? -->
            <div class="panel shadow p-0">
                <div class="panel-header d-flex align-items-center">
                    <app-moderation-answers-avatar [user]="answer.team" [answer]="answer" [exactTime]=true></app-moderation-answers-avatar>
                </div>
                <div class="panel-body">
                    <div class="message-wrapper">
                        <div class="message py-3">
                            <app-moderation-answerdispatcher [explicitMediaUrls]="true" [placeholders]="mediaPlaceholder" [question]="question" [item]="answer"></app-moderation-answerdispatcher>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ml-4 pl-4 comments">
            <ng-container *ngIf="answer.comments.length">
                <h5>{{ 'COMMENTS' | translate }}</h5>
                <div class="message mine" *ngFor="let comment of answer.comments">
                    <app-moderation-answer-comment [impersonal]="true" [commentAnswer]="getInstance('comment', comment)" [answer]="answer"></app-moderation-answer-comment>
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>

