import {Component, ViewEncapsulation, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, Form} from '@angular/forms';
import { Mode } from '../../../../../../../setup';
import { ModalService } from '@app/core/services/modal.service';
import { AuthService } from '@app/core/services/auth.service';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { ToastrService } from 'ngx-toastr';
import { ResponseModel } from '@app/core/models/response.model';
import { LanguagesService } from '@app/shared/services/languages.service';
@Component({
  selector: 'app-auth-signupform',
  templateUrl: './auth-signupform.component.html',
  styleUrls: ['./auth-signupform.component.scss']
})
export class AuthSignupFormComponent extends CoreBaseComponent implements OnInit {

  form: UntypedFormGroup;
  passwordMinLength = Mode.PASSWORD_MIN_LENGTH;
  currentLang = 'en';

  constructor(private translate: TranslateService, private formBuilder: UntypedFormBuilder, private toastService: ToastrService,
              private route: ActivatedRoute, private authService: AuthService, private modalService: ModalService, private languagesService: LanguagesService) {
    // this.appService.pageTitle = 'Accedi';
    super();
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.email
      ]),
      firstname: new UntypedFormControl('', Validators.required),
      lastname: new UntypedFormControl('', Validators.required),
      company_declared: new UntypedFormControl('', Validators.nullValidator),
      language_code: new UntypedFormControl(this.getLocalLang(), Validators.nullValidator),
      password: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?!.*[\%\/\\\&\?\,\'\;\:\+\(\)\#\@\.\$\^\*\_\!\-]{2})(?=.*?[_,.+()/#?!@$%^&*-]).{8,}$')
      ]),
      consentA: new UntypedFormControl('', Validators.requiredTrue),
      consentB: new UntypedFormControl('', Validators.requiredTrue)
    });
    this.currentLang = this.languagesService.currentLanguage;
  }

  privacyModal() {
    this.modalService.open({title: 'PRIVACY_POLICY', dialogSize: 'lg', message: this.translate.instant('PRIVACY_POLICY_SIGN_UP')});
  }

  submit() {
    this.operation('signing-up').reset().isSubmitting();
    this.form.controls.email.setValue(this.form.controls.email.value.toLowerCase());
    this.authService.signUp(this.form).subscribe((response: ResponseModel) => {
      if (response.getErrors()) {
        this.operation('signing-up').isFailed(response.getErrors());
        this.toastService.error(this.translate.instant('ERRORS.CANTSIGNUP'), 'Sign up');
      } else {
        this.operation('signing-up').isSuccess(response.getErrors());
      }
    }, (error) => {
      this.operation('signing-up').isFailed(error.errors);
      this.toastService.error(this.translate.instant('ERRORS.CANTSIGNUP'), 'Sign up');
    });
  }
}
