<div>
    <table [id]="tableid" [ngClass]="{'loading': loading, 'no-items': !itemsToShow.length}">
        <thead>
            <th
                *ngFor="let column of columns" (click)="clickOnColumn(column.name || column.icon_name)" [class]="column.class"
            >
                <span
                    [ngClass]="column.tooltip ? 'table-tooltip' : ''" [placement]="column.placement ? column.placement : 'top'" [ngbTooltip]="column.tooltip ? (column.tooltip | translate) : ''"
                    *ngIf="column.icon_name && (column.icon_align === 'left' || !column.icon_align)"><i [class]="column.icon_name" [style]="(column.color ? 'color: ' + column.color : '' )"></i>
                </span>
                <span
                    [ngClass]="column.tooltip ? 'table-tooltip' : ''" [placement]="column.placement ? column.placement : 'top'" [ngbTooltip]="column.tooltip ? (column.tooltip | translate) : ''"
                    *ngIf="column.sorter" class="mr-1"><i class="fal fa-sort"></i>
                </span>
                <span
                    [ngClass]="column.tooltip ? 'table-tooltip' : ''" [placement]="column.placement ? column.placement : 'top'" [ngbTooltip]="column.tooltip ? (column.tooltip | translate) : ''"
                    *ngIf="column.name">{{ column.name | translate }}
                </span>
                <span
                    [ngClass]="column.tooltip ? 'table-tooltip' : ''" [placement]="column.placement ? column.placement : 'top'" [ngbTooltip]="column.tooltip ? (column.tooltip | translate) : ''"
                    *ngIf="column.icon_name && column.icon_align === 'right'"><i [class]="column.icon_name" [style]="(column.color ? 'color: ' + column.color : '' )"></i>
                </span>
            </th>
        </thead>
        <tbody>
            <ng-container *ngIf="itemsToShow.length">
                <ng-container
                *ngFor="let item of itemsToShow; let i = index;"
                [ngTemplateOutlet]='rowTemplate'
                [ngTemplateOutletContext]="{item:item}"></ng-container>
            </ng-container>
            <ng-container *ngIf="!itemsToShow.length && !loading">
                <tr>
                    <td [attr.colspan]="columns.length">{{ errorMessage | translate }}</td>
                </tr>
            </ng-container>
            <ng-container *ngIf="loading && !itemsToShow.length">
                <tr>
                    <td [attr.colspan]="columns.length">
                        <app-core-spinner toggle="loading"></app-core-spinner>
                    </td>
                </tr>
            </ng-container>
        </tbody>
        <tfoot *ngIf="itemsToShow.length && pagination">
            <td [attr.colspan]="columns.length">
                <div class="mr-3 mt-2 d-inline-block">
                    <div class="spinner-border spinner-border-sm" *ngIf="loading" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    &nbsp;{{ (currentPage * perPage) + 1 }} - {{ ( currentPage * perPage) + itemsToShow.length }} di {{ totalItemsFound }}</div>
                <a class="mr-4 icon-nav" [ngClass]="{'disabled': currentPage <= 0}" (click)="left()">
                    <i class="fal fa-chevron-left"></i>
                </a>
                <a class="icon-nav" [ngClass]="{'disabled': currentPage >= pages - 1}" (click)="right()">
                    <i class="fal fa-chevron-right"></i>
                </a>
            </td>
        </tfoot>
    </table>
</div>