<app-shared-sidebar-container>
    <app-analysis-sidebar-header [title]="'READ_ALL_POSTS' | translate"></app-analysis-sidebar-header>


    <div class="sidebar-body">

        <section *ngIf="countryUniqId">
            <app-overview-modal-filter
                (searchFn)="getData()"
                [filterForm]="filterForm"
                [star]="true"
                [highlighted]="true"
                [countryUniqId]="countryUniqId">
            </app-overview-modal-filter>
        </section>
        <div class="alert alert-info mt-2"  *ngIf="operation('getting-answers').success && !answers.length && !comments.length">{{ 'THERE_ARE_NO_ANSWERS' | translate }}</div>
        <div class="my-3">
            <app-core-spinner [toggle]="operation('getting-answers').submitting"></app-core-spinner>
        </div>
        <div class="row mt-4" *ngIf="questions">
            <div class="col-12 d-flex align-items-center justify-content-end">
                <div class="check-radio-box mb-0 mr-4">
                    <input type="checkbox" [checked]="hasSelected()" (change)="selectAll($event)" id="check1">
                    <label for="check1">
                        {{ 'SELECT_ALL' | translate }}
                    </label>
                </div>
                <ng-template #exportPopOver>
                    <!--  -->
                    <div class="p-3">
                        <div class="row">
                            <div class="col-12">
                                <b>{{'CHOOSE_EXPORT_FORMAT' | translate }}</b>
                            </div>
                            <div class="col-12 mt-2 text-center">
                                <button class="btn-sicche btn-secondary" (click)="getXlsExport()"><i class="fal fa-file-excel"></i> XLS</button>
                            </div>
                            <div class="col-12 text-center">
                                <button class="btn-sicche btn-secondary" (click)="downloadSelected()"><i class="fal fa-file-pdf"></i> PDF</button>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <button *ngIf="!isSmart" [disabled]="!hasSelected() || downloading" [ngbPopover]="exportPopOver" class="btn btn-sicche">
                    <i class="fal fa-download"></i>&nbsp;
                    <span *ngIf="!downloading">{{ 'DOWNLOAD_SELECTED' | translate }}</span>
                    <span *ngIf="downloading">{{ 'WAIT_PLEASE' | translate }}</span>
                </button>
            </div>
        </div>

        <div>
            <div class="row" *ngIf="loading">
                <div class="col-12 text-center" >
                    <app-core-spinner></app-core-spinner>
                </div>
            </div>
            <div class="row" *ngIf="!loading && questions?.length">
                <div class="w-100" *ngFor="let question of questions; let i = index">
                    <div *ngIf="i <= infiniteScrollIndex">
                        <div class="col-12 mt-1">
                            <div class="mt-3" [innerHTML]="question.title">

                            </div>
                            <span *ngIf="question.type !== 'communications'">
                                <span *ngIf="question.answers.length">
                                    <div *ngFor="let answer of question.answers" class="position-relative">
                                        <app-analysis-answer-wrapper
                                            [answer]="answer"
                                            (textSelection)="selectText($event)"
                                        ></app-analysis-answer-wrapper>
                                        <span *ngIf="getAnswerComments(answer, question).length" >
                                            <div *ngFor="let comment of getAnswerComments(answer, question)" class="comment-block">
                                                <app-moderation-answer-comment
                                                    (textSelection)="selectText($event)"
                                                    [selectable]="true"
                                                    [id]="'answer-block-comment-' + comment.id"
                                                    [commentAnswer]="comment"
                                                    [verbose]="true"
                                                    [readonly]="true"
                                                    [answer]="answer">
                                                </app-moderation-answer-comment>
                                            </div>
                                        </span>
                                    </div>
                                </span>
                                <span *ngIf="getOrphansComments(question).length">
                                    <div *ngFor="let comment of getOrphansComments(question)" class="comment-block">
                                        <app-moderation-answer-comment
                                            (textSelection)="selectText($event)"
                                            [selectable]="true"
                                            [id]="'answer-block-comment-' + comment.id"
                                            [commentAnswer]="comment"
                                            [verbose]="true"
                                            [readonly]="true"
                                            >
                                        </app-moderation-answer-comment>
                                    </div>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-12 text-center">
                    <button class="btn btn-sicche" [disabled]="infiniteScrollIndex >= questions?.length" (click)="loadMore()">
                        {{'SHOW_MORE' | translate }}
                    </button>
                </div>
            </div>
        </div>

    </div>

    <div class="overlay-extract" *ngIf="showSelectionModal">
        <app-moderation-quotes-modal [answer]="quotedAnswer" [extraData]="quotedCommentid ? {commentid: quotedCommentid} : null" [commentid]="quotedCommentid" (closeFunc)="quotesModalClose($event)"></app-moderation-quotes-modal>
    </div>
</app-shared-sidebar-container>