import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { InterviewedsService } from '@app/shared/services/intervieweds.service';
// import { ResearchService } from '@app/shared/services/research.service';
import { ResearchModel } from '@app/shared/models/research.model';
import { BaseModel } from '@app/core/models/base';
import { ResearchService } from '@app/shared/services/research.service';
import { ResponseModel } from '@app/core/models/response.model';
import { LanguagesService } from '@app/shared/services/languages.service';
import { CountriesService } from '@app/core/services/countries.service';
import { PageModel } from '@app/core/models/page';
import { DiscountCodesService } from '@app/shared/services/discount-codes.service';
import { DiscountModel } from '@app/shared/models/discount.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@app/core/services/auth.service';
import { InfoService } from '@app/shared/services/info.service';
import { CountryModel } from '@app/shared/models/country.model';
import { TimezoneService } from '@app/core/services/timezone.service';
import { ManageResearchesService } from '@app/shared/services/support/manage-researches.service';
import { ServiceErrorModel } from '@app/core/models/service-error.model';

@Component({
  selector: 'app-private-new-research',
  templateUrl: './new-research.component.html',
  styleUrls: ['./new-research.component.scss']
})
export class NewResearchComponent extends CoreBaseComponent implements OnInit {

  @Output()
  addedCallback: EventEmitter<any> = new EventEmitter(null);
  @Output()
  newResearchClosed: EventEmitter<any> = new EventEmitter(null);
  form: UntypedFormGroup;
  public maxCountries = 20;
  public total = 0;
  public discount: DiscountModel = null;
  public discountIsValid: boolean = null;
  public infoService = InfoService;
  public priceData: {final_price: number, discount: number, original_price: number};
  availableLanguages = [];

  get countries() {
    return this.form.get('countries') as UntypedFormArray;
  }

  constructor(public activeModal: NgbActiveModal, public interviewedsService: InterviewedsService, private toastService: ToastrService,
              private formBuilder: UntypedFormBuilder, private researchService: ResearchService, public languagesService: LanguagesService,
              public countriesService: CountriesService, private discountCodesService: DiscountCodesService,
              private manageResearchesService: ManageResearchesService,
              private translate: TranslateService, public authService: AuthService, public timezoneService: TimezoneService) {
    super();
  }

  ngOnInit() {
    this.createForm();

    this.availableLanguages = this.languagesService.getLanguageList();
  }

  createForm() {
    this.form = this.formBuilder.group({
      nr_effective_days: new UntypedFormControl('1', Validators.required),
      nr_interviewed: new UntypedFormControl('25', Validators.required),
      title: new UntypedFormControl('', Validators.required),
      discount_code: new UntypedFormControl('', Validators.nullValidator),
      extra_track_loading: new UntypedFormControl(''),
      extra_set_team: new UntypedFormControl(''),
      extra_video_editing: new UntypedFormControl(''),
      notes: new UntypedFormControl(''),
      countries: this.formBuilder.array([])
    });
    this.addCountryLang();
    this.computeTotal();

    this.form.get('nr_effective_days').valueChanges.subscribe(val => {
      this.form.patchValue({discount_code: null});
      this.computeTotal(null, null, true);
    });
    this.form.get('nr_interviewed').valueChanges.subscribe(val => {
      this.form.patchValue({discount_code: null});
      this.computeTotal(null, null, true);
    });
    this.form.get('countries').valueChanges.subscribe(val => {
      this.form.patchValue({discount_code: null});
      this.computeTotal(null, null, true);
    });
    this.form.get('extra_track_loading').valueChanges.subscribe(val => {
      this.form.patchValue({discount_code: null});
      this.computeTotal(null, null, true);
    });
    this.form.get('extra_video_editing').valueChanges.subscribe(val => {
      this.form.patchValue({discount_code: null});
      this.computeTotal(null, null, true);
    });
    this.form.get('extra_set_team').valueChanges.subscribe(val => {
      this.form.patchValue({discount_code: null});
      this.computeTotal(null, null, true);
    });
    this.form.get('discount_code').valueChanges.subscribe(val => {
      if (!val) {
        // this.discountIsValid = null;
        this.computeTotal(null, null, true);
      }
    });
  }

  computeTotal(success?: any, failed?: any, withDiscountCode = true) {
    const research = this.makeResearch();
    if (!withDiscountCode) {
      research.discount_code = null;
    }
    this.manageResearchesService.calculatePrice(research).subscribe((res: ResponseModel) => {
      this.priceData = res.getData();
      this.total = this.priceData.final_price;
      if (success) {
        success();
      }
      if (this.priceData.discount) {
        this.discountIsValid = true;
      } else {
      }
    }, (e: ServiceErrorModel) => {
      this.priceData = e.getData();
      this.discountIsValid = false;
      if (failed) {
        failed();
      }
    });
    // this.total = research.computePrice();
    if (this.discount) {
     this.verifyDiscountCode();
    }
  }

  addCountryLang() {
    this.countries.push(new UntypedFormGroup({
      country_code: new UntypedFormControl('it', null),
      language_code: new UntypedFormControl('it', null),
      timezone: new UntypedFormControl('Europe/Rome', null)
    }));
  }

  updateTimezone(e, controlIndex: number) {
    const timezone = this.timezoneService.getTimezoneByCountryCode(e.country_code);
    this.form.controls.countries['controls'][controlIndex].controls.timezone.setValue(timezone);

    // se il paese selezionato fa parte delle lingue tradotte viene preimpostata, altrimenti metto inglese
    const translatedLanguages = this.languagesService.values.map(lang => lang.language_code)
    if (translatedLanguages.includes(e.country_code)) {
      this.form.controls.countries['controls'][controlIndex].controls.language_code.setValue(e.country_code);
    } else {
      this.form.controls.countries['controls'][controlIndex].controls.language_code.setValue('en');
    }
  }

  removeCountryLang(index: number) {
    this.countries.removeAt(index);
  }

  makeResearch(): ResearchModel {
    return (new ResearchModel()).assignForm(this.form).assignProperty('price', this.total);
  }

  verifyDiscountCode() {
    const operation = this.operation('checking-code').reset().isSubmitting();
    return this.computeTotal(() => {
      operation.isSuccess();
    }, () => {
      operation.isFailed();
    });
    /*
    this.discountIsValid = null;
    this.discount = null;
    this.operation('checking-code').reset().isSubmitting();
    this.discountCodesService.verifyCode(this.makeResearch()).subscribe((response: ResponseModel) => {
      this.discount = response.getData();
      this.discountIsValid = this.discount.isValid();
      this.operation('checking-code').isEnded();
    }, (error) => {
      this.discountIsValid = false;
      this.operation('checking-code').isFailed(error.errors);
    });
    */
  }

  closeModal() {
    this.activeModal.close();
    this.newResearchClosed.emit(true);
  }

  submit() {
    this.discountIsValid = null;
    this.operation('research-submit').reset().isSubmitting();
    this.researchService.create(this.makeResearch()).subscribe((response: ResponseModel) => {
      this.operation('research-submit').isSuccess(response.getErrors());
      this.addedCallback.emit(response.getData());
    }, (error) => {
      if (error.errors.hasOwnProperty('code')) {
        this.discountIsValid = false;
      }
      this.operation('research-submit').isFailed(error.errors);
    });
  }

}
