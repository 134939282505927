
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient, HttpParameterCodec } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { StorageService } from './storage.service';
import { Mode, BASIC_AUTH } from '../../../../setup';
import { environment } from '../../../environments/environment';
import { ResponseModel } from '../models/response.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  $selectRecoveryMode: boolean;

  constructor(
    private http: HttpClient,
    private Storage: StorageService,
    ) {
  }


  public set selectRecoveryMode(v : boolean) {
    if (v) {
      localStorage.setItem('select_revovery_mode', '1');
    } else {
      localStorage.removeItem('select_revovery_mode');
    }
    this.$selectRecoveryMode = v;
  }

  public get selectRecoveryMode() {
    return this.$selectRecoveryMode || (localStorage.getItem('select_revovery_mode') ? true : false);
  }


  formatErrors(error: any) {
    return error.error;
  }

  url(path: string) {
    return `${environment.serviceUrl}${path}`;
  }

  post(path: string, body = {}, serviceUrl = environment.serviceUrl): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(`${serviceUrl}${path}`, body, this.composeHeaders())
      .pipe(map(response => {
        return new ResponseModel(response);
      }));
  }

  postFormData(path: string, formData: any, serviceUrl = environment.serviceUrl): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(`${serviceUrl}${path}`, formData, this.composeHeadersFormData())
      .pipe(map(response => {
        return new ResponseModel(response);
      }));
  }

  put(path: string, body = {}, serviceUrl = environment.serviceUrl): Observable<ResponseModel> {
    return this.http.put(`${serviceUrl}${path}`, body, this.composeHeaders())
    .pipe(map(response => {
      return new ResponseModel(response);
    }));
  }

  delete(path: string, serviceUrl = environment.serviceUrl): Observable<ResponseModel> {
    return this.http.delete(`${serviceUrl}${path}`, this.composeHeaders())
    .pipe(map(response => {
      return new ResponseModel(response);
    }));
  }

  patch(path: string, body = {}, serviceUrl = environment.serviceUrl): Observable<ResponseModel> {
    return this.http.patch(`${serviceUrl}${path}`, body, this.composeHeaders())
    .pipe(map(response => {
      return new ResponseModel(response);
    }));
  }

  get(path: string, params?: any, serviceUrl = environment.serviceUrl): Observable<ResponseModel> {
    return this.http.get(`${serviceUrl}${path}`, this.composeHeaders(params))
    .pipe(map(response => {
      return new ResponseModel(response);
    }));
  }

  blobGet(path: string, params?: any, serviceUrl = environment.serviceUrl): Observable<ResponseModel> {
    params = this.composeHeaders(params).params;
    return this.http
      .get(`${serviceUrl}${path}`, {
          responseType: 'blob',
          headers: this.composeHeaders().headers,
          params
        }
      ).pipe(map(response => {
        return new ResponseModel({blob: response});
      }));
  }

  blobPost(path, data, serviceUrl = environment.serviceUrl): Observable<ResponseModel> {
    return this.http.post(`${serviceUrl}${path}`, data, {
      responseType: 'blob',
      headers: this.composeHeaders().headers
    }).pipe(map(response => {
      return new ResponseModel({blob: response});
    }));
  }

  print(url) {
    this.http.get(url, {
      responseType: 'blob'
    }).subscribe(
        (resp) => {
            const pdf = new Blob([resp], { type: 'application/pdf' });
            const objectURL = URL.createObjectURL(pdf);
            const frm = document.createElement('iframe');
            frm.style.display = 'none';
            frm.src = objectURL;
            document.body.appendChild(frm);
            frm.onload = () => {
                frm.contentWindow.print();
            };
        });
  }

  // todo ridondante. Vd. composeHeaders
  composeHeadersFormData() {
    const headersMap: {[header: string]: string} = {};
    const httpOptionsOutput = {
      headers: new HttpHeaders(environment.httpHeaders),
      withCredentials: Mode.HEADER_WITH_CREDENTIALS,
      params: null
    };
    switch (Mode.AUTH_TYPE.toLowerCase()) {
      case 'bearer': {
        const bearer = this.Storage.get('bearer');
        if (bearer) {
          headersMap.Authorization = `Bearer ${bearer}`;
        }
        break;
      }
      case 'phpsessid': {
        const phpsessid = this.Storage.get('phpsessid');
        if (phpsessid) {
          headersMap.PHPSESSID = phpsessid;
        }
        break;
      }
    }
    if (Mode.BASIC_AUTH === true) {
      headersMap.Authorization = 'Basic ' + btoa(BASIC_AUTH.USERNAME + ':' + BASIC_AUTH.PASSWORD);
    }
    httpOptionsOutput.headers = new HttpHeaders(headersMap);

    return httpOptionsOutput;
  }

  composeHeaders(params?) {
    const headersMap: {[header: string]: string} = {};
    let headers: HttpHeaders;
    const httpOptionsOutput = {
      headers: new HttpHeaders(environment.httpHeaders),
      withCredentials: Mode.HEADER_WITH_CREDENTIALS,
      params: new HttpParams({encoder: new CustomEncoder()})
    };
    switch (Mode.AUTH_TYPE.toLowerCase()) {
      case 'bearer': {
        const bearer = this.Storage.get('bearer');
        if (bearer) {
          headersMap.Authorization = `Bearer ${bearer}`;
        }
        break;
      }
      case 'phpsessid': {
        const phpsessid = this.Storage.get('phpsessid');
        if (phpsessid) {
          headersMap.PHPSESSID = phpsessid;
        }
        break;
      }
    }

    if (Mode.BASIC_AUTH === true) {
      headersMap.Authorization = 'Basic ' + btoa(BASIC_AUTH.USERNAME + ':' + BASIC_AUTH.PASSWORD);
    }

    // if (params) {
    //   Object.keys(params).forEach(key => {
    //     if (params[key]) {
    //       headersMap[key] = params[key];
    //     }
    //   });
    // }

    if (headersMap) {
      headers = new HttpHeaders(headersMap);
    }

    if (environment.httpHeaders) {
      Object.keys(environment.httpHeaders).forEach(key => {
        headersMap[key] = environment.httpHeaders[key];
      });
    }

    httpOptionsOutput.headers = new HttpHeaders(headersMap);

    if (params) {
      // httpOptionsOutput.params = params.params ? params.params : null;
      for (const key in params.params) {
        if (Object.prototype.hasOwnProperty.call(params.params, key)) {
          const element = params.params[key];
          httpOptionsOutput.params = httpOptionsOutput.params.set(key, element);
        }
      }
    }

    if (this.selectRecoveryMode) {
      httpOptionsOutput.params = httpOptionsOutput.params.set('select_recovery_mode', 'true');
    }

    return httpOptionsOutput;
  }
/*
  stripeGet(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.cms_url}${path}`, {headers: this.composeHeaders(), params});
  }
*/

}
// l'url encode di angular dà problemi con il +, override con encodeURIComponent
class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }
  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
