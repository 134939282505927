import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../../services/sidebar.service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
@Component({
  selector: 'app-core-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  visible = true;

  constructor(public sidebarService: SidebarService, public router: Router) { }

  ngOnInit() {
  }

  toggleSidebar() {
    this.visible = !this.visible;
  }

}
