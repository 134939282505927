import { Component, OnInit, Input } from '@angular/core';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { CountryService } from '@app/shared/services/country.service';
import { ResponseModel } from '@app/core/models/response.model';
import { AnalysisService } from '@app/shared/services/analysis.service';
import { AnalysisModel } from '@app/shared/models/analysis.model';

@Component({
  selector: 'app-overview-modal-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.scss']
})
export class OverviewModalAudioComponent extends CoreBaseComponent implements OnInit {

  @Input()
  type: string;
  @Input()
  countryUniqId = null;
  public filterForm: UntypedFormGroup = null;
  public audios: any[] = [];
  public isSmart = false;

  constructor(private coreLayoutService: CoreLayoutService, private translate: TranslateService,
              private countryService: CountryService, public analysisService: AnalysisService) {
    super();
  }

  ngOnInit(): void {
    this.isSmart = this.countryService.currentCountryIsClientBasic();
    this.operation('getting-audios').isSuccess();
    this.coreLayoutService.title('AUDIO');
    // Filter form
    this.filterForm = new UntypedFormGroup({
      room: new UntypedFormControl('', null),
      block: new UntypedFormControl('', null),
      question: new UntypedFormControl('', null),
      sample_variable_tags: new UntypedFormControl('', null),
      personas: new UntypedFormControl('', null),
      block_datetime: new UntypedFormControl('', null),
      answer_tags: new UntypedFormControl('', null),
      rating: new UntypedFormControl('', null),
      only_highlighted: new UntypedFormControl('', null),
      keyword_search_content: new UntypedFormControl('', null),
      intervieweds: new UntypedFormControl('', null),
      only_contest: new UntypedFormControl('', null),
      only_live: new UntypedFormControl('', null),
    });
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  selectAll(event: any) {
    this.audios.map((audio: any) => event.target.checked ? audio.state.selected = true : audio.state.selected = false);
  }

  getAudioByFilter() {
    let date = '';
    if (this.filterForm.value.block_datetime) {
      date = this.formatDate(this.filterForm.value.block_datetime.date)
    }
    const all_rooms = this.filterForm.value.room ? this.filterForm.value.room.length == 1 && this.filterForm.value.room[0].id == 0 ? true : false : false;  // todo mod
    this.operation('getting-audios').reset().isSubmitting();
    const filter = {
      all_rooms,
      only_highlighted: this.filterForm.value.only_highlighted,
      only_contest: this.filterForm.value.only_contest,
      only_live: this.filterForm.value.only_live,
      keyword_search_content: this.filterForm.value.keyword_search_content,
      intervieweds: this.filterForm.value.intervieweds,
      rating: this.filterForm.value.rating ? this.filterForm.value.rating : null,
      questionid: this.filterForm.value.question ? this.filterForm.value.question.id : null,
      block_datetime: date,
      roomids: (this.filterForm.value.room[0].id !== 0) ? this.filterForm.value.room.map(item => item.id) : [],
      blockids: this.filterForm.value.block ? this.filterForm.value.block.map(item => item.id) : null,
      answer_tags: this.filterForm.value.answer_tags ? this.filterForm.value.answer_tags : [],
      personas_tags: this.filterForm.value.personas ? this.filterForm.value.personas : [],
      team_sample_variable_tags: this.filterForm.value.sample_variable_tags ? this.filterForm.value.sample_variable_tags : []
    };

    Object.keys(filter).forEach((key) => (!filter[key]) && delete filter[key]);

    this.analysisService.getAudiosBy(this.countryUniqId, filter).subscribe((res: ResponseModel) => {
      this.operation('getting-audios').isSuccess();
      this.audios = res.getData(true).filter(item => item.file_uniqid).map(item => new AnalysisModel(item));
    });
  }

  hasSelected() {
    return this.audios.find((video: any) => video.state.selected === true);
  }

  downloadSelected() {
    const ids = [];
    this.audios.forEach((audio: any) => {
      if (audio.state.selected) {
        ids.push(audio.file_uniqid);
      }
    });

    const filter = {
      only_highlighted: this.filterForm.value.only_highlighted,
      only_contest: this.filterForm.value.only_contest,
      only_live: this.filterForm.value.only_live,
      keyword_search_content: this.filterForm.value.keyword_search_content,
      intervieweds: this.filterForm.value.intervieweds,
      rating: this.filterForm.value.rating ? this.filterForm.value.rating : '',
      roomid: this.filterForm.value.room ? this.filterForm.value.room.id : '',
      questionid: this.filterForm.value.question ? this.filterForm.value.question.id : '',
      block_datetime: this.filterForm.value.block_datetime ? this.filterForm.value.block_datetime.date : '',
      download_zip: true,
      file_uniqids: ids,
      blockids: this.filterForm.value.block ? this.filterForm.value.block.map(item => item.id) : '',
      team_personas_tags: this.filterForm.value.personas ? this.filterForm.value.personas : [],
      team_sample_variable_tags: this.filterForm.value.sample_variable_tags ? this.filterForm.value.sample_variable_tags : []
    };

    Object.keys(filter).forEach((key) => (!filter[key]) && delete filter[key]);

    this.analysisService.getDownloadAudiosBy(this.countryUniqId, filter).subscribe((res: ResponseModel) => {
      res.download('audios.zip');
    });

  }

}
