
import { Injectable } from '@angular/core';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { BaseService } from '@app/core/services/base.service';
import { ApiService } from '@app/core/services/api.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { of, Observable } from 'rxjs';
import { SearchParams } from '@app/core/models/search-params';
import { PageModel } from '@app/core/models/page';

@Injectable({
  providedIn: 'root'
})
export class CountriesService extends BaseService implements BaseServiceI {

  values: any[];

  constructor(public router: Router, public apiService: ApiService, public translateService: TranslateService) {
    super(router, apiService, null);
    const slugsArray = ['AF', 'AL', 'DZ', 'AD', 'AO', 'AI', 'AQ', 'AG', 'AN', 'SA', 'AR', 'AM', 'AW', 'AU',
    'AT', 'AZ', 'BS', 'BH', 'BD', 'BB', 'BE', 'BZ', 'BJ', 'BM', 'BT', 'BY', 'BO', 'BA',
    'BW', 'BR', 'BN', 'BG', 'BF', 'BI', 'KH', 'CM', 'CA', 'CV', 'TD', 'CL', 'CN', 'CY',
    'CO', 'KM', 'CG', 'KP', 'KR', 'CR', 'CI', 'HR', 'CU', 'DK', 'DM', 'EC', 'EG', 'SV',
    'AE', 'ER', 'EE', 'ET', 'RU', 'FJ', 'PH', 'FI', 'FR', 'GA', 'GM', 'GE', 'GS', 'DE',
    'GH', 'JM', 'JP', 'GI', 'DJ', 'JO', 'GR', 'GD', 'GL', 'GP', 'GU', 'GT', 'GG', 'GF',
    'GN', 'GQ', 'GW', 'GY', 'HT', 'HN', 'IN', 'ID', 'IR', 'IQ', 'IE', 'IS', 'BV', 'NF',
    'CX', 'IM', 'AX', 'KY', 'CC', 'CK', 'FK', 'FO', 'HM', 'MP', 'MH', 'UM', 'SB', 'TC',
    'VI', 'VG', 'IL', 'IT', 'JE', 'KZ', 'KE', 'KG', 'KI', 'KW', 'LA', 'LS', 'LV', 'LB',
    'LR', 'LY', 'LI', 'LT', 'LU', 'MG', 'MW', 'MV', 'MY', 'ML', 'MT', 'MA', 'MQ', 'MR',
    'MU', 'YT', 'MX', 'FM', 'MD', 'MC', 'MN', 'ME', 'MS', 'MZ', 'MM', 'NA', 'NR', 'NP',
    'NI', 'NE', 'NG', 'NU', 'NO', 'NC', 'NZ', 'OM', 'NL', 'PK', 'PW', 'PS', 'PA', 'PG',
    'PY', 'PE', 'PN', 'PF', 'PL', 'PT', 'PR', 'QA', 'HK', 'MO', 'CZ', 'CF', 'CD',
    'DO', 'MK', 'RO', 'RW', 'RE', 'EH', 'KN', 'LC', 'PM', 'VC', 'WS', 'AS', 'BL', 'SM',
    'SH', 'ST', 'SN', 'RS', 'CS', 'SC', 'SL', 'SG', 'SY', 'SK', 'SI', 'SO', 'ES', 'LK',
    'US', 'ZA', 'SD', 'SR', 'SJ', 'SE', 'CH', 'SZ', 'TJ', 'TH', 'TW', 'TZ', 'TF', 'IO',
    'TL', 'TG', 'TK', 'TO', 'TT', 'TN', 'TR', 'TM', 'TV', 'UA', 'UG', 'HU', 'UY', 'UZ',
    'VU', 'VA', 'VE', 'VN', 'WF', 'YE', 'ZM', 'ZW', 'ZZ', 'UK'];
    this.translateService.get(slugsArray).subscribe(trs => {
      const temp = [
        {country_code: 'af', name: trs.AF},
        {country_code: 'uk', name: trs.UK},
        {country_code: 'al', name: trs.AL},
        {country_code: 'dz', name: trs.DZ},
        {country_code: 'ad', name: trs.AD},
        {country_code: 'ao', name: trs.AO},
        {country_code: 'ai', name: trs.AI},
        {country_code: 'aq', name: trs.AQ},
        {country_code: 'ag', name: trs.AG},
        {country_code: 'an', name: trs.AN},
        {country_code: 'sa', name: trs.SA},
        {country_code: 'ar', name: trs.AR},
        {country_code: 'am', name: trs.AM},
        {country_code: 'aw', name: trs.AW},
        {country_code: 'au', name: trs.AU},
        {country_code: 'at', name: trs.AT},
        {country_code: 'az', name: trs.AZ},
        {country_code: 'bs', name: trs.BS},
        {country_code: 'bh', name: trs.BH},
        {country_code: 'bd', name: trs.BD},
        {country_code: 'bb', name: trs.BB},
        {country_code: 'be', name: trs.BE},
        {country_code: 'bz', name: trs.BZ},
        {country_code: 'bj', name: trs.BJ},
        {country_code: 'bm', name: trs.BM},
        {country_code: 'bt', name: trs.BT},
        {country_code: 'by', name: trs.BY},
        {country_code: 'bo', name: trs.BO},
        {country_code: 'ba', name: trs.BA},
        {country_code: 'bw', name: trs.BW},
        {country_code: 'br', name: trs.BR},
        {country_code: 'bn', name: trs.BN},
        {country_code: 'bg', name: trs.BG},
        {country_code: 'bf', name: trs.BF},
        {country_code: 'bi', name: trs.BI},
        {country_code: 'kh', name: trs.KH},
        {country_code: 'cm', name: trs.CM},
        {country_code: 'ca', name: trs.CA},
        {country_code: 'cv', name: trs.CV},
        {country_code: 'td', name: trs.TD},
        {country_code: 'cl', name: trs.CL},
        {country_code: 'cn', name: trs.CN},
        {country_code: 'cy', name: trs.CY},
        {country_code: 'co', name: trs.CO},
        {country_code: 'km', name: trs.KM},
        {country_code: 'cg', name: trs.CG},
        {country_code: 'kp', name: trs.KP},
        {country_code: 'kr', name: trs.KR},
        {country_code: 'cr', name: trs.CR},
        {country_code: 'ci', name: trs.CI},
        {country_code: 'hr', name: trs.HR},
        {country_code: 'cu', name: trs.CU},
        {country_code: 'dk', name: trs.DK},
        {country_code: 'dm', name: trs.DM},
        {country_code: 'ec', name: trs.EC},
        {country_code: 'eg', name: trs.EG},
        {country_code: 'sv', name: trs.SV},
        {country_code: 'ae', name: trs.AE},
        {country_code: 'er', name: trs.ER},
        {country_code: 'ee', name: trs.EE},
        {country_code: 'et', name: trs.ET},
        {country_code: 'ru', name: trs.RU},
        {country_code: 'fj', name: trs.FJ},
        {country_code: 'ph', name: trs.PH},
        {country_code: 'fi', name: trs.FI},
        {country_code: 'fr', name: trs.FR},
        {country_code: 'ga', name: trs.GA},
        {country_code: 'gm', name: trs.GM},
        {country_code: 'ge', name: trs.GE},
        {country_code: 'gs', name: trs.GS},
        {country_code: 'de', name: trs.DE},
        {country_code: 'gh', name: trs.GH},
        {country_code: 'jm', name: trs.JM},
        {country_code: 'jp', name: trs.JP},
        {country_code: 'gi', name: trs.GI},
        {country_code: 'dj', name: trs.DJ},
        {country_code: 'jo', name: trs.JO},
        {country_code: 'gr', name: trs.GR},
        {country_code: 'gd', name: trs.GD},
        {country_code: 'gl', name: trs.GL},
        {country_code: 'gp', name: trs.GP},
        {country_code: 'gu', name: trs.GU},
        {country_code: 'gt', name: trs.GT},
        {country_code: 'gg', name: trs.GG},
        {country_code: 'gf', name: trs.GF},
        {country_code: 'gn', name: trs.GN},
        {country_code: 'gq', name: trs.GQ},
        {country_code: 'gw', name: trs.GW},
        {country_code: 'gy', name: trs.GY},
        {country_code: 'ht', name: trs.HT},
        {country_code: 'hn', name: trs.HN},
        {country_code: 'in', name: trs.IN},
        {country_code: 'id', name: trs.ID},
        {country_code: 'ir', name: trs.IR},
        {country_code: 'iq', name: trs.IQ},
        {country_code: 'ie', name: trs.IE},
        {country_code: 'is', name: trs.IS},
        {country_code: 'bv', name: trs.BV},
        {country_code: 'nf', name: trs.NF},
        {country_code: 'cx', name: trs.CX},
        {country_code: 'im', name: trs.IM},
        {country_code: 'ax', name: trs.AX},
        {country_code: 'ky', name: trs.KY},
        {country_code: 'cc', name: trs.CC},
        {country_code: 'ck', name: trs.CK},
        {country_code: 'fk', name: trs.FK},
        {country_code: 'fo', name: trs.FO},
        {country_code: 'hm', name: trs.HM},
        {country_code: 'mp', name: trs.MP},
        {country_code: 'mh', name: trs.MH},
        {country_code: 'um', name: trs.UM},
        {country_code: 'sb', name: trs.SB},
        {country_code: 'tc', name: trs.TC},
        {country_code: 'vi', name: trs.VI},
        {country_code: 'vg', name: trs.VG},
        {country_code: 'il', name: trs.IL},
        {country_code: 'it', name: trs.IT},
        {country_code: 'je', name: trs.JE},
        {country_code: 'kz', name: trs.KZ},
        {country_code: 'ke', name: trs.KE},
        {country_code: 'kg', name: trs.KG},
        {country_code: 'ki', name: trs.KI},
        {country_code: 'kw', name: trs.KW},
        {country_code: 'la', name: trs.LA},
        {country_code: 'ls', name: trs.LS},
        {country_code: 'lv', name: trs.LV},
        {country_code: 'lb', name: trs.LB},
        {country_code: 'lr', name: trs.LR},
        {country_code: 'ly', name: trs.LY},
        {country_code: 'li', name: trs.LI},
        {country_code: 'lt', name: trs.LT},
        {country_code: 'lu', name: trs.LU},
        {country_code: 'mg', name: trs.MG},
        {country_code: 'mw', name: trs.MW},
        {country_code: 'mv', name: trs.MV},
        {country_code: 'my', name: trs.MY},
        {country_code: 'ml', name: trs.ML},
        {country_code: 'mt', name: trs.MT},
        {country_code: 'ma', name: trs.MA},
        {country_code: 'mq', name: trs.MQ},
        {country_code: 'mr', name: trs.MR},
        {country_code: 'mu', name: trs.MU},
        {country_code: 'yt', name: trs.YT},
        {country_code: 'mx', name: trs.MX},
        {country_code: 'fm', name: trs.FM},
        {country_code: 'md', name: trs.MD},
        {country_code: 'mc', name: trs.MC},
        {country_code: 'mn', name: trs.MN},
        {country_code: 'me', name: trs.ME},
        {country_code: 'ms', name: trs.MS},
        {country_code: 'mz', name: trs.MZ},
        {country_code: 'mm', name: trs.MM},
        {country_code: 'na', name: trs.NA},
        {country_code: 'nr', name: trs.NR},
        {country_code: 'np', name: trs.NP},
        {country_code: 'ni', name: trs.NI},
        {country_code: 'ne', name: trs.NE},
        {country_code: 'ng', name: trs.NG},
        {country_code: 'nu', name: trs.NU},
        {country_code: 'no', name: trs.NO},
        {country_code: 'nc', name: trs.NC},
        {country_code: 'nz', name: trs.NZ},
        {country_code: 'om', name: trs.OM},
        {country_code: 'nl', name: trs.NL},
        {country_code: 'pk', name: trs.PK},
        {country_code: 'pw', name: trs.PW},
        {country_code: 'ps', name: trs.PS},
        {country_code: 'pa', name: trs.PA},
        {country_code: 'pg', name: trs.PG},
        {country_code: 'py', name: trs.PY},
        {country_code: 'pe', name: trs.PE},
        {country_code: 'pn', name: trs.PN},
        {country_code: 'pf', name: trs.PF},
        {country_code: 'pl', name: trs.PL},
        {country_code: 'pt', name: trs.PT},
        {country_code: 'pr', name: trs.PR},
        {country_code: 'qa', name: trs.QA},
        {country_code: 'hk', name: trs.HK},
        {country_code: 'mo', name: trs.MO},
        {country_code: 'cz', name: trs.CZ},
        {country_code: 'cf', name: trs.CF},
        {country_code: 'cd', name: trs.CD},
        {country_code: 'do', name: trs.DO},
        {country_code: 'mk', name: trs.MK},
        {country_code: 'ro', name: trs.RO},
        {country_code: 'rw', name: trs.RW},
        {country_code: 're', name: trs.RE},
        {country_code: 'eh', name: trs.EH},
        {country_code: 'kn', name: trs.KN},
        {country_code: 'lc', name: trs.LC},
        {country_code: 'pm', name: trs.PM},
        {country_code: 'vc', name: trs.VC},
        {country_code: 'ws', name: trs.WS},
        {country_code: 'as', name: trs.AS},
        {country_code: 'bl', name: trs.BL},
        {country_code: 'sm', name: trs.SM},
        {country_code: 'sh', name: trs.SH},
        {country_code: 'st', name: trs.ST},
        {country_code: 'sn', name: trs.SN},
        {country_code: 'rs', name: trs.RS},
        {country_code: 'cs', name: trs.CS},
        {country_code: 'sc', name: trs.SC},
        {country_code: 'sl', name: trs.SL},
        {country_code: 'sg', name: trs.SG},
        {country_code: 'sy', name: trs.SY},
        {country_code: 'sk', name: trs.SK},
        {country_code: 'si', name: trs.SI},
        {country_code: 'so', name: trs.SO},
        {country_code: 'es', name: trs.ES},
        {country_code: 'lk', name: trs.LK},
        {country_code: 'us', name: trs.US},
        {country_code: 'za', name: trs.ZA},
        {country_code: 'sd', name: trs.SD},
        {country_code: 'sr', name: trs.SR},
        {country_code: 'sj', name: trs.SJ},
        {country_code: 'se', name: trs.SE},
        {country_code: 'ch', name: trs.CH},
        {country_code: 'sz', name: trs.SZ},
        {country_code: 'tj', name: trs.TJ},
        {country_code: 'th', name: trs.TH},
        {country_code: 'tw', name: trs.TW},
        {country_code: 'tz', name: trs.TZ},
        {country_code: 'tf', name: trs.TF},
        {country_code: 'io', name: trs.IO},
        {country_code: 'tl', name: trs.TL},
        {country_code: 'tg', name: trs.TG},
        {country_code: 'tk', name: trs.TK},
        {country_code: 'to', name: trs.TO},
        {country_code: 'tt', name: trs.TT},
        {country_code: 'tn', name: trs.TN},
        {country_code: 'tr', name: trs.TR},
        {country_code: 'tm', name: trs.TM},
        {country_code: 'tv', name: trs.TV},
        {country_code: 'ua', name: trs.UA},
        {country_code: 'ug', name: trs.UG},
        {country_code: 'hu', name: trs.HU},
        {country_code: 'uy', name: trs.UY},
        {country_code: 'uz', name: trs.UZ},
        {country_code: 'vu', name: trs.VU},
        {country_code: 'va', name: trs.VA},
        {country_code: 've', name: trs.VE},
        {country_code: 'vn', name: trs.VN},
        {country_code: 'wf', name: trs.WF},
        {country_code: 'ye', name: trs.YE},
        {country_code: 'zm', name: trs.ZM},
        {country_code: 'zw', name: trs.ZW},
        {country_code: 'zz', name: trs.ZZ}
      ];
      this.values = temp.sort((a, b) => a.name.localeCompare(b.name));
    });
  }

  nameByCode(code: string) {
    if (this.values) {
      return this.values.find(country => {
        if (country.country_code === code) {
          return country.name;
        }
      }).name;
    } else {
      return null;
    }
  }

  dropdownList(searchParams?: SearchParams, useCache: boolean = false): Observable<PageModel> {
    return of(new PageModel(this.values, this.values.length));
  }


}
