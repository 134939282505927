import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
name: 'newLineToBr'
})
export class NewLineToBrPipe implements PipeTransform {
    constructor() {}
    // tslint:disable-next-line: ban-types
    transform(value): Object {
        return value.replace(/(\r\n|\n|\r)/gm, "<br />");
    }
}
