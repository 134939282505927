<app-shared-sidebar-container>
    <app-shared-sidebar-header>
        <div slot="title">
            <h2 class="mb-0">{{ 'MESSAGES_SECTION.MESSAGE' | translate }}</h2>
        </div>
        <div slot="buttons" class="text-right">
            <button class="btn-sicche" [disabled]="!message.msg || !message.subject" (click)="getDestinationUsers()" *ngIf="((readonly || !hasRecipientOtherdata() || forceHideSelectDate) && !canSave) || forceShowSend">{{ 'SEND' | translate }}</button>
            <button class="btn-sicche" [disabled]="!message.msg || !message.subject" (click)="updateEmail()" *ngIf="!readonly && hasRecipientOtherdata() && canSave">{{ 'SAVE' | translate }}</button>
        </div>
    </app-shared-sidebar-header>

    <div class="sidebar-body" *ngIf="!isReady">
        <div class="row">
            <div class="col-12 text-center">
                <app-shared-spinner></app-shared-spinner>
            </div>
        </div>
    </div>
    <div class="sidebar-body" *ngIf="isReady">
        <div class="row" *ngIf="!readonly && !tosReadonly && !hasRecipientOtherdata()">
            <div class="col-12 mb-3">
                <label>
                    <input type="checkbox" [(ngModel)]="scheduledMessage">
                    {{'MESSAGES_SECTION.SCHEDULE_MESSAGE' | translate}}
                </label>
            </div>
            <div class="col-12"  *ngIf="scheduledMessage">
                <div class="row">
                    <div class="col-4">
                        <div class="form-group date-input">
                            <span class="label-big">{{'MESSAGES_SECTION.DATE_SEND' | translate}}</span>
                            <label>
                                <input type="text" readonly [value]="message.date_send | dateToLocaleString" (click)="datepickerSend = !datepickerSend" class="form-control">
                                <ngb-datepicker *ngIf="datepickerSend" #dp (dateSelect)="changeDateSend($event)" class="datepicker-custom"></ngb-datepicker>
                                <span class="placeholder">{{ 'MESSAGES_SECTION.DATE_SEND' | translate }}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <div class="form-group">
                            <span class="label-big">{{'MESSAGES_SECTION.TIME_SEND' | translate}}</span>
                            <label>
                                <ngb-timepicker [meridian]="meridianDate" (ngModelChange)="changeDateSend()" [disabled]="disableTime" [(ngModel)]="timeSend"></ngb-timepicker>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-3 mb-2">
                <h5>{{'RECIPIENTS' | translate}}</h5>
            </div>
            <div *ngIf="!scheduledMessage" class="col-5 mb-3">
                <div class="mb-2">
                    <label> <input type="radio" name="first_radio_group" (change)="updateOtherData()" [(ngModel)]="recipientType" [value]="'ALL'"> {{'MESSAGES_SECTION.ALL' | translate}}</label>
                    <label class="ml-3"><input type="radio" name="first_radio_group" (change)="updateOtherData()" [(ngModel)]="recipientType" [value]="'SINGLE'"> {{'MESSAGES_SECTION.SINGLE' | translate}}</label>
                    <label class="ml-3"><input type="radio" name="first_radio_group" (change)="updateOtherData()" [(ngModel)]="recipientType" [value]="'GROUP'"> {{'MESSAGES_SECTION.GROUP' | translate}}</label>
                </div>
                <div class="col-12" *ngIf="recipientType == 'SINGLE'">
                    <div class="form-group">
                        <app-core-select
                        *ngIf="users"
                        [classes]="'form-control'"
                        [multiple]="false"
                        [cache]="true"
                        [customList]="users"
                        [label]="'nicknameAndEmail'"
                        [searchable]="true"
                        (change)="setUser($event)"
                        >
                        </app-core-select>
                        <span class="placeholder">{{'EMAIL_OR_NICKNAME' | translate}}</span>

                    </div>

                </div>

                <div *ngIf="recipientType == 'GROUP' && country">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group tool tags-select-tool w-100">
                                <app-core-select
                                [customList]="country.sample_variable_tags"
                                [classes]="'form-control'"
                                [multiple]="true"
                                [cache]="true"
                                [value]="'value'"
                                [addTag]="false"
                                [searchable]="true"
                                (change)="getUsersByTags($event)"
                                [(ngmodel)]="message.other_data.recipient_room"
                                >
                                </app-core-select>
                                <span class="placeholder">{{'SAMPLE_VARIABLE_TAGS' | translate}}</span>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <app-core-select
                                    *ngIf="rooms"
                                    [classes]="'form-control'"
                                    [multiple]="false"
                                    [cache]="true"
                                    [customList]="rooms"
                                    [label]="'title'"
                                    [value]="'id'"
                                    [searchable]="true"
                                    (change)="getUsersByRooms($event)"
                                >
                                </app-core-select>
                                <span class="placeholder">{{'ROOMS' | translate}}</span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <!-- <label class="ml-3"> {{'MESSAGES_SECTION.ALL' | translate}} <input type="radio" [(ngModel)]="recipientFilter" [value]="''"></label>
                        <label class="ml-3"> {{'MESSAGES_SECTION.filter_missing_answers_for_country' | translate}} <input type="radio" [(ngModel)]="recipientFilter" [value]="'filter_missing_answers_for_country'"></label> -->
                        <label class="mr-3"><input type="radio" name="second_radio_group" [(ngModel)]="recipientFilter" [value]="'filter_no_answers_given_for_country'"> {{'MESSAGES_SECTION.filter_no_answers_given_for_country' | translate}} </label>
                        <label class="mr-3"><input type="radio" name="second_radio_group" [(ngModel)]="recipientFilter" [value]="'filter_never_logged'"> {{'MESSAGES_SECTION.filter_never_logged' | translate}} </label>
                        <span class="ml-5 clickable" (click)="recipientFilter = null">{{'CANCEL' | translate}}</span>
                        <!-- <label class="ml-3">
                            {{'MESSAGES_SECTION.filter_have_not_logged_since_hours' | translate}}
                            <input type="radio" [(ngModel)]="recipientFilter" [value]="'filter_have_not_logged_since_hours'">
                            <app-core-select
                            [customList]="hoursToDatePipe.getList()"
                            [label]="'text'"
                            [value]="'initial_value'"
                            (change)="changeSelectedHours($event)"
                            >
                            </app-core-select>
                        </label> -->

                    </div>
                </div>
            </div>
            <div *ngIf="scheduledMessage" class="col-12">
                <div class="row">
                    <div class="col-2">
                        <div class="form-group">
                            <app-core-select
                                *ngIf="rooms"
                                [classes]="'form-control'"
                                [multiple]="false"
                                [cache]="true"
                                [customList]="rooms"
                                [label]="'title'"
                                [value]="'id'"
                                [searchable]="true"
                                (change)="getUsersByRooms($event)"
                            >
                            </app-core-select>
                            <span class="placeholder">{{'ROOMS' | translate}}</span>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group tool tags-select-tool w-100">
                            <app-core-select
                                *ngIf="country"
                                [classes]="'form-control'"
                                [multiple]="true"
                                [cache]="true"
                                [customList]="country.sample_variable_tags"
                                [searchable]="true"
                                (change)="getUsersByTags($event)"
                            >
                            </app-core-select>
                            <span class="placeholder">{{'SAMPLE_VARIABLE_TAGS' | translate}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 mt-3 mb-2">
                <h5>{{'MESSAGE' | translate}}</h5>
            </div>
        </div>
        <div *ngIf="!readonly && hasRecipientOtherdata() && !forceHideSelectDate">
            <div class="row">
                <div class="col-6">
                    <div class="form-group date-input">
                        <span class="label-big">{{'MESSAGES_SECTION.DATE_SEND' | translate}}</span>
                        <label>
                            <input type="text" readonly [value]="message.date_send | dateToLocaleString" (click)="datepickerSend = !datepickerSend" class="form-control">
                            <ngb-datepicker *ngIf="datepickerSend" #dp (dateSelect)="changeDateSend($event)" class="datepicker-custom"></ngb-datepicker>
                            <span class="placeholder">{{ 'MESSAGES_SECTION.DATE_SEND' | translate }}</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <span class="label-big">{{'MESSAGES_SECTION.TIME_SEND' | translate}}</span>
                        <label>
                            <ngb-timepicker [meridian]="meridianDate" (ngModelChange)="changeDateSend()" [disabled]="disableTime" [(ngModel)]="timeSend"></ngb-timepicker>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <!-- template messages -->
        <div class="row my-3" *ngIf="!readonly && !tosReadonly ">
            <div class="col-12">
                <label>
                    <input type="checkbox" [(ngModel)]="showLoadFromTemplate">
                    {{'LOAD_FROM_TEMPLATE_MESSAGE' | translate}}
                    <span class="info-tooltip ml-2" placement="top" [ngbTooltip]="'TOOLTIPS.TEMPLATE_MESSAGES' | translate">
                        <i class="far fa-info-circle"></i>
                    </span>
                </label>
            </div>
            <div class="col-7" *ngIf="showLoadFromTemplate">
                <div class="row" >
                    <div class="col-12 ">
                        <div class="form-group">
                            <app-core-select
                            *ngIf="templateMessages"
                            [classes]="'form-control'"
                            [multiple]="false"
                            [cache]="true"
                            [customList]="templateMessages"
                            [label]="'title'"
                            [searchable]="true"
                            (change)="setTemplateMessage($event)"
                            >
                            </app-core-select>
                            <span class="placeholder">{{'TEMPLATE_MESSAGES' | translate}}</span>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-5 text-right" *ngIf="showLoadFromTemplate">
                <a (click)="showLoadFromTemplate=false" class="clickable mr-2">{{'CANCEL' | translate}}</a>
                <button class="btn-sicche btn-secondary" [disabled]="!currentTemplateMessage" (click)="loadFromTemplate()">{{'LOAD_FROM_TEMPLATE_MESSAGE' | translate}}</button>
            </div>
        </div>
        <!-- end messaggi template -->


        <div class="row" *ngIf="readonly || tosReadonly || hasRecipientOtherdata()">
            <div class="col-12 my-3">
                <span *ngIf="readonly">{{'MESSAGES_SECTION.SENT_TO' | translate}}:</span>
                <span *ngIf="(tosReadonly && !readonly) || (message.user_ids && !readonly)">{{'MESSAGES_SECTION.RECIPIENTS' | translate}}:</span>
                <p *ngIf="message.other_data && message.other_data.recipient_user_firstname">{{message.other_data.recipient_user_firstname}}</p>
                <p *ngIf="message.other_data && message.other_data.recipient_room">
                    {{message.other_data.recipient_room.title}}
                </p>
                <p *ngIf="message.other_data && message.other_data.recipient_variable_tags">
                    {{message.other_data.recipient_variable_tags.join(', ')}}
                </p>
                <p *ngIf="message.other_data && message.other_data.recipient_filters" >
                    <span *ngIf="isString(message.other_data.recipient_filters)">
                        {{'MESSAGES_SECTION.' + message.other_data.recipient_filters | translate}}
                    </span>
                    <span *ngIf="!isString(message.other_data.recipient_filters)">
                        {{'MESSAGES_SECTION.' + message.other_data.recipient_filters[0].text | translate}} {{message.other_data.recipient_filters[0].value || ''}}
                    </span>
                </p>
                <p *ngIf="message.other_data && !message.other_data.recipient_filters && !message.other_data.recipient_user_firstname && (!message.other_data.recipient_room || message.other_data.recipient_room.length == 0) && (!message.other_data.recipient_variable_tags || message.other_data.recipient_variable_tags.length == 0)">
                    {{'MESSAGES_SECTION.ALL' | translate}}
                </p>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <input [disabled]="readonly" type="text" class="form-control" [(ngModel)]="message.subject">
                    <span class="placeholder">{{'MESSAGES_SECTION.SUBJECT' | translate}}</span>
                </div>
            </div>
            <div class="col-12">
                <quill-editor [disabled]="readonly" [(ngModel)]="message.msg" [modules]="modules" [styles]="{height: '250px'}" [placeholder]="'INSERT-TEXT' | translate"></quill-editor>
            </div>
        </div>

        <div class="row" *ngIf="readonly">
            <div class="mt-4 card shadow" [ngClass]="message.user_data.length > 1 ? 'col-8' : 'col-12'">
                <div class="position-relative">
                    <div class="table-responsive">
                        <app-core-table
                            *ngIf="message"
                            [items]="message.user_data"
                            [columns]="[{name: 'AVATAR', class:'text-left'}, {name: 'EMAIL', class:'text-left'}, {name: 'FIRSTNAME', class:'text-left'}, {name: 'LASTNAME', class:'text-left'}, {name: 'NICKNAME', class:'text-left'}, {name: 'EMAIL_OPENED', class:'text-left'}]"
                            tableid="users"
                            [perPage]="5"
                            [pagination]="true"
                            [rowTemplate]="rowTemplate">
                        </app-core-table>
                        <ng-template let-item="item" #rowTemplate>
                            <tr class="text-center">
                                <td class="text-left">
                                    <div class="avatar">
                                        <div *ngIf="item.avatar_url" class="avatar-bg-img" [ngStyle]="{'background-image': 'url(' + (item.avatar_url | privatesrc) + ')'}">
                                        </div>
                                    </div>
                                </td>
                                <td class="text-left">
                                    {{item.email}}
                                </td>
                                <td class="text-left">
                                    {{item.firstname}}
                                </td>
                                <td class="text-left">
                                    {{item.lastname}}
                                </td>
                                <td class="text-left">
                                    {{item.nickname}}
                                </td>
                                <td class="text-left">
                                    <i class="fal fa-check text-check font-weight-bold" *ngIf="isEmailReadByUserid(item.userid)"></i>
                                </td>
                            </tr>
                        </ng-template>
                    </div>
                </div>
            </div>
            <div class="mt-4 col-4 " *ngIf="chartReady && message.user_data.length > 1" style="display:flex;justify-content:center;align-items:center;">
                <div class="pl-2 text-center" style="flex-grow: 2;">
                    {{message.user_data.length}} {{'RECIPIENTS' | translate}}
                    <highcharts-chart
                        [Highcharts]="Highcharts"
                        [options]="chartOptions"
                        style="width: 100%; height: 250px; display: block;">
                    </highcharts-chart>
                </div>
            </div>

        </div>

        <!-- template messages 1118 -->
        <div class="row mt-4">
            <div class="col-12">
                <label>
                    <input type="checkbox" [(ngModel)]="showSaveAsTemplate">
                    {{'SAVE_AS_TEMPLATE_MESSAGE' | translate}}
                </label>
            </div>
            <div class="col-9" *ngIf="showSaveAsTemplate" >
                <div class="row" *ngIf="showSaveAsTemplate">
                    <div class="col-12 ">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="{{'TEMPLATE_MESSAGE_TITLE' | translate }}" [(ngModel)]="templateTitle">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-3 text-right" *ngIf="showSaveAsTemplate" >
                <button class="btn-sicche btn-secondary" [disabled]="!templateTitle" (click)="saveAsTemplateMessage()">{{'SAVE_AS_TEMPLATE_MESSAGE' | translate}}</button>
            </div>
        </div>
    </div>
</app-shared-sidebar-container>
