import { NavigationComponent } from './pages/layers/private/researches/navigation/navigation.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Mode } from '../../setup';
import {AuthGuard} from '@app/core/guards/auth/auth.guard';
import {AuthGuardNotPro} from '@app/shared/guards/authGuardNotPro.guard';
import {AuthGuardNoBasic} from '@app/shared/guards/authGuardNotBasic.guard';
import {AlreadyLoggedInGuard} from '@app/core/guards//auth/public.guard';
// Layers
import { PublicComponent } from '@app/pages/layers/public/public.component';
import { PrivateComponent } from '@app/pages/layers/private/private.component';

import { CustomerLoginComponent } from '@app/pages/layers/public/customer-login/customer-login.component';
import { LogoutComponent } from '@app/pages/layers/public/logout/logout.component';

// Custom pages
import { AuthComponent } from '@app/pages/layers/public/auth/auth.component';
import { DashboardComponent } from '@app/pages/layers/private/dashboard/dashboard.component';
import { ResearchesDashboardComponent } from '@app/pages/layers/private/researches/researches.component';
import { SetupComponent } from './pages/layers/private/researches/navigation/setup/setup.component';
import { ModerationComponent } from './pages/layers/private/researches/navigation/moderation/moderation.component';
import { MonitoringComponent } from './pages/layers/private/researches/navigation/monitoring/monitoring.component';
import { MessagesComponent } from './pages/layers/private/researches/navigation/messages/messages.component';
import { AnalysisComponent } from './pages/layers/private/researches/navigation/analysis/analysis.component';
import { SetCountryRedirectComponent } from './pages/layers/public/set-country-redirect/set-country-redirect.component';
import { AnswerExportComponent } from './pages/public/answer-export/answer-export.component';
import { PostExportComponent } from './pages/public/post-export/post-export.component';
import { ExportExternalViewComponent } from './pages/public/export-external-view/export-external-view.component';


/**
 * Custom public routes
 */
export const PUBLIC_ROUTES: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'login', component: AuthComponent },
  { path: 'signup', component: AuthComponent },
  { path: 'set-country-redirect/:research_uniqid/:country_uniqid', component: SetCountryRedirectComponent },
];

/**
 * Custom private routes
 */
export const SECURE_ROUTES: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardNotPro] },
  { path: 'researches', component: ResearchesDashboardComponent },
  { path: 'researches/navigation/:research_uniqid/:country_uniqid', component: NavigationComponent, children: [
    { path: 'setup', component: SetupComponent, canActivateChild: [AuthGuardNotPro, AuthGuardNoBasic] },
    { path: 'moderation', component: ModerationComponent },
    { path: 'monitoring', component: MonitoringComponent, canActivateChild: [AuthGuardNotPro, AuthGuardNoBasic] },
    { path: 'messages', component: MessagesComponent, canActivateChild: [AuthGuardNotPro, AuthGuardNoBasic] },
    { path: 'analysis', component: AnalysisComponent },
  ] }
];


/**
 * Main route ''
 */
const APP_ROUTES: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full', },
  { path: 'customer-login/:research_uniqid/:country_uniqid/:token', component: CustomerLoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'answer-export', component: AnswerExportComponent },
  { path: 'export-external-view/:token', component: ExportExternalViewComponent },
  { path: 'posts-export', component: PostExportComponent },
  { path: '', component: PublicComponent, canActivateChild: [AlreadyLoggedInGuard], children: PUBLIC_ROUTES },
  { path: '', component: PrivateComponent, canActivateChild: [AuthGuard],  children: SECURE_ROUTES }
];

@NgModule({
  // 'preloadingStrategy' lets you preload all the lazy loaded modules after the 'main' ones have finished loading
  imports: [RouterModule.forRoot(APP_ROUTES, {preloadingStrategy: PreloadAllModules, useHash: Mode.USE_HASH, anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})

export class AppRoutingModule {
}


