import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ModalService } from '@app/core/services/modal.service';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { ResearchService } from '@app/shared/services/research.service';
import { PageModel } from '@app/core/models/page';
import { ResearchModel } from '@app/shared/models/research.model';
import { CountriesService } from '@app/core/services/countries.service';
import { Observable } from 'rxjs';
import { UiService } from '@app/shared/services/ui.service';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { AnalysisService } from '@app/shared/services/analysis.service';
import { TeamService } from '@app/shared/services/team.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ResponseModel } from '@app/core/models/response.model';


@Component({
  selector: 'app-private-researches',
  templateUrl: './researches.component.html',
  styleUrls: ['./researches.component.scss']
})
export class ResearchesDashboardComponent extends CoreBaseComponent implements OnInit, OnDestroy {

  public researches: ResearchModel[];
  public archivedResearches: ResearchModel[];
  public observable: Observable<any> = null;
  constructor(
    private modalService: ModalService,
    public researchesService: ResearchService,
    public countriesService: CountriesService,
    private uiService: UiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private analysisService: AnalysisService,
    private toastService: ToastrService,
    private teamService: TeamService,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.uiService.changeLogo('');
    this.operation('ongoing-researches').reset().isLoading();
    this.researchesService.getOngoingResearches({
        page_index: 0, page_size: 10, filter: {include_countries: 1, exclude_statuses: 'REJECTED'}, sort_attribute: 'creationtime', sort_order: 'desc'
      }
      ).subscribe((result: PageModel) => {
      this.researches = result.getData();
      this.operation('ongoing-researches').isSuccess(result.getErrors());
    }, (error) => {
      this.operation('ongoing-researches').isFailed(error.errors);
    });
  }

  export(research: ResearchModel) {
    for (const country of research.countries) {
      this.analysisService.getExportPdfEmail(country.country_uniqid).subscribe((res: ResponseModel) => {
        this.toastService.success(this.translate.instant('PDF_EXPORT_ALL_EMAIL_SENT_NO_EMAIL'), null, {timeOut: 10000});
      });
    }
  }

  ngOnDestroy() {
  }
}
