<div class="container-fluid">
  <div class="row mt-2" *ngIf="data.length && contentTags">
      <div class="col-12" *ngFor="let item of data">
        <span *ngIf="item.isAnswer && item.data.answer_data">
          <app-analysis-answer-wrapper [exportableVersion]="true" [answer]="item.data"></app-analysis-answer-wrapper>
        </span>
        <span *ngIf="!item.isAnswer">
            <app-moderation-answer-comment
                [selectable]="true"
                [id]="'answer-block-comment-exportable' + item.data.id"
                [commentAnswer]="item.data"

                [impersonal]="true"
                >
            </app-moderation-answer-comment>
        </span>
      </div>
  </div>
</div>