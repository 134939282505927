import { Component, OnInit, Input } from '@angular/core';
import { CountryModel } from '@app/shared/models/country.model';
import { TeamModel } from '@app/shared/models/team.model';
import { ResponseModel } from '@app/core/models/response.model';
import { ManageUsersService } from '@app/shared/services/support/manage-users.service';

@Component({
  selector: 'app-check-customer-status',
  templateUrl: './check-customer-status.component.html',
  styleUrls: ['./check-customer-status.component.scss']
})
export class CheckCustomerStatusComponent implements OnInit {

  @Input() country: CountryModel;
  customers: TeamModel[];

  constructor(
    private manageUsersService: ManageUsersService
  ) { }

  ngOnInit(): void {
    this.manageUsersService.getCustomerMonitoring(this.country.country_uniqid).subscribe((res: ResponseModel) => {
      this.customers = res.getData();
    });
  }

}
