<div class="modal-header">
    <i *ngIf="options.showBtnClose" (click)="cancel()" class="fal fa-times" aria-hidden="true"></i>
</div>
<div class="modal-body">
    <h2 class="text-orange">
        <ng-container *ngFor="let word of options.title.split('.'); let i = index">{{ word | translate }}</ng-container>
    </h2>
    <p class="message">
        <ng-container *ngFor="let word of options.message.split('.'); let i = index"><span [innerHTML]="word | translate"></span></ng-container>
    </p>
</div>
<div *ngIf="options.showOkBtn || options.showCancelBtn || options.showCloseBtn" class="modal-footer text-center justify-content-center">
    <a *ngIf="options.showCancelBtn" class="flex-grow-1" (click)="cancel()">{{ options.btnCancelText | translate }}</a>
    <button *ngIf="options.showOkBtn" type="button" class="btn-sicche" [autofocus]="false" (click)="ok()">{{ options.btnOkText | translate }}</button>
    <button *ngIf="options.showCloseBtn" type="button" class="btn btn-info" (click)="cancel()">{{ options.btnCloseText | translate }}</button>
</div>