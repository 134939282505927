import { Role } from './role.model';
import { BaseModel } from '@app/core/models/base';

/**
 * Intervieweds model class
 */
export class InterviewedsModel extends BaseModel {

    parsedResearches: any;
    email: string;
    firstname: string[];
    lastname: string[];
    nickname: string[];
    telephone: string[];
    lasttime: Date;
    nr_researches: number;
    userid: number;
    researches: any;
    researches_title: any;
    // todo proprietà

    static getResourceName() {
        return 'intervieweds';
    }

}
