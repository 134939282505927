import { Component, OnInit, Input } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalOptions } from '../../components/modal/modal-options';

@Component({
  selector: 'app-core-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  options: ModalOptions;
  params: any;
  submitting = false;

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
  }

  ok() {
    this.activeModal.close(true);
  }

  cancel() {
    this.activeModal.close(false);
  }

  close(data: {} | string) {
    this.activeModal.close(data);
  }

}
