import { Component, OnInit, ViewChild, AfterViewInit, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { AnswersModel } from '@app/shared/models/answers.model';
import * as Highcharts from 'highcharts';
import { AnswerModel } from '@app/shared/models/answer.model';
import { ModerationHelperService } from '@app/shared/services/moderation-helper.service';
import { TeamService } from '@app/shared/services/team.service';
import { QuoteModel } from '@app/shared/models/quote.model';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { AnswersService } from '@app/shared/services/answers.service';
import { ResponseModel } from '@app/core/models/response.model';

@Component({
  selector: 'app-moderation-answerstype-closed-multiple',
  templateUrl: './closed-multiple.component.html',
  styleUrls: ['./closed-multiple.component.scss']
})
export class AnswersTypeClosedMultipleComponent extends CoreBaseComponent implements OnInit {

  @Input()
  question: QuestionModel;
  @Input()
  answer: AnswerModel;
  @Input()
  classes: string;
  @Input()
  explicitMediaUrls: boolean;
  @Output() textSelection: EventEmitter<any> = new EventEmitter(null);
  public itemToModify: number = null;
  @Input() set message(value: {action: string, data: any}) {
    this.switchMessage(value);
  }
  public form: UntypedFormArray;
  public quotes: Array<QuoteModel>;

  constructor(private moderationHelperService:  ModerationHelperService, private answerService: AnswersService, private teamService: TeamService, private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.form = new UntypedFormArray([], null);
    if (this.answer.answer_data) {
      this.answer.answer_data.forEach((c: {comment}) =>
        this.form.push(new UntypedFormControl(c.comment, null))
      );
    }
    if(!this.teamService.isCurrentUserCustomerPro() && !this.teamService.isCurrentUserCustomerBasic()) {
      if(this.answer.quotes) {
        this.quotes = this.answer.quotes;
      }
      this.moderationHelperService.quotes.subscribe(quotes => {
        this.quotes = [...(this.answer.quotes || []),...(quotes || [])];
        this.cdr.detectChanges();
      });
    }
  }

  private switchMessage(message: {action: string, data: any}) {
    if (!message) {
      return false;
    }
    switch (message.action) {
      case 'modify-answer':
        this.itemToModify = message.data;
        break;
    }
  }

  answerContains(answerData: any, answerId: number) {
    return answerData.find(data => data.optionid === answerId);
  }

  userAnswerComment(answerData: any, answerId: number) {
    const found = answerData.find(data => data.optionid === answerId);
    if (found) {
      let comment = found.comment;
      if(comment) {
        found.comment = found.comment.replace(/(\r\n|\n|\r)/gm, "<br />");
        if(this.quotes) {
          this.quotes.forEach(quote => {

            const qclass = quote.color ? quote.color : 'grey';
            if(quote.quote_original && quote.quote_original.length) {
              quote.quote_original.forEach(q => {
                comment = comment.replace(q, '<em class="highlight ' + qclass + '">'+ q +'</em>');
              });
            } else {
              comment = comment.replace(quote.quote, '<em class="highlight ' + qclass + '">' + quote.quote +'</em>');
            }

          });
        }
      }
      return comment;
    } else {
      return null;
    }
  }

  selectText() {
    this.textSelection.emit({commentid:null});
  }

  saveMods() {
    this.operation('saving').reset().isSubmitting();
    const data = this.answer;
    data.answer_data.forEach((answerData, index) => {
      answerData.comment = this.form.value[index];
    });
    this.answerService.updateAnswer(this.answer.id, data).subscribe((res: ResponseModel) => {
      this.answer.answer_data = data.answer_data;
      this.itemToModify = null;
      this.operation('saving').isSuccess();
    });
  }

  submit() {
  }

}
