<section>
    <div class="filter px-6 container py-5">
        <p *ngIf="!loadingFilter">{{ 'SMART_CLIENT_SETUP_INFO' | translate }}</p>
        <app-core-spinner [toggle]="loadingFilter"></app-core-spinner>
        <form [formGroup]="filterForm" *ngIf="!loading && !loadingFilter">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group tool tags-select-tool w-100">
                        <app-core-select 
                            [readonly]="!rooms.length"
                            [classes]="'form-control'" 
                            [placeholder]="'ROOM.ROOM'"
                            [cache]="true"
                            [multiple]="true"
                            [form]="filterForm"
                            [formEntityName]="'room'"
                            [searchable]="true"
                            [customList]="rooms"
                            [label]="'title'"
                            (change)="roomChange($event)"
                            >
                        </app-core-select>
                    </div>
                </div>
                <div class="col-md-6 d-flex">
                    <div class="form-group tool tags-select-tool w-100">
                        <app-core-select 
                            [classes]="'form-control'"
                            [placeholder]="'BLOCK.BLOCK'"
                            [cache]="true"
                            [multiple]="true"
                            [customList]="blocks"
                            [label]="'title'"
                            [form]="filterForm"
                            [readonly]="filterForm.value.question || !blocks.length"
                            [formEntityName]="'block'" 
                            [searchable]="true" 
                            (onreset)="resetBlock()"
                            (change)="blockChange($event)"
                            >
                        </app-core-select>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-4">
                    <div>
                        <div class="form-group mb-0">
                            <app-core-select 
                                [classes]="'form-control'" 
                                [placeholder]="'DAY'" 
                                [cache]="true"
                                [form]="filterForm"
                                [formEntityName]="'block_datetime'" 
                                [customList]="days"
                                [resetButton]="true"
                                [empty]="{date: null, name: '---'}"
                                [readonly]="filterForm.value.block ? filterForm.value.block.length ? true : filterForm.value.question ? true : false : filterForm.value.question ? true : false || !days.length"
                                [label]="'name'"
                                (change)="dayChange($event)"
                                >
                            </app-core-select>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div>
                        <div class="form-group mb-0">
                            <app-core-select 
                                [classes]="'form-control'" 
                                [placeholder]="'QUESTION'" 
                                [cache]="true"
                                [resetButton]="true"
                                [form]="filterForm"
                                [formEntityName]="'question'" 
                                [customList]="questions"
                                [readonly]="!questions.length"
                                [searchable]="true"
                                [label]="'title'"
                                >
                            </app-core-select>
                        </div>
                        <div>
                            <span *ngIf="filterForm.value.question" 
                                (click)="filterForm.patchValue({ question: ''})">
                                Reset
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div>
                        <div class="form-group mb-0">
                            <app-core-select 
                                [classes]="'form-control'" 
                                [placeholder]="'SAMPLE_VARIABLE_TAGS'"
                                [cache]="true"
                                [readonly]="!sampleVariableTags.length"  
                                [multiple]="true" 
                                [customList]="sampleVariableTags"
                                [form]="filterForm"
                                [resetButton]="true"
                                [formEntityName]="'sample_variable_tags'" 
                                [searchable]="true"
                                >
                            </app-core-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-12">
                    <p>{{ 'SMART_CLIENT_SETUP_INFO_AREAS' | translate }}</p>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-2">
                    <div class="check-radio-box">
                        <input name="0" formControlName="show_graph" type="checkbox" id="check1">
                        <label for="check1">
                            {{ 'QUOTES_B' | translate }}
                        </label>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="check-radio-box">
                        <input name="1" formControlName="show_audio" type="checkbox" id="check2">
                        <label for="check2">
                            {{ 'AUDIO' | translate }}
                        </label>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="check-radio-box">
                        <input name="2" formControlName="show_video" type="checkbox" id="check3">
                        <label for="check3">
                            {{ 'VIDEO' | translate }}
                        </label>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="check-radio-box">
                        <input name="3" formControlName="show_tagcloud" (change)="changed()" type="checkbox" id="check4">
                        <label for="check4">
                            {{ 'TAG_CLOUD' | translate }}
                        </label>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="check-radio-box">
                        <input name="4" formControlName="show_image" type="checkbox" id="check5">
                        <label for="check5">
                            {{ 'IMAGES' | translate }}
                        </label>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="check-radio-box">
                        <input name="5" formControlName="show_star" type="checkbox" id="check6">
                        <label for="check6">
                            {{ 'STARS_S' | translate }}
                        </label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col text-right">
                    <a class="as-link mr-2" (click)="closeModal()">{{ 'CANCEL' | translate }}</a>
                    <button class="btn-sicche btn-secondary" [disabled]="atLeastOneArea()" (click)="save()">
                        <div *ngIf="saving" class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        {{ 'SAVE' | translate }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</section>