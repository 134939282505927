import { Injectable } from '@angular/core';
import { AnswerModel } from '@app/shared/models/answer.model';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AnswersModalSupportService {

  // tslint:disable: variable-name
  activeQuotes$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  showQuotesModal$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  answerSelected$: BehaviorSubject<AnswerModel> = new BehaviorSubject(null);
  commentSelected$: BehaviorSubject<number> = new BehaviorSubject(null);

  constructor() {
  }

}
