import { Component, OnInit, Input } from '@angular/core';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { AnalysisService } from '@app/shared/services/analysis.service';
import { ResponseModel } from '@app/core/models/response.model';
import { CountryService } from '@app/shared/services/country.service';
import { AnalysisModel } from '@app/shared/models/analysis.model';
import { LightboxModalComponent } from '@app/core/components/lightbox-modal/lightbox-modal.component';
import { ModalService } from '@app/core/services/modal.service';

@Component({
  selector: 'app-overview-modal-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.scss']
})
export class OverviewModalImagesComponent extends CoreBaseComponent implements OnInit {

  @Input()
  type: string;
  @Input()
  countryUniqId = null;
  public filterForm: UntypedFormGroup = null;
  public images: any[] = [];
  public imagesAll: any[] = [];
  public isSmart = false;
  imagesToDownload = [];

  offset = 0;
  limit = 12;
  pageSize = 12;
  selectedPage = 0;

  constructor(private coreLayoutService: CoreLayoutService, private countryService: CountryService,
              private translate: TranslateService, public analysisService: AnalysisService, private modalService: ModalService) {
    super();
  }

  ngOnInit(): void {
    this.isSmart = this.countryService.currentCountryIsClientBasic();
    this.coreLayoutService.title('IMAGES');
    // Filter form
    this.filterForm = new UntypedFormGroup({
      room: new UntypedFormControl('', null),
      block: new UntypedFormControl('', null),
      question: new UntypedFormControl('', null),
      sample_variable_tags: new UntypedFormControl('', null),
      personas: new UntypedFormControl('', null),
      block_datetime: new UntypedFormControl('', null),
      answer_tags: new UntypedFormControl('', null),
      rating: new UntypedFormControl('', null),
      only_highlighted: new UntypedFormControl('', null),
      keyword_search_content: new UntypedFormControl('', null),
      intervieweds: new UntypedFormControl('', null),
      only_contest: new UntypedFormControl('', null),
      only_live: new UntypedFormControl('', null),
    });
    // this.getImagesByFilter();
  }

  selectAll(event: any) {
    this.images.map((image: any) => event.target.checked ? image.state.selected = true : image.state.selected = false);
    this.imagesAll.map((image: any) => event.target.checked ? image.state.selected = true : image.state.selected = false);
  }

  hasSelected() {
    return this.images.find((image: any) => image.state.selected === true);
  }

  downloadSelect() {
    const fileids = [];
    this.imagesAll.forEach((image: any) => {
      if (image.state.selected) {
        fileids.push(image.file_uniqid);
      }
    });
    // rimuovo eventuali duplicati
    const filter = {
      download_zip: true,
      file_uniqids: fileids.join(','),
    };
    this.analysisService.getDownloadImagesBy(this.countryUniqId, filter).subscribe((res: ResponseModel) => {
      res.download('images.zip');
    });
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  getImagesByFilter() {
    let date = '';
    if (this.filterForm.value.block_datetime) {
      date = this.formatDate(this.filterForm.value.block_datetime.date)
    }
    const all_rooms = this.filterForm.value.room ? this.filterForm.value.room.length == 1 && this.filterForm.value.room[0].id == 0 ? true : false : false;  // todo mod
    this.operation('getting-images').reset().isSubmitting();
    const filter = {
      all_rooms,
      only_highlighted: this.filterForm.value.only_highlighted,
      only_contest: this.filterForm.value.only_contest,
      only_live: this.filterForm.value.only_live,
      keyword_search_content: this.filterForm.value.keyword_search_content,
      intervieweds: this.filterForm.value.intervieweds,
      rating: this.filterForm.value.rating ? this.filterForm.value.rating : null,
      questionid: this.filterForm.value.question ? this.filterForm.value.question.id : null,
      block_datetime: date,
      blockids: this.filterForm.value.block ? this.filterForm.value.block.map(item => item.id) : null,
      roomids: (this.filterForm.value.room[0].id !== 0) ? this.filterForm.value.room.map(item => item.id) : [],
      answer_tags: this.filterForm.value.answer_tags ? this.filterForm.value.answer_tags : [],
      personas_tags: this.filterForm.value.personas ? this.filterForm.value.personas : [],
      team_sample_variable_tags: this.filterForm.value.sample_variable_tags ? this.filterForm.value.sample_variable_tags : []
    };
    Object.keys(filter).forEach((key) => (!filter[key]) && delete filter[key]);
    this.analysisService.getImagesBy(this.countryUniqId, filter).subscribe((res: ResponseModel) => {
      this.operation('getting-images').isSuccess();
      this.imagesAll = res.getData(true).filter(item => item.file_uniqid).map(item => new AnalysisModel(item));

      this.paginateByPage();
    });
  }

  paginate(add = 0) {
    this.limit += add;
    if (this.limit > this.imagesAll.length) {
      this.limit = this.imagesAll.length;
    }
    this.images = this.imagesAll.slice(this.offset, this.limit);
  }

  paginateByPage(page = 0) {
    this.offset = this.pageSize * page;
    this.limit = this.pageSize * (page + 1);
    this.selectedPage = page;

    this.paginate();
  }

  getImagesCountAsArr() {
    const arr = [];
    for (let index = 0; index < Math.ceil(this.imagesAll.length / 12); index++) {
      arr.push(index);
    }
    return arr
  }

  lightbox(url) {
    this.modalService.open({modalModel: LightboxModalComponent, dialogSize: 'custom'}, {image: 0, images: [{image_url: url}]});

  }

}
