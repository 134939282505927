import { BaseModel } from '@app/core/models/base';
import { BaseSubTypeModelSchema } from '@app/core/models/base-sub-types-list.interface';
import { CommentModel } from './comment.model';
import { QuestionModel } from './question.model';
import { QuoteModel } from './quote.model';
import { TeamModel } from './team.model';


export class AnswerModel extends BaseModel {

    // tslint:disable: variable-name
    id: number;
    questionid: number;
    blockid: number;
    roomid: number;
    country_uniqid: string;
    question_type: string;
    nickname: string;
    answer_data: any;
    userid: number;
    read: boolean;
    answer_tags: string[];
    stars: number;
    favourite: boolean;
    creationtime: Date;
    device: string;
    comments: CommentModel[];
    nr_comments: number;
    nr_flag_like: number;
    quotes: QuoteModel[];
    team: TeamModel;
    currentuser_like_answer: boolean;
    total_like_to_answer: number;
    user?: any;
    question_data?: any;
    question_title?: string;
    commentsB: CommentModel[];

    static getResourceName() {
        return 'answer-management';
    }

    static getSubTypesList(): BaseSubTypeModelSchema[] {
        return [
            {object: 'team', model: TeamModel},
            {object: 'question_data', model: QuestionModel},
            {object: 'comments', model: CommentModel}
        ];
    }

    isSmartphone() {
        return this.device === 'SMARTHONE';
    }

    isMobile() {
        return this.device === 'MOBILE' || this.device === 'SMARTHONE';
    }

    isDesktop() {
        return this.device === 'DESKTOP';
    }

}
