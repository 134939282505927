import { Component, OnInit } from '@angular/core';
import { CountryService } from '@app/shared/services/country.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { ResearchService } from '@app/shared/services/research.service';

@Component({
  selector: 'app-set-country-redirect',
  templateUrl: './set-country-redirect.component.html',
  styleUrls: ['./set-country-redirect.component.scss']
})
export class SetCountryRedirectComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private countryService: CountryService,
    private researchService: ResearchService
  ) { }

  ngOnInit(): void {
    const countryUniqid = this.activatedRoute.snapshot.paramMap.get('country_uniqid');
    const researchUniqid = this.activatedRoute.snapshot.paramMap.get('research_uniqid');
    this.countryService.customerCountryUniqid = countryUniqid;
    this.researchService.customerResearchUniqid = researchUniqid;

    if (this.authService.isAuthenticated()) {
      this.router.navigate(['dashboard']);
    } else {
      this.router.navigate(['login']);
    }
  }

}
