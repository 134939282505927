/**
 * Base model class
 */
import { UntypedFormGroup } from '@angular/forms';
import { BaseSubTypeModelSchema } from './base-sub-types-list.interface';

export class BaseModel {
    success: BaseModel;

    // State
    state: {
      selected: boolean
      deleted: boolean
    };

    extension?: any = {};

    static getResourceName(): string {
      return '';
    }

    /**
     * Models of the sub objects of this model
     * ex. article<articleModel>.user<userModel>
     * !beta
     */
    static getSubTypesList(): BaseSubTypeModelSchema[] {
      return null;
      /**
       * ex.
       *  return [
       *   {object: 'team', model: TeamModel, children: OtherModel}
       *  ];
       */
    }

    constructor(data?: any) {
      if (data) {
        Object.assign(this, data);
      }
      // Init props
      this.state = {
        selected: false,
        deleted: false
      };
    }

    toggleSelect() {
      this.state.selected = !this.state.selected;
      return this;
    }

    toggleDelete() {
      this.state.deleted = !this.state.deleted;
      return this;
    }

    assignForm(form: UntypedFormGroup, except?: Array<string>) {
      const tmoObject = {};
      Object.assign(tmoObject, form.getRawValue());
      if (except) {
        for (const key of except) {
          delete tmoObject[key];
        }
      }
      Object.assign(this, tmoObject);
      return this;
    }


    assignProperty(key: string, value: any) {
      this[key] = value;
      return this;
    }

    getId(): any {
      const idKey = 'id';
      return this[idKey] ? this[idKey] : null;
    }

    getLabel(): string {
      return '';
    }

    getAttributeValue(key: string, defaultValue: string = ''): any {
      const index = Object.keys(this).indexOf(key);
      if (index > -1) {
        return Object.values(this)[index];
      }
      return defaultValue;
    }

    toJSONString()  {
      return JSON.stringify(this);
    }

    assignIstancesByKey(property: any, model: any) {
      if (property) {
        for (const key of Object.keys(property)) {
          if (property[key]) {
            property[key] = new model(property[key]);
          }
        }
      }
    }

    parseCompatibilityDate(value: string | Date) {
      if (typeof value === 'string') {
        if (value.indexOf('-') !== -1) {
          value = this.replaceAll(value, '-', '/');
        }
        // @ts-ignore
        if (value.indexOf('+') !== -1) {
          value = value.toString();
          value = value.substring(0, value.indexOf('+'));
        }
      }
      return value;
    }

    replaceAll(str, search, replace) {
      return str.replace(new RegExp(search, 'g'), replace);
    }

}
