import {Injectable} from '@angular/core';
import {CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import {Observable} from 'rxjs';



@Injectable({
  providedIn: 'root'
})

export class AuthGuardNoBasic implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
    ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const user = this.authService.user;
        if (user.otherdata.first_role === 'CUSTOMER_BASIC') {
            this.router.navigate(['logout']);
            return false;
        }
        return true;
  }

}
