import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { QuestionModel } from '@app/shared/models/question.model';

@Component({
  selector: 'app-setup-questions-shared-media-upload',
  templateUrl: './shared-media-upload.component.html',
  styleUrls: ['./shared-media-upload.component.scss']
})
export class QuestionsSharedMediaUploadComponent extends CoreBaseComponent implements OnInit, OnDestroy {

  @Output() addPdfOut      = new EventEmitter<FileList>();
  @Output() removePdfOut   = new EventEmitter<number>();
  @Output() addImageOut    = new EventEmitter<FileList>();
  @Output() removeImageOut = new EventEmitter<number>();

  @Input() question: QuestionModel;
  @Input() images: any[] = [];
  @Input() pdfFiles: any[] = [];

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  addPDF(files: FileList) {
    this.addPdfOut.next(files);
  }

  removePdf(id: number) {
    this.removePdfOut.next(id);
  }

  addImage(files: FileList) {
    this.addImageOut.next(files);
  }

  removeImage(id: number) {
    this.removeImageOut.next(id);
  }

}
