import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundPipe } from './pipes/round.pipe';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser'

//  INFO: need to be imported in every child module
import { HttpClient } from '@angular/common/http';
// info: need to be imported in every child module
import { TranslateModule } from '@ngx-translate/core';
import { TranslateLoader } from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}


// Shared components
import { LogoComponent } from './components/logo/logo.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarHeaderComponent } from './components/sidebar/sidebar-header/sidebar-header.component';
import { SidebarContainerComponent } from './components/sidebar/sidebar-container/sidebar-container.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { DatepickerRangeComponent } from './components/datepicker-range/datepicker-range.component';
import { HoursToDaysPipe } from '../shared/pipes/hoursToDays.pipe';
import { SendMessageSharedComponent } from './components/send-message/send-message.component';
import { QuillModule } from 'ngx-quill';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '@app/core/core.module';
import { DateAgoPipe } from './pipes/date-ago.pipe';

@NgModule({
    declarations: [
        RoundPipe,
        LogoComponent,
        FooterComponent,
        SpinnerComponent,
        SidebarContainerComponent,
        SidebarHeaderComponent,
        DatepickerRangeComponent,
        HoursToDaysPipe,
        SendMessageSharedComponent,
        DateAgoPipe
    ],
    imports: [
        CommonModule,
        RouterModule,
        BrowserModule,
        FormsModule,
        QuillModule.forRoot(),
        CKEditorModule,
        TranslateModule.forRoot({
            defaultLanguage: 'it',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgbModule,
        CoreModule
    ],
    providers: [
        HoursToDaysPipe
    ],
    exports: [
        RoundPipe,
        LogoComponent,
        SpinnerComponent,
        FooterComponent,
        TranslateModule,
        SidebarContainerComponent,
        SidebarHeaderComponent,
        CKEditorModule,
        DatepickerRangeComponent,
        HoursToDaysPipe,
        SendMessageSharedComponent,
        DateAgoPipe
    ]
})
export class SharedModule { }
