import { Component, OnInit, OnDestroy, ViewChild, Input, AfterViewInit, ElementRef } from '@angular/core';
import { ModalService } from '@app/core/services/modal.service';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { ResearchModel } from '@app/shared/models/research.model';
import { NewResearchModalComponent } from '../../../modals/new-research/new-research.component';
import { CountriesService } from '@app/core/services/countries.service';


@Component({
  selector: 'app-private-researches-slider',
  templateUrl: './researches-slider.component.html',
  styleUrls: ['./researches-slider.component.scss']
})
export class ResearchesSliderComponent extends CoreBaseComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('ds') ds: ElementRef;
  @Input()
  researches: ResearchModel[] = null;
  @Input()
  link = true;

  constructor(private modalService: ModalService, public countriesService: CountriesService) {
    super();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
  }

  moveLeft() {
    // this.ds.nativeElement.flickity('next');
  }

  moveRight() {
    //this.ds.moveRight();
  }

  newResearchModalSlider(e) {
    e.stopPropagation();
    this.modalService.open({modalModel: NewResearchModalComponent, dialogSize: 'custom'}, null);
  }
}
