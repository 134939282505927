import { BaseModel } from '../base';
import { ToolbarItem } from './toolbar-item.interface';
/**
 * Page Model
 */

export class ToolbarItemModel implements ToolbarItem {

  slug?: string;
  label: string;
  component: any;
  hidden?: true | false;
  status: 'on' | 'off';
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';

  constructor(data: ToolbarItem) {
    Object.assign(this, data);
  }

  disable() {
    this.status = 'off';
  }

  enable() {
    this.status = 'on';
  }

  hide() {
    this.hidden = true;
  }

  show() {
    this.hidden = false;
  }

  toggle() {
    this.status = this.status === 'on' ? 'off' : 'on';
  }

}
