import { BaseModel } from '@app/core/models/base';


export class FaqModel extends BaseModel {

    // tslint:disable: variable-name
    position: number;
    name: string;
    question: string;
    answer: any;
    vimeo_embed: any;
    language: string;
    category: string;
    title: string;
    id: string;


    static getResourceName() {
        return 'faq';
    }

}
