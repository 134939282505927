import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';
import { StorageService } from './storage.service';
import { ApiService } from './api.service';
import { Mode } from '../../../../setup';
import { BaseModel } from '../models/base';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ResponseModel, USERDATA_SLUG } from '../models/response.model';
import { AuthModel } from '../models/auth.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {

  /**
   * Current logged user data
   * @Subscribe to listen for logged user changes
   */
  public authorizedUser$: BehaviorSubject<any>;

  constructor(
    private Storage: StorageService,
    router: Router,
    public http: HttpClient,
    apiService: ApiService,
    ) {
      super(router, apiService, AuthModel);
      // Set initial user
      this.authorizedUser$ = new BehaviorSubject(this.user);

  }

  get userVersion() {
    return this.Storage.get('userversion');
  }

  get user() {
    return this.Storage.get(USERDATA_SLUG);
  }

  get authorizedUser() {
    return this.authorizedUser$.value;
  }

  get isLogged(): boolean {
    return this.authorizedUser$.value ? true : false;
  }

  /**
   * Login
   * @ param data
   * @ param responseModel (model to be instantiated: ex. UserModel?)
   * @ otherData: if you want to store additional data that is removed on logout, use otherData in the format {key: value}
   *   they will be memorized as authextradata_YOURKEY - extra data will always be saved in the localStorage
   */
  login(data: {username: string, password: string | Int32Array}, responseModel?: any, otherData?: any): Observable<ResponseModel> {
    return this.apiService.post(`/login`, JSON.stringify(data), environment.serviceAuth)
    .pipe(
      map((response: ResponseModel) => {
        if (!response.hasErrors()) {
          this.setAuthData(response.data, otherData);
          const output = (responseModel ? new responseModel(response.data[USERDATA_SLUG]) : response);
          // Save into variable
          this.onAuthorizedGet(output);
          return output;
        } else {
          return response;
        }
      }),
      catchError(this.errorHandl)
    );
  }

  /**
   * Set authentication data
   * responseData: it contains user data provided by the login API {PHPSESSID, userdata...}
   * @ otherData: if you want to store additional data that is removed on logout, use otherData in the format {key: value}
   *   they will be memorized as authextradata_YOURKEY - extra data will always be saved in the localStorage
   * @param responseData
   */
  public setAuthData(responseData: any, otherData?: any): boolean {
    if (!responseData) {
      return false;
    }
    // Mem data and auth variables
    if (Mode.AUTH_TYPE.toLowerCase() === 'bearer') {
      this.Storage.set('bearer', responseData.token);
    } else if (Mode.AUTH_TYPE.toLowerCase() === 'phpsessid') {
      this.Storage.set('phpsessid', responseData.PHPSESSID);
      // document.cookie = 'PHPSESSID=' + res.PHPSESSID;
    }
    // Mem user dta
    this.Storage.set(USERDATA_SLUG, responseData[USERDATA_SLUG]);
    // Mem version
    if (responseData[USERDATA_SLUG].version) {
      this.Storage.set('userversion', responseData[USERDATA_SLUG].version);
    }
    // Save other data
    if (otherData) {
      Object.keys(otherData).forEach((key: string) => {
        this.Storage.set('authextradata_' + key, otherData[key], true);
      });
    }
    return true;
  }

  /**
   * Change user, only work if logged as admin
   * @ param username: username/email of the user to authorize and get
   * @ param responseModel (model to be instantiated: ex. UserModel?)
   */
  su(username: string, responseModel?: any, otherData?: any) {
    return this.apiService.post('/su', { username }, environment.serviceAuth)
    .pipe(
      map((response: ResponseModel) => {
        this.clearExtraData();
        if (!response.hasErrors()) {
          const responseData = response.data;
          this.Storage.set(USERDATA_SLUG, responseData[USERDATA_SLUG]);
          if (responseData[USERDATA_SLUG].version) {
            this.Storage.set('userversion', responseData[USERDATA_SLUG].version);
          }
          const output = (responseModel ? new responseModel(responseData[USERDATA_SLUG]) : response);
          // Save other data
          if (otherData) {
            Object.keys(otherData).forEach((key: string) => {
              this.Storage.set('authextradata_' + key, otherData[key], true);
            });
          }
          // Save into variable
          this.onAuthorizedGet(output);
          return output;
        } else {
          return response;
        }
      }),
      catchError(this.errorHandl)
    );
  }

  /**
   * Logout
   * @ param redirectTo: url where to be redirected
   * @ param frontOnly: if it is enough to clean up the storage without calling the logout API
   */
  logout(redirectTo?: string, frontOnly = false): Observable<any> {
    if (frontOnly) {
      this.clearExtraData();
      this.onAuthorizedGet(null);
      this.clearStorage();
      if (redirectTo) {
        this.router.navigate([redirectTo]);
      }
      return null;
    }
    return this.apiService.post(`/logout`, null, environment.serviceAuth)
    .pipe(
      map((response: ResponseModel) => {
        const responseData = response.getData();
        if (responseData.status === 'loggedout') {
          // this.clearStorage();
        }
        if (redirectTo) {
          this.router.navigate([redirectTo]);
        }
        this.clearExtraData();
        this.onAuthorizedGet(null);
        return response;
      }),
      catchError(this.errorHandl)
    );
  }

  public getExtraData(key?: string) {
    return this.Storage.get('authextradata_' + key, true);
  }

  /**
   * Clear extra data
   */
  private clearExtraData() {
    // Remove all extra data
    const extraItems = this.Storage.getAll(true);
    Object.keys(extraItems).forEach((key: string) => {
      if (key.startsWith('authextradata_')) {
        this.Storage.forget(key, true);
      }
    });
  }

  /**
   * Get current logged user
   * param dataModel (model to be instantiated: ex. UserModel?)
   */
  current(dataModel?: any): Observable<any> {
    return this.apiService.get(`/current-user`, null, environment.serviceUrl)
    .pipe(
      map((response: ResponseModel) => {
        if (dataModel) {
          response.data = new dataModel(response.getData());
        }
        // Save into variable
        this.onAuthorizedGet(response.data);
        return response;
      }),
      catchError(this.errorHandl)
    );
  }

  updateCurrent(data, dataModel?: any): Observable<any> {
    return this.apiService.post(`/current-user`, data, environment.serviceUrl)
    .pipe(
      map((response: ResponseModel) => {
        if (dataModel) {
          response.data = new dataModel(response.getData());
        }
        this.onAuthorizedGet(response.data);
        return response;
      }),
      catchError(this.errorHandl)
    );
  }

  private onAuthorizedGet(authorized: any) {
    // console.log(authorized);
    this.authorizedUser$.next(authorized);
  }

  /**
   * Remove session data from storage
   * (ex. after log out)
   */
  private clearStorage() {
    this.Storage.clearSession();
  }

  /**
   * Sign up a new user
   * @ param data
   */
  signUp(data: any | UntypedFormGroup) {
    if (data instanceof UntypedFormGroup) {
      data = data.getRawValue(); // FormGroup to key => value json
    }
    return this.apiService.post(`/users`, JSON.stringify(data), environment.serviceUrl)
    .pipe(
      map((response: ResponseModel) => {
        if (!response.hasErrors()) {
          // todo?
          return response;
        } else {
          return response;
        }
      }),
      catchError(this.errorHandl)
    );
  }

  /**
   * Ask password reset
   */
  askPasswordReset(data, dataModel?: any, language_code: string = null) {
    data.module = 'sinsicche';
    return this.apiService.post(`/request-passwordreset`, data, environment.serviceAuth)
    .pipe(
      map((response: ResponseModel) => {
        if (dataModel) {
          response.data = new dataModel(response.getData());
        }
        return response;
      }),
      catchError(this.errorHandl)
    );
  }

  /**
   * Reset password
   */
  resetPassword(data, dataModel?: any) {
    return this.apiService.post(`/set-newpassword`, data, environment.serviceAuth)
    .pipe(
      map((response: ResponseModel) => {
        if (dataModel) {
          response.data = new dataModel(response.getData());
        }
        return response;
      }),
      catchError(this.errorHandl)
    );
  }

  /**
   * Check if the user is currently authenticated
   * This information is given by the presence of a token in the storage
   */
  isAuthenticated(): boolean {
    const token = this.Storage.get(Mode.AUTH_TYPE.toLowerCase());
    if (token) {
      return true;
    } else {
      return false;
    }
  }

  /*
  public lastUpdateCheckTime():any {
    return this.Storage.get('lastupdatecheck');
  }
  public setLastUpdateCheckTime(ISOdate:string) {
    this.Storage.forget('lastupdatecheck');
    return this.Storage.set('lastupdatecheck', ISOdate);
  }
  */

  backendLogs = [];

  logToBackend(error) {
    if (!this.backendLogs.find(log => log.message === error.message)) {
      const now = new Date();
      localStorage.setItem('last_log_time', now.getTime().toString())
      const json = JSON.stringify(error);
      return this.apiService.post(`/frontend-log`, {
        name: environment.projectName,
        error_text: json
      }, environment.serviceAuth)
    }
  }

}
