import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ResponseModel } from '@app/core/models/response.model';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { QuestionsService } from '@app/shared/services/questions.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { BlockModel } from '@app/shared/models/block.model';
import { share } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { AbstractQuestion } from '../abstract-question.component';

export interface ImageSet {
  id: number;
  name: string;
  title: string;
  subtitle: string;
  instructions_pdf_url: string;
  dimensions: (1|2|3)[];
  images: {
      image_url: string,
      image_del?: boolean,
      image_uniqid?: string
  }[];
}

@Component({
  selector: 'app-setup-questions-image-association.',
  templateUrl: './image-association.component.html',
  styleUrls: ['./image-association.component.scss']
})
export class ImageAssociationQuestionComponent extends AbstractQuestion implements OnInit {

  @Input() countryUniqId: string = null;
  @Input() block: BlockModel = null;
  @Input() question: QuestionModel = null;
  @Input() disabled = false;
  @Output() saveEmit: EventEmitter<string> = new EventEmitter(null);

  public form: UntypedFormGroup;
  public imageSets: ImageSet[] = [];
  public availImageSetsForDim: ImageSet[];
  public imageSetDisabled = false;

  get images(): UntypedFormArray {
    return this.form.get('images') as UntypedFormArray;
  }

  get dimensions(): UntypedFormControl {
    return (this.form.controls.question_data as UntypedFormGroup).controls.dimensions as UntypedFormControl;
  }

  constructor(private formBuilder: UntypedFormBuilder, private layoutService: CoreLayoutService, protected translate: TranslateService,
              private questionsService: QuestionsService, protected toastService: ToastrService) {
    super(toastService, translate);
  }

  ngOnInit(): void {
    const $imageSets = this.questionsService.getBaseImageSets().pipe(share());
    $imageSets.subscribe((res: ResponseModel) => {
      this.imageSets = res.getData()
        .map((el: ImageSet) => {
          el.title = this.translate.instant('IMAGE_ASSOCIATION.' + el.title);
          return el;
        });
      this.availImageSetsForDim = this.imageSets; // vedi commento 114
    });

    this.form = this.formBuilder.group({
      question_data: this.formBuilder.group({
        randomize_order: new UntypedFormControl(this.question ? this.question.question_data.randomize_order : true, null),
        dimensions: new UntypedFormControl(this.question ? this.question.question_data.dimensions : '', [Validators.required]),
        mandatory_comment: new UntypedFormControl(this.question ? this.question.question_data.mandatory_comment : '', null)
      }),
      image_set_selection: new UntypedFormControl(
        this.question
          ? this.question.question_data.image_set
          : null,
        null
      ),
      images: this.formBuilder.array(
        this.question
          ? this.question.question_data.images.map(item =>
            new UntypedFormGroup({
              image_url: new UntypedFormControl(item.image_url, null),
              image_del: new UntypedFormControl(false, null),
              image_uniqid: new UntypedFormControl(item.image_uniqid, null)
            })
          )
          : []
      ),
      is_masked: new UntypedFormControl(this.question ? this.question.is_masked : this.block.is_room_group ? true : false, null),
      title: new UntypedFormControl(this.question ? this.question.title : '', null),
      has_minimum_multiple_answers_nr: new UntypedFormControl((this.question && this.question.minimum_multiple_answers_nr) ? true : null),
      minimum_multiple_answers_nr: new UntypedFormControl(this.question ? this.question.minimum_multiple_answers_nr : null),
      type: new UntypedFormControl('image-association', null),
      blockid: new UntypedFormControl(this.block.id, Validators.required),
      personas_tags: new UntypedFormControl(this.question ? this.question.personas_tags : '', null),
      sample_variable_tags: new UntypedFormControl(this.question ? this.question.sample_variable_tags : '', null),
    });
    if (this.disabled) {
      this.form.disable();
    }
    this.layoutService.buttons([
      {label: 'SAVE_AND_EXIT', slug: 'saveexit', classes: 'mr-3 btn-sicche btn-secondary', icon: 'fal fa-share-square', click: this.submitAndExit },
      {label: 'SAVE_AND_CREATE_NEW', slug: 'save', classes: 'mr-5 btn-sicche btn-primary', icon: 'fal fa-check', click: this.submitAndStay },
    ]);

    this.initAttachments();
    // gli image set erano pensati per dipendere dalla dimensione selezionata, ma non si vuole che l'input delle
    // dimensioni comandi sulle immagini caricate. Di conseguenza questa funzionalità è sospesa
    // if (!this.dimensions.value) {
    //   this.imageSetDisabled = true;
    // } else {
    //   $imageSets.subscribe(() => this.enableImageSet());
    // }
    // this.dimensions.valueChanges.subscribe(value => this.onDimensionsSelection(value));
  }

  submitAndExit = () => {
    this.layoutService.getButton('saveexit').loading = true;
    this.submit(() => {
      this.saveEmit.emit('save-and-leave');
    }, () => {
      this.layoutService.getButton('saveexit').loading = false;
    });
  }

  submitAndStay = () => {
    this.layoutService.getButton('save').loading = true;
    this.submit(() => {
      this.saveEmit.emit('save-and-stay');
    }, () => {
      this.layoutService.getButton('save').loading = false;
    });
  }

  // onDimensionsSelection(value: string|number /*1|2|3*/) {
  //   if (value) {
  //     this.enableImageSet(parseInt(`${value}`, 10));
  //   } else {
  //     this.imageSetDisabled = true;
  //   }
  // }

  enableImageSet(dimension?: number /*1|2|3*/) {
    if (!dimension) {
      dimension = parseInt((this.form.controls.question_data as UntypedFormGroup).controls.dimensions.value, 10);
    }
    this.availImageSetsForDim = [...this.imageSets].filter(s => s.dimensions.indexOf(dimension as 1|2|3) !== -1);
    this.imageSetDisabled = false;
    if (this.form.controls.image_set_selection.value) {
      if (this.availImageSetsForDim.findIndex(s => s.id === this.form.controls.image_set_selection.value.id) === -1) {
        this.form.controls.image_set_selection.setValue('');
        this.onImageSetSelectionChange(null);
      }
    }
    this.form.controls.image_set_selection.enable();
    this.images.enable();
  }

  onImageSetSelectionChange(event: ImageSet) {
    while (this.images.length !== 0) {
      this.images.removeAt(0);
    }
    if (event && event.images) {
      event.images.forEach(img => this.images.push(
        new UntypedFormGroup({
          image_url: new UntypedFormControl(img.image_url, null),
          new: new UntypedFormControl(false, null),
        })
      ));
    }
  }

  onUploadError(e: any) {
    this.toastService.error(this.translate.instant(e.error, e.params), '');
  }

  submit(callback: any, fallback: any) {
    if (this.disabled) {
      fallback();
      this.toastService.error(this.translate.instant('ERRORS.CAN_NOT_MODIFY_PAST'));
      return;
    }
    const formValue = this.form.getRawValue();
    if (this.form.get('question_data').invalid || !formValue.images.length) {
      fallback();
      this.toastService.error(this.translate.instant('ERRORS.GENERIC_INPUTS_REQUIRED'));
    } else {
      this.operation('creating').reset().isSubmitting();
      const data = formValue;
      if (data.sample_variable_tags && data.sample_variable_tags.length > 0) {
        data.sample_variable_tags_by_comma = data.sample_variable_tags.join(',');
        data.sample_variable_tags = null;
      }
      if (data.personas_tags && data.personas_tags.length > 0) {
        data.personas_tags_by_comma = data.personas_tags.join(',');
        data.personas_tags = null;
      }

      data.question_data.images = data.images;
      data.question_data.images.forEach((img: any, i: number) => img.answer_id = i);

      data.question_data.attached_pdf_files = this.attachedPdfFiles;
      data.question_data.attached_images = this.attachedImages;

      delete data.images;
      (!this.question ? this.questionsService.createQuestion(data)
        : this.questionsService.updateQuestion(this.question.id, data)).subscribe((res: ResponseModel) => {
        this.operation('creating').isSuccess(res.getErrors());
        this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
        callback();
      }, (e) => {
        this.operation('creating').isFailed(e.errors);
        this.toastService.error(this.translate.instant('ERRORS.GENERIC'));
        fallback();
      });
    }
  }
}
