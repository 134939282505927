<div [flickity]="{
    freeScroll: true,
    pageDots: false,
    percentPosition: false,
    cellAlign: 'left'
}" class="w-100 text-right" #ds>
    <div [flickityChild] class="drag-card" *ngFor="let research of researches">
        <a [routerLink]="research.isActive() ? research.setuplink : null" *ngIf="research.getId()">
            <div class="res-overview-box research" [ngClass]="{'disabled': !research.isActive()}">
                <div class="overlay d-flex align-items-center justify-content-center" *ngIf="research.isActive()">
                    <div class="w-50 mx-auto text-center">
                        <img class="mb-3" src="assets/icons/sicche_icon_quotes-rollover.svg" />
                        <h3 class="text-white">{{ 'GOTO_RESEARCH' | translate }}</h3>
                    </div>
                </div>
                <div class="overlay d-flex align-items-center justify-content-center" *ngIf="!research.isActive()">
                    <div class="w-50 mx-auto text-center">
                        <svg width="35px" class="mb-3 primary-color" height="24px" viewBox="0 0 35 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g id="molecule-/-vigolette-piene-disactive" stroke="none" stroke-width="1" fill="#FFFFFF" fill-rule="evenodd">
                                <path d="M13.0248638,20.3602493 C12.372821,21.4148575 11.596335,22.0218034 11.2585408,22.262785 C10.9320145,22.53519 10.0923235,23.0057667 9.67500859,23.2377425 C9.27756038,23.5018183 8.81259728,23.6740403 8.3581473,23.8835504 C7.88398675,24.0367877 7.40536364,24.2149583 6.90869562,24.3009537 C6.41680981,24.423542 5.91543052,24.461957 5.42325814,24.5178917 C3.44511774,24.6508643 1.58893861,24.326711 0.233793501,24.0176159 C-1.12206383,23.707793 -2,23.4395475 -2,23.4395475 C-2,23.4395475 -1.07752826,23.4723782 0.290679256,23.4239391 C0.974740445,23.4063121 1.76811949,23.3352368 2.60043644,23.2294821 C3.43074443,23.1017662 4.29797189,22.9188746 5.11274054,22.6440314 C5.51165855,22.4854229 5.91401945,22.3487664 6.27234529,22.1479378 C6.64694355,21.9806975 6.96822415,21.7434855 7.28708903,21.5473081 C7.57402196,21.3000645 7.94481382,21.0766527 8.16515025,20.8173895 L1.32781949,16.377175 C-0.03548895,15.4447939 -0.577808059,13.5593321 0.326963769,12.1141366 L5.33481895,4.35497893 C6.23959078,2.90978346 8.30796351,2.59772899 9.67127668,3.52937758 C10.5801521,4.15047664 13.0453122,5.75137031 17.0667569,8.33205859 C18.4307823,9.26443503 18.9723844,11.1499015 18.0676126,12.595097 L13.0248638,20.3602493 Z" id="Path" fill="#828282" fill-rule="nonzero" transform="translate(8.256714, 13.774274) scale(-1, -1) rotate(327.000000) translate(-8.256714, -13.774274) "></path>
                                <path d="M32.0248638,20.3602493 C31.372821,21.4148575 30.596335,22.0218034 30.2585408,22.262785 C29.9320145,22.53519 29.0923235,23.0057667 28.6750086,23.2377425 C28.2775604,23.5018183 27.8125973,23.6740403 27.3581473,23.8835504 C26.8839867,24.0367877 26.4053636,24.2149583 25.9086956,24.3009537 C25.4168098,24.423542 24.9154305,24.461957 24.4232581,24.5178917 C22.4451177,24.6508643 20.5889386,24.326711 19.2337935,24.0176159 C17.8779362,23.707793 17,23.4395475 17,23.4395475 C17,23.4395475 17.9224717,23.4723782 19.2906793,23.4239391 C19.9747404,23.4063121 20.7681195,23.3352368 21.6004364,23.2294821 C22.4307444,23.1017662 23.2979719,22.9188746 24.1127405,22.6440314 C24.5116585,22.4854229 24.9140194,22.3487664 25.2723453,22.1479378 C25.6469435,21.9806975 25.9682242,21.7434855 26.287089,21.5473081 C26.574022,21.3000645 26.9448138,21.0766527 27.1651502,20.8173895 L20.3278195,16.377175 C18.9645111,15.4447939 18.4221919,13.5593321 19.3269638,12.1141366 L24.334819,4.35497893 C25.2395908,2.90978346 27.3079635,2.59772899 28.6712767,3.52937758 C29.5801521,4.15047664 32.0453122,5.75137031 36.0667569,8.33205859 C37.4307823,9.26443503 37.9723844,11.1499015 37.0676126,12.595097 L32.0248638,20.3602493 Z" id="Path" fill="#828282" fill-rule="nonzero" transform="translate(27.256714, 13.774274) scale(-1, -1) rotate(327.000000) translate(-27.256714, -13.774274) "></path>
                            </g>
                        </svg>
                        <h3 class="text-white">{{ 'RESEARCH_NOT_ACTIVE' | translate }}</h3>
                    </div>
                </div>
                <svg *ngIf="!research.isActive()" class="mb-3 primary-color" width="35px" height="24px" viewBox="0 0 35 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="molecule-/-virgolette-piene-active" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <path d="M13.0248638,20.3602493 C12.372821,21.4148575 11.596335,22.0218034 11.2585408,22.262785 C10.9320145,22.53519 10.0923235,23.0057667 9.67500859,23.2377425 C9.27756038,23.5018183 8.81259728,23.6740403 8.3581473,23.8835504 C7.88398675,24.0367877 7.40536364,24.2149583 6.90869562,24.3009537 C6.41680981,24.423542 5.91543052,24.461957 5.42325814,24.5178917 C3.44511774,24.6508643 1.58893861,24.326711 0.233793501,24.0176159 C-1.12206383,23.707793 -2,23.4395475 -2,23.4395475 C-2,23.4395475 -1.07752826,23.4723782 0.290679256,23.4239391 C0.974740445,23.4063121 1.76811949,23.3352368 2.60043644,23.2294821 C3.43074443,23.1017662 4.29797189,22.9188746 5.11274054,22.6440314 C5.51165855,22.4854229 5.91401945,22.3487664 6.27234529,22.1479378 C6.64694355,21.9806975 6.96822415,21.7434855 7.28708903,21.5473081 C7.57402196,21.3000645 7.94481382,21.0766527 8.16515025,20.8173895 L1.32781949,16.377175 C-0.03548895,15.4447939 -0.577808059,13.5593321 0.326963769,12.1141366 L5.33481895,4.35497893 C6.23959078,2.90978346 8.30796351,2.59772899 9.67127668,3.52937758 C10.5801521,4.15047664 13.0453122,5.75137031 17.0667569,8.33205859 C18.4307823,9.26443503 18.9723844,11.1499015 18.0676126,12.595097 L13.0248638,20.3602493 Z" id="Path" fill="#FF5100" fill-rule="nonzero" transform="translate(8.256714, 13.774274) scale(-1, -1) rotate(327.000000) translate(-8.256714, -13.774274) "></path>
                        <path d="M32.0248638,20.3602493 C31.372821,21.4148575 30.596335,22.0218034 30.2585408,22.262785 C29.9320145,22.53519 29.0923235,23.0057667 28.6750086,23.2377425 C28.2775604,23.5018183 27.8125973,23.6740403 27.3581473,23.8835504 C26.8839867,24.0367877 26.4053636,24.2149583 25.9086956,24.3009537 C25.4168098,24.423542 24.9154305,24.461957 24.4232581,24.5178917 C22.4451177,24.6508643 20.5889386,24.326711 19.2337935,24.0176159 C17.8779362,23.707793 17,23.4395475 17,23.4395475 C17,23.4395475 17.9224717,23.4723782 19.2906793,23.4239391 C19.9747404,23.4063121 20.7681195,23.3352368 21.6004364,23.2294821 C22.4307444,23.1017662 23.2979719,22.9188746 24.1127405,22.6440314 C24.5116585,22.4854229 24.9140194,22.3487664 25.2723453,22.1479378 C25.6469435,21.9806975 25.9682242,21.7434855 26.287089,21.5473081 C26.574022,21.3000645 26.9448138,21.0766527 27.1651502,20.8173895 L20.3278195,16.377175 C18.9645111,15.4447939 18.4221919,13.5593321 19.3269638,12.1141366 L24.334819,4.35497893 C25.2395908,2.90978346 27.3079635,2.59772899 28.6712767,3.52937758 C29.5801521,4.15047664 32.0453122,5.75137031 36.0667569,8.33205859 C37.4307823,9.26443503 37.9723844,11.1499015 37.0676126,12.595097 L32.0248638,20.3602493 Z" id="Path" fill="#FF5100" fill-rule="nonzero" transform="translate(27.256714, 13.774274) scale(-1, -1) rotate(327.000000) translate(-27.256714, -13.774274) "></path>
                    </g>
                </svg>
                <span *ngIf="research.isActive()" class="custom-icon icon-quotes-left"></span>
                <h3 class="media-title">{{ research.title }}</h3>
                <div class="media detail">
                    <i class="align-self-center fal fal fa-calendar-alt"></i>
                    <div class="media-body ml-2" *ngIf="research.isActive()">
                        <div>{{ 'FROM_DATE' | translate }} {{ research.date_start | dateToLocaleString }}</div>
                        <div>{{ 'TO_DATE' | translate }} {{ research.date_end | dateToLocaleString }}</div>
                    </div>
                    <div class="media-body ml-2" *ngIf="!research.isActive()">
                        <div>{{ 'WAITING_APPROVAL' | translate }}</div>
                    </div>
                </div>
                <div class="media detail">
                    <i class="align-self-center fal fa-users vertical-align-middle"></i>
                    <div class="media-body ml-2">
                        {{ 'INTERVIEWEDS.TOTALS.CUSTOM' | translate: {param1: research.nr_interviewed } }}
                    </div>
                </div>
                <div class="media detail">
                    <i class="align-self-center fal fa-flag"></i>
                    <div class="media-body ml-2">
                        <ng-container *ngIf="research.countries">
                            <span *ngFor="let country of research.countries let last = last;">
                                {{ countriesService.nameByCode(country.country_code) }}<ng-container *ngIf="!last">,</ng-container>
                            </span>
                        </ng-container>
                    </div>
                </div>
            </div>
        </a>
        <div *ngIf="!research.getId()" class="res-overview-box new-research d-flex flex-column align-items-center justify-content-center as-link" (click)="newResearchModalSlider($event)">
            <svg width="42px" height="58px" class="mx-auto mb-2" viewBox="0 0 42 58" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <!-- Generator: Sketch 63.1 (92452) - https://sketch.com -->
                <g id="molecule-/-sicchemoji-/-happy" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Group" transform="translate(-9.000000, -1.000000)" fill="#828282" fill-rule="nonzero">
                        <path d="M23.9546255,25.7453978 C23.1754382,27.0056485 22.247542,27.7309454 21.8438798,28.018917 C21.4536827,28.3444394 20.4502568,28.9067759 19.9515679,29.1839856 C19.4766196,29.4995547 18.9209914,29.705359 18.3779263,29.9557223 C17.8113072,30.13884 17.2393553,30.3517528 16.6458399,30.4545169 C16.0580392,30.6010092 15.4588938,30.6469149 14.8707507,30.7137564 C12.5068843,30.872658 10.2887609,30.4852967 8.66937035,30.1159298 C7.04912866,29.7456932 6,29.4251414 6,29.4251414 C6,29.4251414 7.10234841,29.4643739 8.7373485,29.4064895 C9.55479768,29.3854253 10.5028811,29.3004907 11.497495,29.1741145 C12.4897083,29.0214946 13.5260401,28.8029402 14.4996839,28.4745042 C14.9763886,28.284968 15.4572076,28.1216643 15.8854049,27.8816752 C16.3330477,27.681824 16.7169761,27.3983571 17.0980178,27.1639262 C17.440901,26.8684715 17.8839952,26.6014958 18.1472959,26.2916777 L9.9767251,20.985647 C8.34757938,19.8714569 7.69951117,17.6183409 8.78070828,15.8913407 L14.7650664,6.619192 C15.8462635,4.89219176 18.317957,4.51928847 19.9471083,5.63260316 C21.0332092,6.37481295 23.9790613,8.28787165 28.7846645,11.3717793 C30.414667,12.4859637 31.0618785,14.7390853 29.9806813,16.4660856 L23.9546255,25.7453978 Z" id="Path" transform="translate(18.256714, 17.875195) scale(-1, -1) rotate(327.000000) translate(-18.256714, -17.875195) "></path>
                        <path d="M46.9546255,25.7453978 C46.1754382,27.0056485 45.247542,27.7309454 44.8438798,28.018917 C44.4536827,28.3444394 43.4502568,28.9067759 42.9515679,29.1839856 C42.4766196,29.4995547 41.9209914,29.705359 41.3779263,29.9557223 C40.8113072,30.13884 40.2393553,30.3517528 39.6458399,30.4545169 C39.0580392,30.6010092 38.4588938,30.6469149 37.8707507,30.7137564 C35.5068843,30.872658 33.2887609,30.4852967 31.6693704,30.1159298 C30.0491287,29.7456932 29,29.4251414 29,29.4251414 C29,29.4251414 30.1023484,29.4643739 31.7373485,29.4064895 C32.5547977,29.3854253 33.5028811,29.3004907 34.497495,29.1741145 C35.4897083,29.0214946 36.5260401,28.8029402 37.4996839,28.4745042 C37.9763886,28.284968 38.4572076,28.1216643 38.8854049,27.8816752 C39.3330477,27.681824 39.7169761,27.3983571 40.0980178,27.1639262 C40.440901,26.8684715 40.8839952,26.6014958 41.1472959,26.2916777 L32.9767251,20.985647 C31.3475794,19.8714569 30.6995112,17.6183409 31.7807083,15.8913407 L37.7650664,6.619192 C38.8462635,4.89219176 41.317957,4.51928847 42.9471083,5.63260316 C44.0332092,6.37481295 46.9790613,8.28787165 51.7846645,11.3717793 C53.414667,12.4859637 54.0618785,14.7390853 52.9806813,16.4660856 L46.9546255,25.7453978 Z" id="Path" transform="translate(41.256714, 17.875195) scale(-1, -1) rotate(327.000000) translate(-41.256714, -17.875195) "></path>
                    </g>
                    <path d="M16.4715026,61 C20.3678756,61 23.4352332,59.8853503 25.6735751,57.656051 C27.8911917,55.3842887 29,52.2632696 29,48.2929936 C29,46.5095541 28.6994819,44.8004246 28.0984456,43.1656051 C27.4974093,41.5307856 26.626943,40.1295117 25.4870466,38.9617834 C25.0103627,38.4734607 24.5025907,38.0541401 23.9637306,37.7038217 C23.4248705,37.3535032 22.8549223,37.0615711 22.253886,36.8280255 C20.8031088,36.2760085 19,36 16.8445596,36 L16.8445596,36 L11,36 L11,61 L16.4715026,61 Z M17.4663212,58.8025478 L13.4559585,58.8025478 L13.4559585,38.1974522 L15.880829,38.1974522 C17.8290155,38.1974522 19.3419689,38.3673036 20.4196891,38.7070064 C21.5388601,39.0679406 22.5233161,39.6624204 23.373057,40.4904459 C24.5336788,41.6369427 25.3419689,43.0169851 25.7979275,44.6305732 C25.9430052,45.1825902 26.0518135,45.7558386 26.1243523,46.3503185 C26.1968912,46.9447983 26.2331606,47.581741 26.2331606,48.2611465 C26.2331606,51.7006369 25.2901554,54.3864119 23.4041451,56.3184713 C21.7875648,57.9745223 19.8082902,58.8025478 17.4663212,58.8025478 L17.4663212,58.8025478 Z" id="D" fill="#828282" fill-rule="nonzero" transform="translate(20.000000, 48.500000) rotate(90.000000) translate(-20.000000, -48.500000) "></path>
                </g>
            </svg>
            <h3 class="title text-center">{{ 'START_NEW_RESEARCH' | translate }}</h3>
        </div>
    </div>
</div>
<div class="see-all-research-link">
    <span *ngIf="link"><a routerLink="/researches">{{ 'SEE_ALL_YOUR_RESEARCHES' | translate }}&nbsp;<i class="far fa-arrow-right text-primary-color" style="font-size: 13px;"></i></a>&nbsp;</span>
</div>

