<ng-container *ngIf="answer">
  <div class="img-card-box d-flex flex-wrap justify-content-center p-0 {{ classes }}" *ngIf="answer.answer_data">
    <div class="mt-3 p-2" *ngFor="let cat of question.question_data.categories">
      <div *ngIf="getAnswersForCategory(cat).length" class="card p-2">
        <div class="d-flex justify-content-center">
          <h5>{{ cat }}</h5>
        </div>
        <div class="text-center">
          <div *ngFor="let a of getAnswersForCategory(cat)" class="d-inline-block v-top m-2">
            <div class="card">
              <img *ngIf="getAnswer(a.optionid).image_url" class="card-img-top" [src]="getAnswer(a.optionid).image_url | privatesrc" alt="">
              <!-- <div *ngIf="getAnswer(a.optionid).image_url && explicitMediaUrls" class="explicit-media-url" >
                <a [href]="getAnswer(a.optionid).image_url">{{ 'MEDIA_DOWNLOAD' | translate }}</a>
              </div> -->
              <div class="text-center">
                <p class="m-0 p-2">{{ getAnswer(a.optionid).comment }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
