<ng-container *ngIf="answer">
  <div class="img-card-box d-flex flex-wrap justify-content-center p-0 {{ classes }}" *ngIf="answer.answer_data">
    <div class="mt-3 px-2" *ngFor="let a of answer.answer_data; let i = index">
      <div class="card">
        <div class="d-flex">
          <div class="d-flex align-items-center justify-content-center m-3">
            <p class="m-0">{{ getStatement(i) }}</p>
          </div>
          <div class="d-flex align-items-center justify-content-center m-3">
            <p class="m-0">{{ a.step }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
