import { ManageUsersModel } from '../../models/support/manage-users.model';
import { Injectable } from '@angular/core';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { BaseService } from '@app/core/services/base.service';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { InterviewedsModel } from '@app/shared/models/intervieweds.model';


@Injectable({
  providedIn: 'root'
})
export class ManageUsersService extends BaseService implements BaseServiceI {

  dashboardUsers: InterviewedsModel[] = null;

  constructor(public router: Router, public apiService: ApiService, private http: HttpClient) {
    super(router, apiService, ManageUsersModel);
  }

  getDashboardUsers(download_xls = false, sort_attribute = null, sort_order = null) {
    if (download_xls) {
      return this.getDownload('dashboard-users', {download_xls, sort_attribute, sort_order});
    }
    return this.get('dashboard-users', {sort_attribute, sort_order});
  }

  downloadDashboardUsers() {
    return this.sub('dashboard-users-download').get();
  }

  getCustomerMonitoring(country_uniqid) {
    return this.get('get-customers-status', {country_uniqid});
  }

  updatePrivacyPolicy(privacy) {
    return this.sub('set-default-privacy-policy').create({privacy_policy: privacy.default_privacy_html});
  }

}
