import { BlogModel } from './../models/blog.model';
import { Injectable } from '@angular/core';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { BaseService } from '@app/core/services/base.service';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { FaqModel } from '../models/faq.model';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ModerationHelperService extends BaseService implements BaseServiceI {

  /**
   * Global variables used as helper
   */
  public message$: BehaviorSubject<string> = new BehaviorSubject(null);
  public spreadInOthersAnswersAndComments: BehaviorSubject<any> = new BehaviorSubject(null);
  public contentTagList = [];
  public teamLockChange: BehaviorSubject<any> = new BehaviorSubject(null);

  // Quotes
  public quotes: BehaviorSubject<any> = new BehaviorSubject(null);
  public refreshQuotes: BehaviorSubject<boolean> = new BehaviorSubject(false);

  // Comment
  public lastCommentInserted: BehaviorSubject<any> = new BehaviorSubject(null);
  public reloadCommentsOf: BehaviorSubject<number> = new BehaviorSubject(null); // id answer

  // Back button in side modal (func)
  public backButtonInSideModal: BehaviorSubject<any> = new BehaviorSubject(null);

  public replyingFromModal: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(public router: Router, public apiService: ApiService, private http: HttpClient) {
    super(router, apiService, FaqModel);
  }

  public setQuotes(data: any) {
    this.quotes.next(data);
  }

}
