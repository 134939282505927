<div class="sidebar-body">
    <section *ngIf="countryUniqId">
        <app-overview-modal-filter
            (searchFn)="getAudioByFilter()"
            [filterForm]="filterForm"
            [highlighted]="true"
            [star]="true"
            [countryUniqId]="countryUniqId">
        </app-overview-modal-filter>
    </section>
    <app-core-spinner class="mt-2" [toggle]="operation('getting-audios').submitting"></app-core-spinner>
    <div class="alert alert-info mt-2"  *ngIf="operation('getting-audios').success && !audios.length">{{ 'THERE_ARE_NO_AUDIO' | translate }}</div>
    <section class="mt-4" *ngIf="!operation('getting-audios').submitting && audios.length">

        <div class="row mt-2 mb-3">
            <div class="col-12 d-flex align-items-center justify-content-end">
                <div class="text-left flex-grow-1">
                    <h3>{{ audios.length }} audio</h3>
                </div>
                <div class="check-radio-box mb-0 mr-4">
                    <input (change)="selectAll($event)" type="checkbox"id="check1">
                    <label for="check1">
                        {{ 'SELECT_ALL' | translate }}
                    </label>
                </div>
                <button
                    *ngIf="!isSmart"
                    [disabled]="!hasSelected()"
                    (click)="downloadSelected()"
                    class="btn btn-sicche">
                        <i class="fal fa-download"></i>&nbsp;{{ 'DOWNLOAD_SELECTED' | translate }}
                </button>
            </div>
        </div>
        <div class="row" *ngFor="let audio of audios;let i = index;">
            <div class="col-md-4 left-style mb-4" *ngIf="i%3 == 0 && audios[i]">
                <div class="panel shadow p-3 h-100 my-0">
                    <div class="audio-wrapper">
                        <input [(ngModel)]="audios[i].state.selected" type="checkbox" class="form-control" class="selection-checkbox">
                        <audio controls>
                            <source [src]="audios[i].file_url | privatesrc" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                    </div>
                    <div class="card-body">
                        <ng-container *ngTemplateOutlet="footerTemplate; context: {popPos: 'right', item : audios[i]}"></ng-container>
                    </div>
                </div>
            </div>
            <div class="col-md-4 left-style mb-4" *ngIf="i%3 == 0 && audios[i+1]">
                <div class="panel shadow p-3 h-100 my-0">
                    <div class="audio-wrapper">
                        <input [(ngModel)]="audios[i+1].state.selected" type="checkbox" class="form-control" class="selection-checkbox">
                        <audio controls>
                            <source [src]="audios[i+1].file_url | privatesrc" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                    </div>
                    <div class="card-body">
                        <ng-container *ngTemplateOutlet="footerTemplate; context: {popPos: 'left', item : audios[i+1]}"></ng-container>
                    </div>
                </div>
            </div>
            <div class="col-md-4 left-style mb-4" *ngIf="i%3 == 0 && audios[i+2]">
                <div class="panel shadow p-3 h-100 my-0">
                    <div class="audio-wrapper">
                        <input [(ngModel)]="audios[i+2].state.selected" type="checkbox" class="form-control" class="selection-checkbox">
                        <audio controls>
                            <source [src]="audios[i+2].file_url | privatesrc" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                    </div>
                    <div class="card-body">
                        <ng-container *ngTemplateOutlet="footerTemplate; context: {popPos: 'left', item : audios[i+2]}"></ng-container>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>


<ng-template let-item="item" let-popPos="popPos" #footerTemplate>
    <div class="row">
        <div class="col-md-10">
            <div class="info-group d-flex">
                <!-- AVATAR -->
                <div class="avatar">
                    <div class="avatar-bg-img" *ngIf="item.team.avatar_url" [ngStyle]="{'background-image': 'url(' + (item.team.avatar_url | privatesrc) + ')'}"></div>
                    <div *ngIf="!item.team.avatar_url" class="d-flex align-items-center justify-content-center placeholder-avatar">
                        <i class="align-self-center fal fa-user"></i>
                    </div>
                </div>
                <!-- #AVATAR -->
                <div class="ml-2 bold d-flex align-items-center">
                    <div>{{ item.team.nickname ? item.team.nickname : item.team.firstname + ' ' + item.team.lastname }}</div>
                </div>
            </div>
            <div class="info-group d-flex mt-3">
                <i class="align-self-start fal fa-comment"></i>
                <div class="ml-2">
                    <div>{{ item.room_title }} - {{ item.answer_creationtime | dateToLocaleString }} - {{ item.block_title }} - <a [ngbTooltip]="item.question_title" triggers="click:blur" [autoClose]="'outside'">{{ 'QUESTION' | translate }}</a></div>
                </div>
            </div>
        </div>
        <div class="col-md-2 p-0 text-right">
            <a
                download
                [href]="item.file_download_url"
                target="_blank"
                class="btn-sicche btn-icon small btn-secondary">
                    <i class="far fa-download"></i>
            </a>
        </div>
    </div>
</ng-template>