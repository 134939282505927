import { Component, OnInit, Input } from '@angular/core';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { AnalysisService } from '@app/shared/services/analysis.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-overview-modal',
  templateUrl: './overview-modal.component.html',
  styleUrls: ['./overview-modal.component.scss']
})
export class OverviewModalComponent implements OnInit {

  @Input()
  type: string;
  @Input()
  countryUniqId = null;

  constructor(private coreLayoutService: CoreLayoutService, private translate: TranslateService) { }

  ngOnInit(): void {
    // this.coreLayoutService.title('QUOTES');
  }

}
