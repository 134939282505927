import { Component, OnInit, Input, ElementRef, ViewChild, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { AnswerModel } from '@app/shared/models/answer.model';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ResponseModel } from '@app/core/models/response.model';
import { ModalService } from '@app/core/services/modal.service';
import { AnswersService } from '@app/shared/services/answers.service';
import { QuoteModel } from '@app/shared/models/quote.model';
import { TeamService } from '@app/shared/services/team.service';
import { ModerationHelperService } from '@app/shared/services/moderation-helper.service';

@Component({
  selector: 'app-moderation-answerstype-pin-on-media',
  templateUrl: './pin-on-media.component.html',
  styleUrls: ['./pin-on-media.component.scss']
})
export class AnswersTypePinOnMediaComponent extends CoreBaseComponent implements OnInit {

  @Input() question: QuestionModel;
  @Input() answer: AnswerModel;
  @Input() classes: string;
  @Input() explicitMediaUrls: boolean;
  @Input() set message(value: {action: string, data: any}) {
    this.switchMessage(value);
  }

  @Output() textSelection: EventEmitter<any> = new EventEmitter(null);

  get formPins(): UntypedFormArray {
    return this.form.controls.pins as UntypedFormArray;
  }

  @ViewChild('pinnedImage') imageel: ElementRef;
  @ViewChild('videoplayer') videoplayer: ElementRef;
  timeAxis: number[];

  public ready = false;
  public selectedPin: any;
  public showPin = false;
  public form: UntypedFormGroup;
  public itemToModify: number = null;
  public quotes: Array<QuoteModel> = [];
  public comment: String = null;

  constructor(private modalService: ModalService, private answerService: AnswersService, private teamService: TeamService,
              private moderationHelperService: ModerationHelperService, private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.comment = this.answer.answer_data.comment;
    // Pins
    if(this.answer.answer_data.pins) {
      this.answer.answer_data.pins.forEach((pin, index) => {
        this.answer.answer_data.pins[index].commentHTML = this.answer.answer_data.pins[index].comment ? this.answer.answer_data.pins[index].comment : '';
        // ...just to prevent bugs
        if(!this.answer.answer_data.pins[index].comment) {
          this.answer.answer_data.pins[index].comment = '';
        }
      });
    }
    // Quotes
    if(!this.teamService.isCurrentUserCustomerPro() && !this.teamService.isCurrentUserCustomerBasic()) {
      if(this.answer.quotes) {
        this.printQuotes(this.answer.quotes);
        this.quotes = this.answer.quotes;
      }
      this.moderationHelperService.quotes.subscribe(quotes => {
        this.quotes = quotes;
        this.printQuotes(quotes);
        this.cdr.detectChanges();
      });
    }
    this.form = new UntypedFormGroup({
      comment: new UntypedFormControl('', null),
      pins: new UntypedFormArray([], null)
    });
    if (this.answer.answer_data.comment) {
      this.form.controls.comment.patchValue(this.answer.answer_data.comment);
    }
    if (this.answer.answer_data.pins) {
      this.answer.answer_data.pins.forEach((p: any) =>
        (this.form.controls.pins as UntypedFormArray).push(new UntypedFormControl(p.comment, null))
      );
    }
  }

  private printQuotes(quotes: Array<QuoteModel>) {
    //@ts-ignore
    this.comment = this.comment?.replaceAll(/\s{2,}/g, ' ')?.replaceAll(/\r?\n|\r/g, '');
    if(quotes != null) {
      quotes.forEach(quote => {
        if(quote.answerid === this.answer.id) {
          if(this.comment.includes(quote.quote)) {
            if(this.comment) {
              const qclass = quote.color ? quote.color : 'grey';
              this.comment = this.comment.replace(quote.quote, '<em class="highlight ' + qclass + '">' + quote.quote +'</em>');
            }
          }
          // Pins
          if(this.answer.answer_data.pins) {
            this.answer.answer_data.pins.forEach((pin, index) => {
              const qclass = quote.color ? quote.color : 'grey';
              this.answer.answer_data.pins[index].commentHTML = this.answer.answer_data.pins[index].commentHTML.replace(quote.quote, '<em class="highlight ' + qclass + '">' + quote.quote +'</em>');
            });
          }
        }
      });
    }
  }

  private switchMessage(message: {action: string, data: any}) {
    if (!message) {
      return false;
    }
    switch (message.action) {
      case 'modify-answer':
        this.itemToModify = message.data;
        break;
    }
  }

  saveMods() {
    this.operation('saving').reset().isSubmitting();
    const data = this.answer;
    this.form.value.pins.forEach((pin: any, i: number) => data.answer_data.pins[i].comment = this.form.value.pins[i]);
    this.answerService.updateAnswer(this.answer.id, data).subscribe((res: ResponseModel) => {
      this.answer.answer_data.comment = this.form.value.comment;
      // Update main comment and quotes on main comment
      this.comment = this.answer.answer_data.comment;
      this.form.value.pins.forEach((com: string, i: number) => this.answer.answer_data.pins[i].comment = com);
      // Update pins
      if(this.answer.answer_data.pins) {
        this.answer.answer_data.pins.forEach((pin, index) => {
          this.answer.answer_data.pins[index].commentHTML = this.answer.answer_data.pins[index].comment ? this.answer.answer_data.pins[index].comment : '';
          // ...just to prevent bugs
          if(!this.answer.answer_data.pins[index].comment) {
            this.answer.answer_data.pins[index].comment = '';
          }
        });
      }
      // Print quotes again
      this.printQuotes(this.quotes);
      this.itemToModify = null;
      this.operation('saving').isSuccess();
    });
  }

  pinSelect(pin: any) {
    this.showPin = true;
    this.selectedPin = pin;
    if (this.question.question_data.media_type === 'video') {
      this.videoplayer.nativeElement.pause();
      this.videoplayer.nativeElement.currentTime = pin.time;
    }
  }

  getVideoPinDistance(pin?: any): string {
    return `${pin.time * 100 / this.videoplayer.nativeElement.duration}%`;
  }

  getImagePinDistance(pin?: any): { left: string, top: string } {
    return {
      left: `${100 * pin.position.x / this.imageel.nativeElement.naturalWidth}%`,
      top: `${100 * pin.position.y / this.imageel.nativeElement.naturalHeight}%`,
    };
  }

  onVideoMetaLoad() {
    const nseg = (this.videoplayer.nativeElement.duration > 11 ? 11 : this.videoplayer.nativeElement.duration) + 1;
    const seg = this.videoplayer.nativeElement.duration / nseg;
    this.timeAxis = new Array(nseg).fill(0).map((n, i) => i * seg);
    this.ready = true;
  }

  selectText() {
    this.textSelection.emit({commentid:null});
  }
}
