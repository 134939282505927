import { Component, OnInit, Input, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { AnswerModel } from '@app/shared/models/answer.model';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { ResponseModel } from '@app/core/models/response.model';
import { ModalService } from '@app/core/services/modal.service';
import { AnswersService } from '@app/shared/services/answers.service';
import { QuoteModel } from '@app/shared/models/quote.model';
import { ModerationHelperService } from '@app/shared/services/moderation-helper.service';
import { TeamService } from '@app/shared/services/team.service';

@Component({
  selector: 'app-moderation-answerstype-sentence-completion',
  templateUrl: './sentence-completion.component.html',
  styleUrls: ['./sentence-completion.component.scss']
})
export class AnswersTypeSentenceCompletionComponent extends CoreBaseComponent implements OnInit {

  @Input() question: QuestionModel;
  @Input() answer: AnswerModel;
  @Input() classes: string;
  @Input() explicitMediaUrls: boolean;
  @Input() set message(value: {action: string, data: any}) {
    this.switchMessage(value);
  }
  @Output() textSelection: EventEmitter<any> = new EventEmitter(null);

  public quotes: Array<QuoteModel>;


  public form: UntypedFormArray;
  public itemToModify: number = null;

  constructor(
    private modalService: ModalService,
    private answerService: AnswersService,
    private moderationHelperService: ModerationHelperService,
    private cdr: ChangeDetectorRef,
    private teamService: TeamService
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = new UntypedFormArray([], null);
    this.answer.answer_data.completion_comments.forEach((a: string) =>
      this.form.push(new UntypedFormControl(a, null))
    );

    if(!this.teamService.isCurrentUserCustomerPro() && !this.teamService.isCurrentUserCustomerBasic()) {
      if(this.answer.quotes) {
        this.quotes = this.answer.quotes;
      }
      this.moderationHelperService.quotes.subscribe(quotes => {
        this.quotes = quotes;
        this.cdr.detectChanges();
      });

      this.printQuotes()
      setInterval(() => {
        this.printQuotes()
      }, 1000)
    }
  }

  printQuotes() {
    const answer = this.answer.answer_data
    if (answer.comment) {
      answer.comment = answer.comment.replace(/(\r\n|\n|\r)/gm, "<br />");
      if(this.quotes) {
        this.quotes.forEach(quote => {
          const qclass = quote.color ? quote.color : 'grey';
          if(quote.quote_original && quote.quote_original.length) {
            quote.quote_original.forEach(q => {
              answer.comment = answer.comment.replace(q, '<em class="highlight ' + qclass + '">'+ q +'</em>');
            });
          } else {
            answer.comment = answer.comment.replace(quote.quote, '<em class="highlight ' + qclass + '">' + quote.quote +'</em>');
          }
        });
      }
    }
  }

  selectText() {
    this.textSelection.emit({commentid:null});
  }


  private switchMessage(message: {action: string, data: any}) {
    if (!message) {
      return false;
    }
    switch (message.action) {
      case 'modify-answer':
        this.itemToModify = message.data;
        break;
    }
  }

  saveMods() {
    this.operation('saving').reset().isSubmitting();
    const data = this.answer;
    data.answer_data.completion_comments = this.form.value;
    this.answerService.updateAnswer(this.answer.id, data).subscribe((res: ResponseModel) => {
      this.answer.answer_data.completion_comments = this.form.value;
      this.itemToModify = null;
      this.operation('saving').isSuccess();
    });
  }

}
