import { Injectable } from '@angular/core';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { BaseService } from '@app/core/services/base.service';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { HttpClient } from '@angular/common/http';
import { catchError, count } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { ServiceMessageModel } from '@app/core/models/service-message.model';
import { CountryModel } from '../models/country.model';
import { ResponseModel } from '@app/core/models/response.model';


@Injectable({
  providedIn: 'root'
})
export class CountryService extends BaseService implements BaseServiceI {
  // tslint:disable: variable-name

  message$: BehaviorSubject<ServiceMessageModel> = new BehaviorSubject(null);
  country$: BehaviorSubject<CountryModel> = new BehaviorSubject(null);
  customerCountryUniqid$: string;

  private currentPersonasTagsSource  = new BehaviorSubject([]);
  currentPersonasTags = this.currentPersonasTagsSource.asObservable();

  private currentContentTagSource  = new BehaviorSubject([]);
  currentContentTag = this.currentContentTagSource.asObservable();

  private allUserPersonasTagByIdSource  = new BehaviorSubject({});
  allUserPersonasTagById = this.allUserPersonasTagByIdSource.asObservable();
  $allUserPersonasTagById = {};

  private allUsersNoteByIdSource  = new BehaviorSubject({});
  allUsersNoteById = this.allUsersNoteByIdSource.asObservable();
  $allUsersNoteById = {};


  constructor(public router: Router, public apiService: ApiService, private http: HttpClient) {
    super(router, apiService, CountryModel);
  }

  get customerCountryUniqid(): string {
    return this.customerCountryUniqid$ || localStorage.getItem('country_uniqid');
  }
  set customerCountryUniqid(uniqid) {
    localStorage.setItem('country_uniqid', uniqid);
    this.customerCountryUniqid$ = uniqid;
  }

  updateAnswerPersonasTag(userId, personasTag) {
    this.$allUserPersonasTagById[userId] = personasTag
    const personasTagByUserid = this.$allUserPersonasTagById;
    this.allUserPersonasTagByIdSource.next(personasTagByUserid)
  }

  updateUsersNotes(userId, note) {
    this.$allUsersNoteById[userId] = note
    const noteByUserid = this.$allUsersNoteById;
    this.allUsersNoteByIdSource.next(noteByUserid)
  }

  updateContentTags(country_uniqid: string) {
    return new Promise((resolve, reject) => {
      this.getContentTags(country_uniqid, 'answer_tags').subscribe((res: ResponseModel) => {
        this.currentContentTagSource.next(res.getData().map(tag => tag.name))
        resolve(res.getData().map(tag => tag.name))
      })
    })
  }

  getPersonasTags(country_uniqid: string) {
    return this.sub(`${country_uniqid}/personas-tag-list`).get('', null, true);
  }

  updatePersonasTags(country_uniqid) {
    return new Promise((resolve, reject) => {
      this.getPersonasTags(country_uniqid).subscribe((res: ResponseModel) => {
        this.currentPersonasTagsSource.next(res.getData());
        resolve(true)
      })
    })
  }

  currentCountryUseMeridian(): boolean {
    // al momento utilizziamo gli orari con i meridiani ovunque tranne che in italia
    return this.getSessionCountry().country_code != 'it';
  }


  getSessionCountry(): CountryModel {
    return this.country$.value;
  }

  setSessionCountry(country: CountryModel): CountryModel {
    this.country$.next(country);
    return this.getSessionCountry();
  }

  currentCountryIsItaly() {
    let country = this.getSessionCountry();
    if(country) {
      return country.language_code === 'it';
    } else {
      return false;
    }
  }

  // autocomplete_tag?tag_type=answer_tags&tag_text=bell
  getContentTags(countryUniqid: string, tag_type: string, tag_text: string = '') {
    return this.sub(`${countryUniqid}`).get('autocomplete_tag', {tag_type, tag_text});
  }

  getCountrySetupInfo(country_uniqid: string, include_stats = false) {
    return this.get(`${country_uniqid}`, {include_role_permission: 1, include_stats});
  }

  currentCountryIsClientBasic() {
    if (!this.country$.value) {
      return false;
    }
    return this.country$.value.isClientBasic();
  }

  updateCountry(country: CountryModel) {
    return this.sub(country.country_uniqid).create(country);
  }

  getDefaultCountryStructure(country_uniqid) {
    return this.sub(`${country_uniqid}/set_default_structure`).create({});
  }

  autocompleteTag(country_uniqid, search, type = 'sample_variable_tags') {
    return this.sub(country_uniqid).get('autocomplete_tag', {tag_text: search, tag_type: type});
  }

  getCountryActiveDays(country_uniqid: string, roomid?: number, no_future_days?: boolean) {
    return this.get(country_uniqid + '/get-active-days', {roomid, no_future_days});
  }

  getActiveDays(country_uniqid) {
    return this.sub(`${country_uniqid}/get-active-days`).get('', null, true);
  }

}
