<app-shared-sidebar-container  >
    <app-shared-sidebar-header>
        <div slot="title">
            <h2 class="mb-0">{{ 'INTERVIEWEDS.ADD_TO_ROOM' | translate }}</h2>
        </div>
        <div slot="buttons" class="text-right">
        </div>
    </app-shared-sidebar-header>
    <div class="sidebar-body small" *ngIf="room && users">
        <div class="container-fluid">
            <div class="row">
                <div class="form-group">
                    <app-core-select
                    *ngIf="country"
                    [classes]="'form-control'"
                    [multiple]="false"
                    [cache]="true"
                    [customList]="columns"
                    [searchable]="false"
                    [(ngmodel)]="room.sample_variable_tags"
                    (change)="setVariableTags($event)"
                    >
                    </app-core-select>
                </div>
            </div>

            <div class="row mt-5">
                <div class="col-12 mb-3">
                    {{'INTERVIEWEDS.NAME' | translate}} ({{users.length}})
                </div>

                <!-- [columns]="[{name: 'MAIL/LOGIN'}, {name: 'NAME'}, {icon_name: 'fal fa-square', type: 'icon'}]" -->
                <div class="col-12">
                    <div class="position-relative">
                        <div class="table-responsive">
                            <app-core-table
                            [items]="users"
                            [columns]="[{name: 'MAIL/LOGIN'}, {name: 'FIRSTNAME_AND_LASTNAME'}, {name: 'NICKNAME'}]"
                            tableid="users"
                            [perPage]="15"
                            [rowTemplate]="rowTemplate"
                            (columnClick)="columnClick($event)">
                            </app-core-table>
                            <ng-template let-item="item" #rowTemplate>
                                <tr class="text-center">
                                    <td class="font-weight-bold text-left">
                                        {{ item.email }}
                                    </td>
                                    <td class="font-weight-bold text-left">
                                        {{ item.firstname }} {{ item.lastname }}
                                    </td>
                                    <td class="font-weight-bold">
                                        {{ item.nickname }}
                                    </td>
                                    <!-- <td>
                                        <input type="checkbox" [checked]="selectedUsers.includes(item.userid)" (change)="changeSelectedUsers(item.userid)">
                                    </td> -->
                                </tr>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-shared-sidebar-container>