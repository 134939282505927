import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ResearchService } from '@app/shared/services/research.service';
import { ResearchModel } from '@app/shared/models/research.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { ResponseModel } from '@app/core/models/response.model';
import { ResearchSessionService } from '@app/shared/services/research-session.service';
import { CountryService } from '@app/shared/services/country.service';
import { LanguagesService } from '@app/shared/services/languages.service';
import { TeamService } from '@app/shared/services/team.service';
import { UiService } from '@app/shared/services/ui.service';
import { NotificationService } from '@app/shared/services/notification.service';

@Component({
  selector: 'app-private-researches-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent extends CoreBaseComponent implements OnInit, OnDestroy {

  constructor(
    private researchService: ResearchService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public researchSessionService: ResearchSessionService,
    public countryService: CountryService,
    private languageService: LanguagesService,
    public teamService: TeamService,
    private uiService: UiService,
    private notificationService: NotificationService
  ) {
    super();
  }

  researchUniqid: string;
  research: ResearchModel;
  countryUniqid: string;
  section: string;
  lastUpdate = new Date();

  ngOnInit(): void {
    this.loading = true;
    this.researchUniqid = this.activatedRoute.snapshot.paramMap.get('research_uniqid');
    this.countryUniqid = this.activatedRoute.snapshot.paramMap.get('country_uniqid');
    this.loadResearch();
    this.countryService.getCountrySetupInfo(this.countryUniqid).subscribe((res: ResponseModel) => {
      const countryData = res.getData();
      this.countryService.setSessionCountry(countryData);
      this.uiService.changeLogo(countryData.logo_url);

    });

  }

  loadResearch() {
    this.researchService.getResearchByUniqid(this.researchUniqid).subscribe((res: ResponseModel) => {
      this.research = this.researchSessionService.setResearch(res.getData());
      this.loading = true;

      // se la ricerca ha status busy ogni minuto ricarico i dati finché lo status non cambia
      if (this.research.status === 'BUSY') {
        setTimeout(() => {
          this.loadResearch();
        }, 1000 * 30);
      }

      // Get current user
      this.teamService.getCurrentUserTeam(this.countryUniqid).subscribe((res: ResponseModel) => {
      }, (err) => {
        this.countryUniqid = this.research.countries[0].country_uniqid;
        this.changeCountry();
      });
    });
  }



  // changeCountry() {
  //   this.router.navigate(['/researches/navigation', this.researchUniqid, this.countryUniqid, 'setup']);
  // }

  changeCountry() {
    this.router.navigate(['/researches/navigation', this.researchUniqid, this.countryUniqid, 'setup']);
    // this.loadCountry();
    window.location.href = `/#/researches/navigation/${this.researchUniqid}/${this.countryUniqid}/setup`;
    window.location.reload();
  }

  ngOnDestroy() {
    this.researchSessionService.destroy();
  }

}
