import { Component, OnInit, Input } from '@angular/core';
import { CoreLayoutService } from '@app/core/services/layout.service';

@Component({
  selector: 'app-shared-sidebar-header',
  templateUrl: './sidebar-header.component.html',
  styleUrls: ['./sidebar-header.component.scss']
})
export class SidebarHeaderComponent implements OnInit {
    constructor(public coreLayoutService: CoreLayoutService) { }
    ngOnInit() {}
}
