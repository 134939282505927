<div [formGroup]="form" *ngIf="ready">
    <div class="row">
        <div class="col-7">
            <div class="alert alert-warning" *ngIf="!questionsList.length">{{ 'THERE_ARE_NO_QUANT_QUESTIONS' | translate }}</div>
            <div class="form-group form-group tool tags-select-tool" *ngIf="questionsList.length">
                <div class="question-filters">
                    <app-core-select
                        *ngIf="questionsList.length"
                        [classes]="'form-control'"
                        [form]="form"
                        [formEntityName]="'condition_questionid'"
                        [value]="'id'"
                        [label]="'title'"
                        [resetButton]="true"
                        [preselected]="{byprop: 'id'}"
                        (change)="questionSelected($event)"
                        [customList]="questionsList">
                    </app-core-select>
                    <span class="placeholder">{{ 'AUTO_FILTER_FOR_Q_CONDITION' | translate }}</span>
                </div>
            </div>
        </div>
        <div class="col-3">
            <div class="form-group form-group tool tags-select-tool" *ngIf="answersList.length">
                <div class="question-filters">
                    <div>
                        <app-core-select
                            [classes]="'form-control'"
                            [form]="form"
                            [label]="'comment'"
                            [value]="'id'"
                            [resetButton]="true"
                            [formEntityName]="'condition_questionopt'"
                            [preselected]="{byprop: 'id'}"
                            [customList]="answersList">
                        </app-core-select>
                    </div>
                    <span class="placeholder">{{ 'QUESTION_ANSWER' | translate }}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-7">
            <div class="form-group form-group tool tags-select-tool">
                <div class="question-filters">
                    <!--
                    <app-core-select
                        [classes]="'form-control'"
                        [service]="roomsService"
                        [query]="{country_uniqid: countryUniqId}"
                        [label]="'title'"
                        [form]="form"
                        [value]="'title'"
                        [formEntityName]="'sample_variable_tags'"
                        [multiple]="true">
                    </app-core-select>
                    -->
                    <app-core-select
                        [classes]="'form-control'"
                        [form]="form"
                        [resetButton]="true"
                        [customList]="sampleVariableTags"
                        [formEntityName]="'sample_variable_tags'"
                        [multiple]="true">
                    </app-core-select>
                    <span class="placeholder">{{ 'RECIPIENTS_SEGMENTS' | translate }} <small>({{ 'SAMPLE_VARIABLES' | translate }})</small></span>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-7">
            <div class="form-group form-group tool tags-select-tool">
                <div class="question-filters">
                    <app-core-select
                        [classes]="'form-control'"
                        [multiple]="true"
                        [form]="form"
                        [resetButton]="true"
                        [customList]="personasTagsList"
                        [formEntityName]="'personas_tags'">
                    </app-core-select>
                    <span class="placeholder">{{ 'PERSONAS' | translate }}</span>
                </div>
            </div>
        </div>
    </div>
</div>