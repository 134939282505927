import { ManageResearchesModel } from '../../models/manage-researches.model';
import { Injectable } from '@angular/core';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { BaseService } from '@app/core/services/base.service';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ManageResearchesService extends BaseService implements BaseServiceI {


  constructor(public router: Router, public apiService: ApiService, private http: HttpClient) {
    super(router, apiService, ManageResearchesModel);
  }

  calculatePrice(research: any) {
    return this.sub('price_calculator').create(research);
  }

  getNewDemo() {
    return this.get('get-new-demo');
  }
}
