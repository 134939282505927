import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MessageModel } from '@app/shared/models/message.model';
import { TeamModel } from '@app/shared/models/team.model';
import { TeamService } from '@app/shared/services/team.service';
import { ResponseModel } from '@app/core/models/response.model';
import { DatetimeToDbPipe } from '@app/core/pipes/datetime-to-db.pipe';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { MessagesComponent } from '../messages.component';
import { CountryModel } from '@app/shared/models/country.model';
import { HoursToDaysPipe } from '@app/shared/pipes/hoursToDays.pipe';
import { CountryService } from '@app/shared/services/country.service';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { RoomModel } from '@app/shared/models/room.model';
import { RoomsService } from '@app/shared/services/rooms.service';
import * as Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';
import * as angular from 'angular';
import { LanguagesService } from '@app/shared/services/languages.service';
import { TemplateMessageModel } from '@app/shared/models/template-message.model';
import { TemplateMessageService } from '@app/shared/services/template-messages.service';
exporting(Highcharts);

@Component({
  selector: 'app-create-edit-message',
  templateUrl: './create-edit-message.component.html',
  styleUrls: ['./create-edit-message.component.scss']
})
export class CreateEditMessageComponent extends CoreBaseComponent implements OnInit {

  @Input() message: MessageModel;
  @Input() countryUniqid: string;
  @Input() edit: boolean;
  @Input() country: CountryModel;
  @Input() canSave: boolean;
  public Highcharts: typeof Highcharts = Highcharts;
  public chartOptions: Highcharts.Options = {
    title: {
      text: ''
    },
    series: [{
      name: '',
      //@ts-ignore
      data: [

      ],
      type: 'pie'
    }],
    tooltip: {
      pointFormat: '<b>{point.percentage:.1f}%</b>' /* {series.name}:  */
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: ['downloadJPEG', 'downloadPDF', 'downloadSVG'],
        },
      },
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                // @ts-ignore
                allowOverlap: true,
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
        }
    }
  };

  constructor(
    private teamService: TeamService,
    private datetimeToDbPipe: DatetimeToDbPipe,
    public coreLayoutService: CoreLayoutService,
    public hoursToDatePipe: HoursToDaysPipe,
    private toastService: ToastrService,
    private translateService: TranslateService,
    private roomsService: RoomsService,
    private countryService: CountryService,
    private languageService: LanguagesService,
    private templateMessageService: TemplateMessageService,
  ) {
    super();
    this.modules = {
      'emoji-shortname': true,
      'emoji-textarea': true,
      'emoji-toolbar': true,
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ header: 1 }, { header: 2 }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ color: [] }, { background: [] }],
        ['link'],
        ['emoji']
      ]
    };
  }

  forceShowSend = false;
  recipientType = 'ALL';
  // tslint:disable: max-line-length
  modules: any;
  autocompleteEmail: TeamModel[];
  recipientFilter: any;
  hoursSelect: any;
  readonly = false;
  tosReadonly = false;
  now = new Date();
  timeSend = {hour: this.now.getHours(), minute: this.now.getMinutes()};
  disableTime = false;
  datepickerSend = false;
  scheduledMessage = false;
  rooms: RoomModel[];
  sampleVariableTagsFilter: string[];
  roomsFilter: RoomModel;
  currentDateObj;
  users: TeamModel[];
  forceHideSelectDate = false;
  meridianDate = false;

  showSaveAsTemplate = false;
  showLoadFromTemplate = false;
  templateTitle = '';
  currentTemplateMessage: TemplateMessageModel;
  templateMessages: TemplateMessageModel[];
  chartReady = false;
  isReady = false;


  ngOnInit(): void {
    this.readonly = this.edit;
    this.roomsService.getRoomsByCountryUniqid(this.country.country_uniqid).subscribe((res: ResponseModel) => {
      this.rooms = res.getData();
    });

    this.loadTemplateMessages();
    this.meridianDate = this.languageService.currentLanguage == 'it' ? false : true;

    setTimeout(() => {
      this.message.country_uniqid = this.countryUniqid;
      this.teamService.getUsers(
        {
          country_uniqid: this.countryUniqid,
          role: 'INTERVIEWED'
        }
      ).subscribe((res: ResponseModel) => {
        const temp = res.getData();
        for (const user of temp) {
          user.nicknameAndEmail = (user.nickname || '') + ' - ' + user.email;
        }
        const usersWithNick = temp.filter(u => u.nickname)
        const usersWithoutNick = temp.filter(u => !u.nickname)

        this.users = usersWithNick.sort((a,b) => (a.nickname > b.nickname) ? 1 : ((b.nickname > a.nickname) ? -1 : 0))
        this.users = [...this.users, ...usersWithoutNick.sort((a,b) => (a.email > b.email) ? 1 : ((b.email > a.email) ? -1 : 0))]

        this.message.type = this.message.type || 'GENERIC';
        this.message.date_send = this.message.date_send || this.datetimeToDbPipe.transform(this.convertTZ(new Date(), this.countryService.getSessionCountry().timezone));
        // this.message.date_send = this.datetimeToDbPipe.transform(new Date('2021-12-17T03:24:00'));

        if (this.message.other_data && this.message.user_ids && this.message.user_ids.length > 0 && this.message.sent) {
          this.tosReadonly = true;
        }

        if (this.message.other_data && !this.message.other_data.is_scheduled) {
          this.forceHideSelectDate = true;
        }

        this.message.other_data = this.message.other_data || {recipient_user_firstname: null, recipient_room: null, recipient_filters: null, recipient_variable_tags: []};
        const parsedDate = new Date(this.parseCompatibilityDate(this.message.date_send));
        this.timeSend = {hour: parsedDate.getHours(), minute: parsedDate.getMinutes()};

        // grafico
        let read = 0
        let notRead = 0
        if (this.message.user_data) {
          for (const user of this.message.user_data) {
            if (this.isEmailReadByUserid(user.userid)) {
              read++
            } else {
              notRead++
            }
          }
        }
        //@ts-ignore
        this.chartOptions.series[0].data = [{name: this.translateService.instant('READ'), y: read}, {name: this.translateService.instant('NOT_READ'), y: notRead}];
        this.chartReady = true;

        this.isReady = true;
      });
    });
  }

  saveAsTemplateMessage() {
    const templateMessage = {
      title: this.templateTitle,
      body: this.message.msg,
      subject: this.message.subject
    }
    this.templateMessageService.createTemplateMessage(templateMessage).subscribe((res: ResponseModel) => {
      this.templateTitle = '';
      this.showSaveAsTemplate = false;1
      this.toastService.success(this.translateService.instant('SAVED_AS_TEMPLATE_MESSAGE'));
      this.loadTemplateMessages()
    })
  }
  loadTemplateMessages() {
    this.templateMessageService.getTemplateMessages().subscribe((res: ResponseModel) => {
      this.templateMessages = res.getData();
    })
  }

  setTemplateMessage(template: TemplateMessageModel) {
    this.currentTemplateMessage = template;
  }

  loadFromTemplate() {
    this.message.subject = this.currentTemplateMessage.subject;
    this.message.msg = this.currentTemplateMessage.body;
  }

  updateOtherData() {
    this.message.other_data = {recipient_user_firstname: null, recipient_room: null, recipient_filters: null, recipient_variable_tags: []};
  }

  changeDateSend(e = null) {
    setTimeout(() => {
      let selectedDate = new Date();
      if (e) {
        selectedDate.setFullYear(e.year);
        selectedDate.setMonth(e.month - 1);
        selectedDate.setDate(e.day);
      } else {
        selectedDate = new Date(this.parseCompatibilityDate(this.message.date_send));
      }
      selectedDate.setHours(this.timeSend.hour)
      selectedDate.setMinutes(this.timeSend.minute);
      this.currentDateObj = e;
      this.message.date_send = this.datetimeToDbPipe.transform(selectedDate, this.countryService.getSessionCountry().timezone);;
      this.datepickerSend = false;
    });
  }

  getUsersByTags(tags) {
    this.sampleVariableTagsFilter = tags;
  }

  getUsersByRooms(room) {
    this.roomsFilter = room;
  }

  hasRecipientOtherdata() {
    if (this.message.other_data && (
      this.message.other_data.is_scheduled ||
      this.message.other_data.recipient_filters ||
      this.message.other_data.recipient_room ||
      this.message.other_data.recipient_user_firstname ||
      this.message.other_data.recipient_variable_tags.length > 0
      )
    ) {
      return true;
    }
    return false;
  }

  getDestinationUsers(send = true) {
    if (this.message.user_ids && this.message.user_ids.length > 0) {
      return this.sendEmails();
    }

    if (!this.scheduledMessage) {
      if (this.recipientType === 'ALL') {
        const temp = this.users;
        this.message.user_ids = temp.map(u => u.userid);
        (send) ? this.sendEmails() : console.log('preparing user list');
      } else if (this.recipientType === 'SINGLE') {
        (send) ? this.sendEmails() : console.log('preparing user list');
      } else {
        if (this.recipientFilter === 'filter_missing_answers_for_country') {
          this.message.other_data.recipient_filters = 'filter_missing_answers_for_country';
          this.teamService.getFilteredUserIds(this.countryUniqid, this.sampleVariableTagsFilter, 'filter_missing_answers_for_country').subscribe((res: ResponseModel) => {
              this.message.user_ids = res.getData().map(u => u.id);
              (send) ? this.sendEmails() : console.log('preparing user list');
            });
        } else if (this.recipientFilter === 'filter_no_answers_given_for_country') {
          this.message.other_data.recipient_filters = 'filter_no_answers_given_for_country';
          this.teamService.getFilteredUserIds(this.countryUniqid, this.sampleVariableTagsFilter, 'filter_no_answers_given_for_country').subscribe((res: ResponseModel) => {
              this.message.user_ids = res.getData().map(u => u.id);
              (send) ? this.sendEmails() : console.log('preparing user list');
            });
        } else if (this.recipientFilter === 'filter_never_logged') {
          this.message.other_data.recipient_filters = 'filter_never_logged';
          this.teamService.getFilteredUserIds(this.countryUniqid, this.sampleVariableTagsFilter, 'filter_never_logged').subscribe((res: ResponseModel) => {
              this.message.user_ids = res.getData().map(u => u.id);
              (send) ? this.sendEmails() : console.log('preparing user list');
            });
        }  else if (this.recipientFilter === 'filter_have_not_logged_since_hours') {
          this.message.other_data.recipient_filters = this.hoursToDatePipe.getByInitialValue(this.hoursSelect);
          this.teamService.getFilteredUserIds(this.countryUniqid, this.sampleVariableTagsFilter, 'filter_have_not_logged_since_hours', this.hoursSelect).subscribe((res: ResponseModel) => {
              this.message.user_ids = res.getData().map(u => u.id);
              (send) ? this.sendEmails() : console.log('preparing user list');
            });
        } else {
          if (this.sampleVariableTagsFilter) {
            this.message.other_data.recipient_variable_tags = this.sampleVariableTagsFilter;
          }
          if (this.roomsFilter) {
            this.message.other_data.recipient_room = this.roomsFilter;
          }
          this.teamService.getFilteredUserIds(this.countryUniqid, (this.sampleVariableTagsFilter), null, this.hoursSelect, (this.roomsFilter ? this.roomsFilter.id : null)).subscribe((res: ResponseModel) => {
            this.message.user_ids = res.getData().map(u => u.id);
            (send) ? this.sendEmails() : console.log('preparing user list');
          });
        }
      }
    } else {
      this.teamService.getFilteredUserIds(this.country.country_uniqid, this.sampleVariableTagsFilter, null, null, (this.roomsFilter ? this.roomsFilter.id : null)).subscribe((res: ResponseModel) => {
        if (this.roomsFilter) {
          this.message.other_data.recipient_room = this.roomsFilter;
        }
        if (this.sampleVariableTagsFilter) {
          this.message.other_data.recipient_variable_tags = this.sampleVariableTagsFilter;
        }
        this.message.user_ids = res.getData(true).map(u => u.id);
        (send) ? this.sendEmails() : console.log('preparing user list');
      });
    }
  }

  sendEmails() {
    this.changeDateSend()
    this.message.include_link = true;
    if (this.scheduledMessage) {
      this.message.other_data.is_scheduled = true;
    } else {
      this.message.send_now = true;
    }

    // return console.log(this.message.user_ids);
    if (this.message.user_ids && this.message.user_ids.length > 0) {
      if (this.edit) {
        this.message.date_send = this.datetimeToDbPipe.transform(new Date(this.message.date_send), this.countryService.getSessionCountry().timezone);
        this.teamService.editMessage(this.message).subscribe((res: ResponseModel) => {
          this.coreLayoutService.closeSidebar();
        }, (err) => {
          this.toastService.error(this.translateService.instant(err.errors));
          this.resetOtherData();
          this.forceShowSend = true;
        });
      } else {
        this.teamService.sendMessage(this.message).subscribe((res: ResponseModel) => {
          this.coreLayoutService.closeSidebar();
        }, (err) => {
          this.forceShowSend = true;
          this.toastService.error(this.translateService.instant(err.errors));
          this.resetOtherData();
        });
      }
    } else {
      this.toastService.error(this.translateService.instant('MESSAGES_SECTION.NO_USER_IDS'));
      this.resetOtherData();
      return;
    }
  }

  resetOtherData() {
    this.message.other_data.recipient_filters = null;
    this.message.other_data.recipient_room = null;
    this.message.other_data.recipient_user_firstname = null;
    this.message.other_data.recipient_variable_tags = [];
  }

  updateEmail() {
    const message = angular.copy(this.message);
    message.date_send = this.datetimeToDbPipe.transform(message.date_send)
    this.teamService.editMessage(message).subscribe((res: ResponseModel) => {
      this.coreLayoutService.closeSidebar();
    });
  }

  changeSelectedHours(e) {
    this.hoursSelect = e.initial_value;
  }

  getUsersName(user) {
    if (user.nickname) {
      return user.nickname
    } else if (user.firstname) {
      return (user.firstname + ' ' + user.lastname)
    } else {
      return user.email
    }

  }


  setUser(user) {
    this.message.user_ids = [user.userid];
    // this.message.other_data.recipient_user_firstname = `${user.firstname} ${user.lastname}`;
    this.message.other_data.recipient_user_firstname = this.getUsersName(user);
    this.forceHideSelectDate = true;
  }

  isEmailReadByUserid(userid: number) {
    if (this.message.read_by_users) {
      return this.message.read_by_users.includes(userid);
    }
    return false;
  }

  emailAutocomplete(search: string) {
    this.teamService.autocompleteUsersByNickname(search, this.message.country_uniqid).subscribe((res: ResponseModel) => {
      this.autocompleteEmail = res.getData();
    });

    // this.teamService.autocompleteUsers(search).subscribe((res: ResponseModel) => {
    //   this.autocompleteEmail = res.getData();
    // });
  }

}
