import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { ToastrService } from 'ngx-toastr';
import { QuestionsService } from '@app/shared/services/questions.service';
import { ResponseModel } from '@app/core/models/response.model';
import { TranslateService } from '@ngx-translate/core';
import { AddQuestionComponent } from '../../../../add-question/add-question.component';
import { BlockModel } from '@app/shared/models/block.model';
import { ModalService } from '@app/core/services/modal.service';

@Component({
  selector: 'app-private-block-question-item',
  templateUrl: './question-item.component.html',
  styleUrls: ['./question-item.component.scss']
})
export class QuestionItemComponent implements OnInit {
  @Input() isEditable: Boolean;
  @Input() question: QuestionModel;
  @Input() block: BlockModel;
  @Input() index: number;
  @Input() currentDate: Date; // currently selected day for questions
  @Output() questionsChange = new EventEmitter();

  constructor(
    private coreLayoutService: CoreLayoutService,
    private toastService: ToastrService,
    private questionsService: QuestionsService,
    private translateService: TranslateService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
  }

  openQuestion() {
    this.coreLayoutService.openSidebar(AddQuestionComponent, {
      country_uniqid: this.question.country_uniqid,
      block: this.block,
      question: this.question,
      currentDate: this.currentDate,
      isEditableFromBlockData: this.isEditable
    });
  }

  duplicateQuestion() {
    this.questionsService.duplicateQuestion(this.question).subscribe((res: ResponseModel) => {
      this.toastService.info(this.translateService.instant('DUPLICATED'));
      this.questionsChange.emit('');
    });
  }

  removeQuestion() {
    this.modalService.open({title: 'ARE_YOU_SURE.?',
      btnOkText: 'YES',
      btnCancelText: 'CANCEL',
      showBtnClose: false,
      showCancelBtn: true,
      customClass: 'confirm-modal',
      message: 'ARE_YOU_SURE_DELETE.?'
    }).then((data) => {
    if (data) {
      // this.answer.nr_comments--; non serve qui
      this.questionsService.deleteQuestion(this.question).subscribe((res: ResponseModel) => {
        this.toastService.info(this.translateService.instant('REMOVED'));
        this.questionsChange.emit('');
          });
        }
    });
  }

}
