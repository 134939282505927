import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { LanguagesService } from '@app/shared/services/languages.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-shared-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  version = null
  constructor(
    private languagesService: LanguagesService,
    private http: HttpClient
  ) { }
  ngOnInit() {
    this.http.get('./assets/version.json').subscribe((data: any) => {
      this.version = data.version
    })
  }

  openWebPage() {
    window.open(this.languagesService.getWebPageUrl());
  }

}
