<div id="dashboard-wrapper">
    <app-private-dash-researches></app-private-dash-researches>
    <div class="quotes-up">
        <span class="custom-icon text-primary-color icon-quotes-left-hollow" style="font-size: 16.286rem;"></span>
    </div>
    <app-private-dash-interviewedtable *ngIf="!isSpecialCustomer"></app-private-dash-interviewedtable>
    <div class="quotes-down text-right">
        <span class="custom-icon text-primary-color icon-quotes-right-hollow" style="font-size: 16.286rem;"></span>
    </div>
    <app-private-dash-blog *ngIf="!isSpecialCustomer"></app-private-dash-blog>
</div>