<app-shared-sidebar-container>
    <app-shared-sidebar-header>
        <div slot="bar" class="tools-bar d-flex align-items-center">
            <h2 class="mb-0 mr-4"><i class="fal fa-chevron-left clickable mr-2 d-none"></i></h2>
            <div class="tool check mr-5 position-relative variable-filter">
                <i class="fal fa-tag mr-2 fa-lg text-gray"></i>
                <span (click)="variablesFilterShow = !variablesFilterShow" id="variablesFilterShowToggle" animation="true" popoverClass="medium-popover" autoClose="outside" [ngbPopover]="contextMenu" placement="auto">
                    {{ 'FILTER_BY_VARIABLES' | translate }}
                    <b>
                        ({{getFilterCount()}} {{'ACTIVE_FILTERS' | translate }})
                    </b>
                </span>

                <div [hidden]="!variablesFilterShow" class="tool-btn menu" >
                    <ng-template #contextMenu>
                        <ng-scrollbar>
                        <div class="row p-2">
                            <div class="col-6">
                                <div class="mb-3">
                                    <b>{{'SAMPLE_VARIABLE_TAGS' | translate }}</b>
                                </div>
                                <div>
                                    <ul >
                                        <li (click)="!room.sample_variable_tags?.includes(tag) ? selectSampleVariableTag(tag) : null"
                                        [ngClass]="{'selected': selectedSampleVariableTags?.includes(tag), 'readonly': room.sample_variable_tags?.includes(tag)}"
                                        *ngFor="let tag of sampleVariableTags">
                                            {{ tag }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="mb-3">
                                    <b>{{'PERSONAS_SHORT' | translate }}</b>
                                </div>
                                <div>
                                    <ul>
                                        <li *ngFor="let personasTag of personasTags"
                                        (click)="!room.sample_variable_tags?.includes(personasTag) ? selectPersonasTag(personasTag) : null"
                                        [ngClass]="{'selected': selectedPersonasTags?.includes(personasTag)}"
                                        >
                                            {{personasTag}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </ng-scrollbar>
                        <div class="row">
                            <div class="col-12 d-flex popover-footer">
                                <span class="ml-0 info-tooltip text-black" placement="bottom" [ngbTooltip]="'MODERATION_FILTER_TOOLTIP' | translate">
                                    <i class="far fa-info-circle"></i>
                                </span>
                                <div class="ml-auto">
                                    <a class="pr-4" (click)="resetFilters()">{{'RESET' | translate}}</a>
                                    <a class="" (click)="storeFilters()">{{'SAVE' | translate}}</a>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>

            </div>
            <div class="tool mr-5" *ngIf="hasQuotes && !isCustomerPro">
                <div class="switch mr-1">
                    <input id="switch" type="checkbox" [ngModel]="answersModalSupportService.activeQuotes$.value" (change)="activeQuotesSwitch($event.target.checked)">
                    <span (click)="activeQuotesSwitch(!answersModalSupportService.activeQuotes$.value)" class="slider round"></span>
                    <label for="switch">{{ 'ACTIVATE_QUOTES' | translate }}</label>
                </div>

            </div>
            <div class="tool search mr-5 flex-grow-1" *ngIf="hasVideos">
                <div class="form-group mb-0">
                    <label>
                        <input type="text" [ngModel]="findInVideo" class="form-control" (keyup)="findInVideosChange($event)">
                        <span class="placeholder">{{ 'FIND_IN_VIDEOS' | translate }}</span>
                    </label>
                    <i class="far fa-search ml-3"></i>
                </div>
            </div>
            <div class="tool search mr-5 flex-grow-1" *ngIf="hasVocals">
                <div class="form-group mb-0">
                    <label>
                        <input type="text" [ngModel]="findInVocal" class="form-control" (keyup)="findInVocalsChange($event)">
                        <span class="placeholder">{{ 'FIND_IN_VOCALS' | translate }}</span>
                    </label>
                    <i class="far fa-search ml-3"></i>
                </div>
            </div>
            <div *ngIf="moderationHelperService.backButtonInSideModal.value" class="tool search mr-5 flex-grow-1">
                <div class="go-back float-right">
                    <i (click)="moderationHelperService.backButtonInSideModal.value()" class="far fa-chevron-left"></i>
                </div>
            </div>
        </div>
    </app-shared-sidebar-header>
    <div class="sidebar-body">
        <app-core-spinner [toggle]="loading"></app-core-spinner>
        <div *ngIf="!loading">
            <!--<div class="alert alert-warning mt-5" *ngIf="!answer.length">{{ 'NO_ANSWERS' | translate }}</div>-->
            <app-moderation-answers-group-wrapper
                [sampleVariableTags]="sampleVariableTagsQuestion"
                [question]="question"
                [answer]="answer"></app-moderation-answers-group-wrapper>
        </div>
    </div>
  </app-shared-sidebar-container>
