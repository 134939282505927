// given a birthdate (following 'new Date' format), it returns the age

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dateToAge'
})
export class DateToAgePipe implements PipeTransform {

  getAge(dateString) {
    const today = new Date();
    const birthDate = new Date(dateString);
    const m = today.getUTCMonth() - birthDate.getUTCMonth();

    let age = today.getUTCFullYear() - birthDate.getUTCFullYear();

    if (m < 0 || (m === 0 && today.getUTCDate() < birthDate.getUTCDate())) {
      age--;
    }

    return age;
  }

  transform(value: string, args?: any): any {
    return this.getAge(value);
  }
}
