import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { catchError, map } from 'rxjs/operators';
import { User } from '../models/user.model';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { ResponseModel } from '@app/core/models/response.model';
import { TeamModel } from '../models/team.model';
import { MessageModel } from '../models/message.model';
import { count } from 'console';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeamService extends BaseService implements BaseServiceI {
  // tslint:disable: variable-name
  constructor(public router: Router, public apiService: ApiService, private http: HttpClient) {
    super(router, apiService, TeamModel);
  }
  currentUser$: TeamModel;
  user$: BehaviorSubject<TeamModel> = new BehaviorSubject(null);

  set currentUser(role: TeamModel) {
    this.currentUser$ = role;
    this.user$.next(this.currentUser$);
  }

  get currentUser() {
    return this.currentUser$;
  }

  getCurrentUserRole() {
    if (this.currentUser) {
      return this.currentUser.role;
    }
    return false;
  }

  isCurrentUserCustomer() {
    return (this.getCurrentUserRole() === 'CUSTOMER_BASIC' || this.getCurrentUserRole() === 'CUSTOMER_PRO') ? true : false;
  }
  isCurrentUserCustomerBasic() {
    return (this.getCurrentUserRole() === 'CUSTOMER_BASIC') ? true : false;

  }
  isCurrentUserCustomerPro() {
    return (this.getCurrentUserRole() === 'CUSTOMER_PRO') ? true : false;
  }

  resetCurrentUser() {
    this.currentUser = null;
  }

  getUsers(
    {
      country_uniqid = null,
      roomid = null,
      sort_attribute = null,
      sort_order = null,
      role = null,
      include_stats_answer_to_read = false,
      blockid = null,
      sample_variable_tags_by_comma = null,
      personas_tags_by_comma = null
    }
  ) {
    const data = {
      country_uniqid,
      roomid,
      sort_attribute,
      sort_order,
      role,
      include_stats_answer_to_read,
      blockid,
      sample_variable_tags_by_comma,
      personas_tags_by_comma
    };
    Object.keys(data).forEach((key) => (!data[key]) && delete data[key]);
    return this.get('', data);
    // http://sicche-mrs.sinapto.tech/api/sicche/teams/null?country_uniqid=5ea70293dd136it001&roomid=142&sort_attribute=null&sort_order=null
    // return this.apiService.get('/teams', data);
  }

  getCurrentUserTeam(country_uniqid) {
    if (country_uniqid) {
      return this.get('current_user', {country_uniqid});
    }
  }

  // tslint:disable: max-line-length
  getUserMonitoring(country_uniqid, sort_attribute = 'email', sort_order = 'asc', sample_variable_tags = null, roomid = null, excel = false) {
    const data = {country_uniqid, role: 'INTERVIEWED', sort_attribute, sort_order, 'sample_variable_tags_by_comma': sample_variable_tags, include_stats_monitoring: true, roomid, xls_stats_monitoring: excel};
    Object.keys(data).forEach((key) => (!data[key]) && delete data[key]);

    if (excel) {
      return this.getDownload('', data);
    }
    return this.get('', data);
  }



  findUsers(country_uniqid = null, roomid = null, sort_attribute = null, sort_order = null, role = null, include_stats_monitoring = null) {
    const data = {country_uniqid, roomid, sort_attribute, sort_order, role, include_stats_monitoring};
    Object.keys(data).forEach((key) => (!data[key]) && delete data[key]);
    return this.find({page_index: 0, page_size: 999999, filter: data});
  }

  findUsersThatAnswered(question_id: number) {
    return this.customCall('question-management/questions/' + question_id+ '/teams').get();
  }

  sendMessage(msg: MessageModel) {
    let url = 'send_message';
    if (msg.isCustomer) {
      url = 'send_invite_to_customer'
    }
    return this.sub(url).create(msg);
  }

  sendTestMessage(msg: MessageModel) {
    return this.sub('send_test_message').create(msg);
  }

  cancelMessage(msg: MessageModel) {
    return this.sub('cancel_message').create(msg);
  }

  addUsersByExcel(file, filekey, body) {
    return this.postFile('add_users_by_excel', file, filekey, body);
  }

  createNewPassword(user_ids, country_uniqid) {
      const data = {
        user_ids,
        country_uniqid
      };
      return this.sub('create_new_password').create(data);
  }

  deleteFromCountries(user_ids, country_uniqid) {
    const data = {
        user_ids,
        country_uniqid
    };
    return this.sub('delete_from_countries').create(data);
  }

  deleteFromRooms(user_ids, roomid) {
    const data = {
        user_ids,
        roomid
    };
    return this.sub('delete_from_rooms').create(data);
  }

  addModifyUser(user: TeamModel) {
    return this.sub('add_update_user').create(user);
  }

  autocompleteUsers(email = null, nickname = null) {
    const data = {email, nickname};
    Object.keys(data).forEach((key) => (!data[key]) && delete data[key]);
    return this.get('autocomplete', data);
  }

  autocompleteUsersByNickname(nickname: string, country_uniqid: string, role = 'INTERVIEWED') {
    const data = {nickname, country_uniqid, role};
    return this.get('autocomplete-nickname-country', data);
  }

  lockUser(user: TeamModel) {
    return this.addModifyUser(new TeamModel({
      id: user.userid,
      email: user.email,
      country_uniqid: user.country_uniqid,
      is_locked: true,
      role: user.role
    }));
  }

  unLockUser(user: TeamModel) {
    return this.addModifyUser(new TeamModel({
      id: user.userid,
      email: user.email,
      country_uniqid: user.country_uniqid,
      is_locked: false,
      role: user.role
    }));
  }

  getMessages(country_uniqid, user_ids = null, sample_variable_tags = null) {
    const data = {country_uniqid, 'user_ids[]': user_ids, 'sample_variable_tags[]': sample_variable_tags};
    Object.keys(data).forEach((key) => (!data[key] || data[key].length === 0) && delete data[key]);
    return this.get('get_messages', data);
  }

  editMessage(msg: MessageModel) {
    return this.sub('messages/' + msg.messageid).create(msg);
  }

  getFilteredUserIds(country_uniqid, sample_variable_tags, filter, filter_value = null, roomid = null) {
    const query: any = {country_uniqid, role: 'INTERVIEWED'};
    if (sample_variable_tags && sample_variable_tags.length > 0) {
      query['match_all_sample_variable_tags[]'] = sample_variable_tags;
    }
    if (roomid) {
      query.roomid = roomid;
    }
    if (filter === 'filter_missing_answers_for_country') {
      query.filter_missing_answers_for_country = country_uniqid;
    }
    if (filter === 'filter_no_answers_given_for_country') {
      query.filter_no_answers_given_for_country = country_uniqid;
    }
    if (filter === 'filter_never_logged') {
      query.filter_never_logged = true;
    }
    if (filter === 'filter_have_not_logged_since_hours') {
      query.filter_have_not_logged_since_hours = filter_value;
    }

    return this.get('autocomplete', query);
  }

  autocompleteNickname(country_uniqid: string, nickname: string, role: string) {
    return this.sub('autocomplete-nickname-country').get(null, {country_uniqid, nickname, role});
  }

  autocompleteBySampleVariableTags(country_uniqid, sample_variable_tags) {
    return this.get('autocomplete', {country_uniqid, 'sample_variable_tags[]': sample_variable_tags});
  }

  updateRoomUsers(user_ids: string[], roomid, clean_previous = true) {
    return this.sub('add_to_rooms').create({user_ids, roomid, clean_previous});
  }

  sendMissingAnswersEmail(msg: any) {
    return this.sub('send_missing_answers_email').create(msg);
  }

  getQuestionsWithMultipleAnswers(country_uniqid, roomid = null) {
    const data: any = {country_uniqid}
    if (roomid) {
      data.roomid = roomid
    }
    return this.get('team-questions-with-multiple-answers', data)
  }

}
