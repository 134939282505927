import { BaseModel } from '@app/core/models/base';
import { TeamModel } from './team.model';

/**
 * User model class
 */

export class NoticeModel extends BaseModel {

    // tslint:disable: variable-name
    // tslint:disable: variable-name
    id?: number;
    creationtime: Date;
    type: string; // MENTION / COMMENT / LIKE
    roomid: number;
    blockid: number;
    questionid: number;
    commentid?: number;
    answerid: number;
    from_team: TeamModel;
    to_userid: number;
    to_role: string;
    country_uniqid: string;
    research_uniqid: string;
    read: boolean;

    test?:boolean; // system var

    static getResourceName() {
        return 'current-user/manage-notifications';
    }


}
