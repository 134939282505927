import { Component, OnInit, ViewChild, AfterViewInit, Input, EventEmitter, Output } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { QuestionsService } from '@app/shared/services/questions.service';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { AnswersService } from '@app/shared/services/answers.service';
import { ResponseModel } from '@app/core/models/response.model';
import { AnswerModel } from '@app/shared/models/answer.model';
import { AuthService } from '@app/core/services/auth.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { TeamService } from '@app/shared/services/team.service';
import { TeamModel } from '@app/shared/models/team.model';
import { QuoteModel } from '@app/shared/models/quote.model';
import { AnswersModalSupportService } from '@app/shared/services/support/answers-modal.service';
import { ModalService } from '@app/core/services/modal.service';
import { LightboxModalComponent } from '@app/core/components/lightbox-modal/lightbox-modal.component';
import { CountryService } from '@app/shared/services/country.service';
import { ModerationHelperService } from '@app/shared/services/moderation-helper.service';
import { NotificationService } from '@app/shared/services/notification.service';
import { QuotesMinimumWords } from '../../../../../../../../../../../setup';
@Component({
  selector: 'app-moderation-answer-block',
  templateUrl: './answer-block.component.html',
  styleUrls: ['./answer-block.component.scss']
})
export class AnswersBlockComponent extends CoreBaseComponent implements OnInit {

  @Input()
  question: QuestionModel;
  @Output()
  replyPress: EventEmitter<any> = new EventEmitter(null);
  @Input()
  item: AnswerModel; // answer
  @Input()
  intervieweds: AnswerModel; // answer
  @Input()
  personasTags: Array<any>; // answer
  @Input()
  set contentTags(value)  {
    this.contentTagsList = value;
    this.contentTagsReceived = true;
  }
  public contentTagsReceived = false;
  public contentTagsList = null;
  public mediaTab = null;
  private activeQuotes = false;
  public deleted = false;
  public selectedText = null;
  public showSelectionModal = false;
  public questions: QuestionModel[] = [];
  public comments = [];
  public itemToModify: number;
  public settingAsRead: boolean = false;
  public form: UntypedFormGroup;
  public message = null;
  public highlighted = false;
  // public personasTags: Array<any> = null;
  public personasForm: UntypedFormGroup;
  public isCustomerPro = false;
  public contentTagsForm: UntypedFormGroup;
  public highlightedCommentId = null;
  public quoteExtraData = null;
  public showComments = false;

  constructor(private questionsService: QuestionsService, private coreLayoutService: CoreLayoutService, private translate: TranslateService,
              public answerService: AnswersService, public authService: AuthService, private toastService: ToastrService,
              private teamsService: TeamService, private answersModalSupportService: AnswersModalSupportService,
              public  moderationHelperService: ModerationHelperService, private notificationService: NotificationService,
              private modalService: ModalService, private countryService: CountryService, private teamService: TeamService) {
    super();
  }

  ngOnInit(): void {
    // Get intervieweds
    /*
    this.teamService.findUsers(
      this.item.country_uniqid,
      this.item.roomid,
      null,
      null,
      'INTERVIEWED'
    ).subscribe(res => {
      this.intervieweds = res.getData().filter(user => user.nickname && user.userid != this.item.team.userid ? true : false );
    });
    */
    this.isCustomerPro = this.teamService.isCurrentUserCustomerPro();
    this.personasForm = new UntypedFormGroup({
      personas_tags: new UntypedFormControl(this.item.team.personas_tags ?
        this.item.team.personas_tags.length ? this.item.team.personas_tags[0] : null : null, null),
    });
    this.contentTagsForm = new UntypedFormGroup({
      content_tags: new UntypedFormControl(this.item.answer_tags ?
        this.item.answer_tags.length ? this.item.answer_tags : null : null, null),
    });
    this.answersModalSupportService.activeQuotes$.subscribe(val => {
      this.activeQuotes = val;
    });
    this.moderationHelperService.reloadCommentsOf.subscribe(idAnswer => {
      if(idAnswer === this.item.id) {
        this.loadComments(idAnswer, null, true);
      }
    });
    // Set as read when in viewport
    setTimeout( () => {
      const mainSidebar = document.getElementsByClassName('main-sidebar')[0];
      this.checkAnswerInViewport();
      mainSidebar.addEventListener("scroll", () => {
        this.checkAnswerInViewport();
      });
    }, 1000);
    // When personas tags change for this user (for example in a comment)
    this.moderationHelperService.spreadInOthersAnswersAndComments.subscribe(value => {
      if(value && value.team.userid === this.item.team.userid) {
        if(value.what === 'personasTag') {
          this.personasTagsChange(value.personasTag, true);
        } else if (value.what === 'note') {
          this.notesChanged(value.note);
        }
      }
    });
    // Get personas tags
    /*
    this.countryService.getPersonasTags(this.question.country_uniqid).subscribe((res: ResponseModel) => {
      this.personasTags = res.getData();
    });
    */
    // Serve notification
    this.serveNotification();
    // Auto load comments
    this.loadComments(null, null, true);
  }

  checkAnswerInViewport() {
    if(this.inViewport(
      document.getElementById('message' + this.item.id),
      document.getElementsByClassName('main-sidebar')[0],
      150
    )) {
      if (!this.item.read) {
        this.settingAsRead = true;
        this.answerService.setAsRead(this.item.id).subscribe((res: any) => {
          setTimeout(() => {
            this.settingAsRead = false;
            this.item.read = true;
          }, 2500);
        });
      }
    }
  }

  private serveNotification() {
    const notice = this.notificationService.currentNotice;
    if (notice) {
      if (notice.answerid && !notice.commentid) {
        this.loadComments(null);
        this.highlighted = this.item.id === notice.answerid;
        if (this.highlighted && !notice.commentid) {
          this.notificationService.currentNotice = null;
        }
      }
      if (notice.commentid) {
        this.loadComments(null, () => {
          setTimeout(() => {
            if(this.coreLayoutService.sidebarIsOpen()) {
              document.getElementById('answer-block-comment-' + notice.commentid).scrollIntoView();
            }
          }, 500);
        });
      }
    }
  }

  loadComments(id: number, callback = null, show = false) {
    if(show) {
      this.showComments = true;
    } else {
      this.showComments = !this.showComments;
    }
    if(!this.showComments) {
      return this.comments = [];
    }
    this.highlightedCommentId = null;
    // Get last comment inserted (if exists)
    const lastCommentInserted = this.moderationHelperService.lastCommentInserted.value;
    //if (this.comments.length) {
      //return this.comments = [];
    //}
    this.operation('loading-comments').reset().isSubmitting();
    if(id) {
      this.coreLayoutService.sidebarBlock$.next(true);
      this.answerService.getComments(id, true).subscribe((res: ResponseModel) => {
        this.operation('loading-comments').isSuccess();
        if(lastCommentInserted) {
          // this.highlightedCommentId = lastCommentInserted.id;
        }
        this.comments = res.getData();
        try {
          if(lastCommentInserted) {
            if(lastCommentInserted) {
              setTimeout(() => {
                const commentFound = document.getElementById('answer-block-comment-' + lastCommentInserted.id);
                if(commentFound) {
                  // this.highlightedCommentId = lastCommentInserted.id;
                  this.moderationHelperService.lastCommentInserted.next(null);
                  if(this.coreLayoutService.sidebarIsOpen()) {
                    setTimeout(() => {
                      commentFound.scrollIntoView();
                    }, 100);
                    setTimeout(() => {
                      this.coreLayoutService.sidebarBlock$.next(false)
                    }, 1100);
                  }
                }
              }, 100);
            }
          } else {
            this.coreLayoutService.sidebarBlock$.next(false);
          }
        } catch(e) { }
        if (callback) {
          callback();
        }
    });
    } else {
      this.operation('loading-comments').isSuccess();
      this.comments = this.item.comments;
      try {
        if(lastCommentInserted) {
          if(lastCommentInserted) {
            setTimeout(() => {
              const commentFound = document.getElementById('answer-block-comment-' + lastCommentInserted.id);
              if(commentFound) {
                // this.highlightedCommentId = lastCommentInserted.id;
                this.moderationHelperService.lastCommentInserted.next(null);
                if(this.coreLayoutService.sidebarIsOpen()) {
                  commentFound.scrollIntoView();
                }
              }
            }, 100);
          }
        }
      } catch(e) { }
      if (callback) {
        callback();
      }
    }

    /*

    */


  }

  modifyAnswer(id: number) {
    this.message = {action: 'modify-answer', data: id};
  }

  // T: non set favorite ma mette un like
  setFavourite(status: boolean) {
    this.answerService.updateAnswer(this.item.id, {
      favourite: status
    }).subscribe((res: ResponseModel) => {
      this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
      this.item.favourite = status;
    }, (e) => {
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
    });
  }

  likeAnswer(answer: AnswerModel) {
    if (this.teamService.currentUser$.role === 'CUSTOMER_PRO') {
      return false;
    }
    if (answer.currentuser_like_answer) {
      this.answerService.unlike(answer.id).subscribe((res: ResponseModel) => {
        answer.currentuser_like_answer = false;
        answer.total_like_to_answer--;
      });
    } else {
      this.answerService.like(answer.id).subscribe((res: ResponseModel) => {
        answer.currentuser_like_answer = true;
        answer.total_like_to_answer++;
      });
    }
  }

  tagsChanged(tags: any) {
    tags = tags.map(tag => tag.name ? tag.name.toLowerCase() : tag.toLowerCase());
    this.answerService.updateAnswer(this.item.id, {
      answer_tags: tags.map(tag => tag.name ? tag.name.toLowerCase() : tag.toLowerCase())
    }).subscribe((res: ResponseModel) => {
      this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));

      // Add any new tag to the list of usable tags
      // todo: usa lo stesso metodo per le personas, che è più efficiente
      tags.forEach(tag => {
        if (!this.moderationHelperService.contentTagList.includes(tag)) {
          this.moderationHelperService.contentTagList.push(tag);
        }
      });
      this.moderationHelperService.contentTagList = [...this.moderationHelperService.contentTagList ];
      // Sort by name
      this.moderationHelperService.contentTagList.sort(function(a,b) {
        if (a > b) {
          return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
      });
      this.item.answer_tags = tags;
    }, (e) => {
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
    });
  }

  blockUser() {
    this.modalService.open({title: 'ARE_YOU_SURE_BLOCK_USER.?',
        btnOkText: 'YES',
        btnCancelText: 'CANCEL',
        showBtnClose: false,
        showCancelBtn: true,
        customClass: 'confirm-modal',
        message: 'BLOCK_USER_INFO'
      }).then((data) => {
        if (data) {
          this.teamsService.lockUser(this.item.team).subscribe((res: ResponseModel) => {
            this.item.team.is_locked = true;
            this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
            this.moderationHelperService.teamLockChange.next(this.item.team);
          }, (e) => {
            this.toastService.error(this.translate.instant('ERROR_GENERIC'));
          });
        }
    });
  }

  unlockUser() {
    this.teamsService.unLockUser(this.item.team).subscribe((res: ResponseModel) => {
      this.item.team.is_locked = false;
      this.moderationHelperService.teamLockChange.next(this.item.team);
      this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
    }, (e) => {
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
    });
  }

  deleteAnswer(id: number) {
    this.modalService.open({title: 'ARE_YOU_SURE.?',
        btnOkText: 'YES',
        btnCancelText: 'CANCEL',
        showBtnClose: false,
        showCancelBtn: true,
        customClass: 'confirm-modal',
        message: 'ARE_YOU_SURE_DELETE_ANSWER.?'
      }).then((data) => {
        if (data) {
          this.answerService.deleteAnswer(id).subscribe((res: ResponseModel) => {
            this.deleted = true;
            this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
          }, (e) => {
            this.toastService.error(this.translate.instant('ERROR_GENERIC'));
          });
        }
    });
  }

  reply(answer: AnswerModel, isPrivate: boolean) {
    this.replyPress.emit({ answer, isPrivate });
  }

  quotesModalClose() {
    this.showSelectionModal = false;
  }

  countWords(str) {
    var tag = document.createElement("p");
    tag.setAttribute('id', 'temp-text');
    var text = document.createTextNode(str);
    tag.appendChild(text);
    var textContent = tag.textContent;
    return textContent.split(' ').length;
 }

  selectText(data?) {
    if(data) {
      this.quoteExtraData = data;
    }
    if (this.activeQuotes) {
      this.selectedText = null;
      if (window.getSelection) {
        this.selectedText = window.getSelection().toString();
      }
      // Replace non-breaking-space chars TRICK
      this.selectedText += "";
      var temp = document.createElement('div'), str;
      temp.innerHTML = this.selectedText;
      //@ts-ignore
      str = temp.innerText || temp.textContent || temp.text;
      if(!str) {
        return false;
      }
      var normalizedString = str.split(/\s/);
      temp = null;
      // End replacing non-breaking-space chars TRICK
      if (this.selectedText) {
        const size = normalizedString.length;
        if(size >= QuotesMinimumWords) {
          this.showSelectionModal = true;
        }
      } else {
        this.showSelectionModal = false;
      }
    }
  }

  personasTagsChange(event, justClient = false) {
    // console.log(event);
    /*
    const tags = event.map((tagItem: any) => tagItem.name ? tagItem.name : tagItem);
    const tag = tags[event.length - 1];
    if (tag) {
      this.item.team.personas_tags = [tag];
    } else {
      this.item.team.personas_tags = [];
    }
    */
    this.personasForm.patchValue({
      personas_tags: event ? event.name ? event.name.toLowerCase() : event.toLowerCase() : null
    });
    if(justClient) {
      return this.item.team.personas_tags = [this.personasForm.value.personas_tags];
    }
    const operation = this.operation('saving-personas-tags').reset().isSubmitting();
    this.teamsService.addModifyUser(new TeamModel({
      email: this.item.team.email,
      /*this.item.team.personas_tags ? this.item.team.personas_tags.map((tag: any) => tag.name ? tag.name : tag) : null,*/
      personas_tags: event ? event.name ? event.name.toLowerCase() !== '' ? [event.name.toLowerCase()] : [] : event.toLowerCase() !== '' ? [event.toLowerCase()] : [] : [],
      role: this.item.team.role,
      country_uniqid: this.item.country_uniqid
    })).subscribe((res: ResponseModel) => {
      operation.isSuccess();
      this.moderationHelperService.message$.next('personas-list-applied');
      this.moderationHelperService.spreadInOthersAnswersAndComments.next({what: 'personasTag', team: this.item.team, personasTag: this.personasForm.value.personas_tags});
      this.item.team.personas_tags = res.getData().personas_tags;
      this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
      this.countryService.updatePersonasTags(this.item.country_uniqid);
    }, (e) => {
      operation.isFailed();
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
    });
  }

  notesChanged(value = null) {
    if(value) {
      return this.item.team.note = value;
    }
    const operation = this.operation('saving-note').reset().isSubmitting();
    this.teamsService.addModifyUser(new TeamModel({
      email: this.item.team.email,
      note: this.item.team.note,
      role: this.item.team.role,
      country_uniqid: this.item.country_uniqid
    })).subscribe((res: ResponseModel) => {
      operation.isSuccess();
      this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
      this.moderationHelperService.spreadInOthersAnswersAndComments.next({what: 'note', team: this.item.team, note: this.item.team.note});
    }, (e) => {
      operation.isFailed();
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
    });
  }

}
