import { Injectable, Input } from "@angular/core";
import { CoreBaseComponent } from "@app/core/components/base.component";
import { QuestionModel } from "@app/shared/models/question.model";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

@Injectable()
export abstract class AbstractQuestion extends CoreBaseComponent {

  attachedPdfFiles = [];
  attachedImages = [];
  @Input() question: QuestionModel = null;

  constructor(protected toaster: ToastrService,
              protected translate: TranslateService,
              public activeModal?: NgbActiveModal) {
    super(activeModal);
  }

  initAttachments() {
    if(this.question) {
      if (this.question.question_data) {
        if (this.question.question_data.attached_pdf_files) {
          this.attachedPdfFiles = this.question.question_data.attached_pdf_files;
        }
        if (this.question.question_data.attached_images) {
          this.attachedImages = this.question.question_data.attached_images;
        }
      }
    }
  }

  addPdf(files: FileList) {
    if (files.item(0).type.indexOf('pdf') !== -1) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.attachedPdfFiles = [
          ...this.attachedPdfFiles,
          {
            id: this.generateUniqid(),
            pdf: e.target.result,
            name: files.item(0).name
          }
        ];
      };
      reader.readAsDataURL(files.item(0));
    } else {
      this.toaster.error(this.translate.instant('INVALID'));
    }
  }

  removePdf(id) {
    const temp = [];
    for (const pdf of this.attachedPdfFiles) {
      if (pdf.id !== id) {
        temp.push(pdf);
      }
    }
    this.attachedPdfFiles = temp;
  }

  addImage(files: FileList) {
    if (files.item(0).type.indexOf('image') !== -1) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.attachedImages = [
          ...this.attachedImages,
          {
            id: this.generateUniqid(),
            img: e.target.result,
            name: files.item(0).name
          }
        ];
      };
      reader.readAsDataURL(files.item(0));
    } else {
      this.toaster.error(this.translate.instant('INVALID'));
    }
  }

  removeImage(id) {
    const temp = [];
    for (const img of this.attachedImages) {
      if (img.id !== id) {
        temp.push(img);
      }
    }
    this.attachedImages = temp;
  }

}
