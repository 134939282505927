import { Component, OnInit, Input } from '@angular/core';
import { AnswersService } from '@app/shared/services/answers.service';
import { ToastrService } from 'ngx-toastr';
import { AnswerModel } from '@app/shared/models/answer.model';
import { ResponseModel } from '@app/core/models/response.model';
import { TranslateService } from '@ngx-translate/core';
import { CommentModel } from '@app/shared/models/comment.model';

@Component({
  selector: 'app-moderation-starsrating',
  templateUrl: './stars-rating.component.html',
  styleUrls: ['./stars-rating.component.scss']
})
export class ModerationStarsRatingComponent implements OnInit {


  @Input()
  answer: AnswerModel;
  @Input()
  comment: CommentModel;
  @Input()
  readonly = false;
  @Input()
  minimal = false;
  @Input()
  exportableVersion = false;

  constructor(public answerService: AnswersService, private toastService: ToastrService, private translate: TranslateService) { }

  ngOnInit() {
  }

  rated(rating: number) {
    if (this.exportableVersion) {
      return;
    }
    if(this.answer) {
      if (this.answer.stars === rating) {
        rating = null;
      }
      this.answerService.updateAnswer(this.answer.id, {
        stars: rating
      }).subscribe((res: ResponseModel) => {
        this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
        this.answer.stars = rating;
      }, (e) => {
        this.toastService.error(this.translate.instant('ERROR_GENERIC'));
      });
    }
    if(this.comment) {
      if (this.comment.stars === rating) {
        rating = null;
      }
      this.answerService.updateComment(this.comment.id, {
        answerid: this.comment.answerid,
        stars: rating,
        commentid: this.comment.id,
        flag_like: this.comment.flag_like
      }).subscribe((res: ResponseModel) => {
        this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
        this.comment.stars = rating;
      }, (e) => {
        this.toastService.error(this.translate.instant('ERROR_GENERIC'));
      });
    }
  }

}
