import { Pipe, PipeTransform } from '@angular/core';
import { DatetimeToDbPipe } from './datetime-to-db.pipe';

@Pipe({
    name: 'datetimeFromDB'
})

export class DatetimeFromDB implements PipeTransform {

    constructor(
        private datetimeToDbPipe: DatetimeToDbPipe
    ) {}

    transform(value: string | Date, serverTimezone, toString = false, ...args: unknown[]): any {
        if (!value) {
            return null;
        }
        if (typeof value === 'string') {
            value = this.parseCompatibilityDate(value);
        }
        return value;
        // const localeTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        // const dateFromServer = new Date(value)

        // const dateFromServerOffset = getOffset(dateFromServer, serverTimezone)
        // const dateFromServerUTC = new Date(dateFromServer.getTime() - dateFromServerOffset)

        // const localeOffset = getOffset(new Date(), localeTimezone)

        // const finalDate = new Date(dateFromServerUTC.getTime() + localeOffset)

        // if (toString) {
        //     return this.datetimeToDbPipe.transform(finalDate)
        // }
        // return finalDate

        // function getOffset(d, tz) {
        //     const a = d.toLocaleString("ja", {timeZone: tz}).split(/[/\s:]/);
        //     a[1]--;
        //     const t1 = Date.UTC.apply(null, a);
        //     const t2 = new Date(d).setMilliseconds(0);
        //     return -(t2 - t1);
        // }

    }

    parseCompatibilityDate(value: string | Date | number): string {
        if (typeof value === 'string') {
          if (value.indexOf('-') !== -1) {
            value = replaceAll(value, '-', '/');
          }
          // @ts-ignore
          if (value.indexOf('+') !== -1) {
            value = value.toString();
            value = value.substring(0, value.indexOf('+'));
          }
        }
        // @ts-ignore
        return value;

        function replaceAll(str, search, replace) {
            return str.replace(new RegExp(search, 'g'), replace);
        }
      }

}
