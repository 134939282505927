import { BaseModel } from '@app/core/models/base';
import { CountryInterface } from './country.interface';


export class CountryModel extends BaseModel implements CountryInterface {

    // tslint:disable: variable-name
    id: number;
    country_code: string;
    language_code: string;
    researchid: string;
    country_uniqid: string;
    title: string;
    timezone: string;
    timezone_locked: boolean;
    is_main_country: boolean;
    logo: any;
    logo_url: string;
    // mandare true per rimuovere logo country
    logo_del: boolean;
    color: string;
    research_status: string;
    research_date_start: Date;
    research_date_end: Date;
    current_user_role: string;
    current_user_access_modify: boolean;
    current_user_access_moderate: boolean;
    current_user_access_message: boolean;
    current_user_access_report: boolean;
    sample_variable_tags: string[];
    email_from_name: string;
    privacy_html: string;
    privacy_user_flag: boolean;
    nr_rooms: number;
    nr_intervieweds: number;
    nr_blocks: number;
    role_permission: any;
    translated: any;

    static getResourceName() {
        return 'countries';
    }

    isClientBasic() {
        if (this.role_permission) {
            if (this.role_permission.filtered_user_role === 'CUSTOMER_BASIC') {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

}
