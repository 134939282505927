import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-core-button-submit',
  templateUrl: './submit.component.html',
  styleUrls: ['./submit.component.scss']
})
export class ButtonSubmitComponent implements OnInit {

  @Input()
  form: UntypedFormGroup;
  @Input()
  toggle: any;
  @Input()
  title: string;
  @Input()
  spinnerDimension = 'small';
  @Output()
  submitCallback: EventEmitter<string> = new EventEmitter();
  @Input()
  classes: string;

  constructor() {
  }

  ngOnInit() {
  }

  submit() {
      this.submitCallback.emit('submitted');
  }

}
