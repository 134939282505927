import { Injectable, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';
import { ToolServiceInterface } from '../models/tool-service.interface';
import { LayoutButtonModel } from '../models/layout/layout-button.model';
import { LayoutButtonInterface } from '../models/layout/layout-button.interface';

/**
 * Sidebar
 * Note: components to render must be declared as EntryComponents
 * Example, open: this.coreLayoutService.openSidebar(ProfileEditComponent, {id: 1});
 */


@Injectable({
  providedIn: 'root'
})
export class CoreLayoutService implements ToolServiceInterface {

  /**
   * !att. if you add properties, don't forget to reset them in the function reset()
   */
  // Setup
  sidebarOpen$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  sidebarBlock$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  classes$: BehaviorSubject<string> = new BehaviorSubject(null);
  sidebarPosition$: BehaviorSubject<string> = new BehaviorSubject('right');
  closeSidebarOnClickBackdrop$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  closeSidebarOnClickOutside$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  buttons$: BehaviorSubject<Array<any>> = new BehaviorSubject(null);
  titleButton$: BehaviorSubject<LayoutButtonModel> = new BehaviorSubject(null);
  title$: BehaviorSubject<string> = new BehaviorSubject(null);

  // Events
  sidebarOnClosed$: BehaviorSubject<any> = new BehaviorSubject(null);

  // Data
  componentToRender$: BehaviorSubject<any> = new BehaviorSubject(null);
  sidebarParams$: BehaviorSubject<any> = new BehaviorSubject(null);
  private closingMessageData: any;

  /**
   * Developer toolbar with functions useful for development
   */
  private devToolbar$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private logger: NGXLogger) {
  }

  /**
   * You can decide whether to show or hide the developer toolbar
   */
  get devToolbar() {
    return this.devToolbar$.value;
  }

  set devToolbar(value: boolean) {
    this.devToolbar$.next(value);
  }

  buttons(buttons: Array<LayoutButtonInterface>) {
    setTimeout(() => {
      this.buttons$.next(buttons ? buttons.map(button => new LayoutButtonModel(button)) : null);
    }, 0);
  }

  closingMessage(data: any) {
    return this.closingMessageData = data;
  }

  getButton(slug: string): LayoutButtonModel {
    return this.buttons$.value.find(button => button.slug ===  slug);
  }

  title(title: string) {
    setTimeout(() => {
      this.title$.next(title);
    }, 0);
  }

  titleButton(button: LayoutButtonInterface) {
    setTimeout(() => {
      this.titleButton$.next(button ? new LayoutButtonModel(button) : null);
    }, 0);
  }

  reset(): CoreLayoutService {
    this.title$.next(null);
    this.closingMessageData = null;
    this.componentToRender$.next(null);
    this.sidebarParams$.next(null);
    this.sidebarOpen$.next(false);
    // this.classes$.next(null);
    return this;
  }

  sidebarIsOpen() {
    return this.sidebarOpen$.value;
  }

  setComponentSidebar(component: any): CoreLayoutService {
      this.componentToRender$.next(component);
      return this;
  }

  setComponentSidebarParams(params: any): CoreLayoutService {
    this.sidebarParams$.next(params);
    return this;
  }

  openSidebar(component?: any, params?: any): CoreLayoutService {
    this.reset();
    if (params) {
      this.setComponentSidebarParams(params);
    }
    if (component) {
        this.setComponentSidebar(component);
    }
    this.sidebarOpen$.next(true);
    return this;
  }

  renderSidebarComponent(component: any, params = null): CoreLayoutService {
    this.setComponentSidebarParams(params);
    this.componentToRender$.next(component);
    return this;
  }

  closeSidebar(): CoreLayoutService {
    this.sidebarOpen$.next(false);
    this.buttons$.next(null);
    this.title$.next(null);
    this.titleButton$.next(null);
    this.sidebarClosed(true);
    this.reset();
    return this;
  }

  // todo BUG viene chiamato 2 volte | fixa il core
  /**
   * sidebarClosed
   * tip: use closingMessageData to send a custom message to the event sidebarOnClosed$ --- closingMessage(data: any)
   */
  sidebarClosed(val): CoreLayoutService {
    this.sidebarOnClosed$.next(this.closingMessageData ? this.closingMessageData : val);
    return this;
  }

}
