
<section id="blog-table">
    <h3>{{ 'BLOG' | translate }}</h3>
    <div class="clearfix"></div>
    <div class="mt-2 container-fluid" *ngIf="baseBlogUrl">
        <div class="row">
            <app-core-spinner [toggle]="operation('getting-news').submitting"></app-core-spinner>
            <div *ngFor="let item of list; let i = index;" class="card col-3">
                <div class="box shadow h-100 d-flex flex-column justify-content-between">
                    <a target="_blank" style="text-decoration: none;" [href]="baseBlogUrl + item.url">
                        <img class="card-img-top" [src]="baseBlogUrl + item.image.url" alt="Card image cap">
                        <div class="card-body d-flex flex-column justify-content-between">
                            <h3 class="font-weight-bold">{{ item.title }}</h3>
                            <p class="light">{{ item.date | date:'dd LLL, yyyy':null:'it-It'}} | {{ item.category.name }}</p>
                            <p class="card-text" [innerHTML]="item.text | truncate:[60, '...']"></p>
                            <span class="text-right">
                                {{ 'READ_ALL' | translate }}&nbsp;
                                <i class="far fa-arrow-right text-primary-color" style="font-size: 13px;"></i>&nbsp;
                            </span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="text-center mt-5">
        <span>
            <a [href]="baseBlogUrl + '/it/blog'">{{ 'READ_ALL_NEWS' | translate }}&nbsp;
            <i class="far fa-arrow-right text-primary-color" style="font-size: 13px;"></i></a>&nbsp;
        </span>
    </div> -->
</section>