import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-core-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  @Input()
  conditionBoolean: boolean = null;
  @Input()
  msgSuccess: string;
  @Input()
  msgDanger: string;
  @Input()
  isSmall = true;

  constructor() { }

  ngOnInit() {
  }

}
