import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageModel } from '@app/shared/models/message.model';
import { TeamService } from '@app/shared/services/team.service';
import { PageModel } from '@app/core/models/page';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { CreateEditMessageComponent } from './create-edit-message/create-edit-message.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponseModel } from '@app/core/models/response.model';
import { DatetimeToDbPipe } from '@app/core/pipes/datetime-to-db.pipe';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { TeamModel } from '@app/shared/models/team.model';
import { CountryModel } from '@app/shared/models/country.model';
import { CountryService } from '@app/shared/services/country.service';
import { ResearchSessionService } from '@app/shared/services/research-session.service';
import { DatetimeFromDB } from '@app/core/pipes/datetime-from-db.pipe';
import { TemplateMessageModel } from '@app/shared/models/template-message.model';
import { TemplateMessageService } from '@app/shared/services/template-messages.service';
import { EditTemplateComponent } from './edit-template/edit-template.component';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent extends CoreBaseComponent implements OnInit, OnDestroy {

  constructor(
    public teamService: TeamService,
    private coreLayoutService: CoreLayoutService,
    private activatedRoute: ActivatedRoute,
    private datetimeToDbPipe: DatetimeToDbPipe,
    private countryService: CountryService,
    private researchSessionService: ResearchSessionService,
    private router: Router,
    private datetimeFromDB: DatetimeFromDB,
    private templateMessageService: TemplateMessageService,
  ) {
    super();
  }

  perPage = 20;
  messagesCount = 0;
  countryUniqid: string;
  country: CountryModel;
  messages: MessageModel[];
  loadingMessages = false;
  showFilters = false;
  filterUserId: string[];
  filterSampleVariableTags: string[];
  autocompleteEmail: TeamModel[];
  users: TeamModel[];

  showTemplateMessages = false;
  templateMessages: TemplateMessageModel[];

  sidebarSub;


  ngOnInit() {
    if (this.countryService.currentCountryIsClientBasic()) {
      return this.router.navigate(['/researches/navigation',
        this.researchSessionService.getResearchUniqId(), this.researchSessionService.getResearchCountryUniqId(), 'analysis']);
    }
    if(this.teamService.isCurrentUserCustomerPro()) {
      return this.router.navigate(['/researches/navigation',
        this.researchSessionService.getResearchUniqId(), this.researchSessionService.getResearchCountryUniqId(), 'moderation']);
    }
    this.countryUniqid = this.activatedRoute.snapshot.parent.paramMap.get('country_uniqid');
    this.sidebarSub = this.coreLayoutService.sidebarOnClosed$.subscribe(() => {
      this.loadMessages();
      this.loadTemplateMessages()

    });

    this.countryService.getCountrySetupInfo(this.countryUniqid).subscribe((res: ResponseModel) => {
      this.country = res.getData();
    });

    this.teamService.getUsers(
      {
        country_uniqid: this.countryUniqid,
        role: 'INTERVIWED'
      }
    ).subscribe((res: ResponseModel) => {
      const temp = res.getData();
      for (const user of temp) {
        user.nicknameAndEmail = (user.nickname || '') + ' - ' + user.email;
      }
      this.users = temp;
    });
    this.loadTemplateMessages()

  }

  loadTemplateMessages() {
    this.templateMessageService.getTemplateMessages().subscribe((res: ResponseModel) => {
      this.templateMessages = res.getData();
    })
  }

  editTemplate(template) {
    this.coreLayoutService.openSidebar(EditTemplateComponent, {template});

  }

  ngOnDestroy(): void {
    if (this.sidebarSub) {
      this.sidebarSub.unsubscribe();
    }
  }

  setUser(user) {
    if (user) {
      this.filterUserId = [user.userid];
    } else {
      this.filterUserId = null;
    }
    this.loadMessages();
  }


  setVariableTags(e) {
    this.filterSampleVariableTags = e;
    this.loadMessages();
  }

  emailAutocomplete(search: string) {
    this.teamService.autocompleteUsersByNickname(search, this.countryUniqid).subscribe((res: ResponseModel) => {
      this.autocompleteEmail = res.getData();
    });
  }

  loadMessages() {
    this.loadingMessages = true;
    this.teamService.getMessages(this.countryUniqid, this.filterUserId, this.filterSampleVariableTags).subscribe((res: ResponseModel) => {
      for (const key in res.getData()) {
        if (Object.prototype.hasOwnProperty.call(res.getData(), key)) {
          const element = res.getData()[key];
          element.date_send = this.datetimeFromDB.transform(element.date_send, this.countryService.getSessionCountry().timezone);
        }
      }
      this.messages = res.getData();
      this.loadingMessages = false;
      this.messagesCount = this.messages.length;
    });
  }

  newMessage() {
    const message = new MessageModel();
    this.coreLayoutService.openSidebar(
      CreateEditMessageComponent,
      {message, countryUniqid: this.countryUniqid, edit: false, country: this.country}
    );
  }

  editMessage(message: MessageModel) {
    this.coreLayoutService.openSidebar(
      CreateEditMessageComponent,
      {message, countryUniqid: this.countryUniqid, edit: message.sent, country: this.country, canSave: true}
    );
  }

  // sendAgain(message: MessageModel) {
  //   // message.date_send = this.datetimeToDbPipe.transform(new Date());
  //   this.coreLayoutService.openSidebar(
  //     CreateEditMessageComponent,
  //     {message, countryUniqid: this.countryUniqid, edit: false, country: this.country}
  //   );
  // }

  printNames(userData) {
    let names = '';
    for (const user of userData) {
      // names += `${user.firstname} ${user.lastname.substring(0, 1)}. ,`;
      names += `${user.nickname}, `;
    }
    return names.substring(0, names.length - 1);
  }

  cancelMessage(message: MessageModel) {
    this.teamService.cancelMessage(message).subscribe((res: ResponseModel) => {
      this.loadMessages();
    });
  }

}
