import { Injectable } from '@angular/core';
import { SearchParams } from '@app/core/models/search-params';
import { Observable, of } from 'rxjs';
import { PageModel } from '@app/core/models/page';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {

  constructor() { }

  dropdownList(searchParams?: SearchParams, useCache: boolean = false): Observable<PageModel> {
    return of(new PageModel(this.values, this.values.length));
  }

  getTimezoneByCountryCode(countryCode: string) {
    const temp = this.values;
    return temp.find(t => t.country_code.toUpperCase() === countryCode.toUpperCase()).value;
  }
  // tslint:disable: member-ordering
  values = [
    {
        name: 'Africa/Abidjan',
        value: 'Africa/Abidjan',
        country_code: 'CI'
    },
    {
        name: 'Africa/Accra',
        value: 'Africa/Accra',
        country_code: 'GH'
    },
    {
        name: 'Africa/Addis_Ababa',
        value: 'Africa/Addis_Ababa',
        country_code: 'ET'
    },
    {
        name: 'Africa/Algiers',
        value: 'Africa/Algiers',
        country_code: 'DZ'
    },
    {
        name: 'Africa/Asmara',
        value: 'Africa/Asmara',
        country_code: 'ER'
    },
    {
        name: 'Africa/Bamako',
        value: 'Africa/Bamako',
        country_code: 'ML'
    },
    {
        name: 'Africa/Bangui',
        value: 'Africa/Bangui',
        country_code: 'CF'
    },
    {
        name: 'Africa/Banjul',
        value: 'Africa/Banjul',
        country_code: 'GM'
    },
    {
        name: 'Africa/Bissau',
        value: 'Africa/Bissau',
        country_code: 'GW'
    },
    {
        name: 'Africa/Blantyre',
        value: 'Africa/Blantyre',
        country_code: 'MW'
    },
    {
        name: 'Africa/Brazzaville',
        value: 'Africa/Brazzaville',
        country_code: 'CG'
    },
    {
        name: 'Africa/Bujumbura',
        value: 'Africa/Bujumbura',
        country_code: 'BI'
    },
    {
        name: 'Africa/Cairo',
        value: 'Africa/Cairo',
        country_code: 'EG'
    },
    {
        name: 'Africa/Casablanca',
        value: 'Africa/Casablanca',
        country_code: 'MA'
    },
    {
        name: 'Africa/Conakry',
        value: 'Africa/Conakry',
        country_code: 'GN'
    },
    {
        name: 'Africa/Dakar',
        value: 'Africa/Dakar',
        country_code: 'SN'
    },
    {
        name: 'Africa/Dar_es_Salaam',
        value: 'Africa/Dar_es_Salaam',
        country_code: 'TZ'
    },
    {
        name: 'Africa/Djibouti',
        value: 'Africa/Djibouti',
        country_code: 'DJ'
    },
    {
        name: 'Africa/Douala',
        value: 'Africa/Douala',
        country_code: 'CM'
    },
    {
        name: 'Africa/El_Aaiun',
        value: 'Africa/El_Aaiun',
        country_code: 'EH'
    },
    {
        name: 'Africa/Freetown',
        value: 'Africa/Freetown',
        country_code: 'SL'
    },
    {
        name: 'Africa/Gaborone',
        value: 'Africa/Gaborone',
        country_code: 'BW'
    },
    {
        name: 'Africa/Harare',
        value: 'Africa/Harare',
        country_code: 'ZW'
    },
    {
        name: 'Africa/Johannesburg',
        value: 'Africa/Johannesburg',
        country_code: 'ZA'
    },
    {
        name: 'Africa/Juba',
        value: 'Africa/Juba',
        country_code: 'SS'
    },
    {
        name: 'Africa/Kampala',
        value: 'Africa/Kampala',
        country_code: 'UG'
    },
    {
        name: 'Africa/Khartoum',
        value: 'Africa/Khartoum',
        country_code: 'SD'
    },
    {
        name: 'Africa/Kigali',
        value: 'Africa/Kigali',
        country_code: 'RW'
    },
    {
        name: 'Africa/Kinshasa',
        value: 'Africa/Kinshasa',
        country_code: 'CD'
    },
    {
        name: 'Africa/Lagos',
        value: 'Africa/Lagos',
        country_code: 'NG'
    },
    {
        name: 'Africa/Libreville',
        value: 'Africa/Libreville',
        country_code: 'GA'
    },
    {
        name: 'Africa/Lome',
        value: 'Africa/Lome',
        country_code: 'TG'
    },
    {
        name: 'Africa/Luanda',
        value: 'Africa/Luanda',
        country_code: 'AO'
    },
    {
        name: 'Africa/Lubumbashi',
        value: 'Africa/Lubumbashi',
        country_code: 'CD'
    },
    {
        name: 'Africa/Lusaka',
        value: 'Africa/Lusaka',
        country_code: 'ZM'
    },
    {
        name: 'Africa/Malabo',
        value: 'Africa/Malabo',
        country_code: 'GQ'
    },
    {
        name: 'Africa/Maputo',
        value: 'Africa/Maputo',
        country_code: 'MZ'
    },
    {
        name: 'Africa/Maseru',
        value: 'Africa/Maseru',
        country_code: 'LS'
    },
    {
        name: 'Africa/Mbabane',
        value: 'Africa/Mbabane',
        country_code: 'SZ'
    },
    {
        name: 'Africa/Mogadishu',
        value: 'Africa/Mogadishu',
        country_code: 'SO'
    },
    {
        name: 'Africa/Monrovia',
        value: 'Africa/Monrovia',
        country_code: 'LR'
    },
    {
        name: 'Africa/Nairobi',
        value: 'Africa/Nairobi',
        country_code: 'KE'
    },
    {
        name: 'Africa/Ndjamena',
        value: 'Africa/Ndjamena',
        country_code: 'TD'
    },
    {
        name: 'Africa/Niamey',
        value: 'Africa/Niamey',
        country_code: 'NE'
    },
    {
        name: 'Africa/Nouakchott',
        value: 'Africa/Nouakchott',
        country_code: 'MR'
    },
    {
        name: 'Africa/Ouagadougou',
        value: 'Africa/Ouagadougou',
        country_code: 'BF'
    },
    {
        name: 'Africa/Porto-Novo',
        value: 'Africa/Porto-Novo',
        country_code: 'BJ'
    },
    {
        name: 'Africa/Sao_Tome',
        value: 'Africa/Sao_Tome',
        country_code: 'ST'
    },
    {
        name: 'Africa/Tripoli',
        value: 'Africa/Tripoli',
        country_code: 'LY'
    },
    {
        name: 'Africa/Tunis',
        value: 'Africa/Tunis',
        country_code: 'TN'
    },
    {
        name: 'Africa/Windhoek',
        value: 'Africa/Windhoek',
        country_code: 'NA'
    },
    {
        name: 'America/Adak',
        value: 'America/Adak',
        country_code: 'US'
    },
    {
        name: 'America/Anchorage',
        value: 'America/Anchorage',
        country_code: 'US'
    },
    {
        name: 'America/Anguilla',
        value: 'America/Anguilla',
        country_code: 'AI'
    },
    {
        name: 'America/Antigua',
        value: 'America/Antigua',
        country_code: 'AG'
    },
    {
        name: 'America/Araguaina',
        value: 'America/Araguaina',
        country_code: 'BR'
    },
    {
        name: 'America/Argentina/Buenos_Aires',
        value: 'America/Argentina/Buenos_Aires',
        country_code: 'AR'
    },
    {
        name: 'America/Argentina/Catamarca',
        value: 'America/Argentina/Catamarca',
        country_code: 'AR'
    },
    {
        name: 'America/Argentina/Cordoba',
        value: 'America/Argentina/Cordoba',
        country_code: 'AR'
    },
    {
        name: 'America/Argentina/Jujuy',
        value: 'America/Argentina/Jujuy',
        country_code: 'AR'
    },
    {
        name: 'America/Argentina/La_Rioja',
        value: 'America/Argentina/La_Rioja',
        country_code: 'AR'
    },
    {
        name: 'America/Argentina/Mendoza',
        value: 'America/Argentina/Mendoza',
        country_code: 'AR'
    },
    {
        name: 'America/Argentina/Rio_Gallegos',
        value: 'America/Argentina/Rio_Gallegos',
        country_code: 'AR'
    },
    {
        name: 'America/Argentina/Salta',
        value: 'America/Argentina/Salta',
        country_code: 'AR'
    },
    {
        name: 'America/Argentina/San_Juan',
        value: 'America/Argentina/San_Juan',
        country_code: 'AR'
    },
    {
        name: 'America/Argentina/San_Luis',
        value: 'America/Argentina/San_Luis',
        country_code: 'AR'
    },
    {
        name: 'America/Argentina/Tucuman',
        value: 'America/Argentina/Tucuman',
        country_code: 'AR'
    },
    {
        name: 'America/Argentina/Ushuaia',
        value: 'America/Argentina/Ushuaia',
        country_code: 'AR'
    },
    {
        name: 'America/Aruba',
        value: 'America/Aruba',
        country_code: 'AW'
    },
    {
        name: 'America/Asuncion',
        value: 'America/Asuncion',
        country_code: 'PY'
    },
    {
        name: 'America/Atikokan',
        value: 'America/Atikokan',
        country_code: 'CA'
    },
    {
        name: 'America/Bahia',
        value: 'America/Bahia',
        country_code: 'BR'
    },
    {
        name: 'America/Bahia_Banderas',
        value: 'America/Bahia_Banderas',
        country_code: 'MX'
    },
    {
        name: 'America/Barbados',
        value: 'America/Barbados',
        country_code: 'BB'
    },
    {
        name: 'America/Belem',
        value: 'America/Belem',
        country_code: 'BR'
    },
    {
        name: 'America/Belize',
        value: 'America/Belize',
        country_code: 'BZ'
    },
    {
        name: 'America/Blanc-Sablon',
        value: 'America/Blanc-Sablon',
        country_code: 'CA'
    },
    {
        name: 'America/Boa_Vista',
        value: 'America/Boa_Vista',
        country_code: 'BR'
    },
    {
        name: 'America/Bogota',
        value: 'America/Bogota',
        country_code: 'CO'
    },
    {
        name: 'America/Boise',
        value: 'America/Boise',
        country_code: 'US'
    },
    {
        name: 'America/Cambridge_Bay',
        value: 'America/Cambridge_Bay',
        country_code: 'CA'
    },
    {
        name: 'America/Campo_Grande',
        value: 'America/Campo_Grande',
        country_code: 'BR'
    },
    {
        name: 'America/Cancun',
        value: 'America/Cancun',
        country_code: 'MX'
    },
    {
        name: 'America/Caracas',
        value: 'America/Caracas',
        country_code: 'VE'
    },
    {
        name: 'America/Cayenne',
        value: 'America/Cayenne',
        country_code: 'GF'
    },
    {
        name: 'America/Cayman',
        value: 'America/Cayman',
        country_code: 'KY'
    },
    {
        name: 'America/Chicago',
        value: 'America/Chicago',
        country_code: 'US'
    },
    {
        name: 'America/Chihuahua',
        value: 'America/Chihuahua',
        country_code: 'MX'
    },
    {
        name: 'America/Costa_Rica',
        value: 'America/Costa_Rica',
        country_code: 'CR'
    },
    {
        name: 'America/Creston',
        value: 'America/Creston',
        country_code: 'CA'
    },
    {
        name: 'America/Cuiaba',
        value: 'America/Cuiaba',
        country_code: 'BR'
    },
    {
        name: 'America/Curacao',
        value: 'America/Curacao',
        country_code: 'CW'
    },
    {
        name: 'America/Danmarkshavn',
        value: 'America/Danmarkshavn',
        country_code: 'GL'
    },
    {
        name: 'America/Dawson',
        value: 'America/Dawson',
        country_code: 'CA'
    },
    {
        name: 'America/Dawson_Creek',
        value: 'America/Dawson_Creek',
        country_code: 'CA'
    },
    {
        name: 'America/Denver',
        value: 'America/Denver',
        country_code: 'US'
    },
    {
        name: 'America/Detroit',
        value: 'America/Detroit',
        country_code: 'US'
    },
    {
        name: 'America/Dominica',
        value: 'America/Dominica',
        country_code: 'DM'
    },
    {
        name: 'America/Edmonton',
        value: 'America/Edmonton',
        country_code: 'CA'
    },
    {
        name: 'America/Eirunepe',
        value: 'America/Eirunepe',
        country_code: 'BR'
    },
    {
        name: 'America/El_Salvador',
        value: 'America/El_Salvador',
        country_code: 'SV'
    },
    {
        name: 'America/Fort_Nelson',
        value: 'America/Fort_Nelson',
        country_code: 'CA'
    },
    {
        name: 'America/Fortaleza',
        value: 'America/Fortaleza',
        country_code: 'BR'
    },
    {
        name: 'America/Glace_Bay',
        value: 'America/Glace_Bay',
        country_code: 'CA'
    },
    {
        name: 'America/Godthab',
        value: 'America/Godthab',
        country_code: 'GL'
    },
    {
        name: 'America/Goose_Bay',
        value: 'America/Goose_Bay',
        country_code: 'CA'
    },
    {
        name: 'America/Grand_Turk',
        value: 'America/Grand_Turk',
        country_code: 'TC'
    },
    {
        name: 'America/Grenada',
        value: 'America/Grenada',
        country_code: 'GD'
    },
    {
        name: 'America/Guadeloupe',
        value: 'America/Guadeloupe',
        country_code: 'GP'
    },
    {
        name: 'America/Guatemala',
        value: 'America/Guatemala',
        country_code: 'GT'
    },
    {
        name: 'America/Guayaquil',
        value: 'America/Guayaquil',
        country_code: 'EC'
    },
    {
        name: 'America/Guyana',
        value: 'America/Guyana',
        country_code: 'GY'
    },
    {
        name: 'America/Halifax',
        value: 'America/Halifax',
        country_code: 'CA'
    },
    {
        name: 'America/Havana',
        value: 'America/Havana',
        country_code: 'CU'
    },
    {
        name: 'America/Hermosillo',
        value: 'America/Hermosillo',
        country_code: 'MX'
    },
    {
        name: 'America/Indiana/Indianapolis',
        value: 'America/Indiana/Indianapolis',
        country_code: 'US'
    },
    {
        name: 'America/Indiana/Knox',
        value: 'America/Indiana/Knox',
        country_code: 'US'
    },
    {
        name: 'America/Indiana/Marengo',
        value: 'America/Indiana/Marengo',
        country_code: 'US'
    },
    {
        name: 'America/Indiana/Petersburg',
        value: 'America/Indiana/Petersburg',
        country_code: 'US'
    },
    {
        name: 'America/Indiana/Tell_City',
        value: 'America/Indiana/Tell_City',
        country_code: 'US'
    },
    {
        name: 'America/Indiana/Vevay',
        value: 'America/Indiana/Vevay',
        country_code: 'US'
    },
    {
        name: 'America/Indiana/Vincennes',
        value: 'America/Indiana/Vincennes',
        country_code: 'US'
    },
    {
        name: 'America/Indiana/Winamac',
        value: 'America/Indiana/Winamac',
        country_code: 'US'
    },
    {
        name: 'America/Inuvik',
        value: 'America/Inuvik',
        country_code: 'CA'
    },
    {
        name: 'America/Iqaluit',
        value: 'America/Iqaluit',
        country_code: 'CA'
    },
    {
        name: 'America/Jamaica',
        value: 'America/Jamaica',
        country_code: 'JM'
    },
    {
        name: 'America/Juneau',
        value: 'America/Juneau',
        country_code: 'US'
    },
    {
        name: 'America/Kentucky/Louisville',
        value: 'America/Kentucky/Louisville',
        country_code: 'US'
    },
    {
        name: 'America/Kentucky/Monticello',
        value: 'America/Kentucky/Monticello',
        country_code: 'US'
    },
    {
        name: 'America/Kralendijk',
        value: 'America/Kralendijk',
        country_code: 'BQ'
    },
    {
        name: 'America/La_Paz',
        value: 'America/La_Paz',
        country_code: 'BO'
    },
    {
        name: 'America/Lima',
        value: 'America/Lima',
        country_code: 'PE'
    },
    {
        name: 'America/Los_Angeles',
        value: 'America/Los_Angeles',
        country_code: 'US'
    },
    {
        name: 'America/Lower_Princes',
        value: 'America/Lower_Princes',
        country_code: 'SX'
    },
    {
        name: 'America/Maceio',
        value: 'America/Maceio',
        country_code: 'BR'
    },
    {
        name: 'America/Managua',
        value: 'America/Managua',
        country_code: 'NI'
    },
    {
        name: 'America/Manaus',
        value: 'America/Manaus',
        country_code: 'BR'
    },
    {
        name: 'America/Marigot',
        value: 'America/Marigot',
        country_code: 'MF'
    },
    {
        name: 'America/Martinique',
        value: 'America/Martinique',
        country_code: 'MQ'
    },
    {
        name: 'America/Matamoros',
        value: 'America/Matamoros',
        country_code: 'MX'
    },
    {
        name: 'America/Mazatlan',
        value: 'America/Mazatlan',
        country_code: 'MX'
    },
    {
        name: 'America/Menominee',
        value: 'America/Menominee',
        country_code: 'US'
    },
    {
        name: 'America/Merida',
        value: 'America/Merida',
        country_code: 'MX'
    },
    {
        name: 'America/Metlakatla',
        value: 'America/Metlakatla',
        country_code: 'US'
    },
    {
        name: 'America/Mexico_City',
        value: 'America/Mexico_City',
        country_code: 'MX'
    },
    {
        name: 'America/Miquelon',
        value: 'America/Miquelon',
        country_code: 'PM'
    },
    {
        name: 'America/Moncton',
        value: 'America/Moncton',
        country_code: 'CA'
    },
    {
        name: 'America/Monterrey',
        value: 'America/Monterrey',
        country_code: 'MX'
    },
    {
        name: 'America/Montevideo',
        value: 'America/Montevideo',
        country_code: 'UY'
    },
    {
        name: 'America/Montserrat',
        value: 'America/Montserrat',
        country_code: 'MS'
    },
    {
        name: 'America/Nassau',
        value: 'America/Nassau',
        country_code: 'BS'
    },
    {
        name: 'America/New_York',
        value: 'America/New_York',
        country_code: 'US'
    },
    {
        name: 'America/Nipigon',
        value: 'America/Nipigon',
        country_code: 'CA'
    },
    {
        name: 'America/Nome',
        value: 'America/Nome',
        country_code: 'US'
    },
    {
        name: 'America/Noronha',
        value: 'America/Noronha',
        country_code: 'BR'
    },
    {
        name: 'America/North_Dakota/Beulah',
        value: 'America/North_Dakota/Beulah',
        country_code: 'US'
    },
    {
        name: 'America/North_Dakota/Center',
        value: 'America/North_Dakota/Center',
        country_code: 'US'
    },
    {
        name: 'America/North_Dakota/New_Salem',
        value: 'America/North_Dakota/New_Salem',
        country_code: 'US'
    },
    {
        name: 'America/Ojinaga',
        value: 'America/Ojinaga',
        country_code: 'MX'
    },
    {
        name: 'America/Panama',
        value: 'America/Panama',
        country_code: 'PA'
    },
    {
        name: 'America/Pangnirtung',
        value: 'America/Pangnirtung',
        country_code: 'CA'
    },
    {
        name: 'America/Paramaribo',
        value: 'America/Paramaribo',
        country_code: 'SR'
    },
    {
        name: 'America/Phoenix',
        value: 'America/Phoenix',
        country_code: 'US'
    },
    {
        name: 'America/Port-au-Prince',
        value: 'America/Port-au-Prince',
        country_code: 'HT'
    },
    {
        name: 'America/Port_of_Spain',
        value: 'America/Port_of_Spain',
        country_code: 'TT'
    },
    {
        name: 'America/Porto_Velho',
        value: 'America/Porto_Velho',
        country_code: 'BR'
    },
    {
        name: 'America/Puerto_Rico',
        value: 'America/Puerto_Rico',
        country_code: 'PR'
    },
    {
        name: 'America/Punta_Arenas',
        value: 'America/Punta_Arenas',
        country_code: 'CL'
    },
    {
        name: 'America/Rainy_River',
        value: 'America/Rainy_River',
        country_code: 'CA'
    },
    {
        name: 'America/Rankin_Inlet',
        value: 'America/Rankin_Inlet',
        country_code: 'CA'
    },
    {
        name: 'America/Recife',
        value: 'America/Recife',
        country_code: 'BR'
    },
    {
        name: 'America/Regina',
        value: 'America/Regina',
        country_code: 'CA'
    },
    {
        name: 'America/Resolute',
        value: 'America/Resolute',
        country_code: 'CA'
    },
    {
        name: 'America/Rio_Branco',
        value: 'America/Rio_Branco',
        country_code: 'BR'
    },
    {
        name: 'America/Santarem',
        value: 'America/Santarem',
        country_code: 'BR'
    },
    {
        name: 'America/Santiago',
        value: 'America/Santiago',
        country_code: 'CL'
    },
    {
        name: 'America/Santo_Domingo',
        value: 'America/Santo_Domingo',
        country_code: 'DO'
    },
    {
        name: 'America/Sao_Paulo',
        value: 'America/Sao_Paulo',
        country_code: 'BR'
    },
    {
        name: 'America/Scoresbysund',
        value: 'America/Scoresbysund',
        country_code: 'GL'
    },
    {
        name: 'America/Sitka',
        value: 'America/Sitka',
        country_code: 'US'
    },
    {
        name: 'America/St_Barthelemy',
        value: 'America/St_Barthelemy',
        country_code: 'BL'
    },
    {
        name: 'America/St_Johns',
        value: 'America/St_Johns',
        country_code: 'CA'
    },
    {
        name: 'America/St_Kitts',
        value: 'America/St_Kitts',
        country_code: 'KN'
    },
    {
        name: 'America/St_Lucia',
        value: 'America/St_Lucia',
        country_code: 'LC'
    },
    {
        name: 'America/St_Thomas',
        value: 'America/St_Thomas',
        country_code: 'VI'
    },
    {
        name: 'America/St_Vincent',
        value: 'America/St_Vincent',
        country_code: 'VC'
    },
    {
        name: 'America/Swift_Current',
        value: 'America/Swift_Current',
        country_code: 'CA'
    },
    {
        name: 'America/Tegucigalpa',
        value: 'America/Tegucigalpa',
        country_code: 'HN'
    },
    {
        name: 'America/Thule',
        value: 'America/Thule',
        country_code: 'GL'
    },
    {
        name: 'America/Thunder_Bay',
        value: 'America/Thunder_Bay',
        country_code: 'CA'
    },
    {
        name: 'America/Tijuana',
        value: 'America/Tijuana',
        country_code: 'MX'
    },
    {
        name: 'America/Toronto',
        value: 'America/Toronto',
        country_code: 'CA'
    },
    {
        name: 'America/Tortola',
        value: 'America/Tortola',
        country_code: 'VG'
    },
    {
        name: 'America/Vancouver',
        value: 'America/Vancouver',
        country_code: 'CA'
    },
    {
        name: 'America/Whitehorse',
        value: 'America/Whitehorse',
        country_code: 'CA'
    },
    {
        name: 'America/Winnipeg',
        value: 'America/Winnipeg',
        country_code: 'CA'
    },
    {
        name: 'America/Yakutat',
        value: 'America/Yakutat',
        country_code: 'US'
    },
    {
        name: 'America/Yellowknife',
        value: 'America/Yellowknife',
        country_code: 'CA'
    },
    {
        name: 'Antarctica/Casey',
        value: 'Antarctica/Casey',
        country_code: 'AQ'
    },
    {
        name: 'Antarctica/Davis',
        value: 'Antarctica/Davis',
        country_code: 'AQ'
    },
    {
        name: 'Antarctica/DumontDUrville',
        value: 'Antarctica/DumontDUrville',
        country_code: 'AQ'
    },
    {
        name: 'Antarctica/Macquarie',
        value: 'Antarctica/Macquarie',
        country_code: 'AU'
    },
    {
        name: 'Antarctica/Mawson',
        value: 'Antarctica/Mawson',
        country_code: 'AQ'
    },
    {
        name: 'Antarctica/McMurdo',
        value: 'Antarctica/McMurdo',
        country_code: 'AQ'
    },
    {
        name: 'Antarctica/Palmer',
        value: 'Antarctica/Palmer',
        country_code: 'AQ'
    },
    {
        name: 'Antarctica/Rothera',
        value: 'Antarctica/Rothera',
        country_code: 'AQ'
    },
    {
        name: 'Antarctica/Syowa',
        value: 'Antarctica/Syowa',
        country_code: 'AQ'
    },
    {
        name: 'Antarctica/Troll',
        value: 'Antarctica/Troll',
        country_code: 'AQ'
    },
    {
        name: 'Antarctica/Vostok',
        value: 'Antarctica/Vostok',
        country_code: 'AQ'
    },
    {
        name: 'Arctic/Longyearbyen',
        value: 'Arctic/Longyearbyen',
        country_code: 'SJ'
    },
    {
        name: 'Asia/Aden',
        value: 'Asia/Aden',
        country_code: 'YE'
    },
    {
        name: 'Asia/Almaty',
        value: 'Asia/Almaty',
        country_code: 'KZ'
    },
    {
        name: 'Asia/Amman',
        value: 'Asia/Amman',
        country_code: 'JO'
    },
    {
        name: 'Asia/Anadyr',
        value: 'Asia/Anadyr',
        country_code: 'RU'
    },
    {
        name: 'Asia/Aqtau',
        value: 'Asia/Aqtau',
        country_code: 'KZ'
    },
    {
        name: 'Asia/Aqtobe',
        value: 'Asia/Aqtobe',
        country_code: 'KZ'
    },
    {
        name: 'Asia/Ashgabat',
        value: 'Asia/Ashgabat',
        country_code: 'TM'
    },
    {
        name: 'Asia/Atyrau',
        value: 'Asia/Atyrau',
        country_code: 'KZ'
    },
    {
        name: 'Asia/Baghdad',
        value: 'Asia/Baghdad',
        country_code: 'IQ'
    },
    {
        name: 'Asia/Bahrain',
        value: 'Asia/Bahrain',
        country_code: 'BH'
    },
    {
        name: 'Asia/Baku',
        value: 'Asia/Baku',
        country_code: 'AZ'
    },
    {
        name: 'Asia/Bangkok',
        value: 'Asia/Bangkok',
        country_code: 'TH'
    },
    {
        name: 'Asia/Barnaul',
        value: 'Asia/Barnaul',
        country_code: 'RU'
    },
    {
        name: 'Asia/Beirut',
        value: 'Asia/Beirut',
        country_code: 'LB'
    },
    {
        name: 'Asia/Bishkek',
        value: 'Asia/Bishkek',
        country_code: 'KG'
    },
    {
        name: 'Asia/Brunei',
        value: 'Asia/Brunei',
        country_code: 'BN'
    },
    {
        name: 'Asia/Chita',
        value: 'Asia/Chita',
        country_code: 'RU'
    },
    {
        name: 'Asia/Choibalsan',
        value: 'Asia/Choibalsan',
        country_code: 'MN'
    },
    {
        name: 'Asia/Colombo',
        value: 'Asia/Colombo',
        country_code: 'LK'
    },
    {
        name: 'Asia/Damascus',
        value: 'Asia/Damascus',
        country_code: 'SY'
    },
    {
        name: 'Asia/Dhaka',
        value: 'Asia/Dhaka',
        country_code: 'BD'
    },
    {
        name: 'Asia/Dili',
        value: 'Asia/Dili',
        country_code: 'TL'
    },
    {
        name: 'Asia/Dubai',
        value: 'Asia/Dubai',
        country_code: 'AE'
    },
    {
        name: 'Asia/Dushanbe',
        value: 'Asia/Dushanbe',
        country_code: 'TJ'
    },
    {
        name: 'Asia/Famagusta',
        value: 'Asia/Famagusta',
        country_code: 'CY'
    },
    {
        name: 'Asia/Gaza',
        value: 'Asia/Gaza',
        country_code: 'PS'
    },
    {
        name: 'Asia/Hebron',
        value: 'Asia/Hebron',
        country_code: 'PS'
    },
    {
        name: 'Asia/Ho_Chi_Minh',
        value: 'Asia/Ho_Chi_Minh',
        country_code: 'VN'
    },
    {
        name: 'Asia/Hong_Kong',
        value: 'Asia/Hong_Kong',
        country_code: 'HK'
    },
    {
        name: 'Asia/Hovd',
        value: 'Asia/Hovd',
        country_code: 'MN'
    },
    {
        name: 'Asia/Irkutsk',
        value: 'Asia/Irkutsk',
        country_code: 'RU'
    },
    {
        name: 'Asia/Jakarta',
        value: 'Asia/Jakarta',
        country_code: 'ID'
    },
    {
        name: 'Asia/Jayapura',
        value: 'Asia/Jayapura',
        country_code: 'ID'
    },
    {
        name: 'Asia/Jerusalem',
        value: 'Asia/Jerusalem',
        country_code: 'IL'
    },
    {
        name: 'Asia/Kabul',
        value: 'Asia/Kabul',
        country_code: 'AF'
    },
    {
        name: 'Asia/Kamchatka',
        value: 'Asia/Kamchatka',
        country_code: 'RU'
    },
    {
        name: 'Asia/Karachi',
        value: 'Asia/Karachi',
        country_code: 'PK'
    },
    {
        name: 'Asia/Kathmandu',
        value: 'Asia/Kathmandu',
        country_code: 'NP'
    },
    {
        name: 'Asia/Khandyga',
        value: 'Asia/Khandyga',
        country_code: 'RU'
    },
    {
        name: 'Asia/Kolkata',
        value: 'Asia/Kolkata',
        country_code: 'IN'
    },
    {
        name: 'Asia/Krasnoyarsk',
        value: 'Asia/Krasnoyarsk',
        country_code: 'RU'
    },
    {
        name: 'Asia/Kuala_Lumpur',
        value: 'Asia/Kuala_Lumpur',
        country_code: 'MY'
    },
    {
        name: 'Asia/Kuching',
        value: 'Asia/Kuching',
        country_code: 'MY'
    },
    {
        name: 'Asia/Kuwait',
        value: 'Asia/Kuwait',
        country_code: 'KW'
    },
    {
        name: 'Asia/Macau',
        value: 'Asia/Macau',
        country_code: 'MO'
    },
    {
        name: 'Asia/Magadan',
        value: 'Asia/Magadan',
        country_code: 'RU'
    },
    {
        name: 'Asia/Makassar',
        value: 'Asia/Makassar',
        country_code: 'ID'
    },
    {
        name: 'Asia/Manila',
        value: 'Asia/Manila',
        country_code: 'PH'
    },
    {
        name: 'Asia/Muscat',
        value: 'Asia/Muscat',
        country_code: 'OM'
    },
    {
        name: 'Asia/Nicosia',
        value: 'Asia/Nicosia',
        country_code: 'CY'
    },
    {
        name: 'Asia/Novokuznetsk',
        value: 'Asia/Novokuznetsk',
        country_code: 'RU'
    },
    {
        name: 'Asia/Novosibirsk',
        value: 'Asia/Novosibirsk',
        country_code: 'RU'
    },
    {
        name: 'Asia/Omsk',
        value: 'Asia/Omsk',
        country_code: 'RU'
    },
    {
        name: 'Asia/Oral',
        value: 'Asia/Oral',
        country_code: 'KZ'
    },
    {
        name: 'Asia/Phnom_Penh',
        value: 'Asia/Phnom_Penh',
        country_code: 'KH'
    },
    {
        name: 'Asia/Pontianak',
        value: 'Asia/Pontianak',
        country_code: 'ID'
    },
    {
        name: 'Asia/Pyongyang',
        value: 'Asia/Pyongyang',
        country_code: 'KP'
    },
    {
        name: 'Asia/Qatar',
        value: 'Asia/Qatar',
        country_code: 'QA'
    },
    {
        name: 'Asia/Qostanay',
        value: 'Asia/Qostanay',
        country_code: 'KZ'
    },
    {
        name: 'Asia/Qyzylorda',
        value: 'Asia/Qyzylorda',
        country_code: 'KZ'
    },
    {
        name: 'Asia/Riyadh',
        value: 'Asia/Riyadh',
        country_code: 'SA'
    },
    {
        name: 'Asia/Sakhalin',
        value: 'Asia/Sakhalin',
        country_code: 'RU'
    },
    {
        name: 'Asia/Samarkand',
        value: 'Asia/Samarkand',
        country_code: 'UZ'
    },
    {
        name: 'Asia/Seoul',
        value: 'Asia/Seoul',
        country_code: 'KR'
    },
    {
        name: 'Asia/Shanghai',
        value: 'Asia/Shanghai',
        country_code: 'CN'
    },
    {
        name: 'Asia/Singapore',
        value: 'Asia/Singapore',
        country_code: 'SG'
    },
    {
        name: 'Asia/Srednekolymsk',
        value: 'Asia/Srednekolymsk',
        country_code: 'RU'
    },
    {
        name: 'Asia/Taipei',
        value: 'Asia/Taipei',
        country_code: 'TW'
    },
    {
        name: 'Asia/Tashkent',
        value: 'Asia/Tashkent',
        country_code: 'UZ'
    },
    {
        name: 'Asia/Tbilisi',
        value: 'Asia/Tbilisi',
        country_code: 'GE'
    },
    {
        name: 'Asia/Tehran',
        value: 'Asia/Tehran',
        country_code: 'IR'
    },
    {
        name: 'Asia/Thimphu',
        value: 'Asia/Thimphu',
        country_code: 'BT'
    },
    {
        name: 'Asia/Tokyo',
        value: 'Asia/Tokyo',
        country_code: 'JP'
    },
    {
        name: 'Asia/Tomsk',
        value: 'Asia/Tomsk',
        country_code: 'RU'
    },
    {
        name: 'Asia/Ulaanbaatar',
        value: 'Asia/Ulaanbaatar',
        country_code: 'MN'
    },
    {
        name: 'Asia/Urumqi',
        value: 'Asia/Urumqi',
        country_code: 'CN'
    },
    {
        name: 'Asia/Ust-Nera',
        value: 'Asia/Ust-Nera',
        country_code: 'RU'
    },
    {
        name: 'Asia/Vientiane',
        value: 'Asia/Vientiane',
        country_code: 'LA'
    },
    {
        name: 'Asia/Vladivostok',
        value: 'Asia/Vladivostok',
        country_code: 'RU'
    },
    {
        name: 'Asia/Yakutsk',
        value: 'Asia/Yakutsk',
        country_code: 'RU'
    },
    {
        name: 'Asia/Yangon',
        value: 'Asia/Yangon',
        country_code: 'MM'
    },
    {
        name: 'Asia/Yekaterinburg',
        value: 'Asia/Yekaterinburg',
        country_code: 'RU'
    },
    {
        name: 'Asia/Yerevan',
        value: 'Asia/Yerevan',
        country_code: 'AM'
    },
    {
        name: 'Atlantic/Azores',
        value: 'Atlantic/Azores',
        country_code: 'PT'
    },
    {
        name: 'Atlantic/Bermuda',
        value: 'Atlantic/Bermuda',
        country_code: 'BM'
    },
    {
        name: 'Atlantic/Cape_Verde',
        value: 'Atlantic/Cape_Verde',
        country_code: 'CV'
    },
    {
        name: 'Atlantic/Faroe',
        value: 'Atlantic/Faroe',
        country_code: 'FO'
    },
    {
        name: 'Atlantic/Madeira',
        value: 'Atlantic/Madeira',
        country_code: 'PT'
    },
    {
        name: 'Atlantic/Reykjavik',
        value: 'Atlantic/Reykjavik',
        country_code: 'IS'
    },
    {
        name: 'Atlantic/South_Georgia',
        value: 'Atlantic/South_Georgia',
        country_code: 'GS'
    },
    {
        name: 'Atlantic/St_Helena',
        value: 'Atlantic/St_Helena',
        country_code: 'SH'
    },
    {
        name: 'Atlantic/Stanley',
        value: 'Atlantic/Stanley',
        country_code: 'FK'
    },
    {
        name: 'Australia/Adelaide',
        value: 'Australia/Adelaide',
        country_code: 'AU'
    },
    {
        name: 'Australia/Brisbane',
        value: 'Australia/Brisbane',
        country_code: 'AU'
    },
    {
        name: 'Australia/Broken_Hill',
        value: 'Australia/Broken_Hill',
        country_code: 'AU'
    },
    {
        name: 'Australia/Currie',
        value: 'Australia/Currie',
        country_code: 'AU'
    },
    {
        name: 'Australia/Darwin',
        value: 'Australia/Darwin',
        country_code: 'AU'
    },
    {
        name: 'Australia/Eucla',
        value: 'Australia/Eucla',
        country_code: 'AU'
    },
    {
        name: 'Australia/Hobart',
        value: 'Australia/Hobart',
        country_code: 'AU'
    },
    {
        name: 'Australia/Lindeman',
        value: 'Australia/Lindeman',
        country_code: 'AU'
    },
    {
        name: 'Australia/Lord_Howe',
        value: 'Australia/Lord_Howe',
        country_code: 'AU'
    },
    {
        name: 'Australia/Melbourne',
        value: 'Australia/Melbourne',
        country_code: 'AU'
    },
    {
        name: 'Australia/Perth',
        value: 'Australia/Perth',
        country_code: 'AU'
    },
    {
        name: 'Australia/Sydney',
        value: 'Australia/Sydney',
        country_code: 'AU'
    },
    {
        name: 'Europe/Amsterdam',
        value: 'Europe/Amsterdam',
        country_code: 'NL'
    },
    {
        name: 'Europe/Andorra',
        value: 'Europe/Andorra',
        country_code: 'AD'
    },
    {
        name: 'Europe/Astrakhan',
        value: 'Europe/Astrakhan',
        country_code: 'RU'
    },
    {
        name: 'Europe/Athens',
        value: 'Europe/Athens',
        country_code: 'GR'
    },
    {
        name: 'Europe/Belgrade',
        value: 'Europe/Belgrade',
        country_code: 'RS'
    },
    {
        name: 'Europe/Berlin',
        value: 'Europe/Berlin',
        country_code: 'DE'
    },
    {
        name: 'Europe/Bratislava',
        value: 'Europe/Bratislava',
        country_code: 'SK'
    },
    {
        name: 'Europe/Brussels',
        value: 'Europe/Brussels',
        country_code: 'BE'
    },
    {
        name: 'Europe/Bucharest',
        value: 'Europe/Bucharest',
        country_code: 'RO'
    },
    {
        name: 'Europe/Budapest',
        value: 'Europe/Budapest',
        country_code: 'HU'
    },
    {
        name: 'Europe/Busingen',
        value: 'Europe/Busingen',
        country_code: 'DE'
    },
    {
        name: 'Europe/Chisinau',
        value: 'Europe/Chisinau',
        country_code: 'MD'
    },
    {
        name: 'Europe/Copenhagen',
        value: 'Europe/Copenhagen',
        country_code: 'DK'
    },
    {
        name: 'Europe/Dublin',
        value: 'Europe/Dublin',
        country_code: 'IE'
    },
    {
        name: 'Europe/Gibraltar',
        value: 'Europe/Gibraltar',
        country_code: 'GI'
    },
    {
        name: 'Europe/Guernsey',
        value: 'Europe/Guernsey',
        country_code: 'GG'
    },
    {
        name: 'Europe/Helsinki',
        value: 'Europe/Helsinki',
        country_code: 'FI'
    },
    {
        name: 'Europe/Isle_of_Man',
        value: 'Europe/Isle_of_Man',
        country_code: 'IM'
    },
    {
        name: 'Europe/Istanbul',
        value: 'Europe/Istanbul',
        country_code: 'TR'
    },
    {
        name: 'Europe/Jersey',
        value: 'Europe/Jersey',
        country_code: 'JE'
    },
    {
        name: 'Europe/Kaliningrad',
        value: 'Europe/Kaliningrad',
        country_code: 'RU'
    },
    {
        name: 'Europe/Kiev',
        value: 'Europe/Kiev',
        country_code: 'UA'
    },
    {
        name: 'Europe/Kirov',
        value: 'Europe/Kirov',
        country_code: 'RU'
    },
    {
        name: 'Europe/Lisbon',
        value: 'Europe/Lisbon',
        country_code: 'PT'
    },
    {
        name: 'Europe/Ljubljana',
        value: 'Europe/Ljubljana',
        country_code: 'SI'
    },
    {
        name: 'Europe/London',
        value: 'Europe/London',
        country_code: 'UK'
    },
    {
        name: 'Europe/Luxembourg',
        value: 'Europe/Luxembourg',
        country_code: 'LU'
    },
    {
        name: 'Europe/Madrid',
        value: 'Europe/Madrid',
        country_code: 'ES'
    },
    {
        name: 'Europe/Malta',
        value: 'Europe/Malta',
        country_code: 'MT'
    },
    {
        name: 'Europe/Mariehamn',
        value: 'Europe/Mariehamn',
        country_code: 'AX'
    },
    {
        name: 'Europe/Minsk',
        value: 'Europe/Minsk',
        country_code: 'BY'
    },
    {
        name: 'Europe/Monaco',
        value: 'Europe/Monaco',
        country_code: 'MC'
    },
    {
        name: 'Europe/Moscow',
        value: 'Europe/Moscow',
        country_code: 'RU'
    },
    {
        name: 'Europe/Oslo',
        value: 'Europe/Oslo',
        country_code: 'NO'
    },
    {
        name: 'Europe/Paris',
        value: 'Europe/Paris',
        country_code: 'FR'
    },
    {
        name: 'Europe/Podgorica',
        value: 'Europe/Podgorica',
        country_code: 'ME'
    },
    {
        name: 'Europe/Prague',
        value: 'Europe/Prague',
        country_code: 'CZ'
    },
    {
        name: 'Europe/Riga',
        value: 'Europe/Riga',
        country_code: 'LV'
    },
    {
        name: 'Europe/Rome',
        value: 'Europe/Rome',
        country_code: 'IT'
    },
    {
        name: 'Europe/Samara',
        value: 'Europe/Samara',
        country_code: 'RU'
    },
    {
        name: 'Europe/San_Marino',
        value: 'Europe/San_Marino',
        country_code: 'SM'
    },
    {
        name: 'Europe/Sarajevo',
        value: 'Europe/Sarajevo',
        country_code: 'BA'
    },
    {
        name: 'Europe/Saratov',
        value: 'Europe/Saratov',
        country_code: 'RU'
    },
    {
        name: 'Europe/Simferopol',
        value: 'Europe/Simferopol',
        country_code: 'UA'
    },
    {
        name: 'Europe/Skopje',
        value: 'Europe/Skopje',
        country_code: 'MK'
    },
    {
        name: 'Europe/Sofia',
        value: 'Europe/Sofia',
        country_code: 'BG'
    },
    {
        name: 'Europe/Stockholm',
        value: 'Europe/Stockholm',
        country_code: 'SE'
    },
    {
        name: 'Europe/Tallinn',
        value: 'Europe/Tallinn',
        country_code: 'EE'
    },
    {
        name: 'Europe/Tirane',
        value: 'Europe/Tirane',
        country_code: 'AL'
    },
    {
        name: 'Europe/Ulyanovsk',
        value: 'Europe/Ulyanovsk',
        country_code: 'RU'
    },
    {
        name: 'Europe/Uzhgorod',
        value: 'Europe/Uzhgorod',
        country_code: 'UA'
    },
    {
        name: 'Europe/Vaduz',
        value: 'Europe/Vaduz',
        country_code: 'LI'
    },
    {
        name: 'Europe/Vatican',
        value: 'Europe/Vatican',
        country_code: 'VA'
    },
    {
        name: 'Europe/Vienna',
        value: 'Europe/Vienna',
        country_code: 'AT'
    },
    {
        name: 'Europe/Vilnius',
        value: 'Europe/Vilnius',
        country_code: 'LT'
    },
    {
        name: 'Europe/Volgograd',
        value: 'Europe/Volgograd',
        country_code: 'RU'
    },
    {
        name: 'Europe/Warsaw',
        value: 'Europe/Warsaw',
        country_code: 'PL'
    },
    {
        name: 'Europe/Zagreb',
        value: 'Europe/Zagreb',
        country_code: 'HR'
    },
    {
        name: 'Europe/Zaporozhye',
        value: 'Europe/Zaporozhye',
        country_code: 'UA'
    },
    {
        name: 'Europe/Zurich',
        value: 'Europe/Zurich',
        country_code: 'CH'
    },
    {
        name: 'Indian/Antananarivo',
        value: 'Indian/Antananarivo',
        country_code: 'MG'
    },
    {
        name: 'Indian/Chagos',
        value: 'Indian/Chagos',
        country_code: 'IO'
    },
    {
        name: 'Indian/Christmas',
        value: 'Indian/Christmas',
        country_code: 'CX'
    },
    {
        name: 'Indian/Cocos',
        value: 'Indian/Cocos',
        country_code: 'CC'
    },
    {
        name: 'Indian/Comoro',
        value: 'Indian/Comoro',
        country_code: 'KM'
    },
    {
        name: 'Indian/Kerguelen',
        value: 'Indian/Kerguelen',
        country_code: 'TF'
    },
    {
        name: 'Indian/Mahe',
        value: 'Indian/Mahe',
        country_code: 'SC'
    },
    {
        name: 'Indian/Maldives',
        value: 'Indian/Maldives',
        country_code: 'MV'
    },
    {
        name: 'Indian/Mauritius',
        value: 'Indian/Mauritius',
        country_code: 'MU'
    },
    {
        name: 'Indian/Mayotte',
        value: 'Indian/Mayotte',
        country_code: 'YT'
    },
    {
        name: 'Indian/Reunion',
        value: 'Indian/Reunion',
        country_code: 'RE'
    },
    {
        name: 'Pacific/Apia',
        value: 'Pacific/Apia',
        country_code: 'WS'
    },
    {
        name: 'Pacific/Auckland',
        value: 'Pacific/Auckland',
        country_code: 'NZ'
    },
    {
        name: 'Pacific/Bougainville',
        value: 'Pacific/Bougainville',
        country_code: 'PG'
    },
    {
        name: 'Pacific/Chatham',
        value: 'Pacific/Chatham',
        country_code: 'NZ'
    },
    {
        name: 'Pacific/Chuuk',
        value: 'Pacific/Chuuk',
        country_code: 'FM'
    },
    {
        name: 'Pacific/Easter',
        value: 'Pacific/Easter',
        country_code: 'CL'
    },
    {
        name: 'Pacific/Efate',
        value: 'Pacific/Efate',
        country_code: 'VU'
    },
    {
        name: 'Pacific/Enderbury',
        value: 'Pacific/Enderbury',
        country_code: 'KI'
    },
    {
        name: 'Pacific/Fakaofo',
        value: 'Pacific/Fakaofo',
        country_code: 'TK'
    },
    {
        name: 'Pacific/Fiji',
        value: 'Pacific/Fiji',
        country_code: 'FJ'
    },
    {
        name: 'Pacific/Funafuti',
        value: 'Pacific/Funafuti',
        country_code: 'TV'
    },
    {
        name: 'Pacific/Galapagos',
        value: 'Pacific/Galapagos',
        country_code: 'EC'
    },
    {
        name: 'Pacific/Gambier',
        value: 'Pacific/Gambier',
        country_code: 'PF'
    },
    {
        name: 'Pacific/Guadalcanal',
        value: 'Pacific/Guadalcanal',
        country_code: 'SB'
    },
    {
        name: 'Pacific/Guam',
        value: 'Pacific/Guam',
        country_code: 'GU'
    },
    {
        name: 'Pacific/Honolulu',
        value: 'Pacific/Honolulu',
        country_code: 'US'
    },
    {
        name: 'Pacific/Kiritimati',
        value: 'Pacific/Kiritimati',
        country_code: 'KI'
    },
    {
        name: 'Pacific/Kosrae',
        value: 'Pacific/Kosrae',
        country_code: 'FM'
    },
    {
        name: 'Pacific/Kwajalein',
        value: 'Pacific/Kwajalein',
        country_code: 'MH'
    },
    {
        name: 'Pacific/Majuro',
        value: 'Pacific/Majuro',
        country_code: 'MH'
    },
    {
        name: 'Pacific/Marquesas',
        value: 'Pacific/Marquesas',
        country_code: 'PF'
    },
    {
        name: 'Pacific/Midway',
        value: 'Pacific/Midway',
        country_code: 'UM'
    },
    {
        name: 'Pacific/Nauru',
        value: 'Pacific/Nauru',
        country_code: 'NR'
    },
    {
        name: 'Pacific/Niue',
        value: 'Pacific/Niue',
        country_code: 'NU'
    },
    {
        name: 'Pacific/Norfolk',
        value: 'Pacific/Norfolk',
        country_code: 'NF'
    },
    {
        name: 'Pacific/Noumea',
        value: 'Pacific/Noumea',
        country_code: 'NC'
    },
    {
        name: 'Pacific/Pago_Pago',
        value: 'Pacific/Pago_Pago',
        country_code: 'AS'
    },
    {
        name: 'Pacific/Palau',
        value: 'Pacific/Palau',
        country_code: 'PW'
    },
    {
        name: 'Pacific/Pitcairn',
        value: 'Pacific/Pitcairn',
        country_code: 'PN'
    },
    {
        name: 'Pacific/Pohnpei',
        value: 'Pacific/Pohnpei',
        country_code: 'FM'
    },
    {
        name: 'Pacific/Port_Moresby',
        value: 'Pacific/Port_Moresby',
        country_code: 'PG'
    },
    {
        name: 'Pacific/Rarotonga',
        value: 'Pacific/Rarotonga',
        country_code: 'CK'
    },
    {
        name: 'Pacific/Saipan',
        value: 'Pacific/Saipan',
        country_code: 'MP'
    },
    {
        name: 'Pacific/Tahiti',
        value: 'Pacific/Tahiti',
        country_code: 'PF'
    },
    {
        name: 'Pacific/Tarawa',
        value: 'Pacific/Tarawa',
        country_code: 'KI'
    },
    {
        name: 'Pacific/Tongatapu',
        value: 'Pacific/Tongatapu',
        country_code: 'TO'
    },
    {
        name: 'Pacific/Wake',
        value: 'Pacific/Wake',
        country_code: 'UM'
    },
    {
        name: 'Pacific/Wallis',
        value: 'Pacific/Wallis',
        country_code: 'WF'
    }
];

  timezones = {
    'America/Adak': '(GMT-10:00) America/Adak (Hawaii-Aleutian Standard Time)',
    'America/Atka': '(GMT-10:00) America/Atka (Hawaii-Aleutian Standard Time)',
    'America/Anchorage': '(GMT-9:00) America/Anchorage (Alaska Standard Time)',
    'America/Juneau': '(GMT-9:00) America/Juneau (Alaska Standard Time)',
    'America/Nome': '(GMT-9:00) America/Nome (Alaska Standard Time)',
    'America/Yakutat': '(GMT-9:00) America/Yakutat (Alaska Standard Time)',
    'America/Dawson': '(GMT-8:00) America/Dawson (Pacific Standard Time)',
    'America/Ensenada': '(GMT-8:00) America/Ensenada (Pacific Standard Time)',
    'America/Los_Angeles': '(GMT-8:00) America/Los_Angeles (Pacific Standard Time)',
    'America/Tijuana': '(GMT-8:00) America/Tijuana (Pacific Standard Time)',
    'America/Vancouver': '(GMT-8:00) America/Vancouver (Pacific Standard Time)',
    'America/Whitehorse': '(GMT-8:00) America/Whitehorse (Pacific Standard Time)',
    'Canada/Pacific': '(GMT-8:00) Canada/Pacific (Pacific Standard Time)',
    'Canada/Yukon': '(GMT-8:00) Canada/Yukon (Pacific Standard Time)',
    'Mexico/BajaNorte': '(GMT-8:00) Mexico/BajaNorte (Pacific Standard Time)',
    'America/Boise': '(GMT-7:00) America/Boise (Mountain Standard Time)',
    'America/Cambridge_Bay': '(GMT-7:00) America/Cambridge_Bay (Mountain Standard Time)',
    'America/Chihuahua': '(GMT-7:00) America/Chihuahua (Mountain Standard Time)',
    'America/Dawson_Creek': '(GMT-7:00) America/Dawson_Creek (Mountain Standard Time)',
    'America/Denver': '(GMT-7:00) America/Denver (Mountain Standard Time)',
    'America/Edmonton': '(GMT-7:00) America/Edmonton (Mountain Standard Time)',
    'America/Hermosillo': '(GMT-7:00) America/Hermosillo (Mountain Standard Time)',
    'America/Inuvik': '(GMT-7:00) America/Inuvik (Mountain Standard Time)',
    'America/Mazatlan': '(GMT-7:00) America/Mazatlan (Mountain Standard Time)',
    'America/Phoenix': '(GMT-7:00) America/Phoenix (Mountain Standard Time)',
    'America/Shiprock': '(GMT-7:00) America/Shiprock (Mountain Standard Time)',
    'America/Yellowknife': '(GMT-7:00) America/Yellowknife (Mountain Standard Time)',
    'Canada/Mountain': '(GMT-7:00) Canada/Mountain (Mountain Standard Time)',
    'Mexico/BajaSur': '(GMT-7:00) Mexico/BajaSur (Mountain Standard Time)',
    'America/Belize': '(GMT-6:00) America/Belize (Central Standard Time)',
    'America/Cancun': '(GMT-6:00) America/Cancun (Central Standard Time)',
    'America/Chicago': '(GMT-6:00) America/Chicago (Central Standard Time)',
    'America/Costa_Rica': '(GMT-6:00) America/Costa_Rica (Central Standard Time)',
    'America/El_Salvador': '(GMT-6:00) America/El_Salvador (Central Standard Time)',
    'America/Guatemala': '(GMT-6:00) America/Guatemala (Central Standard Time)',
    'America/Knox_IN': '(GMT-6:00) America/Knox_IN (Central Standard Time)',
    'America/Managua': '(GMT-6:00) America/Managua (Central Standard Time)',
    'America/Menominee': '(GMT-6:00) America/Menominee (Central Standard Time)',
    'America/Merida': '(GMT-6:00) America/Merida (Central Standard Time)',
    'America/Mexico_City': '(GMT-6:00) America/Mexico_City (Central Standard Time)',
    'America/Monterrey': '(GMT-6:00) America/Monterrey (Central Standard Time)',
    'America/Rainy_River': '(GMT-6:00) America/Rainy_River (Central Standard Time)',
    'America/Rankin_Inlet': '(GMT-6:00) America/Rankin_Inlet (Central Standard Time)',
    'America/Regina': '(GMT-6:00) America/Regina (Central Standard Time)',
    'America/Swift_Current': '(GMT-6:00) America/Swift_Current (Central Standard Time)',
    'America/Tegucigalpa': '(GMT-6:00) America/Tegucigalpa (Central Standard Time)',
    'America/Winnipeg': '(GMT-6:00) America/Winnipeg (Central Standard Time)',
    'Canada/Central': '(GMT-6:00) Canada/Central (Central Standard Time)',
    'Canada/East-Saskatchewan': '(GMT-6:00) Canada/East-Saskatchewan (Central Standard Time)',
    'Canada/Saskatchewan': '(GMT-6:00) Canada/Saskatchewan (Central Standard Time)',
    'Chile/EasterIsland': '(GMT-6:00) Chile/EasterIsland (Easter Is. Time)',
    'Mexico/General': '(GMT-6:00) Mexico/General (Central Standard Time)',
    'America/Atikokan': '(GMT-5:00) America/Atikokan (Eastern Standard Time)',
    'America/Bogota': '(GMT-5:00) America/Bogota (Colombia Time)',
    'America/Cayman': '(GMT-5:00) America/Cayman (Eastern Standard Time)',
    'America/Coral_Harbour': '(GMT-5:00) America/Coral_Harbour (Eastern Standard Time)',
    'America/Detroit': '(GMT-5:00) America/Detroit (Eastern Standard Time)',
    'America/Fort_Wayne': '(GMT-5:00) America/Fort_Wayne (Eastern Standard Time)',
    'America/Grand_Turk': '(GMT-5:00) America/Grand_Turk (Eastern Standard Time)',
    'America/Guayaquil': '(GMT-5:00) America/Guayaquil (Ecuador Time)',
    'America/Havana': '(GMT-5:00) America/Havana (Cuba Standard Time)',
    'America/Indianapolis': '(GMT-5:00) America/Indianapolis (Eastern Standard Time)',
    'America/Iqaluit': '(GMT-5:00) America/Iqaluit (Eastern Standard Time)',
    'America/Jamaica': '(GMT-5:00) America/Jamaica (Eastern Standard Time)',
    'America/Lima': '(GMT-5:00) America/Lima (Peru Time)',
    'America/Louisville': '(GMT-5:00) America/Louisville (Eastern Standard Time)',
    'America/Montreal': '(GMT-5:00) America/Montreal (Eastern Standard Time)',
    'America/Nassau': '(GMT-5:00) America/Nassau (Eastern Standard Time)',
    'America/New_York': '(GMT-5:00) America/New_York (Eastern Standard Time)',
    'America/Nipigon': '(GMT-5:00) America/Nipigon (Eastern Standard Time)',
    'America/Panama': '(GMT-5:00) America/Panama (Eastern Standard Time)',
    'America/Pangnirtung': '(GMT-5:00) America/Pangnirtung (Eastern Standard Time)',
    'America/Port-au-Prince': '(GMT-5:00) America/Port-au-Prince (Eastern Standard Time)',
    'America/Resolute': '(GMT-5:00) America/Resolute (Eastern Standard Time)',
    'America/Thunder_Bay': '(GMT-5:00) America/Thunder_Bay (Eastern Standard Time)',
    'America/Toronto': '(GMT-5:00) America/Toronto (Eastern Standard Time)',
    'Canada/Eastern': '(GMT-5:00) Canada/Eastern (Eastern Standard Time)',
    'America/Caracas': '(GMT-4:-30) America/Caracas (Venezuela Time)',
    'America/Anguilla': '(GMT-4:00) America/Anguilla (Atlantic Standard Time)',
    'America/Antigua': '(GMT-4:00) America/Antigua (Atlantic Standard Time)',
    'America/Aruba': '(GMT-4:00) America/Aruba (Atlantic Standard Time)',
    'America/Asuncion': '(GMT-4:00) America/Asuncion (Paraguay Time)',
    'America/Barbados': '(GMT-4:00) America/Barbados (Atlantic Standard Time)',
    'America/Blanc-Sablon': '(GMT-4:00) America/Blanc-Sablon (Atlantic Standard Time)',
    'America/Boa_Vista': '(GMT-4:00) America/Boa_Vista (Amazon Time)',
    'America/Campo_Grande': '(GMT-4:00) America/Campo_Grande (Amazon Time)',
    'America/Cuiaba': '(GMT-4:00) America/Cuiaba (Amazon Time)',
    'America/Curacao': '(GMT-4:00) America/Curacao (Atlantic Standard Time)',
    'America/Dominica': '(GMT-4:00) America/Dominica (Atlantic Standard Time)',
    'America/Eirunepe': '(GMT-4:00) America/Eirunepe (Amazon Time)',
    'America/Glace_Bay': '(GMT-4:00) America/Glace_Bay (Atlantic Standard Time)',
    'America/Goose_Bay': '(GMT-4:00) America/Goose_Bay (Atlantic Standard Time)',
    'America/Grenada': '(GMT-4:00) America/Grenada (Atlantic Standard Time)',
    'America/Guadeloupe': '(GMT-4:00) America/Guadeloupe (Atlantic Standard Time)',
    'America/Guyana': '(GMT-4:00) America/Guyana (Guyana Time)',
    'America/Halifax': '(GMT-4:00) America/Halifax (Atlantic Standard Time)',
    'America/La_Paz': '(GMT-4:00) America/La_Paz (Bolivia Time)',
    'America/Manaus': '(GMT-4:00) America/Manaus (Amazon Time)',
    'America/Marigot': '(GMT-4:00) America/Marigot (Atlantic Standard Time)',
    'America/Martinique': '(GMT-4:00) America/Martinique (Atlantic Standard Time)',
    'America/Moncton': '(GMT-4:00) America/Moncton (Atlantic Standard Time)',
    'America/Montserrat': '(GMT-4:00) America/Montserrat (Atlantic Standard Time)',
    'America/Port_of_Spain': '(GMT-4:00) America/Port_of_Spain (Atlantic Standard Time)',
    'America/Porto_Acre': '(GMT-4:00) America/Porto_Acre (Amazon Time)',
    'America/Porto_Velho': '(GMT-4:00) America/Porto_Velho (Amazon Time)',
    'America/Puerto_Rico': '(GMT-4:00) America/Puerto_Rico (Atlantic Standard Time)',
    'America/Rio_Branco': '(GMT-4:00) America/Rio_Branco (Amazon Time)',
    'America/Santiago': '(GMT-4:00) America/Santiago (Chile Time)',
    'America/Santo_Domingo': '(GMT-4:00) America/Santo_Domingo (Atlantic Standard Time)',
    'America/St_Barthelemy': '(GMT-4:00) America/St_Barthelemy (Atlantic Standard Time)',
    'America/St_Kitts': '(GMT-4:00) America/St_Kitts (Atlantic Standard Time)',
    'America/St_Lucia': '(GMT-4:00) America/St_Lucia (Atlantic Standard Time)',
    'America/St_Thomas': '(GMT-4:00) America/St_Thomas (Atlantic Standard Time)',
    'America/St_Vincent': '(GMT-4:00) America/St_Vincent (Atlantic Standard Time)',
    'America/Thule': '(GMT-4:00) America/Thule (Atlantic Standard Time)',
    'America/Tortola': '(GMT-4:00) America/Tortola (Atlantic Standard Time)',
    'America/Virgin': '(GMT-4:00) America/Virgin (Atlantic Standard Time)',
    'Antarctica/Palmer': '(GMT-4:00) Antarctica/Palmer (Chile Time)',
    'Atlantic/Bermuda': '(GMT-4:00) Atlantic/Bermuda (Atlantic Standard Time)',
    'Atlantic/Stanley': '(GMT-4:00) Atlantic/Stanley (Falkland Is. Time)',
    'Brazil/Acre': '(GMT-4:00) Brazil/Acre (Amazon Time)',
    'Brazil/West': '(GMT-4:00) Brazil/West (Amazon Time)',
    'Canada/Atlantic': '(GMT-4:00) Canada/Atlantic (Atlantic Standard Time)',
    'Chile/Continental': '(GMT-4:00) Chile/Continental (Chile Time)',
    'America/St_Johns': '(GMT-3:-30) America/St_Johns (Newfoundland Standard Time)',
    'Canada/Newfoundland': '(GMT-3:-30) Canada/Newfoundland (Newfoundland Standard Time)',
    'America/Araguaina': '(GMT-3:00) America/Araguaina (Brasilia Time)',
    'America/Bahia': '(GMT-3:00) America/Bahia (Brasilia Time)',
    'America/Belem': '(GMT-3:00) America/Belem (Brasilia Time)',
    'America/Buenos_Aires': '(GMT-3:00) America/Buenos_Aires (Argentine Time)',
    'America/Catamarca': '(GMT-3:00) America/Catamarca (Argentine Time)',
    'America/Cayenne': '(GMT-3:00) America/Cayenne (French Guiana Time)',
    'America/Cordoba': '(GMT-3:00) America/Cordoba (Argentine Time)',
    'America/Fortaleza': '(GMT-3:00) America/Fortaleza (Brasilia Time)',
    'America/Godthab': '(GMT-3:00) America/Godthab (Western Greenland Time)',
    'America/Jujuy': '(GMT-3:00) America/Jujuy (Argentine Time)',
    'America/Maceio': '(GMT-3:00) America/Maceio (Brasilia Time)',
    'America/Mendoza': '(GMT-3:00) America/Mendoza (Argentine Time)',
    'America/Miquelon': '(GMT-3:00) America/Miquelon (Pierre & Miquelon Standard Time)',
    'America/Montevideo': '(GMT-3:00) America/Montevideo (Uruguay Time)',
    'America/Paramaribo': '(GMT-3:00) America/Paramaribo (Suriname Time)',
    'America/Recife': '(GMT-3:00) America/Recife (Brasilia Time)',
    'America/Rosario': '(GMT-3:00) America/Rosario (Argentine Time)',
    'America/Santarem': '(GMT-3:00) America/Santarem (Brasilia Time)',
    'America/Sao_Paulo': '(GMT-3:00) America/Sao_Paulo (Brasilia Time)',
    'Antarctica/Rothera': '(GMT-3:00) Antarctica/Rothera (Rothera Time)',
    'Brazil/East': '(GMT-3:00) Brazil/East (Brasilia Time)',
    'America/Noronha': '(GMT-2:00) America/Noronha (Fernando de Noronha Time)',
    'Atlantic/South_Georgia': '(GMT-2:00) Atlantic/South_Georgia (South Georgia Standard Time)',
    'Brazil/DeNoronha': '(GMT-2:00) Brazil/DeNoronha (Fernando de Noronha Time)',
    'America/Scoresbysund': '(GMT-1:00) America/Scoresbysund (Eastern Greenland Time)',
    'Atlantic/Azores': '(GMT-1:00) Atlantic/Azores (Azores Time)',
    'Atlantic/Cape_Verde': '(GMT-1:00) Atlantic/Cape_Verde (Cape Verde Time)',
    'Africa/Abidjan': '(GMT+0:00) Africa/Abidjan (Greenwich Mean Time)',
    'Africa/Accra': '(GMT+0:00) Africa/Accra (Ghana Mean Time)',
    'Africa/Bamako': '(GMT+0:00) Africa/Bamako (Greenwich Mean Time)',
    'Africa/Banjul': '(GMT+0:00) Africa/Banjul (Greenwich Mean Time)',
    'Africa/Bissau': '(GMT+0:00) Africa/Bissau (Greenwich Mean Time)',
    'Africa/Casablanca': '(GMT+0:00) Africa/Casablanca (Western European Time)',
    'Africa/Conakry': '(GMT+0:00) Africa/Conakry (Greenwich Mean Time)',
    'Africa/Dakar': '(GMT+0:00) Africa/Dakar (Greenwich Mean Time)',
    'Africa/El_Aaiun': '(GMT+0:00) Africa/El_Aaiun (Western European Time)',
    'Africa/Freetown': '(GMT+0:00) Africa/Freetown (Greenwich Mean Time)',
    'Africa/Lome': '(GMT+0:00) Africa/Lome (Greenwich Mean Time)',
    'Africa/Monrovia': '(GMT+0:00) Africa/Monrovia (Greenwich Mean Time)',
    'Africa/Nouakchott': '(GMT+0:00) Africa/Nouakchott (Greenwich Mean Time)',
    'Africa/Ouagadougou': '(GMT+0:00) Africa/Ouagadougou (Greenwich Mean Time)',
    'Africa/Sao_Tome': '(GMT+0:00) Africa/Sao_Tome (Greenwich Mean Time)',
    'Africa/Timbuktu': '(GMT+0:00) Africa/Timbuktu (Greenwich Mean Time)',
    'America/Danmarkshavn': '(GMT+0:00) America/Danmarkshavn (Greenwich Mean Time)',
    'Atlantic/Canary': '(GMT+0:00) Atlantic/Canary (Western European Time)',
    'Atlantic/Faeroe': '(GMT+0:00) Atlantic/Faeroe (Western European Time)',
    'Atlantic/Faroe': '(GMT+0:00) Atlantic/Faroe (Western European Time)',
    'Atlantic/Madeira': '(GMT+0:00) Atlantic/Madeira (Western European Time)',
    'Atlantic/Reykjavik': '(GMT+0:00) Atlantic/Reykjavik (Greenwich Mean Time)',
    'Atlantic/St_Helena': '(GMT+0:00) Atlantic/St_Helena (Greenwich Mean Time)',
    'Europe/Belfast': '(GMT+0:00) Europe/Belfast (Greenwich Mean Time)',
    'Europe/Dublin': '(GMT+0:00) Europe/Dublin (Greenwich Mean Time)',
    'Europe/Guernsey': '(GMT+0:00) Europe/Guernsey (Greenwich Mean Time)',
    'Europe/Isle_of_Man': '(GMT+0:00) Europe/Isle_of_Man (Greenwich Mean Time)',
    'Europe/Jersey': '(GMT+0:00) Europe/Jersey (Greenwich Mean Time)',
    'Europe/Lisbon': '(GMT+0:00) Europe/Lisbon (Western European Time)',
    'Europe/London': '(GMT+0:00) Europe/London (Greenwich Mean Time)',
    'Africa/Algiers': '(GMT+1:00) Africa/Algiers (Central European Time)',
    'Africa/Bangui': '(GMT+1:00) Africa/Bangui (Western African Time)',
    'Africa/Brazzaville': '(GMT+1:00) Africa/Brazzaville (Western African Time)',
    'Africa/Ceuta': '(GMT+1:00) Africa/Ceuta (Central European Time)',
    'Africa/Douala': '(GMT+1:00) Africa/Douala (Western African Time)',
    'Africa/Kinshasa': '(GMT+1:00) Africa/Kinshasa (Western African Time)',
    'Africa/Lagos': '(GMT+1:00) Africa/Lagos (Western African Time)',
    'Africa/Libreville': '(GMT+1:00) Africa/Libreville (Western African Time)',
    'Africa/Luanda': '(GMT+1:00) Africa/Luanda (Western African Time)',
    'Africa/Malabo': '(GMT+1:00) Africa/Malabo (Western African Time)',
    'Africa/Ndjamena': '(GMT+1:00) Africa/Ndjamena (Western African Time)',
    'Africa/Niamey': '(GMT+1:00) Africa/Niamey (Western African Time)',
    'Africa/Porto-Novo': '(GMT+1:00) Africa/Porto-Novo (Western African Time)',
    'Africa/Tunis': '(GMT+1:00) Africa/Tunis (Central European Time)',
    'Africa/Windhoek': '(GMT+1:00) Africa/Windhoek (Western African Time)',
    'Arctic/Longyearbyen': '(GMT+1:00) Arctic/Longyearbyen (Central European Time)',
    'Atlantic/Jan_Mayen': '(GMT+1:00) Atlantic/Jan_Mayen (Central European Time)',
    'Europe/Amsterdam': '(GMT+1:00) Europe/Amsterdam (Central European Time)',
    'Europe/Andorra': '(GMT+1:00) Europe/Andorra (Central European Time)',
    'Europe/Belgrade': '(GMT+1:00) Europe/Belgrade (Central European Time)',
    'Europe/Berlin': '(GMT+1:00) Europe/Berlin (Central European Time)',
    'Europe/Bratislava': '(GMT+1:00) Europe/Bratislava (Central European Time)',
    'Europe/Brussels': '(GMT+1:00) Europe/Brussels (Central European Time)',
    'Europe/Budapest': '(GMT+1:00) Europe/Budapest (Central European Time)',
    'Europe/Copenhagen': '(GMT+1:00) Europe/Copenhagen (Central European Time)',
    'Europe/Gibraltar': '(GMT+1:00) Europe/Gibraltar (Central European Time)',
    'Europe/Ljubljana': '(GMT+1:00) Europe/Ljubljana (Central European Time)',
    'Europe/Luxembourg': '(GMT+1:00) Europe/Luxembourg (Central European Time)',
    'Europe/Madrid': '(GMT+1:00) Europe/Madrid (Central European Time)',
    'Europe/Malta': '(GMT+1:00) Europe/Malta (Central European Time)',
    'Europe/Monaco': '(GMT+1:00) Europe/Monaco (Central European Time)',
    'Europe/Oslo': '(GMT+1:00) Europe/Oslo (Central European Time)',
    'Europe/Paris': '(GMT+1:00) Europe/Paris (Central European Time)',
    'Europe/Podgorica': '(GMT+1:00) Europe/Podgorica (Central European Time)',
    'Europe/Prague': '(GMT+1:00) Europe/Prague (Central European Time)',
    'Europe/Rome': '(GMT+1:00) Europe/Rome (Central European Time)',
    'Europe/San_Marino': '(GMT+1:00) Europe/San_Marino (Central European Time)',
    'Europe/Sarajevo': '(GMT+1:00) Europe/Sarajevo (Central European Time)',
    'Europe/Skopje': '(GMT+1:00) Europe/Skopje (Central European Time)',
    'Europe/Stockholm': '(GMT+1:00) Europe/Stockholm (Central European Time)',
    'Europe/Tirane': '(GMT+1:00) Europe/Tirane (Central European Time)',
    'Europe/Vaduz': '(GMT+1:00) Europe/Vaduz (Central European Time)',
    'Europe/Vatican': '(GMT+1:00) Europe/Vatican (Central European Time)',
    'Europe/Vienna': '(GMT+1:00) Europe/Vienna (Central European Time)',
    'Europe/Warsaw': '(GMT+1:00) Europe/Warsaw (Central European Time)',
    'Europe/Zagreb': '(GMT+1:00) Europe/Zagreb (Central European Time)',
    'Europe/Zurich': '(GMT+1:00) Europe/Zurich (Central European Time)',
    'Africa/Blantyre': '(GMT+2:00) Africa/Blantyre (Central African Time)',
    'Africa/Bujumbura': '(GMT+2:00) Africa/Bujumbura (Central African Time)',
    'Africa/Cairo': '(GMT+2:00) Africa/Cairo (Eastern European Time)',
    'Africa/Gaborone': '(GMT+2:00) Africa/Gaborone (Central African Time)',
    'Africa/Harare': '(GMT+2:00) Africa/Harare (Central African Time)',
    'Africa/Johannesburg': '(GMT+2:00) Africa/Johannesburg (South Africa Standard Time)',
    'Africa/Kigali': '(GMT+2:00) Africa/Kigali (Central African Time)',
    'Africa/Lubumbashi': '(GMT+2:00) Africa/Lubumbashi (Central African Time)',
    'Africa/Lusaka': '(GMT+2:00) Africa/Lusaka (Central African Time)',
    'Africa/Maputo': '(GMT+2:00) Africa/Maputo (Central African Time)',
    'Africa/Maseru': '(GMT+2:00) Africa/Maseru (South Africa Standard Time)',
    'Africa/Mbabane': '(GMT+2:00) Africa/Mbabane (South Africa Standard Time)',
    'Africa/Tripoli': '(GMT+2:00) Africa/Tripoli (Eastern European Time)',
    'Asia/Amman': '(GMT+2:00) Asia/Amman (Eastern European Time)',
    'Asia/Beirut': '(GMT+2:00) Asia/Beirut (Eastern European Time)',
    'Asia/Damascus': '(GMT+2:00) Asia/Damascus (Eastern European Time)',
    'Asia/Gaza': '(GMT+2:00) Asia/Gaza (Eastern European Time)',
    'Asia/Istanbul': '(GMT+2:00) Asia/Istanbul (Eastern European Time)',
    'Asia/Jerusalem': '(GMT+2:00) Asia/Jerusalem (Israel Standard Time)',
    'Asia/Nicosia': '(GMT+2:00) Asia/Nicosia (Eastern European Time)',
    'Asia/Tel_Aviv': '(GMT+2:00) Asia/Tel_Aviv (Israel Standard Time)',
    'Europe/Athens': '(GMT+2:00) Europe/Athens (Eastern European Time)',
    'Europe/Bucharest': '(GMT+2:00) Europe/Bucharest (Eastern European Time)',
    'Europe/Chisinau': '(GMT+2:00) Europe/Chisinau (Eastern European Time)',
    'Europe/Helsinki': '(GMT+2:00) Europe/Helsinki (Eastern European Time)',
    'Europe/Istanbul': '(GMT+2:00) Europe/Istanbul (Eastern European Time)',
    'Europe/Kaliningrad': '(GMT+2:00) Europe/Kaliningrad (Eastern European Time)',
    'Europe/Kiev': '(GMT+2:00) Europe/Kiev (Eastern European Time)',
    'Europe/Mariehamn': '(GMT+2:00) Europe/Mariehamn (Eastern European Time)',
    'Europe/Minsk': '(GMT+2:00) Europe/Minsk (Eastern European Time)',
    'Europe/Nicosia': '(GMT+2:00) Europe/Nicosia (Eastern European Time)',
    'Europe/Riga': '(GMT+2:00) Europe/Riga (Eastern European Time)',
    'Europe/Simferopol': '(GMT+2:00) Europe/Simferopol (Eastern European Time)',
    'Europe/Sofia': '(GMT+2:00) Europe/Sofia (Eastern European Time)',
    'Europe/Tallinn': '(GMT+2:00) Europe/Tallinn (Eastern European Time)',
    'Europe/Tiraspol': '(GMT+2:00) Europe/Tiraspol (Eastern European Time)',
    'Europe/Uzhgorod': '(GMT+2:00) Europe/Uzhgorod (Eastern European Time)',
    'Europe/Vilnius': '(GMT+2:00) Europe/Vilnius (Eastern European Time)',
    'Europe/Zaporozhye': '(GMT+2:00) Europe/Zaporozhye (Eastern European Time)',
    'Africa/Addis_Ababa': '(GMT+3:00) Africa/Addis_Ababa (Eastern African Time)',
    'Africa/Asmara': '(GMT+3:00) Africa/Asmara (Eastern African Time)',
    'Africa/Asmera': '(GMT+3:00) Africa/Asmera (Eastern African Time)',
    'Africa/Dar_es_Salaam': '(GMT+3:00) Africa/Dar_es_Salaam (Eastern African Time)',
    'Africa/Djibouti': '(GMT+3:00) Africa/Djibouti (Eastern African Time)',
    'Africa/Kampala': '(GMT+3:00) Africa/Kampala (Eastern African Time)',
    'Africa/Khartoum': '(GMT+3:00) Africa/Khartoum (Eastern African Time)',
    'Africa/Mogadishu': '(GMT+3:00) Africa/Mogadishu (Eastern African Time)',
    'Africa/Nairobi': '(GMT+3:00) Africa/Nairobi (Eastern African Time)',
    'Antarctica/Syowa': '(GMT+3:00) Antarctica/Syowa (Syowa Time)',
    'Asia/Aden': '(GMT+3:00) Asia/Aden (Arabia Standard Time)',
    'Asia/Baghdad': '(GMT+3:00) Asia/Baghdad (Arabia Standard Time)',
    'Asia/Bahrain': '(GMT+3:00) Asia/Bahrain (Arabia Standard Time)',
    'Asia/Kuwait': '(GMT+3:00) Asia/Kuwait (Arabia Standard Time)',
    'Asia/Qatar': '(GMT+3:00) Asia/Qatar (Arabia Standard Time)',
    'Europe/Moscow': '(GMT+3:00) Europe/Moscow (Moscow Standard Time)',
    'Europe/Volgograd': '(GMT+3:00) Europe/Volgograd (Volgograd Time)',
    'Indian/Antananarivo': '(GMT+3:00) Indian/Antananarivo (Eastern African Time)',
    'Indian/Comoro': '(GMT+3:00) Indian/Comoro (Eastern African Time)',
    'Indian/Mayotte': '(GMT+3:00) Indian/Mayotte (Eastern African Time)',
    'Asia/Tehran': '(GMT+3:30) Asia/Tehran (Iran Standard Time)',
    'Asia/Baku': '(GMT+4:00) Asia/Baku (Azerbaijan Time)',
    'Asia/Dubai': '(GMT+4:00) Asia/Dubai (Gulf Standard Time)',
    'Asia/Muscat': '(GMT+4:00) Asia/Muscat (Gulf Standard Time)',
    'Asia/Tbilisi': '(GMT+4:00) Asia/Tbilisi (Georgia Time)',
    'Asia/Yerevan': '(GMT+4:00) Asia/Yerevan (Armenia Time)',
    'Europe/Samara': '(GMT+4:00) Europe/Samara (Samara Time)',
    'Indian/Mahe': '(GMT+4:00) Indian/Mahe (Seychelles Time)',
    'Indian/Mauritius': '(GMT+4:00) Indian/Mauritius (Mauritius Time)',
    'Indian/Reunion': '(GMT+4:00) Indian/Reunion (Reunion Time)',
    'Asia/Kabul': '(GMT+4:30) Asia/Kabul (Afghanistan Time)',
    'Asia/Aqtau': '(GMT+5:00) Asia/Aqtau (Aqtau Time)',
    'Asia/Aqtobe': '(GMT+5:00) Asia/Aqtobe (Aqtobe Time)',
    'Asia/Ashgabat': '(GMT+5:00) Asia/Ashgabat (Turkmenistan Time)',
    'Asia/Ashkhabad': '(GMT+5:00) Asia/Ashkhabad (Turkmenistan Time)',
    'Asia/Dushanbe': '(GMT+5:00) Asia/Dushanbe (Tajikistan Time)',
    'Asia/Karachi': '(GMT+5:00) Asia/Karachi (Pakistan Time)',
    'Asia/Oral': '(GMT+5:00) Asia/Oral (Oral Time)',
    'Asia/Samarkand': '(GMT+5:00) Asia/Samarkand (Uzbekistan Time)',
    'Asia/Tashkent': '(GMT+5:00) Asia/Tashkent (Uzbekistan Time)',
    'Asia/Yekaterinburg': '(GMT+5:00) Asia/Yekaterinburg (Yekaterinburg Time)',
    'Indian/Kerguelen': '(GMT+5:00) Indian/Kerguelen (French Southern & Antarctic Lands Time)',
    'Indian/Maldives': '(GMT+5:00) Indian/Maldives (Maldives Time)',
    'Asia/Calcutta': '(GMT+5:30) Asia/Calcutta (India Standard Time)',
    'Asia/Colombo': '(GMT+5:30) Asia/Colombo (India Standard Time)',
    'Asia/Kolkata': '(GMT+5:30) Asia/Kolkata (India Standard Time)',
    'Asia/Katmandu': '(GMT+5:45) Asia/Katmandu (Nepal Time)',
    'Antarctica/Mawson': '(GMT+6:00) Antarctica/Mawson (Mawson Time)',
    'Antarctica/Vostok': '(GMT+6:00) Antarctica/Vostok (Vostok Time)',
    'Asia/Almaty': '(GMT+6:00) Asia/Almaty (Alma-Ata Time)',
    'Asia/Bishkek': '(GMT+6:00) Asia/Bishkek (Kirgizstan Time)',
    'Asia/Dacca': '(GMT+6:00) Asia/Dacca (Bangladesh Time)',
    'Asia/Dhaka': '(GMT+6:00) Asia/Dhaka (Bangladesh Time)',
    'Asia/Novosibirsk': '(GMT+6:00) Asia/Novosibirsk (Novosibirsk Time)',
    'Asia/Omsk': '(GMT+6:00) Asia/Omsk (Omsk Time)',
    'Asia/Qyzylorda': '(GMT+6:00) Asia/Qyzylorda (Qyzylorda Time)',
    'Asia/Thimbu': '(GMT+6:00) Asia/Thimbu (Bhutan Time)',
    'Asia/Thimphu': '(GMT+6:00) Asia/Thimphu (Bhutan Time)',
    'Indian/Chagos': '(GMT+6:00) Indian/Chagos (Indian Ocean Territory Time)',
    'Asia/Rangoon': '(GMT+6:30) Asia/Rangoon (Myanmar Time)',
    'Indian/Cocos': '(GMT+6:30) Indian/Cocos (Cocos Islands Time)',
    'Antarctica/Davis': '(GMT+7:00) Antarctica/Davis (Davis Time)',
    'Asia/Bangkok': '(GMT+7:00) Asia/Bangkok (Indochina Time)',
    'Asia/Ho_Chi_Minh': '(GMT+7:00) Asia/Ho_Chi_Minh (Indochina Time)',
    'Asia/Hovd': '(GMT+7:00) Asia/Hovd (Hovd Time)',
    'Asia/Jakarta': '(GMT+7:00) Asia/Jakarta (West Indonesia Time)',
    'Asia/Krasnoyarsk': '(GMT+7:00) Asia/Krasnoyarsk (Krasnoyarsk Time)',
    'Asia/Phnom_Penh': '(GMT+7:00) Asia/Phnom_Penh (Indochina Time)',
    'Asia/Pontianak': '(GMT+7:00) Asia/Pontianak (West Indonesia Time)',
    'Asia/Saigon': '(GMT+7:00) Asia/Saigon (Indochina Time)',
    'Asia/Vientiane': '(GMT+7:00) Asia/Vientiane (Indochina Time)',
    'Indian/Christmas': '(GMT+7:00) Indian/Christmas (Christmas Island Time)',
    'Antarctica/Casey': '(GMT+8:00) Antarctica/Casey (Western Standard Time (Australia))',
    'Asia/Brunei': '(GMT+8:00) Asia/Brunei (Brunei Time)',
    'Asia/Choibalsan': '(GMT+8:00) Asia/Choibalsan (Choibalsan Time)',
    'Asia/Chongqing': '(GMT+8:00) Asia/Chongqing (China Standard Time)',
    'Asia/Chungking': '(GMT+8:00) Asia/Chungking (China Standard Time)',
    'Asia/Harbin': '(GMT+8:00) Asia/Harbin (China Standard Time)',
    'Asia/Hong_Kong': '(GMT+8:00) Asia/Hong_Kong (Hong Kong Time)',
    'Asia/Irkutsk': '(GMT+8:00) Asia/Irkutsk (Irkutsk Time)',
    'Asia/Kashgar': '(GMT+8:00) Asia/Kashgar (China Standard Time)',
    'Asia/Kuala_Lumpur': '(GMT+8:00) Asia/Kuala_Lumpur (Malaysia Time)',
    'Asia/Kuching': '(GMT+8:00) Asia/Kuching (Malaysia Time)',
    'Asia/Macao': '(GMT+8:00) Asia/Macao (China Standard Time)',
    'Asia/Macau': '(GMT+8:00) Asia/Macau (China Standard Time)',
    'Asia/Makassar': '(GMT+8:00) Asia/Makassar (Central Indonesia Time)',
    'Asia/Manila': '(GMT+8:00) Asia/Manila (Philippines Time)',
    'Asia/Shanghai': '(GMT+8:00) Asia/Shanghai (China Standard Time)',
    'Asia/Singapore': '(GMT+8:00) Asia/Singapore (Singapore Time)',
    'Asia/Taipei': '(GMT+8:00) Asia/Taipei (China Standard Time)',
    'Asia/Ujung_Pandang': '(GMT+8:00) Asia/Ujung_Pandang (Central Indonesia Time)',
    'Asia/Ulaanbaatar': '(GMT+8:00) Asia/Ulaanbaatar (Ulaanbaatar Time)',
    'Asia/Ulan_Bator': '(GMT+8:00) Asia/Ulan_Bator (Ulaanbaatar Time)',
    'Asia/Urumqi': '(GMT+8:00) Asia/Urumqi (China Standard Time)',
    'Australia/Perth': '(GMT+8:00) Australia/Perth (Western Standard Time (Australia))',
    'Australia/West': '(GMT+8:00) Australia/West (Western Standard Time (Australia))',
    'Australia/Eucla': '(GMT+8:45) Australia/Eucla (Central Western Standard Time (Australia))',
    'Asia/Dili': '(GMT+9:00) Asia/Dili (Timor-Leste Time)',
    'Asia/Jayapura': '(GMT+9:00) Asia/Jayapura (East Indonesia Time)',
    'Asia/Pyongyang': '(GMT+9:00) Asia/Pyongyang (Korea Standard Time)',
    'Asia/Seoul': '(GMT+9:00) Asia/Seoul (Korea Standard Time)',
    'Asia/Tokyo': '(GMT+9:00) Asia/Tokyo (Japan Standard Time)',
    'Asia/Yakutsk': '(GMT+9:00) Asia/Yakutsk (Yakutsk Time)',
    'Australia/Adelaide': '(GMT+9:30) Australia/Adelaide (Central Standard Time (South Australia))',
    'Australia/Broken_Hill': '(GMT+9:30) Australia/Broken_Hill (Central Standard Time (South Australia/New South Wales))',
    'Australia/Darwin': '(GMT+9:30) Australia/Darwin (Central Standard Time (Northern Territory))',
    'Australia/North': '(GMT+9:30) Australia/North (Central Standard Time (Northern Territory))',
    'Australia/South': '(GMT+9:30) Australia/South (Central Standard Time (South Australia))',
    'Australia/Yancowinna': '(GMT+9:30) Australia/Yancowinna (Central Standard Time (South Australia/New South Wales))',
    'Antarctica/DumontDUrville': '(GMT+10:00) Antarctica/DumontDUrville (Dumont-d\'Urville Time)',
    'Asia/Sakhalin': '(GMT+10:00) Asia/Sakhalin (Sakhalin Time)',
    'Asia/Vladivostok': '(GMT+10:00) Asia/Vladivostok (Vladivostok Time)',
    'Australia/ACT': '(GMT+10:00) Australia/ACT (Eastern Standard Time (New South Wales))',
    'Australia/Brisbane': '(GMT+10:00) Australia/Brisbane (Eastern Standard Time (Queensland))',
    'Australia/Canberra': '(GMT+10:00) Australia/Canberra (Eastern Standard Time (New South Wales))',
    'Australia/Currie': '(GMT+10:00) Australia/Currie (Eastern Standard Time (New South Wales))',
    'Australia/Hobart': '(GMT+10:00) Australia/Hobart (Eastern Standard Time (Tasmania))',
    'Australia/Lindeman': '(GMT+10:00) Australia/Lindeman (Eastern Standard Time (Queensland))',
    'Australia/Melbourne': '(GMT+10:00) Australia/Melbourne (Eastern Standard Time (Victoria))',
    'Australia/NSW': '(GMT+10:00) Australia/NSW (Eastern Standard Time (New South Wales))',
    'Australia/Queensland': '(GMT+10:00) Australia/Queensland (Eastern Standard Time (Queensland))',
    'Australia/Sydney': '(GMT+10:00) Australia/Sydney (Eastern Standard Time (New South Wales))',
    'Australia/Tasmania': '(GMT+10:00) Australia/Tasmania (Eastern Standard Time (Tasmania))',
    'Australia/Victoria': '(GMT+10:00) Australia/Victoria (Eastern Standard Time (Victoria))',
    'Australia/LHI': '(GMT+10:30) Australia/LHI (Lord Howe Standard Time)',
    'Australia/Lord_Howe': '(GMT+10:30) Australia/Lord_Howe (Lord Howe Standard Time)',
    'Asia/Magadan': '(GMT+11:00) Asia/Magadan (Magadan Time)',
    'Antarctica/McMurdo': '(GMT+12:00) Antarctica/McMurdo (New Zealand Standard Time)',
    'Antarctica/South_Pole': '(GMT+12:00) Antarctica/South_Pole (New Zealand Standard Time)',
    'Asia/Anadyr': '(GMT+12:00) Asia/Anadyr (Anadyr Time)',
    'Asia/Kamchatka': '(GMT+12:00) Asia/Kamchatka (Petropavlovsk-Kamchatski Time)'
  };
}
