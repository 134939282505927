import { Component, OnInit, Input, HostListener, ViewContainerRef, ViewChild, ComponentFactoryResolver, OnDestroy, AfterViewInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ToolbarItem } from '../../models/toolbar/toolbar-item.interface';
import { ToolbarService } from '../../services/toolbar.service';

@Component({
  selector: 'app-core-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, OnDestroy, AfterViewInit {

  navbarOpen = false;
  fixed = false;
  show = true;
  lastScrollTop = 0;

  private logoComponentRef = null;
  @ViewChild('logoContainer', { read: ViewContainerRef }) logoContainer: ViewContainerRef;

  constructor(public toolbarService: ToolbarService, private resolver: ComponentFactoryResolver) {
  }

  @HostListener('window:scroll', [])
  onScroll() {
    if (this.toolbarService.fixed$.value) {
      const num = window.scrollY;
      if (num > this.lastScrollTop) {
        this.show = false;
      } else {
        this.show = true;
      }
      this.lastScrollTop = num <= 0 ? 0 : num;
    }
  }

  ngOnInit() {
    // console.log(this.toolbarService.items$);
  }

  ngAfterViewInit() {
    // da sistemare meglio
    setTimeout(() => {
      this.toolbarService.logo$.subscribe(component => {
        if (component) {
          this.renderLogo(component);
        }
      });
    }, 200);
  }

  renderLogo(logoComponent) {
      this.logoContainer.clear();
      const factory = this.resolver.resolveComponentFactory(logoComponent);
      this.logoComponentRef = this.logoContainer.createComponent(factory);
      if (this.toolbarService.logoParams$.value) {
        Object.keys(this.toolbarService.logoParams$.value).forEach(key => {
          this.logoComponentRef.instance[key] = this.toolbarService.logoParams$.value[key];
        });
      }
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  ngOnDestroy() {
    if (this.logoComponentRef) {
      this.logoComponentRef.destroy();
    }
  }

}
