import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { AnswerModel } from '@app/shared/models/answer.model';
import { LightboxModalComponent } from '@app/core/components/lightbox-modal/lightbox-modal.component';
import { ModalService } from '@app/core/services/modal.service';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { AnswersService } from '@app/shared/services/answers.service';
import { ResponseModel } from '@app/core/models/response.model';
import { TeamService } from '@app/shared/services/team.service';
import { ModerationHelperService } from '@app/shared/services/moderation-helper.service';
import { QuoteModel } from '@app/shared/models/quote.model';

@Component({
  selector: 'app-moderation-answerstype-image-answer',
  templateUrl: './image-answer.component.html',
  styleUrls: ['./image-answer.component.scss']
})
export class AnswersTypeImageAnswerComponent extends CoreBaseComponent implements OnInit {

  @Input() question: QuestionModel;
  @Input() answer: AnswerModel;
  @Input() classes: string;
  @Input() explicitMediaUrls: boolean;
  @Input() placeholders: boolean;
  @Input() set message(value: {action: string, data: any}) {
    this.switchMessage(value);
  }
  
  @Output() textSelection: EventEmitter<any> = new EventEmitter(null);

  public form: UntypedFormArray;
  get formComments(): UntypedFormArray {
    return this.form.get('comments') as UntypedFormArray;
  }

  // Quotes
  public quotes: Array<QuoteModel> = [];
  public qSubscription = null;

  public itemToModify: number = null;

  constructor(private modalService: ModalService, private answerService: AnswersService, public teamService: TeamService,
              private moderationHelperService: ModerationHelperService, private cdr: ChangeDetectorRef) {
    super();
  }


  ngOnInit(): void {
    this.form = new UntypedFormArray([], null);
    if (this.answer.answer_data.comments) {
      this.answer.answer_data.comments.forEach((c: string) =>
        this.form.push(new UntypedFormControl(c, null))
      );
    }
    // Quotes
    if(!this.teamService.isCurrentUserCustomerPro() && !this.teamService.isCurrentUserCustomerBasic()) {
      if(this.answer.quotes) {
        this.quotes = this.answer.quotes;
        this.printQuotes(this.answer.quotes);
      }
      this.qSubscription = this.moderationHelperService.quotes.subscribe(quotes => {
        this.printQuotes(quotes);
        this.quotes = quotes;
        this.cdr.detectChanges();
      });
    }
  }

  private printQuotes(quotes: Array<QuoteModel>) {
    if(quotes != null) {
      quotes.forEach(quote => {
        this.answer.answer_data.comments.forEach((comment, index) => {
          this.answer.answer_data.comments[index] = this.answer.answer_data.comments[index].replace(/(\r\n|\n|\r)/gm, "<br />");
          if(this.answer.answer_data.comments[index]) {
            if(quote.quote_original && quote.quote_original.length) {
              quote.quote_original.forEach(q => {
                if(this.answer.answer_data.comments[index].includes(q) && quote.answerid === this.answer.id) {
                  const qclass = quote.color ? quote.color : 'grey';
                  this.answer.answer_data.comments[index] = this.answer.answer_data.comments[index].replace(q, '<em class="highlight ' + qclass + '">' + q +'</em>');
                }
              });
            } else {
              if(this.answer.answer_data.comments[index].includes(quote.quote) && quote.answerid === this.answer.id) {
                const qclass = quote.color ? quote.color : 'grey';
                this.answer.answer_data.comments[index] = this.answer.answer_data.comments[index].replace(quote.quote, '<em class="highlight ' + qclass + '">' + quote.quote +'</em>');
              }
            }
          }
        });
      });
    }
  }

  openLightbox(image: any | number, mediaArray?: Array<any>) {
    this.modalService.open({modalModel: LightboxModalComponent, dialogSize: 'custom'}, {image, images: mediaArray});
  }

  private switchMessage(message: {action: string, data: any}) {
    if (!message) {
      return false;
    }
    switch (message.action) {
      case 'modify-answer':
        this.itemToModify = message.data;
        break;
    }
  }

  saveMods() {
    this.operation('saving').reset().isSubmitting();
    const data = this.answer;
    data.answer_data.comments = this.form.value;
    this.answerService.updateAnswer(this.answer.id, data).subscribe((res: ResponseModel) => {
      this.answer.answer_data.comments = this.form.value;
      this.itemToModify = null;
      setTimeout(() => {
        this.printQuotes(this.quotes);
      }, 500);
      this.operation('saving').isSuccess();
    });
  }

  selectText() {
    this.textSelection.emit({commentid:null});
  }
}
