/**
 * Strip html
 * preserving spaces
 */

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'striphtmlKeepSpaces'
})
export class StriphtmlKeepSpacesPipe implements PipeTransform {

  transform(html: string, args?: any): any {
    var regex = /(<([^>]+)>)/ig, result;
    result = html.replace(regex, " ");
    result = result.replace(/\s+/g, " ").replace(/&nbsp;/gi, ' '); // remove double spaces
    result = result.trim();
    return result;
  }
}