<ng-container *ngIf="answer">
  <div class="p-0 mb-4 {{ classes }}" *ngIf="answer.answer_data">
    <div class="d-inline-block mt-3 mr-3" *ngFor="let c of answer.answer_data.completion_comments; let i = index">
      <div class="card">
        <div class="m-3">
          <p class="mb-0">{{ c }}</p>
        </div>
      </div>
    </div>
    <div *ngIf="answer.answer_data.comment">
      <div class="sentence-comment mt-4 p-3" (mouseup)="selectText()" [innerHTML]="answer.answer_data.comment">
      </div>
    </div>

    <div *ngIf="itemToModify === answer.id" class="row mt-4">
      <div class="col-6">
        <form [formGroup]="form">
          <div *ngFor="let c of form.controls; let i = index" class="form-group">
            <input type="text" class="form-control" [formControlName]="i">
            <span class="placeholder">{{ 'COMMENT' | translate }}</span>
          </div>
        </form>
        <button class="btn btn-success" (click)="saveMods()">
          <span *ngIf="operation('saving').submitting" class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
          </span>
          {{ 'SAVE' | translate }}
        </button>
      </div>
    </div>

  </div>
</ng-container>
