import { Component, Input, OnInit } from '@angular/core';
import { ResponseModel } from '@app/core/models/response.model';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { ModalService } from '@app/core/services/modal.service';
import { TemplateMessageModel } from '@app/shared/models/template-message.model';
import { TemplateMessageService } from '@app/shared/services/template-messages.service';

@Component({
  selector: 'app-edit-template',
  templateUrl: './edit-template.component.html',
  styleUrls: ['./edit-template.component.scss']
})
export class EditTemplateComponent implements OnInit {

  @Input() template: TemplateMessageModel;
  modules: any;

  constructor(
    private templateMessageService: TemplateMessageService,
    private coreLayoutService: CoreLayoutService,
    private modalService: ModalService,
  ) {
    this.modules = {
      'emoji-shortname': true,
      'emoji-textarea': true,
      'emoji-toolbar': true,
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ header: 1 }, { header: 2 }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ color: [] }, { background: [] }],
        ['link'],
        ['emoji']
      ]
    };
  }

  ngOnInit(): void {
  }

  updateTemplate() {
    this.templateMessageService.editTemplateMessage(this.template).subscribe((res: ResponseModel) => {
      this.coreLayoutService.closeSidebar();
    })
  }

  deleteTemplate() {

    this.modalService.open({title: 'ARE_YOU_SURE.?',
        btnOkText: 'YES',
        btnCancelText: 'CANCEL',
        showBtnClose: false,
        showCancelBtn: true,
        customClass: 'confirm-modal',
        message: 'ARE_YOU_SURE_DELETE.?'
      }).then((data) => {
        if (data) {
          this.templateMessageService.deleteTemplateMessage(this.template.id).subscribe((res: ResponseModel) => {
            this.coreLayoutService.closeSidebar();
          })
        }
    });
  }
}
