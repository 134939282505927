import { Component, OnInit, Input } from '@angular/core';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { UntypedFormGroup, Validators, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { Mode } from '../../../../../../setup';
import { UserService } from '@app/shared/services/user.service';
import { ResponseModel } from '@app/core/models/response.model';
import { ModalService } from '@app/core/services/modal.service';
import { LanguagesService } from '@app/shared/services/languages.service';
import { AuthService } from '@app/core/services/auth.service';
import { User } from '@app/shared/models/user.model';
import { CustomValidators } from '@app/core/validators/validators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { Router } from '@angular/router';
import * as angular from 'angular';
import { ApiService } from '@app/core/services/api.service';
@Component({
  selector: 'app-shared-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent extends CoreBaseComponent implements OnInit {

  @Input()
  id = null;

  currentLang: string;

  public form: UntypedFormGroup;
  public passwordMinLength = Mode.PASSWORD_MIN_LENGTH;
  public avatarUrl = null;
  private avatarTouched = false;
  public user: User = null;
  private avatarDel = false;
  public new = false;
  selectRecoveryMode: boolean;

  isAdmin = false;
  sudoEmail: string;

  constructor(private formBuilder: UntypedFormBuilder, private userService: UserService, private authService: AuthService,
              public translate: TranslateService, private layoutService: CoreLayoutService, private router: Router,
              public languagesService: LanguagesService, private toastService: ToastrService, public apiService: ApiService) {
      super();
  }

  ngOnInit() {
    this.selectRecoveryMode = this.apiService.selectRecoveryMode;
    this.currentLang = angular.copy(this.translate.currentLang)
    this.loading = true;
    this.layoutService.buttons(null);
    this.authService.current(User).subscribe((result: ResponseModel) => {
      this.user = result.getData();

      if (this.user.id === '1') {
        this.isAdmin = true;
      }

      this.loading = false;
      this.avatarUrl = this.user.avatar_url;
      this.form = this.formBuilder.group({
        avatar: new UntypedFormControl(''),
        email: new UntypedFormControl(this.user.email, [
          Validators.required,
          Validators.email
        ]),
        firstname: new UntypedFormControl(this.user.firstname, Validators.required),
        lastname: new UntypedFormControl(this.user.lastname, Validators.required),
        company_declared: new UntypedFormControl(this.user.company_declared),
        company: new UntypedFormControl(this.user.company),
        telephone: new UntypedFormControl(this.user.telephone),
        language_code: new UntypedFormControl(this.user.language_code),
        password: new UntypedFormControl('', [
          Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$')
        ]),
        password_confirm: new UntypedFormControl('', [
          Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$')
        ])
      }, {
        validator: CustomValidators.Match('password', 'password_confirm')
      });
    });
  }

  suChangeUser() {
    this.authService.su(this.sudoEmail.toLowerCase()).subscribe((res: ResponseModel) => {
      this.router.navigateByUrl('/dashboard');
      setTimeout(() => {
        window.location.reload();
      }, 500);
      this.layoutService.closeSidebar();
    }, (e) => {
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
    });
  }

  uploadPicture(event) {
    const selectedFile = event.target.files[0];
    const reader = new FileReader();
    this.operation('receiving-avatar').reset().isSubmitting();
    const mimeType = selectedFile.type;
    if (mimeType.match(/image\/*/) == null) {
      this.operation('receiving-avatar').isFailed();
      return;
    }
    reader.readAsDataURL(selectedFile);
    reader.onload = (/*event*/) => {
      this.new = true;
      this.avatarUrl = reader.result;
      this.avatarTouched = true;
      this.form.patchValue({
        avatar: reader.result
      });
      this.avatarUrl = reader.result;
      this.operation('receiving-avatar').isSuccess();
    };
  }

  removePicture() {
    this.avatarTouched = true;
    this.avatarUrl = null;
    this.avatarDel = true;
  }

  submit() {
    this.operation('saving-data').reset().isSubmitting();
    const data = this.formValueExcept(this.form, this.avatarTouched ? null : ['avatar']);
    if (this.avatarDel) {
      data.avatar_del = true;
    }

    if (this.user.select_recovery_mode_available) {
      this.apiService.selectRecoveryMode = this.selectRecoveryMode;
    }

    this.authService.updateCurrent(data, User)
      .subscribe((response: ResponseModel) => {
        this.operation('saving-data').isSuccess(response.getErrors());
        this.languagesService.changeLanguage(data.language_code);
        if (this.currentLang !== data.language_code) {
          location.reload();
        }
      }, (error) => {
        this.operation('saving-data').isFailed(error.errors);
    });
  }

}
