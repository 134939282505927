import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-answers-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class AnswersPieChartComponent implements OnInit {

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    title: null,
    series: [{
      data: [1, 2, 3],
      type: 'pie'
    }]
  };

  constructor() { }

  ngOnInit() {
  }
}
