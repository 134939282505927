import { Component, OnInit } from '@angular/core';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { RoomModel } from '@app/shared/models/room.model';
import { RoomsService } from '@app/shared/services/rooms.service';
import { ResponseModel } from '@app/core/models/response.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-private-setup-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.scss']
})
export class InstructionsComponent implements OnInit {

  constructor(
    public coreLayoutService: CoreLayoutService,
    private roomService: RoomsService,
    private toastService: ToastrService,
    private translateService: TranslateService
  ) {
    this.modules = {
      'emoji-shortname': true,
      'emoji-textarea': true,
      'emoji-toolbar': true,
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote'],
        [{ header: 1 }, { header: 2 }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ direction: 'rtl' }],
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }],
        [{ font: [] }],
        [{ align: [] }],
        ['clean'],
        ['link', 'image', 'video'],
        ['emoji']
      ]
    };
   }
   modules = {};
   room: RoomModel;

  ngOnInit(): void {
    this.coreLayoutService.sidebarParams$.subscribe((room: RoomModel) => {
      this.room = room;
    });
  }


  save() {
    this.roomService.updateRoom(this.room).subscribe((res: ResponseModel) => {
      this.coreLayoutService.closeSidebar();
      this.toastService.success(this.translateService.instant('DATA_UPDATED'));
    });
  }
}
