import {Component, ElementRef, ViewChild, ViewEncapsulation, Output, EventEmitter} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router, ActivatedRoute} from '@angular/router';


@Component({
  selector: 'app-auth-navbar',
  templateUrl: './auth-navbar.component.html',
  styleUrls: ['./auth-navbar.component.scss']
})
export class AuthNavbarComponent {

  @Output()
  createAccountClick: EventEmitter<number> = new EventEmitter();

  navbarOpen = false;
  tab = 1;

  constructor(private translate: TranslateService, private route: ActivatedRoute) {
    // this.appService.pageTitle = 'Accedi';
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  toggle() {
    this.createAccountClick.emit(this.tab = this.tab === 1 ? 2 : 1);
  }
}
