<div class="message" [id]="'message' + item.id" [ngClass]="{'deleted': deleted, 'highlight': highlighted}">
    <div class="panel shadow p-0">
        <div class="panel-header d-flex align-items-center">
            <app-moderation-answers-avatar [user]="item.team" [answer]="item"></app-moderation-answers-avatar>
            <div class="col">
                <span *ngIf="!item.read" class="badge badge-secondary d-inline-flex align-items-center p-2 bg-orange">
                    {{ 'NOT_READ' | translate }}
                    <span *ngIf="settingAsRead" class="spinner-border light spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                </span>
            </div>
            <div class="col d-flex align-items-center">
                <div [ngbTooltip]="item.team.sample_variable_tags?.join(', ')" class="tool-btn mr-5" *ngIf="!isCustomerPro">
                    <i class="fal fa-tag mr-2"></i>
                    <span [ngClass]="{'with-data': item.team.sample_variable_tags?.join(', ')}">Tags</span>
                </div>
                <div class="tool-btn mr-5 tags-select-tool" *ngIf="!isCustomerPro">
                    <ng-template #personasPopover>
                        <app-core-select
                            *ngIf="personasForm"
                            [classes]="'form-control'"
                            [form]="personasForm"
                            [addTag]="true"
                            [formEntityName]="'personas_tags'"
                            [preselected]="{byprop: personasForm.value.personas_tags, value: personasForm.value.personas_tags}"
                            (change)="personasTagsChange($event)"
                            [searchable]="true"
                            [customList]="personasTags"
                            >
                        </app-core-select>
                    </ng-template>
                    <i [autoClose]="'outside'" [ngbPopover]="personasPopover" [popoverTitle]="'SELECT_PERSONAS' | translate" class="fal fa-portrait mr-2"></i>
                    <span [ngClass]="{'': item.team.personas_tags}" [autoClose]="'outside'" [ngbPopover]="personasPopover" [popoverTitle]="'SELECT_PERSONAS' | translate">
                        {{ (
                            item.team.personas_tags?.length && item.team.personas_tags?.toString().toLowerCase()
                                ? item.team.personas_tags.join(', ').toLowerCase()
                                : 'personas'
                        ) }}
                    </span>
                </div>
                <div class="tool-btn mr-5" *ngIf="!isCustomerPro">
                    <ng-template #notesPopover>
                        <textarea [(ngModel)]="item.team.note" class="form-control">{{ item.team.note ? item.team.note : null }}</textarea>
                        <button class="btn btn-sicche btn-secondary mx-3" (click)="notesChanged()">
                            <span *ngIf="operation('saving-note').submitting" class="spinner-border spinner-border-sm" role="status">
                                <span class="sr-only">Loading...</span>
                            </span>
                            {{ 'SAVE' | translate }}
                        </button>
                    </ng-template>
                    <i class="fal fa-sticky-note mr-2"></i>
                    <span [ngbPopover]="notesPopover" [ngClass]="{'with-data': item.team.note}"
                        [ngbTooltip]="item.team.note" placement="bottom"
                        [autoClose]="'outside'" [popoverTitle]="'NOTA' | translate">{{ 'NOTE_S' | translate }}</span>
                </div>
            </div>
            <div *ngIf="!isCustomerPro" class="tool-btn menu" [ngbPopover]="contextMenu" placement="auto">
                <ng-template #contextMenu>
                    <ul class="mb-0">
                        <li (click)="reply(item, true)">{{ 'SEND_PRIVATE_MESSAGE' | translate }}</li>
                        <li *ngIf="!item.team.is_locked" (click)="blockUser()">{{ 'BLOCK_USER' | translate }}</li>
                        <li *ngIf="item.team.is_locked" (click)="unlockUser()">{{ 'UNBLOCK_USER' | translate }}</li>
                        <li *ngIf="
                            item.question_type === 'open-answer' ||
                            item.question_type === 'closed-single' ||
                            item.question_type === 'closed-multiple' ||
                            item.question_type === 'sentence-completion' ||
                            item.question_type === 'image-answer' ||
                            item.question_type === 'pin-on-media'"
                            (click)="modifyAnswer(item.id)">{{ 'MODIFY_ANSWER' | translate }}</li>
                        <li (click)="deleteAnswer(item.id)">{{ 'DELETE_ANSWER' | translate }}</li>
                    </ul>
                </ng-template>
                <i class="fas fa-ellipsis-v"></i>
            </div>
        </div>
        <div class="panel-body">
            <div class="message-wrapper">
                <div class="message">
                    <app-moderation-answerdispatcher
                        [question]="question"
                        [item]="item"
                        [message]="message"
                        (textSelection)="selectText($event)">
                    </app-moderation-answerdispatcher>
                </div>
            </div>
        </div>
        <div class="panel-footer">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-xl-6 d-flex align-items-center justify-content-start" *ngIf="!isCustomerPro">
                        <div class="tool tag-tool text-nowrap">
                            <i class="far fa-tag"></i>
                            <span>{{ 'CONTENT_TAG' | translate }}</span>
                        </div>
                        <div class="tool tags-select-tool w-100 pr-2">
                            <div class="d-flex justify-content-center">
                                <div *ngIf="!contentTagsReceived" class="spinner-border spinner-border-sm" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            <app-core-select
                                *ngIf="contentTagsReceived"
                                [classes]="'form-control'"
                                [form]="contentTagsForm"
                                [formEntityName]="'content_tags'"
                                [searchable]="true"
                                [addTag]="true"
                                (change)="tagsChanged($event)"
                                [customList]="moderationHelperService.contentTagList"
                                [multiple]="true"
                                >
                            </app-core-select>
                            <!--
                            <ng-select [items]="item.answer_tags"
                                [hideSelected]="true"
                                multiple="true"
                                [addTag]="true"
                                bindLabel="name"
                                (change)="tagsChanged($event)"
                                [(ngModel)]="item.answer_tags">
                            </ng-select>
                            -->
                        </div>
                    </div>
                    <div class="col-xl-6 d-flex align-items-center mt-3 mt-xl-0" [ngClass]="{'justify-content-start': isCustomerPro, 'justify-content-end ': !isCustomerPro}">
                        <div class="tool favourites-tool stars flex-grow-1" *ngIf="!isCustomerPro">
                            <app-moderation-starsrating [readonly]="isCustomerPro" [answer]="item"></app-moderation-starsrating>
                        </div>
                        <div style="min-width: 50px;" class="tool like-tool" *ngIf="!isCustomerPro">
                            <i class="fal fa-heart" (click)="likeAnswer(item)" [ngClass]="{'favourite-yes': item.currentuser_like_answer}"></i>
                            <span class="total_answer_like" *ngIf="item.total_like_to_answer">({{ item.total_like_to_answer }})</span>
                        </div>
                        <div *ngIf="item.nr_comments" class="tool mod-del-tool">
                            <a (click)="loadComments(item.id)" class="mr-5">{{ 'COMMENTS' | translate }}<span *ngIf="item.nr_comments">({{ item.nr_comments }})</span></a>
                        </div>
                        <div *ngIf="!isCustomerPro && !item.nr_comments" class="tool mod-del-tool">
                            <button class="btn-sicche active" (click)="reply(item, false)">
                                <i class="fal fa-reply"></i>&nbsp;{{ 'REPLY' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-core-spinner [toggle]="operation('loading-comments').submitting"></app-core-spinner>
<ng-container *ngIf="comments.length && showComments">
    <div class="message mine" *ngFor="let comment of comments">
        <app-moderation-answer-comment
            [personasTags]="personasTags"
            [intervieweds]="intervieweds"
            [id]="'answer-block-comment-' + comment.id"
            [highlighted_in]="comment.id === highlightedCommentId"
            [commentAnswer]="comment"
            (textSelection)="selectText($event)"
            [answer]="item">
        </app-moderation-answer-comment>
    </div>
    <div *ngIf="!isCustomerPro && item.nr_comments" class="tool mod-del-tool text-right mb-5">
        <button class="btn-sicche active" (click)="reply(item, false)">
            <i class="fal fa-reply"></i>&nbsp;{{ 'REPLY' | translate }}
        </button>
    </div>
</ng-container>

<div class="overlay-extract" *ngIf="showSelectionModal">
    <app-moderation-quotes-modal [extraData]="quoteExtraData" [answer]="item" (closeFunc)="quotesModalClose()"></app-moderation-quotes-modal>
</div>