<h2 class="light">{{ 'LOGIN_BEFORE_USE' | translate }}</h2>
<form *ngIf="form" [formGroup]="form">
    <div [class.is-invalid]="form.get('username').invalid && form.get('username').touched">
      <div class="form-group">
        <label>
          <input required formControlName="username" type="text" class="form-control" id="username">
          <span class="placeholder">{{ 'USERNAME' | translate }}</span>
        </label>
      </div>
    </div>
    <div [class.is-invalid]="form.get('password').invalid && form.get('password').touched">
      <small class="field-note" (click)="forgottenPasswordModal()">
        <a class="as-link">{{ 'FORGOTTEN_PASSWORD' | translate }}?</a>
      </small>
      <div class="form-group">
        <label>
          <input required formControlName="password" type="password" class="form-control" id="password" aria-describedby="passwordHelp">
          <span class="placeholder">{{ 'PASSWORD' | translate }}</span>
        </label>
      </div>
      <div class="text-danger" *ngIf="form.get('password').invalid && form.get('password').touched">
        <small *ngIf="passwordMinLength">{{ 'PASSWORD_RULES_WARNING' | translate:({param1: passwordMinLength, dumbTrigger: 'it'} | ParamsTranslate) }}</small>
      </div>
      <div class="alert alert-danger" *ngIf="error">
        {{ 'ERRORS.INVALIDUSERNAMEORPASSWORD' | translate }}
      </div>
    </div>
    <div class="check-radio-box">
        <input type="checkbox" id="rem-me" formControlName="rememberme">
        <label class="mb-0 ml-2" for="rem-me">
          {{ 'REMEMBER_ME' | translate }}
        </label>
    </div>
    <div class="text-center w-100">
        <app-core-button-submit
          class="w-100 mt-5 d-block"
          [classes]="'btn-sicche active mx-auto'"
          [spinnerDimension]="'small'"
          [form]="form" 
          (submitCallback)="submit()" 
          [toggle]="submitting">
          <span><i class="fal fa-sign-in-alt"></i>&nbsp;{{ 'LOGIN' | translate }}</span>
      </app-core-button-submit>
    </div>
</form>