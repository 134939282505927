<ng-container [formGroup]="form">
    <div class="d-flex align-items-start mb-3">
        <div class="flex-grow-0 mt-4 pt-3">
            <div class="form-group">
                <input #file
                    [disabled]="formArray.disabled"
                    [accept]="mediaType === 'image' ? 'image/*' : 'video/*'"
                    (change)="selectMedia($event)"
                    class="d-none"
                    type="file"
                    [multiple]="multipleAllowed"/>
                <div class="btn btn-secondary text-center mb-2" [class.disabled]="formArray.disabled || loading" (click)="file.click()">
                    <i *ngIf="!loading" class="fal fa-upload" style="font-size: 2rem;"></i>
                    <i *ngIf="loading" class="fal fa-spinner-third fa-spin"></i>
                </div>
            </div>
        </div>
        <div [formArrayName]="formArrayName" class="question-options-list" cdkDropList (cdkDropListDropped)="changeImagesOrder($event)">

            <ng-container *ngIf="mediaType === 'image'">
                <div *ngFor="let formGroup of formArray.controls; let i = index;" class="answer-row flex-grow-0" cdkDrag>
                    <div class="d-flex align-items-center justify-content-center image-holder position-relative">
                        <a class="delete-img" (click)="deleteImg(i)">
                            <i class="fas fa-times"></i>
                        </a>
                        <div *ngIf=" forceImgSquareView && !formGroup.value.new" class="div-img-square" [style.background-image]="'url(' + (formGroup.value.image_url | privatesrc) + ')'"></div>
                        <div *ngIf=" forceImgSquareView &&  formGroup.value.new" class="div-img-square" [style.background-image]="safeImgStyle('url(' + (formGroup.value.image_render) + ')')"></div>
                        <img *ngIf="!forceImgSquareView && !formGroup.value.new" [src]="formGroup.value.image_url | privatesrc">
                        <img *ngIf="!forceImgSquareView &&  formGroup.value.new" [src]="formGroup.value.image_render">
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="mediaType === 'video'">
                <div [class.d-none]="!formArray.controls.length || !formArray.controls[0].value" class="answer-row flex-grow-0">
                    <div class="d-flex align-items-center justify-content-center image-holder position-relative">
                        <a class="delete-img" (click)="deleteVid()">
                            <i class="fas fa-times"></i>
                        </a>
                        <video *ngIf="!videoFromExternal" #video></video>
                        <video *ngIf="videoFromExternal && formArray.controls[0]" [src]="formArray.controls[0].value.video_url | privatesrc"></video>
                    </div>
                </div>
            </ng-container>

            <div class="text-center mt-2">
                <a class="set-cleaner" *ngIf="formArray.value.length > 0" (click)="deleteAll()">
                    {{ (
                        mediaType==='image'
                            ? (formArray.controls.length > 1 ? 'CLEAN_ALL_IMAGES' : 'CLEAN_IMAGE')
                            : (formArray.controls.length > 1 ? 'CLEAN_ALL_VIDEOS' : 'CLEAN_VIDEO')
                    ) | translate }}
                </a>
            </div>
        </div>
    </div>

</ng-container>
