import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-shared-avatar',
  templateUrl: './avatar-img.component.html',
  styleUrls: ['./avatar-img.component.scss']
})
export class AvatarImgComponent implements OnInit {


  @Input()
  imageUrl: string = null;

  constructor() { }

  ngOnInit() {
  }

}
