<app-shared-sidebar-container *ngIf="isSidebar">
    <app-shared-sidebar-header>
        <div slot="title">
            <h2 class="mb-0">{{ 'MESSAGES_SECTION.CREATE_NEW' | translate }}</h2>
        </div>

    </app-shared-sidebar-header>

    <div class="sidebar-body">
        <!-- template messages -->
        <div class="row my-3" >
            <div class="col-12">
                <label>
                    <input type="checkbox" [(ngModel)]="showLoadFromTemplate">
                    {{'LOAD_FROM_TEMPLATE_MESSAGE' | translate}}
                    <span class="info-tooltip ml-2" placement="top" [ngbTooltip]="'TOOLTIPS.TEMPLATE_MESSAGES' | translate">
                        <i class="far fa-info-circle"></i>
                    </span>
                </label>
            </div>
            <div class="col-7" *ngIf="showLoadFromTemplate">
                <div class="row" >
                    <div class="col-12 ">
                        <div class="form-group">
                            <app-core-select
                            *ngIf="templateMessages"
                            [classes]="'form-control'"
                            [multiple]="false"
                            [cache]="true"
                            [customList]="templateMessages"
                            [label]="'title'"
                            [searchable]="true"
                            (change)="setTemplateMessage($event)"
                            >
                            </app-core-select>
                            <span class="placeholder">{{'TEMPLATE_MESSAGES' | translate}}</span>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-5 text-right" *ngIf="showLoadFromTemplate">
                <a (click)="showLoadFromTemplate=false" class="clickable mr-2">{{'CANCEL' | translate}}</a>
                <button class="btn-sicche btn-secondary" [disabled]="!currentTemplateMessage" (click)="loadFromTemplate()">{{'LOAD_FROM_TEMPLATE_MESSAGE' | translate}}</button>
            </div>
        </div>
        <!-- end messaggi template -->
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <input type="text" [(ngModel)]="subject" class="form-control">
                    <span class="placeholder">{{ 'MESSAGES_SECTION.SUBJECT' | translate }}</span>
                </div>
            </div>
            <div class="col-12">
                <div class="panel shadow">
                    <quill-editor [(ngModel)]="text" [modules]="modules" [styles]="{height: '250px'}" [placeholder]="'MESSAGES_SECTION.MESSAGE' | translate"></quill-editor>
                </div>
            </div>
            <div class="col-12 text-right" *ngIf="text && subject">
                <button class="btn-sicche" (click)="send()">{{ 'SEND' | translate }}</button>
            </div>
        </div>

         <div class="row mt-4">
            <div class="col-12">
                <label>
                    <input type="checkbox" [(ngModel)]="showSaveAsTemplate">
                    {{'SAVE_AS_TEMPLATE_MESSAGE' | translate}}
                </label>
            </div>
            <div class="col-9" *ngIf="showSaveAsTemplate" >
                <div class="row" *ngIf="showSaveAsTemplate">
                    <div class="col-12 ">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="{{'TEMPLATE_MESSAGE_TITLE' | translate }}" [(ngModel)]="templateTitle">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-3 text-right" *ngIf="showSaveAsTemplate" >
                <button class="btn-sicche btn-secondary" [disabled]="!templateTitle" (click)="saveAsTemplateMessage()">{{'SAVE_AS_TEMPLATE_MESSAGE' | translate}}</button>
            </div>
        </div>
    </div>
</app-shared-sidebar-container>

<div class="row" *ngIf="!isSidebar">
    <div class="col-12">
         <!-- template messages -->
     <div class="row my-3" >
        <div class="col-12">
            <label>
                <input type="checkbox" [(ngModel)]="showLoadFromTemplate">
                {{'LOAD_FROM_TEMPLATE_MESSAGE' | translate}}
                <span class="info-tooltip ml-2" placement="top" [ngbTooltip]="'TOOLTIPS.TEMPLATE_MESSAGES' | translate">
                    <i class="far fa-info-circle"></i>
                </span>
            </label>
        </div>
        <div class="col-7" *ngIf="showLoadFromTemplate">
            <div class="row" >
                <div class="col-12 ">
                    <div class="form-group">
                        <app-core-select
                        *ngIf="templateMessages"
                        [classes]="'form-control'"
                        [multiple]="false"
                        [cache]="true"
                        [customList]="templateMessages"
                        [label]="'title'"
                        [searchable]="true"
                        (change)="setTemplateMessage($event)"
                        >
                        </app-core-select>
                        <span class="placeholder">{{'TEMPLATE_MESSAGES' | translate}}</span>

                    </div>
                </div>
            </div>
        </div>
        <div class="col-5 text-right" *ngIf="showLoadFromTemplate">
            <a (click)="showLoadFromTemplate=false" class="clickable mr-2">{{'CANCEL' | translate}}</a>
            <button class="btn-sicche btn-secondary" [disabled]="!currentTemplateMessage" (click)="loadFromTemplate()">{{'LOAD_FROM_TEMPLATE_MESSAGE' | translate}}</button>
        </div>
    </div>
    </div>
    <!-- end messaggi template -->
    <div class="col-12">
        <div class="form-group">
            <input type="text" [(ngModel)]="subject" class="form-control">
            <span class="placeholder">{{ 'MESSAGES_SECTION.SUBJECT' | translate }}</span>
        </div>
    </div>
    <div class="col-12">
        <div class="panel shadow">
            <quill-editor [(ngModel)]="text" [modules]="modules" [styles]="{height: '250px'}" [placeholder]="'MESSAGES_SECTION.MESSAGE' | translate"></quill-editor>
        </div>
    </div>
    <div class="col-12 text-right">
        <button [disabled]="!text || !subject || sending" class="btn-sicche" (click)="send()">{{ 'SEND' | translate }}</button>
    </div>
    <div class="col-12">
        <div class="row mt-4">
            <div class="col-12">
                <label>
                    <input type="checkbox" [(ngModel)]="showSaveAsTemplate">
                    {{'SAVE_AS_TEMPLATE_MESSAGE' | translate}}
                </label>
            </div>
            <div class="col-9" *ngIf="showSaveAsTemplate" >
                <div class="row" *ngIf="showSaveAsTemplate">
                    <div class="col-12 ">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="{{'TEMPLATE_MESSAGE_TITLE' | translate }}" [(ngModel)]="templateTitle">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-3 text-right" *ngIf="showSaveAsTemplate" >
                <button class="btn-sicche btn-secondary" [disabled]="!templateTitle" (click)="saveAsTemplateMessage()">{{'SAVE_AS_TEMPLATE_MESSAGE' | translate}}</button>
            </div>
        </div>
    </div>
</div>
