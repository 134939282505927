<div *ngIf="!operation('requesting').success">
  <h2 class="light">{{ 'REQUEST_RESET_THE_PASSWORD' | translate }}</h2>
  <form *ngIf="form" [formGroup]="form">
      <div class="form-group" [class.is-invalid]="form.get('username').invalid && form.get('username').touched">
        <div class="form-group required">
          <label>
            <input required formControlName="username" type="text" class="form-control" id="username">
            <span class="placeholder">{{ 'USERNAME' | translate }}</span>
          </label>
        </div>
      </div>
      <small *ngIf="operation('requesting').errors?.username">
        <span class="text-danger">{{ ('SERVER_ERRORS.' + operation('requesting').errors.username) | translate }}</span>
      </small>
      <small *ngIf="operation('requesting').errors">
        <span class="text-danger">{{ ('SERVER_ERRORS.' + operation('requesting').errors.getErrorByKey(0)) | translate }}</span>
      </small>
      <div class="text-center w-100">
          <app-core-button-submit
            class="w-100 mt-1 d-block"
            [classes]="'btn-sicche active mx-auto'"
            [spinnerDimension]="'small'"
            [form]="form" 
            (submitCallback)="submit()" 
            [toggle]="operation('requesting').submitting">
            <span>{{ 'RESET' | translate }}</span>
        </app-core-button-submit>
      </div>
  </form>
</div>
<div *ngIf="operation('requesting').success">
  <div class="va success-panel text-center">
    <div>
        <i class="far fa-check mb-4 fa-3x text-primary-color"></i>
    </div>
    <h3>{{ 'REQUEST_HAS_BEEN_SENT' | translate }}</h3>
    <p>{{ 'ASK_PASSWORD_RESET.SUCCESS_MESSAGE' | translate }}</p>
    <br/>
    <!-- <a class="as-link" (click)="closeModal()">{{ 'OK_I_UNDERSTAND' | translate }}</a>-->
  </div>
</div>