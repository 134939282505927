import { Component, OnInit, ViewChild, AfterViewInit, Input, EventEmitter, Output } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { QuestionsService } from '@app/shared/services/questions.service';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { AnswersService } from '@app/shared/services/answers.service';
import { ResponseModel } from '@app/core/models/response.model';
import { AnswerModel } from '@app/shared/models/answer.model';
import { AuthService } from '@app/core/services/auth.service';
import { FormGroup, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@app/core/services/modal.service';
import { TeamService } from '@app/shared/services/team.service';
import { NotificationService } from '@app/shared/services/notification.service';
import { TeamModel } from '@app/shared/models/team.model';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { LightboxModalComponent } from '@app/core/components/lightbox-modal/lightbox-modal.component';
import { CommentModel } from '@app/shared/models/comment.model';
import { ModerationHelperService } from '@app/shared/services/moderation-helper.service';
import { QuoteModel } from '@app/shared/models/quote.model';

@Component({
  selector: 'app-moderation-answer-comment-media',
  templateUrl: './comment-media.component.html',
  styleUrls: [ './comment-media.component.scss']
})
export class AnswersBlockCommentMediaComponent extends CoreBaseComponent implements OnInit {

  @Input()
  comment: CommentModel;
  @Output()
  transcriptionSelection: EventEmitter<any> = new EventEmitter();
  @Input()
  transcriptionBottom = false;
  public showMediaTabs = false;
  public mediaTab = 'images';

  constructor(private questionsService: QuestionsService, private coreLayoutService: CoreLayoutService,
              private notificationService: NotificationService, private moderationHelperService: ModerationHelperService,
              public answerService: AnswersService, public authService: AuthService, private modalService: ModalService,
              private toastService: ToastrService, private translate: TranslateService, private teamService: TeamService) {
    super();
  }

  ngOnInit(): void {
    if(this.comment.hasMedia()) {
        if(this.comment.hasVideos() && this.comment.hasImages()) {
            this.showMediaTabs = true;
        } else if(this.comment.hasVideos() && !this.comment.hasImages() && !this.comment.hasAudios()) {
            this.mediaTab = 'videos';
        } else if(this.comment.hasImages() && !this.comment.hasVideos() && !this.comment.hasAudios()) {
            this.mediaTab = 'images';
        } else if(this.comment.hasAudios() && !this.comment.hasVideos() && !this.comment.hasImages()) {
            this.mediaTab = 'audios';
        }
    }
    this.addTranscriptionQuotes();
  }

  addTranscriptionQuotes(reset = false) {
    if(!this.teamService.isCurrentUserCustomerPro() && !this.teamService.isCurrentUserCustomerBasic()) {
        if(this.comment.quotes) {
            this.copyOriginalText(reset);
            this.printQuotes(this.comment.quotes);
        }
        this.moderationHelperService.quotes.subscribe(quotes => {
            if(quotes) {
              this.copyOriginalText(reset);
              this.printQuotes(quotes);
            }
        });
    }
  }

  copyOriginalText(reset = false) {
    // Video transcription
    this.comment.videos().forEach(video => {
      video.hlTranscription = '';
      if (video.video_data.file_words) {
        video.video_data.file_words.forEach(word => {
          video.hlTranscription += word.word + ' ';
        });
      }
    });
  }

  printQuotes(quotes: Array<QuoteModel>) {
    quotes.forEach(quote => {
      if(this.comment.videos().length && quote.commentid === this.comment.id) {
        this.comment.videos().forEach(video => {
          this.hlTranscription(video, quote);
        });
      }
    });
  }

  private hlTranscription(video: any, quote: QuoteModel) {
    if(!video.hasOwnProperty('hlTranscription')) {
      video.hlTranscription = '';
      video.video_data.file_words.forEach(word => {
        video.hlTranscription += word.word + ' ';
      });
    }
    const qclass = quote.color ? quote.color : 'grey';
    video.hlTranscription = video.hlTranscription.replace(quote.quote, '<em class="highlight ' + qclass + '">' + quote.quote + '</em>');
  }

  openLightbox(image: any | number, mediaArray?: Array<any>) {
    this.modalService.open({modalModel: LightboxModalComponent, dialogSize: 'custom'}, {image, images: mediaArray});
  }

  setVideoTime(commentID, start, end) {
    const video: any = document.getElementById('com_video_player_' + commentID);
    video.currentTime = start / 1000;
  }

  selectText(comment) {
    this.transcriptionSelection.emit(comment);
  }

}
