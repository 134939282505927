import { Component, OnInit, EventEmitter, Output, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-setup-questions-media-upload',
  templateUrl: './media-upload.component.html',
  styleUrls: ['./media-upload.component.scss']
})
export class QuestionsMediaUploadComponent extends CoreBaseComponent implements OnInit, OnDestroy {

  @Input() formArray: UntypedFormArray;
  @Input() formArrayName = 'images';
  @Input() mediaType = 'image';
  @Input() form: UntypedFormGroup;
  @Input() requiredRatio: string = null;
  @Input() forceImgSquareView = false;
  @Input() multipleAllowed = true;
  @Output() inputError = new EventEmitter<{ error: string, params?: { [k: string]: string } }>();

  // video upload not through base64
  @ViewChild('video') video: ElementRef;
  videoFromExternal = false;
  formChangeSub: Subscription = null;
  loading = false;

  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  ngOnInit(): void {
    if (this.formArray.controls.length && this.formArray.controls[0].value) {
      this.videoFromExternal = true;
    }

    this.formChangeSub = this.formArray.valueChanges.subscribe(v => {
      if (this.formArray.controls.length === 0) {
        // if at any time the video is deleted, it has to be re-uploaded from "internal"
        this.videoFromExternal = false;
      }
    })
  }

  freeObjectURL() {
    if (!this.videoFromExternal && this.video) {
      const URL = window.URL || window.webkitURL;
      URL.revokeObjectURL(this.video.nativeElement.src)
    }
  }

  ngOnDestroy(): void {
    this.freeObjectURL();
    if (this.formChangeSub) this.formChangeSub.unsubscribe();
  }

  changeImagesOrder(e: CdkDragDrop<string[]>) {
    this.arrayMove(this.formArray.value, e.previousIndex, e.currentIndex, this.formArray);
  }

  deleteImg(index: number) {
    this.formArray.removeAt(index);
    /*
    imageFormGroup.patchValue({
      image: null,
      image_url: null,
      image_del: true
    });
    */
  }

  safeImgStyle(txt: string) {
    return this.sanitizer.bypassSecurityTrustStyle(txt);
  }

  deleteAll() {
    while (this.formArray.length !== 0) {
      this.formArray.removeAt(0);
    }
  }

  selectMedia(event) {
    if (this.mediaType === 'image') {
      this.selectPictures(event);
    }
    if (this.mediaType === 'video') {
      this.selectVideos(event);
    }
  }

  selectPictures(event) {
    if (!event || !event.target.files.length) { return; }
    this.loading = true;
    let processed = 0;
    for (let i = 0; i < (event.target.files as FileList).length; i++) {
      const selectedFile = event.target.files[i];
      const reader = new FileReader();
      this.operation('receiving-image').reset().isSubmitting();
      const mimeType = selectedFile.type;
      if (mimeType.match(/image\/*/) == null) {
        this.operation('receiving-image').isFailed();
        return;
      }

      reader.readAsDataURL(selectedFile);
      reader.onload = () => {
        if (this.requiredRatio) { // no more used
          const wh = this.requiredRatio.split(':').map(x => parseInt(x, 10));
          const img = new Image();
          img.onload =  () => {
            if (img.width / img.height !== wh[0] / wh[1]) {
              this.inputError.emit({
                error: 'ERRORS.WRONG_IMAGE_RATIO',
                params: { ratio: this.requiredRatio, filename: selectedFile.name }
              });
            } else {
              this.formArray.push(new UntypedFormGroup({
                image: new UntypedFormControl(reader.result, null),
                new: new UntypedFormControl(true, null),
              }));
            }
          };
          img.src = reader.result as string;
        } else {
          const img = new Image();
          img.onload = () => {
            this.formArray.push(new UntypedFormGroup({
              image: new UntypedFormControl(this.imageToDataUri(img, img.width, img.height), null),
              image_render: new UntypedFormControl(this.imageToDataUri(img, img.width, img.height)),
              new: new UntypedFormControl(true, null),
            }));
            processed++;
            this.loading = processed < (event.target.files as FileList).length;
          };

          img.src = reader.result as string;
        }
        this.operation('receiving-image').isSuccess();
      };
    }
  }

  imageToDataUri(image: any, width: number, height: number) {
    // create an off-screen canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    // set its dimension to target size
    canvas.width = width;
    canvas.height = height;
    // draw source image into the off-screen canvas:
    ctx.drawImage(image, 0, 0, width, height);
    // encode image to data-uri with base64 version of compressed image
    return canvas.toDataURL();
  }

  getResizedImageUri(img: any, maxmin: number): any {
    // maxmin is the maximum size of the shortest dimension
    // ex: 1200x800 minmax = 400 gives 600x400
    const ratio = img.width / img.height;
    const w = ratio >= 1 ? (img.height > maxmin ? Math.ceil(ratio * maxmin) : img.width) : (img.width > maxmin ? maxmin : img.width);
    const h = ratio >= 1 ? (img.height > maxmin ? maxmin : img.height) : (img.width > maxmin ? Math.ceil(maxmin / ratio) : img.height);
    return this.imageToDataUri(img, w, h);
  }

  selectVideos(event) {
    if (!event || !event.target.files.length) { return; }
    this.loading = true;

    this.addVideo(event.target.files[0]);
    this.loading = false;

    // let processed = 0;
    // for (let i = 0; i < (event.target.files as FileList).length; i++) {
    //   const selectedFile = event.target.files[i];
    //   const reader = new FileReader();
    //   this.operation('receiving-video').reset().isSubmitting();
    //   const mimeType = selectedFile.type;
    //   if (mimeType.match(/video\/*/) == null) {
    //     this.operation('receiving-video').isFailed();
    //     return;
    //   }
    //   reader.readAsDataURL(selectedFile);
    //   reader.onload = () => {
    //     this.formArray.push(new FormGroup({
    //       video: new FormControl(reader.result, null),
    //       new: new FormControl(true, null),
    //     }));
    //     this.operation('receiving-video').isSuccess();
    //     processed++;
    //     this.loading = processed < (event.target.files as FileList).length;
    //   };
    // }
  }

  addVideo(file: File) {
    const max = 2 * Math.pow(1024, 3); // 1GB
    this.videoFromExternal = false;
    if (file.size >= max) {
      this.inputError.emit({
        error: 'ERRORS.VIDEO_TOO_BIG',
        params: { filename: file.name }
      });
    } else {
      this.formArray.push(new UntypedFormGroup({
        video: new UntypedFormControl(file, null),
        new: new UntypedFormControl(true, null),
      }));
      const URL = window.URL || window.webkitURL;
      this.video.nativeElement.src = URL.createObjectURL(file);
      this.video.nativeElement.load();
    }
  }

  deleteVid() {
    if (!this.videoFromExternal) {
      const URL = window.URL || window.webkitURL;
      URL.revokeObjectURL(this.video.nativeElement.src.src);
    }
    this.formArray.removeAt(0);
  }

}
