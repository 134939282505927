import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from '@app/core/services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';

@Component({
  selector: 'app-private-toolbar-logout',
  templateUrl: './toolbar-logout.component.html',
  styleUrls: ['./toolbar-logout.component.scss']
})
export class ToolbarLogoutComponent implements OnInit, OnDestroy {

  constructor(private toastService: ToastrService, private modalService: ModalService,
              private translate: TranslateService, private router: Router, private authService: AuthService) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  logout() {
    this.modalService.open({title: 'ARE_YOU_SURE.?',
        btnOkText: 'YES',
        btnCancelText: 'CANCEL',
        showBtnClose: false,
        showCancelBtn: true,
        customClass: 'confirm-modal',
        message: 'ARE_YOU_SURE_LOGOUT.?'
      }).then((data) => {
        if (data) {
          this.authService.logout('/login', true).subscribe(res => {
            if (res.data.status === 'loggedout') {
              this.toastService.success(this.translate.instant('LOGGED_OUT_CORRECTLY'), 'Log out');
              this.router.navigateByUrl('/login');
            } else {
              this.toastService.error(this.translate.instant('LOGGING_OUT_ERROR'), 'Log out');
            }
          });
        }
    });
  }
}
