import { Component, OnInit } from '@angular/core';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { OverviewModalComponent } from '@app/pages/layers/private/researches/navigation/analysis/overview-modal/overview-modal.component';
import { AnalysisService } from '@app/shared/services/analysis.service';
import { ResponseModel } from '@app/core/models/response.model';
import { ActivatedRoute } from '@angular/router';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { SmartUserSetupComponent } from './smart-setup-modal/smart-setup-modal.component';
import { ModalService } from '@app/core/services/modal.service';
import { CountryService } from '@app/shared/services/country.service';
import { TeamService } from '@app/shared/services/team.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss']
})
export class AnalysisComponent extends CoreBaseComponent implements OnInit {

  private countryUniqid = null;
  public overview = null;
  public smartConfig = {
    show_video: false,
    show_image: false,
    show_audio: false,
    show_star: false,
    show_graph: false,
    show_tagcloud: false,
    show_all_posts: false
  };
  public isSmart = false;
  public isCustomerPro = false;
  loadingExport = false;

  contentTags: String[] = [];

  constructor(private coreLayoutService: CoreLayoutService, private activatedRoute: ActivatedRoute,
              private analysisService: AnalysisService, private modalService: ModalService, public countryService: CountryService,
              public teamService: TeamService, private toastService: ToastrService, private translate: TranslateService) {
                super();
              }

  ngOnInit(): void {


    this.isSmart = this.countryService.currentCountryIsClientBasic();
    this.isCustomerPro = this.teamService.isCurrentUserCustomerPro();
    this.operation('loading-stats').reset().isSubmitting();
    this.countryUniqid = this.activatedRoute.snapshot.parent.paramMap.get('country_uniqid');
    if (this.isSmart) {
      this.analysisService.getSmartConfig(this.countryUniqid)
      .subscribe((res: ResponseModel) => {
        this.smartConfig = res.getData();
      });
    }
    this.analysisService.get(this.countryUniqid).subscribe((res: ResponseModel) => {
      this.overview = res.getData();
      this.operation('loading-stats').isSuccess();
    });

    this.countryService.updateContentTags(this.countryUniqid)

    // load shared items
    this.countryService.updatePersonasTags(this.countryUniqid);
    this.countryService.updateContentTags(this.countryUniqid);
  }

  // spostato in gestione intervistati
  /* openSmartSetupModal() {
    this.modalService.open({modalModel: SmartUserSetupComponent, dialogSize: 'custom'}, {countryUniqId: this.countryUniqid});
  } */

  openOverviewModal(type: string) {
    this.coreLayoutService.openSidebar(OverviewModalComponent, {type, countryUniqId: this.countryUniqid});
  }

  getPdfExport() {
    if (!this.loadingExport) {
      this.loadingExport = true;
      this.analysisService.getExportPdfEmail(this.countryUniqid).subscribe((res: ResponseModel) => {
        this.loadingExport = false;
        this.toastService.success(this.translate.instant('PDF_EXPORT_ALL_EMAIL_SENT', {email: this.teamService.currentUser.email}), null, {timeOut: 10000});
      });
    }
  }

  getXlsExport() {
    if (!this.loadingExport) {
      this.loadingExport = true;
      this.analysisService.getExportCountryAsXls(this.countryUniqid).subscribe((res: ResponseModel) => {
        this.loadingExport = false;
        this.toastService.success(this.translate.instant('PDF_EXPORT_ALL_EMAIL_SENT', {email: this.teamService.currentUser.email}), null, {timeOut: 10000});
      });
    }
  }

}
