import { ResearchModel } from './../models/research.model';
import { Injectable } from '@angular/core';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { BaseService } from '@app/core/services/base.service';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { DiscountModel } from '../models/discount.model';


@Injectable({
  providedIn: 'root'
})
export class DiscountCodesService extends BaseService implements BaseServiceI {

  constructor(public router: Router, public apiService: ApiService, private http: HttpClient) {
    super(router, apiService, DiscountModel);
  }

  verifyCode(data: any) {
    return this.sub('validity-check').create(data); //  fake create, it is a get-like
  }

}
