<section>
    <form [formGroup]="form">
        <div class="container-fluid">

            <app-setup-questions-shared-media-upload
                [question]="question"
                [images]="attachedImages"
                [pdfFiles]="attachedPdfFiles"
                (addImageOut)="addImage($event)"
                (removeImageOut)="removeImage($event)"
                (addPdfOut)="addPdf($event)"
                (removePdfOut)="removePdf($event)">
            </app-setup-questions-shared-media-upload>

            <h4 class="mb-2">{{ 'QUESTION' | translate }}</h4>
            <div class="question-text panel shadow">
                <app-setup-questions-text [form]="form" [countryUniqId]="countryUniqId"></app-setup-questions-text>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <h4>{{ 'CATEGORIES' | translate }}</h4>
                </div>
                <div class="col-7">
                    <div class="form-group align-items-start">
                        <div formArrayName="categories" class="question-options-list">
                            <div *ngFor="let category of categories.controls; let i = index;" class="category-row">
                                <div class="category my-2 d-flex justify-content-between">
                                    <div class="text-input-no-img">
                                        <label>
                                            <input [formControlName]="i" type="text" class="form-control">
                                            <span class="placeholder">{{ 'CATEGORY' | translate }}</span>
                                        </label>
                                    </div>
                                    <div class="category-buttons text-center">
                                        <div>
                                            <i
                                                *ngIf="i >= 2"
                                                (click)="removeCategory(i)"
                                                class="fal fa-minus-circle mr-2 as-link"
                                                style="font-size: 28px;">
                                            </i>
                                            <i
                                                *ngIf="(i+1) === categories['controls'].length"
                                                (click)="addCategory()"
                                                class="fal fa-plus-circle as-link" 
                                                style="font-size: 28px;">
                                            </i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <h4>{{ 'ITEMS' | translate }}</h4>
                </div>
                <div class="col-7">
                    <div class="form-group align-items-start">
                        <div formArrayName="answers" class="question-options-list" cdkDropList (cdkDropListDropped)="changeQuestionsOrder($event)">
                            <div *ngFor="let answer of form.get('answers')['controls']; let i = index;" class="answer-row my-2" cdkDrag>
                                <div class="answer">
                                    <div class="handler">
                                        <i class="far fa-grip-lines" style="font-size: 18px;"></i>
                                    </div>
                                    <app-setup-questions-answer-option [form]="answer" [answer]="answer"></app-setup-questions-answer-option>
                                    <div class="category-buttons text-center">
                                        <div>
                                            <i (click)="removeAnswer(i)" *ngIf="i >= 1" class="fal fa-minus-circle mr-2 as-link" style="font-size: 28px;"></i>
                                            <i
                                                (click)="addAnswer()"
                                                *ngIf="(i+1) === form.get('answers')['controls'].length"
                                                class="fal fa-plus-circle as-link" 
                                                style="font-size: 28px;"></i>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4 d-flex align-items-start my-2">
                    <div class="form-group mb-0" formGroupName="question_data">
                        <label for="randomize-order" class="d-flex align-items-center input-height">
                            <input formControlName="randomize_order" type="checkbox" id="randomize-order">
                            {{ 'RANDOMIZE_ITEMS_ORDER' | translate }}
                        </label>
                    </div>
                </div>
            </div>
            <app-setup-questions-selects [question]="question"  [block]="block" [form]="form" [countryUniqId]="countryUniqId"></app-setup-questions-selects>
            <h4 *ngIf="block.is_room_group" class="mb-2">{{ 'GENERALS' | translate }}</h4>
            <div *ngIf="!block.is_room_group" class="row question-checks mt-3">
                <div class="col-6">
                    <div class="mb-3">
                        <input formControlName="is_masked" type="checkbox" id="check2">
                        <label for="check2">
                            {{ 'MASQUERADE_QUESTION' | translate }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </form>
</section>