import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResponseModel } from '@app/core/models/response.model';
import { AnswersChartsService } from '@app/pages/layers/private/researches/navigation/moderation/answers-modals/answers-charts.service';
import { AnswerModel } from '@app/shared/models/answer.model';
import { BlockModel } from '@app/shared/models/block.model';
import { CommentModel } from '@app/shared/models/comment.model';
import { AnalysisService } from '@app/shared/services/analysis.service';
import { LanguagesService } from '@app/shared/services/languages.service';
import { QuestionsTypesService } from '@app/shared/services/questions-types.service';
import * as Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';
exporting(Highcharts);

@Component({
  selector: 'app-answer-export',
  templateUrl: './answer-export.component.html',
  styleUrls: ['./answer-export.component.scss']
})
export class AnswerExportComponent implements OnInit {

  countryUniqid: string;
  dataUniqid: string;
  mediaPlaceholder = true;

  chart: any = {options: null};
  ready = false;

  data: any;
  blocksByDays: { [roomid: number]: { [day: string]: BlockModel[] } } = { };
  charts: { [questionid: number]: { dim: { w: string, h: string }, options: any } } = { };
  public Highcharts: typeof Highcharts = Highcharts;
  forcedDateLocale = 'en-US'; // TODO nei dati della ricerca c'è il language, va in qualche modo tradotto da "en" in "en-US"
  constructor(
    private activatedRoute: ActivatedRoute,
    private analysisService: AnalysisService,
    private qtypeSvc: QuestionsTypesService,
    private answersChartsService: AnswersChartsService,
    private cd: ChangeDetectorRef,
    private languageService: LanguagesService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.activatedRoute.queryParams.subscribe(params => {
      this.mediaPlaceholder = !(params.placeholders === '0');
      this.countryUniqid = params.country_uniqid;
      this.dataUniqid = params.data_uniqid;

      this.analysisService.getAllData(this.countryUniqid, this.dataUniqid).subscribe(async (res: ResponseModel) => {
        this.data = res.getData();
        this.languageService.changeLanguage(this.data.country?.language_code);

        document.documentElement.style.setProperty('--orange', this.data.country.color);
        Object.keys(this.data.rooms).forEach(k => {
          this.blocksByDays[k] = this.getRoomBlocksByDay(this.data.rooms[k]);
        });
        await this.chartsSetup();
        // se il render dei template avviene prima di aver definito i dati per i grafici a volte non li carica
        this.ready = true
        // tslint:disable: no-string-literal
        setTimeout(() => {
          const roomElements = document.querySelectorAll('.room-wrap');
          let maxHeight = 0;
          roomElements.forEach(el => {
            maxHeight = (maxHeight < el['offsetHeight']) ? el['offsetHeight'] : maxHeight;
          });

          const body = document.body;
          const html = document.documentElement;
          const height = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
          document['page_height'] = height;
        });
      });
    });
  }

  arrayFromObj(obj: { [k: string]: any } | { [k: number]: any }): any[] {
    return Object.keys(obj).map(k => obj[k]);
  }

  hasChart(question): boolean {
    return this.answersChartsService.hasChart(question);
  }

  async chartsSetup(): Promise<void> {
    const getChart = async (question: any) => {
      if (this.answersChartsService.hasChart(question)) {
        this.charts[question.id] = {
          dim: await this.answersChartsService.calculateChartDim(question),
          options: { },
        };
        const dim = await this.answersChartsService.calculateChartDim(question);
        await this.answersChartsService.calculateChartData(question, this.arrayFromObj(question.answers), this.charts[question.id].options, dim);
        this.cd.detectChanges()
      }
    };

    const promises = { };
    this.arrayFromObj(this.data.rooms).forEach(room =>
      this.arrayFromObj(room.blocks).forEach(block =>
        this.arrayFromObj(block.questions).forEach(async (question) =>
          promises[question.id] = await getChart(question)
        )
      )
    );
    // await Promise.all(Object.keys(promises).map(k => promises[k]));
  }

  getRoomBlocksByDay(room: any) {
    const groupBy = <T, K extends keyof any>(
      list: T[],
      getKey: (item: T) => K
    ) =>
      list.reduce(
        (previous, currentItem) => {
          const group = getKey(currentItem);
          if (!previous[group]) {
            previous[group] = [];
          }
          previous[group].push(currentItem);
          return previous;
        },
        {} as Record<K, T[]>
      );

    return groupBy(
      Object.keys(room.blocks).map(k => room.blocks[k]) as BlockModel[],
      i => (i.datetime_start as unknown as string).split(' ')[0]
    );
  }

  getQuestionType(slug: string): any {
    return this.qtypeSvc.getBySlug(slug);
  }

  getInstance(cl: string, obj: any): any {
    const map = {
      answer: AnswerModel,
      comment: CommentModel
    };
    const r = new map[cl]();
    Object.keys(obj).map(k => r[k] = obj[k]);
    return r;
  }
}
// bomqiwsmitba
// pdf_data_3eced50daeb8d4f719514780d8b796ef
// http://localhost:4200/#/answer-export?country_uniqid=bomqiwsmitba&data_uniqid=pdf_data_338_16135778aecd8706b3f75e739c60e786
