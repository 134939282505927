import { BaseModel } from '@app/core/models/base';
import { MessageInterface } from './message.interface';

export class MessageModel extends BaseModel implements MessageInterface {

    // tslint:disable: variable-name
    messageid: string;
    user_ids: string[];
    msg: string;
    msg_disclaimer?: string;
    other_data: {
        recipient_user_firstname: string,
        recipient_room: any,
        recipient_filters: any,
        recipient_variable_tags: any,
        is_scheduled?: boolean
    };
    sent: boolean;
    country_uniqid: string;
    type: string;
    roomid?: number;
    subject: string;
    date_send: string;
    include_password?: boolean;
    include_link?: boolean;
    read_by_users: number[];
    user_data: {
        avatar_url: string,
        email: string,
        firstname: string,
        lastname: string,
        nickname: string,
        userid: number,
    }[]
    send_now: boolean;
    isCustomer: boolean;

    static getResourceName() {
        return 'messages';
    }

}
