import { Component, OnInit, Input, TemplateRef, OnChanges, ChangeDetectionStrategy, DoCheck, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { PageModel } from '@app/core/models/page';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-core-table',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  /**
   * TR template: see example
   */
  @Input()
  rowTemplate: TemplateRef<any>;

  /**
   * Items list (ex. array of objects or simple array)
   */
  @Input()
  _items: Array<any> = [];

  @Output() columnClick = new EventEmitter();

  @Input()
  set items(val: any) {
    this._items = val;
    this.reset();
  }

  @Input()
  service: BaseService;

  @Input()
  pagination = false;

  /**
   * Columns
   */
  @Input()
  columns: Array<any> = [{
    name: 'ID',
    type: 'number',
    class: '',
    icon_name: '',
    sorter: '',
    tooltip: '',
    placement: null
  }, {
    name: 'NAME',
    type: 'string',
    class: '',
    icon_name: '',
    sorter: '',
    tooltip: '',
    placement: null
  }, {
    name: 'SURNAME',
    type: 'string',
    class: '',
    icon_name: '',
    sorter: '',
    tooltip: '',
    placement: null
  }];

  /**
   * How many items to show per page
   */
  @Input()
  perPage = 3;
  @Input()
  errorMessage = 'THERE_ARE_NO_ITEMS';
  /**
   * How many items to show per page
   */
  @Input()
  serviceMethod = 'find';

  /**
   * Table's id
   */
  @Input()
  tableid = '';


  @Input()
  query: any = null;
  @Input()
  observable: Observable<any> = null;

  // System props
  public itemsToShow = [];
  public pages = 0;
  public currentPage = 0;
  public totalItemsFound = 0;
  public loading = false;
  private ready = false;

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.ready = true;
    if (this.service) {
      this.filterByService();
    } else {
      this.reset();
    }
  }

  reset() {
    if (this.ready) {
      this.currentPage = 0;
      this.pages = Math.ceil(this._items.length / this.perPage);
      if (!this.service) {
        this.filter();
      }
    }
  }

  filterByService() {
    this.loading = true;
    let params = null;
    params = {page_size: this.perPage, page_index: this.currentPage};
    // Extend the query params
    if (this.query) {
      Object.assign(params, {filter: {...this.query}});
    }
    (this.observable ? this.observable : this.service[this.serviceMethod](params))
      .subscribe(this.handlResponse.bind(this));
  }

  private handlResponse(response: PageModel) {
    this.loading = false;
    this.pages = Math.round(response.getTotalFound() / this.perPage); // todo: check
    this.totalItemsFound = response.getTotalFound();
    this.itemsToShow = response.getData();
    this.changeDetectorRef.detectChanges();
  }

  filter() {
    this.totalItemsFound = this._items.length;
    this.itemsToShow = this._items.slice(
      this.currentPage * this.perPage,
      (this.perPage * this.currentPage) + this.perPage);
  }

  right() {
    if (this.currentPage < this.pages - 1) {
      this.currentPage++;
    }
    if (!this.service) {
      this.filter();
    } else {
      this.filterByService();
    }
  }

  left() {
    if (this.currentPage > 0) {
      this.currentPage--;
    }
    if (!this.service) {
      this.filter();
    } else {
      this.filterByService();
    }
  }

  clickOnColumn(name) {
    this.columnClick.emit(name);
  }

}
