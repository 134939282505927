import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { AnswerModel } from '@app/shared/models/answer.model';

@Component({
  selector: 'app-moderation-answers-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class AnswersUserAvatarComponent implements OnInit {

  @Input()
  user: any;
  @Input()
  answer: AnswerModel;
  @Input()
  minified = false;
  @Input()
  exactTime = false; // choose pipe for date instead of dateAgo

  constructor() {
  }

  ngOnInit(): void {
  }

  submit() {
  }

}
