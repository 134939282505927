import { Injectable } from '@angular/core';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { BaseService } from '@app/core/services/base.service';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { ResearchModel } from '../models/research.model';
import { CountryModel } from '../models/country.model';


@Injectable({
  providedIn: 'root'
})
export class ResearchSessionService extends BaseService implements BaseServiceI {

  private research$: BehaviorSubject<ResearchModel> = new BehaviorSubject(null);
  private uniqid$: BehaviorSubject<any> = new BehaviorSubject(null);
  private countryUniqId$: BehaviorSubject<string> = new BehaviorSubject(null);
  private subject = new Subject<any>();

  get research() {
    return this.getResearch();
  }

  constructor(public router: Router, public apiService: ApiService, private http: HttpClient) {
    super(router, apiService, ResearchModel);
  }

  getResearch(): ResearchModel {
    return this.research$.value;
  }

  setResearchUniqId(uniqId: any) {
    this.uniqid$.next(uniqId);
  }

  setResearchCountryUniqId(id: string) {
    this.countryUniqId$.next(id);
  }

  getResearchCountryUniqId() {
    return this.countryUniqId$.value;
  }

  getResearchUniqId() {
    return this.uniqid$.value;
  }

  setResearch(research: ResearchModel): ResearchModel {
    this.research$.next(research);
    return this.getResearch();
  }

  updateCountryInResearch(country: CountryModel) {
    const research = this.getResearch();
    let countriesTemp = [];
    for (const c of research.countries) {
      if (c.country_uniqid === country.country_uniqid) {
        countriesTemp = [...countriesTemp, country];
      } else {
        countriesTemp = [...countriesTemp, c];
      }
    }
    research.countries = countriesTemp;
  }

  destroy() {
    this.research$.next(null);
  }

  newEvent(event) {
    this.subject.next(event);
  }

  get events() {
    return this.subject.asObservable();
  }


}
