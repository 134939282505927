import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { RoomModel } from '@app/shared/models/room.model';
import { BlockService } from '@app/shared/services/block.service';
import { ResponseModel } from '@app/core/models/response.model';
import { BlockModel } from '@app/shared/models/block.model';
import { DatePipe } from '@angular/common';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { ResearchSessionService } from '@app/shared/services/research-session.service';
import { ResearchModel } from '@app/shared/models/research.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DateToLocaleStringPipe } from '@app/core/pipes/date-to-locale-string.pipe';
import { ModalService } from '@app/core/services/modal.service';
import { CountryService } from '@app/shared/services/country.service';
import * as angular from 'angular';
import { DatetimeFromDB } from '@app/core/pipes/datetime-from-db.pipe';

@Component({
  selector: 'app-private-setup-manage-questions',
  templateUrl: './manage-questions.component.html',
  styleUrls: ['./manage-questions.component.scss']
})
export class ManageQuestionsComponent extends CoreBaseComponent implements OnInit {

  @Input() room: RoomModel;

  blocks: BlockModel[];
  activeBlocks: BlockModel[];
  days: any;
  day: any;
  lastDay: Date;
  research: ResearchModel;
  daysLoaded = false;
  activeDays = [];

  constructor(
    private blockService: BlockService,
    private datePipe: DatePipe,
    private dateToLocaleString: DateToLocaleStringPipe,
    private dateToEuPipe: DateToLocaleStringPipe,
    private toastService: ToastrService,
    private translateService: TranslateService,
    private researchSessionService: ResearchSessionService,
    private modalService: ModalService,
    private countryService: CountryService,
    private cdRef: ChangeDetectorRef,
    private datetimeFromDB: DatetimeFromDB
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadBlocks();
  }

  selectedDayIsFuture() {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const selectedDate = new Date(this.day);
    if (selectedDate.getTime() >= now.getTime()) {
      return true;
    }
    return false;
  }

  isEditable() {
    if (this.research.editable_in_the_past) {
      return true;
    }
    if (this.selectedDayIsFuture()) {
      return true;
    }
    return false;
  }


  loadBlocks(updateDays = false) {
    const currentDay = this.day ? angular.copy(this.day) : null
    this.operation('loading-blocks').reset().isSubmitting();
    this.blocks = [];
    this.blockService.getBlocksByRoomId(this.room.id).subscribe((res: ResponseModel) => {
      this.operation('loading-blocks').isSuccess();
      const temp = [];
      for (const key in res.getData()) {
        if (res.getData().hasOwnProperty(key)) {
          const element = res.getData()[key];
          element.datetime_start = this.datetimeFromDB.transform(element.datetime_start, this.countryService.getSessionCountry().timezone);
          element.datetime_end = this.datetimeFromDB.transform(element.datetime_end, this.countryService.getSessionCountry().timezone);
          temp.push(element);
        }
      }
      this.blocks = temp;

      if (!this.daysLoaded || updateDays) {
        this.updateDays();
        this.daysLoaded = true;
      }


      this.research = this.researchSessionService.getResearch();
      this.lastDay = new Date(this.parseCompatibilityDate(this.research.date_end_effective));

      if (currentDay) {
        this.day = this.days.find(d => d.date.getTime() == currentDay.getTime()).date
      } else {
        this.day = this.days[0].date;
      }
      this.updateActiveBlock();
    });
  }


  removeBlock(e) {
    this.modalService.open({title: 'ARE_YOU_SURE.?',
        btnOkText: 'YES',
        btnCancelText: 'CANCEL',
        showBtnClose: false,
        showCancelBtn: true,
        customClass: 'confirm-modal',
        message: 'ARE_YOU_SURE_DELETE.?'
      }).then((data) => {
        if (data) {
          this.blockService.removeBlockById(e).subscribe((res: ResponseModel) => {
            const temp = this.blocks.filter(b => b.id !== e);
            this.blocks = temp;
            this.updateActiveBlock();
            this.updateDays();
          });
        }
    });
  }

  updateDays() {
    this.days = [];

    const dateStartRoom = new Date(this.parseCompatibilityDate(this.room.date_start));
    const dateEndRoom   = new Date(this.parseCompatibilityDate(this.room.date_end));
    const currentDate   = dateStartRoom;
    const allBlocks     = angular.copy(this.blocks);

    let daysTemp = [];
    let index    = 1;

    while (currentDate.getTime() <= dateEndRoom.getTime()) {
      let name = `${this.dateToLocaleString.transform(currentDate)}`;
      if (this.hasActiveBlocks(currentDate, allBlocks)) {
        name = `${this.translateService.instant('DAY')} ${index} - ${this.dateToLocaleString.transform(currentDate)}`;
        index++;
      }
      const newDate = {
        date: new Date(currentDate),
        name
      };
      daysTemp = [...daysTemp, newDate];
      const dayWithoutHours = angular.copy(this.day || new Date());
      dayWithoutHours.setHours(0, 0, 0, 0);
      if (this.day && (newDate.date.getTime() === this.day.getTime() || newDate.date.getTime() === dayWithoutHours.getTime())) {
        this.changeDate(newDate);
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    this.days = daysTemp;
    this.cdRef.detectChanges();
  }

  changeDate(e) {
    this.day = e.date;
    // this.updateActiveBlock();
    this.loadBlocks()
    this.cdRef.detectChanges();
  }

  newBlock(block: BlockModel) {
    // this.blocks = [...this.blocks, block];
    this.loadBlocks();
    // this.updateActiveBlock();
  }

  updateActiveBlock() {
    this.activeBlocks = [];
    for (const block of this.blocks) {
      if (
        this.getDateWithoutTime(block.datetime_start).getTime()
        === this.getDateWithoutTime(this.day).getTime()
      ) {
        this.activeBlocks = [...this.activeBlocks, block];
      }
    }
  }

  hasActiveBlocks(day: Date, allBlocks) {
    for (const block of allBlocks) {
      if (
        this.getDateWithoutTime(block.datetime_start).getTime()
        === this.getDateWithoutTime(day).getTime()
      ) {
        return true;
      }
    }
    return false;
  }


  addBlock() {
    this.operation('creating-block').reset().isSubmitting();
    this.blockService.createBlock(this.room, this.day).subscribe((res: ResponseModel) => {
      const newBlock = res.getData()[0];
      if (!this.blocks) {
        this.blocks = [newBlock];
      } else {
        this.blocks = [...this.blocks, newBlock];
      }
      this.updateActiveBlock();
      this.updateDays();
      this.operation('creating-block').isSuccess();
    }, err => {
      console.error(err);
      this.operation('creating-block').isFailed();
      for (const key in err.errors) {
        if (Object.prototype.hasOwnProperty.call(err.errors, key)) {
          const element = err.errors[key];
          this.toastService.error(this.translateService.instant(element));
        }
      }
    });
  }

  printBlockPos(blocks: BlockModel[]) {
    const pos = [];
    for (const b of blocks) {
      pos.push(b.position);
    }
  }

  changeBlockOrder(e) {
    const prevBlock = angular.copy(this.activeBlocks[e.previousIndex]);
    const currBlock = angular.copy(this.activeBlocks[e.currentIndex]);

    const activeBlocksTemp = this.activeBlocks;
    const blocksTemp = this.blocks;
    for (const activeBlock of activeBlocksTemp) {
      if (activeBlock.id === prevBlock.id) {
        activeBlock.position = currBlock.position;
      }
      if (activeBlock.id === currBlock.id) {
        activeBlock.position = prevBlock.position;
      }
    }
    for (const block of blocksTemp) {
      if (block.id === prevBlock.id) {
        block.position = currBlock.position;
      }
      if (block.id === currBlock.id) {
        block.position = prevBlock.position;
      }
    }
    activeBlocksTemp.sort((a, b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0));
    blocksTemp.sort((a, b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0));
    this.activeBlocks = activeBlocksTemp;
    this.blocks = blocksTemp;

    this.blockService.reorder(this.room.id, this.blocks).subscribe((res: ResponseModel) => {
      this.toastService.success(this.translateService.instant('DATA_UPDATED'));
    }, (err) => {
      this.toastService.error(this.translateService.instant('REORDER_FAILED'));
    });
  }
}
