import { Component, OnInit, Input } from '@angular/core';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { ResponseModel } from '@app/core/models/response.model';
import { AnalysisService } from '@app/shared/services/analysis.service';
import { CountryService } from '@app/shared/services/country.service';
import { AnalysisModel } from '@app/shared/models/analysis.model';

@Component({
  selector: 'app-overview-modal-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class OverviewModalVideoComponent extends CoreBaseComponent implements OnInit {

  @Input()
  type: string;
  @Input()
  countryUniqId = null;
  public filterForm: UntypedFormGroup = null;
  public videos: any[] = [];
  public videosAll: any[] = [];
  public isSmart = false;
  public downloading = false;
  offset = 0;
  limit = 12;
  pageSize = 12;
  selectedPage = 0;

  constructor(private coreLayoutService: CoreLayoutService, private countryService: CountryService,
              private translate: TranslateService, public analysisService: AnalysisService) {
    super();
  }

  selectAll(event: any) {
    this.videos.map((image: any) => event.target.checked ? image.state.selected = true : image.state.selected = false);
    this.videosAll.map((image: any) => event.target.checked ? image.state.selected = true : image.state.selected = false);
  }

  hasSelected() {
    return this.videos.find((video: any) => video.state.selected === true);
  }

  ngOnInit(): void {
    this.isSmart = this.countryService.currentCountryIsClientBasic();
    this.coreLayoutService.title('VIDEO');
    // Filter form
    this.filterForm = new UntypedFormGroup({
      room: new UntypedFormControl('', null),
      block: new UntypedFormControl('', null),
      question: new UntypedFormControl('', null),
      sample_variable_tags: new UntypedFormControl('', null),
      personas: new UntypedFormControl('', null),
      block_datetime: new UntypedFormControl('', null),
      answer_tags: new UntypedFormControl('', null),
      rating: new UntypedFormControl('', null),
      only_highlighted: new UntypedFormControl('', null),
      keyword_search_content: new UntypedFormControl('', null),
      intervieweds: new UntypedFormControl('', null),
      only_contest: new UntypedFormControl('', null),
      only_live: new UntypedFormControl('', null),
    });
    // this.getVideosByFilter();
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  getVideosByFilter() {
    let date = '';
    if (this.filterForm.value.block_datetime) {
      date = this.formatDate(this.filterForm.value.block_datetime.date)
    }
    const all_rooms = this.filterForm.value.room ? this.filterForm.value.room.length == 1 && this.filterForm.value.room[0].id == 0 ? true : false : false;
    this.operation('getting-videos').reset().isSubmitting();
    const filter = {
      all_rooms,
      rating: this.filterForm.value.rating ? this.filterForm.value.rating : null,
      only_highlighted: this.filterForm.value.only_highlighted,
      only_contest: this.filterForm.value.only_contest,
      only_live: this.filterForm.value.only_live,
      keyword_search_content: this.filterForm.value.keyword_search_content,
      intervieweds: this.filterForm.value.intervieweds,
      questionid: this.filterForm.value.question ? this.filterForm.value.question.id : null,
      block_datetime: date,
      answer_tags: this.filterForm.value.answer_tags ? this.filterForm.value.answer_tags : [],
      roomids: (this.filterForm.value.room[0].id !== 0) ? this.filterForm.value.room.map(item => item.id) : [],
      blockids: this.filterForm.value.block ? this.filterForm.value.block.map(item => item.id) : null,
      personas_tags: this.filterForm.value.personas ? this.filterForm.value.personas : [],
      team_sample_variable_tags: this.filterForm.value.sample_variable_tags ? this.filterForm.value.sample_variable_tags : []
    };

    Object.keys(filter).forEach((key) => (!filter[key]) && delete filter[key]);

    this.analysisService.getVideosBy(this.countryUniqId, filter).subscribe((res: ResponseModel) => {
      this.operation('getting-videos').isSuccess();
      this.videosAll = res.getData(true).filter(item => item.file_uniqid).map(item => new AnalysisModel(item));
      this.paginateByPage();
    });
  }

  downloadSelected() {
    const ids = [];
    this.videosAll.forEach((image: any) => {
      if (image.state.selected) {
        ids.push(image.file_uniqid);
      }
    });
    this.downloading = true;
    const filter = {
      all_rooms: this.filterForm.value.room ? this.filterForm.value.room.id === 0 ? true : false : false,
      only_highlighted: this.filterForm.value.only_highlighted,
      only_contest: this.filterForm.value.only_contest,
      only_live: this.filterForm.value.only_live,
      keyword_search_content: this.filterForm.value.keyword_search_content,
      file_uniqids: ids,
      rating: this.filterForm.value.rating ? this.filterForm.value.rating : '',
      roomid: this.filterForm.value.room ? this.filterForm.value.room.id : '',
      blockids: this.filterForm.value.block ? this.filterForm.value.block.map(item => item.id) : '',
      questionid: this.filterForm.value.question ? this.filterForm.value.question.id : '',
      block_datetime: this.filterForm.value.block_datetime ? this.filterForm.value.block_datetime.date : '',
      download_zip: true,
      team_personas_tags: this.filterForm.value.personas ? this.filterForm.value.personas : [],
      team_sample_variable_tags: this.filterForm.value.sample_variable_tags ? this.filterForm.value.sample_variable_tags : []
    };

    Object.keys(filter).forEach((key) => (!filter[key]) && delete filter[key]);

    this.analysisService.getDownloadVideosBy(this.countryUniqId, filter).subscribe((res: ResponseModel) => {
      res.download('videos.zip');
      this.downloading = false;
    });
  }

  paginate(add = 0) {
    this.limit += add;
    if (this.limit > this.videosAll.length) {
      this.limit = this.videosAll.length;
    }
    this.videos = this.videosAll.slice(this.offset, this.limit);
  }

  paginateByPage(page = 0) {
    this.offset = this.pageSize * page;
    this.limit = this.pageSize * (page + 1);
    this.selectedPage = page;

    this.paginate();
  }

  getVideosCountAsArr() {
    const arr = [];
    for (let index = 0; index < Math.ceil(this.videosAll.length / 12); index++) {
      arr.push(index);
    }
    return arr
  }

}
