<div class="panel shadow">
    <div class="container-fluid" *ngIf="user">
        <div class="row">
            <div class="col-3">
                <div class="mb-5">
                    <h3 class="mt-4">{{'LAST_LOGIN' | translate}} </h3>
                    <p>
                        {{user.last_login_datetime | dateToLocaleString}}
                    </p>
                </div>
            </div>
            <div class="col-3">
                <div class="mb-5">
                    <h3 class="mt-4">{{'SIGNED_UP_SINCE' | translate}}</h3>
                    <p>
                        {{user.creationtime | dateToLocaleString}}
                    </p>
                </div>
            </div>

            <div class="col-12 mt-3">
                <div class="row" >
                    <div class="col-lg-8 col-xl-8">
                        <h4>{{'USER_MANAGEABLE_DATA' | translate}}</h4>
                        <div class="w-100 py-2">
                            <div class="row mb-3">
                                <div class="col-8 d-flex align-items-center">
                                    
                                    <!--<div class="avatar">-->
                                        <!--<img [src]="user.avatar || (user.avatar_url | privatesrc)" alt="" class="image">-->
                                        <!-- <div class="image" [ngStyle]="{'background-image':'url('+ ((user.avatar_url | privatesrc) || 'assets/images/avatar.png') +')'}"></div> -->
                                        <!-- USARE QUESTO TEMPLATE PER AVATAR
                                        <div class="avatar" *ngIf="avatarUrl">
                                            <div *ngIf="!new" class="image" [ngStyle]="{'background-image':'url('+ (avatarUrl | privatesrc) +')'}"></div>
                                            <div *ngIf="new" class="image" [ngStyle]="{'background-image':'url('+ (avatarUrl) +')'}"></div>
                                        </div>
                                        <i *ngIf="!avatarUrl" class="fal fa-user avatar-placeholder"></i>
                                        </div>
                                        -->
                                    <!--</div>-->
                                    <!--
                                    <div class="ml-2">
                                        <input type="file" id="change-avatar" accept="image/*" (change)="changeAvatar($event.target.files)">
                                        <label for="change-avatar" class="placeholder">{{ 'USER.CHANGE_AVATAR' | translate }}</label>
                                    </div>
                                    -->
                                    <div class="form-group choose-file-input required">
                                        <div (click)="removeAvatar()" class="remove-logo" *ngIf="user.avatar_url || user.avatar">
                                            <i class="fal fa-times"></i>
                                        </div>
                                        <label>
                                            <img [src]="user.avatar || (user.avatar_url | privatesrc)" alt="" class="country-logo">
                                            <span class="placeholder">{{ 'USER.CHANGE_AVATAR' | translate }}</span>
                                            <input type="file" id="change-avatar" accept="image/*" (change)="changeAvatar($event.target.files)">
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <input class="form-control" type="text" [(ngModel)]="user.nickname">
                                        <span class="placeholder">{{ 'USER.NICKNAME' | translate }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <input class="form-control" type="text" [(ngModel)]="user.firstname">
                                        <span class="placeholder">{{ 'USER.FIRSTNAME' | translate }}</span>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <input class="form-control" type="text" [(ngModel)]="user.lastname">
                                        <span class="placeholder">{{ 'USER.LASTNAME' | translate }}</span>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <input class="form-control" type="text" readonly [(ngModel)]="user.email">
                                        <span class="placeholder">{{ 'USER.EMAIL' | translate }}</span>
                                    </div>
                                    <p>{{'IF_YOU_WANT_TO_CHANGE_EMAIL_DELETE_AND_CREATE' | translate}}</p>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <input class="form-control" type="text" [(ngModel)]="user.telephone">
                                        <span class="placeholder">{{ 'USER.TELEPHONE' | translate }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4">
                        <h4>{{'MODERATOR_MANAGEABLE_DATA' | translate}}</h4>
                        <div class="w-100 py-2">
                            <div class="form-group tool tags-select-tool">
                                <!-- TODO -->
                                <app-core-select 
                                    [classes]="'form-control'"
                                    [cache]="true"
                                    [customList]="tags"
                                    [multiple]="true"
                                    (change)="updateTags($event)"
                                    [(ngmodel)]="user.sample_variable_tags"
                                >
                                </app-core-select>
                                <span class="placeholder">{{ 'USER.VARIABLES' | translate }}</span>
                            </div>
                            <div>
                                <input id="user-block" type="checkbox" [checked]="user.is_locked" (click)="lockUser()">
                                <label for="user-block" class="placeholder">{{ 'USER.LOCKED' | translate }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-xl-12 mt-3">
                        <h4>{{'MODERATOR_NOTES' | translate}}</h4>
                        <div class="w-100 py-2">
                            <div class="form-group">
                                <textarea class="form-control" [(ngModel)]="user.note" rows="10"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>