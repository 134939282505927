import { BaseModel } from '@app/core/models/base';

export class DiscountModel extends BaseModel {

    value: number;
    type: string;

    constructor(data?: any) {
        super(data);
    }

    /**
     * Api address
     */
    static getResourceName() {
        return 'discount-codes';
    }

    isValid() {
        if (!this.value && !this.type) {
            return false;
        } else {
            return true;
        }
    }

}
