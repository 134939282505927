import { BaseModel } from '@app/core/models/base';


export class BlogModel extends BaseModel {

    // tslint:disable: variable-name
    title: string;
    date: string;
    category: any;
    text: string;
    image: {url: string, filename: string, description: string};
    url: string;

    static getResourceName() {
        return 'blog';
    }

}
