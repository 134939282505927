import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { AnswerModel } from '@app/shared/models/answer.model';
import { ModalService } from '@app/core/services/modal.service';
import { AnswersService } from '@app/shared/services/answers.service';


@Component({
  selector: 'app-moderation-answerdispatcher',
  templateUrl: './answer-dispatcher.component.html',
  styleUrls: ['./answer-dispatcher.component.scss']
})
export class AnswersModerationDispatcherComponent extends CoreBaseComponent implements OnInit {

  @Input()
  question: QuestionModel;
  @Input()
  item: AnswerModel;
  @Input()
  classes: string;
  @Input()
  message: any;
  @Input()
  explicitMediaUrls = false;
  @Input()
  placeholders = false;
  @Output()
  textSelection: EventEmitter<any> = new EventEmitter(null);


  constructor(private modalService: ModalService, private answerService: AnswersService) {
    super();
  }

  ngOnInit(): void {
  }

  selectText(data) {
    this.textSelection.emit(data);
  }

}
