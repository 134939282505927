import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { BaseService } from '../../services/base.service';
import { ResponseModel } from '../../models/response.model';
import { PageModel } from '@app/core/models/page';
import { UntypedFormGroup } from '@angular/forms';
import { toJSDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';

@Component({
  selector: 'app-core-select',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {

  // tslint:disable-next-line: no-output-native
  @Output()
  change: EventEmitter<any> = new EventEmitter();
  @Output()
  onreset: EventEmitter<any> = new EventEmitter();

  @Input()
  service: BaseService;
  @Input()
  id: string;
  @Input()
  multiple = false;
  @Input()
  readonly = false;
  @Input()
  classes: string;
  @Input()
  label = 'name';
  @Input()
  value: string;
  @Input()
  addTag = null;
  @Input()
  cache = false; // if true, service's data will be stored and reused
  @Input()
  placeholder = '';
  @Input()
  searchable: boolean;
  @Input()
  ngmodel = null;
  @Input()
  mock = false;
  @Input()
  form: UntypedFormGroup = null;
  @Input()
  formEntityName = '';
  @Input()
  query: any = null;
  @Input()
  resetButton: boolean; // show/hide the button to cancel the selected value
  @Input()
  empty: string = null;
  @Input()
  propertyvalue: string = null;
  @Input()
  set customList(val: any) {
    this._customList = val;
    this.reset();
  }
  @Input()
  default: any = null;
  /**
   * ! Il preselected si aspetta un formGroup
   */
  @Input()
  preselected: {byprop, value} = {byprop: null, value: null};

  list: any[];
  loading = true;
  _customList: Array<any>;
  private ready = false;

  constructor(private ref: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.ready = true;
    this.init();
  }

  reset() {
    if (this.ready) {
      this.init();
    }
  }

  resetValue(reactiveFormValue?: boolean) {
    if (reactiveFormValue) {
      try {
        this.form.controls[this.formEntityName].patchValue(null);
        this.onreset.emit();
        this.onChange('');
      } catch (e) {
        console.error('app-core-select', e);
      }
    } else {
      this.ngmodel = null;
      this.onreset.emit();
      this.onChange('');
    }
  }

  init() {
    if (this.mock) {
      this.list = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];
      this.loading = false;
    } else {
      if (this._customList) {
        if (this.propertyvalue) {
          this.list = this._customList.map(item => item[this.propertyvalue]);
        } else {
          this.list = this._customList;
        }
        if (this.empty) {
          this.list.unshift(this.empty);
        }
        this.loading = false;
        this.setPreselected();
      } else if (this.service) {
        this.loading = true;
        const params = {};
        if (this.query) {
          Object.assign(params, this.query);
        }
        this.service.dropdownList({
          page_index: 0,
          page_size: 99999999999999,
          filter: params
        }, this.cache).subscribe((response: PageModel) => {
          const data = response.getList();
          if (this.propertyvalue) {
            this.list = data.map(item => item[this.propertyvalue]);
          } else {
            this.list = response.getList();
          }
          if (this.empty) {
            this.list.unshift(this.empty);
          }
          this.loading = false;
          this.ref.detectChanges();
          this.setPreselected();
        });
      } else {
        this.loading = false;
      }
    }
  }

  setPreselected() {
    const values = [];
    if (this.list && this.preselected.value) {
      if (false) {
        // todo?
      } else {
        this.list.forEach(item => {
          if (Array.isArray(this.preselected.value)) {
            if (this.preselected.byprop) {
              if (this.preselected.value.includes(item[this.preselected.byprop])) {
                values.push(item);
              }
            } else {
              if (this.preselected.value.includes(item)) {
                values.push(item);
              }
            }
          } else {
            if (item[this.preselected.byprop] === this.preselected.value) {
              values.push(item);
            }
          }
        });
      }

      if (values.length) {
        if (this.multiple) {
          this.setVal(values);
        } else {
          this.setVal([values[0]]);
        }
      }

    }
  }

  private setVal(val: any) {
    if (this.form) {
      try {
        this.form.controls[this.formEntityName].patchValue(val);
      } catch (e) {
        console.error('app-core-select', e);
      }
    }
  }

  compareWith(item, selected) {
    // @ts-ignore
    if (this.bindValue) {
      // @ts-ignore
      return item[this.bindValue] == selected;
    } else {
      return item == selected;
    }
  }

  onChange(data: any) {
    this.change.emit(data);
  }

  json(data) {
    return JSON.stringify(data)
  }

  isEmptyArray(data) {
    if (data instanceof Array && data.length === 0) {
      return true;
    }
    return false;
  }

}
