import { Component, OnInit, OnDestroy, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { NotificationService } from '@app/shared/services/notification.service';
import { ActivatedRoute, ParamMap, Router, RoutesRecognized } from '@angular/router';
import { CountryModel } from '@app/shared/models/country.model';
import { CountryService } from '@app/shared/services/country.service';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { ResponseModel } from '@app/core/models/response.model';
import { NoticeModel } from '@app/shared/models/notice.model';
import { ModerationComponent } from '../../researches/navigation/moderation/moderation.component';


@Component({
  selector: 'app-private-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss']
})
export class ToolbarNotificationsComponent extends CoreBaseComponent implements OnInit, OnDestroy {

  constructor(
    private notificationService: NotificationService,
    private countryService: CountryService,
    private router: Router
  ) {
    super();
  }
  /**
   * Test notification
   * to be configured
   */
  public openingNoticeId: number = null;
  private testNotification = true;
  private testNotificationData = {
    type: 'COMMENT',
    roomid: 560,
    blockid: 980,
    research_uniqid: 'boyzmheq',
    creationtime: '',
    country_uniqid: 'boyzmheqitba',
    questionid: 1704,
    commentid: 1060,
    answerid: 0,
    read:false,
    test: true,
    to_userid: 1,
    to_role: 1,
    from_team: {
      avatar_url: null,
      nickname: 'Test user',
      email: 'test@gmail.com'
    }
  };
  countryUniqid: string;
  country: CountryModel;
  notices: NoticeModel[];
  toSetRead: NoticeModel[] = [];
  noticesCount = 0;
  lastUpdate;
  interval = null;

  ngOnInit() {
    this.openingNoticeId = null;
    this.country = this.countryService.getSessionCountry();
    this.countryUniqid = (this.country ? this.country.country_uniqid : null);
    if (this.notificationService.sessionNotices && this.notificationService.noticeCountry === this.countryUniqid ) {
      this.notices = this.notificationService.sessionNotices;
      this.noticesCount = this.notices.filter(n => !n.read).length;
      this.setLikeRead();
    } else {
      if (this.notificationService.getNotices(this.countryUniqid)) {
        this.notificationService.getNotices(this.countryUniqid).subscribe((res: ResponseModel) => {
          this.notices = res.getData();
          //this.notices.push(this.testNotificationData)
          this.lastUpdate = new Date();
          this.noticesCount = this.notices.filter(n => !n.read).length;
          this.updateSessionNotices();
          this.setLikeRead();
        });
      }
    }
    setTimeout(() => {
      this.updateNoticesInterval();
    }, 1000);
  }



  updateNoticesInterval() {
    const interval = 1000 * 60 * 5;
    setInterval(() => {
      if (!this.lastUpdate || (new Date().getTime() - this.lastUpdate.getTime()) > interval) {
        if (this.notificationService.getNotices(this.countryUniqid)) {
          this.notificationService.getNotices(this.countryUniqid).subscribe((res: ResponseModel) => {
            this.lastUpdate = new Date();
            this.notificationService.sessionNotices = res.getData();
            this.notices = res.getData();
            this.noticesCount = this.notices.filter(n => !n.read).length;
          });
        }
      }
    }, interval)
  }

  updateSessionNotices() {
    this.notificationService.sessionNotices = this.notices;
    this.notificationService.noticeCountry = this.countryUniqid;
  }

  setLikeRead() {
    for (const n of this.notices) {
      if (n.type === 'LIKE') {
        this.toSetRead.push(n);
      }
    }
  }

  openNotice(notice: NoticeModel) {
    this.toSetRead.push(notice);
    if(this.openingNoticeId) {
      return false;
    }
    this.openingNoticeId = notice.id;
    if(notice.test) {
      this.notificationService.currentNotice = notice;
        this.router.navigate(['researches', 'navigation', notice.research_uniqid, notice.country_uniqid, 'moderation']).then((nav) => {
          this.openingNoticeId = null;
          if (!nav) {
            this.notificationService.noticeClicked.emit(notice);
          }
        });
      return false;
    }
    // To fasten the opening of the notice, lets open it and later set it as read
    this.noticesCount--;
    if (notice.type !== 'CUSTOMER_ACCESS') {
      this.notificationService.currentNotice = notice;
      this.router.navigate(['researches', 'navigation', notice.research_uniqid, notice.country_uniqid, 'moderation']).then((nav) => {
        if (!nav) {
          this.notificationService.noticeClicked.emit(notice);
        }
      });
    }
    this.updateSessionNotices();
    // Set as read
    this.notificationService.setNoticesRead(this.toSetRead.map(n => n.id), this.countryUniqid).subscribe(() => {
      this.openingNoticeId = null;
      // Make it disappear
      const temp = this.notices.filter(n => n.id !== notice.id);
      this.notices = temp;
    });
  }

  ngOnDestroy() {

  }
}
