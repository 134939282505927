import { Injectable } from '@angular/core';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { BaseService } from '@app/core/services/base.service';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { HttpClient } from '@angular/common/http';
import { QuestionModel } from '../models/question.model';
import { TeamModel } from '../models/team.model';


@Injectable({
  providedIn: 'root'
})
export class QuestionsService extends BaseService implements BaseServiceI {

  constructor(public router: Router, public apiService: ApiService, private http: HttpClient) {
    super(router, apiService, QuestionModel);
  }

  getQuestionsByBlockId(blockids: Array<any>, include_stats = false, only_type_closed = false, otherOptions = {}) {
    return this.sub('questions').get('', {...otherOptions, 'blockids[]': blockids,
      sort_attribute: 'position', sort_order: 'asc', include_stats, only_type_closed});
  }

  getQuestionsByBlockId_b(query) {
    return this.sub('get-all-questions-by-user').get('', {...query, sort_attribute: 'position', sort_order: 'asc', include_stats: true, only_type_closed: false});
  }

  getQuestionsByRoomid(roomid, include_stats = false, only_type_closed = false, otherOptions = {}, country_uniqid) {
    let options = {...otherOptions, sort_attribute: 'position', sort_order: 'asc', include_stats, only_type_closed};
    if(roomid) {
      // @ts-ignore
      options.roomid = roomid;
    }
    if(country_uniqid) {
      // @ts-ignore
      options.country_uniqid = country_uniqid;
    }
    return this.sub('questions').get('',
      options
    );
  }

  getAutomaticFilterQuestions(roomid: number, blockid: number) {
    return this.get('automatic-filter-questions', {roomid, blockid});
  }

  duplicateQuestion(question: QuestionModel) {
    return this.sub('questions/' + question.id.toString() + '/duplicate').create({});
  }

  deleteQuestion(question: QuestionModel) {
    return this.deleteById('/question-management/questions/', question.id.toString());
  }

  teamsWhoAnswered(question: QuestionModel) {
    return this.as(TeamModel).get('questions/' + question.id.toString() + '/teams-who-answered');
  }

  createQuestion(data: any, form: FormData = new FormData()) {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        let element = data[key];
        if (element !== null) {
          if (typeof element === 'object') {
            element = JSON.stringify(element);
          }
          form.append(key, element);
        }
      }
    }

    return this.apiService.postFormData('/question-management/questions', form);
  }

  updateQuestion(id: number, data: any, form: FormData = new FormData()) {
    if (!data?.has_minimum_multiple_answers_nr) {
      data.minimum_multiple_answers_nr = 0
    }
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        let element = data[key];
        if (element !== null) {
          if (typeof element === 'object') {
            element = JSON.stringify(element);
          }
          form.append(key, element);
        }
      }
    }


    return this.apiService.postFormData(`/question-management/questions/${id}`, form);
    // return this.sub(`questions/${id}`).create(data);
  }

  reorderQuestions(questions: QuestionModel[], blockid) {
    const data = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < questions.length; i++) {
      questions[i].position = i;
      data.push({
        questionid: questions[i].id,
        position: i,
      });
    }

    return this.sub('reorder').create({blockid, new_order: data});
  }


  getBaseImageSets() {
    return this.get('default-values/image-association');
  }

  getBaseCouples() {
    return this.get('default-values/semantic-differences');
  }
}
