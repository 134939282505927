<app-shared-sidebar-container>
    <app-shared-sidebar-header>
        <div slot="title">
            <h2 class="mb-0" *ngIf="!editUser">{{ 'INTERVIEWEDS.INTERVIEWEDS' | translate }}</h2>
            <span *ngIf="editUser">
                <h2 class="mb-0" >
                    <i class="fal fa-chevron-left clickable mr-2" (click)="editUser = false"></i>
                    {{ currentUser.firstname + ' ' + currentUser.lastname }}
                </h2>
            </span>
        </div>
        <div slot="buttons" class="text-right" *ngIf="editUser">
            <button class="btn-sicche" (click)="saveEditUser()">{{ 'SAVE' | translate }}</button>
        </div>
    </app-shared-sidebar-header>

    <div class="sidebar-body">
        <div class="container-fluid" *ngIf="!sendMessage && !editUser">
            <div class="row">
                <div class="col-12 alert shadow" [innerHTML]="'MANAGE_INTERVIEWEDS.ALERT' | translate"></div>
            </div>
            <div class="row">
                <div class="col-3">
                    <div class="panel shadow p-4">
                        <p class="bold">{{ 'MANAGE_INTERVIEWEDS.CREATE_TAG_VARIABLE' | translate }}</p>
                        <!-- <p>{{ 'MANAGE_INTERVIEWEDS.SEPARATE_TAG_BY_COMMA' | translate}}</p> -->
                        <div class="form-group">
                            <!-- <ng-autocomplete
                            [data]="autocompleteTag"  [searchKeyword]="'name'"
                            (inputChanged)='tagAutocomplete($event)'
                            [itemTemplate]="tagTemplate" [debounceTime]="300"
                            [(ngModel)]="tagAutocompleteText"
                            class="form-control"
                            >
                            </ng-autocomplete> -->
                            <input type="text" class="form-control" (keyup.enter)="addTag($event)" id="add-tag-input">
                        </div>

                        <!-- <ng-template #tagTemplate let-item>
                            <a (click)="addTag(item.name)" [innerHTML]="item.name"></a>
                        </ng-template> -->

                        <div class="w-100" *ngIf="country">
                            <span *ngFor="let t of country.sample_variable_tags" class="badge badge-secondary m-1 p-1" contenteditable="false">
                                {{t}}
                                <i class="fal fa-times" (click)="removeTag(t)"></i>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-md-9">
                    <div class="panel shadow p-4">
                        <div class="row">
                            <div class="col-md-4">
                                <p class="bold mb-4">{{ 'MANAGE_INTERVIEWEDS.INSERT_BY_EXCEL' | translate }}</p>
                                <a class="btn-sicche btn-secondary mb-4" [href]="excelDownloadUrl" target="_blank" download>
                                    <i class="fal fa-download"></i>
                                    {{ 'MANAGE_INTERVIEWEDS.DOWNLOAD_EXCEL_TEMPLATE' | translate }}
                                </a>

                                <div class="form-group choose-file-input required">
                                    <label>
                                        <span class="placeholder">{{ 'EXCEL' | translate }}</span>
                                        <input type="file" id="file" (change)="uploadExcel($event.target.files)" style="width: 40%">
                                    </label>
                                </div>
                            </div>

                            <div class="col-md-8">
                                <p class="bold mb-4">{{ 'MANAGE_INTERVIEWEDS.INSERT_ONE_BY_ONE' | translate }}</p>
                                <form action="" *ngIf="form" [formGroup]="form" class="w-100">

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div [class.is-invalid]="form.get('email').invalid && form.get('email').touched">
                                                <div class="form-group required">
                                                <label>
                                                    <!-- <input required formControlName="email" type="email" class="form-control" id="email"> -->
                                                    <ng-autocomplete
                                                    #autocomplete
                                                    required formControlName="email" [data]="autocompleteEmail"
                                                    (inputChanged)='emailAutocomplete($event)' [searchKeyword]="'email'"
                                                    [itemTemplate]="emailTemplate" [debounceTime]="300" (inputCleared)="formGroup()"
                                                    [notFoundTemplate]="notFoundTemplate"
                                                    >
                                                    </ng-autocomplete>

                                                    <ng-template #emailTemplate let-item>
                                                        <a (click)="autocompleteUser(item)" [innerHTML]="item.email || ''"></a>
                                                    </ng-template>
                                                    <ng-template #notFoundTemplate let-notFound>
                                                        <div>{{'NOT_FOUND' | translate}}</div>
                                                    </ng-template>

                                                    <span class="placeholder">{{ 'EMAIL' | translate }}</span>
                                                </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div [class.is-invalid]="form.get('firstname').invalid && form.get('firstname').touched">
                                                <div class="form-group">
                                                <label>
                                                    <input formControlName="firstname" type="text" class="form-control" id="firstname">
                                                    <span class="placeholder">{{ 'FIRST_NAME' | translate }}</span>
                                                </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div [class.is-invalid]="form.get('lastname').invalid && form.get('lastname').touched">
                                                <div class="form-group">
                                                <label>
                                                    <input formControlName="lastname" type="text" class="form-control" id="lastname">
                                                    <span class="placeholder">{{ 'LAST_NAME' | translate }}</span>
                                                </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div [class.is-invalid]="form.get('telephone').invalid && form.get('telephone').touched">
                                                <div class="form-group">
                                                <label>
                                                    <input required inputmode="numeric" pattern="[0-9]*" min="0" formControlName="telephone"  type="number" class="form-control hide-input-arrows" id="telephone">
                                                    <span class="placeholder">{{ 'TELEPHONE' | translate }}</span>
                                                </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group tool tags-select-tool w-100">
                                                <app-core-select
                                                    [classes]="'form-control'"
                                                    [placeholder]="'Tags: '"
                                                    [multiple]="true"
                                                    [cache]="true"
                                                    [form]="form"
                                                    [formEntityName]="'sample_variable_tags'"
                                                    [customList]="country.sample_variable_tags"
                                                    [label]="'name'"
                                                    [value]="'value'"
                                                    [searchable]="true">
                                                </app-core-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col text-right">
                                            <button [disabled]="!this.form.value.email || submitting" (click)="submitForm()" class="btn-sicche"><i class="fal fa-plus"></i>{{ 'ADD' | translate }}</button>
                                        </div>
                                    </div>

                                </form>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <app-core-spinner class="mt-3" [toggle]="loadingUsers"></app-core-spinner>
            <div class="row">

                <div class="col mt-4" *ngIf="users">
                    <div class="d-flex justify-content-between align-items-center mt-3 tool-row">
                        <div class="d-flex align-items-center justify-content-end">
                            <button (click)="changeSelectedUsers('all')" class="btn-sicche btn-secondary w-limit">{{ 'MANAGE_TEAM.SELECT_DESELECT_ALL' | translate }}</button>
                            <button [disabled]="selectedUsers.length == 0" class="btn-sicche btn-secondary w-limit" (click)="sendInvite()"><i class="fal fa-envelope"></i>{{'SEND_INVITE' | translate}}</button>
                            <button [disabled]="selectedUsers.length == 0" class="btn-sicche btn-secondary w-limit" (click)="removeUser()"><i class="fal fa-trash-alt"></i>{{'DELETE_USER' | translate}}</button>
                            <!-- <button [disabled]="selectedUsers.length == 0" class="btn-sicche btn-secondary w-limit" (click)="sendPassword()"><i class="fal fa-lock-alt"></i>{{'SEND_PASSWORD' | translate}}</button> -->
                        </div>
                        <div class=" text-right">
                            <h4 class="mb-0">{{ 'MANAGE_INTERVIEWEDS.ADDED_INTERVIEWEDS' | translate }} ({{users.length}})</h4>
                        </div>

                    </div>
                    <div class="panel shadow mt-1">
                        <div class="position-relative">
                            <div class="table-responsive">
                                <app-core-table
                                    [items]="users"
                                    [columns]="columns"
                                    tableid="users"
                                    [perPage]="999999"
                                    (columnClick)="columnClick($event)"
                                    [rowTemplate]="rowTemplate">
                                </app-core-table>
                                <ng-template let-item="item" #rowTemplate>
                                    <tr class="text-center">
                                        <td>
                                            <input type="checkbox" [checked]="selectedUsers.indexOf(item.userid) > -1" (change)="changeSelectedUsers(item.userid)">
                                        </td>
                                        <td class="font-weight-bold">
                                            <i *ngIf="!item.invite_sent && !item.invite_read" class="fal fa-minus"></i>
                                            <i *ngIf="item.invite_sent && !item.invite_read" class="fal fa-envelope"></i>
                                            <i *ngIf="item.invite_sent && item.invite_read" class="fal fa-envelope-open"></i>
                                        </td>
                                        <td class="font-weight-bold text-left">
                                            <a class="clickable" (click)="openEditUser(item)">{{ item.email }}</a>
                                        </td>
                                        <td class="text-left font-weight-bold">
                                            {{ item.firstname }} {{ item.lastname }}
                                        </td>
                                        <td class="text-left font-weight-bold">
                                            {{ item.nickname }}
                                        </td>
                                        <td class="font-weight-bold" *ngFor="let tag of country.sample_variable_tags">
                                            <input type="checkbox" [checked]="item.sample_variable_tags && item.sample_variable_tags.includes(tag)" (change)="updateUserTag(item.userid, tag)">
                                        </td>
                                    </tr>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="sendMessage">
            <app-shared-send-message
            [selectedUsers]="selectedUsers"
            [selectedUsersData]="getUsersByIds(selectedUsers)"
            [country_uniqid]="country.country_uniqid"
            [type]="'INVITE'"
            [isInterview]="true"
            [isSidebar]="false"
            (msgSent)="msgSent()"
            ></app-shared-send-message>
        </div>
        <div *ngIf="editUser">
            <app-private-edit-interviewed [tags]="country.sample_variable_tags" [user]="currentUser" (edited)="editUser = false"></app-private-edit-interviewed>
        </div>
    </div>
</app-shared-sidebar-container>
