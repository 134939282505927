
import { Injectable } from '@angular/core';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { BaseService } from '@app/core/services/base.service';
import { ApiService } from '@app/core/services/api.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { of, Observable } from 'rxjs';
import { SearchParams } from '@app/core/models/search-params';
import { PageModel } from '@app/core/models/page';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguagesService extends BaseService implements BaseServiceI {

  fullySupportedLanguages: string[] = [
    'it', 'en'
  ]

  values: Array<any> = [
    {language_code: 'it', name: null},
    {language_code: 'en', name: null},
    {language_code: 'fr', name: null},
    {language_code: 'es', name: null},
    {language_code: 'de', name: null},
  ];
  currentLanguage$: string;

  constructor(public router: Router, public apiService: ApiService, public translateService: TranslateService) {
    super(router, apiService, null);
    this.translateService.get(['ITALIAN', 'ENGLISH', 'FRENCH', 'SPANISH', 'DEUTSCHE']).subscribe(trs => {
      this.values = [
        {language_code: 'it', name: trs.ITALIAN},
        {language_code: 'en', name: trs.ENGLISH},
        {language_code: 'fr', name: trs.FRENCH},
        {language_code: 'es', name: trs.SPANISH},
        {language_code: 'de', name: trs.DEUTSCHE},
    ];
    });
  }

  // forzo la ri-traduzione della lingua
  getLanguageList(langCode = null) {
    if (langCode) {
      this.currentLanguage = langCode
    }
    this.values = [
      {language_code: 'it', name: this.translateService.instant('ITALIAN')},
      {language_code: 'en', name: this.translateService.instant('ENGLISH')},
      {language_code: 'fr', name: this.translateService.instant('FRENCH')},
      {language_code: 'es', name: this.translateService.instant('SPANISH')},
      {language_code: 'de', name: this.translateService.instant('DEUTSCHE')},
      {language_code: 'gr', name: this.translateService.instant('GREEK')},
    ];
    return this.values;
  }

  dropdownList(searchParams?: SearchParams, useCache: boolean = false): Observable<PageModel> {
    return of(new PageModel(this.values, this.values.length));
  }

  changeLanguage(langcode: string) {
    this.currentLanguage = langcode;
    const found = this.values.some(value => value.language_code === langcode);
    if (found) {
      this.translateService.use(langcode);
    } else {
      this.translateService.use(this.getLocalLang());
    }
  }

  set currentLanguage(lang: string) {
    const found = this.values.some(value => value.language_code === lang);
    if (found) {
      this.currentLanguage$ = lang;
    }
  }
  get currentLanguage(): string {
    return this.currentLanguage$;
  }

  getWebPageUrl() {
    const baseUrl = environment.websiteUrl;
    const url = `${baseUrl}/${this.currentLanguage}/home`;
    return url;
  }

  getLocalLang(defaultLanguage = 'it', long = false) {
    if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
      return defaultLanguage;
    }
    const wn = window.navigator as any;
    let lang = wn.languages ? wn.languages[0] : defaultLanguage;
    lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;
    if (long) {
      return lang;
    }
    return lang.substring(0, 2);
  }

}
