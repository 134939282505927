<app-shared-sidebar-container>
    <app-analysis-sidebar-header [title]="'STARS' | translate"></app-analysis-sidebar-header>

    <div class="sidebar-body">
        <section *ngIf="countryUniqId">
            <app-overview-modal-filter
                (searchFn)="getStarsByFilter()"
                [filterForm]="filterForm"
                [star]="true"
                [highlighted]="true"
                [countryUniqId]="countryUniqId">
            </app-overview-modal-filter>
        </section>
        <div class="alert alert-info mt-2"  *ngIf="operation('getting-answers').success && !answers.length && !comments.length">{{ 'THERE_ARE_NO_ANSWERS' | translate }}</div>
        <div class="my-3">
            <app-core-spinner [toggle]="operation('getting-answers').submitting"></app-core-spinner>
        </div>
        <div class="row mt-4">
            <div class="col-12 d-flex align-items-center justify-content-end">
                <div class="check-radio-box mb-0 mr-4">
                    <input type="checkbox" (change)="selectAll($event)" id="check1">
                    <label for="check1">
                        {{ 'SELECT_ALL' | translate }}
                    </label>
                </div>
                <ng-template #exportPopOver>
                    <!--  -->
                    <div class="p-3">
                        <div class="row">
                            <div class="col-12">
                                <b>{{'CHOOSE_EXPORT_FORMAT' | translate }}</b>
                            </div>
                            <div class="col-12 mt-2 text-center">
                                <button class="btn-sicche btn-secondary" (click)="getXlsExport()"><i class="fal fa-file-excel"></i> XLS</button>
                            </div>
                            <div class="col-12 text-center">
                                <button class="btn-sicche btn-secondary" (click)="downloadSelected()"><i class="fal fa-file-pdf"></i> PDF</button>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <button *ngIf="!isSmart" [disabled]="!hasSelected() || downloading" [ngbPopover]="exportPopOver" class="btn btn-sicche">
                    <i class="fal fa-download"></i>&nbsp;
                    <span *ngIf="!downloading">{{ 'DOWNLOAD_SELECTED' | translate }}</span>
                    <span *ngIf="downloading">{{ 'WAIT_PLEASE' | translate }}</span>
                </button>
            </div>
        </div>
        <div class="row mt-2" *ngIf="answers.length && contentTags">
            <div class="col-12">
                <!-- blocco risposta -->
                <div *ngFor="let answer of answers" class="position-relative">
                    <app-analysis-answer-wrapper
                        [answer]="answer"
                        (textSelection)="selectText($event)"
                    ></app-analysis-answer-wrapper>
                    <div style="position: absolute; opacity: 0; pointer-events:none;">
                        <app-analysis-answer-wrapper
                            [exportableVersion]="true"
                            [answer]="answer"
                        ></app-analysis-answer-wrapper>
                    </div>
                    <div *ngFor="let comment of answer.commentsB">
                        <div style="position: absolute; opacity: 0; pointer-events:none;">
                            <app-moderation-answer-comment
                                [selectable]="true"
                                [exportableVersion]="true"
                                [id]="'answer-block-comment-exportable' + comment.id"
                                [commentAnswer]="comment"
                                [verbose]="true"
                                [answer]="answer">
                            </app-moderation-answer-comment>
                        </div>
                        <app-moderation-answer-comment
                            [selectable]="true"
                            (textSelection)="selectText($event)"
                            [id]="'answer-block-comment-' + comment.id"
                            [commentAnswer]="comment"
                            [verbose]="true"
                            [answer]="answer">
                        </app-moderation-answer-comment>
                    </div>
                </div>
                <!-- #blocco risposta -->
            </div>
        </div>
        <div class="row mt-2" *ngIf="commentsNotLinkedToAnswers.length">
            <div class="col-12">
                <!-- blocco commento -->
                <div *ngFor="let comment of commentsNotLinkedToAnswers">
                    <app-moderation-answer-comment
                        [selectable]="true"
                        [id]="'answer-block-comment-' + comment.id"
                        [commentAnswer]="comment"
                        (textSelection)="selectText($event)"
                        [verbose]="true"
                        [readonly]="true">
                    </app-moderation-answer-comment>
                    <div style="position: absolute; opacity: 0; pointer-events:none;">
                        <app-moderation-answer-comment
                            [selectable]="true"
                            [exportableVersion]="true"
                            [id]="'answer-block-comment-exportable' + comment.id"
                            [commentAnswer]="comment"
                            [verbose]="true"
                            [readonly]="true">
                        </app-moderation-answer-comment>
                    </div>
                </div>
                <!-- #blocco commento -->
            </div>
        </div>
    </div>

    <div class="overlay-extract" *ngIf="showSelectionModal">
        <app-moderation-quotes-modal [answer]="quotedAnswer" [extraData]="quotedCommentid ? {commentid: quotedCommentid} : null" [commentid]="quotedCommentid" (closeFunc)="quotesModalClose()"></app-moderation-quotes-modal>
    </div>

</app-shared-sidebar-container>