<ng-container *ngIf="answer">
  <div class="img-card-box d-flex flex-wrap justify-content-center p-0 {{ classes }}" *ngIf="answer.answer_data">
    <div *ngIf="!itemToModify">
      <div class="d-inline-block v-top mt-3 px-2" *ngFor="let img of answer.answer_data.images; let i = index">
        <div class="card">
          <div class="d-flex flex-column align-items-center">

            <div class="img-wrap p-1">
              <img *ngIf="img.image_url"
                [src]="img.image_url | privatesrc"
                (click)="openLightbox(i, answer.answer_data.images)">
            </div>


            <div *ngIf="explicitMediaUrls" class="explicit-media-url">
              <a *ngIf="img.image_url" [href]="img.image_url">{{ 'MEDIA_DOWNLOAD' | translate }}</a>
              <span *ngIf="img.image_url" class="media-file-name">{{ explicitMediaFileName(img.image_url) }}</span>
            </div>

          </div>
          <div *ngIf="answer.answer_data.comments" class="card-body d-flex align-items-center justify-content-center">
            <div class="answer-detail text-center" (mouseup)="selectText()">
              <div class="comment" [innerHTML]="answer.answer_data.comments[i]"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="itemToModify === answer.id" class="row mt-4">
      <div class="col-6">
        <form [formGroup]="form" style="float:left;">
          <span class="placeholder">{{ 'COMMENT' | translate }}</span>
          <div *ngFor="let c of form.controls; let i = index" class="form-group">
            <textarea cols="50" class="form-control" [formControlName]="i"></textarea>
          </div>
        </form>
        <button class="btn btn-success" (click)="saveMods()">
          <span *ngIf="operation('saving').submitting" class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
          </span>
          {{ 'SAVE' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
