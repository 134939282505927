<div class="" >

    <div class="text-center p-5" *ngIf="!research">
        <app-core-spinner [toggle]="loading" ></app-core-spinner>
    </div>
    <div class=""  *ngIf="research">
        <a *ngIf="!teamService.isCurrentUserCustomer()" [routerLink]="['/dashboard']" class="link"><i class="fal fa-arrow-left"></i> {{'GO_BACK' | translate}}</a>
        <h1 class="mt-3">{{research.getCountryTitle(countryUniqid) || research.title}}</h1>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-8 col-xl-8 col-xxl-8 d-flex flex-column px-0">
                    <div class="d-flex">
                        <div class="info-group">
                            <i class="align-self-center fal fa-clipboard-list-check"></i>
                            <div class="ml-2">
                                <div>{{('DAYS' | translate) | titlecase}}: {{ research.nr_effective_days }}</div>
                            </div>
                        </div>
                        <div class="info-group">
                            <i class="align-self-center fal fal fa-calendar-alt"></i>
                            <div class="ml-2">
                                <div>{{'FROM' | translate}} {{research.date_start | dateToLocaleString}}</div>
                                <div>{{'DATE_TO' | translate}} {{research.date_end | dateToLocaleString}}</div>
                            </div>
                        </div>
                        <div class="info-group">
                            <i class="align-self-center fal fal fa-users"></i>
                            <div class="ml-2">
                                <div>{{ 'INTERVIEWEDS.UP_TO' | translate }}{{research.nr_interviewed}}</div>
                                <div>{{ 'INTERVIEWEDS.INTERVIEWEDS' | translate }}</div>
                            </div>
                        </div>
                        <div class="info-group">
                            <i class="align-self-center fal fal fa-flag"></i>
                            <div class="d-flex flex-wrap">
                                <span class="ml-2" *ngIf="countryNames" placement="top" [ngbTooltip]="countryNames.join(', ')">
                                    {{countryNames.join(', ') | truncate: [30, '...']}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mt-2">
                        <div class="info-group">
                            <i class="align-self-center fal fa-clock"></i>
                            <div class="d-flex flex-wrap">
                                <span class="ml-1">
                                    {{'TIME_IS_IN_TIMEZONE_ALERT' | translate}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-xl-4 col-xxl-4 pl-0 pr-4">
                    <div class="d-flex flex-column align-items-flex-start justify-content-end mr-4" *ngIf="research.countries.length > 1">
                        <h4 class="mb-1">{{ 'CHOOSE_COUNTRY' | translate }}</h4>
                        <!-- <select [(ngModel)]="countryUniqid" id="" class="form-control" (change)="changeCountry()">
                            <option *ngFor="let c of research.countries" [ngValue]="c.country_uniqid" >{{ (c.country_code | uppercase) | translate}}</option>
                        </select> -->
                        <div class="form-group nolabel mb-0 w-100">
                            <app-core-select
                                [classes]="'form-control'"
                                [cache]="true"
                                [customList]="research.countries"
                                [label]="'translated'"
                                [value]="'country_uniqid'"
                                [(ngmodel)]="countryUniqid"
                                (change)="changeCountry($event)"
                            >
                            </app-core-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-5 position-relative">
        <div class="band-top-white w-100 d-flex">
            <div class="group" *ngIf="research && !countryService.currentCountryIsClientBasic() && !teamService.isCurrentUserCustomer()">
                <div  (click)="savePath(['/researches/navigation', uniqid, countryUniqid, 'setup'])" [routerLink]="['/researches/navigation', uniqid, countryUniqid, 'setup']" routerLinkActive="research-navigation-link-active" class="navigation-link">
                    {{ 'SETUP' | translate }}
                </div>
            </div>
            <div class="group" *ngIf="research">
                <div (click)="savePath(['/researches/navigation', uniqid, countryUniqid, 'moderation'])" [routerLink]="['/researches/navigation', uniqid, countryUniqid, 'moderation']" *ngIf="!teamService.isCurrentUserCustomerBasic()" routerLinkActive="research-navigation-link-active" class="navigation-link">
                    {{ (teamService.isCurrentUserCustomer() ? 'ANALYSIS_CUSTOMER.MODERATION_BTN' : 'MODERATION') | translate }}
                </div>
                <div (click)="savePath(['/researches/navigation', uniqid, countryUniqid, 'monitoring'])" [routerLink]="['/researches/navigation', uniqid, countryUniqid, 'monitoring']" *ngIf="!teamService.isCurrentUserCustomer()" routerLinkActive="research-navigation-link-active" class="navigation-link">
                    {{ 'MONITORING' | translate }}
                </div>
                <div (click)="savePath(['/researches/navigation', uniqid, countryUniqid, 'messages'])" [routerLink]="['/researches/navigation', uniqid, countryUniqid, 'messages']" *ngIf="!teamService.isCurrentUserCustomer()" routerLinkActive="research-navigation-link-active" class="navigation-link">
                    {{ 'MESSAGES' | translate }}
                </div>
            </div>
            <div class="group">
                <div *ngIf="!teamService.isCurrentUserCustomerBasic()" (click)="savePath(['/researches/navigation', uniqid, countryUniqid, 'analysis'])" [routerLink]="['/researches/navigation', uniqid, countryUniqid, 'analysis']" routerLinkActive="research-navigation-link-active" class="navigation-link">
                    <div *ngIf="!isCustomerPro">{{ (teamService.isCurrentUserCustomer() ? 'ANALYSIS_CUSTOMER.ANALYSIS_BTN' : 'ANALYSIS') | translate }}</div>
                    <div *ngIf="isCustomerPro">{{ 'STATISTICS' | translate }}</div>
                </div>
            </div>
        </div>
    </div>
</div>