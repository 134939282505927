import { BaseModel } from '@app/core/models/base';
import { BaseSubTypeModelSchema } from '@app/core/models/base-sub-types-list.interface';
import { BlockModel } from './block.model';

/**
 * User model class
 */

export class TeamModel extends BaseModel {

    // tslint:disable: variable-name
    id?: string;
    userid: string;
    avatar: any;
    avatar_url: string;
    avatar_del: boolean;
    answers_to_read?: number;
    username: string;
    password: string;
    email: string;
    firstname: string;
    lastname: string;
    nickname: string;
    is_locked: boolean;
    note: string;
    company: string;
    language_code: string;
    telephone: string;
    default_privacy_html: string;
    consentA: boolean;
    consentB: boolean;
    last_login: number;
    last_login_datetime: Date;
    creationtime: Date;
    nr_researches: number;
    // country/room related
    password_status: string;
    role: string;
    uniqid: string;
    country_uniqid: string;
    roomid: number;
    sample_variable_tags: string[];
    personas_tags: string[];
    msg?: string;
    monitoring_dislike: boolean;
    monitoring_medal: boolean;
    request_change_password: boolean;
    questions: any[];

    // With include_stats
    status_activity?: string;

    static getResourceName() {
        return 'teams';
    }

    getFullName() {
        return `${this.firstname} ${this.lastname}`;
    }

    hasLoggedIn(checkHasNickname = false) {
        if(checkHasNickname) {
            return this.nickname ? true : false;
        } 
        if(!this.hasOwnProperty('status_activity')) {
            return false;
        } else {
            return this.status_activity == 'NEVER_LOGGED_IN';
        }
    }

    getName() {

    }

}
