import { NgbDateParserFormatter, NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateUtil extends NgbDateParserFormatter {

  static readonly DB_FORMAT_DATE_HOUR = 'YYYY-MM-DDTHH:mm:ssZ';
  static readonly DISPLAY_FORMAT_DATE = 'DD/MM/YYYY';
  static readonly DISPLAY_FORMAT_DATE_MINUS = 'DD-MM-YYYY';
  static readonly STANDARD_FORMAT_DATE = 'YYYY-MM-DD';

  static dateToString = (date: NgbDateStruct, format?: string): string => {
    if (!date) {
      return '';
    }
    const mdt = moment([date.year, date.month - 1, date.day]);
    if (!mdt.isValid()) {
      return '';
    }
    return mdt.format(format ? format : DateUtil.STANDARD_FORMAT_DATE);
  }

  static fullCalendarFormat = (stringDate: string, format = DateUtil.STANDARD_FORMAT_DATE) => {
    const date = moment(stringDate);
    date.add(1, 'days');
    return moment(date).format(format);
  }

  static stringToDate = (stringDate?: string): NgbDateStruct => {
    const date = stringDate ? moment(stringDate) : moment();
    return {year: date.year(), month: date.month() + 1, day: date.date()};
  }

  static stringToHour = (stringDate: string): NgbTimeStruct => {
    const date = moment(stringDate);
    return {hour: date.hour(), minute: date.minute(), second: 0};
  }

  static compareNgbDates(dateA: NgbDateStruct, dateB: NgbDateStruct) {
    if (moment(new Date(`${dateB.year}-${dateB.month}-${dateB.day}`)).isBefore(new Date(`${dateA.year}-${dateA.month}-${dateA.day}`))) {
      return -1;
    }
  }

  static checkDateReal(from: string | number | Date, to: string | number | Date,
                       dateStart: string | number | Date, dateEnd: string | number | Date) {
    from       = new Date(from).getTime();
    to         = new Date(to).getTime();

    dateStart = new Date(dateStart).getTime();
    dateEnd   = new Date(dateEnd).getTime();

    if (from >= dateStart && from <= dateEnd && to >= dateStart && to <= dateEnd) {
      return true;
    }
    return false;
  }

  static dateHourToString = (date: NgbDateStruct, hour: NgbTimeStruct, format?: string): string => {
    const dateHour = moment()
      .year(date.year)
      .month(date.month - 1)
      .date(date.day)
      .hours(hour.hour)
      .minutes(hour.minute)
      .seconds(0);
    return moment(dateHour).format(format ? format : DateUtil.DB_FORMAT_DATE_HOUR);
  }

  static formatString = (date: string, format?: string): string => {
    const newDate = moment(date).format(format ? format : DateUtil.DISPLAY_FORMAT_DATE_MINUS);
    return newDate;
  }

  parse(value: string): NgbDateStruct {
      if (value) {
          value = value.trim();
          const mdt = moment(value, DateUtil.DISPLAY_FORMAT_DATE);
      }
      return null;
  }
  format(date: NgbDateStruct): string {
      if (!date) {
        return '';
      }
      const mdt = moment([date.year, date.month - 1, date.day]);
      if (!mdt.isValid()) {
        return '';
      }
      return mdt.format(DateUtil.DISPLAY_FORMAT_DATE);
  }

}
