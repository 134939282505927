import { Injectable } from '@angular/core';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { BaseService } from '@app/core/services/base.service';
import { ApiService } from '@app/core/services/api.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { of, Observable } from 'rxjs';
import { SearchParams } from '@app/core/models/search-params';
import { PageModel } from '@app/core/models/page';

@Injectable({
  providedIn: 'root'
})
export class QuestionsTypesService extends BaseService implements BaseServiceI {

  values: any[];

  constructor(public router: Router, public apiService: ApiService, public translateService: TranslateService) {
    super(router, apiService, null);
    const slugsArray = ['QUESTIONS.QT1', 'QUESTIONS.QTD1', 'QUESTIONS.QT2', 'QUESTIONS.QTD2',
                        'QUESTIONS.QT3', 'QUESTIONS.QTD3', 'QUESTIONS.QT4', 'QUESTIONS.QTD4',
                        'QUESTIONS.QT5', 'QUESTIONS.QTD5', 'QUESTIONS.QT6', 'QUESTIONS.QTD6',
                        'QUESTIONS.QT7', 'QUESTIONS.QTD7', 'QUESTIONS.QT8', 'QUESTIONS.QTD8',
                        'QUESTIONS.QT9', 'QUESTIONS.QTD9', 'QUESTIONS.QT10', 'QUESTIONS.QTD10',
                        'QUESTIONS.QT11', 'QUESTIONS.QTD11', 'QUESTIONS.QT12', 'QUESTIONS.QTD12',
                        'QUESTIONS.QT13', 'QUESTIONS.QTD13', 'QUESTIONS.QT14', 'QUESTIONS.QTD14'];
    this.translateService.get(slugsArray).subscribe(trs => {
      this.values = [
        {id: 1, slug: 'communications', name: trs['QUESTIONS.QT1'], description: trs['QUESTIONS.QTD1'], icon: 'fal fa-bullhorn', type: 1},
        {id: 2, slug: 'open-answer', name: trs['QUESTIONS.QT2'], description: trs['QUESTIONS.QTD2'], icon: 'fal fa-comments', type: 1},
        {id: 3, slug: 'sentence-completion', name: trs['QUESTIONS.QT3'], description: trs['QUESTIONS.QTD3'], icon: 'fal fa-ellipsis-h', type: 1},
        {id: 4, slug: 'vocal-answer', name: trs['QUESTIONS.QT4'], description: trs['QUESTIONS.QTD4'], icon: 'fal fa-microphone', type: 1},
        {id: 5, slug: 'image-answer', name: trs['QUESTIONS.QT5'], description: trs['QUESTIONS.QTD5'], icon: 'fal fa-image', type: 1},
        {id: 6, slug: 'video-answer', name: trs['QUESTIONS.QT6'], description: trs['QUESTIONS.QTD6'], icon: 'far fa-play', type: 1},

        {id: 7, slug: 'closed-single', name: trs['QUESTIONS.QT7'], description: trs['QUESTIONS.QTD7'], icon: 'fal fa-check-circle', type: 2},
        {id: 8, slug: 'closed-multiple', name: trs['QUESTIONS.QT8'], description: trs['QUESTIONS.QTD8'], icon: 'fal fa-check-square', type: 2},
        {id: 9, slug: 'attitude-scale', name: trs['QUESTIONS.QT9'], description: trs['QUESTIONS.QTD9'], icon: 'fal fa-tachometer-alt-slowest', type: 2},
        {id: 10, slug: 'semantic-differentials', name: trs['QUESTIONS.QT10'], description: trs['QUESTIONS.QTD10'], icon: 'fal fa-balance-scale-right', type: 2},
        {id: 11, slug: 'grouping-category', name: trs['QUESTIONS.QT11'], description: trs['QUESTIONS.QTD11'], icon: 'fal fa-th', type: 2},
        {id: 12, slug: 'preference-ranking', name: trs['QUESTIONS.QT12'], description: trs['QUESTIONS.QTD12'], icon: 'fal fa-trophy-alt', type: 2},

        {id: 13, slug: 'pin-on-media', name: trs['QUESTIONS.QT13'], description: trs['QUESTIONS.QTD13'], icon: 'fal fa-smile-beam', type: 3},
        {id: 14, slug: 'image-association', name: trs['QUESTIONS.QT14'], description: trs['QUESTIONS.QTD14'], icon: 'fal fa-smile-beam', type: 3}
      ];
    });
  }


  getByType(type: number, from?: number, howMany?: number) {
    const output = this.values.filter(item => item.type === type);
    if (from != null && howMany != null) {
      return output.slice(from, howMany);
    } else {
      return output;
    }
  }

  getBySlug(slug: string): any {
    const output = this.values.filter(item => item.slug === slug);
    if (output.length) {
      return output[0];
    } else {
      return null;
    }
  }

}
