<section class="container-fluid p-5 modal-small">
    <div class="d-flex align-items-center justify-content-end mb-4 close-button w-100" (click)="closeModal()">
        <i class="fal fa-times fa-2x cross cursor-pointer"></i>
    </div>
    <div class="row">
        <div class="col-md-12">
            <ul>
                <li *ngFor="let research of researches">
                    {{ research.date_end | dateToLocaleString }}: {{ research.title }}
                </li>
            </ul>
        </div>
    </div>
</section>
