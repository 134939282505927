import { Component, OnInit, ViewChild } from '@angular/core';
import { CountryService } from '@app/shared/services/country.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { AddQuestionComponent } from '@app/pages/layers/private/researches/navigation/setup/add-question/add-question.component';
import { CustomizeResearchComponent } from './customize-research/customize-research.component';
import { ResearchSessionService } from '@app/shared/services/research-session.service';
import { TeamService } from '@app/shared/services/team.service';


@Component({
  selector: 'app-private-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit {

  constructor(
    private layoutService: CoreLayoutService,
    private countryService: CountryService,
    private researchSessionService: ResearchSessionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public teamService: TeamService
    ) {
  }

  @ViewChild(CustomizeResearchComponent) customizeResearch: CustomizeResearchComponent;

  ngOnInit() {
    //const researchUniqid = this.activatedRoute.snapshot.paramMap.get('research_uniqid');
    // const countryUniqid = this.activatedRoute.snapshot.paramMap.get('country_uniqid');
    if (this.countryService.currentCountryIsClientBasic()) {
      return this.router.navigate(['/researches/navigation',
        this.researchSessionService.getResearchUniqId(), this.researchSessionService.getResearchCountryUniqId(), 'analysis']);
    }
    if(this.teamService.isCurrentUserCustomerPro()) {
      return this.router.navigate(['/researches/navigation', this.researchSessionService.getResearchUniqId(), this.researchSessionService.getResearchCountryUniqId(), 'moderation']);
    }
  }

  addQuestion() {
    this.layoutService.openSidebar(AddQuestionComponent);
  }

  lockTimezone() {
    this.customizeResearch.lockTimezione();
  }

}
