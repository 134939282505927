import { Injectable } from '@angular/core';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { BaseService } from '@app/core/services/base.service';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { ServiceMessageModel } from '@app/core/models/service-message.model';
import { CountryModel } from '../models/country.model';
import { ResponseModel } from '@app/core/models/response.model';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class DeviceService extends BaseService implements BaseServiceI {


  constructor(public router: Router, public apiService: ApiService, private http: HttpClient, private translateService: TranslateService) {
    super(router, apiService, null);
  }

  _devices = [
    {
        name: 'DEVICE.ALL',
        value: 'ALL'
    },
    {
        name: 'DEVICE.DESKTOP',
        value: 'DESKTOP'
    },
    {
        name: 'DEVICE.SMARTPHONE',
        value: 'SMARTPHONE'
    }
  ];

  get devices() {
    const deviceTemp = [];
    for (const d of this._devices) {
        deviceTemp.push({
            name: this.translateService.instant(d.name),
            value: d.value
        });
    }
    return deviceTemp;
  }

}
