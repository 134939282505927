import { Component, OnInit, OnDestroy } from '@angular/core';
import { CountryModel } from '@app/shared/models/country.model';
import { ActivatedRoute } from '@angular/router';
import { CountryService } from '@app/shared/services/country.service';
import { ResponseModel } from '@app/core/models/response.model';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CustomizePrivacyComponent } from './customize-privacy/customize-privacy.component';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { TimezoneService } from '@app/core/services/timezone.service';
import { ManageTeamComponent } from './manage-team/manage-team.component';
import { StorageService } from '@app/core/services/storage.service';
import { ToolbarService } from '@app/core/services/toolbar.service';
import { UiService } from '@app/shared/services/ui.service';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { ManageInterviewedsComponent } from './manage-intervieweds/manage-intervieweds.component';
import { ResearchSessionService } from '@app/shared/services/research-session.service';
import { ResearchModel } from '@app/shared/models/research.model';
import { ModalService } from '@app/core/services/modal.service';
import { LanguagesService } from '@app/shared/services/languages.service';

// import { FileUploadService } from '@app/core/services/file-upload.service';

@Component({
  selector: 'app-private-setup-customize-research',
  templateUrl: './customize-research.component.html',
  styleUrls: ['./customize-research.component.scss']
})
export class CustomizeResearchComponent extends CoreBaseComponent implements OnInit, OnDestroy {

  constructor(
    private countryService: CountryService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private coreLayoutService: CoreLayoutService,
    public timezoneService: TimezoneService,
    public storageService: StorageService,
    private uiService: UiService,
    public researchSessionService: ResearchSessionService,
    private toolbarService: ToolbarService,
    private toastService: ToastrService,
    private translate: TranslateService,
    private modalService: ModalService,
    private languagesService: LanguagesService
  ) {
    super();
  }

  country: CountryModel;
  researchUniqid: string;
  countryUniqid: string;
  languageCode: string;
  form: UntypedFormGroup;
  fileToUpload: File = null;
  research: ResearchModel;
  available_languages = []
  sidebarSub;

  ngOnInit(): void {
    this.researchUniqid = this.activatedRoute.snapshot.parent.paramMap.get('research_uniqid');
    this.countryUniqid = this.activatedRoute.snapshot.parent.paramMap.get('country_uniqid');

    this.research = this.researchSessionService.getResearch();

    this.getCountryData();
    this.sidebarSub = this.coreLayoutService.sidebarOnClosed$.subscribe(() => {
      this.getCountryData();
    });
  }

  ngOnDestroy() {
    if (this.sidebarSub) {
      this.sidebarSub.unsubscribe();
    }
  }

  checkCommaEmailFromName() {
    if (this.form.controls.email_from_name.value.indexOf(',') >= 0) {
      let emailFromName = this.form.controls.email_from_name.value;
      emailFromName = emailFromName.replace(',', '');
      this.form.controls.email_from_name.setValue(emailFromName);
    }
  }

  isMainCountry(country: CountryModel) {
    if (this.research.countries[0].id === country.id) {
      return true;
    }
    return false;
  }

  getCountryData() {
    this.operation('loading-form').reset().isSubmitting();
    this.countryService.getCountrySetupInfo(this.countryUniqid, true).subscribe((res: ResponseModel) => {
      this.country = res.getData();

      this.changeLogo();
      this.formGroup();
      this.operation('loading-form').isSuccess();
      this.available_languages = this.languagesService.getLanguageList(this.country.language_code);

    });
  }

  customizePrivacy() {
    this.coreLayoutService.openSidebar(CustomizePrivacyComponent, this.country);
  }

  manageTeam() {
    console.log(this.country, 'hello')
    this.coreLayoutService.openSidebar(ManageTeamComponent, this.country);
  }


  formGroup() {
    this.form = this.formBuilder.group({
      title: new UntypedFormControl(this.country.title, Validators.required),
      logo: new UntypedFormControl(this.country.logo, Validators.nullValidator),
      color: new UntypedFormControl(this.country.color, Validators.nullValidator),
      language_code: new UntypedFormControl(this.country.language_code, Validators.required),
      timezone: new UntypedFormControl(this.country.timezone || this.timezoneService.timezones["Europe/Rome"], Validators.required),
      country_uniqid: new UntypedFormControl(this.countryUniqid),
      logo_del: new UntypedFormControl(false),
      email_from_name: new UntypedFormControl(this.country.email_from_name, Validators.nullValidator)
    });
  }

  removeLogo() {
    this.fileToUpload = null;
    this.country.logo_del = true;
    this.form.controls.logo_del.setValue(true);
    this.country.logo_url = '';
    this.submitForm();
  }

  uploadLogo(files: FileList) {
    this.fileToUpload = files.item(0);
  }


  submitForm() {
    delete this.form.value.logo;
    const formValue = this.form.value;
    Object.keys(formValue).forEach((key) => (formValue[key] == null) && delete formValue[key]);
    this.countryService.postFile(this.countryUniqid, this.fileToUpload, 'logo', formValue).subscribe((res: ResponseModel) => {
      const data = res.getData();
      this.researchSessionService.updateCountryInResearch(data);
      this.researchSessionService.newEvent('country_updated');
      this.country.logo_url = data.logo_url;
      this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
      // due volte perché altrimenti non va ¯\_(ツ)_/¯
      this.changeLogo();
      this.changeLogo();
      this.country = res.getData();
    }, (e) => {
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
    });

  }

  changeLogo() {
    this.uiService.changeLogo(this.country.logo_url);
  }

  colorChange(e) {
    this.country.color = e;
    this.form.controls.color.setValue(this.country.color);
    this.changeCssVariable('--orange', this.country.color);
    this.submitForm();
  }

  importStructure() {
    this.modalService.open({title: 'ARE_YOU_SURE.?',
        btnOkText: 'YES',
        btnCancelText: 'CANCEL',
        showBtnClose: false,
        showCancelBtn: true,
        customClass: 'confirm-modal',
        message: 'ARE_YOU_SURE_TO_IMPORT_STRUCTURE.?'
      }).then((data) => {
        if (data) {
          this.countryService.getDefaultCountryStructure(this.countryUniqid).subscribe((res: ResponseModel) => {
            window.location.reload();
          });
        }
    });
  }

  lockTimezione() {
    this.country.timezone_locked = true;
  }

  manageIntervieweds() {
    this.coreLayoutService.openSidebar(ManageInterviewedsComponent, {country: this.country});
  }

}
