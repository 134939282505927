import { Component, OnInit, Input } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { AnswerModel } from '@app/shared/models/answer.model';

@Component({
  selector: 'app-moderation-answerstype-grouping-category',
  templateUrl: './grouping-category.component.html',
  styleUrls: ['./grouping-category.component.scss']
})
export class AnswersTypeGroupingCategoryComponent extends CoreBaseComponent implements OnInit {

  @Input()
  question: QuestionModel;
  @Input()
  answer: AnswerModel;
  @Input()
  classes: string;
  @Input()
  explicitMediaUrls: boolean;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  getAnswer(optionid: number) {
    return this.question.question_data.answers.find(a => a.id === optionid);
  }

  getAnswersForCategory(cat: string) {
    return this.answer.answer_data.filter(d => d.category === cat);
  }
}
