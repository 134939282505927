import { Component, OnInit, ViewChild, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { QuestionsService } from '@app/shared/services/questions.service';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { AnswersService } from '@app/shared/services/answers.service';
import { ResponseModel } from '@app/core/models/response.model';
import { AnswerModel } from '@app/shared/models/answer.model';
import { TeamService } from '@app/shared/services/team.service';
import { MessageModel } from '@app/shared/models/message.model';
import { TranslateService } from '@ngx-translate/core';
import { DatetimeToDbPipe } from '@app/core/pipes/datetime-to-db.pipe';
import * as Highcharts from 'highcharts';
import { AnswersChartsService } from '../answers-charts.service';
import { CountryService } from '@app/shared/services/country.service';
import { ResearchSessionService } from '@app/shared/services/research-session.service';
import { ActivatedRoute } from '@angular/router';
import exporting from 'highcharts/modules/exporting';
import { AuthService } from '@app/core/services/auth.service';
import { TeamModel } from '@app/shared/models/team.model';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ModerationHelperService } from '@app/shared/services/moderation-helper.service';
import {DomSanitizer} from '@angular/platform-browser';
import { TemplateMessageModel } from '@app/shared/models/template-message.model';
import { TemplateMessageService } from '@app/shared/services/template-messages.service';
import { ToastrService } from 'ngx-toastr';
import * as angular from 'angular';
exporting(Highcharts);

@Component({
  selector: 'app-moderation-answers-group-wrapper',
  templateUrl: './answer-group-wrapper.component.html',
  styleUrls: ['./answer-group-wrapper.component.scss']
})
export class AnswersGroupWrapperComponent extends CoreBaseComponent implements OnInit, AfterViewInit {

  @Input()
  question: QuestionModel;
  @Input()
  sampleVariableTags: Array<any> = [];
  @Input()
  answer: AnswerModel[];
  @ViewChild('qp') public qp: NgbPopover;
  allAnswers: AnswerModel[] = [];
  public isPrivateAnswer = false;
  public replyForm: UntypedFormGroup;
  public titleForm: UntypedFormGroup;
  public answerToComment: AnswerModel = null;
  public answerToCommentIsPrivate: boolean;
  public answerToCommentIsPrivateMail: boolean;
  public questions: QuestionModel[] = [];
  public chartReady = false;
  public contentTags = [];
  public textToInject: any;
  public Highcharts: typeof Highcharts = Highcharts;
  public intervieweds: TeamModel[] = [];
  public personasTags: Array<any> = [];
  public chartOptions: Highcharts.Options = { };
  public chartDimension: { w: string, h: string } = { w: '0px', h: '0px' };
  public inTextThereAreQuotes = false;
  public quillHeight = null;
  public viewReady = false;
  public quillModules = {
    'emoji-shortname': true,
    'emoji-textarea': true,
    'emoji-toolbar': true,
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
      ['clean'],
      ['link', 'video', 'file'],
      ['emoji']
    ]
  };
  imageCloudLoader = false;
  imageCloudData = []

  // template messages
  showSaveAsTemplate = false;
  showLoadFromTemplate = false;
  templateTitle = '';
  currentTemplateMessage: TemplateMessageModel;
  templateMessages: TemplateMessageModel[];

  constructor(private questionsService: QuestionsService, private coreLayoutService: CoreLayoutService,
              private activatedRoute: ActivatedRoute, private authService: AuthService, private sanitizer: DomSanitizer,
              private moderationHelperService:  ModerationHelperService, private datetimeToDbPipe: DatetimeToDbPipe,
              private cdr: ChangeDetectorRef,
              private countryService: CountryService, private researchSessionService: ResearchSessionService,
              private dateTimeToDb: DatetimeToDbPipe, private answersChartsService: AnswersChartsService,
              private answerService: AnswersService, private teamService: TeamService, private translate: TranslateService,
              private templateMessageService: TemplateMessageService, private toastService: ToastrService) {
    super();
  }
  saveAsTemplateMessage() {
    const templateMessage = {
      title: this.templateTitle,
      body: this.replyForm.value.comment,
      subject: this.replyForm.value.subject
    }
    this.templateMessageService.createTemplateMessage(templateMessage).subscribe((res: ResponseModel) => {
      this.templateTitle = '';
      this.showSaveAsTemplate = false;1
      this.toastService.success(this.translate.instant('SAVED_AS_TEMPLATE_MESSAGE'));
      this.loadTemplateMessages()
    })
  }
  loadTemplateMessages() {
    this.templateMessageService.getTemplateMessages().subscribe((res: ResponseModel) => {
      this.templateMessages = res.getData();
    })
  }

  setTemplateMessage(template: TemplateMessageModel) {
    this.currentTemplateMessage = template;
  }

  loadFromTemplate() {
    this.replyForm.controls.subject.setValue(this.currentTemplateMessage.subject);
    this.replyForm.controls.comment.setValue(this.currentTemplateMessage.body);
  }

  editorCreated(event) {
  }

  ngAfterViewInit() {
    this.loadTemplateMessages();
    // Calculate needed height for quill-editor
    var div = document.getElementById('hiddenDiv');
    div.innerHTML += this.question.title;
    if(div.offsetHeight <= 50) {
      // just safety
      this.quillHeight = '130px';
    } else {
      this.quillHeight = div.offsetHeight + 'px';
    }
    div.style.height = '0px';
    setTimeout(() => {
      this.viewReady = true;
    }, 1000)
  }

  async ngOnInit(): Promise<void> {
    this.allAnswers = angular.copy(this.answer)
    this.intervieweds = [];
    // Get intervieweds
    this.answer.forEach(answer => {
      this.intervieweds.push(answer.team);
    });
    // Get personas tags
    this.countryService.getPersonasTags(this.question.country_uniqid).subscribe((res: ResponseModel) => {
      this.personasTags = res.getData();
    });
    // Re-get personas if somewhere else personas are changes or  applied
    this.moderationHelperService.message$.subscribe((message: string) => {
      if (message === 'personas-list-applied') { //  todo: only when personas-new-item
        this.countryService.getPersonasTags(this.question.country_uniqid).subscribe((res: ResponseModel) => {
          this.personasTags = res.getData();
        });
      }
    });
    this.answerToCommentIsPrivate = this.question.is_room_group ? false : true;
    this.moderationHelperService.lastCommentInserted.next(null);
    // Close playing videos when side modal close
    this.coreLayoutService.sidebarOnClosed$.subscribe(() => {
      var i, frames;
      frames = document.getElementsByTagName("iframe");
      for (i = 0; i < frames.length; ++i) {
          frames[i].src = '';
      }
    });
    this.replyForm = new UntypedFormGroup({
      comment: new UntypedFormControl('', null),
      subject: new UntypedFormControl('', null)
    });
    this.titleForm = new UntypedFormGroup({
      title: new UntypedFormControl(this.question.title, null)
    });
    const countryUniqid: string = this.countryService.getSessionCountry().country_uniqid;
    this.countryService.getContentTags(countryUniqid, 'answer_tags').subscribe((res: ResponseModel) => {
      this.contentTags = res.getData().map(item => item.name.toLowerCase());
      // Sort contentTags by name
      this.contentTags = this.contentTags.sort((a, b) => a.localeCompare(b));
      this.moderationHelperService.contentTagList = this.contentTags;
    });
    this.chartDimension = await this.answersChartsService.calculateChartDim(this.question);

    if (this.question.type === 'image-association') {
      let allCombinationsIndex = 10
      let imagesCount = { };
      this.answer.forEach(a => a.answer_data.forEach(ad => {
        imagesCount[ad.optionid] = imagesCount[ad.optionid] ? imagesCount[ad.optionid] + 1 : 1;
      }));
      const data = []
      for (const images of this.question.question_data.images) {
        if (imagesCount[images.answer_id]) {
          data.push({
            ...images,
            name: allCombinationsIndex,
            weight: imagesCount[images.answer_id],
            perc: Math.floor((imagesCount[images.answer_id] / this.answer.length) * 100)
          })
          allCombinationsIndex++
        }
      }

      data.sort((a,b) => a.weight - b.weight).slice(0, 10)

      // @ts-ignore
      this.chartOptions.temp = data
      // @ts-ignore
      this.chartOptions.tempEvent = (e) => this.selectedCloudImage(e)
      this.imageCloudData = data
    }
    this.chartReady = await this.answersChartsService.calculateChartData(this.question, this.answer, this.chartOptions, this.chartDimension);
    if (this.question.type === 'image-association') {
      // show loader to hide chart while it's loading images
      this.imageCloudLoader = true
      setTimeout(() => {
        this.imageCloudLoader = false;
      }, 1100);

    }
  }

  selectedCloudImage(e) {
    const answer_id = e.point.answer_id

    const selectedEl = document.getElementById(`image-cloud-id_${answer_id}`)
    const isSelected = selectedEl.classList.contains('selected-image-cloud-item')

    document.querySelectorAll('.image-cloud-item').forEach(item => {
      item.classList.remove('selected-image-cloud-item')
    })

    this.answer = angular.copy(this.allAnswers)

    if (!isSelected) {
      selectedEl.classList.add('selected-image-cloud-item')
      const filtered = this.answer.filter(a => a.answer_data.find(item => item.optionid === answer_id))
      this.answer = filtered
    }

  }

  backClick = () => {
    this.moderationHelperService.backButtonInSideModal.next(null);
    this.answerToComment = null;
  }

  sanitizeQuestionTitle() {
    return this.sanitizer.bypassSecurityTrustHtml(this.question.title)
  }

  sendComment() {
    this.operation('sending-comment').reset().isSubmitting();
    if (this.answerToCommentIsPrivateMail) {
      const message = new MessageModel();
      message.user_ids = [this.answerToComment.team.userid];
      message.send_now = true;
      message.type = 'GENERIC';
      message.subject = this.replyForm.value.subject;
      message.other_data = {
        recipient_user_firstname: null,
        recipient_room: null,
        recipient_filters: null,
        recipient_variable_tags: null
      };
      message.other_data.recipient_user_firstname = this.answerToComment.team.nickname;
      message.msg = this.replyForm.value.comment;
      message.include_link = true;
      message.country_uniqid = this.question.country_uniqid;
      message.date_send = this.datetimeToDbPipe.transform(new Date(), this.countryService.getSessionCountry().timezone);
      this.moderationHelperService.replyingFromModal.next(true);
      this.teamService.sendMessage(message).subscribe((res: ResponseModel) => {
        this.moderationHelperService.replyingFromModal.next(false);
        this.operation('sending-comment').isSuccess();
        setTimeout(() => {
          this.answerToComment = null;
        }, 2000);
      }, (e) => {
        this.moderationHelperService.replyingFromModal.next(false);
        // error
      });
    } else {
      if (!this.answerToCommentIsPrivate) {
        this.moderationHelperService.replyingFromModal.next(true);
        this.answerService.insertComment({
          answerid: this.answerToComment.id,
          comment: this.replyForm.value.comment,
          flag_like: false,
          commentid: null
        }).subscribe((res: ResponseModel) => {
          this.moderationHelperService.replyingFromModal.next(false);
          this.moderationHelperService.lastCommentInserted.next(res.getData()[0]);
          this.answerToComment.nr_comments++;
          this.moderationHelperService.reloadCommentsOf.next(this.answerToComment.id);
          this.operation('sending-comment').isSuccess();
          setTimeout(() => {
            this.answerToComment = null;
          }, 2000);
        }, (e) => {
          this.moderationHelperService.replyingFromModal.next(false);
          this.operation('sending-comment').isFailed(e.error);
        });
      } else {
        this.moderationHelperService.replyingFromModal.next(true);
        this.answerService.insertComment({
          answerid: this.answerToComment.id,
          comment: this.replyForm.value.comment,
          flag_like: false,
          commentid: null,
          private_userid: Number(this.answerToComment.team.userid),
        }).subscribe((res: ResponseModel) => {
          this.moderationHelperService.replyingFromModal.next(false);
          this.moderationHelperService.lastCommentInserted.next(res.getData()[0]);
          this.answerToComment.nr_comments++;
          this.moderationHelperService.reloadCommentsOf.next(this.answerToComment.id);
          this.operation('sending-comment').isSuccess();
          setTimeout(() => {
            this.answerToComment = null;
          }, 2000);
        }, (e) => {
          this.moderationHelperService.replyingFromModal.next(false);
          this.operation('sending-comment').isFailed(e.error);
        });
      }
    }
  }

  textChangeEvent() {
    this.textContainsQuotes();
    this.cdr.detectChanges();
  }

  quoteAllIntervieweds(popover) {
    let text = '';
    this.intervieweds.forEach(user => {
      if(!this.replyForm.value.comment) {
        text += ' @' + this.replaceAll(user.nickname, ' ', '_') + ' ';
      } else {
        if(!this.replyForm.value.comment.includes('@' + this.replaceAll(user.nickname, ' ', '_'))) {
          text += ' @' + this.replaceAll(user.nickname, ' ', '_') + ' ';
        }
      }
    });
    this.textToInject = {
      text
    };
    this.qp.close();
  }

  quoteSelectedIntervieweds(popover) {
    let text = '';
    this.intervieweds.forEach(user => {
      if (user.state.selected) {
        if(!this.replyForm.value.comment) {
          text += ' @' + this.replaceAll(user.nickname, ' ', '_') + ' ';
        } else {
          if(!this.replyForm.value.comment.includes('@' + this.replaceAll(user.nickname, ' ', '_'))) {
            text += ' @' + this.replaceAll(user.nickname, ' ', '_') + ' ';
          }
        }
      }
    });
    this.textToInject = {
      text
    };
    this.qp.close();
    this.cdr.detectChanges();
    //this.inTextThereAreQuotes = this.textContainsQuotes();
  }

  hasCheckedIntervieweds() {
    return this.intervieweds.filter(u => u.state.selected).length;
  }

  resetIntervieweds() {
    this.intervieweds.forEach(user => {
      if (user.state.selected) {
        user.state.selected = false;
      }
    });
  }

  checkQuotedIntervieweds() {
    this.resetIntervieweds();
    if(this.replyForm.value.comment) {
      this.intervieweds.forEach(user => {
        if(this.replyForm.value.comment.includes('@' + this.replaceAll(user.nickname, ' ', '_'))) {
          user.state.selected = true;
        }
      });
    }
  }

  textContainsQuotes() {
    let test = false;
    if(this.replyForm.value.comment) {
      this.intervieweds.forEach(user => {
        if(this.replyForm.value.comment.includes('@' + this.replaceAll(user.nickname, ' ', '_'))) {
          test = true;
        }
      });
    }
    this.inTextThereAreQuotes = test;
    return test;
  }

  addMention(nickname: string) {
    this.textToInject = {
      text: ' @' + this.replaceAll(nickname, ' ', '_') + ' '
    };
  }

  //todo HIGHLIGHT citazione
  // https://stackoverflow.com/questions/52743841/find-and-highlight-word-in-text-using-js

  replyAnswer(data: {answer: AnswerModel, isPrivate: boolean, user: any}) {
    this.moderationHelperService.backButtonInSideModal.next(this.backClick);
    this.operation('sending-comment').reset();
    this.replyForm.reset();
    this.answerToComment = data.answer;
    this.answerToCommentIsPrivate = false;
    this.answerToCommentIsPrivateMail = data.isPrivate;
    this.questionsService.teamsWhoAnswered(this.question)
    .subscribe(res => {
      this.intervieweds = res.getData().filter(user => user.nickname && user.userid != this.answerToComment.team.userid ? true : false );
    });
    //this.answerToCommentIsPrivate = data.isPrivate;
  }

  cancel() {
    this.answerToComment = null;
  }

  submit() {
  }

  ngOnDestroy() {
  }

}
