import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ToolbarItem } from '../models/toolbar/toolbar-item.interface';
import { ToolbarItemModel } from '../models/toolbar/toolbar-item.model';
import { ToolServiceInterface } from '../models/tool-service.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToolbarService implements ToolServiceInterface {

  items$: BehaviorSubject<ToolbarItem[]> = new BehaviorSubject([]);
  logo$: BehaviorSubject<any[]> = new BehaviorSubject(null);
  logoParams$: BehaviorSubject<any> = new BehaviorSubject(null);
  fixed$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  keepExtended$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private logger: NGXLogger) {
  }

  reset() {
    this.items$.next([]);
    this.logo$.next(null);
    this.fixed$.next(false);
    return this;
  }

  add(item: ToolbarItem) {
    this.items$.next([...this.items$.value, new ToolbarItemModel(item)]);
    return this;
  }

  get(slug: string) {
    return this.items$.value.find(item => item.slug === slug);
  }

  logo(logoComponent, params?: any) {
    this.logo$.next(logoComponent);
    if (params) {
      this.logoParams$.next(params);
    }
    return this;
  }

  setFixed(status: boolean) {
    this.fixed$.next(status);
    return this;
  }

  keepExtended(val: boolean) {
    this.keepExtended$.next(val);
    return this;
  }

}
