import { Component, OnInit, Input } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { AnswerModel } from '@app/shared/models/answer.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-moderation-answerstype-semantic-differentials',
  templateUrl: './semantic-differentials.component.html',
  styleUrls: ['./semantic-differentials.component.scss']
})
export class AnswersTypeSemanticDifferrentialsComponent extends CoreBaseComponent implements OnInit {

  @Input() question: QuestionModel;
  @Input() answer: AnswerModel;
  @Input() classes: string;
  @Input() explicitMediaUrls: boolean;

  constructor(private translate: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this.question.question_data.answers.forEach(a => {
      if (a.type === 'pre') {
        a.label_left = this.translate.instant(a.label_left_key);
        a.label_right = this.translate.instant(a.label_right_key);
      }
    });
  }

  getAnswer(optionid: number) {
    return this.question.question_data.answers.find(a => a.id === optionid);
  }
}
