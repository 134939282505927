import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TeamService } from '@app/shared/services/team.service';
import { StripHtmlPipe } from '@app/core/pipes/striphtml.pipe';
import { CountryService } from '@app/shared/services/country.service';
import { EditInterviewedComponent } from './edit-interviewed/edit-interviewed.component';
import { TeamModel } from '@app/shared/models/team.model';
import { PageModel } from '@app/core/models/page';
import { ResponseModel } from '@app/core/models/response.model';
import { environment } from '../../../../../../../../../environments/environment';
import { CountryModel } from '@app/shared/models/country.model';
import { PrivateSrcPipe } from '@app/core/pipes/private-src.pipe';
import { SmartUserSetupComponent } from '../../../analysis/smart-setup-modal/smart-setup-modal.component';
import { ModalService } from '@app/core/services/modal.service';
import { NotificationService } from '@app/shared/services/notification.service';
import { StorageService } from '@app/core/services/storage.service';

@Component({
  selector: 'app-manage-intervieweds',
  templateUrl: './manage-intervieweds.component.html',
  styleUrls: ['./manage-intervieweds.component.scss']
})
export class ManageInterviewedsComponent implements OnInit {

  constructor(
    public coreLayoutService: CoreLayoutService,
    private toastService: ToastrService,
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private teamService: TeamService,
    private stripHtmlPipe: StripHtmlPipe,
    private countryService: CountryService,
    private privateSrcPipe: PrivateSrcPipe,
    private modalService: ModalService,
    private storageService: StorageService,
    private stripHtml: StripHtmlPipe
  ) {
    this.modules = {
      'emoji-shortname': true,
      'emoji-textarea': true,
      'emoji-toolbar': true,
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote'],
        [{ header: 1 }, { header: 2 }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ direction: 'rtl' }],
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }],
        [{ font: [] }],
        [{ align: [] }],
        ['clean'],
        ['link', 'image', 'video'],
        ['emoji']
      ]
    };
  }

  @ViewChild(EditInterviewedComponent) editInterviewed: EditInterviewedComponent;
  @ViewChild('autocomplete') autocomplete;


  @Input() country: CountryModel;
  users: TeamModel[];
  tagText: string;
  form: UntypedFormGroup;
  selectedUsers = [];
  columns: any[];
  excelDownloadUrl: string;
  loadingUsers = false;
  sendMessage = false;
  editUser = false;
  currentUser: TeamModel;

  subject: string;
  text: string;
  modules = {};
  autocompleteEmail: TeamModel[];
  autocompleteTag: any[];
  tagAutocompleteText: string;

  sortAttribute = 'creationtime';
  sortOrder = 'desc';
  submitting = false;

  ngOnInit(): void {
    this.setColumns();

    this.loadUsers();
    // tslint:disable-next-line: max-line-length
    const phpsessid = this.storageService.get('phpsessid');
    this.excelDownloadUrl = `${environment.serviceUrl}/teams/download_users_by_excel?country_uniqid=${this.country.country_uniqid}&PHPSESSID=${phpsessid}`;
  }

  loadUsers() {
    this.loadingUsers = true;
    this.teamService.findUsers(this.country.country_uniqid, null, this.sortAttribute, this.sortOrder, 'INTERVIEWED', true)
      .subscribe((res: PageModel) => {
        this.users = res.getData();
        this.loadingUsers = false;
        this.formGroup();

      });
  }

  getUsersByIds(userids) {
    let ret = [];
    for (const user of this.users) {
      if (userids.includes(user.userid)) {
        ret.push(user);
      }
    }
    return ret;
  }

  emailAutocomplete(search: string) {
    this.teamService.autocompleteUsers(search).subscribe((res: ResponseModel) => {
      this.autocompleteEmail = res.getData();
    });
  }

  tagAutocomplete(search: string) {
    if (search.indexOf(',') !== -1) {
      const newTag = this.stripHtmlPipe.transform(search.substring(0, search.length - 1).replace(',', ''));
      this.addTag(newTag);
      this.tagAutocompleteText = '';
    } else {
      this.countryService.autocompleteTag(this.country.country_uniqid, search).subscribe((res: ResponseModel) => {
        this.autocompleteTag = res.getData();
      });
    }
  }

  autocompleteUser(user: TeamModel) {
    const email = this.stripHtml.transform(user.email)
    this.form.controls.email.setValue(email);
    this.form.controls.firstname.setValue(user.firstname);
    this.form.controls.lastname.setValue(user.lastname);
    this.form.controls.telephone.setValue(user.telephone || '');
    this.autocomplete.close()
  }

  setColumns() {
    const temp: any = [
      { name: 'SELECT', class: '' },
      { name: 'INVITE_STATUS', class: '' },
      { name: 'MAIL/LOGIN', class: 'text-left', icon_name: 'fal fa-sort' },
      { name: 'FIRSTNAME_AND_LASTNAME', class: 'text-left', icon_name: 'fal fa-sort' },
      { name: 'NAME', class: 'text-left', icon_name: 'fal fa-sort' }
    ];
    if (!this.country.sample_variable_tags) {
      this.country.sample_variable_tags = [];
    }
    for (const tag of this.country.sample_variable_tags) {
      temp.push({ name: tag, class: '' });
    }

    this.columns = temp;
  }

  formGroup() {
    this.form = this.formBuilder.group({
      email: new UntypedFormControl('', Validators.required),
      firstname: new UntypedFormControl('', Validators.required),
      lastname: new UntypedFormControl('', Validators.required),
      telephone: new UntypedFormControl('', Validators.nullValidator),
      sample_variable_tags: new UntypedFormControl('', Validators.nullValidator)
    });
  }

  updateUserTag(userid, tag) {
    for (const user of this.users) {
      if (user.userid === userid) {
        if (!user.sample_variable_tags) {
          user.sample_variable_tags = [];
        }
        // aggiunge o toglie tag
        if (user.sample_variable_tags.includes(tag)) {
          const temp = user.sample_variable_tags;
          const index = temp.indexOf(tag);
          temp.splice(index, 1);
          user.sample_variable_tags = temp;
        } else {
          user.sample_variable_tags = [...user.sample_variable_tags, tag];
        }
        // update utente api
        this.teamService.addModifyUser(user).subscribe((res: ResponseModel) => {
          // TODO
        });
      }
    }
  }

  submitForm() {
    if (this.submitting) {
      return false;
    }
    const body = this.form.value;
    body.email = (body.email.email || body.email).trim();
    if (body.email) {
      this.submitting = true;
      body.role = 'INTERVIEWED';
      body.country_uniqid = this.country.country_uniqid;
      this.teamService.addModifyUser(body).subscribe((res: ResponseModel) => {
        this.toastService.success(this.translateService.instant('SUCCESS_GENERIC'));
        const existing = this.checkIfExisting(res.getData());
        this.loadUsers();
        this.formGroup();
        this.submitting = false;
      }, err => {
        this.submitting = false;
        for (const key in err.errors) {
          if (err.errors.hasOwnProperty(key)) {
            const element = err.errors[key];
            this.toastService.error(this.translateService.instant('ERRORS.' + element.toUpperCase()));
          }
        }
      });
    } else {
      this.toastService.error(this.translateService.instant('MISSING_DATA'));
    }
  }

  checkIfExisting(newUser: TeamModel) {
    for (const user of this.users) {
      if (newUser.email === user.email) {
        this.toastService.info(this.translateService.instant('USER_ALREADY_EXISTING_DATA_UPDATED'));
        return true;
      }
    }
  }

  uploadExcel(files: FileList) {
    const fileToUpload = files.item(0);
    const body = {
      country_uniqid: this.country.country_uniqid
    };

    this.teamService.addUsersByExcel(fileToUpload, 'excel', body).subscribe((res: ResponseModel) => {
      const users = res.getData();
      this.users = users;
      this.toastService.success(this.translateService.instant('UPLOAD_SUCCESS'));
      this.save(false);
    }, (error) => {
      for (const k in error.error.errors) {
        if (Object.prototype.hasOwnProperty.call(error.error.errors, k)) {
          const element = error.error.errors[k];
          console.log(element);
          if (element.includes('Row')) {
            const row = element.match(/^[^\d]*(\d+)/)[1] || 0
            const error = element.substring(element.indexOf(', ') + 2, element.indexOf(':')).trim()
            const target = element.substring(element.indexOf(': ') + 2, element.length -1).trim()

            this.toastService.error(this.translateService.instant(`XLS_UPLOAD_ERRORS.${error.toUpperCase()}`, {
              row,
              target
            }));
          } else {
            this.toastService.error(this.translateService.instant('ERRORS.' + element.toUpperCase()));
          }
        }
      }

    });
  }

  insertTag(e) {
    if (e.key === ',') {
      const newTag = this.stripHtmlPipe.transform(e.target.innerHTML.substring(0, e.target.innerHTML.length - 1).replace(',', ''));
      e.target.innerHTML = '';
      this.addTag(newTag);
    }
  }

  addTag(e) {
    let tag = document.getElementById('add-tag-input')['value'];
    tag = tag.trim().toLowerCase().replace("'", "");
    if (tag && !this.country.sample_variable_tags.includes(tag)) {
      this.country.sample_variable_tags = [...this.country.sample_variable_tags, tag];
      this.setColumns();
      this.save(false);
      document.getElementById('add-tag-input')['value'] = '';
    } else {
      this.toastService.error(this.translateService.instant('INVALID_INPUT'))
    }
  }

  removeTag(tag) {
    this.country.sample_variable_tags = this.country.sample_variable_tags.filter(t => t !== tag);
    this.save(false);
    this.setColumns();
  }

  save(close = true) {
    this.countryService.updateCountry(this.country).subscribe((res: ResponseModel) => {
      if (close) {
        this.coreLayoutService.closeSidebar();
      }
    });
  }

  openSmartSetupModal() {
    this.modalService.open({ modalModel: SmartUserSetupComponent, dialogSize: 'custom' }, { countryUniqId: this.country.country_uniqid });
  }

  changeSelectedUsers(id) {
    if (id === 'all') {
      if (this.selectedUsers.length > 0) {
        this.selectedUsers = [];
      } else {
        this.selectedUsers = [];
        for (const u of this.users) {
          this.selectedUsers.push(u.userid);
        }
      }
    } else {
      const indexOf = this.selectedUsers.indexOf(id);
      if (indexOf >= 0) {
        this.selectedUsers.splice(indexOf, 1);
      } else {
        this.selectedUsers.push(id);
      }
    }
  }

  sendInvite() {
    this.sendMessage = true;
  }

  sendPassword() {
    this.teamService.createNewPassword(this.selectedUsers, this.country.country_uniqid).subscribe((res: ResponseModel) => {
      this.toastService.info(this.translateService.instant('SENT'));
      this.selectedUsers = [];
    });
  }

  removeUser() {
    this.modalService.open({
      title: 'ARE_YOU_SURE.?',
      btnOkText: 'YES',
      btnCancelText: 'CANCEL',
      showBtnClose: false,
      showCancelBtn: true,
      customClass: 'confirm-modal',
      message: 'ARE_YOU_SURE_DELETE.?'
    }).then(data => {
      if (data) {
        this.teamService.deleteFromCountries(this.selectedUsers, this.country.country_uniqid).subscribe((res: ResponseModel) => {
          const users = this.users.filter(u => !this.selectedUsers.includes(u.userid));
          this.users = users;
          this.selectedUsers = [];
          this.toastService.success(this.translateService.instant('REMOVED'));

        }, (err) => {
          this.toastService.error(this.translateService.instant('CAN_NOT_REMOVE_INTERVIEWEE_AFTER_3_DAYS'));
        });
      }
    })
  }

  openEditUser(user: TeamModel) {
    user.country_uniqid = this.country.country_uniqid;
    this.currentUser = user;
    this.editUser = true;
  }

  saveEditUser() {
    this.editInterviewed.submit();
    this.editUser = false;
  }

  columnClick(e) {
    if (e === 'MAIL/LOGIN') {
      this.sortByMail();
    }
    if (e === 'FIRSTNAME_AND_LASTNAME') {
      this.sortByName();
    }
    if (e === 'NAME') {
      this.sortByNickname();
    }
  }

  msgSent() {
    this.loadUsers();
    this.selectedUsers = [];
    this.sendMessage = false;
  }

  sortByMail() {
    this.sortOrder = this.sortOrder === 'desc' ? 'asc' : 'desc';
    this.sortAttribute = 'email';
    this.loadUsers();
  }
  sortByName() {
    this.sortOrder = this.sortOrder === 'desc' ? 'asc' : 'desc';
    this.sortAttribute = 'firstname';
    this.loadUsers();
  }
  sortByNickname() {
    this.sortOrder = this.sortOrder === 'desc' ? 'asc' : 'desc';
    this.sortAttribute = 'nickname';
    this.loadUsers();
  }
}
