import { BaseModel } from '@app/core/models/base';


export class ManageUsersModel extends BaseModel {

    static getResourceName() {
        return 'manage-users';
    }

}
