<span class="hover-pointer notifications"
    placement="bottom"
    [autoClose]="'outside'"
    [ngbPopover]="popContent"
>
    <i class="fal fa-bell link" [ngClass]="noticesCount && noticesCount > 0 ? 'ring-bell-animation' : ''"></i>
    <span class="count-num">{{noticesCount || 0}}</span>
    <ng-template #popContent>
        <h5>{{ 'NOTIFICATIONS' | translate }}</h5>
        <app-core-spinner [toggle]="!notices"></app-core-spinner>

        <div class="notifications-list" [ngClass]="{'opening-notice': openingNoticeId}" *ngIf="notices">
            <div *ngFor="let n of notices">
                <div [ngClass]="{'opening-this': openingNoticeId == n.id}" class="bg-white border shadow p-3 my-1 card d-flex flex-row align-items-center position-relative cursor-pointer" (click)="openNotice(n)">
                    <div class="overlay" *ngIf="openingNoticeId == n.id">
                        <div class="d-flex justify-content-center">
                            <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                    <span class="flex-grow-1 d-flex">
                        <span>
                            <!--<img *ngIf="n.from_team.avatar_url" [src]="n.from_team.avatar_url | privatesrc" style="width: 15%;">-->
                            <app-shared-avatar *ngIf="n.from_team.avatar_url" [imageUrl]="n.from_team.avatar_url | privatesrc"></app-shared-avatar>
                        </span>

                        <div class="d-flex flex-grow-1 flex-column pl-3">
                            <div><b>{{n.from_team.nickname}}</b></div>
                            <span class="block-with-text flex-grow-1" *ngIf="n.type === 'COMMENT'">{{'NOTIFICATION.COMMENT' | translate}}</span>
                            <span class="block-with-text flex-grow-1" *ngIf="n.type === 'LIKE'">{{'NOTIFICATION.LIKE' | translate}}</span>
                            <span class="block-with-text flex-grow-1" *ngIf="n.type === 'MENTION'">{{'NOTIFICATION.MENTION' | translate}}</span>
                            <span class="block-with-text flex-grow-1" *ngIf="n.type === 'CUSTOMER_ACCESS'">{{'NOTIFICATION.CUSTOMER_ACCESS' | translate:({email: (n.from_team.reference_email || n.from_team.email)})}}</span>
                        </div>

                    </span><span class="flex-grow-0"><i class="far fa-chevron-right text-primary-color bold"></i></span>
                </div>
            </div>
        </div>
    </ng-template>
</span>