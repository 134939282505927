import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { TemplateMessageModel } from '../models/template-message.model';

@Injectable({
  providedIn: 'root'
})
export class TemplateMessageService extends BaseService implements BaseServiceI {
  // tslint:disable: variable-name
  constructor(public router: Router, public apiService: ApiService, private http: HttpClient) {
    super(router, apiService, TemplateMessageModel);
  }

  getTemplateMessages() {
    return this.get('');
  }

  createTemplateMessage(data) {
    return this.create(data);
  }

  editTemplateMessage(data) {
    return this.sub(`${data.id}`).create(data);
  }

  deleteTemplateMessage(id) {
      return this.deleteById('/manage-users/template-message/', id);
  }
}
