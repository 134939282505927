import { ResearchModel } from '../models/research.model';
import { Injectable } from '@angular/core';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { BaseService } from '@app/core/services/base.service';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { ServiceMessageModel } from '@app/core/models/service-message.model';
import { DatetimeToDbPipe } from '@app/core/pipes/datetime-to-db.pipe';
import { AnalysisModel } from '../models/analysis.model';
import { QuestionModel } from '../models/question.model';
import { AnswerModel } from '../models/answer.model';
import { ResponseModel } from '@app/core/models/response.model';
import { catchError, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AnalysisService extends BaseService implements BaseServiceI {

  // tslint:disable: variable-name
  message$: BehaviorSubject<ServiceMessageModel> = new BehaviorSubject(null);

  constructor(public router: Router, public apiService: ApiService, private http: HttpClient, private datetimeToDbPipe: DatetimeToDbPipe) {
    super(router, apiService, AnalysisModel);
  }

  getTagStats(countryUniqId: string) {
    return this.sub(`${countryUniqId}/tag-cloud`).as(null).get();
  }

  getAnswersBy(countryUniqId: string, query: any, model:any = AnswerModel) {
    return this.sub(`${countryUniqId}/answers`).as(model).get('', query);
  }

  getQuestionsBy(countryUniqId: string, query: any) {
    return this.sub(`${countryUniqId}/questions`, QuestionModel).get('', query);
  }

  getPostsBy(countryUniqId: string, query: any) {
    return this.sub(`${countryUniqId}/all-posts`, QuestionModel).get('', query);
  }

  getQuotesBy(countryUniqId: string, query: any) {
    return this.sub(`${countryUniqId}/quotes`).get('', query);
  }

  getDownloadQuotesBy(countryUniqId: string, query: any) {
    return this.sub(`${countryUniqId}/quotes`).getDownload('', query);
  }

  getAudiosBy(countryUniqId: string, data?: any) {
    return this.sub(`${countryUniqId}/audio`).create(data);
  }

  getVideosBy(countryUniqId: string, data?: any) {
    return this.sub(`${countryUniqId}/videos`).create(data);
  }

  getDownloadVideosBy(countryUniqId: string, data?: any) {
    return this.sub(`${countryUniqId}/videos`).createDownload(data);
  }

  getDownloadAudiosBy(countryUniqId: string, data?: any) {
    return this.sub(`${countryUniqId}/audio`).createDownload(data);
  }

  getAudioBy(countryUniqId: string, data?: any) {
    return this.sub(`${countryUniqId}/audio`).create(data);
  }

  getImagesBy(countryUniqId: string, data?: any) {
    return this.sub(`${countryUniqId}/images`).create(data);
  }

  getDownloadImagesBy(countryUniqId: string, data?: any) {
    return this.sub(`${countryUniqId}/images`).createDownload(data);
  }

  setSmartConfig(countryUniqId: string, data?: any) {
    return this.sub(`${countryUniqId}/customer-basic-filter`).create(data);
  }

  getSmartConfig(countryUniqId: string, query?: any) {
    return this.sub(`${countryUniqId}/customer-basic-filter`).get('', query);
  }

  getAnswersExport(countryUniqId, answersImages, commentsIds = []) {
    return this.sub(`${countryUniqId}/download-answers`).createDownload({answerids: answersImages, commentids: commentsIds});
  }

  getAllData(countryUniqId: string, dataUniqid: string) {
    return this.get(`${countryUniqId}/all-data/${dataUniqid}`, null, true);
  }

  getExportPdf(countryUniqId: string,) {
    return this.getDownload(`${countryUniqId}/export-all`);
  }

  getExportPdfEmail(countryUniqId: string) {
    return this.get(`${countryUniqId}/export-all`);
  }

  getExportCountryAsXls(countryUniqId: string) {
    return this.get(`${countryUniqId}/export-country-xls`);
  }

  getPostXlsExport(countryUniqId: string, answer_ids: string[], comment_ids: string[], type: 'tagcloud' | 'selected_content' | 'content_tag' | 'all_posts') {
    return this.sub(`${countryUniqId}/export-posts-xls`).create({answer_ids, comment_ids, type});
  }

  getQuotesXlsExport(countryUniqId: string, quote_ids: string[]) {
    return this.sub(`${countryUniqId}/export-quotes-xls`).create({quote_ids})
  }

  getPostExport(countryUniqId: string, posts: any) {
    return this.sub(`${countryUniqId}/export-posts`).create({posts})
  }


  getExternalViewData(token) {
    return this.apiService.get('/export/get-external-view-data/' + token)
    .pipe(
      map((item: ResponseModel) => {
        return item;
      }),
      catchError(this.errorHandl)
    );
  }
}
