import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '@app/core/services/modal.service';
import { ModalComponent } from '@app/core/components/modal/modal.component';
import { ModalOptions } from '@app/core/components/modal/modal-options';

@Component({
  selector: 'app-private-modal-new-research',
  templateUrl: './new-research.component.html',
  styleUrls: ['./new-research.component.scss']
})
export class NewResearchModalComponent extends ModalComponent implements OnInit {

  static defaultOptions: ModalOptions = {
    title: 'ADD_NOTE',
    btnOkText: 'ADD',
    dialogSize: 'lg',
    showOkBtn: false,
    showCancelBtn: false,
    showCloseBtn: false
  };

  addedCallback = null;

  constructor(public activeModal: NgbActiveModal, private fb: UntypedFormBuilder) {
                super(activeModal);
  }

  ngOnInit() {
  }

  newResearchAdded(data) {
    this.params?.addedCallback();
  }

  submitNote() {
  }

}
