<div class="demo-alert p-2 text-center bg-sicche col-6 offset-3 text-white border d-flex align-items-center justify-content-center"  *ngIf="research && research.is_demo">
    <h3 class="text-white mb-0">{{'IS_DEMO_ALERT' | translate}}</h3>
</div>

<div class="demo-alert p-2 mt-2 text-center bg-sicche col-6 offset-3 text-white border d-flex align-items-center justify-content-center"  *ngIf="research && research.status === 'BUSY'">
    <h3 class="text-white mb-0">{{'RESEARCH_IS_BUSY' | translate}}</h3>
</div>

<app-private-researches-navigation-bar></app-private-researches-navigation-bar>
<div class="pt-5">
    <div *ngIf="researchSessionService.getResearch() && researchSessionService.getResearchUniqId() && countryService.country$.value && teamService.currentUser$">
        <router-outlet></router-outlet>
    </div>
</div>
