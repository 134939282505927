<div
    *ngIf="smallDeviceAlert"
    (click)="smallDeviceAlert = false"
    style="
    width: 300px;
    position: absolute;
    z-index: 999999;
    top: 10px;
    left: 50%;
    margin-left: -150px;
    background-color: var(--orange);
    font-size: 1rem;
    color: white;
    display: flex;
    padding: 10px;
    text-align: center;
    border-radius: 4px;
    "
>
    <span class="pointer mr-auto pl-2">X</span>
    <br>
    {{'INVALID_DEVICE_RESOLUTION' | translate }}
<br>
</div>
<router-outlet></router-outlet>