import { BaseModel } from '../base';
import { LayoutButtonInterface } from './layout-button.interface';

export class LayoutButtonModel implements LayoutButtonInterface {

  label: string;
  slug: string;
  loading?: boolean;
  enabled: boolean;
  icon?: string;
  click?: any;
  classes?: any;

  constructor(data: LayoutButtonInterface) {
    this.enabled = true;
    this.loading = false;
    Object.assign(this, data);
    if (!this.slug) {
      this.slug = String(Math.random());
    }
  }

  enable() {
    this.enabled = true;
  }

}
