// tslint:disable: max-line-length

import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

// App.component
import { AppComponent } from './app.component';

import { FlickityModule } from 'ngx-flickity';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgxStarsModule } from 'ngx-stars';
// color picker
import { ColorPickerModule } from 'ngx-color-picker';

// masonry
import { NgxMasonryModule } from 'ngx-masonry';

// ngb
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// html editor
import { QuillModule } from 'ngx-quill';

// info: need to be imported in every child module
import {TranslateModule } from '@ngx-translate/core';

import { HighchartsChartModule } from 'highcharts-angular';

// Pages
import { PublicComponent } from '@app/pages/layers/public/public.component';
import { PrivateComponent } from '@app/pages/layers/private/private.component';
import { AuthComponent } from '@app/pages/layers/public/auth/auth.component';
import { AuthNavbarComponent } from '@app/pages/layers/public/auth/auth-navbar/auth-navbar.component';
import { AuthLoginFormComponent } from '@app/pages/layers/public/auth/auth-loginform/auth-loginform.component';
import { AuthSignupFormComponent } from '@app/pages/layers/public/auth/auth-signupform/auth-signupform.component';

import { AvatarImgComponent } from '@shared/components/avatar-img/avatar-img.component';
import { SiccheEmoticonComponent } from './shared/components/sicche-emoticon/sicche-emoticon.component';

// Auth
import { ForgottenPasswordComponent } from '@app/pages/layers/public/auth/auth-loginform/forgotten-password/forgotten-password.component';
import { ResetPasswordComponent } from '@app/pages/layers/public/auth/auth-loginform/reset-password/reset-password.component';

// Dashboard
import { DashboardComponent } from '@app/pages/layers/private/dashboard/dashboard.component';
import { InterviewedTableComponent } from '@app/pages/layers/private/dashboard/interviewed-table/interviewed-table.component';
import { ResearchesModalComponent } from '@app/pages/layers/private/dashboard/interviewed-table/researches-modal/researches-modal.component';
import { BlogComponent } from '@app/pages/layers/private/dashboard/blog/blog.component';
import { ResearchesComponent } from '@app/pages/layers/private/dashboard/researches/researches.component';


// Toolbar items (entryComponents)
import { ToolbarNotificationsComponent } from '@app/pages/layers/private/toolbar/notifications/toolbar-notifications.component';
import { ToolbarLogoutComponent } from '@app/pages/layers/private/toolbar/logout/toolbar-logout.component';
import { ToolbarProfileComponent } from '@app/pages/layers/private/toolbar/profile/toolbar-profile.component';
import { HelpComponent } from '@app/pages/layers/private/toolbar/help/help.component';
// Modals
import { NewResearchModalComponent } from '@app/pages/layers/private/modals/new-research/new-research.component';
import { ForgottenPasswordModalComponent } from '@app/pages/layers/public/auth/modals/forgotten-password/forgotten-password-modal.component';
import { ResetPasswordModalComponent } from '@app/pages/layers/public/auth/modals/reset-password/reset-password-modal.component';


// Researches
import { ResearchesDashboardComponent } from '@app/pages/layers/private/researches/researches.component';
// .partials
import { ResearchesSliderComponent } from '@app/pages/layers/private/researches/partials/researches-slider/researches-slider.component';
import { NewResearchComponent } from '@app/pages/layers/private/researches/new-research/new-research.component';
import { SetupComponent } from './pages/layers/private/researches/navigation/setup/setup.component';
import { AddQuestionComponent } from '@app/pages/layers/private/researches/navigation/setup/add-question/add-question.component';
import { QuestionsTypesComponent } from '@app/pages/layers/private/researches/navigation/setup/add-question/questions-types/questions-types.component';
import { QuestionsAnswerOptionComponent } from '@app/pages/layers/private/researches/navigation/setup/add-question/questions-types/partials/answer-option/answer-option.component';


// Question types
import { QuestionsMediaUploadComponent } from './pages/layers/private/researches/navigation/setup/add-question/questions-types/partials/media-upload/media-upload.component';
import { QuestionsSharedMediaUploadComponent } from './pages/layers/private/researches/navigation/setup/add-question/questions-types/partials/shared-media-upload/shared-media-upload.component';
import { QuestionsSelectsComponent } from '@app/pages/layers/private/researches/navigation/setup/add-question/questions-types/partials/selects/selects.component';
import { QuestionsTextComponent } from '@app/pages/layers/private/researches/navigation/setup/add-question/questions-types/partials/text/text.component';
import { OpenAnswerQuestionComponent } from '@app/pages/layers/private/researches/navigation/setup/add-question/questions-types/open-answer/open-answer.component';
import { ClosedMultipleQuestionComponent } from '@app/pages/layers/private/researches/navigation/setup/add-question/questions-types/closed-multiple/closed-multiple.component';
import { ClosedSingleQuestionComponent } from '@app/pages/layers/private/researches/navigation/setup/add-question/questions-types/closed-single/closed-single.component';
import { CommunicationsQuestionComponent } from '@app/pages/layers/private/researches/navigation/setup/add-question/questions-types/communications/communications.component';
import { SentenceCompletionQuestionComponent } from '@app/pages/layers/private/researches/navigation/setup/add-question/questions-types/sentence-completion/sentence-completion.component';
import { AttitudeScaleQuestionComponent } from '@app/pages/layers/private/researches/navigation/setup/add-question/questions-types/attitude-scale/attitude-scale.component';
import { VocalAnswerQuestionComponent } from '@app/pages/layers/private/researches/navigation/setup/add-question/questions-types/vocal-answer/vocal-answer.component';
import { ImageAnswerQuestionComponent } from '@app/pages/layers/private/researches/navigation/setup/add-question/questions-types/image-answer/image-answer.component';
import { VideoAnswerQuestionComponent } from '@app/pages/layers/private/researches/navigation/setup/add-question/questions-types/video-answer/video-answer.component';
import { SemanticDifferentialsQuestionComponent } from './pages/layers/private/researches/navigation/setup/add-question/questions-types/semantic-differentials/semantic-differentials.component';
import { GroupingCategoryQuestionComponent } from './pages/layers/private/researches/navigation/setup/add-question/questions-types/grouping-category/grouping-category.component';
import { PreferenceRankingQuestionComponent } from './pages/layers/private/researches/navigation/setup/add-question/questions-types/preference-ranking/preference-ranking.component';
import { ImageAssociationQuestionComponent } from './pages/layers/private/researches/navigation/setup/add-question/questions-types/image-association/image-association.component';
import { PinOnMediaQuestionComponent } from './pages/layers/private/researches/navigation/setup/add-question/questions-types/pin-on-media/pin-on-media.component';

import { GoogleMapsModule } from '@angular/google-maps'

import { NavigationComponent } from './pages/layers/private/researches/navigation/navigation.component';
import { NavigationBarComponent } from './pages/layers/private/researches/navigation-bar/navigation-bar.component';
import { ModerationComponent } from './pages/layers/private/researches/navigation/moderation/moderation.component';
import { ModerationPeopleViewComponent } from './pages/layers/private/researches/navigation/moderation/people-view/people-view.component';
import { ModerationPrivateMessageComponent } from './pages/layers/private/researches/navigation/moderation/people-view/private-message/private-message.component';
import { ModerationPeopleAnswerComponent } from './pages/layers/private/researches/navigation/moderation/people-view/answer/answer.component';
import { ModerationPeopleQuestionAnswerComponent } from './pages/layers/private/researches/navigation/moderation/people-view/question-answer/question-answer.component';
import { ModerationStarsRatingComponent } from '@app/pages/layers/private/researches/navigation/moderation/partials/stars-rating/stars-rating.component';
import { ModerationQuotesModalComponent } from '@app/pages/layers/private/researches/navigation/moderation/partials/quotes-modal/quotes-modal.component';
import { MonitoringComponent } from './pages/layers/private/researches/navigation/monitoring/monitoring.component';
import { MessagesComponent } from './pages/layers/private/researches/navigation/messages/messages.component';
import { AnalysisComponent } from './pages/layers/private/researches/navigation/analysis/analysis.component';
import { SmartUserSetupComponent } from './pages/layers/private/researches/navigation/analysis/smart-setup-modal/smart-setup-modal.component';
import { AnswersComponent } from '@app/pages/layers/private/researches/navigation/moderation/answers-modals/answers-modal.component';
import { AnswersGroupWrapperComponent } from '@app/pages/layers/private/researches/navigation/moderation/answers-modals/answer-group-wrapper/answer-group-wrapper.component';
import { AnswersBlockComponent } from '@app/pages/layers/private/researches/navigation/moderation/answers-modals/answer-group-wrapper/answer/answer-block.component';
import { AnswersBlockCommentComponent } from '@app/pages/layers/private/researches/navigation/moderation/answers-modals/answer-group-wrapper/answer/comment/comment/answer-comment.component';
import { AnswersBlockCommentMediaComponent } from '@app/pages/layers/private/researches/navigation/moderation/answers-modals/answer-group-wrapper/answer/comment/comment/comment-media/comment-media.component';
import { AnswersBlockCommentWrapperComponent } from '@app/pages/layers/private/researches/navigation/moderation/answers-modals/answer-group-wrapper/answer/comment/comment-block.component';
import { AnswersPieChartComponent } from './pages/layers/private/researches/navigation/moderation/answers-modals/pie-chart/pie-chart.component';
import { WysiwygComponent } from './pages/layers/private/researches/navigation/moderation/answers-modals/wysiwyg/wysiwyg.component';
import { AnswersUserAvatarComponent } from './pages/layers/private/researches/navigation/moderation/answers-modals/user-avatar/user-avatar.component';
import { AnswersModerationDispatcherComponent } from '@app/pages/layers/private/researches/navigation/moderation/answer-dispatcher/answer-dispatcher.component';
import { ChartsModerationDispatcherComponent } from '@app/pages/layers/private/researches/navigation/moderation/charts-dispatcher/charts-dispatcher.component';


// Answers types blocks
import { AnswersTypeClosedMultipleComponent } from './pages/layers/private/researches/navigation/moderation/answer-types/closed-multiple/closed-multiple.component';
import { AnswersTypeOpenComponent } from './pages/layers/private/researches/navigation/moderation/answer-types/open-answer/open-answer.component';
import { AnswersTypeClosedSingleComponent } from './pages/layers/private/researches/navigation/moderation/answer-types/closed-single/closed-single.component';
import { AnswersTypeImageAnswerComponent } from './pages/layers/private/researches/navigation/moderation/answer-types/image-answer/image-answer.component';
import { AnswersTypeVideoAnswerComponent } from './pages/layers/private/researches/navigation/moderation/answer-types/video-answer/video-answer.component';
import { AnswersTypeVocalAnswerComponent } from './pages/layers/private/researches/navigation/moderation/answer-types/vocal-answer/vocal-answer.component';
import { AnswersTypeSentenceCompletionComponent } from './pages/layers/private/researches/navigation/moderation/answer-types/sentence-completion/sentence-completion.component';
import { AnswersTypeSemanticDifferrentialsComponent } from './pages/layers/private/researches/navigation/moderation/answer-types/semantic-differentials/semantic-differentials.component';
import { AnswersTypeGroupingCategoryComponent } from './pages/layers/private/researches/navigation/moderation/answer-types/grouping-category/grouping-category.component';
import { AnswersTypeAttitudeScaleComponent } from './pages/layers/private/researches/navigation/moderation/answer-types/attitude-scale/attitude-scale.component';
import { AnswersTypePreferenceRankingComponent } from './pages/layers/private/researches/navigation/moderation/answer-types/preference-ranking/preference-ranking.component';
import { AnswersTypeImageAssociationComponent } from './pages/layers/private/researches/navigation/moderation/answer-types/image-association/image-association.component';
import { AnswersTypePinOnMediaComponent } from './pages/layers/private/researches/navigation/moderation/answer-types/pin-on-media/pin-on-media.component';

// Analisis and export
import { OverviewModalComponent } from '@app/pages/layers/private/researches/navigation/analysis/overview-modal/overview-modal.component';
import { OverviewModalCloudComponent } from '@app/pages/layers/private/researches/navigation/analysis/overview-modal/contents/cloud/cloud.component';
import { OverviewModalQuotesComponent } from '@app/pages/layers/private/researches/navigation/analysis/overview-modal/contents/quotes/quotes.component';
import { OverviewModalAudioComponent } from '@app/pages/layers/private/researches/navigation/analysis/overview-modal/contents/audio/audio.component';
import { OverviewModalImagesComponent } from '@app/pages/layers/private/researches/navigation/analysis/overview-modal/contents/images/images.component';
import { OverviewModalVideoComponent } from '@app/pages/layers/private/researches/navigation/analysis/overview-modal/contents/video/video.component';
import { OverviewModalFilterComponent } from '@app/pages/layers/private/researches/navigation/analysis/overview-modal/contents/partials/filter/filter.component';
import { OverviewModalStarsComponent } from '@app/pages/layers/private/researches/navigation/analysis/overview-modal/contents/stars/stars.component';
import { AnalysisAnswerWrapperComponent } from '@app/pages/layers/private/researches/navigation/analysis/overview-modal/contents/partials/answer-wrapper/answer-wrapper.component';

// Profile
import { ProfileEditComponent } from '@shared/components/profile/edit/profile-edit.component';
import { CustomizeResearchComponent } from './pages/layers/private/researches/navigation/setup/customize-research/customize-research.component';
import { CustomizePrivacyComponent } from './pages/layers/private/researches/navigation/setup/customize-research/customize-privacy/customize-privacy.component';
import { ManageTeamComponent } from './pages/layers/private/researches/navigation/setup/customize-research/manage-team/manage-team.component';
import { ManageRoomsComponent } from './pages/layers/private/researches/navigation/setup/manage-rooms/manage-rooms.component';
import { InstructionsComponent } from './pages/layers/private/researches/navigation/setup/manage-rooms/instructions/instructions.component';
import { ManageQuestionsComponent } from './pages/layers/private/researches/navigation/setup/manage-rooms/manage-questions/manage-questions.component';
import { BlockComponent } from './pages/layers/private/researches/navigation/setup/manage-rooms/manage-questions/block/block.component';
import { QuestionItemComponent } from './pages/layers/private/researches/navigation/setup/manage-rooms/manage-questions/block/question-item/question-item.component';
import { CreateEditMessageComponent } from './pages/layers/private/researches/navigation/messages/create-edit-message/create-edit-message.component';
import { ManageInterviewedsComponent } from './pages/layers/private/researches/navigation/setup/customize-research/manage-intervieweds/manage-intervieweds.component';
import { EditInterviewedComponent } from './pages/layers/private/researches/navigation/setup/customize-research/manage-intervieweds/edit-interviewed/edit-interviewed.component';
import { ActivateUserInRoomComponent } from './pages/layers/private/researches/navigation/setup/manage-rooms/activate-user-in-room/activate-user-in-room.component';
import { CheckSetupComponent } from './pages/layers/private/researches/navigation/setup/customize-research/check-setup/check-setup.component';
import { CheckCustomerStatusComponent } from './pages/layers/private/researches/navigation/monitoring/check-customer-status/check-customer-status.component';
import { MissingAnswersComponent } from './pages/layers/private/researches/navigation/monitoring/missing-answers/missing-answers.component';
import { SetCountryRedirectComponent } from './pages/layers/public/set-country-redirect/set-country-redirect.component';
import { AnswerExportComponent } from './pages/public/answer-export/answer-export.component';
import { PostExportComponent } from './pages/public/post-export/post-export.component';
import { EditTemplateComponent } from './pages/layers/private/researches/navigation/messages/edit-template/edit-template.component';

import { BackendLogger } from './core/classes/error-handler';
import { ExportExternalViewComponent } from './pages/public/export-external-view/export-external-view.component';
import { OverviewModalAllPostsComponent } from './pages/layers/private/researches/navigation/analysis/overview-modal/contents/all-posts/all-posts.component';
import { AnalysisSidebarHeaderComponent } from './pages/layers/private/researches/navigation/analysis/overview-modal/contents/components/analysis-sidebar-header/analysis-sidebar-header.component';

@NgModule({
    declarations: [
        AppComponent,
        // Pages
        PublicComponent,
        HelpComponent,
        PrivateComponent,
        AvatarImgComponent,
        SiccheEmoticonComponent,
        ProfileEditComponent,
        ManageInterviewedsComponent,
        EditInterviewedComponent,
        AuthComponent,
        DashboardComponent,
        ResearchesModalComponent,
        AuthNavbarComponent,
        AuthLoginFormComponent,
        AuthSignupFormComponent,
        ToolbarNotificationsComponent,
        ToolbarLogoutComponent,
        ToolbarProfileComponent,
        NewResearchModalComponent,
        ForgottenPasswordModalComponent,
        InterviewedTableComponent,
        BlogComponent,
        ResearchesComponent,
        ResearchesSliderComponent,
        ResearchesDashboardComponent,
        NewResearchComponent,
        ForgottenPasswordComponent,
        ResetPasswordComponent,
        ResetPasswordModalComponent,
        SetupComponent,
        AddQuestionComponent,
        QuestionsTypesComponent,
        QuestionsAnswerOptionComponent,
        QuestionsMediaUploadComponent,
        QuestionsSharedMediaUploadComponent,
        QuestionsTextComponent,
        OpenAnswerQuestionComponent,
        ClosedMultipleQuestionComponent,
        ClosedSingleQuestionComponent,
        CommunicationsQuestionComponent,
        SentenceCompletionQuestionComponent,
        VocalAnswerQuestionComponent,
        ImageAnswerQuestionComponent,
        VideoAnswerQuestionComponent,
        SemanticDifferentialsQuestionComponent,
        GroupingCategoryQuestionComponent,
        PreferenceRankingQuestionComponent,
        AttitudeScaleQuestionComponent,
        ImageAssociationQuestionComponent,
        PinOnMediaQuestionComponent,
        QuestionsSelectsComponent,
        AnswersComponent,
        AnswersGroupWrapperComponent,
        AnswersBlockComponent,
        AnswersBlockCommentComponent,
        AnswersBlockCommentMediaComponent,
        AnswersBlockCommentWrapperComponent,
        AnswersPieChartComponent,
        AnswersTypeClosedMultipleComponent,
        AnswersTypeClosedSingleComponent,
        AnswersTypeOpenComponent,
        AnswersTypeImageAnswerComponent,
        AnswersTypeVideoAnswerComponent,
        AnswersTypeVocalAnswerComponent,
        AnswersTypeSentenceCompletionComponent,
        AnswersTypeImageAssociationComponent,
        AnswersTypeSemanticDifferrentialsComponent,
        AnswersTypeGroupingCategoryComponent,
        AnswersTypeAttitudeScaleComponent,
        AnswersTypePreferenceRankingComponent,
        AnswersTypePinOnMediaComponent,
        WysiwygComponent,
        AnswersUserAvatarComponent,
        AnswersModerationDispatcherComponent,
        ChartsModerationDispatcherComponent,
        NavigationComponent,
        NavigationBarComponent,
        ModerationComponent,
        ModerationPeopleViewComponent,
        ModerationPrivateMessageComponent,
        ModerationPeopleAnswerComponent,
        ModerationPeopleQuestionAnswerComponent,
        ModerationStarsRatingComponent,
        ModerationQuotesModalComponent,
        MonitoringComponent,
        MessagesComponent,
        AnalysisComponent,
        SmartUserSetupComponent,
        CustomizeResearchComponent,
        CustomizePrivacyComponent,
        ManageTeamComponent,
        ManageRoomsComponent,
        InstructionsComponent,
        ManageQuestionsComponent,
        BlockComponent,
        QuestionItemComponent,
        OverviewModalComponent,
        OverviewModalCloudComponent,
        OverviewModalFilterComponent,
        AnalysisAnswerWrapperComponent,
        OverviewModalQuotesComponent,
        OverviewModalAudioComponent,
        OverviewModalVideoComponent,
        OverviewModalStarsComponent,
        OverviewModalImagesComponent,
        CreateEditMessageComponent,
        CheckSetupComponent,
        ActivateUserInRoomComponent,
        CheckCustomerStatusComponent,
        MissingAnswersComponent,
        SetCountryRedirectComponent,
        HelpComponent,
        AnswerExportComponent,
        PostExportComponent,
        EditTemplateComponent,
        ExportExternalViewComponent,
        OverviewModalAllPostsComponent,
        AnalysisSidebarHeaderComponent
    ],
    imports: [
        GoogleMapsModule,
        ReactiveFormsModule,
        NgxMasonryModule,
        FormsModule,
        BrowserModule,
        RouterModule,
        AppRoutingModule,
        TranslateModule,
        NgxStarsModule,
        HighchartsChartModule,
        SharedModule,
        CoreModule,
        CommonModule,
        FlickityModule,
        ColorPickerModule,
        DragDropModule,
        NgbModule,
        QuillModule.forRoot(),
        AutocompleteLibModule
    ],
    providers: [],
    bootstrap: [AppComponent],
    exports: [
        TranslateModule
    ]
})
export class AppModule { }
