<app-shared-sidebar-container *ngIf="form" >
  <app-shared-sidebar-header>
    <div slot="title">
      <h2 class="mb-0">{{ 'ACCOUNT_MANAGEMENT' | translate }}</h2>
    </div>
    <div slot="buttons" class="text-right">
      <app-core-button-submit
        [classes]="'mr-5 btn-sicche active'"
        [spinnerDimension]="'small'"
        [form]="form"
        (submitCallback)="submit()"
        [toggle]="operation('saving-data').submitting">
        <span><i class="fal fa-check"></i>&nbsp;{{ 'SAVE' | translate }}</span>
      </app-core-button-submit>
    </div>
  </app-shared-sidebar-header>
  <div class="sidebar-body small">
    <div>
      <div *ngIf="operation('saving-data').errors" class="alert alert-danger">
        {{ 'ERRORS.HTTP_GENERIC_ERRORS' | translate }}
      </div>
      <div *ngIf="operation('saving-data').success" class="alert alert-success">
        {{ 'DATA_UPDATE_SUCCESS' | translate }}
      </div>
    </div>
    <div class="container" *ngIf="!isAdmin">
      <app-core-spinner [toggle]="loading"></app-core-spinner>
      <div>
        <div class="center mb-5">
          <div class="title d-none">
            <h1></h1>
          </div>
          <div class="mb-3">
            <div class="avatar" *ngIf="avatarUrl">
              <!--<div *ngIf="!new" style="max-height:150px;" [src]="avatarUrl | privatesrc" / [styles]="{height: '300px'}">-->
              <div *ngIf="!new" class="image" [ngStyle]="{'background-image':'url('+ (avatarUrl | privatesrc) +')'}"></div>
              <div *ngIf="new" class="image" [ngStyle]="{'background-image':'url('+ (avatarUrl) +')'}"></div>
              <!--<img *ngIf="new" style="max-height:150px;" [src]="avatarUrl" />-->
            </div>
            <i *ngIf="!avatarUrl" class="fal fa-user avatar-placeholder"></i>
          </div>
          <div class="text-danger" *ngIf="operation('receiving-avatar').failed">{{ 'ERRORS.FILE_IS_NOT_IMAGE' | translate }}</div>
          <app-core-spinner [toggle]="operation('receiving-avatar').submitting"></app-core-spinner>
          <input type="file" class="d-none" accept="image/*" (change)="uploadPicture($event)" #file>
          <div class="mt-2">
            <a class="p-2 as-link" (click)="removePicture()" placement="bottom" [ngbTooltip]="'REMOVE_PICTURE' | translate">
              <i class="fal fa-trash-alt"></i>
            </a>
            <a class="p-2 as-link" placement="bottom" [ngbTooltip]="'UPLOAD_PICTURE' | translate">
              <i (click)="file.click()" class="fal fa-upload"></i>
            </a>
          </div>
        </div>
      </div>
      <form [formGroup]="form">
        <h4 class="mb-2">{{ 'PERSONAL_DATA' | translate }}</h4>
        <div class="row">
          <div class="col-md-6">
            <div [class.is-invalid]="form.get('firstname').invalid && form.get('firstname').touched">
              <div class="form-group required">
                <label>
                  <input required formControlName="firstname" type="text" class="form-control" id="firstname">
                  <span class="placeholder">{{ 'NAME' | translate }}</span>
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div [class.is-invalid]="form.get('lastname').invalid && form.get('lastname').touched">
              <div class="form-group required">
                <label>
                  <input required formControlName="lastname" type="text" class="form-control" id="lastname">
                  <span class="placeholder">{{ 'SURNAME' | translate }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div [class.is-invalid]="form.get('email').invalid && form.get('email').touched">
              <div class="form-group required">
                <label>
                  <input required formControlName="email" type="text" class="form-control" id="email">
                  <span class="placeholder">{{ 'EMAIL' | translate }}</span>
                </label>
              </div>
              <div *ngIf="form.get('email').invalid && form.get('email').touched">
                <small>
                  <span class="text-danger">{{ 'ERRORS.INVALID_EMAIL' | translate }}</span>
                </small>
              </div>
              <small *ngIf="operation('saving-data').errors?.email">
                <span class="text-danger">{{ ('SERVER_ERRORS.' + operation('saving-data').errors.email) | translate }}</span>
              </small>
            </div>
          </div>
          <div class="col-md-6">
            <div [class.is-invalid]="form.get('telephone').invalid && form.get('telephone').touched">
              <div class="form-group">
                <label>
                  <input formControlName="telephone" type="text" class="form-control" id="telephone">
                  <span class="placeholder">{{ 'TELEPHONE' | translate }}</span>
                </label>
              </div>
              <div *ngIf="form.get('telephone').invalid && form.get('telephone').touched">
                <small>
                  <span class="text-danger">{{ 'ERRORS.INVALID_TELEPHONE' | translate }}</span>
                </small>
              </div>
              <small *ngIf="operation('saving-data').errors?.telephone">
                <span class="text-danger">{{ ('SERVER_ERRORS.' + operation('saving-data').errors.telephone) | translate }}</span>
              </small>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6" *ngIf="form.value.company">
            <div [class.is-invalid]="form.get('company').invalid && form.get('company').touched">
              <div class="form-group required">
                <label>
                  <input readonly required formControlName="company" type="text" class="form-control" id="company">
                  <span class="placeholder">{{ 'COMPANY' | translate }}</span>
                </label>
              </div>
              <small *ngIf="operation('saving-data').errors?.company">
                <span class="text-danger">{{ ('SERVER_ERRORS.' + operation('saving-data').errors.company) | translate }}</span>
              </small>
            </div>
            <a href="mailto:help@sicche.com?subject=Richiesta cambio azienda">
              {{ 'REQUEST_CHANGE_COMPANY' | translate }}
              <span class="info-tooltip" placement="top" [ngbTooltip]="'TOOLTIPS.COMPANY_CHANGE' | translate">
                <i class="far fa-info-circle"></i>
              </span>
            </a>
          </div>
          <div class="col-md-6" *ngIf="!form.value.company">
            <div [class.is-invalid]="form.get('company_declared').invalid && form.get('company_declared').touched">
              <div class="form-group">
                <label>
                  <input formControlName="company_declared" type="text" class="form-control" id="company_declared">
                  <span class="placeholder">{{ 'COMPANY_DECLARED' | translate }}</span>
                </label>
              </div>
              <small *ngIf="operation('saving-data').errors?.company_declared">
                <span class="text-danger">{{ ('SERVER_ERRORS.' + operation('saving-data').errors.company_declared) | translate }}</span>
              </small>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <app-core-select
                [classes]="'form-control'"
                [formEntityName]="'language_code'"
                [form]="form"
                [preselected]="2"
                [placeholder]="'LANGUAGE'"
                [value]="'language_code'"
                [service]="languagesService">
              </app-core-select>
            </div>
          </div>
        </div>
        <h4 class="mt-2 mb-2">{{ 'MODIFY_PASSWORD' | translate }}</h4>
        <p class="text-soft">{{ 'PASSWORD_RULES' | translate:({param1: passwordMinLength, dumbTrigger: 'it'} | ParamsTranslate) }}</p>
        <div class="clearfix"></div>
        <div class="row">
          <div class="col-md-6">
            <div [class.is-invalid]="form.get('password').invalid && form.get('password').touched">
              <div class="form-group">
                <label>
                  <input formControlName="password" type="password" class="form-control" id="password">
                  <span class="placeholder">{{ 'NEW_PASSWORD' | translate }}</span>
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div [class.is-invalid]="form.get('password_confirm').invalid && form.get('password_confirm').touched">
              <div class="form-group">
                <label>
                  <input formControlName="password_confirm" type="password" class="form-control" id="password_confirm">
                  <span class="placeholder">{{ 'REPEAT_PASSWORD' | translate }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <div *ngIf="form.get('password').errors?.pattern || form.get('password_confirm').errors?.pattern && form.get('password').touched  && form.get('password_confirm').touched">
              <small>
                <span class="text-danger">{{ 'PASSWORD_RULES_WARNING' | translate:({param1: passwordMinLength, dumbTrigger: 'it'} | ParamsTranslate) }}</span>
              </small>
            </div>
            <div *ngIf="form.get('password').errors?.MatchFields || form.get('password_confirm').errors?.MatchFields && form.get('password').touched  && form.get('password_confirm').touched">
              <small>
                <span class="text-danger">{{ 'ERRORS.PASSWORDS_DOES_NOT_MATCH' | translate }}</span>
              </small>
            </div>
            <small *ngIf="operation('saving-data').errors?.password_confirm">
              <span class="text-danger">{{ ('SERVER_ERRORS.' + operation('saving-data').errors.password_confirm) | translate }}</span>
            </small>
            <small *ngIf="operation('saving-data').errors?.password">
              <span class="text-danger">{{ ('SERVER_ERRORS.' + operation('saving-data').errors.password) | translate }}</span>
            </small>
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="isAdmin">
      <h3>{{ 'CHANGE_USER' | translate }}: </h3>
      <div class="form-group">
        <input type="email" class="form-control" [(ngModel)]="sudoEmail">
        <span class="placeholder">Email</span>
      </div>
      <div class="text-right">
        <button class="btn-sicche" [disabled]="!sudoEmail" (click)="suChangeUser()">{{ 'CHANGE_USER' | translate }}</button>
      </div>
    </div>

    <div *ngIf="user.select_recovery_mode_available" class="row">
      <div class="col-12">
        <div class="">
          <input class="mr-2" type="checkbox" id="recovery_mode" [(ngModel)]="selectRecoveryMode">
          <label for="recovery_mode">Enable Recovery Mode</label>
        </div>
      </div>
    </div>

  </div>
</app-shared-sidebar-container>
