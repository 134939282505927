import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ResponseModel } from '@app/core/models/response.model';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { QuestionsService } from '@app/shared/services/questions.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { RoomsService } from '@app/shared/services/rooms.service';
import { QuestionModel } from '@app/shared/models/question.model';
import { BlockModel } from '@app/shared/models/block.model';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { AbstractQuestion } from '../abstract-question.component';

@Component({
  selector: 'app-setup-questions-preference-ranking',
  templateUrl: './preference-ranking.component.html',
  styleUrls: ['./preference-ranking.component.scss']
})
export class PreferenceRankingQuestionComponent extends AbstractQuestion implements OnInit {

  @Input() countryUniqId: string = null;
  @Input() block: BlockModel = null;
  @Input() question: QuestionModel = null;
  @Input() disabled = false;
  @Output() saveEmit: EventEmitter<string> = new EventEmitter(null);

  public form: UntypedFormGroup;
  public optionsError = false;
  public itemUniqId = 0;

  get items() {
    return this.form.get('items') as UntypedFormArray;
  }
  get question_data() {
    return this.form.get('question_data') as UntypedFormGroup;
  }

  constructor(private formBuilder: UntypedFormBuilder, private layoutService: CoreLayoutService, protected translate: TranslateService,
              private questionsService: QuestionsService, protected toastService: ToastrService, public roomsService: RoomsService) {
    super(toastService, translate);
  }

  ngOnInit(): void {
    this.itemUniqId = this.question
      ? this.question.question_data.items.reduce((max, el) => el.id > max ? el.id : max, this.itemUniqId)
      : this.itemUniqId;
    this.itemUniqId++;

    this.form = this.formBuilder.group({
      question_data: this.formBuilder.group({
        randomize_order: new UntypedFormControl(this.question ? this.question.question_data.randomize_order : '', null),
        best_worst: new UntypedFormControl(this.question ? this.question.question_data.best_worst : true, null),
        how_many: new UntypedFormControl(this.question ? this.question.question_data.how_many : '', Validators.required),
        mandatory_comment: new UntypedFormControl(this.question ? this.question.question_data?.mandatory_comment : '', null)
      }),
      is_masked: new UntypedFormControl(this.question ? this.question.is_masked : this.block.is_room_group ? true : false, null),
      title: new UntypedFormControl(this.question ? this.question.title : '', null),
      has_minimum_multiple_answers_nr: new UntypedFormControl((this.question && this.question.minimum_multiple_answers_nr) ? true : null),
      minimum_multiple_answers_nr: new UntypedFormControl(this.question ? this.question.minimum_multiple_answers_nr : null),
      items: this.formBuilder.array(this.question
        ? this.question.question_data.items.map((item: any) =>
          new UntypedFormGroup({
            id: new UntypedFormControl(item.id, null),
            image_del: new UntypedFormControl(false, null),
            image: new UntypedFormControl(null),
            image_url: new UntypedFormControl(item.image_url),
            comment: new UntypedFormControl(item.comment)
          }))
        : []
      ),
      type: new UntypedFormControl('preference-ranking', null),
      blockid: new UntypedFormControl(this.block.id, null),
      personas_tags: new UntypedFormControl(this.question ? this.question.personas_tags : '', null),
      sample_variable_tags: new UntypedFormControl(this.question ? this.question.sample_variable_tags : '', null)
    });
    if (!this.question) { // start with at least 2 items to rank
      [1, 2, 3].forEach(el => this.items.push(
        new UntypedFormGroup({
          id: new UntypedFormControl(this.itemUniqId++, null),
          image: new UntypedFormControl('', null),
          image_url: new UntypedFormControl('', null),
          image_del: new UntypedFormControl('', null),
          comment: new UntypedFormControl('', null),
        })
      ));
    }
    if (this.disabled) {
      this.form.disable();
    }
    this.layoutService.buttons([
      {label: 'SAVE_AND_EXIT', slug: 'saveexit', classes: 'mr-3 btn-sicche btn-secondary',
        icon: 'fal fa-share-square', click: this.submitAndExit },
      {label: 'SAVE_AND_CREATE_NEW', slug: 'save', classes: 'mr-5 btn-sicche btn-primary', icon: 'fal fa-check', click: this.submitAndStay }
    ]);
    this.question_data.controls.how_many.valueChanges.subscribe(change => this.updateMinItems());

    this.initAttachments();
  }

  updateMinItems() {
    if (this.question_data.get('how_many').value && this.items.length < this.question_data.get('how_many').value) {
      while (this.items.length < this.question_data.get('how_many').value) {
        this.addItem();
      }
    }
  }

  removeItem(index: number) {
    this.items.removeAt(index);
  }

  addItem() {
    this.items.push(
      new UntypedFormGroup({
        id: new UntypedFormControl(this.itemUniqId++, null),
        image: new UntypedFormControl('', null),
        image_url: new UntypedFormControl('', null),
        image_del: new UntypedFormControl('', null),
        comment: new UntypedFormControl('', null),
      })
    );
  }

  changeItemsOrder(e: CdkDragDrop<string[]>) {
    this.arrayMove(this.items.value, e.previousIndex, e.currentIndex, this.items);
  }

  submitAndExit = () => {
    this.layoutService.getButton('saveexit').loading = true;
    this.submit(() => {
      this.saveEmit.emit('save-and-leave');
    }, () => {
      this.layoutService.getButton('saveexit').loading = false;
    });
  }

  submitAndStay = () => {
    this.layoutService.getButton('save').loading = true;
    this.submit(() => {
      this.saveEmit.emit('save-and-stay');
    }, () => {
      this.layoutService.getButton('save').loading = false;
    });
  }

  submit(callback: any, fallback: any) {
    if (this.disabled) {
      fallback();
      this.toastService.error(this.translate.instant('ERRORS.CAN_NOT_MODIFY_PAST'));
      return;
    }

    const formValue = this.form.getRawValue();
    this.optionsError = false;
    // test for empty items
    formValue.items.forEach(item => {
      if (!item.comment.replace(/ /g, '')) {
        this.optionsError = true;
      }
    });
    if (this.form.get('question_data').get('how_many').invalid) {
      fallback();
      this.toastService.error(this.translate.instant('ERRORS.ITEMS_NUM_OUT_OF_RANGE'), '');
    } else if (this.optionsError) {
      fallback();
      this.toastService.error(this.translate.instant('ERRORS.ANSWER_OPTIONS_MISSING_COMMENT'), '');
    } else {
      this.operation('creating').reset().isSubmitting();
      const data = formValue;
      if (data.sample_variable_tags && data.sample_variable_tags.length > 0) {
        data.sample_variable_tags_by_comma = data.sample_variable_tags.join(',');
        data.sample_variable_tags = null;
      }
      if (data.personas_tags && data.personas_tags.length > 0) {
        data.personas_tags_by_comma = data.personas_tags.join(',');
        data.personas_tags = null;
      }
      data.question_data.items = formValue.items;

      data.question_data.attached_pdf_files = this.attachedPdfFiles;
      data.question_data.attached_images = this.attachedImages;

      (
        !this.question
          ? this.questionsService.createQuestion(data)
          : this.questionsService.updateQuestion(this.question.id, data)
      ).subscribe((res: ResponseModel) => {
        this.operation('creating').isSuccess(res.getErrors());
        this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
        callback();
      }, (e) => {
        this.operation('creating').isFailed(e.errors);
        this.toastService.error(this.translate.instant('ERRORS.GENERIC'));
        fallback();
      });
    }
  }

}
