import { Component, OnInit, Input } from '@angular/core';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { UntypedFormGroup } from '@angular/forms';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { QuestionModel } from '@app/shared/models/question.model';
import { QuestionsTypesService } from '@app/shared/services/questions-types.service';
import { BlockModel } from '@app/shared/models/block.model';

@Component({
  selector: 'app-private-setup-addquestion',
  templateUrl: './add-question.component.html',
  styleUrls: ['./add-question.component.scss']
})
export class AddQuestionComponent extends CoreBaseComponent implements OnInit {

  public questionType: string = null;
  public showTypes = true;
  public form: UntypedFormGroup = new UntypedFormGroup({});
  @Input()
  country_uniqid: number = null;
  @Input()
  block: BlockModel = null;
  @Input()
  roomid: number = null;
  @Input()
  question: QuestionModel = null;
  @Input() currentDate: Date = null;

  @Input() isEditableFromBlockData: boolean;
  public subtitle = null;

  constructor(private layoutService: CoreLayoutService, private questionTypeService: QuestionsTypesService) {
      super();
  }

  ngOnInit(): void {
    if (!this.question) {
      this.layoutService.title('NEW_QUESTION');
    } else {
      this.layoutService.title('MOD_QUESTION');
      this.startQuestion(this.question.type, false);
    }
    this.layoutService.closingMessage(this.block ? this.block.id : null);
  }

  isEditorDisabled(): boolean {
    if (this.isEditableFromBlockData) {
      return false;
    }
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return ((new Date(this.currentDate)).getTime() < now.getTime());
  }

  back() {
    this.showTypes = true;
    this.questionType = null;
    this.layoutService.buttons(null);
    this.subtitle = null;
  }

  startQuestion(typeSlug: string, reset = true) {
    this.showTypes = false;
    this.questionType = typeSlug;
    this.subtitle = this.questionTypeService.getBySlug(typeSlug).name;
    if (reset) {
      this.question = null;
    }
  }

  onSave(proposal: string) {
    if (proposal === 'save-and-leave') {
      this.layoutService.buttons(null);
      this.layoutService.closeSidebar();
    } else {
      this.showTypes = true;
      this.questionType = null;
      this.subtitle = null;
      this.layoutService.buttons(null);
    }
  }

}
