<nav id="core-toolbar"
    [ngClass]="{
        'fixed': toolbarService.fixed$.value === true, 
        'hidden': !show && toolbarService.fixed$.value, 
        'show': show && toolbarService.fixed$.value,
        'navbar-expand-sm': toolbarService.keepExtended$.value, 
        'navbar-expand-lg': !toolbarService.keepExtended$.value}"
    class="navbar navbar-dark">
    <button class="navbar-toggler ml-auto btn-sicche" type="button"
        (click)="toggleNavbar()">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse"
    [ngClass]="{ 'show': navbarOpen }">
        <ng-template #logoContainer></ng-template>
        <ul class="navbar-nav ml-auto navbar-toggleable-xl">
            <li 
                [hidden]="item.hidden != null ? item.hidden : false"
                [placement]="item.tooltipPlacement ? item.tooltipPlacement : 'bottom'" [ngbTooltip]="item.label | translate"
                class="nav-item position-relative" 
                [class.disabled]="item.status != null ? item.status == 'on' ? false : true : false" 
                *ngFor="let item of toolbarService.items$.value">
                <div class="overlay" *ngIf="item.status != null ? item.status == 'on' ? false : true : false"></div>
                <div>
                    <ng-container
                        *ngComponentOutlet="item.component">
                    </ng-container>
                </div>
            </li>
        </ul>
    </div>
</nav>