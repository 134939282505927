import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { AnswersChartsService } from '../../../../moderation/answers-modals/answers-charts.service';
import * as Highcharts from 'highcharts';
import { QuestionModel } from '@app/shared/models/question.model';
import { AnalysisService } from '@app/shared/services/analysis.service';
import { ResponseModel } from '@app/core/models/response.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { AnswerModel } from '@app/shared/models/answer.model';
import { CountryService } from '@app/shared/services/country.service';
import { CommentModel } from '@app/shared/models/comment.model';
import { ToastrService } from 'ngx-toastr';
import { TeamService } from '@app/shared/services/team.service';
import { QuotesMinimumWords } from '../../../../../../../../../../../setup';
import { AnswersModalSupportService } from '@app/shared/services/support/answers-modal.service';
import * as angular from 'angular';

@Component({
  selector: 'app-overview-all-posts',
  templateUrl: './all-posts.component.html',
  styleUrls: ['./all-posts.component.scss']
})
export class OverviewModalAllPostsComponent extends CoreBaseComponent implements OnInit {

  @Input()
  type: string;
  @Input()
  countryUniqId = null;
  public filterForm: UntypedFormGroup = null;
  public isSmart = false;
  public contentTags = null;

  public loading = false;
  public questions: QuestionModel[];

  public answers: AnswerModel[];
  public comments: CommentModel[];
  public downloading = false;

  infiniteScrollIndex: number = 1;
  public selectedCount = 0;

  public selectedText = null;
  public showSelectionModal = false;
  public quotedAnswer = null;
  public quotedCommentid = null;
  activeQuotes = false;

  currentGetId = null;

  constructor(private translate: TranslateService, private analysisService: AnalysisService,
              public cdr: ChangeDetectorRef, private countryService: CountryService, private toastService: ToastrService,
              private teamService: TeamService, private answersModalSupportService: AnswersModalSupportService) {
                super();
              }

  ngOnInit(): void {
    this.isSmart = this.countryService.currentCountryIsClientBasic();
    this.countryService.currentContentTag.subscribe(contentTags => {
      this.contentTags = contentTags;
    });
    // Filter form
    this.filterForm = new UntypedFormGroup({
      room: new UntypedFormControl('', null),
      block: new UntypedFormControl('', null),
      question: new UntypedFormControl('', null),
      sample_variable_tags: new UntypedFormControl('', null),
      personas: new UntypedFormControl('', null),
      only_highlighted: new UntypedFormControl('', null),
      block_datetime: new UntypedFormControl('', null),
      answer_tags: new UntypedFormControl('', null),
      rating: new UntypedFormControl('', null),
      keyword_search_content: new UntypedFormControl('', null),
      intervieweds: new UntypedFormControl('', null),
    });
    this.answersModalSupportService.activeQuotes$.subscribe(val => {
      this.activeQuotes = val;
    });
    // this.getData()
  }

  selectText(event) {
    if (!this.activeQuotes) {
      return false;
    }

    this.selectedText = null;
    if (window.getSelection) {
      this.selectedText = window.getSelection().toString();
    }
    if (event.answer) {
      this.quotedAnswer = event.answer
    }
    if (event.commentid) {
      this.quotedCommentid = event.commentid

      let answer = null
      let answerid = null
      for (const question of this.questions) {
        answerid = answerid || question.comments.find(c => c.id === this.quotedCommentid)?.answerid
      }
      for (const question of this.questions) {
        answer = answer || question.answers.find(a => a.id === answerid)
      }

      this.quotedAnswer = answer;
    }
    // Replace non-breaking-space chars TRICK
    this.selectedText += "";
    var temp = document.createElement('div'), str;
    temp.innerHTML = this.selectedText;
    //@ts-ignore
    str = temp.innerText || temp.textContent || temp.text;
    if(!str) {
      return false;
    }
    var normalizedString = str.split(/\s/);
    temp = null;
    // End replacing non-breaking-space chars TRICK
    if (this.selectedText) {
      const size = normalizedString.length;
      if(size >= QuotesMinimumWords) {
        this.showSelectionModal = true;
      }
    } else {
      this.showSelectionModal = false;
    }
  }


  quotesModalClose(newQuote) {
    this.showSelectionModal = false;
    // add the quote to the element
    if (newQuote) {
      const questions = angular.copy(this.questions)
      for (const question of questions) {
        if (newQuote.commentid) {
          for (const comment of question.comments) {
            if (comment.id === newQuote.commentid) {
              comment.quotes = [...comment.quotes, newQuote]
            }
          }
        }
        if (newQuote.answerid) {
          for (const answer of question.answers) {
            if (answer.id === newQuote.answerid) {
              answer.quotes = [...answer.quotes, newQuote]
            }
          }
        }
      }

      this.questions = questions;
    }

    // this.getData(this.questions,  true);
  }

  getData(tempQuestion = [], scrollToQuoted = false) {
    this.loading = true;
    this.questions = tempQuestion;

    let date = '';
    if (this.filterForm.value.block_datetime) {
      date = this.formatDate(this.filterForm.value.block_datetime.date);
    }
    const all_rooms = this.filterForm.value.room ? this.filterForm.value.room.length == 1 && this.filterForm.value.room[0].id == 0 ? true : false : false;
    const filters = {
      include_quote: true,
      all_rooms,
      only_highlighted: this.filterForm.value.only_highlighted,
      rating: this.filterForm.value.rating,
      questionid: this.filterForm.value.question ? this.filterForm.value.question.id : null,
      block_datetime: date,
      roomids_by_comma: Array.isArray(this.filterForm.value.room) ? this.filterForm.value.room.length ? this.filterForm.value.room.map(item => item.id) : null : null,
      blockids_by_comma: this.filterForm.value.block ? this.filterForm.value.block.map(item => item.id) : null,
      answer_tags_by_comma: this.filterForm.value.answer_tags ? this.filterForm.value.answer_tags : null,
      team_personas_tags_by_comma: this.filterForm.value.personas ? this.filterForm.value.personas : null,
      team_sample_variable_tags_by_comma: this.filterForm.value.sample_variable_tags ? this.filterForm.value.sample_variable_tags : null,
      keyword_search_content: this.filterForm.value.keyword_search_content ? this.filterForm.value.keyword_search_content : null,
      intervieweds: this.filterForm.value.intervieweds ? this.filterForm.value.intervieweds : null
    };
    Object.keys(filters).forEach(key => {
      if (!filters[key] || filters[key] == '') {
        delete filters[key];
      }
    });

    const getId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    this.currentGetId  = getId

    this.analysisService.getPostsBy(this.countryUniqId, filters).subscribe((res: ResponseModel) => {
      if (this.currentGetId !== getId) {
        return false;
      }
      // filtro le domande che hanno contenuti e instanzio i commenti come commentModel per avere state.selected
      this.questions = res.getData()
      .filter(q => q.comments.length || q.answers.length)
      .map((q => {
        if (q.comments.length) {
          q.comments = q.comments.map(c => new CommentModel(c))
        }
        return q;
      }));
      this.loading = false;
      if (scrollToQuoted) {
        setTimeout(() => {
          if (this.quotedCommentid) {
            this.scrollAndHighlightElement(`answer-block-comment-${this.quotedCommentid}`);
            this.quotedCommentid = null
          } else {
            this.scrollAndHighlightElement(`answer-${this.quotedAnswer?.id}-short`);
          }
        });
      }
    })
  }

  getAnswerComments(answer, questionData): CommentModel[] {
    const comments = questionData.comments.filter(c => c.answerid === answer.id);
    return comments;
  }
  getOrphansComments(questionData): CommentModel[] {
    const answerIds = questionData.answers.map(a => a.id);
    const comments = questionData.comments.filter(c => !answerIds.includes(c.answerid));
    return comments;
  }

  loadMore() {
    this.infiniteScrollIndex++;
  }

  hasSelected() {
    for (const question of this.questions) {
      for (const answer of question.answers) {
        if (answer.state.selected) {
          return true
        }
      }
      for (const comment of question.comments) {
        if (comment?.state?.selected) {
          return true
        }
      }
    }
    return false
  }

  selectAll(event) {
    const hasSelected = this.hasSelected();

    for (const question of this.questions) {
      for (const answer of question.answers) {
        answer.state.selected = !hasSelected;
      }
      for (const comment of question.comments) {
        comment.state.selected = !hasSelected;
      }
    }
  }

  downloadSelected() {
    const postToDownload = [];
    for (const question of this.questions) {
      for (const answer of question.answers) {
        if (answer.state.selected) {
          postToDownload.push({
            id: answer.id,
            isAnswer: true
          })
        }
      }

      for (const comment of question.comments) {
        if (comment.state.selected) {
          postToDownload.push({
            id: comment.id,
            isAnswer: false
          })
        }
      }
    }


    if (postToDownload.length  > 0) {
      this.downloading = true;

      this.analysisService.getPostExport(this.countryUniqId, postToDownload).subscribe((res: ResponseModel) => {
        this.downloading = false;
        this.toastService.success(this.translate.instant('PDF_EXPORT_ALL_EMAIL_SENT', {email: this.teamService.currentUser.email}), null, {timeOut: 10000});
      })
    }
  }

  getXlsExport() {
    this.downloading = true;

    const answerIds = [];
    const commentIds = [];

    for (const question of this.questions) {
      for (const answer of question.answers) {
        if (answer.state.selected) {
          answerIds.push(answer.id)
        }
      }

      for (const comment of question.comments) {
        if (comment.state.selected) {
          commentIds.push(comment.id)
        }
      }
    }


    this.analysisService.getPostXlsExport(this.countryUniqId, answerIds, commentIds, 'all_posts').subscribe((res: ResponseModel) => {
      this.downloading = false;
      this.toastService.success(this.translate.instant('PDF_EXPORT_ALL_EMAIL_SENT', {email: this.teamService.currentUser.email}), null, {timeOut: 10000});
    })
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }
    return [year, month, day].join('-');
  }

}
