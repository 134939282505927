<input type="file" class="d-none" accept="image/*" (change)="selectPicture($event)" #file>
<ng-container *ngIf="form" [formGroup]="form">
    <div *ngIf="!imageUrl" class="placeholder-box va" (click)="file.click()">
        <i class="fal fa-image d-flex align-items-center" style="font-size: 48px;"></i>
    </div>
    <div *ngIf="imageUrl" class="image-box position-relative">
        <a class="delete-img" (click)="deleteImg()">x</a>
        <img *ngIf="!new" [src]="imageUrl | privatesrc">
        <img *ngIf="new" [src]="imageUrl">
    </div>
    <div class="text-input">
        <label>
            <input formControlName="comment" type="text" class="form-control">
            <span class="placeholder">{{ 'OPTION' | translate }}</span>
        </label>
    </div>
</ng-container>