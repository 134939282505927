import { ResearchModel } from './../models/research.model';
import { Injectable } from '@angular/core';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { BaseService } from '@app/core/services/base.service';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { ServiceMessageModel } from '@app/core/models/service-message.model';
import { SearchParams } from '@app/core/models/search-params';
import { DatetimeToDbPipe } from '@app/core/pipes/datetime-to-db.pipe';
import { CommentModel } from '../models/comment.model';
import { AnswerModel } from '../models/answer.model';
import { SrvRecord } from 'dns';
import { ResponseModel } from '@app/core/models/response.model';



@Injectable({
  providedIn: 'root'
})
export class AnswersService extends BaseService implements BaseServiceI {

  // tslint:disable: variable-name
  message$: BehaviorSubject<ServiceMessageModel> = new BehaviorSubject(null);
  toSetRead = [];

  constructor(public router: Router, public apiService: ApiService, private http: HttpClient, private datetimeToDbPipe: DatetimeToDbPipe) {
    super(router, apiService, AnswerModel);
  }

  getAnswers(query: any) {
    return this.sub('answers').get('', query);
  }

  getAnswerByComment(comment: CommentModel) {
    return new Promise((resolve, reject) => {
      this.sub('answers').get('', {id: comment.answerid, questionid: comment.questionid}).subscribe((res: ResponseModel) => {
        resolve(res.getData()[0])
      })
    })
  }

  insertComment(data: {answerid: number, comment: Text, flag_like: boolean, commentid: number, private_userid?: number}) {
    return this.sub('comments').create(data);
  }

  updateComment(commentId: number, data: {answerid: number, comment?: Text, flag_like?: boolean, commentid: number, stars?: number, comment_tags?: Array<string>}) {
    return this.sub(`comments/${commentId}`).create(data);
  }

  deleteComment(commentId: number) {
    return this.sub(`comments/${commentId}`).delete();
  }

  getComments(answerid: number, include_quote = false) {
    return this.sub(`comments`).as(CommentModel).get('', {answerid, flag_like: false, include_quote});
  }

  likeComment(commentId: number, answerid: number) {
    return this.sub(`comments`).create({answerid, flag_like: true, parentid: commentId});
  }

  unlikeComment(commentid: number, answerid: number) {
    return this.sub(`remove_like/${answerid}`).create({commentid});
  }

  setAsRead(answerId: number) {
    if(this.toSetRead) {
      return new Observable(subscriber => {
        if(!this.toSetRead.includes(answerId)) {
          this.toSetRead.push(answerId);
        }
        subscriber.next(this.toSetRead);
      })
    }
    return this.updateAnswer(answerId, {read: 1});
  }

  // answer_data:json,answer_tags:array,read:boolean,favourite:boolean,stars:int,device:text,quotes:array
  updateAnswer(answerid: number, data: any = {}) {
    return this.sub(`answers/${answerid}`).create(data);
  }

  addQuote(data: any = {}) {
    return this.sub(`quotes`).create(data);
  }

  updateQuote(id, data: any = {}) {
    return this.sub(`quotes/${id}`).create(data);
  }

  deleteAnswer(answerid: number) {
    return this.deleteById('/answer-management/answers/', answerid.toString());
  }

  setAnswersRead(arrayIds?) {
    let ids = null;
    if(arrayIds) {
      ids = arrayIds;
    } else {
      ids = this.toSetRead;
    }
    if(ids.length) {
      return this.customCall('answer-management/set-answers-read').create({answer_ids: this.toSetRead});
    } else {
      return new Observable(subscriber => {
        subscriber.next(ids.length);
        this.toSetRead = [];
      });
    }
  }

  like(answerid) {
    return this.sub('comments').create({answerid, flag_like: true});
  }
  unlike(answerid: number, commentid = null) {
    return this.sub(`remove_like/${answerid}`).create({commentid});
  }

}
