

<app-core-layout
    [sidebar]="true"
>
    <div slot="with-sidebar">
        <app-core-toolbar></app-core-toolbar>
        <div class="d-flex w-100 position-relative">
            
            <aside class="sidebar" [class.hide]="!sidebarService.isVisible()">
                <app-core-sidebar></app-core-sidebar>
            </aside>
            <section class="main">
                <div class="internal-quotes-top quotes-down first text-right">
                    <span class="custom-icon text-primary-color icon-quotes-right-hollow" style="font-size: 16.286rem;"></span>
                </div>
                <div class="content">
                    <!--<i class="fas fa-bars as-link text-dark" (click)="sidebarService.toggle()" style="font-size: 28px;"></i>-->
                    <div class="content-body-wrapper">
                        <router-outlet *ngIf="initialized"></router-outlet>
                    </div>
                </div>
            </section>
            
        </div>
        <app-shared-footer></app-shared-footer>
    </div>
</app-core-layout>
