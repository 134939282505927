import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResearchModel } from '@app/shared/models/research.model';
import { ResearchSessionService } from '@app/shared/services/research-session.service';
import { RoomsService } from '@app/shared/services/rooms.service';
import { RoomModel } from '@app/shared/models/room.model';
import { ResponseModel } from '@app/core/models/response.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { InstructionsComponent } from './instructions/instructions.component';
import { DatetimeToDbPipe } from '@app/core/pipes/datetime-to-db.pipe';
import { DateUtil } from '@app/core/helpers/date-util';
import { ActivateUserInRoomComponent } from './activate-user-in-room/activate-user-in-room.component';
import { ModalService } from '@app/core/services/modal.service';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { ManageQuestionsComponent } from './manage-questions/manage-questions.component';
import { CountryService } from '@app/shared/services/country.service';
import { DatetimeFromDB } from '@app/core/pipes/datetime-from-db.pipe';


@Component({
  selector: 'app-private-setup-manage-rooms',
  templateUrl: './manage-rooms.component.html',
  styleUrls: ['./manage-rooms.component.scss']
})
export class ManageRoomsComponent extends CoreBaseComponent implements OnInit {
  // tslint:disable: variable-name
  constructor(
    private activatedRoute: ActivatedRoute,
    private researchSessionService: ResearchSessionService,
    private roomsService: RoomsService,
    private toastService: ToastrService,
    private translateService: TranslateService,
    private coreLayoutService: CoreLayoutService,
    private datetimeToDbPipe: DatetimeToDbPipe,
    private modalService: ModalService,
    private countryService: CountryService,
    private datetimeFromDB: DatetimeFromDB
  ) {
    super();
  }

  @Output() roomCreate = new EventEmitter();

  @ViewChild(ManageQuestionsComponent) manageQuestionsComponent: ManageQuestionsComponent;

  researchUniqid: string;
  countryUniqid: string;
  research: ResearchModel;
  rooms: RoomModel[];
  datepick = false;
  editTitle: number;

  openedRoom: RoomModel;
  openedRoomId: number;

  ngOnInit(): void {
    this.researchUniqid = this.activatedRoute.snapshot.parent.paramMap.get('research_uniqid');
    this.countryUniqid = this.activatedRoute.snapshot.parent.paramMap.get('country_uniqid');
    this.research = this.researchSessionService.getResearch();


    this.roomsService.getRoomsByCountryUniqid(this.countryUniqid).subscribe((res: ResponseModel) => {
      const temp = [];
      const resObj = res.getData();
      for (const key in resObj) {
        if (resObj.hasOwnProperty(key)) {
          const element = resObj[key];
          element.date_start = this.datetimeFromDB.transform(element.date_start, this.countryService.getSessionCountry().timezone, true);
          element.date_end = this.datetimeFromDB.transform(element.date_end, this.countryService.getSessionCountry().timezone, true);
          temp.push(element);
        }
      }
      this.rooms = temp;
    });
  }

  getRoomById(id): RoomModel {
    return this.rooms.filter(r => r.id === id)[0];
  }

  isRoomDeletable(roomid) {
    if (this.research.editable_in_the_past) {
      return true;
    }
    if (!this.rooms.find(r => r.id === roomid).date_start) {
      return true;
    }
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const selectedDate = new Date(this.rooms.find(r => r.id === roomid).date_start);
    if (selectedDate.getTime() >= now.getTime()) {
      return true;
    }
    return false;
  }

  openInterviewedsSidabar(room: RoomModel) {
    const oneRoomOnly = (this.rooms.length === 1);
    this.coreLayoutService.openSidebar(ActivateUserInRoomComponent, {room, oneRoomOnly});
  }

  openInstructionsSidebar(room: RoomModel) {
    this.coreLayoutService.openSidebar(InstructionsComponent, room);
  }

  editTitleToggle(id) {
    this.editTitle = (this.editTitle === id ? '' : id);
  }

  changeDate(e) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const researchDateStart = new Date(this.parseCompatibilityDate(this.research.date_start));
    const fromDate = (today.getTime() > researchDateStart.getTime() ? today : researchDateStart);

    // Set timezone
    const timezone = this.research.countries[0].timezone;
    e.from = new Date(this.parseCompatibilityDate(e.from));
    e.to = new Date(this.parseCompatibilityDate(e.to));
    e.from.toLocaleString('en-US', { timeZone: timezone });
    e.to.toLocaleString('en-US', { timeZone: timezone });
    if (DateUtil.checkDateReal(e.from, e.to, fromDate, new Date(this.parseCompatibilityDate(this.research.date_end))) || this.research.editable_in_the_past) {
      for (const room of this.rooms) {
        if (room.id === e.roomid) {
          room.date_start = this.datetimeToDbPipe.transform(e.from, this.countryService.getSessionCountry().timezone);
          room.date_end = this.datetimeToDbPipe.transform(e.to, this.countryService.getSessionCountry().timezone);
          this.updateRoom(e.roomid);
        }
      }
      this.datepick = !this.datepick;
    } else {
      this.toastService.error(this.translateService.instant('INVALID_DATE'));
    }
    if (this.manageQuestionsComponent) {
      this.manageQuestionsComponent.updateDays();
    }
  }

  updateRoom(id, updatedIsRoomGroup = false) {
    for (const room of this.rooms) {
      if (room.id === id) {
        if (updatedIsRoomGroup) {
          room.update_is_room_group = true;
        }
        // room.date_start = this.datetimeFromDB.transform(room.date_start, this.countryService.getSessionCountry().timezone, true);
        // room.date_end = this.datetimeFromDB.transform(room.date_end, this.countryService.getSessionCountry().timezone, true);
        room.date_start = this.datetimeToDbPipe.transform(room.date_start, this.countryService.getSessionCountry().timezone);
        room.date_end = this.datetimeToDbPipe.transform(room.date_end, this.countryService.getSessionCountry().timezone);
        this.roomsService.updateRoom(room).subscribe((res: ResponseModel) => {
          this.toastService.info(this.translateService.instant('DATA_UPDATED'));
          this.editTitle = -1;
          this.manageQuestionsComponent.loadBlocks();
        }, err => {
          console.error(err);
        });
      }
    }
  }

  openRoom(id, scrollTo: HTMLElement) {
    this.openedRoomId = (this.openedRoomId === id ? -1 : id);
    this.openedRoom = this.getRoomById(id);
    if (this.openedRoomId !== -1) {
      this.scrollAndHighlightElement(scrollTo)
    }
  }

  duplicateRoom(id) {
    for (const room of this.rooms) {
      if (room.id === id) {
        this.roomsService.duplicateRoom(room.id).subscribe((res: ResponseModel) => {
          const newRoom = res.getData()[0];
          this.rooms = [...this.rooms, newRoom];
        });
      }
    }
  }

  removeRoom(id) {
    this.modalService.open({title: 'ARE_YOU_SURE.?',
        btnOkText: 'YES',
        btnCancelText: 'CANCEL',
        showBtnClose: false,
        showCancelBtn: true,
        customClass: 'confirm-modal',
        message: 'ARE_YOU_SURE_DELETE.?'
      }).then((data) => {
        if (data) {
          this.roomsService.deleteRoom(id).subscribe((res: ResponseModel) => {
            const temp = this.rooms.filter(r => r.id !== id);
            this.rooms = temp;
            this.toastService.info(this.translateService.instant('ROOM_REMOVED'));
          });
        }
    });
  }

  createRoom() {
    this.operation('creating-room').reset().isSubmitting();
    const room = new RoomModel();
    room.country_uniqid = this.countryUniqid;
    this.roomsService.createRoom(room).subscribe((res: ResponseModel) => {
      this.rooms = [...this.rooms, res.getData()[0]];
      this.roomCreate.emit('');
      this.operation('creating-room').isSuccess();
    });
  }

}
