<section id="interviewed-table">
    <div class="d-flex justify-content-between align-items-center">
        <div>
            <h3 class="float-left">{{ 'RESPONDENTS_DATABASE' | translate }}</h3>&nbsp;
            <span class="info-tooltip" placement="top" [ngbTooltip]="'INTERVIEWEDS.TOOLTIP' | translate">
                <i class="far fa-info-circle"></i>
            </span>
        </div>
        <button class="btn-sicche btn-secondary" (click)="download()">
            <i class="fal fa-download" style="font-size: 16px;"></i>
        {{ 'DOWNLOAD_EXCEL' | translate }}
        </button>
    </div>
    <div class="panel mt-2 text-center">
        <app-core-spinner [toggle]="loading"></app-core-spinner>
        <div class="position-relative">
            <div class="table-responsive">
                <app-core-table
                    *ngIf="intervieweds"
                    [columns]="[{name: 'NAME', icon_name: 'fal fa-sort', class:'text-left'}, {name: 'SURNAME', icon_name: 'fal fa-sort', class:'text-left'}, {name: 'MAIL', icon_name: 'fal fa-sort', class:'text-left'}, {name: 'TELEPHONE', class:'text-left'}, {icon_name: 'fa-thumbs-down fal', class:'text-center'}, {icon_name: 'fa-medal fal', class:'text-center'}, {name: 'NR_RESEARCHES'}, {name: 'LAST_PARTICIPATION', class:'text-left'}]"
                    [(items)]="intervieweds"
                    tableid="'intervieweds'"
                    [perPage]="5"
                    [pagination]="true"
                    [rowTemplate]="rowTemplate"
                    (columnClick)="columnClicked($event)"
                    ></app-core-table>
                <ng-template let-item="item" #rowTemplate>
                    <tr>
                        <td class="text-left font-weight-bold">
                            {{ item.firstname.toString() }}
                        </td>
                        <td class="text-left font-weight-bold">
                            {{ item.lastname.toString() }}
                        </td>
                        <td class="text-left">
                            {{ item.email }}
                        </td>
                        <td class="text-left">
                            {{ item.telephone.toString() }}
                        </td>
                        <td class="text-center">
                            <div>{{ item.monitoring_dislike ? item.monitoring_dislike : 0 }}</div>
                        </td>
                        <td class="text-center">
                            <div>{{ item.monitoring_medal ? item.monitoring_medal : 0 }}</div>
                        </td>
                        <td class="text-center">
                            <a (click)="openMissingAnswersModal(item.researches)" href="#">{{ item.nr_researches }}</a>
                        </td>
                        <td class="text-left">
                            {{ item.lasttime | dateToLocaleString }}
                        </td>
                    </tr>
                </ng-template>
            </div>
        </div>
    </div>
</section>
