import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { QuestionsTypesService } from '@app/shared/services/questions-types.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CoreBaseComponent } from '@app/core/components/base.component';

@Component({
  selector: 'app-setup-questions-answer-option',
  templateUrl: './answer-option.component.html',
  styleUrls: ['./answer-option.component.scss']
})
export class QuestionsAnswerOptionComponent extends CoreBaseComponent implements OnInit {

  @Input()
  answer: UntypedFormControl;
  @Input()
  form: UntypedFormGroup;
  public imageUrl: any;
  public new = false;

  constructor(public questionTypesService: QuestionsTypesService) {
    super();
  }

  ngOnInit(): void {
    this.imageUrl = this.answer.value.image_url;
  }

  deleteImg() {
    this.imageUrl = null;
    this.answer.patchValue({
      image: null,
      image_url: null,
      image_del: true
    });
  }

  selectPicture(event) {
    const selectedFile = event.target.files[0];
    const reader = new FileReader();
    this.operation('receiving-image').reset().isSubmitting();
    const mimeType = selectedFile.type;
    if (mimeType.match(/image\/*/) == null) {
      this.operation('receiving-image').isFailed();
      return;
    }
    reader.readAsDataURL(selectedFile);
    reader.onload = (/*event*/) => {
      this.new = true;
      this.imageUrl = reader.result;
      this.answer.patchValue({
        image: reader.result,
        image_del: false
      });
      this.operation('receiving-image').isSuccess();
    };
  }

}
