import { Role } from './role.model';
import { BaseModel } from '@app/core/models/base';
import { CountryUser } from './country-user.model';

/**
 * User model class
 */

export class User extends BaseModel {

    // tslint:disable: variable-name
    id: string;
    avatar: any;
    avatar_url: string;
    // per rimuovere mandare avatar_del= true
    avatar_del: boolean;
    username: string;
    password: string;
    email: string;
    firstname: string;
    lastname: string;
    company: string;
    company_declared: string;
    language_code: string;
    telephone: string;
    default_privacy_html: string;
    consentA: boolean;
    consentB: boolean;
    // country/room related
    country_invite_status: string;
    country_role: string;
    country_uniqid: string;
    roomid: number;
    sample_variable_tags: string[];
    otherdata: any;
    select_recovery_mode_available: boolean;

    static getResourceName() {
        return 'users';
    }

    getFullName() {
        return `${this.firstname} ${this.lastname}`;
    }

    isCustomerBasic() {
        if (this.otherdata) {
            if (this.otherdata.first_role) {
                return this.otherdata.first_role === 'CUSTOMER_BASIC' || this.otherdata.first_role === 'CUSTOMER_PRO';
            }
        }
        return false;
    }

}
