import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { catchError, map } from 'rxjs/operators';
import { User } from '../models/user.model';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { ResponseModel } from '@app/core/models/response.model';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService implements BaseServiceI {
  // tslint:disable: variable-name
  constructor(public router: Router, public apiService: ApiService, private http: HttpClient) {
    super(router, apiService, User);
  }

  getUsers(country_uniqid = null, roomid = null, sort_attribute = null, sort_order = null) {
    const data = {country_uniqid, roomid, sort_attribute, sort_order};
    return this.get(null, data);
  }

  getCurrentUser() {
    return this.apiService.get('/current-user');
  }
  updateCurrentUser(data) {
    return this.apiService.post('/current-user', data);
  }
  updateDefaultPrivacyHtml(data) {
    return this.apiService.post('/current-user/default_privacy', data);
  }
}
