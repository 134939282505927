<app-shared-sidebar-container>
    <app-shared-sidebar-header>
        <div slot="title">
            <h2 class="mb-0">{{ 'MONITORING_SECTION.CUSTOMER_SIDEBAR' | translate }}</h2>
        </div>
    </app-shared-sidebar-header>

    <div class="sidebar-body">
        <div class="row">
            <div class="col-12 bg-white shadow card">
                <div class="position-relative">
                    <div class="table-responsive">
                        <app-core-table
                        *ngIf="customers"
                        [items]="customers"
                        [columns]="[
                            {name: 'EMAIL', class:'text-left'},
                            {name: 'MONITORING_SECTION.HAS_LOGGED_IN_CUSTOMER'},
                            {name: 'MONITORING_SECTION.NR_LOGINS'},
                            {name: 'MONITORING_SECTION.IS_ONLINE'}
                        ]"
                        tableid="'messages'"
                        [perPage]="9999999"
                        [rowTemplate]="rowTemplate"
                        >
                        </app-core-table>
                        <ng-template let-item="item" #rowTemplate>
                            <tr class="text-center">
                                <td class="text-left">
                                    {{ item.reference_email || item.email }}
                                </td>
                                <td>
                                    <i class="fal fa-check text-check font-weight-bold" *ngIf="item.access_nr > 0"></i>
                                    <i class="fal fa-times text-alert font-weight-bold" *ngIf="item.access_nr == 0"></i>
                                </td>
                                <td>
                                    {{item.access_nr || 0}}
                                </td>
                                <td>
                                    <i class="fal fa-check text-check font-weight-bold" *ngIf="item.user_online"></i>
                                    <i class="fal fa-times text-alert font-weight-bold" *ngIf="!item.user_online"></i>
                                </td>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-shared-sidebar-container>
