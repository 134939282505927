<section>
    <div class="row">
        <div class="col-md-12">
            <h3 *ngIf="!teamService.isCurrentUserCustomer()">
                {{'ANALYSIS_RESEARCHER.HEADER' | translate}}
            </h3>
            <h3 *ngIf="teamService.isCurrentUserCustomerBasic()">
                {{'ANALYSIS_BASIC.HEADER' | translate}}
            </h3>

            <p *ngIf="!teamService.isCurrentUserCustomer()">
                {{'ANALYSIS_RESEARCHER.HEADER_SUBTITLE' | translate}}
            </p>
        </div>
        <!--
        <div class="col-md-6">
            <button class="btn btn-sicche">
                <i class="far fa-filter"></i>&nbsp;Filtri di ricerca avanzata
            </button>
            <button class="btn btn-sicche">
                <i class="far fa-glasses"></i>&nbsp;Crea report per visualizzazione cliente basic
            </button>
        </div>
        -->
    </div>
</section>
<section class="mt-4 filter-overview d-none">
    <div class="d-flex justify-content-between align-items-center">
        <div>
            <h3>{{ 'ACTIVE_FILTERS' | translate }}</h3>
        </div>
        <button class="btn-sicche btn-secondary float-right">
            <i class="fal fa-download" style="font-size: 16px;"></i>
            {{ 'EXPORT' | translate }}
        </button>
    </div>
    <div class="clear"></div>
    <div class="panel shadow filter px-4 py-3 d-flex align-items-center justify-content-between">
        <div>
            <div><b>{{ 'STRUCTURE' | translate }}:</b>&nbsp;{{ 'EXAMPLE_STRUCTURE' | translate }}</div>
            <div><b>{{ 'CONTENTS' | translate }}:</b>&nbsp;{{ 'EXAMPLE_CONTENT' | translate }}</div>
            <div><b>{{ 'INTERVIEWEDS' | translate }}:</b>&nbsp;{{ 'EXAMPLE_INTERVIEWEDS' | translate }}</div>
        </div>
        <div>
            <i class="far fa-times" style="font-size: 30px;"></i>
        </div>
    </div>
</section>
<section class="mt-5 overview" *ngIf="operation('loading-stats').success && !isCustomerPro">
    <!-- contenuti divisi per categoria -->
    <div class="row">
        <div class="col" *ngIf="!isSmart || isSmart && smartConfig.show_all_posts">
            <div class="panel shadow overview-summary h-100 p-3 mb-0 non-selected" (click)="openOverviewModal('all_posts')">
                <i class="fal fa-book-reader"></i>
                <h4 class="text text-primary-color mt-2 mb-0"><span class="function-title">{{ 'READ_ALL_POSTS' | translate }} </span>({{ overview.overview.written_posts }})</h4>
            </div>
        </div>
        <div class="col" *ngIf="!isSmart || isSmart && smartConfig.show_all_posts">
            <!-- <div class="panel shadow overview-summary h-100 p-3 mb-0 non-selected" (click)="openOverviewModal('graphs')"> -->
            <div class="panel shadow overview-summary h-100 p-3 mb-0 non-selected">
                <i class="fal fa-chart-pie-alt"></i>
                <h4 class="text text-primary-color mt-2 mb-0"><span class="function-title">{{ 'WATCH_ALL_GRAPHS' | translate }} </span>({{ 'COMING_SOON' | translate }})</h4>
            </div>
        </div>
        <div class="col" *ngIf="!isSmart || isSmart && smartConfig.show_image">
            <div class="panel shadow overview-summary h-100 p-3 mb-0 non-selected" (click)="openOverviewModal('images')">
                <i class="fal fa-image"></i>
                <h4 class="text text-primary-color mt-2 mb-0"><span class="function-title">{{ 'WATCH_ALL_IMAGES' | translate }} </span>({{ overview.count.images }})</h4>
            </div>
        </div>
        <div class="col" *ngIf="!isSmart || isSmart && smartConfig.show_video">
            <div class="panel shadow overview-summary h-100 p-3 mb-0 non-selected" (click)="openOverviewModal('video')">
                <i class="fal fa-video"></i>
                <h4 class="text text-primary-color mt-2 mb-0"><span class="function-title">{{ 'WATCH_ALL_VIDEOS' | translate }} </span>({{ overview.count.videos }})</h4>
            </div>
        </div>
        <div class="col" *ngIf="!isSmart || isSmart && smartConfig.show_audio">
            <div class="panel shadow overview-summary h-100 p-3 mb-0 non-selected" (click)="openOverviewModal('audio')">
                <i class="fal fa-volume-up"></i>
                <h4 class="text text-primary-color mt-2 mb-0"><span class="function-title">{{ 'LISTEN_ALL_AUDIOS' | translate }} </span>({{ overview.count.audios }})</h4>
            </div>
        </div>
    </div>
    <!-- contenuti selezionati -->
    <div class="row mt-4">
        <div class="col">
            <div class="d-flex align-items-start justify-content-start flex-column h-100 pr-5 text-left">
                <i _ngcontent-jho-c192="" class="far fa-arrow-right m-2 rotation-90-aclockwise arrow-analysis"></i>
                <span class="arrow-text-analysis">{{ 'RESEARCH_ALL_CONTENT_SECTION_ANALYSIS' | translate }}</span>
            </div>
        </div>
        <div class="col">
            <div class="d-flex align-items-end justify-content-start flex-column h-100 text-right text-custom-sicche pl-5 text-right">
                <i _ngcontent-jho-c192="" class="far fa-arrow-right text-primary-color m-2 arrow-analysis"></i>
                <span class="arrow-text-analysis">{{ 'RESEARCH_ALL_SELECTED_CONTENT_SECTION_ANALYSIS' | translate }}</span>
            </div>
        </div>
        <div class="col" *ngIf="!isSmart || isSmart && smartConfig.show_star">
            <div class="panel shadow overview-summary h-100 p-3 mb-0" (click)="openOverviewModal('stars')">
                <i class="fal fa-stars"></i>
                <h4 class="text text-primary-color mt-2 mb-0"><span class="function-title">{{ 'WATCH_SELECTED_CONTENTS' | translate }} </span>({{ overview.count.stars }})</h4>
            </div>
        </div>
        <div class="col" *ngIf="!isSmart || isSmart && smartConfig.show_graph">
            <div class="panel shadow overview-summary h-100 p-3 mb-0" (click)="openOverviewModal('quotes')">
                <i class="fal fa-quote-left"></i>
                <h4 class="text text-primary-color mt-2 mb-0"><span class="function-title">{{ 'READ_ALL_QUOTES' | translate }} </span>({{ overview.count.quotes }})</h4>
            </div>
        </div>
        <div class="col" *ngIf="!isSmart || isSmart && smartConfig.show_tagcloud">
            <div class="panel shadow overview-summary h-100 p-3 mb-0" (click)="openOverviewModal('cloud')">
                <i class="fal fa-comments"></i>
                <h4 class="text text-primary-color mt-2 mb-0"><span class="function-title">{{ 'WATCH_THE_CLOUD_TAG' | translate }} </span>({{ overview.count.tag_cloud }})</h4>
            </div>
        </div>
    </div>
</section>
<app-core-spinner [toggle]="operation('loading-stats').submitting"></app-core-spinner>
<section class="mt-4 overview" *ngIf="operation('loading-stats').success">
    <div class="row">
        <div class="col-12 my-3">
            <p class="text-center" *ngIf="teamService.isCurrentUserCustomer()">{{'ANALYSIS_CUSTOMER.RESEARCH_STATS' | translate}}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <div class="panel shadow totals h-100 m-0">
                <div class="text-center">
                    <h4>{{ 'PARTICIPATION_OVERVIEW' | translate }}</h4>
                </div>
                <div class="container-fluid">
                    <div class="row details">
                        <div class="col-md-6">
                            <p>{{ 'ACTIVE_PARTICIPANTS' | translate }}</p>
                            <div class="count">
                                {{ overview.overview.users_active }}/{{ overview.overview.users_total }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <p>{{ 'MIN_OF_CONNECTION' | translate }}</p>
                            <div class="count">
                                {{ overview.overview.connection_minutes }}
                            </div>
                        </div>
                    </div>
                    <div class="row details">
                        <div class="col-md-6">
                            <p>{{ 'WRITTEN_POSTS' | translate }}</p>
                            <div class="count">
                                {{ overview.overview.written_posts }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <p>{{ 'WRITTEN_WORDS' | translate }}</p>
                            <div class="count">
                                {{ overview.overview.word_count }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel shadow totals h-100 m-0">
                <div class="text-center">
                    <h4>{{ 'USED_DEVICE' | translate }}</h4>
                </div>
                <div class="container-fluid">
                    <div class="row details text-center">
                        <div class="col-md-6">
                            <div class="d-flex align-items-end justify-content-center">
                                <i class="far fa-desktop-alt mb-1" style="font-size: 68px;"></i>
                                <i class="far fa-tablet fa-rotate-270 mb-1 ml-n3 ml-xl-2" style="font-size: 45px;"></i>
                            </div>
                            <p>{{ 'DESKTOP' | translate }}</p>
                            <div class="count">
                                {{ overview.device.desktop }}%
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="position-relative mb-1">
                                <!--<i class="fas fa-mobile-android-alt sphone" style="font-size: 38px;"></i>-->
                                <i class="far fa-mobile-android" style="font-size: 68px;"></i>
                            </div>
                            <p>{{ 'MOBILE' | translate }}</p>
                            <div class="count">
                                {{ overview.device.mobile }}%
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel shadow totals h-100 m-0">
                <div class="text-center">
                    <h4>{{ 'MULTIMEDIA_CONTENT' | translate }}</h4>
                </div>
                <div class="container-fluid">
                    <div class="row details">
                        <div class="col-md-6 d-flex flex-column justify-content-between">
                            <p>{{ 'UPLOADED_VIDEOS' | translate }}</p>
                            <div class="count">
                                {{ overview.audiovisual.video_uploaded }}
                            </div>
                        </div>
                        <div class="col-md-6 d-flex flex-column justify-content-between">
                            <p>{{ 'MIN_OF_VIDEOS' | translate }}</p>
                            <div class="count">
                                {{ overview.audiovisual.video_minutes }}
                            </div>
                        </div>
                    </div>
                    <div class="row details">
                        <div class="col-md-6 d-flex flex-column justify-content-between">
                            <p>{{ 'VOCAL_MESSAGES' | translate }}</p>
                            <div class="count">
                                {{ overview.audiovisual.vocal_messages }}
                            </div>
                        </div>
                        <div class="col-md-6 d-flex flex-column justify-content-between">
                            <p>{{ 'MIN_OF_AUDIO' | translate }}</p>
                            <div class="count">
                                {{ overview.audiovisual.audio_minutes }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="mt-4" *ngIf="!teamService.isCurrentUserCustomer() && !teamService.isCurrentUserCustomerBasic()">
    <div class="row">
        <div class="col-12 text-right">
            <span class="info-tooltip" placement="top" [ngbTooltip]="'PDF_EXPORT_ALL_TOOLTIP' | translate">
                <i class="far fa-info-circle"></i>
            </span>

            <ng-template #exportPopOver>
                <!--  -->
                <div class="p-3">
                    <div class="row">
                        <div class="col-12">
                            <b>{{'CHOOSE_EXPORT_FORMAT' | translate }}</b>
                        </div>
                        <div class="col-12 mt-2 text-center">
                            <button class="btn-sicche btn-secondary" (click)="getXlsExport()"><i class="fal fa-file-excel"></i> XLS</button>
                        </div>
                        <div class="col-12 text-center">
                            <button class="btn-sicche btn-secondary" (click)="getPdfExport()"><i class="fal fa-file-pdf"></i> PDF</button>
                        </div>
                    </div>
                </div>
            </ng-template>

            <button  [ngbPopover]="exportPopOver" class="btn-sicche btn-secondary" >
                <span *ngIf="!loadingExport">{{'PDF_EXPORT_ALL' | translate}}</span>
                <span *ngIf="loadingExport">{{'WAIT_PLEASE' | translate}}</span>
            </button>
        </div>
    </div>
</section>
<div class="text-right mt-4" *ngIf="operation('loading-stats').success && !isSmart">
    <!-- <button (click)="openSmartSetupModal()" class="btn-sicche btn-primary">
        <i class="fal fa-glasses" style="font-size: 14px;"></i>&nbsp;{{ 'REPORT_SMART_SETUP' | translate }}
    </button> -->
</div>