<div class="days bg-white mt-2">
    <div class="shadow card header d-flex flex-row align-items-center justify-content-center cursor-pointer" cdkDragHandle>
        <div class="px-4 py-2 d-flex flex-grow-1 align-items-center" (click)="toggleCollapse()">
            <h4 class="title mb-0 d-flex align-items-center" *ngIf="!editTitle"><i class="clock-icon fa-xs far fa-clock pr-1"></i><span class="font-weight-bold pr-3">{{timeString}}</span> {{block.title || 'Untitled'}}</h4>
            <div class="form-group nolabel edit mb-0" *ngIf="editTitle">
                <input type="text" class="form-control" [(ngModel)]="block.title" (change)="submitForm()">
            </div>
        </div>
        <div class="d-flex flex-grow-0 p-4 text-right">
            <span *ngIf=" isBlockEditable()">
                <button class="icon" *ngIf="editTitle" (click)="editTitle = !editTitle; block.title = oldBlockTitle"><i class="fal fa-times"></i></button>
                <button class="icon" *ngIf="!editTitle" (click)="editTitle = !editTitle"><i class="fal fa-pen"></i></button>
                <button class="icon" *ngIf="editTitle" (click)="editTitle = !editTitle"><i class="fal fa-check"></i></button>
                <button class="icon" *ngIf="!editTitle" (click)="duplicateBlock()"><i class="fal fa-copy"></i></button>
                <button class="icon" *ngIf="!editTitle" (click)="remove()"><i class="fal fa-trash-alt"></i></button>
            </span>
        </div>
    </div>

    <!-- cambia pure show_collapse_down e hide_collapse_up con quello che vuoi, dovrebbe funziona come un accordion con collapse -->
    <div [ngClass]="!collapse ? 'show_collapse_down col-12 p-4 mt-4' : 'hide_collapse_up col-12 p-4'">
        <form action="" *ngIf="form" [formGroup]="form" (change)="submitForm()" class="w-100">
            <div class="row">

                <div class="col-lg-4 col-xl-4 mb-4">
                    <div [class.is-invalid]="form.get('datetime_start').invalid && form.get('datetime_start').touched">
                        <span class="field-note text-soft d-flex justify-content-center">{{ 'BLOCK.DATE_START' | translate }}</span>
                        <div class="form-group">
                            <label>
                                <!-- TODO buttons -->
                                <!-- <input formControlName="datetime_start" type="text" maxlength="5" class="form-control"> -->
                                <ngb-timepicker [meridian]="meridianDate" (ngModelChange)="changeTimeStart($event)" [(ngModel)]="timeStart" [ngModelOptions]="{standalone: true}"></ngb-timepicker>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 mb-4">
                    <div [class.is-invalid]="form.get('date_end').invalid && form.get('date_end').touched">
                        <span class="field-note text-soft d-flex justify-content-center">{{ 'BLOCK.DATE_END' | translate }}</span>
                        <div class="form-group date-input">
                            <label>
                                <input type="text" [value]="form.controls.date_end.value | dateToLocaleString" readonly (click)="datepickerEnd = !datepickerEnd" class="form-control">
                                <!--  la data fine si può modificare solo nei blocchi in evidenza 1153 slide 3 -->
                                <ngb-datepicker *ngIf="datepickerEnd && block.is_highlighted" formControlName="date_end" #dp (dateSelect)="changeDateEnd($event)" class="datepicker-custom"></ngb-datepicker>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 mb-4">
                    <div>
                        <span class="field-note text-soft d-flex justify-content-center">{{'BLOCK.TIME_END' | translate }}</span>
                        <div class="form-group">
                            <label>
                                <!--  la data fine si può modificare solo nei blocchi in evidenza 1153 slide 3 -->
                                <ngb-timepicker [disabled]="!block.is_highlighted" [meridian]="meridianDate" (ngModelChange)="changeTimeEnd($event)" [(ngModel)]="timeEnd" [ngModelOptions]="{standalone: true}"></ngb-timepicker>
                                <!-- <input formControlName="time_end" type="text" maxlength="5" class="form-control"> -->
                                <!--<span class="placeholder">{{ 'BLOCK.TIME_END' | translate }}</span>-->
                            </label>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column col-md-4">
                    <p>{{ 'ROOM.INTERVIEW_TYPE' | translate }}</p>
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="check-radio-box flex-grow-1">
                            <input id="is_room_group_a{{block.id}}" type="radio" [value]="false"  formControlName="is_room_group" />
                            <label for="is_room_group_a{{block.id}}">{{ 'ROOM.INDIVIDUAL' | translate }}</label>
                        </div>
                        <div class="check-radio-box flex-grow-1" *ngIf="!form.value.is_diary">
                            <input id="is_room_group_b{{block.id}}" type="radio" [value]="true"  formControlName="is_room_group" />
                            <label for="is_room_group_b{{block.id}}">{{ 'ROOM.GROUP' | translate }}</label>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group choose-file-input required" [class.is-invalid]="form.get('image').invalid && form.get('image').touched">
                        <div class="remove-logo" *ngIf="isBlockEditable()">
                            <i class="fal fa-times" (click)="removeImage()"></i>
                        </div>
                        <label>
                            <img [src]="block.image_url | privatesrc" alt="" class="country-logo">
                            <span class="placeholder">{{ 'BLOCK.IMAGE' | translate }}</span>
                            <input type="file" (change)="uploadImage($event.target.files)" style="width: 40%">
                            <span class="info-tooltip tooltip-t-r " placement="top" [ngbTooltip]="'TOOLTIPS.BLOCK_IMAGE' | translate">
                                <i class="far fa-info-circle"></i>
                            </span>
                        </label>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <app-core-select
                            [classes]="'form-control'"
                            [cache]="true"
                            [form]="form"
                            [formEntityName]="'device'"
                            [customList]="devices"
                            [label]="'name'"
                            [value]="'value'"
                            (change)="submitForm()"
                            >
                        </app-core-select>
                        <span class="info-tooltip tooltip-t-r " placement="top" [ngbTooltip]="'TOOLTIPS.DEVICES' | translate">
                            <i class="far fa-info-circle"></i>
                        </span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="check-radio-box">
                        <input  id="is_sequential_questions{{block.id}}" type="checkbox" formControlName="is_sequential_questions">
                        <label for="is_sequential_questions{{block.id}}">{{ 'BLOCK.SEQUENTIALS' | translate }}</label>
                        <span class="info-tooltip ml-2" placement="top" [ngbTooltip]="'TOOLTIPS.SEQUENTIALS' | translate">
                            <i class="far fa-info-circle"></i>
                        </span>
                    </div>
                </div>
                <div class="col-md-4">
                    <!-- <div class="check-radio-box">
                        <input id="is_diary_{{block.id}}" type="checkbox" formControlName="is_diary" (change)="checkDiary()">
                        <label for="is_diary_{{block.id}}">{{ 'BLOCK.DIARY' | translate }}</label>
                        <span class="info-tooltip ml-2" placement="top" [ngbTooltip]="'TOOLTIPS.DIARY' | translate">
                            <i class="far fa-info-circle"></i>
                        </span>
                    </div> -->
                    <div class="check-radio-box">
                        <input id="is_highlighted_{{block.id}}" type="checkbox" formControlName="is_highlighted">
                        <label for="is_highlighted_{{block.id}}">{{ 'BLOCK.IS_HIGHLIGHTED' | translate }}</label>
                        <span class="info-tooltip ml-2" placement="top" [ngbTooltip]="'MONITORING_SECTION.TOOLTIPS.IS_HIGHLIGHTED' | translate">
                            <i class="far fa-info-circle"></i>
                        </span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="check-radio-box">
                        <input id="has_geolocation_{{block.id}}" type="checkbox" formControlName="has_geolocation" (change)="geolocationChange()">
                        <label for="has_geolocation_{{block.id}}">{{ 'BLOCK.GEOLOCATION' | translate }}</label>
                        <span class="info-tooltip ml-2" placement="top" [ngbTooltip]="'TOOLTIPS.GEOLOCATION' | translate">
                            <i class="far fa-info-circle"></i>
                        </span>
                    </div>
                </div>
                <div class="col-md-8 offset-md-4 mt-0 d-flex" *ngIf="form.value.has_geolocation || block.has_geolocation">

                    <div class="form-group">
                        <input type="text" readonly class="form-control" formControlName="geolocation_address">
                        <span class="placeholder">{{ 'BLOCK.ADDRESS' | translate }}</span>
                    </div>

                    <div>
                        <button class="btn-sicche btn-secondary" *ngIf="!showMap" (click)="toggleShowMap()"><i class="fal fa-chevron-down"></i> {{'SHOW_MAP' | translate}}</button>
                        <button class="btn-sicche btn-secondary" *ngIf="showMap" (click)="toggleShowMap()"><i class="fal fa-chevron-up"></i> {{'HIDE_MAP' | translate}}</button>
                    </div>

                </div>
                <div class="col-12">
                    <div [className]="!(showMap && (form.controls.has_geolocation.value)) ? 'd-none' : ''">
                        <div class="form-group">
                            <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
                            <span class="placeholder">
                                {{'SEARCH_ADDRESS' | translate}}
                            </span>
                        </div>
                        <small>{{'DRAG_MARKER_TO_CHANGE_POSITION' | translate}}</small>


                        <google-map height="400px"
                            width="750px"
                            [center]="{
                                lng: block.geolocation_long,
                                lat: block.geolocation_lat
                            }"
                            [zoom]="zoom || 15"
                        >
                            <map-marker
                                [position]="{
                                    lng: block.geolocation_long,
                                    lat: block.geolocation_lat
                                }"
                                [options]="{
                                    draggable: true
                                }"
                                (mapDragend)="moveMarker($event)"
                            >

                            </map-marker>
                        </google-map>
                        <!-- <agm-map [latitude]="block.geolocation_lat" [longitude]="block.geolocation_long" [zoom]="zoom || 15">
                            <agm-marker [latitude]="block.geolocation_lat" [longitude]="block.geolocation_long" [markerDraggable]="true"
                            (dragEnd)="markerDragEnd($event)"></agm-marker>
                        </agm-map> -->
                    </div>
                </div>
            </div>
        </form>

        <!-- gestione domande -->
        <div class="border-top text-center mt-3 pt-3">
            <button (click)="showQuestions = !showQuestions" *ngIf="!showQuestions" class="btn-sicche"><i class="fal fa-chevron-down"></i> {{ 'BLOCK.SHOW_QUESTIONS' | translate }}</button>
            <button (click)="showQuestions = !showQuestions" *ngIf="showQuestions" class="btn-sicche"><i class="fal fa-chevron-up"></i> {{ 'BLOCK.HIDE_QUESTIONS' | translate }}</button>
        </div>
        <div [ngClass]="showQuestions ? 'show_collapse_down py-4' : 'hide_collapse_up py-4'">
            <div *ngIf="loading" class="spinner-border float-rigth spinner-border-sm" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div *ngIf="!questions.length && !loading" class="text-center">
                {{ 'THERE_ARE_NO_QUESTIONS' | translate }}
            </div>
            <div cdkDropList  (cdkDropListDropped)="changeQuestionsOrder($event)">
                <app-private-block-question-item *ngFor="let question of questions; let i = index" [block]="block" [isEditable]="isBlockEditable()" [index]="i" [question]="question" [currentDate]="day" (questionsChange)="loadQuestions()" cdkDrag></app-private-block-question-item>
            </div>
            <div class="text-center mt-3" *ngIf="isBlockEditable()">
                <label class="d-inline-flex align-items-center cursor-pointer" (click)="addQuestion()">
                    <button class="btn-sicche btn-icon extra-small"><i class="fal fa-plus"></i></button>
                    <span>{{ 'ADD_QUESTION' | translate }}</span>
                </label>
            </div>
        </div>
    </div>
</div>
