<ng-container *ngIf="answer">
  <div class="img-card-box d-flex flex-wrap justify-content-center p-0 {{ classes }}" *ngIf="answer.answer_data">
    <div class="mt-3 px-2" *ngFor="let a of answer.answer_data; let i = index">
      <div *ngIf="getImage(a.optionid).image_url" class="card with-comment">
        <div
          class="div-img-square"
          [style.background-image]="'url(' + (getImage(a.optionid).image_url | privatesrc) + ')'">
        </div>
        <div class="img-square-comment" *ngIf="a.comment" (mouseup)="selectText()">
          <div [innerHTML]="a.comment"></div>
        </div>
      </div>
      <!-- <div *ngIf="getImage(a.optionid).image_url && explicitMediaUrls" class="explicit-media-url">
        <a [href]="getImage(a.optionid).image_url">{{ 'MEDIA_DOWNLOAD' | translate }}</a>
      </div> -->
    </div>
  </div>
</ng-container>
