import { Injectable, EventEmitter } from '@angular/core';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { BaseService } from '@app/core/services/base.service';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { HttpClient } from '@angular/common/http';
import { catchError, count } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ServiceMessageModel } from '@app/core/models/service-message.model';
import { CountryModel } from '../models/country.model';
import { ResponseModel } from '@app/core/models/response.model';
import { NoticeModel } from '../models/notice.model';
import { TeamService } from './team.service';


@Injectable({
  providedIn: 'root'
})
export class NotificationService extends BaseService implements BaseServiceI {


  constructor(public router: Router, public apiService: ApiService, private http: HttpClient, private teamService: TeamService) {
    super(router, apiService, NoticeModel);
  }

  private currentNotice$: NoticeModel;
  private sessionNotices$: NoticeModel[];
  private noticeCountry$: string;

  private listners$ = new Subject<any>();

  noticeClicked = new EventEmitter();

  get currentNotice() {
      return this.currentNotice$;
  }

  set currentNotice(notice: NoticeModel) {
      this.currentNotice$ = notice;
  }

  get sessionNotices() {
    return this.sessionNotices$;
  }

  set sessionNotices(notice: NoticeModel[]) {
      this.sessionNotices$ = notice;
  }

  get noticeCountry() {
    return this.noticeCountry$;
  }

  set noticeCountry(countryUniqid: string) {
      this.noticeCountry$ = countryUniqid;
  }


  getNotices(country_uniqid: string = null) {
    if (!this.teamService.isCurrentUserCustomer()) {
      const data: any = {};
      if (country_uniqid) {
        data.country_uniqid = country_uniqid;
        return this.get('researcher', data);
      }
    }
  }


  setNoticesRead(notificationids, country_uniqid: string) {
    return this.sub('researcher_read').create({country_uniqid, notificationids});
  }


}
