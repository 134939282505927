import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TeamService } from '@app/shared/services/team.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DatetimeToDbPipe } from '@app/core/pipes/datetime-to-db.pipe';
import { MessageModel } from '@app/shared/models/message.model';
import { ResponseModel } from '@app/core/models/response.model';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { CountryService } from '@app/shared/services/country.service';
import { TemplateMessageModel } from '@app/shared/models/template-message.model';
import { TemplateMessageService } from '@app/shared/services/template-messages.service';

@Component({
  selector: 'app-shared-send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.scss']
})
export class SendMessageSharedComponent implements OnInit {

  @Input() selectedUsers = [];
  @Input() selectedUsersData = [];
  @Input() roomid: number;
  @Input() country_uniqid: string;
  @Input() type: string;
  @Input() isInterview: boolean;
  @Input() sendLink: boolean = true;
  @Input() isSidebar: boolean;
  @Input() isCustomer: boolean;
  @Output() msgSent = new EventEmitter();

  sending = false;

  constructor(
    private teamService: TeamService,
    private toastService: ToastrService,
    private translateService: TranslateService,
    private datetimeToDbPipe: DatetimeToDbPipe,
    private coreLayoutService: CoreLayoutService,
    private countryService: CountryService,
    private templateMessageService: TemplateMessageService,
  ) {
    this.modules = {
      'emoji-shortname': true,
      'emoji-textarea': true,
      'emoji-toolbar': true,
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ color: [] }, { background: [] }],
        [{ font: [] }],
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ align: [] }],
        ['clean'],
        ['link'],
        ['emoji']
      ]
    };
  }

  showSaveAsTemplate = false;
  showLoadFromTemplate = false;
  templateTitle = '';
  currentTemplateMessage: TemplateMessageModel;
  templateMessages: TemplateMessageModel[];


  subject: string;
  text: string;
  modules = {};

  saveAsTemplateMessage() {
    const templateMessage = {
      title: this.templateTitle,
      body: this.text,
      subject: this.subject
    }
    this.templateMessageService.createTemplateMessage(templateMessage).subscribe((res: ResponseModel) => {
      this.templateTitle = '';
      this.showSaveAsTemplate = false;1
      this.toastService.success(this.translateService.instant('SAVED_AS_TEMPLATE_MESSAGE'));
      this.loadTemplateMessages()
    })
  }
  loadTemplateMessages() {
    this.templateMessageService.getTemplateMessages().subscribe((res: ResponseModel) => {
      this.templateMessages = res.getData();
    })
  }

  setTemplateMessage(template: TemplateMessageModel) {
    this.currentTemplateMessage = template;
  }

  loadFromTemplate() {
    this.subject = this.currentTemplateMessage.subject;
    this.text = this.currentTemplateMessage.body;
  }

  ngOnInit(): void {
    this.loadTemplateMessages();
  }

  getUsersName() {
    return this.selectedUsersData.map(user => {
      if (user.nickname) {
        return user.nickname
      } else if (user.firstname) {
        return (user.firstname + ' ' + user.lastname)
      } else {
        return user.email
      }
    }).join(', ')
  }

  send() {
    if (this.text && this.subject && !this.sending) {
      this.sending = true;
      const message = new MessageModel();
      message.user_ids = this.selectedUsers;
      message.type = this.type || 'GENERIC';
      message.subject = this.subject;
      if (this.selectedUsers) {
        message.other_data = {
          recipient_user_firstname: null,
          recipient_room: null,
          recipient_filters: null,
          recipient_variable_tags: null
        };
        message.other_data.recipient_user_firstname = this.getUsersName();
      }
      message.msg = this.text;
      if (message.type === 'INVITE' && this.sendLink) {
        message.include_link = true;
      }

      message.country_uniqid = this.country_uniqid;
      message.date_send = this.datetimeToDbPipe.transform(new Date(), this.countryService.getSessionCountry().timezone);
      message.send_now = true;

      message.isCustomer = this.isCustomer;

      this.teamService.sendMessage(message).subscribe((res: ResponseModel) => {
        this.sending = false;
        this.toastService.success(this.translateService.instant('MESSAGE_SENT'));
        if (this.isSidebar) {
          this.coreLayoutService.closeSidebar();
        }
        this.selectedUsers = [];
        this.msgSent.emit('');
      }, (e) => {
        this.toastService.error(this.translateService.instant('ERROR_GENERIC'));
      });
    }
    }

}
