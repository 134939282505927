<div class="container-fluid">
    <div class="row vh-100">
        <div class="col-md-3 col-xl-4 offset-1 pr-5 left-col h-100">
            <div class="logo-wrapper login py-5">
                <app-logo></app-logo>
            </div>
            <!-- VALUTA SOLUZIONE SCROLL SINISTRA: https://www.codeply.com/go/IuOp3nvCpy -->
            <div class="payoff-wrapper mt-6 pr-4">
                <h2>{{ 'LOGIN_PAYOFF' | translate }}!</h2>
                <h3 class="light">{{ 'PAYOFF_SUB' | translate }}</h3>
                <div class="contact-us mt-5">
                    <a class="as-link" href="mailto:help@sicche.com">
                        <i class="fal fa-envelope mr-1 d-inline-block mr-3 va-middle" style="font-size: 25px;"></i>
                        <small>{{ 'CONTACT_US_DIRECTLY' | translate }}</small>
                    </a>
                </div>
            </div>    
        </div>
        <div class="col-md-7 col-xl-6 col-xxl-5 min-h-100 right-col bg-white p-5 shadow">
            <app-auth-navbar (createAccountClick)="toggleCreateAccount($event)"></app-auth-navbar>
            <div class="px-5 mx-5">
                <div class="form-wrapper va mt-6 pb-3 text-sicche">
                    <div class="position-relative">
                        <div class="login-form-wrapper togglable" [class.visible]="tab == 1">
                            <div *ngIf="activated">
                                <div class="alert alert-success" role="alert">
                                    <h4 class="alert-heading">{{ 'WELL_DONE' | translate }}!</h4>
                                    <p>{{ 'ACCOUNT_HAS_BEEN_ACTIVATED' | translate }}</p>
                                </div>
                            </div>
                            <app-auth-loginform></app-auth-loginform>
                        </div>
                        <div class="signup-form-wrapper togglable" [class.visible]="tab == 2">
                            <app-auth-signupform></app-auth-signupform>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>