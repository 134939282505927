import { Pipe, PipeTransform } from '@angular/core';
import { CountryService } from '@app/shared/services/country.service';
import { isString } from 'util';
import { CoreBaseComponent } from '../components/base.component';

@Pipe({
  name: 'dateToLocaleString'
})
export class DateToLocaleStringPipe implements PipeTransform {

  constructor(
    private countryService: CountryService
  ) {
  }

  transform(value: string |  Date | number, includeTime = false, ...args: unknown[]): string {
    let meridian = false;
    if (this.countryService.getSessionCountry() && this.countryService.getSessionCountry().country_code != 'it') {
      meridian = true;
    }
    if (value) {
      value = this.parseCompatibilityDate(value);
      const d = new Date(value);
      if (meridian) {
        const meridiem = d.getHours() >= 12 ? "PM" : "AM";
        if (includeTime) {
          return `${d.getFullYear()}/${this.pad(d.getMonth() + 1, 2)}/${this.pad(d.getDate(), 2)} ${this.pad(((d.getHours() + 11) % 12 + 1), 2)}:${this.pad(d.getMinutes(), 2)} ${meridiem}`;
        } else {
          return `${d.getFullYear()}/${this.pad(d.getMonth() + 1, 2)}/${this.pad(d.getDate(), 2)}`;
        }
      } else {
        if (includeTime) {
          return `${this.pad(d.getDate(), 2)}/${this.pad(d.getMonth() + 1, 2)}/${d.getFullYear()} ${this.pad(d.getHours(), 2)}:${this.pad(d.getMinutes(), 2)}`
        } else {
          return `${this.pad(d.getDate(), 2)}/${this.pad(d.getMonth() + 1, 2)}/${d.getFullYear()}`
        }
      }
    } else {
      return '';
    }
  }

  pad(num:number, size:number): string {
    let s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }

  getUsersLocale(defaultValue: string): string {
    if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
      return defaultValue;
    }
    const wn = window.navigator as any;
    let lang = wn.languages ? wn.languages[0] : defaultValue;
    lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;
    return lang;
  }

  replaceAll(str, search, replace) {
    return str.replace(new RegExp(search, 'g'), replace);
  }

  parseCompatibilityDate(value: string | Date | number) {
    if (typeof value === 'string') {
      if (value.indexOf('-') !== -1) {
        value = this.replaceAll(value, '-', '/');
      }
      // @ts-ignore
      if (value.indexOf('+') !== -1) {
        value = value.toString();
        value = value.substring(0, value.indexOf('+'));
      }
      // @ts-ignore
      if (value.indexOf('/') === 2) {
        // @ts-ignore
        const parts = value.split('/');
        value = `${value[2]}/${value[1]}/${value[0]}`;
      }
    }
    return value;
  }

}

