import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateToHourMinutes'
})

export class DateToHourMinutes implements PipeTransform {
    transform(value: Date | string,  meridian = false): any {
        if (!value) {
            return '';
        }
        if (typeof value === 'string') {
            if (value.indexOf('-') !== -1) {
                value = this.replaceAll(value, '-', '/');
            }
            // @ts-ignore
            if (value.indexOf('+') !== -1) {
                // @ts-ignore
                value = value.substring(0, value.indexOf('+'));
            }
        }
        const date = new Date(value);
        const hours = this.pad(date.getHours(), 2)
        const minutes = this.pad(date.getMinutes(), 2)
        if (meridian) {
            const meridiem = date.getHours() >= 12 ? "PM" : "AM";
            return `${this.pad(((date.getHours() + 11) % 12 + 1), 2)}:${this.pad(date.getMinutes(), 2)} ${meridiem}`;
        }
        return `${hours}:${minutes}`;
    }

    replaceAll(str, search, replace) {
        return str.replace(new RegExp(search, 'g'), replace);
    }


    pad(num:number, size:number): string {
        let s = num+"";
        while (s.length < size) s = "0" + s;
        return s;
    }

}
