import { Component, OnInit, ViewChild, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { ResearchSessionService } from '@app/shared/services/research-session.service';
import { RoomsService } from '@app/shared/services/rooms.service';
import { ResponseModel } from '@app/core/models/response.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@app/core/services/auth.service';
import 'quill-emoji/dist/quill-emoji.js';
import { TeamModel } from '@app/shared/models/team.model';
import { TeamService } from '@app/shared/services/team.service';
import { AnswersService } from '@app/shared/services/answers.service';
import { MessageModel } from '@app/shared/models/message.model';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { DatetimeToDbPipe } from '@app/core/pipes/datetime-to-db.pipe';
import { ModalComponent } from '@app/core/components/modal/modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-moderation-peopleview-privatemessage',
  templateUrl: './private-message.component.html',
  styleUrls: ['./private-message.component.scss']
})
export class ModerationPrivateMessageComponent extends ModalComponent implements OnInit {

  public form: UntypedFormGroup;
  public success = false;
  public loading = false;
  public user = null;
  public quillModules = {
    'emoji-shortname': true,
    'emoji-textarea': true,
    'emoji-toolbar': true,
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
      ['clean'],
      ['link', 'image', 'video'],
      ['emoji']
    ]
  };


  constructor(private researchSessionService: ResearchSessionService, private dateTimeToDb: DatetimeToDbPipe,
              private translate: TranslateService, private teamService: TeamService, private toastService: ToastrService,
              private answersService: AnswersService, private roomsService: RoomsService, public activeModal: NgbActiveModal,
              public authService: AuthService) {
                super(activeModal);
              }

  ngOnInit(): void {
    this.user = this.params.user;
    this.form = new UntypedFormGroup({
      text: new UntypedFormControl('', Validators.required)
    });
  }

  cancel() {
    this.activeModal.close();
  }

  send() {
      this.loading = true;
      this.teamService.sendMessage(new MessageModel({
        user_ids: [this.user.userid],
        msg: this.form.value.text,
        country_uniqid: this.user.country_uniqid,
        date_send: this.dateTimeToDb.transform(new Date()),
        roomid: this.user.roomid,
        subject: this.translate.instant('MESSAGE'),
        type: ''
      }))
      .subscribe((res: ResponseModel) => {
        this.loading = false;
        this.success = true;
        this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
      }, (e) => {
        this.loading = false;
        this.toastService.error(this.translate.instant('ERROR_GENERIC'));
      });
  }

}
