import { Component, OnInit, ViewChild, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { AnswerModel } from '@app/shared/models/answer.model';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ModalService } from '@app/core/services/modal.service';
import { LightboxModalComponent } from '@app/core/components/lightbox-modal/lightbox-modal.component';
import { AnswersService } from '@app/shared/services/answers.service';
import { ResponseModel } from '@app/core/models/response.model';
import { ModerationHelperService } from '@app/shared/services/moderation-helper.service';
import { QuoteModel } from '@app/shared/models/quote.model';
import { TeamService } from '@app/shared/services/team.service';

@Component({
  selector: 'app-moderation-answerstype-open',
  templateUrl: './open-answer.component.html',
  styleUrls: ['./open-answer.component.scss']
})
export class AnswersTypeOpenComponent extends CoreBaseComponent implements OnInit {

  /**
   * !Attenzione
   * Se si modifica il template della risposta aperta va corretta la sua versione "screenshot"
   * in analisi ed export -> tag cloud nascondendo gli elementi indesiderati
   */

  @Input() question: QuestionModel;
  @Input() answer: AnswerModel;
  @Input() classes: string;
  @Input() explicitMediaUrls: boolean;
  @Input() placeholders: boolean;
  @Input()
  set message(value: {action: string, data: any}) {
    this.switchMessage(value);
  }
  @Output() textSelection: EventEmitter<any> = new EventEmitter(null);
  public mediaTab = null;
  public form: UntypedFormGroup;
  public itemToModify: number = null;
  private qSubscription = null;
  showSpeechToTextArr = [];
  public originalText = null;
  videosCount = null;
  imagesCount = null;

  constructor(private modalService: ModalService, private answerService: AnswersService,
              private moderationHelperService:  ModerationHelperService, private teamService: TeamService) {
    super();

  }

  private switchMessage(message: {action: string, data: any}) {
    if (!message) {
      return false;
    }
    switch (message.action) {
      case 'modify-answer':
        this.itemToModify = message.data;
        break;
    }
  }

  ngOnInit(): void {
    // Return/new-line to br
    this.answer.answer_data.text = this.answer.answer_data.text.replace(/(\r\n|\n|\r)/gm, "<br />");

    setTimeout(() => {
      this.addQuotes();
    });
    // Init mediaTab
    this.mediaTab = this.answer.answer_data.images.length ? 'images' : this.answer.answer_data.videos.length ? 'videos' : null;
    this.form = new UntypedFormGroup({
      text: new UntypedFormControl(this.answer.answer_data.text, null)
    });

    if (this.answer.answer_data.videos && this.answer.answer_data.videos.length) {
      this.videosCount = this.answer.answer_data.videos.length;
    }
    if (this.answer.answer_data.images && this.answer.answer_data.images.length) {
      this.imagesCount = this.answer.answer_data.images.length;
    }
  }

  private addQuotes(reset = false) {
    if(!this.teamService.isCurrentUserCustomerPro() && !this.teamService.isCurrentUserCustomerBasic()) {
    if(this.answer.quotes) {
      this.copyOriginalText(reset);
      this.printQuotes(this.answer.quotes);
    }
    this.qSubscription = this.moderationHelperService.quotes.subscribe(quotes => {
      if(quotes) {
        this.copyOriginalText(reset);
        this.printQuotes(quotes);
      }
    });
  }}

  private copyOriginalText(reset) {
    // Answer text
    if(!this.originalText || reset) {
      this.originalText = this.answer.answer_data.text;
    }
    this.answer.answer_data.text = this.originalText;
    // Video transcription
    this.answer.answer_data.videos.forEach(video => {
      video.hlTranscription = '';
      if (video.video_data.file_words) {
        video.video_data.file_words.forEach(word => {
          video.hlTranscription += word.word + ' ';
        });
      }
    });
  }

  private printQuotes(quotes: Array<QuoteModel>) {
    this.answer.answer_data.text = this.answer.answer_data.text?.replaceAll(/\s{2,}/g, ' ')?.replaceAll(/\r?\n|\r/g, '');
    if(quotes != null) {
      quotes.forEach(quote => {
        if(quote.answerid === this.answer.id) {
          if(this.answer.answer_data.text) {
            const qclass = quote.color ? quote.color : 'grey';
            if(quote.quote_original && quote.quote_original.length) {
              quote.quote_original.forEach(q => {
                this.answer.answer_data.text = this.answer.answer_data.text.replace(q, '<em class="highlight ' + qclass + '">'+ q +'</em>');
              });
            } else {
              // old way - before 29/July/2021
              this.answer.answer_data.text = this.answer.answer_data.text.replace(quote.quote, '<em class="highlight ' + qclass + '">'+ quote.quote +'</em>');
            }
          }
        }
        if(this.answer.answer_data.videos.length && quote.answerid === this.answer.id) {
          this.answer.answer_data.videos.forEach(video => {
            this.hlTranscription(video, quote);
          });
        }
      });
    }
  }

  removeTagsWithClass(str) {
    var re = /(<span(?: \w+="[^"]+")* class="ql-emojiblot"(?: \w+="[^"]+")*>([^<]*)<\/span>)/g;

    str = str.replace(re, match => ``)
  }

  private hlTranscription(video: any, quote: QuoteModel) {
    if(!video.hasOwnProperty('hlTranscription')) {
      video.hlTranscription = '';
      video.video_data.file_words.forEach(word => {
        video.hlTranscription += word.word + ' ';
      });
    }
    const qclass = quote.color ? quote.color : 'grey';
    video.hlTranscription = video.hlTranscription.replace(quote.quote, '<em class="highlight ' + qclass + '">' + quote.quote + '</em>');
  }

  selectText() {
    this.textSelection.emit({commentid:null});
  }

  openLightbox(image: any | number, mediaArray?: Array<any>) {
    this.modalService.open({modalModel: LightboxModalComponent, dialogSize: 'custom'}, {image, images: mediaArray});
  }

  saveMods() {
    this.operation('saving').reset().isSubmitting();
    const data = this.answer;
    data.answer_data.text = this.form.value.text;
    this.answerService.updateAnswer(this.answer.id, data).subscribe((res: ResponseModel) => {
      this.answer.answer_data.text = this.form.value.text;
      this.addQuotes(true);
      this.itemToModify = null;
      this.operation('saving').isSuccess();
    });
  }

  /*
  showSpeechToText(index) {
    if (!this.showSpeechToTextArr.includes(index)) {
      this.showSpeechToTextArr = [...this.showSpeechToTextArr, index];
    } else {
      const i = this.showSpeechToTextArr.indexOf(index);
      this.showSpeechToTextArr.splice(i, 1);
    }
  }
  */

  setVideoTime(answerID, start, end) {
    const video: any = document.getElementById('video_player_' + answerID);
    video.currentTime = start / 1000;
    // console.log(index, start / 1000, end, video);
  }

  ngOnDestroy() {
    if(this.qSubscription) {
      this.qSubscription.unsubscribe();
    }
  }




}
