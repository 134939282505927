import { Component, OnInit, ViewChild, AfterViewInit, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { QuestionsService } from '@app/shared/services/questions.service';
import { NgScrollbar } from 'ngx-scrollbar';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { getLocaleDayNames } from '@angular/common';
import { ResearchModel } from '@app/shared/models/research.model';
import { DatePipe } from '@angular/common';
import { ResearchSessionService } from '@app/shared/services/research-session.service';
import { RoomsService } from '@app/shared/services/rooms.service';
import { ResponseModel } from '@app/core/models/response.model';
import { ActivatedRoute } from '@angular/router';
import { RoomModel } from '@app/shared/models/room.model';
import { BlockService } from '@app/shared/services/block.service';
import { BlockModel } from '@app/shared/models/block.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@app/core/services/auth.service';
import 'quill-emoji/dist/quill-emoji.js';
import { TeamModel } from '@app/shared/models/team.model';
import { TeamService } from '@app/shared/services/team.service';
import { PageModel } from '@app/core/models/page';
import { AnswersService } from '@app/shared/services/answers.service';
import { ToastrService } from 'ngx-toastr';
import { AnswerModel } from '@app/shared/models/answer.model';
import { CommentModel } from '@app/shared/models/comment.model';
import { ModalService } from '@app/core/services/modal.service';
import { ModerationPrivateMessageComponent } from './private-message/private-message.component';
import { CookiesService } from '@app/core/services/cookies.service';
import { AnswersModalSupportService } from '@app/shared/services/support/answers-modal.service';
import { AnalysisService } from '@app/shared/services/analysis.service';
import { ModerationHelperService } from '@app/shared/services/moderation-helper.service';
import { CountryService } from '@app/shared/services/country.service';
import { StorageService } from '@app/core/services/storage.service';
import * as angular from 'angular';

@Component({
  selector: 'app-moderation-peopleview',
  templateUrl: './people-view.component.html',
  styleUrls: ['./people-view.component.scss']
})
export class ModerationPeopleViewComponent extends CoreBaseComponent implements OnInit, AfterViewInit {

  @ViewChild(NgScrollbar) scrollbarRef: NgScrollbar;
  @ViewChild('scrollTarget', { static: true }) scrollTarget: HTMLElement;
  @Input()
  roomid: number;
  @Input()
  room: RoomModel;
  @Input()
  block: any;
  @Input()
  blocks: any;
  @Input()
  question: QuestionModel;
  @Input()
  country_uniqid: string;
  @Input()
  questionid: number;
  @Output() selectedBlockChanged = new EventEmitter();

  public loadingChat = false;
  public answerIdToModify = null;
  public answerTextModified = null;
  public questions: QuestionModel[] = [];

  @Input() selectedTeamUser: TeamModel;


  public quillModules = {
    'emoji-shortname': true,
    'emoji-textarea': true,
    'emoji-toolbar': true,
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image', 'video'],
      ['emoji']
    ]
  };

  public intervieweds: TeamModel[] = null;
  public interviewedsToPrint: TeamModel[] = null;
  public answerTo: AnswerModel;
  public answerText = null;
  public answer: any;
  public showTextarea = false;
  public commentToModify: any;
  public commentNewText: null;
  public deletedComments = [];
  public activeQuotes = false;
  public loggedUserRole = null;
  public isCustomerPro = false;
  public variablesFilterShow = false;
  public loading = false;

  public selectedSampleVariableTags = [];
  public selectedPersonasTags = [];

  public personasTagsList = [];
  public sampleVariableTags = [];

  currentRoomBlocks: BlockModel[] = [];
  public navigationData = null;

  constructor(
    private questionsService: QuestionsService,private translate: TranslateService,
    public teamService: TeamService, private toastService: ToastrService,
    private answersService: AnswersService, private storageService: StorageService, private modalService: ModalService,
    public answersModalSupportService: AnswersModalSupportService, private analysisService: AnalysisService,
    private moderationHelperService: ModerationHelperService, private countryService: CountryService,
    public authService: AuthService, private cookiesService: CookiesService
  ) {
    super();
  }

  ngOnInit(): void {

    this.isCustomerPro = this.teamService.isCurrentUserCustomerPro();
    // Check if quotes is active
    this.loadData()
  }

  loadData(selectUser = null) {
    this.activeQuotes = this.cookiesService.getCookie('activequotes') ? true : false;
    this.answersModalSupportService.activeQuotes$.next(this.activeQuotes);
    // Get quotes
    this.analysisService.getQuotesBy(this.country_uniqid, {'blockids[]': [this.block.id]}).subscribe((res: ResponseModel) => {
      this.moderationHelperService.setQuotes(res.getData());
    }); // Refresh quotes
    this.moderationHelperService.refreshQuotes.subscribe((refresh: boolean) => {
      this.analysisService.getQuotesBy(this.country_uniqid, {'blockids[]': [this.block.id]}).subscribe((res: ResponseModel) => {
        this.moderationHelperService.setQuotes(res.getData());
      });
    });

    this.currentRoomBlocks = this.blocks.filter(block => block.roomid === this.roomid);

    this.updateNavigationData()

    this.countryService.getPersonasTags(this.country_uniqid).subscribe((res: ResponseModel) => {
      this.personasTagsList = res.getData();
      this.sampleVariableTags = this.countryService.getSessionCountry().sample_variable_tags;
      this.selectedSampleVariableTags = angular.copy(this.room.sample_variable_tags) || [];

      const storedSampleVariableTags = this.storageService.get('moderation_filter_sample_variable_tags_' + this.country_uniqid);
      const storedPersonasTags = this.storageService.get('moderation_filter_personas_tags_' + this.country_uniqid);
      if (storedSampleVariableTags || storedPersonasTags) {
        this.selectedSampleVariableTags = this.sampleVariableTags.filter(tag => storedSampleVariableTags.includes(tag))
        this.selectedPersonasTags = this.personasTagsList.filter(tag => storedPersonasTags.includes(tag))
      }

      if (selectUser) {
        // user are already loaded, just reselect last user
        this.selectTeamUser(selectUser, null)
      } else {
        this.getUsers();
      }
    });
  }

  getFilterCount() {
    // 1666 il sample variable tag della room non conta
    const count = this.selectedSampleVariableTags.length + this.selectedPersonasTags.length - (this.room.sample_variable_tags || []).length;
    return count
  }


  updateNavigationData() {
    const navigationData = {
      isFirst: this.currentRoomBlocks.indexOf(this.block) === 0,
      isLast: this.currentRoomBlocks.indexOf(this.block) === (this.currentRoomBlocks.length - 1),
    }
    this.navigationData = navigationData;
  }

  prevBlock() {
    const indexOf = this.currentRoomBlocks.indexOf(this.block);
    this.block = this.currentRoomBlocks[indexOf - 1];
    this.selectedBlockChanged.emit(this.block);
    this.loadData(this.selectedTeamUser)
  }

  nextBlock() {
    const indexOf = this.currentRoomBlocks.indexOf(this.block);
    this.block = this.currentRoomBlocks[indexOf + 1];
    this.selectedBlockChanged.emit(this.block);
    this.loadData(this.selectedTeamUser)
  }

  getUsers() {
    const currentSelectedTeamUser = angular.copy(this.selectedTeamUser)
    this.loading = true;
    // Get users
    this.teamService.getUsers({
      country_uniqid: this.country_uniqid,
      roomid: this.roomid,
      blockid: this.block.id,
      include_stats_answer_to_read: true,
      sample_variable_tags_by_comma: (this.selectedSampleVariableTags.length > 0) ? this.selectedSampleVariableTags.join(',') : null,
      personas_tags_by_comma: (this.selectedPersonasTags.length > 0) ? this.selectedPersonasTags.join(',') : null
    }).subscribe((res: ResponseModel) => {
      this.intervieweds = res.getData();
      // Filter by logged in
      this.interviewedsToPrint = this.intervieweds.filter((user: TeamModel) => {
        return user.hasLoggedIn(true);
      });
      this.interviewedsToPrint = this.orderByName(this.interviewedsToPrint);
      if (this.intervieweds.length) {
        this.selectTeamUser(this.interviewedsToPrint[0], null);
      }
      this.loading = false
    });
  }

  selectSampleVariableTag(tag: string, filter = true) {
    if (!this.selectedSampleVariableTags.includes(tag)) {
      this.selectedSampleVariableTags.push(tag);
    } else {
      this.selectedSampleVariableTags.splice(this.selectedSampleVariableTags.indexOf(tag), 1);
    }
    this.getUsers();
  }

  selectPersonasTag(tag, filter = true) {
    if (!this.selectedPersonasTags.includes(tag)) {
      this.selectedPersonasTags.push(tag);
    } else {
      this.selectedPersonasTags.splice(this.selectedPersonasTags.indexOf(tag), 1);
    }
    this.getUsers();
  }
  resetFilters() {
    this.storageService.forget('moderation_filter_sample_variable_tags_' + this.country_uniqid);
    this.storageService.forget('moderation_filter_personas_tags_' + this.country_uniqid);
    this.selectedSampleVariableTags = angular.copy(this.room.sample_variable_tags) || [];
    this.selectedPersonasTags = [];
    this.getUsers();
  }

  storeFilters() {
    this.storageService.set('moderation_filter_sample_variable_tags_' + this.country_uniqid, this.selectedSampleVariableTags);
    this.storageService.set('moderation_filter_personas_tags_' + this.country_uniqid, this.selectedPersonasTags);
    this.getUsers();
    document.getElementById('variablesFilterShowToggle').click();
  }

  quotesModalClose() {
    this.answersModalSupportService.showQuotesModal$.next(false);
  }

  selectTeamUser(user: any, scrollTo: any) {
    if (this.operation('sending-comment').submitting || !user) {
      return null;
    }
    this.answerTo = null;
    this.answerText = null;
    this.selectedTeamUser = user;
    if (this.selectedTeamUser.userid !== '-1' && scrollTo) {
      // this.scrollTarget.scrollIntoView({behavior: 'smooth'});
    }
    this.loadChat(this.selectedTeamUser);
  }

  private loadChat(teamUser: TeamModel) {
    this.loadingChat = true;
    this.questionsService.getQuestionsByBlockId_b({blockid: this.block.id, userid: this.selectedTeamUser.userid}).subscribe((res: ResponseModel) => {
      this.questions = res.getData();
      this.loadingChat = false;
      // Set answers as read
      let setAsReadIds = [];
      this.questions.forEach((question: QuestionModel) => {
        question.answers.forEach((answer: AnswerModel) => {
          if(!answer.read) {
            setAsReadIds.push(answer.id);
          }
        });
      });
      if(setAsReadIds.length) {
        setTimeout(() => {
            this.answersService.setAnswersRead(setAsReadIds).subscribe((response: ResponseModel) => {
              teamUser.answers_to_read = 0;
            });
        }, 2000);
      }
    });
  }

  rated(rating: number, answer: any) {
    this.answersService.updateAnswer(answer.id, {
      stars: rating
    }).subscribe((res: ResponseModel) => {
      this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
      answer.stars = rating;
    }, (e) => {
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
    });
  }

  deleteAnswer(answer: any) {
    this.modalService.open({title: 'ARE_YOU_SURE.?',
        btnOkText: 'YES',
        btnCancelText: 'CANCEL',
        showBtnClose: false,
        showCancelBtn: true,
        customClass: 'confirm-modal',
        message: 'ARE_YOU_SURE_LOGOUT.?'
      }).then((data) => {
        if (data) {
          this.answersService.deleteAnswer(answer.id).subscribe((res: ResponseModel) => {
            answer.deleted = true;
            this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
          }, (e) => {
            this.toastService.error(this.translate.instant('ERROR_GENERIC'));
          });
        }
    });
  }

  tagsChanged(tags: any, answer: any) {
    this.answersService.updateAnswer(answer.id, {
      answer_tags: tags.map(tag => tag.name)
    }).subscribe((res: ResponseModel) => {
      this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
      answer.answer_tags = tags;
    }, (e) => {
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
    });
  }

  ngAfterViewInit() {
    // this.scrollbarRef.scrollToElement(document.getElementById('chat-end'));
  }

  reply(answer: AnswerModel) {
    this.answerTo = answer;
    this.showTextarea = true;
    this.operation('sending-comment').reset();
  }

  sendReply() {
    this.operation('sending-comment').reset().isSubmitting();
    if (this.answerTo) {
      this.answersService.insertComment({
        answerid: this.answerTo.id,
        comment: this.answerText,
        flag_like: false,
        commentid: null
      }).subscribe((res: ResponseModel) => {
        this.answerText = null;
        this.answerTo.comments = [];
        this.showTextarea = false;
        this.operation('sending-comment').isSuccess();
        this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
        const lastInsertedComment = res.getData()[0];
        if (this.answerTo) {
          this.getComments(this.answerTo, lastInsertedComment);
        }
      }, (e) => {
        this.operation('sending-comment').isFailed(e.error);
      });
    }
  }

  getComments(answer: any, lastComment: CommentModel) {
    this.operation('loading-comments').reset().isSubmitting();
    answer.comments = [];
    this.answersService.getComments(answer.id).subscribe((res: ResponseModel) => {
      this.operation('loading-comments').isSuccess();
      answer.comments = res.getData();
      setTimeout( () => {
       this.scrollbarRef.scrollToElement(`#comment-${lastComment.id}-end`);
      }, 2000);
    }, (e) => {
      this.operation('loading-comments').isFailed();
    });
  }

  onNewCommentChange(data: any) {
    if (this.operation('sending-comment').success) {
      this.operation('sending-comment').reset();
    }
  }

  openPrivateMessageModal() {
    this.modalService.open({modalModel: ModerationPrivateMessageComponent, dialogSize: 'lg'}, {user: this.selectedTeamUser});
  }

  blockUser() {
    this.teamService.lockUser(this.selectedTeamUser).subscribe((res: ResponseModel) => {
      this.selectedTeamUser.is_locked = true;
      this.moderationHelperService.teamLockChange.next(this.selectedTeamUser);
      this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
    }, (e) => {
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
    });
  }

  unlockUser() {
    this.teamService.unLockUser(this.selectedTeamUser).subscribe((res: ResponseModel) => {
      this.selectedTeamUser.is_locked = false;
      this.moderationHelperService.teamLockChange.next(this.selectedTeamUser);
      this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
    }, (e) => {
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
    });
  }

  modifyAnswer(answer: any) {
    this.answerIdToModify = answer.id;
    this.answerTextModified = answer.answer_data.text;
  }

  saveAnswerTextMod(answer: any) {
    this.operation('saving').reset().isSubmitting();
    const data = answer;
    data.answer_data.text = this.answerTextModified;
    this.answersService.updateAnswer(answer.id, data).subscribe((res: ResponseModel) => {
      answer.answer_data.text = this.answerTextModified;
      this.answerTextModified = null;
      this.answerIdToModify = null;
      this.operation('saving').isSuccess();
      this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
    }, (e) => {
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
    });
  }

  addTagFn(name) {
    return { name, tag: true };
  }

  modifyComment(comment: any) {
    this.commentNewText = comment.comment;
    this.commentToModify = comment;
  }

  saveCommentMods(comment: any) {
    this.operation('saving-comment').reset().isSubmitting();
    this.answersService.updateComment(comment.id, {
        answerid: this.answer[0].id,
        comment: this.commentNewText,
        commentid: comment.id,
        flag_like: false
    }).subscribe((res: ResponseModel) => {
        comment.comment = this.commentNewText;
        this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
        this.commentToModify = null;
        this.operation('saving-comment').isSuccess();
    }, (e) => {
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
      this.operation('saving-comment').isFailed();
    });
  }

  deleteComment(comment: any) {
    this.modalService.open({title: 'ARE_YOU_SURE.?',
        btnOkText: 'YES',
        btnCancelText: 'CANCEL',
        showBtnClose: false,
        showCancelBtn: true,
        customClass: 'confirm-modal',
        message: 'ARE_YOU_SURE_LOGOUT.?'
      }).then((data) => {
        if (data) {
          this.operation('deleting-comment').reset().isSubmitting();
          this.answersService.deleteComment(comment.id).subscribe((res: ResponseModel) => {
            this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
            this.deletedComments.push(comment.id);
            this.operation('deleting-comment').isSuccess();
          }, (e) => {
            this.toastService.error(this.translate.instant('ERROR_GENERIC'));
            this.operation('deleting-comment').isFailed(e);
          } );
        }
    });
  }

  toggleQuotesSelection(status: boolean) {
    this.activeQuotes = status;
    this.answersModalSupportService.activeQuotes$.next(this.activeQuotes);
    this.cookiesService.setCookie('activequotes', this.activeQuotes, 0);
  }

  /*
  todo deprecato
  personasTagsChanged() {
    const operation = this.operation('saving-personas-tags').reset().isSubmitting();
    this.teamService.addModifyUser(new TeamModel({
      email: this.selectedTeamUser.email,
      personas_tags: this.selectedTeamUser.personas_tags ? this.selectedTeamUser.personas_tags.map(tag => {
        if (typeof tag === 'string') {
          return tag;
        } else {
          // @ts-ignore
          return tag.name;
        }
      }) : null,
      role: this.selectedTeamUser.role,
      country_uniqid: this.selectedTeamUser.country_uniqid
    })).subscribe((res: ResponseModel) => {
      operation.isSuccess();
      this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
    }, (e) => {
      operation.isFailed();
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
    });
  }
  */

  personasTagsChange(event) {
    const tags = event.map((tagItem: any) => tagItem.name ? tagItem.name : tagItem);
    const tag = tags[event.length - 1];
    if (tag) {
      this.selectedTeamUser.personas_tags = [tag];
    } else {
      this.selectedTeamUser.personas_tags = [];
    }
    const operation = this.operation('saving-personas-tags').reset().isSubmitting();
    this.teamService.addModifyUser(new TeamModel({
      email: this.selectedTeamUser.email,
      personas_tags: this.selectedTeamUser.personas_tags ? this.selectedTeamUser.personas_tags.map(tag => {
        if (typeof tag === 'string') {
          return tag;
        } else {
          // @ts-ignore
          return tag.name;
        }
      }) : null,
      role: this.selectedTeamUser.role,
      country_uniqid: this.selectedTeamUser.country_uniqid
    })).subscribe((res: ResponseModel) => {
      operation.isSuccess();
      this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
    }, (e) => {
      operation.isFailed();
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
    });
  }

  groupByPersonasChange(event) {
    const personas = [];
    const withoutTags = [];
    this.interviewedsToPrint = [];
    if (event.target.checked) {
      this.intervieweds.forEach(interviewed => {
        const tag = interviewed.personas_tags
        ? interviewed.personas_tags.length
          ? interviewed.personas_tags[interviewed.personas_tags.length - 1]
            : null
        : null;
        if (!tag) {
          withoutTags.push(interviewed);
        } else {
          let foundGroup = personas.find(group => group.persona === tag);
          if (!foundGroup) {
            foundGroup = {persona: tag, list: []};
            personas.push(foundGroup);
          }
          foundGroup.list.push(interviewed);
        }
      });
    } else {
      this.interviewedsToPrint = this.intervieweds;
    }
    personas.sort((a, b) => (a.list.length > b.list.length) ? -1
      : (a.list.length === b.list.length) ? ((a.list.length > b.list.length) ? -1 : 1) : 1 );
    personas.forEach(item => {
      this.interviewedsToPrint = this.interviewedsToPrint.concat(item.list);
    });
    this.interviewedsToPrint = this.interviewedsToPrint.concat(withoutTags);
    this.interviewedsToPrint = this.orderByName(this.interviewedsToPrint);
    this.selectTeamUser(this.interviewedsToPrint[0], null);
  }

  private orderByName(intervieweds: Array<TeamModel>) {
    intervieweds.sort((a, b) => a.nickname.localeCompare(b.nickname));
    return intervieweds;
  }

  notesChanged() {
    const operation = this.operation('saving-note').reset().isSubmitting();
    this.teamService.addModifyUser(new TeamModel({
      email: this.selectedTeamUser.email,
      note: this.selectedTeamUser.note,
      role: this.selectedTeamUser.role,
      country_uniqid: this.selectedTeamUser.country_uniqid
    })).subscribe((res: ResponseModel) => {
      operation.isSuccess();
      this.toastService.success(this.translate.instant('SUCCESS_GENERIC'));
    }, (e) => {
      operation.isFailed();
      this.toastService.error(this.translate.instant('ERROR_GENERIC'));
    });
  }


}
