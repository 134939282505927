<section>
    <form [formGroup]="form">
        <div class="container-fluid position-relative">

            <app-setup-questions-shared-media-upload
                [question]="question"
                [images]="attachedImages"
                [pdfFiles]="attachedPdfFiles"
                (addImageOut)="addImage($event)"
                (removeImageOut)="removeImage($event)"
                (addPdfOut)="addPdf($event)"
                (removePdfOut)="removePdf($event)">
            </app-setup-questions-shared-media-upload>

            <h4 class="mb-2">{{ 'QUESTION' | translate }}</h4>
            <div class="question-text panel shadow">
                <app-setup-questions-text [form]="form" [countryUniqId]="countryUniqId"></app-setup-questions-text>
            </div>
            <div class="form-group">
                <div class="question-checks mt-3">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="mb-3" formGroupName="question_data">
                                <input formControlName="add_comment_field" type="checkbox" id="check1">
                                <label for="check1">
                                    {{ 'ADD_COMMENT_FIELD_TO_OPTION' | translate }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-7">
                    <div class="alert alert-danger" role="alert" *ngIf="optionsError">
                        {{ "ERRORS.ANSWER_OPTIONS_MISSING_COMMENT" | translate }}
                    </div>
                    <div class="form-group">
                        <div formArrayName="answers" class="question-options-list" cdkDropList (cdkDropListDropped)="changeQuestionsOrder($event)">
                            <div *ngFor="let answer of form.get('answers')['controls']; let i = index;" class="answer-row" cdkDrag>
                                <div class="answer my-2">
                                    <div class="handler">
                                        <i class="far fa-grip-lines" style="font-size: 18px;"></i>
                                    </div>
                                    <app-setup-questions-answer-option [form]="answer" [answer]="answer"></app-setup-questions-answer-option>
                                    <div class="category-buttons text-center">
                                        <div>
                                            <i (click)="removeAnswer(i)" *ngIf="i >= 1" class="fal fa-minus-circle mr-2 as-link" style="font-size: 28px;"></i>
                                            <i
                                                (click)="addAnswer()"
                                                *ngIf="(i+1) === form.get('answers')['controls'].length"
                                                class="fal fa-plus-circle as-link" 
                                                style="font-size: 28px;"></i>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row question-checks mt-3 mb-3" *ngIf="block.is_room_group">
                <div class="col-12">
                    <div class="mb-3">
                        <input formControlName="is_masked" type="checkbox" id="check2">
                        <label for="check2">
                            {{ 'MASQUERADE_QUESTION' | translate }}
                        </label>
                    </div>
                </div>
            </div>
            <app-setup-questions-selects [question]="question" [block]="block" [form]="form" [countryUniqId]="countryUniqId"></app-setup-questions-selects>
        </div>
    </form>
</section>