import { Component, OnInit, OnDestroy } from '@angular/core';
import { CoreLayoutService } from '@app/core/services/layout.service'
import { ProfileEditComponent } from '@shared/components/profile/edit/profile-edit.component';
@Component({
  selector: 'app-private-toolbar-profile',
  templateUrl: './toolbar-profile.component.html',
  styleUrls: ['./toolbar-profile.component.scss']
})
export class ToolbarProfileComponent implements OnInit, OnDestroy {

  constructor(public coreLayoutService: CoreLayoutService) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  editAccount() {
    this.coreLayoutService.openSidebar(ProfileEditComponent);
  }
}
